import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

const statutToColor = (statut: string) => {
    switch (statut) {
        case 'ORGANISATION':
            return '#36b3e9';
        case 'BENEVOLES':
            return '#f49538';
        case 'PARTENAIRES':
            return '#fecc16';
        case 'EXPOSANTS':
            return '#17bd6d';
        case 'MEDICAL':
            return '#ff0000';
        case 'FOURNISSEURS':
            return '#da43c2';
        default:
            return '#fd5e97';
    }
};

interface IBadgeVannesA6FrontProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeVannesA6Front = (props: IBadgeVannesA6FrontProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;
    const accreditations = vr.accreditationsUsersInfos.map(
        ({ accreditation }) => accreditation.name
    );
    const hasZoneDepart = accreditations.includes('ZONE DÉPART');
    const hasEspaceBenevole = accreditations.includes('ESPACE BENEVOLES');
    const hasEspaceLogistique = accreditations.includes('ESPACE LOGISTIQUE');
    const hasPMA = accreditations.includes('PMA');
    const hasLigneArrivee = accreditations.includes('LIGNE ARRIVÉE');
    const hasPCCourse = accreditations.includes('PC COURSE');
    const hasEspaceVIP = accreditations.includes('ESPACE VIP');

    return (
        <Flex
            height={597}
            width={435}
            css={{
                background: `url(https://assets.recrewteer.com/badges/vannes/vannesRecto.png) no-repeat`,
                backgroundColor: statutToColor(userInfo.vannesStatut),
                backgroundSize: 'cover',
                fontFamily: '$montserrat',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    fontSize: '28px',
                    fontWeight: '700',
                    left: '40px',
                    position: 'absolute',
                    top: '259px'
                }}
            >
                {userInfo.firstName}
            </Box>

            <Box
                css={{
                    fontSize: '28px',
                    fontWeight: '700',
                    left: '40px',
                    position: 'absolute',
                    top: '301px'
                }}
            >
                {userInfo.lastName}
            </Box>

            <Box
                css={{
                    fontSize: '22px',
                    fontWeight: '500',
                    left: '40px',
                    position: 'absolute',
                    textTransform: 'capitalize',
                    top: '362px'
                }}
            >
                {userInfo.vannesStatut}
            </Box>

            <Flex
                gap="2"
                wrap="wrap"
                width={1}
                css={{
                    gap: '',
                    position: 'absolute',
                    px: '44px',
                    top: '461px'
                }}
            >
                <Box
                    css={{
                        background: '#0fb4b0',
                        borderRadius: '$1',
                        fontSize: '11px',
                        padding: '3px 4px',
                        visibility: hasZoneDepart ? 'visible' : 'hidden'
                    }}
                >
                    Zone départ
                </Box>

                <Box
                    css={{
                        background: '#2f8ece',
                        borderRadius: '$1',
                        fontSize: '11px',
                        padding: '3px 4px',
                        visibility: hasEspaceBenevole ? 'visible' : 'hidden'
                    }}
                >
                    Espace Bénévole
                </Box>

                <Box
                    css={{
                        background: '#d26814',
                        borderRadius: '$1',
                        fontSize: '11px',
                        padding: '3px 4px',
                        visibility: hasEspaceLogistique ? 'visible' : 'hidden'
                    }}
                >
                    Espace Logistique
                </Box>

                <Box
                    css={{
                        background: '#c4758b',
                        borderRadius: '$1',
                        fontSize: '11px',
                        padding: '3px 4px',
                        visibility: hasPMA ? 'visible' : 'hidden'
                    }}
                >
                    PMA
                </Box>

                <Box
                    css={{
                        background: '#c7a2cd',
                        borderRadius: '$1',
                        fontSize: '11px',
                        padding: '3px 4px',
                        visibility: hasLigneArrivee ? 'visible' : 'hidden'
                    }}
                >
                    Ligne Arrivée
                </Box>

                <Box
                    css={{
                        background: '#bbcbae',
                        borderRadius: '$1',
                        fontSize: '11px',
                        padding: '3px 4px',
                        visibility: hasPCCourse ? 'visible' : 'hidden'
                    }}
                >
                    PC Course
                </Box>

                <Box
                    css={{
                        background: '#d0558b',
                        borderRadius: '$1',
                        fontSize: '11px',
                        padding: '3px 4px',
                        visibility: hasEspaceVIP ? 'visible' : 'hidden'
                    }}
                >
                    Espace VIP
                </Box>
            </Flex>
        </Flex>
    );
};
