import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';

interface IZoneProps {
    backgroundColor: string;
    zoneNumber: number;
    zoneText: string;
}

export const Zone = (props: IZoneProps) => (
        <Flex align="center" width={1}>
            <Flex
                align="center"
                justify="center"
                height={24}
                width={30}
                css={{ background: props.backgroundColor, color: 'white', fontWeight: 700 }}
            >
                {props.zoneNumber}
            </Flex>

            <Spacer width="2" />

            <Box
                css={{
                    fontSize: '13px',
                    fontWeight: 700
                }}
            >
                {props.zoneText}
            </Box>
        </Flex>
    );

export const EmptyZone = () => <Box height={24} width={1} />;
