import { Accordion } from 'common-front/src/components/accordion/accordion';
import { Button } from 'common-front/src/components/button/button';
import { Page } from 'common-front/src/components/page/page';
import { TextInput } from 'common-front/src/components/reactFinalForm/input/text';
import { Separator } from 'common-front/src/components/separator/separator';
import { H1 } from 'common-front/src/components/titles/h1';
import { UserForm } from 'common-front/src/components/users/create/userForm';
import { VolunteerRegistrationPositions } from 'common-front/src/components/volunteerRegistrationPositions/volunteerRegistrationPositions';
import { VolunteerRegistrationSlots } from 'common-front/src/components/volunteerRegistrationSlots/volunteerRegistrationSlots';
import { useVolunteerRegistrationUpdateMutation } from 'common-front/src/generated/graphqlHooks';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    FormPositionRanking,
    RegisterPositionDisplay,
    RegisterSlotDisplay,
    VolunteerInfoQuery
} from 'common/src/generated/types';
import {
    IUpdateVolunteerRegistrationValues,
    VolunteerRegistrationInputService
} from 'common/src/input/volunteerRegistrationInput';
import { ValidateService } from 'common/src/services/validateService';
import {
    useHistory,
    useParams,
    useService,
    useTranslate
} from 'common/src/util/dependencies/dependencies';
import { getErrorsStrings } from 'common/src/util/error';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import arrayMutators from 'final-form-arrays';
import { isEqual } from 'lodash-es';
import * as React from 'react';
import { Form } from 'react-final-form';
import { useVolunteerInfoQuery } from '../../generated/graphqlHooks';

interface IUpdateVolunteerComponentProps {
    data: VolunteerInfoQuery;
}

const UpdateVolunteerComponent = (props: IUpdateVolunteerComponentProps) => {
    const translate = useTranslate();
    const volunteerRegistrationInput = useService(VolunteerRegistrationInputService);
    const { organizationId, eventId, volunteerRegistrationId } = useParams();
    const history = useHistory();
    const { mutate, isLoading } = useVolunteerRegistrationUpdateMutation({
        redirectOnSuccess: true
    });
    const organization = props.data.organization;
    const event = organization.event;
    const userInfo = event.volunteerRegistration.userInfo;
    const eventsFields = React.useMemo(() => event.formsCustomsFields.map((field) => ({
            isMandatory: false,
            field
        })), [event.formsCustomsFields]);
    const schema = React.useMemo(() => volunteerRegistrationInput.updateVolunteerRegistrationSchema(
            eventsFields,
            organization.customFields.nodes,
            event.slotDisplay,
            FormPositionRanking.Unranked,
            event.positionDisplay,
            event.startAt,
            event.endAt
        ), [eventsFields]);
    const validateService = useService(ValidateService);
    const customFields = React.useMemo(() => event.formsCustomsFields.concat(organization.customFields.nodes), [event.formsCustomsFields, organization.customFields]);

    return (
        <Form
            initialValues={volunteerRegistrationInput.updateVolunteerRegistrationValuesDefault(
                props.data.organization.event.volunteerRegistration,
                props.data.organization.event,
                props.data.organization.event.formsCustomsFields.concat(
                    props.data.organization.customFields.nodes
                )
            )}
            initialValuesEqual={isEqual}
            validate={validateService.validateForForm(schema)}
            mutators={{
                ...arrayMutators
            }}
            onSubmit={async (values: IUpdateVolunteerRegistrationValues) => {
                await mutate({
                    eventId,
                    email: values.email,
                    volunteerRegistrationId,
                    volunteerRegistration: values.volunteerRegistration
                });

                history.push(HeaventPaths.VOLUNTEERS(organizationId, eventId));
            }}
            render={({ form, handleSubmit, invalid, values, errors }) => {
                const errorsStrings = getErrorsStrings(errors);

                return (
                    <Page>
                        <Flex justify="between">
                            <H1>{translate('mettre_jour_l_27001', userInfo.name)}</H1>
                        </Flex>

                        <Box css={{ marginTop: '$9' }}>
                            {errorsStrings && (
                                <>
                                    <Box
                                        css={{
                                            color: '$oldRed',
                                            display: 'flex',
                                            '& > i': {
                                                fontSize: '18px',
                                                marginRight: '$2',
                                                paddingTop: '1px'
                                            }
                                        }}
                                    >
                                        <i className="fal fa-exclamation-circle" />

                                        <div>
                                            {errorsStrings.map((error, index) => (
                                                <div key={index}>{error}</div>
                                            ))}
                                        </div>
                                    </Box>

                                    <Separator margin={32} />
                                </>
                            )}
                            <Accordion initialIsOpen={true} title={translate('e_mail_13869')}>
                                <TextInput
                                    label={translate('adresse_e_mail_60930')}
                                    name="email"
                                    isEmail={true}
                                />
                            </Accordion>

                            <Separator marginBottom={32} />

                            <Accordion
                                initialIsOpen={true}
                                title={translate('informations_pe_37892')}
                            >
                                <UserForm
                                    fieldsToDisplay={eventsFields}
                                    customFields={customFields}
                                    prefix="volunteerRegistration.userInfo.fields."
                                    values={values.volunteerRegistration.userInfo.fields}
                                    change={form.change}
                                />
                            </Accordion>

                            <Separator margin={32} />

                            <Accordion
                                initialIsOpen={true}
                                title={translate('informations_pr_15873')}
                            >
                                <UserForm
                                    fieldsToDisplay={organization.customFields.nodes.map(
                                        (customFied) => ({
                                            isMandatory: false,
                                            field: customFied
                                        })
                                    )}
                                    customFields={customFields}
                                    prefix="volunteerRegistration.userInfo.fields."
                                    values={values.volunteerRegistration.userInfo.fields}
                                    change={form.change}
                                />
                            </Accordion>

                            {event.slotDisplay !== RegisterSlotDisplay.Hide && (
                                <>
                                    <Separator margin={32} />

                                    <Accordion
                                        initialIsOpen={true}
                                        title={translate('cr_neaux_de_dis_89497')}
                                    >
                                        <VolunteerRegistrationSlots
                                            event={event}
                                            values={values.volunteerRegistration}
                                            change={form.change}
                                        />
                                    </Accordion>
                                </>
                            )}

                            {event.positionDisplay !== RegisterPositionDisplay.None && (
                                <>
                                    <Separator margin={32} />

                                    <Accordion
                                        initialIsOpen={true}
                                        title={
                                            event.positionDisplay ===
                                            RegisterPositionDisplay.Category
                                                ? translate('cat_gories_de_m_09461')
                                                : translate('missions_souhai_23225')
                                        }
                                    >
                                        <VolunteerRegistrationPositions
                                            event={event}
                                            values={values.volunteerRegistration}
                                            showHiddenPositions={true}
                                            change={form.change}
                                        />
                                    </Accordion>
                                </>
                            )}

                            <Box css={{ display: 'flex' }}>
                                <Button
                                    onClick={handleSubmit}
                                    isInactive={invalid}
                                    isLoading={isLoading}
                                >
                                    {translate('mettre_jour_85141')}
                                </Button>
                            </Box>
                        </Box>
                    </Page>
                );
            }}
        />
    );
};

export const UpdateVolunteer = () => {
    const { organizationId, eventId, volunteerRegistrationId } = useParams();
    const { data, loader } = useVolunteerInfoQuery({
        organizationId,
        eventId,
        volunteerRegistrationId
    });

    return loader || <UpdateVolunteerComponent data={data} />;
};
