import { DateInput } from 'common-front/src/designSystem/components/date/dateInput';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import { MutationNames } from 'common/src/generated/mutationsNames';
import { EventId } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { ExcludedMutationNames } from 'common/src/vo/mutationNames';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useActivitiesQuery } from '../../generated/graphqlHooks';
import { ActivityRow } from './activityRow';

export const Activities = () => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const [operations, setOperations] = React.useState([]);
    const [eventsIds, setEventsIds] = React.useState<EventId[]>([]);
    const [date, setDate] = React.useState(DateTime.invalid('Invalid'));
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading } = useActivitiesQuery({
        organizationId,
        operations,
        eventsIds,
        date,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.organization?.activities);
    const mutationNames = React.useMemo(() => {
        const excludedMutationNames = Object.keys(ExcludedMutationNames);

        return Object.keys(MutationNames)
            .filter((mn) => !excludedMutationNames.includes(mn))
            .map((mn) => (
                    <option key={mn} value={mn}>
                        {translate(mn)}
                    </option>
                ));
    }, []);

    return (
        <Flex direction="column" height={1} width={1} css={{ overflowY: 'auto', padding: '$7' }}>
            <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                {translate('activit_95747')}
            </Box>

            <Spacer height="7" />

            <Flex
                align="center"
                gap="4"
                css={{
                    border: '1px solid $gray200',
                    borderBottom: 'none',
                    borderTopLeftRadius: '$1',
                    borderTopRightRadius: '$1',
                    padding: '$3 $4'
                }}
            >
                <Box width={320}>
                    <RichSelect
                        isSearchVisible={true}
                        multiple={true}
                        placeholder="Opération"
                        values={operations}
                        onChange={setOperations}
                    >
                        {mutationNames}
                    </RichSelect>
                </Box>

                <Box width={320}>
                    <RichSelect
                        isSearchVisible={true}
                        multiple={true}
                        placeholder="Événement"
                        values={eventsIds}
                        onChange={setEventsIds}
                    >
                        {(data.organization?.events.nodes ?? []).map((event) => (
                                <option key={event.id} value={event.id}>
                                    {event.name}
                                </option>
                            ))}
                    </RichSelect>
                </Box>

                <Box width={200}>
                    <DateInput value={date} onChange={setDate} />
                </Box>
            </Flex>

            <Box width={1} css={{ flex: '1', overflow: 'hidden' }}>
                <TableGrid
                    headerRow={
                        <HeaderRow>
                            <HeaderCell>Description</HeaderCell>
                            <HeaderCell>Date</HeaderCell>
                        </HeaderRow>
                    }
                    paginationRow={
                        <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                            <PaginationCell
                                numberOfPages={numberOfPages}
                                offset={offset}
                                totalCount={totalCount}
                                setOffset={setOffset}
                            />
                        </PaginationRow>
                    }
                >
                    {isLoading ? (
                        <>
                            <RowSkeleton />
                            <RowSkeleton />
                            <RowSkeleton />
                        </>
                    ) : (
                        data.organization.activities.nodes.map((activity, index) => <ActivityRow key={index} activity={activity} />)
                    )}
                </TableGrid>
            </Box>
        </Flex>
    );
};
