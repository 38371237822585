import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';
import { CommonEnvVars } from '../../../envVars';

const getBackground = (population: string) => {
    switch (population) {
        case 'STAFF':
            return `https://assets.recrewteer.com/badges/blot/STAFF@3x.jpg`;
        case 'BÉNÉVOLES':
            return `https://assets.recrewteer.com/badges/blot/BENEVOLES@3x.jpg`;
        case 'MÉDIAS - JOURNALISTE':
            return `https://assets.recrewteer.com/badges/blot/JOURNALISTE@3x.jpg`;
        case 'MÉDIAS - PHOTOGRAPHE':
            return `https://assets.recrewteer.com/badges/blot/PHOTOGRAPHE@3x.jpg`;
        case 'PARTENAIRE':
            return `https://assets.recrewteer.com/badges/blot/PARTENAIRES%403x.jpg`;
        case 'JOUEUR':
            return `https://assets.recrewteer.com/badges/blot/JOUEUR@3x.jpg`;
        case 'CADDIE':
            return `https://assets.recrewteer.com/badges/blot/CADDIE@3x.jpg`;
        default:
            return `https://assets.recrewteer.com/badges/blot/BENEVOLES@3x.jpg`;
    }
};

interface IBadgeBlotProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeBlot = (props: IBadgeBlotProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = ui.blotPopulation || 'STAFF';

    return (
        <>
            <Flex
                height={326}
                width={208}
                css={{
                    background: `url(${getBackground(population)}) no-repeat`,
                    backgroundSize: 'cover',
                    color: 'white',
                    fontFamily: '$magallanes',
                    fontSize: '16px',
                    fontWeight: '700',
                    position: 'relative'
                }}
            >
                {(population.startsWith('MÉDIAS') || population === 'PARTENAIRE') && (
                    <Flex
                        direction="column"
                        width={1}
                        css={{
                            position: 'absolute',
                            top: '275px'
                        }}
                    >
                        <Box width={1} textAlign="center">
                            {ui.name}
                        </Box>
                        <Box width={1} textAlign="center">
                            {ui.fields.cf31319 || ''}
                        </Box>
                    </Flex>
                )}

                {population === 'STAFF' && (
                    <Box
                        width={1}
                        textAlign="center"
                        css={{
                            position: 'absolute',
                            top: '285px'
                        }}
                    >
                        {ui.name}
                    </Box>
                )}
            </Flex>
            <Flex
                height={326}
                width={208}
                css={{
                    background: `white`,
                    backgroundSize: 'cover',
                    position: 'relative'
                }}
            >
                <Box
                    height={90}
                    width={90}
                    css={{
                        left: '59px',
                        position: 'absolute',
                        top: '118px'
                    }}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=84&width=84`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>
            </Flex>
        </>
    );
};
