import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { getColorByBackgroundColor } from '../../../util/color';
import { getIconComponent } from '../icon';

interface IDinoZoneProps {
    accreditation: DocumentUserCustomBadgeFragment['volunteerRegistration']['accreditationsUsersInfos'][0]['accreditation'];
}

export const DinoZone = (props: IDinoZoneProps) => {
    const Icon = getIconComponent(props.accreditation.icon);
    const fill = getColorByBackgroundColor(props.accreditation.color);

    return (
        <Flex align="center" gap="1">
            <Flex
                align="center"
                justify="center"
                height={24}
                width={32}
                css={{
                    backgroundColor: props.accreditation.color,
                    borderRadius: '$1',
                    '& svg': {
                        fill,
                        height: '70%',
                        width: '70%'
                    }
                }}
            >
                <Icon />
            </Flex>

            <Box font="gray800 textXs bold">{props.accreditation.name}</Box>
        </Flex>
    );
};
