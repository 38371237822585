import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { VolunteerRegistrationFragment } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { getWishedPositionsBadges } from 'common/src/vo/position';
import * as React from 'react';

interface IVolunteerRegistrationWishedPositionsProps {
    firstCellCss: any;
    volunteerRegistration: VolunteerRegistrationFragment;
}

export const VolunteerRegistrationWishedPositions = (
    props: IVolunteerRegistrationWishedPositionsProps
) => {
    const intervalService = useService(IntervalService);
    const badges = React.useMemo(() => getWishedPositionsBadges(
            intervalService,
            props.volunteerRegistration?.positionsCategories || [],
            props.volunteerRegistration?.positions || [],
            props.volunteerRegistration?.positionsSlots || []
        ), [props.volunteerRegistration]);

    return (
        <Cell css={props.firstCellCss}>
            <BadgesPlus badges={badges} />
        </Cell>
    );
};
