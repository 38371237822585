import {
    EventId,
    Feature,
    OrganizationId,
    UserInfoDocumentLink,
    UsersInfoId,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { getDocumentFullPath } from 'common/src/util/documentsPaths';
import { getDisplayName } from 'common/src/vo/customBadge';
import * as React from 'react';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { UserProfileAction } from './userProfileAction';
import { UserProfileActionsContainer } from './userProfileActionsContainer';

interface IEventUserProfileActionsDocumentsProps {
    eventId: EventId;
    links: UserInfoDocumentLink;
    organizationId: OrganizationId;
    state: VolunteerRegistrationState;
    userInfoId: UsersInfoId;
}

export const EventUserProfileActionsDocuments = (props: IEventUserProfileActionsDocumentsProps) => {
    const translate = useTranslate();
    const { organization, hasFeature } = useOrganizationContext();
    const showMemberDocuments = props.state === VolunteerRegistrationState.Assigned;

    return (
        <UserProfileActionsContainer title={translate('documents_87028')}>
            {
                // TODO: use `download` attributes
                showMemberDocuments && (
                    <>
                        <UserProfileAction
                            color="purple"
                            icon="file-lines"
                            onClick={() => {
                                window.open(
                                    getDocumentFullPath(props.links.userAssignmentsLink, 'pdf')
                                );
                            }}
                        >
                            {translate('t_l_charger_la_82882')}
                        </UserProfileAction>

                        <UserProfileAction
                            color="purple"
                            icon="file-lines"
                            onClick={() => {
                                window.open(
                                    getDocumentFullPath(props.links.userPlanningLink, 'pdf')
                                );
                            }}
                        >
                            {translate('t_l_charger_le_74660')}
                        </UserProfileAction>

                        <UserProfileAction
                            color="purple"
                            icon="file-lines"
                            onClick={() => {
                                window.open(
                                    getDocumentFullPath(props.links.userPlanningListLink, 'pdf')
                                );
                            }}
                        >
                            {translate('t_l_charger_le_58518')}
                        </UserProfileAction>

                        {hasFeature(Feature.Documents) && (
                            <>
                                <UserProfileAction
                                    color="purple"
                                    icon="file-lines"
                                    onClick={() => {
                                        window.open(
                                            getDocumentFullPath(props.links.userBadgeLink, 'pdf')
                                        );
                                    }}
                                >
                                    {translate('t_l_charger_le_53240')}
                                </UserProfileAction>

                                <UserProfileAction
                                    color="purple"
                                    icon="file-lines"
                                    onClick={() => {
                                        window.open(
                                            getDocumentFullPath(
                                                props.links.userCertificateLink,
                                                'pdf'
                                            )
                                        );
                                    }}
                                >
                                    {translate('t_l_charger_l_a_02386')}
                                </UserProfileAction>
                            </>
                        )}
                    </>
                )
            }

            {organization.customBadges.map((customBadge) => (
                    <UserProfileAction
                        key={customBadge}
                        color="purple"
                        icon="file-lines"
                        onClick={() => {
                            window.open(
                                getDocumentFullPath(
                                    props.links.userCustomBadgeLinks[customBadge],
                                    'pdf'
                                )
                            );
                        }}
                    >
                        {translate('t_l_charger_1_80752', getDisplayName(customBadge))}
                    </UserProfileAction>
                ))}
        </UserProfileActionsContainer>
    );
};
