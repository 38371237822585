import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

export const BelgiumHouseGuestBadge = ({ event }: { event: DocumentUserCustomBadgeFragment }) => {
    const vr = event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            height={530}
            width={333}
            css={{
                background: `url(https://assets.recrewteer.com/badges/belgiumHouse/guestBadge.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$gothamRoundedBold',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    color: 'black',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    position: 'absolute',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    top: '208px',
                    width: '100%'
                }}
            >
                {ui.firstName}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    position: 'absolute',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    top: '237px',
                    width: '100%'
                }}
            >
                {ui.lastName}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    position: 'absolute',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    top: '270px',
                    width: '100%'
                }}
            >
                {ui.fields.cf33875 ?? ''}
            </Box>

            <Box
                height={65}
                width={65}
                css={{
                    background: 'white',
                    left: '134px',
                    position: 'absolute',
                    top: '322px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=65&width=65`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
