import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Grid } from '../../../designSystem/components/grid';
import { DocumentUserCustomBadgeFragment, FormId } from '../../../generated/types';
import { A7_SIZES } from '../../../util/pdf';
import { Meal } from './meal';

const CUSTOM_ORDERS: Record<string, number> = {
    MAsoir: 1,
    MERC: 2,
    MEmatin: 3,
    MEmidi: 4,
    MEsoir: 5,
    JEUD: 6,
    JEmatin: 7,
    JEmidi: 8,
    JEsoir: 9,
    VEND: 10,
    VEmatin: 11,
    VEmidi: 12,
    VEsoir: 13,
    SAM: 14,
    SAmatin: 15,
    SAmidi: 16,
    SAsoir: 17,
    DIM: 18,
    DImatin: 19,
    DImidi: 20,
    DIsoir: 21
};
const BENEVOLES_FORM_ID = 328 as FormId;

interface IBagdeSalamandreA7FrontProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BagdeSalamandreA7Front = (props: IBagdeSalamandreA7FrontProps) => {
    const userInfo = props.event.volunteerRegistration.userInfo;
    const accreditationsUsersInfos = sortBy(
        props.event.volunteerRegistration.accreditationsUsersInfos,
        (aui) => CUSTOM_ORDERS[aui.accreditation.acronym || ''] || -1
    );
    const filledFormsIds = props.event.volunteerRegistration.userInfo.formsUsersInfos.map(
        (fui) => fui.formId
    );

    return (
        <Flex
            direction="column"
            height={A7_SIZES['96dpi'].height - 5}
            width={A7_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/salamandre/salamandreFront.jpg) no-repeat`,
                backgroundSize: 'cover',
                overflow: 'hidden',
                padding: '12px 14px',
                position: 'relative'
            }}
        >
            <Box
                color="white"
                css={{
                    fontFamily: '$gravur',
                    fontSize: '22px',
                    left: '0',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '117px',
                    width: '100%'
                }}
            >
                {userInfo.name}
            </Box>

            <Box
                color="white"
                css={{
                    fontSize: '20px',
                    fontWeight: '800',
                    left: '0',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '139px',
                    width: '100%'
                }}
            >
                {filledFormsIds.includes(BENEVOLES_FORM_ID)
                    ? userInfo.salamandreFonctionFestival
                    : userInfo.salamandreFonction}
            </Box>

            <Grid
                gridtemplatecolumns="repeat(3, 1fr)"
                gridtemplaterows="repeat(3, 1fr)"
                height={187}
                width={1}
                css={{
                    flex: '1',
                    gap: '8px 40px',
                    padding: '12px 36px',
                    position: 'absolute',
                    top: '194px',
                    left: 0
                }}
            >
                {accreditationsUsersInfos.map((aui, index) => (
                        <Meal
                            key={index}
                            icon={aui.accreditation.icon}
                            name={aui.accreditation.acronym || ''}
                        />
                    ))}
            </Grid>
        </Flex>
    );
};
