import * as React from 'react';
import { Avatar } from '../../../components/avatar/avatar';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';

interface IP2NAssignmentPageProps {
    children: React.ReactNode;
    event: DocumentUserCustomBadgeFragment;
    title: string;
}

export const P2NAssignmentPage = (props: IP2NAssignmentPageProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            direction="column"
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background:
                    'url(https://assets.recrewteer.com/badges/p2n/assignment.jpg) no-repeat',
                backgroundSize: 'cover',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    left: '48px',
                    position: 'absolute',
                    top: '47px'
                }}
            >
                <Avatar size={40} email={ui.nameOrEmail} name={ui.name} image={ui.picture?.url} />
            </Box>

            <Flex
                direction="column"
                css={{
                    left: '104px',
                    position: 'absolute',
                    top: '45px'
                }}
            >
                <Box
                    css={{
                        color: '$gray800',
                        fontSize: '16px',
                        fontWeight: '600'
                    }}
                >
                    {ui.name}
                </Box>

                <Box color="gray800">{ui.phone?.internationalFormat || ''}</Box>
            </Flex>

            <Box
                width={1}
                textAlign="center"
                css={{
                    color: 'black',
                    fontSize: '24px',
                    fontWeight: '700',
                    position: 'absolute',
                    top: '112px'
                }}
            >
                {props.title}
            </Box>

            <Flex
                direction="column"
                css={{
                    gap: '28px',
                    flex: '1',
                    left: '48px',
                    position: 'absolute',
                    top: '172px',
                    width: 'calc(100% - 96px)'
                }}
            >
                {props.children}
            </Flex>
        </Flex>
    );
};
