import { CardListItem } from 'common-front/src/components/pagination/mobile/cardListItem';
import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionId, PositionsMobileQuery } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IPositionCardMobileProps {
    open: (id: PositionId) => void;
    position: PositionsMobileQuery['event']['positions']['nodes'][number];
}

export const PositionCardMobile = ({ open, position }: IPositionCardMobileProps) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);

    return (
        <CardListItem
            onClick={() => {
                open(position.id);
            }}
        >
            <Flex gap="2">
                {position.assignedResources >= position.resources ? (
                    <Badge color="success">{translate('complet_51600')}</Badge>
                ) : (
                    <Badge color="warning">{translate('incomplet_45915')}</Badge>
                )}
                {!position.isVisible && <Badge color="gray">{translate('Private')}</Badge>}
            </Flex>
            <Box fontWeight="semiBold" color="gray800" css={{ marginTop: '$2' }}>
                {position.name}
            </Box>
            <Box fontSize="textSm" color="gray500" css={{ marginTop: '$1' }}>{`${translate(
                'creneau',
                position.slots.length
            )} · ${intervalService.toLocaleString(position.range, undefined, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            })}`}</Box>
            <Box css={{ marginTop: '$3' }}>
                <ProgressBar percent={position.assignedResources / position.resources} />
            </Box>
        </CardListItem>
    );
};
