import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    ICreateEventOrganizationValues,
    ICreateUpdateEventValues
} from 'common/src/input/eventInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FormApi } from 'final-form';
import { DateTime } from 'luxon';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';

interface IEventFormProps {
    form: FormApi<ICreateEventOrganizationValues> | FormApi<ICreateUpdateEventValues>;
    values: ICreateEventOrganizationValues | ICreateUpdateEventValues;
}

export const EventForm = (props: IEventFormProps) => {
    const translate = useTranslate();
    const now = DateTime.utc();
    const { event } = props.values;

    return (
        <>
            <OnChange name="event.startAt">
                {(startAt: DateTime) => {
                    if (
                        startAt.isValid &&
                        props.form.getFieldState('event.endAt')!.touched === false
                    ) {
                        props.form.change('event.endAt', startAt);
                    }
                }}
            </OnChange>

            <TextInput label={translate('nom_de_l_v_nem_08711')} name="event.name" />

            <Spacer height="4" />

            <Flex gap="6">
                <DateInput
                    label={translate('date_de_d_but_d_81210')}
                    name="event.startAt"
                    min={now}
                />

                <DateInput
                    label={translate('date_de_fin_des_08314')}
                    name="event.endAt"
                    min={event.startAt && event.startAt.isValid ? event.startAt : now}
                />
            </Flex>
        </>
    );
};
