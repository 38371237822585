import { Empty } from 'common-front/src/components/empty/empty';
import { Item } from 'common-front/src/designSystem/components/leftMenu/item';
import { LeftMenu } from 'common-front/src/designSystem/components/leftMenu/leftMenu';
import { Box } from 'common/src/designSystem/components/box';
import { Grid } from 'common/src/designSystem/components/grid';
import { Feature } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { useFormContext } from '../../show/formContext';
import { FormParametersAccreditations } from './accreditations/formParametersAccreditations';
import { FormParametersAvailabilities } from './availabilities/formParametersAvailabilities';
import {
    FormParametersBroadcastEvent,
    FormParametersBroadcastOrganization
} from './broadcast/formParametersBroadcast';
import { FormParametersDesign } from './design/formParametersDesign';
import { FormParametersGeneral } from './general/formParametersGeneral';
import { FormParametersMissions } from './missions/formParametersMissions';
import { FormParametersState } from './state/formParametersState';

export const FormParameters = () => {
    const translate = useTranslate();
    const { hasFeature } = useOrganizationContext();
    const { basePath, eventId } = useFormContext();

    return (
        <Grid
            gridtemplatecolumns="1fr 210px 80px 880px 1fr"
            gridtemplaterows="1fr"
            width={1}
            css={{ py: '$8' }}
        >
            <Box css={{ gridColumn: '2 / 3' }}>
                <LeftMenu>
                    <Item path={`${basePath}/parameters/general`}>
                        {translate('g_n_ral_64953')}
                    </Item>

                    <Item path={`${basePath}/parameters/design`}>{translate('design_15043')}</Item>

                    {eventId && (
                        <Item path={`${basePath}/parameters/availabilities`}>
                            {translate('disponibilit_s_49923')}
                        </Item>
                    )}

                    {eventId && (
                        <Item path={`${basePath}/parameters/missions`}>
                            {translate('missions_63972')}
                        </Item>
                    )}

                    {eventId && hasFeature(Feature.Accreditation) && (
                        <Item path={`${basePath}/parameters/accreditations`}>
                            {translate('accr_ditations_39450')}
                        </Item>
                    )}

                    <Item path={`${basePath}/parameters/broadcast`}>
                        {translate('diffusion_56689')}
                    </Item>

                    <Item path={`${basePath}/parameters/state`}>{translate('cl_turer_88223')}</Item>
                </LeftMenu>
            </Box>

            <Box width={1} css={{ gridColumn: '4 / 5' }}>
                <Switch>
                    <Route
                        path={`${basePath}/parameters/general`}
                        children={<FormParametersGeneral />}
                    />

                    <Route
                        path={`${basePath}/parameters/design`}
                        children={<FormParametersDesign />}
                    />

                    {eventId && (
                        <Route
                            path={`${basePath}/parameters/availabilities`}
                            children={<FormParametersAvailabilities />}
                        />
                    )}

                    {eventId && (
                        <Route
                            path={`${basePath}/parameters/missions`}
                            children={<FormParametersMissions />}
                        />
                    )}

                    {eventId && hasFeature(Feature.Accreditation) && (
                        <Route
                            path={`${basePath}/parameters/accreditations`}
                            children={<FormParametersAccreditations />}
                        />
                    )}

                    <Route
                        path={`${basePath}/parameters/broadcast`}
                        children={
                            eventId ? (
                                <FormParametersBroadcastEvent />
                            ) : (
                                <FormParametersBroadcastOrganization />
                            )
                        }
                    />

                    <Route
                        path={`${basePath}/parameters/state`}
                        children={<FormParametersState />}
                    />

                    <Route children={<Empty path={`${basePath}/parameters/general`} />} />
                </Switch>
            </Box>
        </Grid>
    );
};
