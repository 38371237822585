import { chunk } from 'lodash-es';
import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgesFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { BadgeJokersfrancesloveniaA6 } from './badgeJokersfrancesloveniaA6';

interface IBadgesJokersfrancesloveniaProps {
    event: DocumentUserCustomBadgesFragment;
}

export const BadgesJokersfranceslovenia = (props: IBadgesJokersfrancesloveniaProps) => (
        <>
            {chunk(props.event.volunteersRegistrations.nodes, 2).map(
                (volunteersRegistrations, index) => (
                        <Flex
                            key={`page-${index}`}
                            wrap="wrap"
                            justify="between"
                            width={A4_SIZES['96dpi'].width}
                            css={{
                                pageBreakBefore: index !== 0 ? 'always' : undefined
                            }}
                        >
                            {volunteersRegistrations.map((volunteerRegistration) => (
                                    <BadgeJokersfrancesloveniaA6
                                        key={volunteerRegistration.userInfo.id}
                                        event={{
                                            ...props.event,
                                            volunteerRegistration
                                        }}
                                    />
                                ))}
                        </Flex>
                    )
            )}
        </>
    );
