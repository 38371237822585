import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { CommonEnvVars } from '../../../envVars';

const DEV = false;

const Rect: React.FunctionComponent<React.ComponentProps<typeof Flex>> = ({ css, children }) => (
    <Flex
        css={
            {
                alignItems: 'center',
                border: '1px solid',
                flexDirection: 'column',
                fontSize: '24px',
                fontWeight: 700,
                height: '42px',
                justifyContent: 'center',
                lineHeight: '24px',
                padding: '2px 0px',
                position: 'absolute',
                width: '110px',
                ...css
            } as React.ComponentProps<typeof Flex>['css']
        }
    >
        {children}
    </Flex>
);

const RectTitle: React.FunctionComponent<React.ComponentProps<typeof Box>> = ({
    css,
    children
}) => (
    <Box
        css={
            {
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '14px',
                ...css
            } as React.ComponentProps<typeof Box>['css']
        }
    >
        {children}
    </Box>
);

const IDS = [
    'P0',
    'P1',
    'P1 Aire Régie',
    'P2',
    'P3',
    'P4',
    'ZONE 1 - PELOUSE',
    'ZONE 2 - VESTIAIRES',
    'ZONE 3 - TRIBUNES',
    'ZONE 4 - MEDIAS',
    'ZONE 5 - AIRE REGIE TV',
    'ZONE 6 - HOSPITALITES',
    'ZONE 7 - SERVICES',
    'ZONE 8 - BORD TERRAIN',
    'ZONE 9 - PRESIDENTIELLE',
    'H-30',
    'PC'
];

export const BadgeDfcoA4 = ({ event }: { event: DocumentUserCustomBadgeFragment }) => {
    const vr = event.volunteerRegistration;
    const ui = vr.userInfo;
    const name = `${ui.firstName || ''} ${(ui.lastName || '').toUpperCase()}`;
    const accreditations = vr.accreditationsUsersInfos.reduce(
        (acc, aui) => {
            if (IDS.includes(aui.accreditation.name)) {
                return { ...acc, [aui.accreditation.name]: aui.accreditation.color };
            } else {
                return acc;
            }
        },
        (DEV
            ? {
                  P0: '#FFF',
                  'ZONE 1 - PELOUSE': '#F8EC1A',
                  'ZONE 2 - VESTIAIRES': '#7FB9E1',
                  'ZONE 3 - TRIBUNES': '#A58A77',
                  'ZONE 4 - MEDIAS': '#97C00E',
                  'ZONE 5 - AIRE REGIE TV': '#F8B332',
                  'ZONE 6 - HOSPITALITES': '#BBA0C9',
                  'ZONE 7 - SERVICES': '#E7BB98',
                  'ZONE 8 - BORD TERRAIN': '#CCCDCF',
                  'ZONE 9 - PRESIDENTIELLE': '#E58CA8',
                  'H-30': '#D5103E',
                  PC: '#D5103E'
              }
            : {}) as {
            [name: string]: string;
        }
    );
    if (DEV) {
        vr.weezeventParticipantInfo = { ...vr.weezeventParticipantInfo, idBarcode: '872D687F9D25' };
    }

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/dfco/accred-a4.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$default',
                position: 'relative'
            }}
        >
            <Box
                height={91}
                width={91}
                css={{
                    left: '423px',
                    position: 'absolute',
                    top: '145px'
                }}
            >
                {isNonEmptyString(ui.picture?.url) && (
                    <img src={ui.picture!.url} height="100%" width="100%" />
                )}
            </Box>
            <Box
                css={{
                    left: '530px',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    position: 'absolute',
                    top: '151px'
                }}
            >
                {name}
            </Box>
            <Box
                css={{
                    left: '530px',
                    fontWeight: 700,
                    lineHeight: 'normal',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '176px'
                }}
            >
                {ui.fields.cf36682 || ''}
            </Box>
            {accreditations['P0'] && (
                <Rect css={{ left: '421px', top: '280px' }}>
                    <Box>P0</Box>
                </Rect>
            )}
            {accreditations['P1'] && (
                <Rect css={{ left: '421px', top: '280px' }}>
                    <Box>P1</Box>
                </Rect>
            )}
            {accreditations['P1 Aire Régie'] && (
                <Rect css={{ left: '421px', top: '280px' }}>
                    <Box>P1 A-R</Box>
                </Rect>
            )}
            {accreditations['P2'] && (
                <Rect css={{ left: '421px', top: '280px' }}>
                    <Box>P2</Box>
                </Rect>
            )}
            {accreditations['P3'] && (
                <Rect css={{ left: '421px', top: '280px' }}>
                    <Box>P3</Box>
                </Rect>
            )}
            {accreditations['P4'] && (
                <Rect css={{ left: '421px', top: '280px' }}>
                    <Box>P4</Box>
                </Rect>
            )}
            {accreditations['H-30'] && (
                <Box
                    css={{
                        backgroundColor: accreditations['H-30'],
                        color: '#FFF',
                        fontWeight: 600,
                        left: '698px',
                        lineHeight: '14px',
                        padding: '2px 4px',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '307px'
                    }}
                >
                    H-30
                </Box>
            )}
            {accreditations['PC'] && (
                <Box
                    css={{
                        backgroundColor: accreditations['PC'],
                        color: '#FFF',
                        fontWeight: 600,
                        left: '746px',
                        lineHeight: '14px',
                        padding: '2px 4px',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '307px'
                    }}
                >
                    PC
                </Box>
            )}
            {accreditations['ZONE 1 - PELOUSE'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 1 - PELOUSE'],
                        left: '421px',
                        top: '337px'
                    }}
                >
                    <Box>1</Box>
                    <RectTitle>PELOUSE</RectTitle>
                </Rect>
            )}
            {accreditations['ZONE 2 - VESTIAIRES'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 2 - VESTIAIRES'],
                        left: '541px',
                        top: '337px'
                    }}
                >
                    <Box>2</Box>
                    <RectTitle>VESTIAIRE</RectTitle>
                </Rect>
            )}
            {accreditations['ZONE 3 - TRIBUNES'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 3 - TRIBUNES'],
                        left: '661px',
                        top: '337px'
                    }}
                >
                    <Box>3</Box>
                    <RectTitle>TRIBUNES</RectTitle>
                </Rect>
            )}
            {accreditations['ZONE 4 - MEDIAS'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 4 - MEDIAS'],
                        left: '421px',
                        top: '387px'
                    }}
                >
                    <Box>4</Box>
                    <RectTitle>MEDIAS</RectTitle>
                </Rect>
            )}
            {accreditations['ZONE 5 - AIRE REGIE TV'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 5 - AIRE REGIE TV'],
                        left: '541px',
                        top: '387px'
                    }}
                >
                    <Box>5</Box>
                    <RectTitle>AIRE REGIE TV</RectTitle>
                </Rect>
            )}
            {accreditations['ZONE 6 - HOSPITALITES'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 6 - HOSPITALITES'],
                        left: '661px',
                        top: '387px'
                    }}
                >
                    <Box>6</Box>
                    <RectTitle>HOSPITALITÉS</RectTitle>
                </Rect>
            )}
            {accreditations['ZONE 7 - SERVICES'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 7 - SERVICES'],
                        left: '421px',
                        top: '437px'
                    }}
                >
                    <Box>7</Box>
                    <RectTitle>SERVICES</RectTitle>
                </Rect>
            )}
            {accreditations['ZONE 8 - BORD TERRAIN'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 8 - BORD TERRAIN'],
                        left: '541px',
                        top: '437px'
                    }}
                >
                    <Box>8</Box>
                    <RectTitle>BORD TERRAIN</RectTitle>
                </Rect>
            )}
            {accreditations['ZONE 9 - PRESIDENTIELLE'] && (
                <Rect
                    css={{
                        backgroundColor: accreditations['ZONE 9 - PRESIDENTIELLE'],
                        left: '661px',
                        top: '437px'
                    }}
                >
                    <Box>9</Box>
                    <RectTitle>PRESIDENTELLE</RectTitle>
                </Rect>
            )}
            {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                <Flex
                    css={{
                        alignItems: 'center',
                        flexDirection: 'column',
                        gap: '4px',
                        left: '478px',
                        position: 'absolute',
                        top: '489px',
                        width: '234px'
                    }}
                >
                    <Box height={28} width={1}>
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/code39?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=28&width=234`}
                            height="100%"
                            width="100%"
                        />
                    </Box>
                    <Box css={{ fontSize: '10px' }}>{vr.weezeventParticipantInfo!.idBarcode}</Box>
                </Flex>
            )}
        </Flex>
    );
};
