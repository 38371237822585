import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    AssignmentInfosQuery,
    PositionId,
    PositionsCategoryId,
    SegmentId,
    TagId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { DateTime } from 'luxon';
import * as React from 'react';
import { AssignmentAppliedFilters } from './assignmentAppliedFilters';
import { AssignmentFilterDropdownMenu } from './assignmentFilterDropdownMenu';

interface IAssignmentFiltersProps {
    date: DateTime | null;
    event: Pick<
        AssignmentInfosQuery['event'],
        'startAt' | 'endAt' | 'positionsCategories' | 'positions' | 'segments'
    >;
    excludeFull: boolean;
    name: string;
    organization: Pick<AssignmentInfosQuery['organization'], 'tags'>;
    positionsCategoriesIds: PositionsCategoryId[] | null;
    positionsIds: PositionId[] | null;
    segmentId: SegmentId | null;
    tagsIds: TagId[] | null;

    setDate(date: DateTime | null): void;
    setExcludeFull(excludeFull: boolean): void;
    setName(name: string): void;
    setPositionsCategoriesIds(positionsCategoriesIds: PositionsCategoryId[] | null): void;
    setPositionsIds(positionsIds: PositionId[] | null): void;
    setSegmentId(segmentId: SegmentId | null): void;
    setTagsIds(tagsIds: TagId[] | null): void;
}

export const AssignmentFilters = (props: IAssignmentFiltersProps) => {
    const translate = useTranslate();
    const [areFiltersOpen, setAreFiltersOpen] = React.useState(false);
    const reset = React.useCallback(() => {
        console.log('reset');

        props.setDate(null);
        props.setExcludeFull(false);
        props.setPositionsCategoriesIds(null);
        props.setPositionsIds(null);
        props.setSegmentId(null);
        props.setTagsIds(null);
    }, [
        props.setDate,
        props.setExcludeFull,
        props.setPositionsCategoriesIds,
        props.setPositionsIds,
        props.setSegmentId,
        props.setTagsIds
    ]);

    return (
        <Flex direction="column" gap="3" width={1}>
            <Flex gap="3">
                <TextInput
                    icon="magnifying-glass"
                    placeholder={translate('rechercher_un_p_70410')}
                    value={props.name}
                    onChange={props.setName}
                />

                <Dropdown
                    isOpen={areFiltersOpen}
                    doNotCloseOnOtherOpen={true}
                    onStateChange={setAreFiltersOpen}
                >
                    <Trigger>
                        <Box>
                            <Button color="white" leftIcon="bars-filter">
                                {translate('filtres_64574')}
                            </Button>
                        </Box>
                    </Trigger>

                    <Menu placement="bottom-end" width={385}>
                        <AssignmentFilterDropdownMenu
                            {...props}
                            close={() => {
                                setAreFiltersOpen(false);
                            }}
                            reset={reset}
                        />
                    </Menu>
                </Dropdown>
            </Flex>

            <AssignmentAppliedFilters {...props} reset={reset} />
        </Flex>
    );
};
