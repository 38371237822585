import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate, Operator, Predicate } from 'common/src/vo/segment';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { RadioText } from '../../designSystem/components/radio';
import { Select } from '../../designSystem/components/select/select';

interface IAddCheckboxFilterProps {
    filterPredicate: FilterPredicate;

    onAdd(predicate: Predicate): void;
}

export const AddCheckboxFilter = (props: IAddCheckboxFilterProps) => {
    const translate = useTranslate();
    const [operator, setOperator] = React.useState<Operator>(
        props.filterPredicate.predicate?.operator ?? 'is'
    );
    const [value, setValue] = React.useState(props.filterPredicate.predicate?.value ?? true);

    return (
        <>
            <RadioText
                state={operator === 'is' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('is');
                }}
            >
                {translate('est_25967')}
            </RadioText>

            {operator === 'is' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <Select value={value} onChange={setValue} parseBoolean={true}>
                            <option value="true">{translate('true')}</option>
                            <option value="false">{translate('false')}</option>
                        </Select>
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'isNot' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('isNot');
                }}
            >
                {translate('n_est_pas_97826')}
            </RadioText>

            {operator === 'isNot' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <Select value={value} onChange={setValue} parseBoolean={true}>
                            <option value="true">{translate('true')}</option>
                            <option value="false">{translate('false')}</option>
                        </Select>
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            {props.filterPredicate.filter.hideEmpty !== true && (
                <>
                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isEmpty' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isEmpty');
                        }}
                    >
                        {translate('est_vide_96759')}
                    </RadioText>

                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isNotEmpty' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isNotEmpty');
                        }}
                    >
                        {translate('n_est_pas_vide_45804')}
                    </RadioText>
                </>
            )}

            <Spacer height="7" />

            <Flex>
                <Button
                    onClick={() => {
                        props.onAdd({
                            operator,
                            value,
                            slug: props.filterPredicate.filter.slug
                        });
                    }}
                >
                    {translate('ajouter_le_filt_54222')}
                </Button>
            </Flex>
        </>
    );
};
