import { Form } from 'common-front/src/components/form/form';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { DatetimeInput } from 'common-front/src/designSystem/form/date/datetimeInput';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FormState } from 'common/src/generated/types';
import {
    FormParametersStateInputService,
    IUpdateFormParametersStateValues
} from 'common/src/input/formParametersStateInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';
import {
    useFormParametersStateQuery,
    useFormParametersStateUpdateMutation
} from '../../../../generated/graphqlHooks';
import { useFormContext } from '../../../show/formContext';

interface IFormParametersStateInnerProps {
    state: FormState;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

const FormParametersStateInner = (props: IFormParametersStateInnerProps) => {
    const translate = useTranslate();
    const { eventId, isSaving } = useFormContext();

    React.useEffect(() => {
        if (isSaving) {
            props.handleSubmit();
        }
    }, [isSaving]);

    return (
        <>
            <OnChange name="form.state">
                {(newState: FormState) => {
                    if (newState !== FormState.ClosedAt) {
                        props.change('form.closedAt', null);
                    }
                }}
            </OnChange>

            <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                {translate('cl_turer_le_for_46483')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('choisir_si_le_f_14519')}</Box>

            <Spacer height="4" />

            <RadioText name="form.state" value={FormState.Open}>
                {translate('ne_pas_cl_turer_46870')}
            </RadioText>

            <Spacer height="3" />

            {eventId && (
                <>
                    <RadioText name="form.state" value={FormState.CloseWhenEventDone}>
                        {translate('cl_turer_le_for_61719')}
                    </RadioText>

                    <Spacer height="3" />
                </>
            )}

            <RadioText name="form.state" value={FormState.Closed}>
                {translate('cl_turer_le_for_10120')}
            </RadioText>

            <Spacer height="3" />

            <RadioText name="form.state" value={FormState.ClosedAt}>
                {translate('cl_turer_le_for_19176')}
            </RadioText>

            {props.state === FormState.ClosedAt && (
                <>
                    <Spacer height="2" />

                    <Flex>
                        <Spacer width="6" />

                        <DatetimeInput name="form.closedAt" />
                    </Flex>
                </>
            )}

            {props.state !== FormState.Open && (
                <>
                    <HorizontalSpacerSeparator height="7" />

                    <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                        {translate('message_de_cl_t_95276')}
                    </Box>

                    <Spacer height="1" />

                    <Box color="gray500">{translate('ce_message_sera_03472')}</Box>

                    <Spacer height="2" />

                    <RichEditor name="form.closedMessage" />
                </>
            )}
        </>
    );
};

export const FormParametersState = () => {
    const { eventId, formId, organizationId, setIsSaving } = useFormContext();
    const { data, loader } = useFormParametersStateQuery({ organizationId, formId });
    const validateService = useService(ValidateService);
    const formParametersStateInput = useService(FormParametersStateInputService);
    const { mutate } = useFormParametersStateUpdateMutation();

    return (
        loader || (
            <Form
                direction="column"
                width={1}
                initialValues={{
                    form: formParametersStateInput.formParametersStateInputDefault(
                        data.organization.form
                    )
                }}
                validate={validateService.validateForForm(
                    formParametersStateInput.updateFormParametersStateSchema()
                )}
                onSubmit={async (values: IUpdateFormParametersStateValues) => {
                    await mutate({
                        organizationId,
                        eventId,
                        formId,
                        form: values.form
                    });

                    setIsSaving(false);
                }}
                render={({ form, handleSubmit, values }) => (
                        <FormParametersStateInner
                            state={values.form.state}
                            change={form.change}
                            handleSubmit={handleSubmit}
                        />
                    )}
            />
        )
    );
};
