import { Flex } from 'common/src/designSystem/components/flex';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Link } from '../../../util/link';

interface IItemProps {
    children: React.ReactNode;
    path: string;

    isActive?(pathname: string): boolean;
    onClick?(): Promise<any>;
}

export const Item = (props: IItemProps) => {
    const location = useLocation();
    const isActive = props.isActive?.(location.pathname) ?? location.pathname === props.path;

    return (
        <Link
            to={props.path}
            onClick={async () => {
                if (props.onClick) {
                    await props.onClick();
                }
            }}
        >
            <Flex
                align="center"
                height={1}
                css={{
                    borderBottom: isActive ? '2px solid $primary700' : '2px solid transparent',
                    borderTop: '2px solid transparent',
                    color: isActive ? '$primary700' : '$gray500',
                    cursor: 'pointer',
                    fontWeight: '$medium',
                    px: '$3'
                }}
            >
                {props.children}
            </Flex>
        </Link>
    );
};
