import { styled } from '@stitches/react';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useHeavent } from '../../hooks/useHeavent';
import { IButtonColor, IButtonSize } from './button';
import { Dropdown } from './dropdown/dropdown';
import { Menu } from './dropdown/menu';
import { Trigger } from './dropdown/trigger';

const StyledButtonDropdown = styled('div', {
    alignItems: 'center',
    borderRadius: '$1',
    cursor: 'pointer',
    display: 'flex',
    fontWeight: '$semiBold',
    overflow: 'hidden',
    userSelect: 'none',
    variants: {
        color: {
            primary: {
                background: '$primary700',
                color: 'white',
                '& > .h-button-dropdown-content': {
                    border: '1px solid $primary700',
                    borderRadius: '$1',
                    borderBottomRightRadius: '0',
                    borderTopRightRadius: '0',
                    borderRight: 'none'
                },
                '& > .h-button-dropdown-icon': {
                    border: '1px solid $primary700',
                    borderRadius: '$1',
                    borderBottomLeftRadius: '0',
                    borderTopLeftRadius: '0',
                    borderLeft: '1px solid $primary800'
                },
                '& > .h-button-dropdown-content:hover': {
                    background: '$primary800',
                    border: '1px solid $primary800',
                    borderRight: 'none'
                },
                '& > .h-button-dropdown-icon:hover': {
                    background: '$primary800',
                    border: '1px solid $primary800'
                }
            },
            white: {
                background: 'white',
                color: '$gray700',
                '& > .h-button-dropdown-content': {
                    border: '1px solid $gray300',
                    borderRadius: '$1',
                    borderBottomRightRadius: '0',
                    borderTopRightRadius: '0',
                    borderRight: 'none'
                },
                '& > .h-button-dropdown-icon': {
                    border: '1px solid $gray300',
                    borderRadius: '$1',
                    borderBottomLeftRadius: '0',
                    borderTopLeftRadius: '0'
                },
                '& > .h-button-dropdown-content:hover': {
                    background: '$gray50',
                    color: '$gray800'
                },
                '& > .h-button-dropdown-icon:hover': {
                    background: '$gray50',
                    color: '$gray800'
                }
            }
        },
        size: {
            sm: {
                height: '32px',
                fontSize: '$textSm'
            },
            md: {
                height: '40px',
                fontSize: '$textSm'
            },
            lg: {
                height: '44px',
                fontSize: '$textMd'
            },
            xl: {
                height: '48px',
                fontSize: '$textMd'
            },
            '2xl': {
                height: '60px',
                fontSize: '$textLg'
            }
        }
    },
    defaultVariants: {
        color: 'primary',
        size: 'md'
    }
});

interface IButtonDropdownProps {
    children: React.ReactNode;
    className?: string;
    color?: Extract<IButtonColor, 'primary' | 'white'>;
    menu: React.ReactNode;
    path?: string;
    size?: IButtonSize;

    onClick?(event: React.MouseEvent<HTMLDivElement>): void;
}

export const ButtonDropdown = React.forwardRef(
    (props: IButtonDropdownProps, ref: React.Ref<HTMLDivElement>) => {
        const { history } = useHeavent();

        return (
            <StyledButtonDropdown color={props.color} size={props.size}>
                <Flex
                    className="h-button-dropdown-content"
                    ref={ref}
                    align="center"
                    height={1}
                    css={{ flex: '1', px: '$4' }}
                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                        if (props.onClick) {
                            props.onClick(event);
                        }

                        if (isNonEmptyString(props.path)) {
                            history.push(props.path);
                        }
                    }}
                >
                    {props.children}
                </Flex>

                <Dropdown>
                    <Trigger>
                        <Flex
                            className="h-button-dropdown-icon"
                            align="center"
                            justify="center"
                            width={40}
                            height={40}
                        >
                            <I icon="chevron-down" />
                        </Flex>
                    </Trigger>

                    <Menu placement="bottom-end" renderOnPortal={true}>
                        {props.menu}
                    </Menu>
                </Dropdown>
            </StyledButtonDropdown>
        );
    }
);
