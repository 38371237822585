import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { AuthInputService, ISignInValues } from 'common/src/input/authInput';
import { ValidateService } from 'common/src/services/validateService';
import { useHistory, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { AuthPage } from './authPage';

interface ISignInEmailProps {
    onSubmit(email: string): Promise<any>;
}

export const SignInEmail = (props: ISignInEmailProps) => {
    const translate = useTranslate();
    const authInput = useService(AuthInputService);
    const validateService = useService(ValidateService);
    const history = useHistory();

    return (
        <AuthPage>
            <Form
                direction="column"
                initialValues={{
                    email: ''
                }}
                validate={(values: ISignInValues) => validateService.validateForForm(authInput.signInSchema())(values)}
                onSubmit={(values: ISignInValues) => props.onSubmit(values.email)}
                render={({ handleSubmit, submitting }) => (
                        <>
                            <Box color="white" fontSize="displaySm" fontWeight="semiBold">
                                {translate('ravi_de_vous_re_24368')}
                            </Box>

                            <Spacer height="3" />

                            <Box color="white" fontSize="textMd">
                                {translate('identifiez_vous_57743')}
                            </Box>

                            <Spacer height="7" />

                            <TextInput
                                autoComplete="email"
                                label={translate('adresse_e_mail_60930')}
                                placeholder={translate('entrez_votre_ad_42451')}
                                name="email"
                                isEmail={true}
                            />

                            <Spacer height="6" />

                            <Button
                                textAlign="center"
                                onClick={handleSubmit}
                                isLoading={submitting}
                            >
                                {translate('connexion_05887')}
                            </Button>

                            <Spacer height="7" />

                            <Flex justify="center" gap="1" width={1}>
                                <Box color="white">{translate('pas_encore_sur_02876')}</Box>

                                <Box
                                    color="white"
                                    css={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        history.push(HeaventPaths.AUTH_SIGN_UP_CREDENTIALS);
                                    }}
                                >
                                    {translate('ouvrez_un_compt_91337')}
                                </Box>
                            </Flex>

                            <Box height={108} />

                            <Box
                                color="primary700"
                                fontSize="textLg"
                                fontWeight="semiBold"
                                textAlign="center"
                            >
                                Besoin de plus d’informations
                                <br />
                                sur Vabene ?
                            </Box>

                            <Spacer height="4" />

                            <Flex justify="center">
                                <Button
                                    color="gray"
                                    onClick={() => {
                                        history.push('/about');
                                    }}
                                >
                                    En savoir plus
                                </Button>
                            </Flex>
                        </>
                    )}
            />
        </AuthPage>
    );
};
