import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { useTagsQuery } from 'common-front/src/generated/graphqlHooks';
import { OrganizationId, TagId, TagsQuery } from 'common/src/generated/types';
import { TagInputService } from 'common/src/input/tagInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { TagCreateModal } from '../../organizationParameters/tags/tagCreateModal';

interface IAssociateTagsComponentProps {
    organizationId: OrganizationId;
    subtitle: string;
    tags: TagsQuery['organization']['tags']['nodes'];
    tagsIds: TagId[];

    onClose(): void;
    onSave(tagsIds: TagId[]): void;
}

const AssociateTagsComponent = (props: IAssociateTagsComponentProps) => {
    const translate = useTranslate();
    const tagInput = useService(TagInputService);
    const [tags, setTags] = React.useState(props.tags);
    const [isTagCreateOpen, setIsTagCreateOpen] = React.useState(false);

    return (
        <FormModal
            buttonText={translate('enregistrer_06519')}
            icon="tag"
            initialValues={{
                tagsIds: props.tagsIds
            }}
            schema={tagInput.associateTagsIdsSchema()}
            title={translate('ajout_d_un_ou_p_17767')}
            subtitle={props.subtitle}
            size="md"
            onClose={props.onClose}
            onSubmit={async (values) => {
                props.onSave(values.tagsIds);
            }}
        >
            {({ form, values }) => (
                    <>
                        <RichSelect
                            isSearchVisible={true}
                            isCreateVisible={true}
                            multiple={true}
                            name="tagsIds"
                            onCreateClick={() => {
                                setIsTagCreateOpen(true);
                            }}
                        >
                            {tags.map((tag) => (
                                    <option key={tag.name} value={tag.id}>
                                        {tag.name}
                                    </option>
                                ))}
                        </RichSelect>

                        {isTagCreateOpen && (
                            <TagCreateModal
                                organizationId={props.organizationId}
                                onClose={() => {
                                    setIsTagCreateOpen(false);
                                }}
                                onSuccess={(tag) => {
                                    setTags([...tags, tag]);

                                    form.change('tagsIds', [...values.tagsIds, tag.id]);
                                }}
                            />
                        )}
                    </>
                )}
        </FormModal>
    );
};

interface IAssociateTagsModalProps {
    organizationId: OrganizationId;
    subtitle: string;
    tagsIds: TagId[];

    onClose(): void;
    onSave(tagsIds: TagId[]): void;
}

export const AssociateTagsModal = (props: IAssociateTagsModalProps) => {
    const { data, loader } = useTagsQuery({ organizationId: props.organizationId });

    return loader ? null : (
        <AssociateTagsComponent
            organizationId={props.organizationId}
            subtitle={props.subtitle}
            tags={data.organization.tags.nodes}
            tagsIds={props.tagsIds}
            onClose={props.onClose}
            onSave={props.onSave}
        />
    );
};
