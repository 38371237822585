import { NoFeature } from 'common-front/src/components/noFeature';
import { Alert } from 'common-front/src/designSystem/components/alert';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Feature } from 'common/src/generated/types';
import { heaventEnvDisplayName } from 'common/src/heaventEnv';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useOrganizationContext } from '../organization/organizationContext';
import { ApiIntegration } from './integration/apiIntegration';
import { Keys } from './keys';

export const Api = () => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const { hasFeature } = useOrganizationContext();

    return (
        <Flex direction="column" height={1} width={1} css={{ padding: '$7' }}>
            <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                {translate('api_et_int_grat_54084')}
            </Box>

            <Spacer height="7" />

            <Tabs>
                <TabList>
                    <Tab path={HeaventPaths.API_API(organizationId, eventId)}>API</Tab>
                    <Tab path={HeaventPaths.API_INTEGRATION(organizationId, eventId)}>
                        {translate('int_grations_88619')}
                    </Tab>
                </TabList>

                <TabPanel path={HeaventPaths.API_API(':organizationId', ':eventId')}>
                    <Spacer height="8" />

                    <Alert leftIcon="circle-exclamation">
                        {translate(`l_api_permet_de_66672`, heaventEnvDisplayName())}
                    </Alert>

                    <Spacer height="7" />

                    {hasFeature(Feature.Api) ? (
                        <Keys />
                    ) : (
                        <Flex
                            align="center"
                            justify="center"
                            height={1}
                            width={1}
                            css={{ flex: '1' }}
                        >
                            <NoFeature icon="code-branch" />
                        </Flex>
                    )}
                </TabPanel>

                <TabPanel path={HeaventPaths.API_INTEGRATION(':organizationId', ':eventId')}>
                    <Spacer height="8" />

                    {hasFeature(Feature.Weezevent) ? (
                        <ApiIntegration eventId={eventId} organizationId={organizationId} />
                    ) : (
                        <Flex
                            align="center"
                            justify="center"
                            height={1}
                            width={1}
                            css={{ flex: '1' }}
                        >
                            <NoFeature icon="code-branch" />
                        </Flex>
                    )}
                </TabPanel>
            </Tabs>
        </Flex>
    );
};
