import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Event } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useEventArchiveMutation } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';

interface IArchiveEventProps {
    event: Pick<Event, 'id' | 'name'>;

    onClose(): void;
    onSuccess(): void;
}

export const EventArchiveModal = (props: IArchiveEventProps) => {
    const translate = useTranslate();
    const { mutate } = useEventArchiveMutation();
    const { reload } = useOrganizationContext();

    return (
        <DeleteModal
            text={translate('archivage_de_31255', props.event.name)}
            subtext={translate('_tes_vous_s_r_d_76724')}
            buttonText={translate('archiver_26131')}
            onDelete={async () => {
                await mutate({
                    eventId: props.event.id
                });

                reload();
                props.onSuccess();
            }}
            onClose={props.onClose}
        />
    );
};
