import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { ALL_EMAILSTATE, ALL_TEXTMESSAGESTATE, CampaignType } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate, Predicate } from 'common/src/vo/segment';
import { uniq } from 'lodash-es';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { RichSelect } from '../../designSystem/components/richSelect/richSelect';

interface IAddCampaignFilterProps {
    filterPredicate: FilterPredicate;

    onAdd(predicate: Predicate): void;
}

export const AddCampaignFilter = (props: IAddCampaignFilterProps) => {
    const translate = useTranslate();
    const [value, setValue] = React.useState<number[]>(
        props.filterPredicate.predicate?.value ?? []
    );
    const [value2, setValue2] = React.useState<string[]>(
        props.filterPredicate.predicate?.value2 ?? []
    );
    const campaigns = props.filterPredicate.filter.campaigns ?? [];
    const selectedCampaignTypes = uniq(
        campaigns.filter(({ id }) => value.includes(id)).map(({ campaignType }) => campaignType)
    );
    const states = uniq([
        'NotSent',
        ...(selectedCampaignTypes.includes(CampaignType.Email) ? ALL_EMAILSTATE : []),
        ...(selectedCampaignTypes.includes(CampaignType.Sms) ? ALL_TEXTMESSAGESTATE : [])
    ]);
    const valid = isNonEmptyArray(value) && isNonEmptyArray(value2);

    return (
        <>
            <RichSelect
                isSearchVisible={true}
                isSelectAllVisible={true}
                multiple={true}
                values={value}
                onChange={setValue}
                placeholder={translate('s_lectionner_un_28439')}
            >
                {campaigns.map((campaign) => (
                        <option key={campaign.id} value={campaign.id}>
                            {campaign.name}
                        </option>
                    ))}
            </RichSelect>

            {value.length > 0 && (
                <>
                    <Spacer height="2" />

                    <RichSelect
                        isSearchVisible={true}
                        isSelectAllVisible={true}
                        multiple={true}
                        values={value2}
                        onChange={setValue2}
                        placeholder={translate('s_lectionner_un_62901')}
                    >
                        {states.map((state) => (
                                <option key={state} value={state}>
                                    {translate(state)}
                                </option>
                            ))}
                    </RichSelect>
                </>
            )}

            <Spacer height="7" />

            <Flex>
                <Button
                    disabled={!valid}
                    onClick={() => {
                        props.onAdd({
                            operator: 'is',
                            value,
                            value2,
                            slug: props.filterPredicate.filter.slug
                        });
                    }}
                >
                    {translate('ajouter_le_filt_54222')}
                </Button>
            </Flex>
        </>
    );
};
