import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { DatetimeInput } from 'common-front/src/designSystem/form/date/datetimeInput';
import { TimeInput } from 'common-front/src/designSystem/form/date/timeInput';
import { FileS3Input } from 'common-front/src/designSystem/form/file/fileS3Input';
import { PhoneInput } from 'common-front/src/designSystem/form/phoneInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { Textarea } from 'common-front/src/designSystem/form/textarea';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { Accept } from 'common-front/src/util/accept';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    ALL_LANGUAGE,
    ALL_SEX,
    FieldProperty,
    FieldType,
    MassEditCustomFieldFragment
} from 'common/src/generated/types';
import { DocumentInputService } from 'common/src/input/documentInput';
import { PhoneInputService } from 'common/src/input/phoneInput';
import { CountriesService } from 'common/src/services/countriesService';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { USABLE_LANGUAGES } from 'common/src/vo/supportedLanguage';
import { DateTime } from 'luxon';
import * as React from 'react';

interface IMassEditFieldInputProps {
    addValuesName: string;
    field: MassEditCustomFieldFragment;
    name: string;
    value: any;

    change(name: string, value: any): void;
}

export const MassEditFieldInput = (props: IMassEditFieldInputProps) => {
    const translate = useTranslate();
    const documentInput = useService(DocumentInputService);
    const phoneInput = useService(PhoneInputService);
    const countriesService = useService(CountriesService);
    const enumToOptions = useEnumToOptions();

    React.useEffect(() => {
        switch (props.field.fieldType) {
            case FieldType.Address:
            case FieldType.Country:
            case FieldType.Language:
            case FieldType.Nationality:
            case FieldType.Sex:
            case FieldType.Text:
            case FieldType.Textarea:
            case FieldType.Time:
                if (typeof props.value !== 'string') {
                    props.change(props.name, '');
                }
                break;
            case FieldType.Date:
            case FieldType.Datetime:
                if (!DateTime.isDateTime(props.value) && !isNonEmptyString(props.value)) {
                    props.change(props.name, DateTime.invalid('Invalid'));
                }
                break;
            case FieldType.Checkbox:
            case FieldType.Validation:
                if (typeof props.value !== 'boolean') {
                    props.change(props.name, false);
                }
                break;
            case FieldType.File:
                if (props.value === null || typeof props.value !== 'object') {
                    props.change(props.name, documentInput.documentInputDefault());
                }
                break;
            case FieldType.Number:
                if (typeof props.value !== 'number') {
                    props.change(props.name, 0);
                }
                break;
            case FieldType.Phone:
                if (props.value === null || typeof props.value !== 'object') {
                    props.change(props.name, phoneInput.basePhoneInputDefault());
                }
                break;
            case FieldType.Select:
                if (props.field.canSelectMultiple && !Array.isArray(props.value)) {
                    props.change(props.name, []);
                } else if (!props.field.canSelectMultiple && typeof props.value !== 'number') {
                    props.change(props.name, '');
                }

                break;
            default:
                assertUnreachable(props.field.fieldType);
        }
    }, [props.field, props.value]);

    switch (props.field.fieldType) {
        case FieldType.Address:
        case FieldType.Text:
            return (
                <TextInput
                    name={props.name}
                    label={translate('nouvelle_valeur_39039', props.field.name)}
                />
            );
        case FieldType.Checkbox:
        case FieldType.Validation:
            return (
                <CheckboxText name={props.name}>
                    {translate('nouvelle_valeur_39039', props.field.name)}
                </CheckboxText>
            );
        case FieldType.Country:
            return (
                <RichSelect
                    isSearchVisible={true}
                    name={props.name}
                    label={translate('nouvelle_valeur_39039', props.field.name)}
                >
                    {countriesService.countriesIdName.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                </RichSelect>
            );
        case FieldType.Date:
            return (
                <DateInput
                    name={props.name}
                    label={translate('nouvelle_valeur_39039', props.field.name)}
                />
            );
        case FieldType.Datetime:
            return (
                <DatetimeInput
                    name={props.name}
                    label={translate('nouvelle_valeur_39039', props.field.name)}
                />
            );
        case FieldType.File: {
            const accept =
                props.field.fieldProperty === FieldProperty.Picture
                    ? [Accept.Images]
                    : [Accept.Images, Accept.Pdf];
            const acl =
                props.field.fieldProperty === FieldProperty.Picture ? 'public-read' : 'private';

            return (
                <FileS3Input
                    accept={accept}
                    acl={acl}
                    prefix={`${props.name}.`}
                    label={translate('nouvelle_valeur_39039', props.field.name)}
                />
            );
        }
        case FieldType.Language:
            if (props.field.fieldProperty === FieldProperty.Language) {
                return (
                    <Select
                        name={props.name}
                        label={translate('nouvelle_valeur_39039', props.field.name)}
                    >
                        <option value="">{translate('s_lectionnez_un_31086')}</option>
                        {enumToOptions(USABLE_LANGUAGES)}
                    </Select>
                );
            } else {
                return (
                    <Select
                        name={props.name}
                        label={translate('nouvelle_valeur_39039', props.field.name)}
                    >
                        <option value="">{translate('s_lectionnez_un_31086')}</option>
                        {enumToOptions(ALL_LANGUAGE)}
                    </Select>
                );
            }
        case FieldType.Nationality:
            return (
                <RichSelect
                    isSearchVisible={true}
                    name={props.name}
                    label={translate('nouvelle_valeur_39039', props.field.name)}
                >
                    {countriesService.nationalitiesIdName.map(({ id, name }) => (
                            <option key={id} value={id}>
                                {name}
                            </option>
                        ))}
                </RichSelect>
            );
        case FieldType.Number:
            return (
                <TextInput
                    name={props.name}
                    label={translate('nouvelle_valeur_39039', props.field.name)}
                    parseInt={true}
                />
            );
        case FieldType.Phone:
            return (
                <PhoneInput
                    prefix={`${props.name}.`}
                    label={translate('nouvelle_valeur_39039', props.field.name)}
                />
            );
        case FieldType.Select:
            if (props.field.canSelectMultiple) {
                return (
                    <>
                        <RichSelect
                            isSearchVisible={true}
                            multiple={true}
                            name={props.name}
                            label={translate('nouvelle_valeur_39039', props.field.name)}
                        >
                            {props.field.values.map(({ id, value }) => (
                                    <option key={id} value={id}>
                                        {value}
                                    </option>
                                ))}
                        </RichSelect>

                        {props.addValuesName && (
                            <>
                                <Spacer height="2" />

                                <CheckboxText name={props.addValuesName}>
                                    {translate('ajouter_les_val_22996')}
                                </CheckboxText>
                            </>
                        )}
                    </>
                );
            } else {
                return (
                    <Select
                        name={props.name}
                        label={translate('nouvelle_valeur_39039', props.field.name)}
                        parseInt={true}
                    >
                        <option value="">{translate('s_lectionnez_un_44130')}</option>
                        {props.field.values.map(({ id, value }) => (
                                <option key={id} value={id}>
                                    {value}
                                </option>
                            ))}
                    </Select>
                );
            }
        case FieldType.Sex:
            return (
                <Select
                    name={props.name}
                    label={translate('nouvelle_valeur_39039', props.field.name)}
                >
                    <option value="">{translate('s_lectionnez_un_43620')}</option>
                    {enumToOptions(ALL_SEX)}
                </Select>
            );
        case FieldType.Textarea:
            return (
                <Textarea
                    name={props.name}
                    label={translate('nouvelle_valeur_39039', props.field.name)}
                />
            );
        case FieldType.Time:
            return (
                <TimeInput
                    name={props.name}
                    label={translate('nouvelle_valeur_39039', props.field.name)}
                />
            );
        default:
            return assertUnreachable(props.field.fieldType);
    }
};
