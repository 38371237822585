import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { SegmentsFolderId, SegmentsFoldersSegmentsFragment } from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { Modal } from '../../designSystem/components/modal';
import { RadioText } from '../../designSystem/components/radio';
import { Select } from '../../designSystem/components/select/select';
import { TextInput } from '../../designSystem/components/textInput';
import { useSegmentCreateMutation, useSegmentUpdateMutation } from '../../generated/graphqlHooks';
import { useHeavent } from '../../hooks/useHeavent';
import { useSegmentsContext } from '../segmentsContext';

interface ISaveSegmentProps {
    segmentsFolders: SegmentsFoldersSegmentsFragment[];
}

export const SaveSegment = (props: ISaveSegmentProps) => {
    const { history, translate } = useHeavent();
    const {
        columns,
        columnsExport,
        eventId,
        getSegmentPath,
        organizationId,
        predicates,
        reload,
        segment,
        segmentId,
        segmentType,
        sort,
        setIsSaveSegmentVisible
    } = useSegmentsContext();
    const onClose = React.useCallback(() => {
        setIsSaveSegmentVisible(false);
    }, [setIsSaveSegmentVisible]);
    const { mutate: segmentUpdate, isLoading: isSegmentUpdateLoading } = useSegmentUpdateMutation();
    const { mutate: segmentCreate, isLoading: isSegmentCreateLoading } = useSegmentCreateMutation();
    const [newSegmentSelected, setNewSegmentSelected] = React.useState(false);
    const [name, setName] = React.useState('');
    const [segmentFolderId, setSegmentFolderId] = React.useState(
        props.segmentsFolders.find((sf) => sf.segments.find((s) => s.id === segmentId))?.id ??
            props.segmentsFolders[0].id
    );
    const valid = newSegmentSelected ? isNonEmptyString(name) : true;

    return (
        <Modal size="md" onClose={onClose}>
            <Box color="primary600" fontSize="displayXs" textAlign="center">
                <I icon="sliders" />
            </Box>

            <Spacer height="7" />

            <Box color="gray900" fontSize="textLg" textAlign="center">
                {translate('enregistrer_une_52986')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500" textAlign="center">
                {translate('sauvegarder_vos_15289')}
            </Box>

            <Spacer height="7" />

            <RadioText
                state={newSegmentSelected ? 'unchecked' : 'checked'}
                onClick={() => {
                    setNewSegmentSelected(false);
                }}
            >
                {translate('enregistrer_les_31603', segment.name)}
            </RadioText>

            <Spacer height="4" />

            <RadioText
                state={newSegmentSelected ? 'checked' : 'unchecked'}
                onClick={() => {
                    setNewSegmentSelected(true);
                }}
            >
                {translate('cr_er_une_nouve_37002')}
            </RadioText>

            {newSegmentSelected && (
                <>
                    <Spacer height="2" />

                    <TextInput
                        value={name}
                        onChange={setName}
                        placeholder={translate('nom_de_votre_no_21748')}
                        hint={translate('cette_vue_sera_83858')}
                    />

                    <Spacer height="4" />

                    <Select
                        label={translate('dans_quel_dossi_07643')}
                        value={segmentFolderId}
                        onChange={(newSegmentFolderId) => {
                            setSegmentFolderId(newSegmentFolderId as SegmentsFolderId);
                        }}
                        parseInt={true}
                    >
                        {props.segmentsFolders.map((segmentFolder) => (
                                <option key={segmentFolder.id} value={segmentFolder.id}>
                                    {segmentFolder.name}
                                </option>
                            ))}
                    </Select>
                </>
            )}

            <Spacer height="7" />

            <Flex justify="end" gap="3">
                <Button
                    isLoading={isSegmentCreateLoading || isSegmentUpdateLoading}
                    onClick={async () => {
                        if (newSegmentSelected) {
                            const {
                                segmentCreate: { id }
                            } = await segmentCreate({
                                organizationId,
                                eventId,
                                segment: {
                                    segmentFolderId,
                                    segmentType,
                                    name,
                                    predicates,
                                    columns,
                                    sort
                                }
                            });

                            reload();
                            history.push(getSegmentPath(id));
                        } else {
                            await segmentUpdate({
                                organizationId,
                                eventId,
                                segmentId,
                                predicates,
                                columns,
                                columnsExport,
                                sort
                            });

                            reload();
                        }
                    }}
                    disabled={!valid}
                >
                    {translate('enregistrer_06519')}
                </Button>

                <Button color="white" onClick={onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
