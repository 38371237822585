import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { BadgeJardinSonorePhotographePage } from './badgeJardinSonorePhotographePage';
import { JardinSonorePhotograpeAccreditation } from './jardinSonorePhotograpeAccreditation';

interface IBadgeJardinSonorePhotographeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeJardinSonorePhotographe = (props: IBadgeJardinSonorePhotographeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const has10 = vr.accreditationsUsersInfos.some((aui) => aui.accreditation.id === 3039 && aui.accreditationSlot.date?.day === 10);
    const _10Auis = vr.accreditationsUsersInfos.filter(
        (aui) =>
            (aui.accreditation.id === 3041 || aui.accreditation.id === 3040) &&
            aui.accreditationSlot.date?.day === 10
    );
    const has11 = vr.accreditationsUsersInfos.some((aui) => aui.accreditation.id === 3039 && aui.accreditationSlot.date?.day === 11);
    const _11Auis = vr.accreditationsUsersInfos.filter(
        (aui) =>
            (aui.accreditation.id === 3041 || aui.accreditation.id === 3040) &&
            aui.accreditationSlot.date?.day === 11
    );
    const has12 = vr.accreditationsUsersInfos.some((aui) => aui.accreditation.id === 3039 && aui.accreditationSlot.date?.day === 12);
    const _12Auis = vr.accreditationsUsersInfos.filter(
        (aui) =>
            (aui.accreditation.id === 3041 || aui.accreditation.id === 3040) &&
            aui.accreditationSlot.date?.day === 12
    );
    const has13 = vr.accreditationsUsersInfos.some((aui) => aui.accreditation.id === 3039 && aui.accreditationSlot.date?.day === 13);
    const _13Auis = vr.accreditationsUsersInfos.filter(
        (aui) =>
            (aui.accreditation.id === 3041 || aui.accreditation.id === 3040) &&
            aui.accreditationSlot.date?.day === 13
    );

    return (
        <>
            {has10 && (
                <BadgeJardinSonorePhotographePage date={10} userInfo={ui}>
                    {_10Auis.map((aui, index) => (
                            <JardinSonorePhotograpeAccreditation
                                key={index}
                                color={aui.accreditation.color}
                                title={aui.accreditationSlot.name}
                                scene={aui.accreditation.name}
                            />
                        ))}
                </BadgeJardinSonorePhotographePage>
            )}

            {has11 && (
                <BadgeJardinSonorePhotographePage date={11} userInfo={ui}>
                    {_11Auis.map((aui, index) => (
                            <JardinSonorePhotograpeAccreditation
                                key={index}
                                color={aui.accreditation.color}
                                title={aui.accreditationSlot.name}
                                scene={aui.accreditation.name}
                            />
                        ))}
                </BadgeJardinSonorePhotographePage>
            )}

            {has12 && (
                <BadgeJardinSonorePhotographePage date={12} userInfo={ui}>
                    {_12Auis.map((aui, index) => (
                            <JardinSonorePhotograpeAccreditation
                                key={index}
                                color={aui.accreditation.color}
                                title={aui.accreditationSlot.name}
                                scene={aui.accreditation.name}
                            />
                        ))}
                </BadgeJardinSonorePhotographePage>
            )}

            {has13 && (
                <BadgeJardinSonorePhotographePage date={13} userInfo={ui}>
                    {_13Auis.map((aui, index) => (
                            <JardinSonorePhotograpeAccreditation
                                key={index}
                                color={aui.accreditation.color}
                                title={aui.accreditationSlot.name}
                                scene={aui.accreditation.name}
                            />
                        ))}
                </BadgeJardinSonorePhotographePage>
            )}
        </>
    );
};
