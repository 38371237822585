import { FullPageContent } from 'common-front/src/components/mobileFullPage/fullPageContent';
import { FullPageHeader } from 'common-front/src/components/mobileFullPage/fullPageHeader';
import { MobileFullPage } from 'common-front/src/components/mobileFullPage/mobileFullPage';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { Flex } from 'common/src/designSystem/components/flex';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { VolunteersRecentSearchesMobile } from './volunteersRecentSearchesMobile';
import { VolunteersSearchResultMobile } from './volunteersSearchResultMobile';
import { styled } from 'common/src/designSystem/components/stitches';
import { useState } from 'react';

const CancelLink = styled(Flex, {
    color: '$blue600'
});

export const VolunteersSearchMobile = () => {
    const { organizationId, eventId } = useParams();
    const history = useHistory();
    const translate = useTranslate();
    const back = () => {
        history.push(HeaventPaths.VOLUNTEERS(organizationId, eventId));
    };
    const [value, setValue] = useState('');
    const [search, setSearch] = useState('');
    const cancel = () => {
        setValue('');
        setSearch('');
    };
    const replaySearch = (recentSearch: string) => {
        setValue(recentSearch);
        setSearch(recentSearch);
    };

    return (
        <MobileFullPage>
            <FullPageHeader back={back}>
                <Flex gap="3" align="center" css={{ marginRight: '$4', flexGrow: 1 }}>
                    <TextInput
                        icon="search"
                        value={value}
                        onChange={(v) => {
                            setValue(v);
                            if (!v) {
                                setSearch(v);
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                setSearch(value);
                            }
                        }}
                        placeholder={translate('rechercher_un_m_05904')}
                    />
                    {search && (
                        <CancelLink onClick={cancel}>{translate('annuler_48254')}</CancelLink>
                    )}
                </Flex>
            </FullPageHeader>
            <FullPageContent>
                {search ? (
                    <VolunteersSearchResultMobile search={search} />
                ) : (
                    <VolunteersRecentSearchesMobile replaySearch={replaySearch} />
                )}
            </FullPageContent>
        </MobileFullPage>
    );
};
