import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate, Operator, Predicate } from 'common/src/vo/segment';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { RadioText } from '../../designSystem/components/radio';
import { TextInput } from '../../designSystem/components/textInput';

interface IAddNumberFilterProps {
    filterPredicate: FilterPredicate;

    onAdd(predicate: Predicate): void;
}

export const AddNumberFilter = (props: IAddNumberFilterProps) => {
    const translate = useTranslate();
    const [operator, setOperator] = React.useState<Operator>(
        props.filterPredicate.predicate?.operator ?? 'is'
    );
    const [value, setValue] = React.useState(props.filterPredicate.predicate?.value ?? '');
    const [value2, setValue2] = React.useState(props.filterPredicate.predicate?.value2 ?? '');
    const valid = [
        'is',
        'isNot',
        'greaterThan',
        'greaterThanEqual',
        'lessThan',
        'lessThanEqual'
    ].includes(operator)
        ? typeof value === 'number'
        : operator === 'between'
        ? typeof value === 'number' && typeof value2 === 'number'
        : true;

    return (
        <>
            <RadioText
                state={operator === 'is' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('is');
                }}
            >
                {translate('est_25967')}
            </RadioText>

            {operator === 'is' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <TextInput value={value} onChange={setValue} parseInt={true} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'isNot' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('isNot');
                }}
            >
                {translate('n_est_pas_97826')}
            </RadioText>

            {operator === 'isNot' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <TextInput value={value} onChange={setValue} parseInt={true} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'greaterThan' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('greaterThan');
                }}
            >
                {translate('plus_grand_que_28412')}
            </RadioText>

            {operator === 'greaterThan' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <TextInput value={value} onChange={setValue} parseInt={true} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'greaterThanEqual' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('greaterThanEqual');
                }}
            >
                {translate('_gal_ou_plus_gr_76897')}
            </RadioText>

            {operator === 'greaterThanEqual' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <TextInput value={value} onChange={setValue} parseInt={true} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'lessThan' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('lessThan');
                }}
            >
                {translate('plus_petit_que_35846')}
            </RadioText>

            {operator === 'lessThan' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <TextInput value={value} onChange={setValue} parseInt={true} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'lessThanEqual' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('lessThanEqual');
                }}
            >
                {translate('_gal_ou_plus_pe_38400')}
            </RadioText>

            {operator === 'lessThanEqual' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <TextInput value={value} onChange={setValue} parseInt={true} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'between' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('between');
                }}
            >
                {translate('est_entre_born_59665')}
            </RadioText>

            {operator === 'between' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <TextInput value={value} onChange={setValue} parseInt={true} />
                        <Spacer width="3" />
                        <TextInput value={value2} onChange={setValue2} parseInt={true} />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            {props.filterPredicate.filter.hideEmpty !== true && (
                <>
                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isEmpty' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isEmpty');
                        }}
                    >
                        {translate('est_vide_96759')}
                    </RadioText>

                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isNotEmpty' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isNotEmpty');
                        }}
                    >
                        {translate('n_est_pas_vide_45804')}
                    </RadioText>
                </>
            )}

            <Spacer height="7" />

            <Flex>
                <Button
                    disabled={!valid}
                    onClick={() => {
                        props.onAdd({
                            operator,
                            value,
                            value2,
                            slug: props.filterPredicate.filter.slug
                        });
                    }}
                >
                    {translate('ajouter_le_filt_54222')}
                </Button>
            </Flex>
        </>
    );
};
