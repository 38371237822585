import { Blank } from 'common-front/src/components/blank/blank';
import { Alert } from 'common-front/src/designSystem/components/alert';
import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EmailsSenderId, OrganizationId } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEmailsSendersQuery } from '../../../../generated/graphqlHooks';
import { OrganizationEmailsSendersList } from './organizationEmailsSendersList';

interface IOrganizationEmailsSendersProps {
    organizationId: OrganizationId;
}

export const OrganizationEmailsSenders = (props: IOrganizationEmailsSendersProps) => {
    const { history, translate } = useHeavent();
    const { data, loader, reload } = useEmailsSendersQuery({
        organizationId: props.organizationId
    });
    const [selectedEmailSenderId, setSelectedEmailSenderId] = React.useState<EmailsSenderId | null>(
        null
    );
    const invalidEmailsSenders = React.useMemo(() => (data.organization?.emailsSenders ?? []).filter((es) => !es.isValidToSendEmail), [data.organization]);

    React.useEffect(() => {
        if (
            !loader &&
            data.organization.emailsSenders.length > 0 &&
            selectedEmailSenderId === null
        ) {
            setSelectedEmailSenderId(data.organization.emailsSenders[0].id);
        }
    }, [loader, selectedEmailSenderId, data.organization?.emailsSenders, setSelectedEmailSenderId]);

    return loader ? (
        loader
    ) : data.organization.emailsSenders.length === 0 ? (
        <Flex direction="column">
            <Box height={250} width={1}>
                <Blank
                    imageSrc={Svgs.EmailsSendersBlank}
                    title={translate('aucun_metteur_96777')}
                    subtitle={translate('cr_er_des_mett_38995')}
                />
            </Box>

            <Flex justify="center">
                <Button
                    onClick={() => {
                        history.push(
                            HeaventPaths.CREATE_ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                                props.organizationId
                            )
                        );
                    }}
                >
                    {translate('cr_er_un_mette_14030')}
                </Button>
            </Flex>
        </Flex>
    ) : (
        <>
            {isNonEmptyArray(invalidEmailsSenders) && (
                <>
                    <Spacer height="7" />

                    <Alert color="error" leftIcon="circle-exclamation">
                        {translate('veuillez_confir_10865')}
                        <ul>
                            {invalidEmailsSenders.map((emailSender) => <li key={emailSender.id}>{emailSender.fromEmail}</li>)}
                        </ul>
                    </Alert>
                </>
            )}

            <OrganizationEmailsSendersList
                emailsSenders={data.organization.emailsSenders}
                organizationId={props.organizationId}
                reload={reload}
            />
        </>
    );
};
