import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { OnboardingView } from 'common-front/src/components/onboardingView/onboardingView';
import { Button } from 'common-front/src/designSystem/components/button';
import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { useUserContext } from 'common-front/src/userContext';
import { signOut } from 'common-front/src/util/aws/cognito';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import { HeaventEnv } from 'common/src/heaventEnv';
import { EventInputService, ICreateEventOrganizationValues } from 'common/src/input/eventInput';
import { OrganizationInputService } from 'common/src/input/organizationInput';
import { ValidateService } from 'common/src/services/validateService';
import { useHistory, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEventOrganizationCreateMutation } from '../../generated/graphqlHooks';
import { OrganizationForm } from '../../organization/create/organizationForm';
import { TermsForm } from '../../organization/create/termsForm';
import { EventForm } from './eventForm';

export const CreateEventOrganization = () => {
    const translate = useTranslate();
    const eventInput = useService(EventInputService);
    const organizationInput = useService(OrganizationInputService);
    const validateService = useService(ValidateService);
    const { mutate } = useEventOrganizationCreateMutation({ redirectOnSuccess: true });
    const history = useHistory();
    const onboardingViewRef = React.useRef<HTMLDivElement | null>(null);
    const termsAccepted = !!useUserContext().user?.termsAcceptedAt;

    return (
        <Form
            height={1}
            width={1}
            hideErrors={true}
            initialValues={{
                event: eventInput.eventInputDefault(),
                organization: organizationInput.organizationInputDefault()
            }}
            validate={validateService.validateForForm(
                eventInput.eventOrganizationInputSchema(termsAccepted)
            )}
            onSubmit={async (eventOrganization: ICreateEventOrganizationValues) => {
                const {
                    eventOrganizationCreate: { id, organizationId }
                } = await mutate({ eventOrganization });

                history.push(HeaventPaths.EVENT(organizationId, id));
            }}
            onShowErrors={() => {
                if (onboardingViewRef.current) {
                    onboardingViewRef.current.scrollTop = 0;
                }
            }}
            render={({ form, values, handleSubmit }) => (
                    <OnboardingView ref={onboardingViewRef} onClose={signOut}>
                        <FormErrors />

                        <FormBox
                            hideToggle={true}
                            initialIsOpen={true}
                            subtitle={translate('param_trer_votr_94121')}
                            title={translate('dites_en_nous_u_13238')}
                            centerTitleAndSubtitle={true}
                        >
                            <EventForm form={form} values={values} />
                        </FormBox>

                        <FormBox
                            hideToggle={true}
                            initialIsOpen={true}
                            subtitle={translate('ajouter_le_nom_69919')}
                            title={translate('_propos_de_vot_88855')}
                        >
                            <OrganizationForm />
                        </FormBox>

                        <FormBox>
                            {!termsAccepted &&
                                CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer && (
                                    <>
                                        <TermsForm />
                                        <Spacer height="4" />
                                    </>
                                )}
                            <Button onClick={handleSubmit} textAlign="center">
                                {translate('cr_er_l_v_neme_16731')}
                            </Button>
                        </FormBox>
                    </OnboardingView>
                )}
        />
    );
};
