import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { noop } from 'lodash-es';
import * as React from 'react';
import { Button } from '../components/button';
import { RightPanel } from '../components/rightPanel/rightPanel';
import { RightPanelBody } from '../components/rightPanel/rightPanelBody';
import { RightPanelFooter } from '../components/rightPanel/rightPanelFooter';
import { RightPanelHeader } from '../components/rightPanel/rightPanelHeader';

export const RightPanels = () => {
    const [isSmallOpen, setIsSmallOpen] = React.useState(false);
    const [isMediumOpen, setIsMediumOpen] = React.useState(false);

    return (
        <Flex direction="column" gap="6">
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Right panels
            </Box>

            <Flex gap="3">
                <Button
                    onClick={() => {
                        setIsSmallOpen(true);
                    }}
                >
                    Open small right panel
                </Button>

                <Button
                    onClick={() => {
                        setIsMediumOpen(true);
                    }}
                >
                    Open medium right panel
                </Button>
            </Flex>

            {isSmallOpen && (
                <RightPanel
                    size="sm"
                    onClose={() => {
                        setIsSmallOpen(false);
                    }}
                >
                    <RightPanelHeader>Small right panel</RightPanelHeader>

                    <RightPanelBody>
                        <Spacer height="6" />

                        <Box color="gray800">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer luctus
                            nec nunc et egestas. Proin vel ornare dui. Ut consectetur sem ut massa
                            mattis eleifend. Sed aliquam vitae mi non consectetur. In lacinia vitae
                            urna accumsan consequat. Cras ac molestie velit, non molestie nulla.
                            Pellentesque aliquet, dui in porttitor iaculis, elit erat fringilla ex,
                            id mattis orci lorem eget massa. Nullam semper dui orci. Morbi non
                            dictum eros. Mauris tincidunt ipsum erat, in venenatis ante bibendum a.
                            Nulla vel eros lacinia, interdum magna vel, finibus est. Aliquam sit
                            amet dapibus enim. Suspendisse aliquam varius odio quis pretium. Mauris
                            fringilla laoreet quam, eu dignissim purus dignissim at. Sed quis
                            ultrices elit. Vestibulum tincidunt lacus quis augue dapibus malesuada.
                            Aenean tempus, tellus dapibus pellentesque vehicula, elit lacus volutpat
                            eros, at sodales metus neque eget neque. Donec tortor nulla, placerat eu
                            bibendum in, venenatis ut ipsum. Etiam at lorem non massa condimentum
                            aliquet ut interdum leo. Aliquam vitae urna nisi. Nam gravida lectus
                            est, commodo lobortis velit sodales ut. Pellentesque consectetur
                            consectetur nisl at feugiat. Vivamus in magna suscipit leo placerat
                            sagittis. Proin luctus risus tellus, a sodales mauris ultricies nec.
                            Mauris eget felis dolor. Aliquam faucibus magna a massa facilisis
                            pulvinar. Phasellus lacinia at erat eget laoreet. Quisque ac hendrerit
                            mauris, sit amet rhoncus ligula. Curabitur nunc purus, gravida a
                            tincidunt vitae, varius eu tellus. Cras vitae porttitor elit. Maecenas
                            faucibus dolor enim, lacinia posuere magna ultricies nec. Nam arcu
                            velit, convallis nec finibus vitae, tincidunt vel lectus. Praesent eros
                            lorem, tincidunt ac lorem interdum, tincidunt laoreet velit. Vestibulum
                            lacinia tempor felis eu aliquam. Phasellus ac mauris fringilla, porta
                            enim vitae, porta tortor. Suspendisse nec urna nec sem gravida dictum
                            quis quis ipsum. Vestibulum mattis lacus vel quam facilisis, in rutrum
                            ante varius. Cras posuere luctus justo eget consectetur. Etiam porta dui
                            non purus imperdiet, sed facilisis velit placerat. Duis et viverra nisi,
                            ac consectetur lorem. Nulla a nisl sed lectus posuere elementum. Morbi
                            suscipit dignissim magna nec rutrum. Fusce nec nisl felis. Interdum et
                            malesuada fames ac ante ipsum primis in faucibus. Mauris vitae neque
                            enim. Fusce nulla neque, efficitur sit amet faucibus quis, ultrices
                            vitae turpis. Duis efficitur leo sit amet turpis porttitor hendrerit.
                            Phasellus id arcu sed tellus dignissim egestas vitae id ex. Quisque urna
                            nunc, sollicitudin hendrerit ipsum vel, dignissim tincidunt sem.
                            Pellentesque efficitur sagittis fermentum. Nunc euismod dapibus cursus.
                            Praesent non mauris in erat malesuada elementum. Nam at suscipit nisi.
                            Fusce mollis gravida erat ut scelerisque. Morbi at felis in erat
                            porttitor tincidunt. Nullam a tortor massa.
                        </Box>

                        <Spacer height="6" />
                    </RightPanelBody>
                </RightPanel>
            )}

            {isMediumOpen && (
                <RightPanel
                    size="md"
                    onClose={() => {
                        setIsMediumOpen(false);
                    }}
                >
                    <RightPanelHeader>Medium right panel</RightPanelHeader>

                    <RightPanelBody>
                        <Spacer height="6" />

                        <Box color="gray800">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer luctus
                            nec nunc et egestas. Proin vel ornare dui. Ut consectetur sem ut massa
                            mattis eleifend. Sed aliquam vitae mi non consectetur. In lacinia vitae
                            urna accumsan consequat. Cras ac molestie velit, non molestie nulla.
                            Pellentesque aliquet, dui in porttitor iaculis, elit erat fringilla ex,
                            id mattis orci lorem eget massa. Nullam semper dui orci. Morbi non
                            dictum eros. Mauris tincidunt ipsum erat, in venenatis ante bibendum a.
                            Nulla vel eros lacinia, interdum magna vel, finibus est. Aliquam sit
                            amet dapibus enim. Suspendisse aliquam varius odio quis pretium. Mauris
                            fringilla laoreet quam, eu dignissim purus dignissim at. Sed quis
                            ultrices elit. Vestibulum tincidunt lacus quis augue dapibus malesuada.
                            Aenean tempus, tellus dapibus pellentesque vehicula, elit lacus volutpat
                            eros, at sodales metus neque eget neque. Donec tortor nulla, placerat eu
                            bibendum in, venenatis ut ipsum. Etiam at lorem non massa condimentum
                            aliquet ut interdum leo. Aliquam vitae urna nisi. Nam gravida lectus
                            est, commodo lobortis velit sodales ut. Pellentesque consectetur
                            consectetur nisl at feugiat. Vivamus in magna suscipit leo placerat
                            sagittis. Proin luctus risus tellus, a sodales mauris ultricies nec.
                            Mauris eget felis dolor. Aliquam faucibus magna a massa facilisis
                            pulvinar. Phasellus lacinia at erat eget laoreet. Quisque ac hendrerit
                            mauris, sit amet rhoncus ligula. Curabitur nunc purus, gravida a
                            tincidunt vitae, varius eu tellus. Cras vitae porttitor elit. Maecenas
                            faucibus dolor enim, lacinia posuere magna ultricies nec. Nam arcu
                            velit, convallis nec finibus vitae, tincidunt vel lectus. Praesent eros
                            lorem, tincidunt ac lorem interdum, tincidunt laoreet velit. Vestibulum
                            lacinia tempor felis eu aliquam. Phasellus ac mauris fringilla, porta
                            enim vitae, porta tortor. Suspendisse nec urna nec sem gravida dictum
                            quis quis ipsum. Vestibulum mattis lacus vel quam facilisis, in rutrum
                            ante varius. Cras posuere luctus justo eget consectetur. Etiam porta dui
                            non purus imperdiet, sed facilisis velit placerat. Duis et viverra nisi,
                            ac consectetur lorem. Nulla a nisl sed lectus posuere elementum. Morbi
                            suscipit dignissim magna nec rutrum. Fusce nec nisl felis. Interdum et
                            malesuada fames ac ante ipsum primis in faucibus. Mauris vitae neque
                            enim. Fusce nulla neque, efficitur sit amet faucibus quis, ultrices
                            vitae turpis. Duis efficitur leo sit amet turpis porttitor hendrerit.
                            Phasellus id arcu sed tellus dignissim egestas vitae id ex. Quisque urna
                            nunc, sollicitudin hendrerit ipsum vel, dignissim tincidunt sem.
                            Pellentesque efficitur sagittis fermentum. Nunc euismod dapibus cursus.
                            Praesent non mauris in erat malesuada elementum. Nam at suscipit nisi.
                            Fusce mollis gravida erat ut scelerisque. Morbi at felis in erat
                            porttitor tincidunt. Nullam a tortor massa.
                        </Box>

                        <Spacer height="6" />
                    </RightPanelBody>

                    <RightPanelFooter>
                        <Flex justify="end" gap="3">
                            <Button onClick={noop}>Créer</Button>

                            <Button
                                color="white"
                                onClick={() => {
                                    setIsMediumOpen(false);
                                }}
                            >
                                Annuler
                            </Button>
                        </Flex>
                    </RightPanelFooter>
                </RightPanel>
            )}
        </Flex>
    );
};
