import { Box } from 'common/src/designSystem/components/box';
import { styled } from 'common/src/designSystem/components/stitches';
import { PageInfo } from 'common/src/generated/types';
import * as React from 'react';
import { Button } from '../../../designSystem/components/button';

const _Box = styled(Box, {
    [`${Button} + .load-more-status`]: {
        paddingTop: '12px'
    }
});

interface LoadMoreControlProps {
    loadMore: () => void;
    pageInfo: PageInfo;
    isLoading: boolean;
    buttonText: string;
    statusText: string;
}

export const LoadMoreControl = ({
    loadMore,
    pageInfo,
    isLoading,
    buttonText,
    statusText
}: LoadMoreControlProps) => {
    if (pageInfo.hasNextPage === false && pageInfo.hasPreviousPage === false) {
        return null;
    }

    return (
        <_Box>
            {pageInfo.hasNextPage && (
                <Button onClick={loadMore} isLoading={isLoading} textAlign="center">
                    {buttonText}
                </Button>
            )}
            <Box className="load-more-status" textAlign="center" fontSize="textSm">
                {statusText}
            </Box>
        </_Box>
    );
};
