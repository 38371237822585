import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IParisAeroportBadgeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const ParisAeroportBadge = (props: IParisAeroportBadgeProps) => {
    const vr = props.event.volunteerRegistration;

    return (
        <Flex
            height={A4_SIZES['96dpi'].width}
            width={A4_SIZES['96dpi'].height}
            css={{
                background: `url(https://assets.recrewteer.com/badges/parisAeroport/background.jpg) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Box
                height={121}
                width={121}
                css={{
                    left: '960px',
                    position: 'absolute',
                    top: '643px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=80&width=80`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
