import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { styled } from 'common/src/designSystem/components/stitches';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

const _Toggle = styled('div', {
    alignItems: 'center',
    borderRadius: '12px',
    cursor: 'pointer',
    display: 'flex',
    position: 'relative',
    '& > div': {
        background: 'white',
        boxShadow: '$sm',
        position: 'absolute',
        transition: 'left 200ms ease-in'
    },
    variants: {
        size: {
            sm: {
                height: '16px',
                width: '30px',
                '& > div': {
                    borderRadius: '12px',
                    height: '12px',
                    width: '12px'
                }
            },
            md: {
                height: '24px',
                width: '44px',
                '& > div': {
                    borderRadius: '20px',
                    height: '20px',
                    width: '20px'
                }
            }
        },
        state: {
            unchecked: {
                background: '$gray200',
                '& > div': {
                    left: '2px'
                }
            },
            checked: {
                background: '$primary600'
            }
        }
    },
    compoundVariants: [
        {
            size: 'sm',
            state: 'checked',
            css: {
                '& > div': {
                    left: `calc(100% - 2px - 12px)`
                }
            }
        },
        {
            size: 'md',
            state: 'checked',
            css: {
                '& > div': {
                    left: `calc(100% - 2px - 20px)`
                }
            }
        }
    ],
    defaultVariants: {
        size: 'sm'
    }
});

export type IToggleSize = 'sm' | 'md';

interface IToggleProps {
    size?: IToggleSize;
    value: boolean;

    onChange(value: boolean): void;
}

export const Toggle = (props: IToggleProps) => (
        <_Toggle
            size={props.size}
            state={props.value ? 'checked' : 'unchecked'}
            onClick={() => {
                props.onChange(!props.value);
            }}
        >
            <div />
        </_Toggle>
    );

interface IToggleTextProps extends IToggleProps {
    children: React.ReactNode;
    subtext?: string;
}

export const ToggleText = (props: IToggleTextProps) => {
    const hasSubText = isNonEmptyString(props.subtext);

    return (
        <Flex
            align="center"
            css={{ cursor: 'pointer', userSelect: 'none' }}
            onClick={() => {
                props.onChange(!props.value);
            }}
        >
            <Toggle
                size={props.size}
                value={props.value}
                onChange={() => {
                    // do nothing
                }}
            />

            <Spacer width="2" />

            <Flex direction="column" css={{ fontSize: props.size === 'md' ? '$3' : '$2' }}>
                <Box color="gray800" fontWeight="medium">
                    {props.children}
                </Box>

                {hasSubText && <Box color="gray500">{props.subtext}</Box>}
            </Flex>
        </Flex>
    );
};
