import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    OrganizationTranslationsStatsQuery,
    TranslationModule,
    TranslationStatus
} from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useOrganizationTranslationsQuery } from '../../generated/graphqlHooks';
import { OrganizationTranslation } from './organizationTranslation';
import { OrganizationTranslationsFilters } from './organizationTranslationsFilters';
import { OrganizationTranslationSkeleton } from './organizationTranslationSkeleton';
import { OrganizationTranslationsKpis } from './organizationTranslationsKpis';

interface IOrganizationTranslationsProps {
    organization: OrganizationTranslationsStatsQuery['organization'];
}

export const OrganizationTranslations = (props: IOrganizationTranslationsProps) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const [content, contentDebounced, setContent] = useStateDebounce('');
    const [eventsIds, setEventsIds] = React.useState<EventId[]>([]);
    const [translationStatus, setTranslationStatus] = React.useState<TranslationStatus>(
        TranslationStatus.TranslatedAndNotTranslated
    );
    const [modules, setModules] = React.useState<TranslationModule[]>([]);
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading } = useOrganizationTranslationsQuery({
        organizationId,
        content: isNonEmptyString(contentDebounced) ? contentDebounced : null,
        eventsIds,
        modules,
        status: translationStatus,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.organization?.translations);
    const translations = data.organization?.translations.nodes ?? [];

    return (
        <Flex direction="column" height={1} css={{ overflowY: 'auto' }}>
            <Flex direction="column" css={{ borderBottom: '1px solid $gray200', padding: '$6 $7' }}>
                <Box font="gray900 textXl medium">{translate('traductions_88349')}</Box>
                <Box color="gray500">{translate('g_rer_toutes_le_39107')}</Box>
            </Flex>

            <Flex direction="column" css={{ background: '$gray50', flex: '1', padding: '$7' }}>
                <OrganizationTranslationsKpis organization={props.organization} />

                <Spacer height="7" />

                <OrganizationTranslationsFilters
                    content={content}
                    eventsIds={eventsIds}
                    modules={modules}
                    organization={props.organization}
                    translationStatus={translationStatus}
                    setContent={setContent}
                    setEventsIds={setEventsIds}
                    setModules={setModules}
                    setTranslationStatus={setTranslationStatus}
                />

                <Spacer height="4" />

                {isLoading ? (
                    <>
                        <OrganizationTranslationSkeleton isFirst={true} isLast={false} />
                        <OrganizationTranslationSkeleton isFirst={false} isLast={false} />
                        <OrganizationTranslationSkeleton isFirst={false} isLast={true} />
                    </>
                ) : (
                    translations.map((translation, index) => (
                            <OrganizationTranslation
                                key={translation.id}
                                isFirst={index === 0}
                                isLast={index === translations.length - 1}
                                languages={props.organization.languages}
                                translation={translation}
                            />
                        ))
                )}

                <Spacer height="4" />

                <PaginationCell
                    numberOfPages={numberOfPages}
                    offset={offset}
                    totalCount={totalCount}
                    setOffset={setOffset}
                />
            </Flex>
        </Flex>
    );
};
