import { Button } from 'common-front/src/designSystem/components/button';
import { OrderRightPanel } from 'common-front/src/designSystem/components/orderRightPanel';
import { RadioText } from 'common-front/src/designSystem/components/radio';
import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { DelegationEventInfosQuery, SegmentCustomFieldFragment } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { DelegationMembersSegmentsService } from 'common/src/vo/segments/delegationMembersSegmentsService';
import { compact, sortBy, without } from 'lodash-es';
import * as React from 'react';

interface ICreateDelegationParametersProps {
    customFields: SegmentCustomFieldFragment[];
    delegationType: 'event' | 'organization';
    forms: DelegationEventInfosQuery['event']['forms'];
    initialValues: ICreateUpdateDelegationValues;
    values: ICreateUpdateDelegationValues;

    change(name: string, value: any): void;
}

export const CreateDelegationParameters = (props: ICreateDelegationParametersProps) => {
    const translate = useTranslate();
    const delegationMembersSegmentsService = useService(DelegationMembersSegmentsService);
    const forms = React.useMemo(() => sortBy(props.forms, (f) => f.name), [props.forms]);
    const [isTimeLimited, setIsTimeLimited] = React.useState(
        !!props.initialValues.delegation.deadlineDate
    );
    const [isLimited, setIsLimited] = React.useState(!!props.initialValues.delegation.maxResources);
    const [areColumnsOpen, setAreColumnsOpen] = React.useState(false);
    const possibleColumns = React.useMemo(() => delegationMembersSegmentsService.getDelegationMembersPossibleColumns(
            props.customFields,
            { forEvent: props.delegationType === 'event' }
        ), [delegationMembersSegmentsService, props.customFields, props.delegationType]);
    const columns = React.useMemo(() => {
        const delegation = props.values.delegation;

        return compact(
            delegation.columns.flatMap((slug) => {
                const column = possibleColumns.find((c) => c.slug === slug);

                if (column) {
                    return [
                        <Badge
                            key={column.slug}
                            color="gray"
                            size="md"
                            rightIcon="xmark"
                            cursor="pointer"
                            onRightIconClick={() => {
                                props.change(
                                    'delegation.columns',
                                    without(delegation.columns, column.slug)
                                );
                            }}
                        >
                            {column.name}
                        </Badge>
                    ];
                } else {
                    return [];
                }
            })
        );
    }, [possibleColumns, props.values.delegation, props.change]);

    return (
        <>
            <Flex direction="column">
                <RichSelect
                    isSearchVisible={true}
                    label={translate('avec_quel_formu_60160')}
                    name="delegation.formId"
                    placeholder={translate('s_lectionnez_un_77066')}
                >
                    {forms.map((form) => (
                            <option key={form.id} value={form.id}>
                                {form.name}
                            </option>
                        ))}
                </RichSelect>

                <Spacer height="6" />

                <Box font="gray800 textSm semiBold">{translate('les_inscription_64653')}</Box>

                <Spacer height="2" />

                <RadioText
                    state={isTimeLimited ? 'checked' : 'unchecked'}
                    onClick={() => {
                        setIsTimeLimited(true);
                    }}
                >
                    {translate('oui_il_y_a_une_68479')}
                </RadioText>

                {isTimeLimited && (
                    <>
                        <Spacer height="2" />

                        <DateInput name="delegation.deadlineDate" />

                        <Spacer height="3" />
                    </>
                )}

                <Spacer height="3" />

                <RadioText
                    state={isTimeLimited ? 'unchecked' : 'checked'}
                    onClick={() => {
                        setIsTimeLimited(false);
                        props.change('delegation.deadlineDate', null);
                    }}
                >
                    {translate('non_les_inscri_87979')}
                </RadioText>

                <Spacer height="6" />

                <Box font="gray800 textSm semiBold">{translate('les_inscription_76218')}</Box>

                <Spacer height="2" />

                <RadioText
                    state={isLimited ? 'checked' : 'unchecked'}
                    onClick={() => {
                        setIsLimited(true);
                    }}
                >
                    {translate('oui_il_y_a_une_02976')}
                </RadioText>

                {isLimited && (
                    <>
                        <Spacer height="2" />

                        <TextInput name="delegation.maxResources" parseInt={true} />

                        <Spacer height="3" />
                    </>
                )}

                <Spacer height="3" />

                <RadioText
                    state={isLimited ? 'unchecked' : 'checked'}
                    onClick={() => {
                        setIsLimited(false);
                        props.change('delegation.maxResources', null);
                    }}
                >
                    {translate('non_les_inscri_07566')}
                </RadioText>

                <Spacer height="6" />

                <Box font="gray800 textSm semiBold">{translate('liste_des_colon_20101')}</Box>

                <Spacer height="2" />

                <Flex align="center" gap="4">
                    <Button
                        color="white"
                        size="sm"
                        onClick={() => {
                            setAreColumnsOpen(true);
                        }}
                    >
                        {translate('_diter_62574')}
                    </Button>

                    <Flex gap="2">{columns}</Flex>
                </Flex>
            </Flex>

            {areColumnsOpen && (
                <OrderRightPanel
                    columns={possibleColumns}
                    initialSelectedColumns={props.values.delegation.columns}
                    saveButtonText={translate('_diter_62574')}
                    searchPlaceholder={translate('rechercher_un_c_86475')}
                    subtitle={translate('personnaliser_l_96096')}
                    title={translate('_dition_des_col_70300')}
                    onClose={() => {
                        setAreColumnsOpen(false);
                    }}
                    onSave={(newColumns: string[]) => {
                        props.change('delegation.columns', newColumns);
                    }}
                />
            )}
        </>
    );
};
