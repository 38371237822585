import { chunk, intersection, sortBy } from 'lodash-es';
import * as React from 'react';
import { DocumentPositionsVolunteersPlanningFragment } from '../../generated/types';
import { splitRangesByDay } from '../../util/interval';
import { PositionsVolunteersPlanningPage } from './positionsVolunteersPlanningPage';

const NUMBER_OF_SLOTS_PER_PAGE = 8;
const NUMBER_OF_MEMBERS_PER_PAGE = 10;

interface IPositionsVolunteersPlanningProps {
    event: DocumentPositionsVolunteersPlanningFragment;
}

export const PositionsVolunteersPlanning = (props: IPositionsVolunteersPlanningProps) => {
    const slots = sortBy(
        splitRangesByDay(props.event.positionsSlots.nodes),
        (ps) => ps.range.start
    );
    const vrs = props.event.volunteersRegistrations.nodes;

    return (
        <>
            {chunk(slots, NUMBER_OF_SLOTS_PER_PAGE).map((slotsChunk, slosChunkIndex) => {
                const slotsIds = slotsChunk.map((ps) => ps.id);
                const volunteersRegistrations = vrs.filter((volunteerRegistration) => {
                    const volunteerSlotsIds = volunteerRegistration.positionsSlotsUsersInfos.map(
                        (psui) => psui.positionSlotId
                    );

                    return intersection(slotsIds, volunteerSlotsIds).length > 0;
                });

                return chunk(volunteersRegistrations, NUMBER_OF_MEMBERS_PER_PAGE).map(
                    (vrsChunk, vrChunkIndex) => (
                            <PositionsVolunteersPlanningPage
                                key={`${slosChunkIndex}-${vrChunkIndex}`}
                                eventName={props.event.name}
                                segmentName={props.event.segment.name}
                                slots={slotsChunk}
                                volunteersRegistrations={vrsChunk}
                            />
                        )
                );
            })}
        </>
    );
};
