import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { FormMissions } from 'common-front/src/forms/missions/formMissions';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UserUpdateLayout } from 'common-front/src/users/update/userUpdateLayout';
import { IUserUpdateMissionsValues } from 'common-front/src/users/updateMissions/userUpdateMissionsValues';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    RegisterPositionFilter,
    RegisterSlotDisplay,
    UserUpdateMissionsInfosQuery
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

interface IUserUpdateMissionsProps {
    event: UserUpdateMissionsInfosQuery['event'];
    forms: UserUpdateMissionsInfosQuery['event']['forms'];
    isLoading: boolean;
    userInfo: UserUpdateMissionsInfosQuery['organization']['userInfo'];
    values: IUserUpdateMissionsValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const UserUpdateMissions = (props: IUserUpdateMissionsProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();

    return (
        <UserUpdateLayout
            forms={props.forms}
            isLoading={props.isLoading}
            origin="event"
            title={translate('mise_jour_des_02859', props.userInfo.nameOrEmail)}
            getTabPath={(formId) => HeaventPaths.EDIT_USER_MISSIONS_FORM(
                    organizationId,
                    eventId,
                    userInfoId,
                    formId
                )}
            onCancel={() => {
                history.push(HeaventPaths.VOLUNTEERS(organizationId, eventId));
            }}
            onUpdate={props.handleSubmit}
        >
            {props.forms.map((form, index) => (
                    <TabPanel
                        key={form.id}
                        path={HeaventPaths.EDIT_USER_MISSIONS_FORM(
                            organizationId,
                            eventId,
                            userInfoId,
                            form.id
                        )}
                    >
                        <Spacer height="7" />

                        <FormMissions
                            event={props.event}
                            options={{
                                ...form,
                                showFullPosition: true,
                                slotDisplay: RegisterSlotDisplay.Hide,
                                positionFilter: RegisterPositionFilter.None
                            }}
                            positionsCategoriesIds={
                                props.values.userInfo.missions[index].positionsCategoriesIds
                            }
                            positionsIds={props.values.userInfo.missions[index].positionsIds}
                            positionsSlotsIds={
                                props.values.userInfo.missions[index].positionsSlotsIds
                            }
                            prefix={`userInfo.missions[${index}].`}
                            slots={[]}
                            userInfoFields={props.userInfo.fields}
                            change={props.change}
                        />
                    </TabPanel>
                ))}
        </UserUpdateLayout>
    );
};
