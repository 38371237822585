import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (population: string) => {
    switch (population) {
        case 'ADMINISTRATEUR':
            return `https://assets.recrewteer.com/badges/blotRennes/ADMINISTRATEUR@2x.jpg`;
        case 'MÉDIAS - JOURNALISTE':
            return `https://assets.recrewteer.com/badges/blotRennes/JOURNALISTE@2x.jpg`;
        case 'MÉDIAS - PHOTOGRAPHE':
            return `https://assets.recrewteer.com/badges/blotRennes/PHOTOGRAPHE@2x.jpg`;
        case 'CHAUFFEUR':
            return `https://assets.recrewteer.com/badges/blotRennes/CHAUFFEUR@2x.jpg`;
        case 'RAMASSEUR DE BALLES':
            return `https://assets.recrewteer.com/badges/blotRennes/RAMASSEUR_DE_BALLES@2x.jpg`;
        case 'JUGE DE LIGNE':
            return `https://assets.recrewteer.com/badges/blotRennes/JUGE_DE_LIGNE@2x.jpg`;
        case 'OFFICIEL ATP':
            return `https://assets.recrewteer.com/badges/blotRennes/OFFICIEL_ATP@2x.jpg`;
        case 'ORGANISATION':
            return `https://assets.recrewteer.com/badges/blotRennes/ORGANISATION@2x.jpg`;
        case 'PRESTATAIRE':
            return `https://assets.recrewteer.com/badges/blotRennes/PRESTATAIRE@2x.jpg`;
        case 'JOUEUR':
            return `https://assets.recrewteer.com/badges/blotRennes/JOUEUR@2x.jpg`;
        case 'COACH':
            return `https://assets.recrewteer.com/badges/blotRennes/COACH@2x.jpg`;
        case 'GUEST':
            return `https://assets.recrewteer.com/badges/blotRennes/GUEST@2x.jpg`;
        case 'SUPERVISEUR':
            return `https://assets.recrewteer.com/badges/blotRennes/SUPERVISEUR@2x.jpg`;
        case 'VIP LOGES Fast Pass':
            return `https://assets.recrewteer.com/badges/blotRennes/VIP_LOGES_Fast_Pass@2x.jpg`;
        case 'VIP LOGES':
            return `https://assets.recrewteer.com/badges/blotRennes/VIP_LOGES@2x.jpg`;
        case 'PARTENAIRE':
            return `https://assets.recrewteer.com/badges/blotRennes/VIP@2x.jpg`;
        default:
            return `https://assets.recrewteer.com/badges/blotRennes/STAFF@2x.jpg`;
    }
};

const get1stLine = (ui: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo']) => {
    switch (ui.blotPopulation) {
        case 'CHAUFFEUR':
        case 'COACH':
        case 'GUEST':
        case 'JOUEUR':
        case 'JUGE DE LIGNE':
        case 'RAMASSEUR DE BALLES':
        case 'BÉNÉVOLES':
        case 'SUPERVISEUR':
            return ui.firstName;
        default:
            return ui.name;
    }
};

const get2ndLine = (ui: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo']) => {
    switch (ui.blotPopulation) {
        case 'MÉDIAS - JOURNALISTE':
        case 'MÉDIAS - PHOTOGRAPHE':
        case 'PRESTATAIRE':
        case 'VIP LOGES Fast Pass':
        case 'VIP LOGES':
        case 'PARTENAIRE':
            return ui.fields.cf31319;
        case 'CHAUFFEUR':
        case 'COACH':
        case 'GUEST':
        case 'JOUEUR':
        case 'JUGE DE LIGNE':
        case 'RAMASSEUR DE BALLES':
        case 'BÉNÉVOLES':
        case 'SUPERVISEUR':
            return (ui.lastName as string).toUpperCase();
        case 'OFFICIEL ATP':
        case 'ORGANISATION':
            return ui.fields.cf36132;
        default: // ADMINISTRATEUR
            return ui.blotFonction;
    }
};

export const BadgeBlotRennes = ({ event }: { event: DocumentUserCustomBadgeFragment }) => {
    const vr = event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = ui.blotPopulation || 'STAFF';

    return (
        <>
            <Flex
                height={326}
                width={208}
                css={{
                    background: `url(${getBackground(population)}) no-repeat`,
                    backgroundSize: 'cover',
                    color: '#141631',
                    fontFamily: '$gothamBold',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    position: 'relative'
                }}
            >
                <Box
                    height={80}
                    width={80}
                    css={{
                        borderRadius: '4px',
                        border: '1px solid #FFF',
                        display: 'flex',
                        justifyContent: 'center',
                        left: '61px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '173px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img
                            src={ui.picture!.url}
                            style={{
                                objectFit: 'cover',
                                width: '100%'
                            }}
                        />
                    )}
                </Box>
                <Box
                    width={1}
                    css={{
                        height: '13px',
                        lineHeight: '16px',
                        overflow: 'hidden',
                        position: 'absolute',
                        textAlign: 'center',
                        textOverflow: 'ellipsis',
                        top: '289px',
                        whiteSpace: 'nowrap'
                    }}
                >
                    {get1stLine(ui)}
                </Box>
                <Box
                    width={1}
                    css={{
                        height: '13px',
                        lineHeight: '16px',
                        overflow: 'hidden',
                        position: 'absolute',
                        textAlign: 'center',
                        textOverflow: 'ellipsis',
                        top: '305px',
                        whiteSpace: 'nowrap'
                    }}
                >
                    {get2ndLine(ui)}
                </Box>
            </Flex>
            <Flex
                height={326}
                width={208}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/blotRennes/verso_v2.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    position: 'relative'
                }}
            >
                <Box
                    height={90}
                    width={90}
                    css={{
                        left: '59px',
                        position: 'absolute',
                        top: '115px'
                    }}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=90&width=90`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>
            </Flex>
        </>
    );
};
