import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeCroixRougeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeCroixRouge = (props: IBadgeCroixRougeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/croixrouge/background_v3.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$poppins',
                fontSize: '18px',
                position: 'relative'
            }}
        >
            <Box
                height={148}
                width={148}
                css={{
                    background: 'white',
                    left: '123px',
                    position: 'absolute',
                    top: '201px'
                }}
            >
                {isNonEmptyString(ui.fields.cf25139) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            ui.fields.cf25139
                        )}&color=000000&height=148&width=148`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>

            <Box
                css={{
                    left: '136px',
                    position: 'absolute',
                    top: '412px'
                }}
            >
                {ui.lastName}
            </Box>

            <Box
                css={{
                    left: '166px',
                    position: 'absolute',
                    top: '439px'
                }}
            >
                {ui.firstName}
            </Box>

            <Box
                css={{
                    left: '148px',
                    position: 'absolute',
                    top: '464px'
                }}
            >
                {ui.fields.cf25139}
            </Box>
        </Flex>
    );
};
