import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Grid } from '../../../designSystem/components/grid';
import { Spacer } from '../../../designSystem/components/spacer';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A6_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { DinoZone } from './dinoZone';

interface IBadgeDinoA6Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeDinoA6 = (props: IBadgeDinoA6Props) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;

    return (
        <Flex
            direction="column"
            height={A6_SIZES['96dpi'].height}
            width={A6_SIZES['96dpi'].width}
            css={{
                background: '$sucess700',
                fontFamily: 'Montserrat',
                padding: '$4'
            }}
        >
            <Flex
                direction="column"
                height={1}
                width={1}
                css={{ background: 'white', padding: '$5' }}
            >
                <Flex height={80}>
                    {isNonEmptyString(userInfo.fields.picture?.url) && (
                        <Box
                            height={1}
                            width={80}
                            css={{
                                border: '2px solid $success700',
                                borderRadius: '$1',
                                overflow: 'hidden'
                            }}
                        >
                            <img src={userInfo.fields.picture?.url} height="100%" width="100%" />
                        </Box>
                    )}

                    <Spacer width="2" />

                    <Flex
                        direction="column"
                        align="start"
                        justify="center"
                        css={{
                            color: '$success700',
                            flex: '1',
                            fontSize: '12px',
                            fontWeight: '900',
                            lineHeight: '18px',
                            textTransform: 'uppercase'
                        }}
                    >
                        <Box>{userInfo.fields.cf15034 || userInfo.fields.cf15035 || ''}</Box>
                        <Box>{userInfo.fields.firstName}</Box>
                        <Box>{userInfo.fields.lastName}</Box>
                    </Flex>

                    <Spacer width="4" />

                    {isNonEmptyString(vr.utid) ? (
                        <Box height={1} width={80}>
                            <img
                                src={`https://api.${
                                    CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                                }/barcode/qr?text=${encodeURIComponent(vr.utid)}&color=000000`}
                                height="100%"
                                width="100%"
                            />
                        </Box>
                    ) : isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) ? (
                        <Box height={1} width={80}>
                            <img
                                src={`https://api.${
                                    CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                                }/barcode/qr?text=${encodeURIComponent(
                                    vr.weezeventParticipantInfo!.idBarcode
                                )}&color=000000`}
                                height="100%"
                                width="100%"
                            />
                        </Box>
                    ) : null}
                </Flex>

                <Spacer height="8" />

                <Flex align="center" height={100}>
                    <Box height={100} width={100}>
                        <img
                            src="https://assets.recrewteer.com/badges/dino/dinoLogo.png"
                            height="100%"
                            width="100%"
                        />
                    </Box>

                    <Spacer width="6" />

                    <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        css={{
                            color: '$success700',
                            flex: '1',
                            fontSize: '30px',
                            fontWeight: '900',
                            textAlign: 'center',
                            textTransform: 'uppercase'
                        }}
                    >
                        {props.event.name}
                    </Flex>
                </Flex>

                <Spacer height="7" />

                <Grid gridtemplatecolumns="1fr 1fr" gridtemplaterows="1fr 1fr 1fr" gap="2">
                    {vr.accreditationsUsersInfos.map(({ accreditation }, index) => <DinoZone key={index} accreditation={accreditation} />)}
                </Grid>

                <Spacer height="5" />

                <Box height={32} width={1}>
                    <img
                        src="https://assets.recrewteer.com/badges/dino/dinoMontagne.png"
                        width="100%"
                    />
                </Box>

                <Spacer height="3" />

                <Box
                    css={{
                        fontSize: '6px',
                        fontWeight: '700'
                    }}
                >
                    Les titres d'accès au Recrewteer Stadium pour les matchs du Dino Recrewteer :
                </Box>

                <Box
                    css={{
                        fontSize: '6px',
                        fontWeight: '400'
                    }}
                >
                    &bull; Le billet du match permet d'entrer dans le stade et donne accès à une
                    place assise réservée.
                </Box>

                <Spacer height="2" />

                <Box css={{ fontSize: '6px', fontWeight: '400' }}>
                    &bull;{' '}
                    <span style={{ fontWeight: 700 }}>
                        L'accréditation permet uniquement de circuler à l'intérieur du stadium dans
                        les zones réservées et ne donne droit à aucune place assise.
                    </span>
                </Box>

                <Spacer height="2" />

                <Box
                    css={{
                        fontSize: '6px',
                        fontWeight: '400'
                    }}
                >
                    Cette accréditation est la propriété du club Dino Recrewteer, organisateur de
                    l'événement, qui se réserve le doit de vérifier l'identité et la qualité
                    professionnelle du porteur. Ce dernier s'engage à suivre les prescriptions de
                    l'organisateur et à respecter en tout point le règlement intérieur du site. Tout
                    manquement à ces règles entraînera la saisie de l'accréditation et l'expulsion
                    du porteur hors du site.
                </Box>
            </Flex>
        </Flex>
    );
};
