import { TextInput } from 'common-front/src/designSystem/components/textInput';
import {
    EventId,
    SegmentCustomFieldFragment,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { VolunteerRegistrationUpdateCell } from './volunteerRegistrationUpdateCell';

interface IVolunteerRegistrationNumberCellProps {
    customField: SegmentCustomFieldFragment;
    eventId: EventId;
    field: PossibleColumn;
    firstCellCss: any;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
}

export const VolunteerRegistrationNumberCell = (props: IVolunteerRegistrationNumberCellProps) => {
    const fieldService = useService(FieldService);
    const valueString = fieldService.getValueString(
        props.customField,
        props.volunteerRegistration.userInfo
    );
    const initialValue = React.useMemo(() => (
            fieldService.getValue<number>(
                props.customField,
                props.volunteerRegistration.userInfo
            ) || 0
        ), [props.customField, props.volunteerRegistration]);

    return (
        <VolunteerRegistrationUpdateCell
            customField={props.customField}
            eventId={props.eventId}
            field={props.field}
            firstCellCss={props.firstCellCss}
            initialValue={initialValue}
            valueString={valueString}
            volunteerRegistration={props.volunteerRegistration}
            reload={props.reload}
        >
            {(value, setValue) => (
                    <TextInput
                        label={props.customField.name}
                        parseInt={true}
                        value={value}
                        onChange={setValue}
                    />
                )}
        </VolunteerRegistrationUpdateCell>
    );
};
