import { Flex } from 'common/src/designSystem/components/flex';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { ComponentProps, PropsWithChildren } from 'react';

export const FullPageHeaderText = ({
    children,
    css,
    ...props
}: PropsWithChildren<ComponentProps<typeof Flex>>) => (
        <Flex
            direction="column"
            align="center"
            css={
                {
                    flex: 1,
                    marginRight: '66px',
                    ...css
                } as CSS
            }
            {...props}
        >
            {children}
        </Flex>
    );
