import { LimitSource } from 'common-front/src/accreditAssign/accreditationLimitSource';
import { AssignPopup } from 'common-front/src/components/assignPopup';
import { getAccreditationLimits } from 'common-front/src/delegations/accreditations/getAccreditationLimits';
import { Button } from 'common-front/src/designSystem/components/button';
import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useMassActions } from 'common-front/src/hooks/useMassActions';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    AccreditationAvailableVolunteersRegistrationsQuery,
    AccreditationDisplay,
    AccreditationsSlotId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { fullName } from 'common/src/vo/accreditationSlot';
import { noop, sortBy } from 'lodash-es';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
    useAccreditationAvailableVolunteersRegistrationsQuery,
    useVolunteersRegistrationsAccreditMutation
} from '../../generated/graphqlHooks';
import { UserPanel } from '../../users/show/userPanel';

interface IAccreditationsAccreditComponentProps {
    event: AccreditationAvailableVolunteersRegistrationsQuery['event'];

    reload(): void;
}

const AccreditationsAccreditComponent = ({
    event,
    reload
}: IAccreditationsAccreditComponentProps) => {
    const {
        translate,
        history,
        params: { organizationId, eventId, accreditationId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);

    const { mutate, isLoading } = useVolunteersRegistrationsAccreditMutation();

    const [search, setSearch] = React.useState('');
    const [slotId, setSlotId] = React.useState(
        event.accreditation.slots.length > 1
            ? event.accreditation.slots[0].id
            : event.accreditation.hiddenSlotId
    );

    const volunteersRegistrations = React.useMemo(() => {
        const availableVolunteersRegistrations =
            event.accreditation.availableVolunteersRegistrations.filter((vr) => event.accreditation.accreditationDisplay === AccreditationDisplay.Radio
                    ? !(
                          vr.isAccreditedTo &&
                          !isNonEmptyArray(
                              vr.accreditationsUsersInfos.filter(
                                  (as) => as.accreditationSlotId === slotId
                              )
                          )
                      )
                    : true);

        if (isNonEmptyString(search)) {
            return availableVolunteersRegistrations.flatMap((vr) => vr.userInfo.name.toLowerCase().includes(search.toLowerCase()) ||
                    vr.userInfo.email.includes(search.toLowerCase())
                    ? {
                          isAccredited: isNonEmptyArray(
                              vr.accreditationsUsersInfos.filter(
                                  (aui) => aui.accreditationSlotId === slotId
                              )
                          ),
                          ...vr
                      }
                    : []);
        } else {
            return availableVolunteersRegistrations.map((vr) => ({
                    isAccredited: isNonEmptyArray(
                        vr.accreditationsUsersInfos.filter(
                            (aui) => aui.accreditationSlotId === slotId
                        )
                    ),
                    ...vr
                }));
        }
    }, [event, search, slotId]);
    const slots = React.useMemo(() => sortBy(
            event.accreditation.slots.map((slot) => ({
                id: slot.id,
                name: fullName(dateTimeService, slot, event.accreditation.name),
                date: slot.date?.toMillis() ?? -1,
                assignedResources: slot.assignedResources,
                assignedWishedResources: slot.assignedWishedResources,
                maxResources: slot.maxResources
            })),
            ['date', 'name']
        ), [event.accreditation.slots]);
    const volunteersRegistrationsMeta = React.useMemo(() => volunteersRegistrations.map((volunteerRegistration) => {
            const da = volunteerRegistration.delegation?.accreditations.find(
                (da) => da.accreditationSlot.id === slotId
            );
            const slot = slots.find((slot) => slot.id === slotId)!;

            const { assignedResources, limitIcon, limitSource, maxResources } =
                getAccreditationLimits(da, slot);

            return {
                id: volunteerRegistration.id,
                delegationId: volunteerRegistration.delegation?.id,
                assignedResources,
                maxResources,
                limitIcon,
                limitSource,
                isDisabled:
                    !volunteerRegistration.isAccreditedTo && maxResources
                        ? assignedResources >= maxResources
                        : undefined
            };
        }), [volunteersRegistrations, slotId]);

    const {
        numberOfSelected,
        numberOfDelegationSelected,
        numberToAccredit,
        numberToDeaccredit,
        selectAllState,
        states,
        toggleRow,
        toggleSelectAll
    } = useMassActions(
        Object.fromEntries(
            volunteersRegistrations.map((volunteerRegistration) => {
                const { isDisabled } = volunteersRegistrationsMeta.find(
                    (vrm) => vrm.id === volunteerRegistration.id
                )!;

                return [
                    `vr${volunteerRegistration.id}`,
                    {
                        id: volunteerRegistration.id,
                        delegationId: volunteerRegistration.delegation?.id,
                        disabled: isDisabled,
                        isAccredited: volunteerRegistration.isAccredited,
                        state: volunteerRegistration.isAccreditedTo ? 'checked' : 'unchecked'
                    }
                ];
            })
        )
    );

    const isMaxExceeded = React.useMemo(() => isNonEmptyArray(
            volunteersRegistrationsMeta.filter((vrm) => vrm.limitSource
                    ? vrm.limitSource === LimitSource.DelegationAccreditation
                        ? numberOfDelegationSelected!.find(
                              (num) => num.delegationId === vrm.delegationId
                          )!.numberOfSelected > vrm.maxResources
                        : numberOfSelected > vrm.maxResources
                    : false)
        ), [volunteersRegistrationsMeta, numberOfDelegationSelected, numberOfSelected]);
    const hasChangesToMake = numberToAccredit > 0 || numberToDeaccredit > 0;

    return (
        <>
            <AssignPopup
                button={
                    <Button
                        disabled={isMaxExceeded || !hasChangesToMake}
                        isLoading={isLoading}
                        onClick={async () => {
                            const volunteersRegistrationsIds = Object.values(states)
                                .filter(({ state }) => state === 'checked')
                                .map(({ id }) => id);

                            await mutate({
                                eventId,
                                accreditationSlotId: slotId as AccreditationsSlotId,
                                volunteersRegistrationsIds
                            });

                            history.push(
                                HeaventPaths.ACCREDITATION(organizationId, eventId, accreditationId)
                            );
                        }}
                    >
                        {!hasChangesToMake
                            ? translate('no_changes_made')
                            : numberToAccredit > 0 && numberToDeaccredit > 0
                            ? translate(
                                  'accredit_and_deaccredit_members',
                                  numberToAccredit,
                                  numberToDeaccredit
                              )
                            : numberToAccredit > 0
                            ? translate('accr_diter_1_13773', numberToAccredit)
                            : translate('deaccredit', numberToDeaccredit)}
                    </Button>
                }
                popup={{
                    category: translate('Accreditation'),
                    closePath: HeaventPaths.ACCREDITATION(organizationId, eventId, accreditationId),
                    title: translate('accr_ditation_03515', event.accreditation.name)
                }}
            >
                {slots.length > 0 && (
                    <>
                        <Flex
                            direction="column"
                            gap="1"
                            css={{
                                background: 'white',
                                border: '1px solid $gray200',
                                borderRadius: '$2',
                                boxShadow: '$xs',
                                padding: '$5 $6'
                            }}
                        >
                            <Box
                                font="gray500 textXs semiBold"
                                css={{ textTransform: 'uppercase' }}
                            >
                                {translate('sur_quel_cr_nea_07475')}
                            </Box>

                            <RichSelect
                                isSearchVisible={true}
                                onChange={(newSlotId: AccreditationsSlotId[]) => {
                                    if (isNonEmptyArray(newSlotId)) {
                                        setSlotId(newSlotId[0]);
                                    }
                                }}
                                placeholder={translate('choisir_un_cr_n_50945')}
                                values={[slotId]}
                            >
                                {slots.map((slot) => (
                                        <option key={slot.id} value={slot.id}>
                                            {slot.name}
                                        </option>
                                    ))}
                            </RichSelect>
                        </Flex>

                        <Spacer height="5" />
                    </>
                )}

                <TableFilters
                    filters={
                        <Box width={320}>
                            <TextInput
                                value={search}
                                onChange={setSearch}
                                placeholder={translate('rechercher_un_m_05904')}
                                icon="magnifying-glass"
                            />
                        </Box>
                    }
                    headerCells={
                        <>
                            <HeaderCell width={48} css={{ paddingRight: 0 }}>
                                <Checkbox state={selectAllState} onClick={toggleSelectAll} />
                            </HeaderCell>
                            <HeaderCell css={{ paddingLeft: '$2', flexGrow: 2 }}>
                                {translate('nom_du_membre_69353')}
                            </HeaderCell>
                            <HeaderCell>{translate('nombre_d_accr_d_02452')}</HeaderCell>
                            <HeaderCell>{translate('numero_disponible')}</HeaderCell>
                        </>
                    }
                    numberOfPages={1}
                    offset={0}
                    rows={volunteersRegistrations.map((volunteerRegistration) => {
                        const { maxResources, limitIcon, limitSource, isDisabled } =
                            volunteersRegistrationsMeta.find(
                                (vrm) => vrm.id === volunteerRegistration.id
                            )!;
                        const finalNumberOfSelected =
                            limitSource === LimitSource.DelegationAccreditation
                                ? numberOfDelegationSelected?.find(
                                      (ds) =>
                                          ds.delegationId === volunteerRegistration.delegation?.id
                                  )?.numberOfSelected ?? numberOfSelected
                                : numberOfSelected;
                        const finalNumberOfAccreditations =
                            volunteerRegistration.numberOfAccreditations +
                            (states[`vr${volunteerRegistration.id}`].state === 'checked'
                                ? volunteerRegistration.isAccredited
                                    ? 0
                                    : 1
                                : volunteerRegistration.isAccredited
                                ? -1
                                : 0);

                        return (
                            <Row
                                key={volunteerRegistration.id}
                                css={{ cursor: 'pointer', userSelect: 'none' }}
                                onClick={() => {
                                    history.push(
                                        HeaventPaths.ACCREDITATION_ACCREDIT_USER_INFORMATIONS(
                                            organizationId,
                                            eventId,
                                            accreditationId,
                                            volunteerRegistration.userInfo.id
                                        )
                                    );
                                }}
                            >
                                <Cell width={48} css={{ paddingRight: 0 }}>
                                    <Checkbox
                                        state={states[`vr${volunteerRegistration.id}`].state}
                                        disabled={isDisabled}
                                        onClick={(newState, e) => {
                                            e.nativeEvent.stopImmediatePropagation();
                                            e.stopPropagation();

                                            toggleRow(`vr${volunteerRegistration.id}`, newState);
                                        }}
                                    />
                                </Cell>
                                <Cell css={{ paddingLeft: '$2', flexGrow: 2 }}>
                                    <AvatarNameEmail
                                        userInfo={volunteerRegistration.userInfo}
                                        hasName={isNonEmptyString(
                                            volunteerRegistration.userInfo.name
                                        )}
                                    />
                                </Cell>
                                <Cell>{finalNumberOfAccreditations}</Cell>
                                {isNonEmptyArray(
                                    volunteersRegistrations.filter((vr) => vr.delegation)
                                ) && (
                                    <Cell>
                                        {maxResources !== Infinity && (
                                            <>
                                                <Box
                                                    css={{
                                                        color:
                                                            finalNumberOfSelected > maxResources
                                                                ? 'red'
                                                                : 'inherit'
                                                    }}
                                                >
                                                    {`${finalNumberOfSelected}/${maxResources}`}
                                                </Box>

                                                <Spacer width={'2'} />

                                                <Box
                                                    css={{
                                                        color:
                                                            finalNumberOfSelected > maxResources
                                                                ? 'red'
                                                                : 'gray500'
                                                    }}
                                                    fontSize={'textXs'}
                                                >
                                                    {limitIcon}
                                                </Box>
                                            </>
                                        )}
                                    </Cell>
                                )}
                            </Row>
                        );
                    })}
                    title={
                        <Flex direction="column">
                            <Box font="gray900 textMd semiBold">
                                {translate('s_lectionnez_le_75158')}
                            </Box>

                            <Box font="gray500 textSm regular">
                                {translate('les_membres_pr_44896')}
                            </Box>
                        </Flex>
                    }
                    totalCount={volunteersRegistrations.length}
                    setOffset={noop}
                />
            </AssignPopup>

            <Switch>
                <Route
                    path={HeaventPaths.ACCREDITATION_ACCREDIT_USER(
                        ':organizationId',
                        ':eventId',
                        ':accreditationId',
                        ':userInfoId'
                    )}
                    children={
                        <RightPanel
                            size="md"
                            onClose={() => {
                                history.push(
                                    HeaventPaths.ACCREDITATION_ACCREDIT(
                                        organizationId,
                                        eventId,
                                        accreditationId
                                    )
                                );
                            }}
                        >
                            <RightPanelBody>
                                <UserPanel
                                    baseRoutePath={HeaventPaths.ACCREDITATION_ACCREDIT_USER(
                                        ':organizationId',
                                        ':eventId',
                                        ':accreditationId',
                                        ':userInfoId'
                                    )}
                                    getBasePath={(userInfoId) => HeaventPaths.ACCREDITATION_ACCREDIT_USER(
                                            organizationId,
                                            eventId,
                                            accreditationId,
                                            userInfoId
                                        )}
                                    onDelete={() => {
                                        history.push(
                                            HeaventPaths.ACCREDITATION_ACCREDIT(
                                                organizationId,
                                                eventId,
                                                accreditationId
                                            )
                                        );

                                        reload();
                                    }}
                                    onUpdateState={reload}
                                />
                            </RightPanelBody>
                        </RightPanel>
                    }
                />
            </Switch>
        </>
    );
};

export const AccreditationsAccredit = () => {
    const { eventId, accreditationId } = useParams();
    const { data, loader, reload } = useAccreditationAvailableVolunteersRegistrationsQuery({
        eventId,
        accreditationId
    });

    if (loader) {
        return loader;
    } else {
        return <AccreditationsAccreditComponent event={data.event} reload={reload} />;
    }
};
