import { Button } from 'common-front/src/designSystem/components/button';
import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { RightPanelFooter } from 'common-front/src/designSystem/components/rightPanel/rightPanelFooter';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { usePositionRightPanelQuery, usePositionSlotsQuery } from '../../generated/graphqlHooks';
import { PositionRightPanelBody } from './positionRightPanelBody';
import { PositionRightPanelBodySkeleton } from './positionRightPanelBodySkeleton';

interface IPositionRightPanelProps {
    onClose(): void;
}

export const PositionRightPanel = (props: IPositionRightPanelProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, positionId }
    } = useHeavent();
    const { data, isLoading } = usePositionRightPanelQuery({ eventId, positionId });
    const [offset, setOffset] = React.useState(0);
    const { data: slotsData, isLoading: areSlotsLoading } = usePositionSlotsQuery({
        eventId,
        positionId,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(slotsData.event?.positionsSlots);

    return (
        <RightPanel size="md" onClose={props.onClose}>
            <RightPanelBody css={{ padding: '0' }}>
                {isLoading ? (
                    <PositionRightPanelBodySkeleton />
                ) : (
                    <PositionRightPanelBody
                        areSlotsLoading={areSlotsLoading}
                        numberOfPages={numberOfPages}
                        offset={offset}
                        position={data.event.position}
                        slots={slotsData.event?.positionsSlots.nodes ?? []}
                        totalCount={totalCount}
                        setOffset={setOffset}
                    />
                )}
            </RightPanelBody>

            <RightPanelFooter>
                <Flex justify="end" gap="3">
                    <Button
                        onClick={() => {
                            history.push(
                                HeaventPaths.POSITION(organizationId, eventId, positionId)
                            );
                        }}
                    >
                        {translate('voir_le_d_tail_72056')}
                    </Button>

                    <Button
                        color="white"
                        onClick={() => {
                            history.push(
                                HeaventPaths.EDIT_POSITION(organizationId, eventId, positionId)
                            );
                        }}
                    >
                        {translate('_diter_la_missi_90442')}
                    </Button>
                </Flex>
            </RightPanelFooter>
        </RightPanel>
    );
};
