import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    DelegationId,
    EventId,
    OrganizationId,
    SegmentId,
    UpdateCellDelegationsQuery,
    UsersInfoId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import {
    executeMassDelegationAddOrganizationDelegationsQuery,
    executeUpdateCellDelegationsQuery,
    useUsersInfosMassDelegationAddMutation,
    useVolunteersRegistrationsMassDelegationAddMutation
} from '../../generated/graphqlHooks';

interface IMassDelegationAddModalProps {
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    segmentId: Emptyable<SegmentId>;
    usersInfosIds: UsersInfoId[];

    onClose(): void;
    onSuccess(): void;
}

export const MassDelegationAddModal = (props: IMassDelegationAddModalProps) => {
    const translate = useTranslate();
    const {
        mutate: volunteersRegistrationsMassDelegationAdd,
        isLoading: isVolunteersRegistrationsMassDelegationAddLoading
    } = useVolunteersRegistrationsMassDelegationAddMutation();
    const { mutate: usersInfosMassDelegationAdd, isLoading: isUsersInfosMassDelegationAddLoading } =
        useUsersInfosMassDelegationAddMutation();
    const [delegations, setDelegations] = React.useState<
        UpdateCellDelegationsQuery['event']['delegations']['nodes']
    >([]);
    const [delegationsIds, setDelegationsIds] = React.useState<DelegationId[]>([]);
    const onUpdate = async () => {
        if (isNonEmptyArray(delegationsIds)) {
            if (props.eventId) {
                await volunteersRegistrationsMassDelegationAdd({
                    eventId: props.eventId,
                    segmentId: props.segmentId,
                    usersInfosIds: props.usersInfosIds,
                    delegationId: delegationsIds[0]
                });
            } else {
                await usersInfosMassDelegationAdd({
                    organizationId: props.organizationId,
                    segmentId: props.segmentId,
                    usersInfosIds: props.usersInfosIds,
                    delegationId: delegationsIds[0]
                });
            }

            props.onSuccess();
            props.onClose();
        }
    };

    React.useEffect(() => {
        (async () => {
            if (props.eventId) {
                const eventResult = await executeUpdateCellDelegationsQuery(
                    { eventId: props.eventId },
                    await getToken()
                );

                setDelegations(eventResult.event.delegations.nodes);
            } else {
                const organizationResult =
                    await executeMassDelegationAddOrganizationDelegationsQuery(
                        { organizationId: props.organizationId },
                        await getToken()
                    );

                setDelegations(organizationResult.organization.delegations.nodes);
            }
        })();
    }, [props.organizationId, props.eventId]);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Flex justify="center">
                <IconBackground color="primary" icon="people-group" />
            </Flex>

            <Spacer height="4" />

            <Box font="gray900 textLg medium" textAlign="center">
                {translate('ajouter_des_mem_60880')}
            </Box>

            <Spacer height="7" />

            <RichSelect
                isSearchVisible={true}
                label={translate('Delegation')}
                values={delegationsIds}
                onChange={setDelegationsIds}
            >
                {delegations.map((delegation) => (
                        <option key={delegation.id} value={delegation.id}>
                            {delegation.name}
                        </option>
                    ))}
            </RichSelect>

            <Spacer height="7" />

            <Flex justify="end" gap="3">
                <Button
                    isLoading={
                        isVolunteersRegistrationsMassDelegationAddLoading ||
                        isUsersInfosMassDelegationAddLoading
                    }
                    onClick={onUpdate}
                >
                    {translate('ajouter_97327')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
