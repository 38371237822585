import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useLoginCodeEmailCreateMutation } from 'common-front/src/generated/graphqlHooks';
import * as Cognito from 'common-front/src/util/aws/cognito';
import {
    initiateAuthWithSignUp,
    reportAuthError,
    sendCustomChallengeAnswer
} from 'common-front/src/util/aws/cognito';
import { LocalStorageKeys } from 'common-front/src/util/localStorage';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { LoginCodeSource } from 'common/src/generated/types';
import { ISignInCodeValues, SignInCodeInputService } from 'common/src/input/signInCodeInput';
import { ValidateService } from 'common/src/services/validateService';
import {
    useHistory,
    useLocalStorage,
    useService,
    useTranslate
} from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { noop } from 'lodash-es';
import { Duration } from 'luxon';
import * as React from 'react';
import { AuthPage } from './authPage';
import { Link } from './link';

interface ICodeProps {
    onSuccess(token: string): Promise<any>;
    onFailure(): void;
}

export const Code = (props: ICodeProps) => {
    const translate = useTranslate();
    const history = useHistory();
    const localStorage = useLocalStorage();
    const validateService = useService(ValidateService);
    const signInCodeInput = useService(SignInCodeInputService);
    const { mutate: loginCodeEmailCreate } = useLoginCodeEmailCreateMutation();
    const [currentInitiateAuthResult, setInitiateAuthResult] =
        React.useState<Cognito.IInitiateAuthResult | null>(null);
    const [step, setStep] = React.useState(0);
    const [currentAttempt, setCurrentAttempt] = React.useState(0);
    const [isRestartVisible, setIsRestartVisible] = React.useState(false);
    const [challengeStartTime, setChallengeStartTime] = React.useState(Date.now());

    return (
        <AuthPage
            link={
                <Link>
                    <Box color="gray500">{translate('vous_n_avez_pas_69078')}</Box>

                    <Box
                        color="primary700"
                        css={{ cursor: 'pointer' }}
                        onClick={() => {
                            history.push(HeaventPaths.AUTH_SIGN_IN_EMAIL);
                        }}
                    >
                        {translate('r_essayer_21582')}
                    </Box>
                </Link>
            }
        >
            <Form
                direction="column"
                width={1}
                initialValues={{
                    email: localStorage.getItem(LocalStorageKeys.AUTH_EMAIL) || '',
                    code: ''
                }}
                validate={(values: ISignInCodeValues) => {
                    if (step === 0) {
                        return validateService.validateForForm(signInCodeInput.step1Schema())(
                            values
                        );
                    } else {
                        return validateService.validateForForm(signInCodeInput.step2Schema())(
                            values
                        );
                    }
                }}
                onSubmit={async (values: ISignInCodeValues) => {
                    if (step === 0) {
                        await loginCodeEmailCreate({
                            loginCodeEmail: {
                                email: values.email,
                                source: LoginCodeSource.App
                            }
                        });

                        setChallengeStartTime(Date.now());
                        const initiateAuthResult = await initiateAuthWithSignUp(
                            values.email,
                            noop,
                            noop
                        );

                        setInitiateAuthResult(initiateAuthResult);
                        setStep(1);
                    } else {
                        const { user, callbacks } = currentInitiateAuthResult!;

                        return sendCustomChallengeAnswer({
                            code: values.code,
                            user,
                            callbacks
                        }).then(props.onSuccess, (error) => {
                            if (error.type === 'RETRY') {
                                setCurrentAttempt(currentAttempt + 1);

                                return {
                                    code: translate(
                                        'le_code_n_est_p_95065',
                                        3 - (currentAttempt + 1)
                                    )
                                };
                            } else {
                                reportAuthError(error.message, values.email, challengeStartTime);
                                setIsRestartVisible(true);

                                let FORM_ERROR;
                                if (
                                    Duration.fromMillis(Date.now() - challengeStartTime).as(
                                        'minutes'
                                    ) > 3
                                ) {
                                    FORM_ERROR = translate('le_code_a_expir_45975');
                                } else {
                                    FORM_ERROR = translate('le_code_n_est_p_12317');
                                }

                                return {
                                    FORM_ERROR
                                };
                            }
                        });
                    }
                }}
                render={({ handleSubmit, submitting }) => (
                        <>
                            <Box
                                color="gray900"
                                fontSize="textXl"
                                fontWeight="medium"
                                textAlign="center"
                            >
                                {translate('se_connecter_av_59438')}
                            </Box>

                            <Spacer height="1" />

                            <Box color="gray500" textAlign="center">
                                {translate('saisissez_votre_01613')}
                            </Box>

                            <Spacer height="7" />

                            <TextInput
                                autoComplete="email"
                                label={translate('adresse_e_mail_60930')}
                                placeholder={translate('entrez_votre_ad_42451')}
                                name="email"
                                isEmail={true}
                                state={step > 0 || isRestartVisible ? 'disabled' : 'active'}
                            />

                            {step > 0 && (
                                <>
                                    <Spacer height="4" />

                                    <TextInput
                                        autoComplete="one-time-code"
                                        label={translate('code_20843')}
                                        placeholder={translate('code_20843')}
                                        name="code"
                                        state={isRestartVisible ? 'disabled' : undefined}
                                        type="number"
                                    />
                                </>
                            )}

                            <Spacer height="6" />

                            {isRestartVisible ? (
                                <Button onClick={props.onFailure} textAlign="center">
                                    {translate('recommencer_14712')}
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleSubmit}
                                    isLoading={submitting}
                                    textAlign="center"
                                >
                                    {step === 0
                                        ? translate('recevoir_le_cod_88633')
                                        : translate('se_connecter_63820')}
                                </Button>
                            )}
                        </>
                    )}
            />
        </AuthPage>
    );
};
