import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate, Operator, Predicate } from 'common/src/vo/segment';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { DateInput } from '../../designSystem/components/date/dateInput';
import { DatetimeInput } from '../../designSystem/components/date/datetimeInput';
import { RadioText } from '../../designSystem/components/radio';

interface IAddDateTimeRangeFilterProps {
    filterPredicate: FilterPredicate;

    onAdd(predicate: Predicate): void;
}

export const AddDateTimeRangeFilter = (props: IAddDateTimeRangeFilterProps) => {
    const translate = useTranslate();
    const [operator, setOperator] = React.useState<Operator>(
        props.filterPredicate.predicate?.operator ?? 'contains'
    );
    const [value, setValue] = React.useState<DateTime | string>(
        props.filterPredicate.predicate?.value ?? ''
    );
    const [value2, setValue2] = React.useState<DateTime | string>(
        props.filterPredicate.predicate?.value2 ?? ''
    );
    const valid =
        DateTime.isDateTime(value) &&
        value.isValid &&
        ['contains', 'notContains', 'is'].includes(operator)
            ? true
            : DateTime.isDateTime(value2) && value2.isValid;

    return (
        <>
            <RadioText
                state={operator === 'contains' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('contains');
                }}
            >
                {translate('contient_34012')}
            </RadioText>

            {operator === 'contains' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value}
                            onChange={setValue}
                        />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'is' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('is');
                }}
            >
                {translate('contient_date_89885')}
            </RadioText>

            {operator === 'is' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DateInput
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value}
                            onChange={setValue}
                        />
                    </Flex>
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'notContains' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('notContains');
                }}
            >
                {translate('ne_contient_pas_85399')}
            </RadioText>

            {operator === 'notContains' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value}
                            onChange={setValue}
                        />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'overlaps' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('overlaps');
                }}
            >
                {translate('chevauche_49445')}
            </RadioText>

            {operator === 'overlaps' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            placeholder={translate('d_but_41840')}
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value}
                            onChange={setValue}
                        />
                    </Flex>

                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            placeholder={translate('fin_60104')}
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value2}
                            onChange={setValue2}
                        />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'notOverlaps' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('notOverlaps');
                }}
            >
                {translate('ne_chevauche_pa_17307')}
            </RadioText>

            {operator === 'notOverlaps' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            placeholder={translate('d_but_41840')}
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value}
                            onChange={setValue}
                        />
                    </Flex>

                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            placeholder={translate('fin_60104')}
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value2}
                            onChange={setValue2}
                        />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'engulfs' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('engulfs');
                }}
            >
                {translate('engloutis_42346')}
            </RadioText>

            {operator === 'engulfs' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            placeholder={translate('d_but_41840')}
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value}
                            onChange={setValue}
                        />
                    </Flex>

                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            placeholder={translate('fin_60104')}
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value2}
                            onChange={setValue2}
                        />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'notEngulfs' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('notEngulfs');
                }}
            >
                {translate('n_engloutis_pas_42363')}
            </RadioText>

            {operator === 'notEngulfs' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            placeholder={translate('d_but_41840')}
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value}
                            onChange={setValue}
                        />
                    </Flex>

                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            placeholder={translate('fin_60104')}
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value2}
                            onChange={setValue2}
                        />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'engulfedBy' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('engulfedBy');
                }}
            >
                {translate('est_englouti_pa_47852')}
            </RadioText>

            {operator === 'engulfedBy' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            placeholder={translate('d_but_41840')}
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value}
                            onChange={setValue}
                        />
                    </Flex>

                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            placeholder={translate('fin_60104')}
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value2}
                            onChange={setValue2}
                        />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={operator === 'notEngulfedBy' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('notEngulfedBy');
                }}
            >
                {translate('n_est_pas_englo_08733')}
            </RadioText>

            {operator === 'notEngulfedBy' && (
                <>
                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            placeholder={translate('d_but_41840')}
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value}
                            onChange={setValue}
                        />
                    </Flex>

                    <Spacer height="1" />

                    <Flex>
                        <Spacer width="6" />
                        <DatetimeInput
                            placeholder={translate('fin_60104')}
                            min={props.filterPredicate.filter.startAt}
                            max={props.filterPredicate.filter.endAt}
                            value={value2}
                            onChange={setValue2}
                        />
                    </Flex>

                    <Spacer height="1" />
                </>
            )}

            <Spacer height="7" />

            <Flex>
                <Button
                    disabled={!valid}
                    onClick={() => {
                        props.onAdd({
                            operator,
                            value,
                            value2,
                            slug: props.filterPredicate.filter.slug
                        });
                    }}
                >
                    {translate('ajouter_le_filt_54222')}
                </Button>
            </Flex>
        </>
    );
};
