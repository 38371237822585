import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import {
    HorizontalSpacerSeparator,
    Separator
} from 'common-front/src/designSystem/components/separator';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    ISkidataConnectionInfoUpsertValues,
    SkidataConnectionInfoInputService
} from 'common/src/input/skidataConnectionInfoInput';
import { useParams, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { difference } from 'lodash-es';
import * as React from 'react';
import {
    useSkidataConnectionInfosDeleteMutation,
    useSkidataConnectionInfosQuery,
    useSkidataConnectionInfosUpsertMutation,
    useSkidataSynchronizeMutation
} from '../../generated/graphqlHooks';
import { SkidataEventsList } from './skidataEventsList';

export const Skidata = () => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const skidataConnectionInfoInput = useService(SkidataConnectionInfoInputService);
    const { data, loader, reload } = useSkidataConnectionInfosQuery({ organizationId });
    const { mutate: connectionInfosDelete } = useSkidataConnectionInfosDeleteMutation();
    const { mutate: connectionInfosUpsert, isLoading } = useSkidataConnectionInfosUpsertMutation();
    const { mutate: synchronize, isLoading: isSynchronizeLoading } =
        useSkidataSynchronizeMutation();
    const events = React.useMemo(() => data.organization?.events.nodes ?? [], [data.organization]);
    const eventsIds = React.useMemo(() => events.map(({ id }) => id), [events]);
    const synchronizedEventsIds = React.useMemo(() => data.organization?.skidataConnectionInfos?.synchronizedEventsIds ?? [], [data.organization]);
    const seasonEventsIds = React.useMemo(() => data.organization?.skidataConnectionInfos?.seasonEventsIds ?? [], [data.organization]);
    const allEvents = React.useMemo(() => {
        const allEventsIds = difference(eventsIds, synchronizedEventsIds.concat(seasonEventsIds));

        return events.filter((event) => allEventsIds.includes(event.id));
    }, [events, synchronizedEventsIds, seasonEventsIds]);
    const synchronizedEvents = React.useMemo(() => events.filter((event) => synchronizedEventsIds.includes(event.id)), [events]);
    const seasonEvents = React.useMemo(() => events.filter((event) => seasonEventsIds.includes(event.id)), [events, seasonEventsIds]);

    return (
        loader || (
            <Form
                direction="column"
                height={1}
                width={1}
                css={{ overflowY: 'auto', padding: '$7' }}
                initialValues={{
                    connectionInfos: skidataConnectionInfoInput.connectionInfosInputDefault(
                        data.organization.skidataConnectionInfos
                    )
                }}
                onSubmit={async (values: ISkidataConnectionInfoUpsertValues) => {
                    await connectionInfosUpsert({
                        organizationId,
                        connectionInfos: values.connectionInfos
                    });

                    reload();
                }}
                render={({ handleSubmit }) => (
                        <>
                            <Flex gap="3">
                                <Box
                                    color="gray900"
                                    fontSize="displayXs"
                                    fontWeight="semiBold"
                                    css={{ flex: '1' }}
                                >
                                    Skidata
                                </Box>

                                {data.organization.skidataConnectionInfos && (
                                    <>
                                        <Button onClick={handleSubmit} isLoading={isLoading}>
                                            {translate('enregistrer_06519')}
                                        </Button>

                                        <Tooltip>
                                            <Trigger>
                                                <Box>
                                                    {isSynchronizeLoading ? (
                                                        <Button color="white" leftIcon="loader" />
                                                    ) : (
                                                        <Button
                                                            color="white"
                                                            leftIcon="arrows-rotate"
                                                            onClick={async () => {
                                                                await synchronize({
                                                                    organizationId
                                                                });
                                                            }}
                                                        />
                                                    )}
                                                </Box>
                                            </Trigger>

                                            <Content placement="top">
                                                {translate('synchroniser_34754')}
                                            </Content>
                                        </Tooltip>

                                        <Tooltip>
                                            <Trigger>
                                                <Box>
                                                    <Button
                                                        color="white"
                                                        leftIcon="arrow-right-from-bracket"
                                                        onClick={async () => {
                                                            await connectionInfosDelete({
                                                                organizationId
                                                            });

                                                            reload();
                                                        }}
                                                    />
                                                </Box>
                                            </Trigger>

                                            <Content placement="top">
                                                {translate('se_d_connecter_74151')}
                                            </Content>
                                        </Tooltip>
                                    </>
                                )}
                            </Flex>

                            <Spacer height="7" />

                            <Flex align="center" gap="3" width={1}>
                                <Box color="gray700" fontWeight="medium" width={280}>
                                    {translate('adresse_du_web_92125')}
                                </Box>

                                <TextInput name="connectionInfos.url" />
                            </Flex>

                            <HorizontalSpacerSeparator height="5" />

                            <Flex align="center" gap="3" width={1}>
                                <Box color="gray700" fontWeight="medium" width={280}>
                                    Issuer
                                </Box>

                                <TextInput name="connectionInfos.issuer" />
                            </Flex>

                            <HorizontalSpacerSeparator height="5" />

                            <Flex align="center" gap="3" width={1}>
                                <Box color="gray700" fontWeight="medium" width={280}>
                                    Receiver
                                </Box>

                                <TextInput name="connectionInfos.receiver" />
                            </Flex>

                            <HorizontalSpacerSeparator height="5" />

                            <Flex align="center" gap="3" width={1}>
                                <Box color="gray700" fontWeight="medium" width={280}>
                                    {translate('lieu_20541')}
                                </Box>

                                <TextInput name="connectionInfos.place" />
                            </Flex>

                            {data.organization.skidataConnectionInfos && (
                                <>
                                    <Spacer height="5" />
                                    <Separator direction="horizontal" />
                                    <Spacer height="5" />

                                    <SkidataEventsList
                                        events={allEvents}
                                        eventType="all"
                                        organizationId={organizationId}
                                        subtitle={''}
                                        title={translate('liste_des_v_ne_29929')}
                                        reload={reload}
                                    />

                                    <HorizontalSpacerSeparator height="5" />

                                    <SkidataEventsList
                                        events={synchronizedEvents}
                                        eventType="synchronized"
                                        organizationId={organizationId}
                                        subtitle={translate('ces_v_nements_58722')}
                                        title={translate('liste_des_v_ne_09741')}
                                        reload={reload}
                                    />

                                    <HorizontalSpacerSeparator height="5" />

                                    <SkidataEventsList
                                        events={seasonEvents}
                                        eventType="season"
                                        organizationId={organizationId}
                                        subtitle={translate('ces_v_nements_24479')}
                                        title={translate('liste_des_v_ne_73078')}
                                        reload={reload}
                                    />
                                </>
                            )}

                            {!data.organization.skidataConnectionInfos && (
                                <>
                                    <Spacer height="5" />

                                    <Flex justify="end">
                                        <Button onClick={handleSubmit} isLoading={isLoading}>
                                            {translate('se_connecter_63820')}
                                        </Button>
                                    </Flex>
                                </>
                            )}
                        </>
                    )}
            ></Form>
        )
    );
};
