import { StyledIcon } from 'common-front/src/designSystem/components/iconSelect';
import { Link } from 'common-front/src/util/link';
import { Box } from 'common/src/designSystem/components/box';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IOrganizationParametersMenuItemProps {
    icon: IIcon;
    path: string;
    title: string;

    isActive?(pathname: string): boolean;
}

export const OrganizationParametersMenuItem = (props: IOrganizationParametersMenuItemProps) => {
    const location = useLocation();
    const isActive = props.isActive
        ? props.isActive(location.pathname)
        : location.pathname === props.path;

    return (
        <Link to={props.path}>
            <StyledIcon
                active={isActive}
                css={{ gap: '$3', justifyContent: 'flex-start', px: '$3', width: '100%' }}
            >
                <Box width={20}>
                    <I icon={props.icon} />
                </Box>

                <Box>{props.title}</Box>
            </StyledIcon>
        </Link>
    );
};
