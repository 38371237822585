import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    OrganizationId,
    UserProfileAccreditationsQuery,
    UsersInfoId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useUserProfileAccreditationsQuery } from '../../../generated/graphqlHooks';
import { UserProfileAccreditation } from './userProfileAccreditation';
import { UserProfileWishedAccreditations } from './userProfileWishedAccreditations';

interface IUserProfileAccreditationsInnerProps {
    event: UserProfileAccreditationsQuery['event'];
    eventId: EventId;
    organization: UserProfileAccreditationsQuery['organization'];
    organizationId: OrganizationId;
}

const UserProfileAccreditationsInner = (props: IUserProfileAccreditationsInnerProps) => {
    const translate = useTranslate();
    const vr = React.useMemo(() => props.event.volunteerRegistration, [props.event]);
    const wishedSlots = React.useMemo(() => props.organization.userInfo.formsUsersInfos.flatMap(
            (fui) => fui.accreditationsSlots
        ), [props.organization]);
    const hasBoth = isNonEmptyArray(vr.accreditationsUsersInfos) && isNonEmptyArray(wishedSlots);

    return (
        <>
            <Spacer height="7" />

            {isNonEmptyArray(vr.accreditationsUsersInfos) && (
                <Flex direction="column" gap="3">
                    <Box font="gray900 textMd semiBold">{translate('accr_ditations_33155')}</Box>

                    {vr.accreditationsUsersInfos.map((aui) => (
                            <UserProfileAccreditation
                                key={aui.accreditationSlot.id}
                                accreditationUserInfo={aui}
                                eventId={props.eventId}
                                organizationId={props.organizationId}
                            />
                        ))}
                </Flex>
            )}

            {hasBoth && <Spacer height="8" />}

            {isNonEmptyArray(wishedSlots) && (
                <UserProfileWishedAccreditations
                    eventId={props.eventId}
                    formsUsersInfos={props.organization.userInfo.formsUsersInfos}
                    organizationId={props.organizationId}
                />
            )}
        </>
    );
};

interface IUserProfileAccreditationsProps {
    eventId: EventId;
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;
}

export const UserProfileAccreditations = (props: IUserProfileAccreditationsProps) => {
    const { data, loader } = useUserProfileAccreditationsQuery({
        organizationId: props.organizationId,
        eventId: props.eventId,
        userInfoId: props.userInfoId
    });

    return (
        loader || (
            <UserProfileAccreditationsInner
                event={data.event}
                eventId={props.eventId}
                organization={data.organization}
                organizationId={props.organizationId}
            />
        )
    );
};
