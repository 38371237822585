import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    FormUserOverlay,
    UserInformationForm
} from 'common-front/src/users/information/userInformationForm';
import { toFormElements } from 'common-front/src/vo/customField';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    FormId,
    OrganizationId,
    UserInfoOverlayFragment,
    UsersInfoId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import {
    useUserProfileInformationsEventQuery,
    useUserProfileInformationsOrganizationQuery
} from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { UserProfileInformationsEmpty } from './userProfileInformationsEmpty';

type FilledForm = {
    formId: FormId;
    insertedAt: DateTime;
};

interface IUserProfileInformationsProps {
    forms: FormUserOverlay[];
    filledForms: FilledForm[];
    initialShowNotFilled: boolean;
    userInfo: UserInfoOverlayFragment;

    getEditUserFormPath(formId: FormId | 'all' | 'private'): string;
    setShowNotFilled(showNotFilled: boolean): void;
}

const UserProfileInformations = (props: IUserProfileInformationsProps) => {
    const { translate } = useHeavent();
    const [name, setName] = React.useState('');
    const [showNotFilled, _setShowNotFilled] = React.useState(props.initialShowNotFilled);
    const setShowNotFilled = React.useCallback(
        (newShowNotFilled) => {
            _setShowNotFilled(newShowNotFilled);
            props.setShowNotFilled(newShowNotFilled);
        },
        [props.setShowNotFilled, _setShowNotFilled]
    );
    const formIdToFilledAt = React.useMemo(() => Object.fromEntries(props.filledForms.map((ff) => [ff.formId, ff.insertedAt])), [props.filledForms]);
    const forms = React.useMemo(() => sortBy(
            props.forms.filter((form) => {
                if (!showNotFilled && formIdToFilledAt[form.id] === undefined) {
                    return false;
                } else if (isNonEmptyString(name)) {
                    return form.name.toLowerCase().includes(name.toLowerCase().trim());
                } else {
                    return true;
                }
            }),
            [(f) => (formIdToFilledAt[f.id] !== undefined ? 1 : 2), (f) => f.name]
        ), [props.forms, formIdToFilledAt, name, showNotFilled]);

    if (isNonEmptyArray(forms)) {
        return (
            <>
                <Spacer height="7" />

                <Flex align="center" gap="3">
                    <Box css={{ flex: '1' }}>
                        <TextInput
                            icon="magnifying-glass"
                            placeholder={translate('rechercher_un_f_28561')}
                            value={name}
                            onChange={setName}
                        />
                    </Box>

                    <CheckboxText
                        state={showNotFilled ? 'checked' : 'unchecked'}
                        onClick={(state) => {
                            setShowNotFilled(state === 'checked');
                        }}
                    >
                        {translate('afficher_les_fo_84832')}
                    </CheckboxText>
                </Flex>

                <Spacer height="5" />

                <Flex direction="column" gap="4">
                    {forms.map((form) => (
                            <UserInformationForm
                                key={form.id}
                                filledAt={formIdToFilledAt[form.id]}
                                form={form}
                                showIsFilled={form.id !== 'all'}
                                userInfo={props.userInfo}
                                getEditUserFormPath={props.getEditUserFormPath}
                            />
                        ))}
                </Flex>
            </>
        );
    } else {
        return <UserProfileInformationsEmpty getEditUserFormPath={props.getEditUserFormPath} />;
    }
};

interface IUserProfileInformationsEventProps {
    eventId: EventId;
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;
}

export const UserProfileInformationsEvent = (props: IUserProfileInformationsEventProps) => {
    const { localEvent, updateLocalEvent } = useEventContext();
    const { data, loader } = useUserProfileInformationsEventQuery({
        organizationId: props.organizationId,
        eventId: props.eventId!,
        userInfoId: props.userInfoId
    });
    const forms = React.useMemo(() => sortBy(data.event?.forms ?? [], (f) => f.name), [data.event]);
    const filledForms = React.useMemo(() => data.organization?.userInfo.formsUsersInfos ?? [], [data.organization]);
    const setShowNotFilled = React.useCallback(
        (showNotFilled) => updateLocalEvent({ showNotFilled }),
        [updateLocalEvent]
    );

    return (
        loader || (
            <UserProfileInformations
                forms={forms}
                filledForms={filledForms}
                initialShowNotFilled={localEvent?.showNotFilled ?? false}
                userInfo={data.organization.userInfo}
                getEditUserFormPath={(formId) => HeaventPaths.EDIT_USER_FORM(
                        props.organizationId,
                        props.eventId,
                        props.userInfoId,
                        formId
                    )}
                setShowNotFilled={setShowNotFilled}
            />
        )
    );
};

interface IUserProfileInformationsOrganizationProps {
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;
}

export const UserProfileInformationsOrganization = (
    props: IUserProfileInformationsOrganizationProps
) => {
    const translate = useTranslate();
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const { data, loader } = useUserProfileInformationsOrganizationQuery({
        organizationId: props.organizationId,
        userInfoId: props.userInfoId
    });
    const forms = React.useMemo(() => {
        if (data.organization) {
            const allElements = toFormElements(data.organization.customFields.nodes);
            const form: FormUserOverlay = {
                id: 'all',
                name: translate('tous_les_champs_57939'),
                elements: allElements
            };

            return sortBy(data.organization.forms as FormUserOverlay[], (f) => f.name).concat([
                form
            ]);
        } else {
            return [];
        }
    }, [data.organization]);
    const filledForms = React.useMemo(() => data.organization?.userInfo.formsUsersInfos ?? [], [data.organization]);
    const setShowNotFilled = React.useCallback(
        (showNotFilled) => updateLocalOrganization({ showNotFilled }),
        [updateLocalOrganization]
    );

    return (
        loader || (
            <UserProfileInformations
                forms={forms}
                filledForms={filledForms}
                initialShowNotFilled={localOrganization?.showNotFilled ?? false}
                userInfo={data.organization.userInfo}
                getEditUserFormPath={(formId) => HeaventPaths.COMMUNITY_EDIT_USER_FORM(
                        props.organizationId,
                        props.userInfoId,
                        formId
                    )}
                setShowNotFilled={setShowNotFilled}
            />
        )
    );
};
