import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    ALL_DELEGATIONPROPERTY,
    DelegationId,
    DelegationProperty,
    DelegationsMassEditInput,
    EventId,
    Form,
    OrganizationId,
    SegmentId,
    Tag
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';
import {
    useDelegationsMassEditMutation,
    useEventDelegationsMassEditInfosQuery,
    useOrganizationMassEditInfosQuery
} from '../../generated/graphqlHooks';
import { DelegationsMassEditFieldInput } from './delegationsMassEditFieldInput';

interface IDelegationsMassEditValues {
    massEdit: DelegationsMassEditInput;
}

interface IDelegationsMassEditModalProps {
    eventId: Emptyable<EventId>;
    forms: Array<Pick<Form, 'id' | 'name'>>;
    ids: DelegationId[];
    organizationId: OrganizationId;
    segmentId: Emptyable<SegmentId>;
    tags: Array<Pick<Tag, 'id' | 'name'>>;

    onClose(): void;
    onSuccess(): void;
}

const DelegationsMassEditModal = (props: IDelegationsMassEditModalProps) => {
    const translate = useTranslate();
    const { mutate } = useDelegationsMassEditMutation();
    const enumToOptions = useEnumToOptions();
    const properties = React.useMemo(() => ALL_DELEGATIONPROPERTY.filter((p) => p !== DelegationProperty.Name), []);

    return (
        <FormModal
            size="md"
            buttonText={translate('modifier_la_val_07602')}
            icon="sliders"
            initialValues={{
                massEdit: {
                    ids: props.ids,
                    property: '' as any,
                    reset: false,
                    segmentId: props.segmentId,
                    value: ''
                }
            }}
            title={translate('_dition_de_la_v_65696')}
            onClose={props.onClose}
            onSubmit={async (values: IDelegationsMassEditValues) => {
                await mutate({
                    organizationId: props.organizationId,
                    eventId: props.eventId,
                    massEdit: values.massEdit
                });

                props.onSuccess();
                props.onClose();
            }}
        >
            {({ form, values }) => (
                    <>
                        <OnChange name="massEdit.property">
                            {(property: DelegationProperty) => {
                                if (property === DelegationProperty.Tag) {
                                    form.change('massEdit.value', []);
                                } else {
                                    form.change('massEdit.value', '');
                                }
                            }}
                        </OnChange>

                        <Select name="massEdit.property" label={translate('quelle_est_la_p_49128')}>
                            <option value="">{translate('s_lectionnez_un_08888')}</option>

                            {enumToOptions(properties)}
                        </Select>

                        {(values.massEdit.property as any) !== '' && (
                            <>
                                <Spacer height="4" />

                                {!values.massEdit.reset && (
                                    <>
                                        <DelegationsMassEditFieldInput
                                            forms={props.forms}
                                            property={values.massEdit.property}
                                            tags={props.tags}
                                            value={values.massEdit.value}
                                        />

                                        <Spacer height="2" />
                                    </>
                                )}

                                {values.massEdit.property !== DelegationProperty.Form && (
                                    <CheckboxText name="massEdit.reset">
                                        {translate('je_souhaite_r_i_32403')}
                                    </CheckboxText>
                                )}
                            </>
                        )}
                    </>
                )}
        </FormModal>
    );
};

interface IEventDelegationsMassEditModalProps {
    eventId: EventId;
    ids: DelegationId[];
    organizationId: OrganizationId;
    segmentId: Emptyable<SegmentId>;

    onClose(): void;
    onSuccess(): void;
}

export const EventDelegationsMassEditModal = (props: IEventDelegationsMassEditModalProps) => {
    const { data } = useEventDelegationsMassEditInfosQuery({
        organizationId: props.organizationId,
        eventId: props.eventId
    });

    return (
        <DelegationsMassEditModal
            {...props}
            forms={data.event?.forms ?? []}
            tags={data.organization?.tags.nodes ?? []}
        />
    );
};

interface IOrganizationDelegationsMassEditModalProps {
    ids: DelegationId[];
    organizationId: OrganizationId;
    segmentId: Emptyable<SegmentId>;

    onClose(): void;
    onSuccess(): void;
}

export const OrganizationDelegationsMassEditModal = (
    props: IOrganizationDelegationsMassEditModalProps
) => {
    const { data } = useOrganizationMassEditInfosQuery({
        organizationId: props.organizationId
    });

    return (
        <DelegationsMassEditModal
            {...props}
            eventId={null}
            forms={data.organization?.forms ?? []}
            tags={data.organization?.tags.nodes ?? []}
        />
    );
};
