import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import * as React from 'react';

interface IBlankRowProps {
    title: string;
    subtitle?: string;
}

export const BlankRow = ({ title, subtitle }: IBlankRowProps) => (
        <Row css={{ height: 'fit-content' }}>
            <Cell>
                <Flex
                    direction="column"
                    align="center"
                    justify="center"
                    width={1}
                    css={{ padding: '$8 0' }}
                >
                    <IconBackground color="primary" icon="magnifying-glass" />

                    <Spacer height="4" />

                    <Box color="gray900" fontSize="textMd" fontWeight="medium">
                        {title}
                    </Box>

                    <Spacer height="1" />

                    <Box color="gray500">{subtitle}</Box>
                </Flex>
            </Cell>
        </Row>
    );
