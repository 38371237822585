import * as React from 'react';
import { Field } from 'react-final-form';
import { RadioRow as RadioRowComponent } from '../components/radioRow';

interface IRadioRowProps {
    children: React.ReactNode;
    name: string;
    value: any;
}

export const RadioRow = (props: IRadioRowProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => {
                const state = props.value === fieldProps.input.value ? 'checked' : 'unchecked';

                return (
                    <RadioRowComponent
                        state={state}
                        onClick={() => {
                            fieldProps.input.onChange(props.value);
                        }}
                    >
                        {props.children}
                    </RadioRowComponent>
                );
            }}
        />
    );
