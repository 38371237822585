import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

export const KpiCardMobile = ({ header, value }: { header: string; value: string | number }) => (
        <Flex
            direction="column"
            gap="2"
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                padding: '$5 $4'
            }}
        >
            <Box color="gray500" fontSize="textSm" fontWeight="medium">
                {header}
            </Box>
            <Box color="gray900" fontSize="displaySm" fontWeight="semiBold">
                {value}
            </Box>
        </Flex>
    );
