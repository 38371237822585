import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import {
    AccreditationPositionProperty,
    AccreditationsAccreditationFragment
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsNameCellProps {
    accreditation: AccreditationsAccreditationFragment;
    firstCellCss: any;

    reload(): void;
}

export const AccreditationsNameCell = (props: IAccreditationsNameCellProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEditMode } = useSegmentsContext();
    const css = isEditMode
        ? { ellipsis: true }
        : {
              cursor: 'pointer',
              ellipsis: true,
              '&:hover': {
                  textDecoration: 'underline'
              }
          };

    return (
        <AccreditationsUpdateCell
            accreditation={props.accreditation}
            cellCss={props.firstCellCss}
            initialValue={props.accreditation.name}
            property={AccreditationPositionProperty.Name}
            reload={props.reload}
            renderInput={(value, setValue) => (
                    <TextInput
                        label={translate('nom_de_l_accr_d_11908')}
                        value={value}
                        onChange={setValue}
                    />
                )}
        >
            <Box
                font="gray900 textSm medium"
                width={1}
                css={css}
                title={props.accreditation.name}
                onClick={() => {
                    if (!isEditMode) {
                        history.push(
                            HeaventPaths.ACCREDITATION(
                                organizationId,
                                eventId,
                                props.accreditation.id
                            )
                        );
                    }
                }}
            >
                {props.accreditation.name}
            </Box>
        </AccreditationsUpdateCell>
    );
};
