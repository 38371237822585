import { NoFeature } from 'common-front/src/components/noFeature';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Feature } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useDocumentsQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { Documents } from './documents';
import { DocumentsSkeleton } from './documentsSkeleton';

export const DocumentsLoader = () => {
    const translate = useTranslate();
    const { eventId } = useParams();
    const { hasFeature } = useOrganizationContext();
    const { data, isLoading } = useDocumentsQuery({ eventId });

    return (
        <Flex direction="column" css={{ padding: '$7' }}>
            <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                {translate('documents_87028')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('retrouver_et_pe_99652')}</Box>

            {hasFeature(Feature.Documents) ? (
                isLoading ? (
                    <DocumentsSkeleton />
                ) : (
                    <Documents event={data.event} />
                )
            ) : (
                <Flex direction="column" align="center" justify="center" css={{ flex: '1' }}>
                    <NoFeature icon="folders" />
                </Flex>
            )}
        </Flex>
    );
};
