import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationPositionProperty,
    AccreditationsPositionsMassEditInput,
    ALL_ACCREDITATIONPOSITIONPROPERTY,
    EventId,
    OrganizationId,
    SegmentId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isEqual } from 'lodash-es';
import * as React from 'react';
import { Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { usePositionsMassEditInfosQuery } from '../../generated/graphqlHooks';
import { MassEditFieldInput } from './massEditFieldInput';

interface IMassEditValues {
    massEdit: AccreditationsPositionsMassEditInput;
}

interface IMassEditModalProps {
    eventId: EventId;
    ids: number[];
    organizationId: OrganizationId;
    segmentId: Emptyable<SegmentId>;
    type: 'accreditations' | 'positions';

    onClose(): void;
    onSuccess(): void;
    mutate(massEdit: AccreditationsPositionsMassEditInput): Promise<any>;
}

export const MassEditModal = (props: IMassEditModalProps) => {
    const translate = useTranslate();
    const { data } = usePositionsMassEditInfosQuery({
        organizationId: props.organizationId,
        eventId: props.eventId
    });
    const properties = React.useMemo(() => ALL_ACCREDITATIONPOSITIONPROPERTY.filter((p) => {
            if (p === AccreditationPositionProperty.Name) {
                return false;
            } else if (
                props.type === 'accreditations' &&
                [AccreditationPositionProperty.Traces].includes(p)
            ) {
                return false;
            } else {
                return true;
            }
        }), []);
    const categories = React.useMemo(() => props.type === 'accreditations'
            ? data.event?.accreditationsCategories ?? []
            : data.event?.positionsCategories ?? [], [props.type, data.event]);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Form
                initialValues={{
                    massEdit: {
                        ids: props.ids,
                        property: '' as any,
                        reset: false,
                        segmentId: props.segmentId,
                        value: ''
                    }
                }}
                initialValuesEqual={isEqual}
                onSubmit={async (values: IMassEditValues) => {
                    await props.mutate(values.massEdit);

                    props.onSuccess();
                    props.onClose();
                }}
                render={({ form, handleSubmit, submitting, values }) => (
                        <>
                            <Flex justify="center">
                                <IconBackground color="primary" icon="sliders" />
                            </Flex>

                            <Spacer height="4" />

                            <Box
                                color="gray900"
                                fontSize="textLg"
                                fontWeight="medium"
                                textAlign="center"
                            >
                                {translate('_dition_de_la_v_65696')}
                            </Box>

                            <Spacer height="7" />

                            <OnChange name="massEdit.property">
                                {(property: AccreditationPositionProperty) => {
                                    if (property === AccreditationPositionProperty.Tag) {
                                        form.change('massEdit.value', []);
                                    } else {
                                        form.change('massEdit.value', '');
                                    }
                                }}
                            </OnChange>

                            <Select
                                name="massEdit.property"
                                label={translate('quelle_est_la_p_49128')}
                            >
                                <option value="">{translate('s_lectionnez_un_08888')}</option>

                                {properties.map((property) => (
                                        <option key={property} value={property}>
                                            {translate(property)}
                                        </option>
                                    ))}
                            </Select>

                            {(values.massEdit.property as any) !== '' && (
                                <>
                                    <Spacer height="4" />

                                    {!values.massEdit.reset && (
                                        <>
                                            <MassEditFieldInput
                                                categories={categories}
                                                property={
                                                    values.massEdit
                                                        .property as AccreditationPositionProperty
                                                }
                                                tags={data.organization?.tags.nodes ?? []}
                                                traces={data.event?.traces ?? []}
                                                value={values.massEdit.value}
                                            />

                                            <Spacer height="2" />
                                        </>
                                    )}

                                    {values.massEdit.property !==
                                        AccreditationPositionProperty.Category && (
                                        <CheckboxText name="massEdit.reset">
                                            {translate('je_souhaite_r_i_32403')}
                                        </CheckboxText>
                                    )}
                                </>
                            )}

                            <Spacer height="7" />

                            <Flex justify="end" gap="3">
                                <Button isLoading={submitting} onClick={handleSubmit}>
                                    {translate('modifier_la_val_07602')}
                                </Button>

                                <Button color="white" onClick={props.onClose}>
                                    {translate('annuler_48254')}
                                </Button>
                            </Flex>
                        </>
                    )}
            />
        </Modal>
    );
};
