import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { customFieldTypeToIcon } from 'common-front/src/vo/customField';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import { CustomFieldsQuery, OrganizationId, SegmentId } from 'common/src/generated/types';
import { useHistory, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { varietyToTranslation } from 'common/src/vo/customField';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { CustomFieldRowDropdown } from './customFieldRowDropdown';

interface ICustomFieldRowProps {
    columns: PossibleColumn[];
    customField: CustomFieldsQuery['organization']['customFields']['nodes'][0];
    organizationId: OrganizationId;
    segmentId: SegmentId;

    reload(): void;
}

export const CustomFieldRow = (props: ICustomFieldRowProps) => {
    const translate = useTranslate();
    const history = useHistory();

    return (
        <>
            <Row
                css={{
                    '&:hover': {
                        background: '$gray100'
                    }
                }}
                onMouseLeave={(e) => {
                    closeOtherDropdowns(e.target);
                }}
            >
                {props.columns.map((field) => field.slug === 'name' ? (
                        <Cell key={field.slug}>
                            <Flex direction="column" width={1}>
                                <Box
                                    color="gray800"
                                    css={{ ellipsis: true, width: '100%' }}
                                    title={props.customField.name}
                                >
                                    {props.customField.name}
                                </Box>
                                {props.customField.conditionCustomField && (
                                    <Box
                                        color="gray500"
                                        css={{ ellipsis: true, width: '100%' }}
                                        title={props.customField.conditionCustomField.name}
                                    >
                                        {translate(
                                            'd_pend_de_1_52961',
                                            props.customField.conditionCustomField.name
                                        )}
                                    </Box>
                                )}
                            </Flex>
                        </Cell>
                    ) : field.slug === 'category' ? (
                        <Cell key={field.slug}>{props.customField.customFieldCategoryName}</Cell>
                    ) : field.slug === 'fieldType' ? (
                        <Cell key={field.slug} width={250}>
                            <Flex>
                                <Box color="gray800" css={{ width: '18px' }}>
                                    <I
                                        icon={customFieldTypeToIcon(
                                            props.customField.fieldType,
                                            props.customField.fieldProperty
                                        )}
                                    />
                                </Box>

                                <Spacer width="2" />

                                <Box color="gray800">{translate(props.customField.fieldType)}</Box>
                            </Flex>
                        </Cell>
                    ) : field.slug === 'numberOfTimesUsed' ? (
                        <Cell key={field.slug} width={200}>
                            {props.customField.numberOfTimesUsed === 0 ? (
                                props.customField.numberOfTimesUsed
                            ) : (
                                <Box
                                    color="primary700"
                                    css={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline'
                                    }}
                                    onClick={() => {
                                        history.push(
                                            HeaventPaths.CUSTOM_FIELD_USAGE(
                                                props.organizationId,
                                                props.segmentId,
                                                props.customField.id
                                            )
                                        );
                                    }}
                                >
                                    {props.customField.numberOfTimesUsed}
                                </Box>
                            )}
                        </Cell>
                    ) : field.slug === 'state' ? (
                        <Cell key={field.slug} width={200}>
                            {props.customField.isPrivate ? (
                                <Badge color="gray" leftIcon="lock">
                                    {translate('Private')}
                                </Badge>
                            ) : (
                                <Badge color="primary" leftIcon="globe">
                                    {translate('Public')}
                                </Badge>
                            )}
                        </Cell>
                    ) : field.slug === 'values' ? (
                        <Cell key={field.slug}>
                            {props.customField.values.map(({ value }) => value).join(', ')}
                        </Cell>
                    ) : field.slug === 'variable' ? (
                        <Cell key={field.slug}>{`{user.${props.customField.slug}}`}</Cell>
                    ) : field.slug === 'variety' ? (
                        <Cell key={field.slug}>
                            {varietyToTranslation(translate, props.customField.variety)}
                        </Cell>
                    ) : field.slug === 'createdBy' ? (
                        <Cell key={field.slug}>
                            {props.customField.createdBy && (
                                <AvatarNameEmail
                                    userInfo={props.customField.createdBy}
                                    hasName={true}
                                />
                            )}
                        </Cell>
                    ) : (
                        <Cell key={field.slug} />
                    ))}

                <CellControls justify="end">
                    <Tooltip>
                        <Trigger>
                            <Box>
                                <I
                                    icon="pen"
                                    onClick={() => {
                                        history.push(
                                            HeaventPaths.EDIT_CUSTOM_FIELD(
                                                props.organizationId,
                                                props.segmentId,
                                                props.customField.id
                                            )
                                        );
                                    }}
                                />
                            </Box>
                        </Trigger>

                        <Content placement="top">{translate('_diter_62574')}</Content>
                    </Tooltip>

                    <CustomFieldRowDropdown
                        customField={props.customField}
                        organizationId={props.organizationId}
                        segmentId={props.segmentId}
                        reload={props.reload}
                    />
                </CellControls>
            </Row>
        </>
    );
};
