import { Blank } from 'common-front/src/components/blank/blank';
import { Svgs } from 'common-front/src/util/assets';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const PositionsListBlankMobile = () => {
    const translate = useTranslate();

    return (
        <Blank
            imageSrc={Svgs.PositionsBlank}
            title={translate('il_n_y_a_pas_de_55837')}
            subtitle={translate('cr_ez_de_nouvel_87003')}
            css={{ flex: 1, padding: '0 $4' }}
        />
    );
};
