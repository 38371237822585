import * as React from 'react';
import { Box } from '../box';
import { Grid } from '../grid';
import { CSS } from '../stitches';
import { TableContextProvider } from './tableContext';

interface ITableGridProps {
    children: React.ReactNode;
    css?: CSS;
    headerRow: React.ReactNode;
    paginationRow?: React.ReactNode;
}

export const TableGrid = (props: ITableGridProps) => {
    const gridTemplateRows = props.paginationRow ? '44px 1fr 56px' : '44px 1fr';

    return (
        <TableContextProvider>
            <Grid
                gridtemplaterows={gridTemplateRows}
                css={{
                    height: '100%',
                    overflow: 'auto hidden',
                    width: '100%',
                    '& > div:last-child': {
                        borderBottomLeftRadius: '$1',
                        borderBottomRightRadius: '$1'
                    },
                    ...(props.css as any)
                }}
            >
                {props.headerRow}

                <Box
                    css={{
                        borderLeft: '1px solid $gray200',
                        borderRight: '1px solid $gray200',
                        overflowY: 'auto',
                        '& .table-row': {
                            borderLeft: 'none',
                            borderRight: 'none'
                        }
                    }}
                >
                    {props.children}
                </Box>

                {props.paginationRow}
            </Grid>
        </TableContextProvider>
    );
};
