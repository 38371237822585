import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import * as React from 'react';
import { PropsWithChildren } from 'react';

export interface IOrganizationMenuItemPropsMobile {
    icon: IIcon;
    onClick: () => void;
}

export const OrganizationMenuItemMobile = ({
    icon,
    children,
    onClick
}: PropsWithChildren<IOrganizationMenuItemPropsMobile>) => (
        <Flex
            gap="5"
            align="center"
            css={{ color: '$gray800', fontWeight: '$medium' }}
            onClick={onClick}
        >
            <I icon={icon} />
            <span>{children}</span>
        </Flex>
    );
