import { LocalEvent, LocalOrganization } from 'common-front/src/localDatabase';
import * as React from 'react';
import { useEventContext } from '../events/show/eventContext';
import { useOrganizationContext } from '../organization/organizationContext';

export function useLocalEventState<S>(
    key: keyof LocalEvent,
    initialState: S | (() => S)
): [S, React.Dispatch<S>] {
    const { localEvent, updateLocalEvent } = useEventContext();
    const [state, _setState] = React.useState((localEvent?.[key] as S) ?? initialState);
    const setState = React.useCallback(
        async (newState: S) => {
            _setState(newState);
            await updateLocalEvent({ [key]: newState });
        },
        [_setState, updateLocalEvent]
    );

    return [state, setState];
}

export function useLocalOrganizationState<S>(
    key: keyof LocalOrganization,
    initialState: S | (() => S)
): [S, React.Dispatch<S>] {
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const [state, _setState] = React.useState((localOrganization?.[key] as S) ?? initialState);
    const setState = React.useCallback(
        async (newState: S) => {
            _setState(newState);
            await updateLocalOrganization({ [key]: newState });
        },
        [_setState, updateLocalOrganization]
    );

    return [state, setState];
}
