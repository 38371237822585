import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IContremarqueClubFranceProps {
    event: DocumentUserCustomBadgeFragment;
}

export const ContremarqueClubFrance = (props: IContremarqueClubFranceProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/clubFrance/bg_contremarque_para.jpg) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    fontSize: '18px',
                    fontWeight: '700',
                    left: '55px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '270px'
                }}
            >
                {ui.clubFrancePopulation}
            </Box>

            <Box
                width={333}
                css={{
                    fontSize: '18px',
                    fontWeight: '700',
                    left: '55px',
                    lineHeight: '20px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '297px'
                }}
            >
                {ui.clubFranceSousPopulation}
            </Box>

            <Box
                css={{
                    fontWeight: '700',
                    left: '55px',
                    position: 'absolute',
                    top: '345px'
                }}
            >
                {ui.name}
            </Box>

            <Flex
                direction="column"
                align="center"
                css={{
                    gap: '2px',
                    left: '318px',
                    position: 'absolute',
                    top: '358px'
                }}
            >
                <Box css={{ fontSize: '8px', fontWeight: '700' }}>
                    {vr.weezeventParticipantInfo?.idBarcode ?? ''}
                </Box>

                <Box height={64} width={64}>
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=64&width=64`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};
