import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { useUserContext } from 'common-front/src/userContext';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { SupportedLanguage } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { range } from 'lodash-es';
import * as React from 'react';

interface IRepeatSlotModalProps {
    showHours: boolean;

    onClose(): void;
    onSubmit(value: number, unit: string): void;
}

export const RepeatSlotModal = (props: IRepeatSlotModalProps) => {
    const translate = useTranslate();
    const { user } = useUserContext();

    return (
        <FormModal
            buttonText={translate('r_p_ter_28960')}
            icon="sliders"
            initialValues={{
                value: 1,
                unit: props.showHours ? 'hour' : 'day'
            }}
            size="md"
            title={translate('r_p_ter_le_cr_n_68038')}
            onClose={props.onClose}
            onSubmit={async (values) => {
                props.onSubmit(values.value, values.unit);
                props.onClose();
            }}
        >
            <Flex align="center" gap="3" wrap="wrap">
                <Box>
                    {translate('r_p_ter_pour_le_06048')}
                    {SupportedLanguage.Fr !== user?.language &&
                        ' ' + translate('prochain_e_s_04262')}
                </Box>

                <Box width={100}>
                    <Select name="value" parseInt={true}>
                        {range(1, 31).map((i) => (
                                <option key={i} value={i}>
                                    {i}
                                </option>
                            ))}
                    </Select>
                </Box>

                {SupportedLanguage.Fr === user?.language && (
                    <Box>{translate('prochain_e_s_04262')}</Box>
                )}

                <Box width={120}>
                    <Select name="unit">
                        {props.showHours && (
                            <option value="hour">{translate('heures_17899')}</option>
                        )}
                        <option value="day">{translate('jours_43822')}</option>
                        <option value="week">{translate('semaines_53228')}</option>
                    </Select>
                </Box>
            </Flex>
        </FormModal>
    );
};
