import { Form } from 'common-front/src/components/form/form';
import { DOMAIN } from 'common-front/src/consts';
import { Button } from 'common-front/src/designSystem/components/button';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EmailType,
    EventId,
    FormId,
    LoginCodeSource,
    OrganizationId
} from 'common/src/generated/types';
import {
    EmailTemplateInputService,
    IUpdateEmailTemplateValues
} from 'common/src/input/emailTemplateInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { getDisplayName } from 'common/src/vo/customBadge';
import { ALL_USER_DOCUMENT_TYPE } from 'common/src/vo/documentType';
import * as React from 'react';
import {
    useEmailTemplateQuery,
    useEmailTemplateUpdateMutation
} from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';

interface ITemplateProps {
    organizationId: OrganizationId;
    eventId: Emptyable<EventId>;
    formId: Emptyable<FormId>;
    emailType: EmailType;
    source: Emptyable<LoginCodeSource>;
}

export const Template = (props: ITemplateProps) => {
    const { history, translate } = useHeavent();
    const { organization } = useOrganizationContext();
    const { data, loader } = useEmailTemplateQuery({
        organizationId: props.organizationId,
        eventId: props.eventId,
        formId: props.formId,
        source: props.source,
        emailType: props.emailType
    });
    const { mutate, isLoading } = useEmailTemplateUpdateMutation();
    const emailTemplateInput = useService(EmailTemplateInputService);
    const validateService = useService(ValidateService);
    const showAttachments =
        props.emailType === EmailType.Accreditations || props.emailType === EmailType.Assignments;

    return (
        loader || (
            <Form
                direction="column"
                width={1}
                initialValues={{
                    emailTemplate: emailTemplateInput.emailTemplateDefault(
                        props.emailType,
                        DOMAIN,
                        data.organization.emailTemplate,
                        props.organizationId,
                        props.eventId,
                        props.formId
                    )
                }}
                validate={validateService.validateForForm(
                    emailTemplateInput.updateEmailTemplateSchema()
                )}
                onSubmit={async (values: IUpdateEmailTemplateValues) => {
                    await mutate({
                        organizationId: props.organizationId,
                        eventId: props.eventId,
                        formId: props.formId,
                        source: props.source,
                        emailTemplate: values.emailTemplate
                    });
                }}
                render={({ handleSubmit, values }) => (
                        <Flex direction="column">
                            <Box color="gray900" fontSize="textLg" fontWeight="medium">
                                {translate('contenu_de_l_e_19848')}
                            </Box>

                            <Spacer height="6" />

                            <CheckboxText name="emailTemplate.useDefault">
                                {translate('utiliser_le_mod_93521')}
                            </CheckboxText>

                            <Spacer height="6" />

                            {!values.emailTemplate.useDefault && (
                                <>
                                    <TextInput
                                        label={translate('sujet_de_l_e_ma_66809')}
                                        name="emailTemplate.subject"
                                    />

                                    <Spacer height="6" />

                                    <RichSelect
                                        isCreateVisible={true}
                                        isSearchVisible={true}
                                        label={
                                            <LabelOptional>
                                                {translate('_metteur_de_l_e_51616')}
                                            </LabelOptional>
                                        }
                                        hint={translate('cet_metteur_se_80394')}
                                        name="emailTemplate.emailSenderId"
                                        onCreateClick={() => {
                                            history.push(
                                                HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                                                    props.organizationId
                                                )
                                            );
                                        }}
                                    >
                                        {(data.organization?.emailsSenders ?? []).map(
                                            (emailSender) => (
                                                    <option
                                                        key={emailSender.id}
                                                        value={emailSender.id}
                                                    >
                                                        {emailSender.name}
                                                    </option>
                                                )
                                        )}
                                    </RichSelect>

                                    <Spacer height="6" />

                                    <RichEditor
                                        label={translate('contenu_de_l_e_19848')}
                                        name="emailTemplate.content"
                                        hint={
                                            <Box
                                                dangerouslySetInnerHTML={{
                                                    __html: translate('vous_pouvez_ajo_23408')
                                                }}
                                                css={{
                                                    '& a': {
                                                        color: '$primary700'
                                                    }
                                                }}
                                            />
                                        }
                                    />

                                    <Spacer height="6" />

                                    {showAttachments && (
                                        <>
                                            <RichSelect
                                                multiple={true}
                                                isSearchVisible={true}
                                                label={translate('pi_ce_s_jointe_82370')}
                                                name="emailTemplate.attachments"
                                            >
                                                {organization.customBadges.map((customBadge) => (
                                                        <option
                                                            key={customBadge}
                                                            value={customBadge}
                                                        >
                                                            {getDisplayName(customBadge)}
                                                        </option>
                                                    ))}

                                                {ALL_USER_DOCUMENT_TYPE.map((documentType) => (
                                                        <option
                                                            key={documentType}
                                                            value={documentType}
                                                        >
                                                            {translate(documentType)}
                                                        </option>
                                                    ))}
                                            </RichSelect>

                                            <Spacer height="6" />
                                        </>
                                    )}
                                </>
                            )}

                            <Flex>
                                <Button onClick={handleSubmit} isLoading={isLoading}>
                                    {translate('enregistrer_le_77369')}
                                </Button>
                            </Flex>
                        </Flex>
                    )}
            />
        )
    );
};
