import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { MassAssignSlotsQuery, PositionsSlotId } from 'common/src/generated/types';
import { usePositionSlotIsRenamedRange } from 'common/src/hooks/usePositionSlotIsRenamedRange';
import { addOrRemove } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IMassAssignSlotRowProps {
    positionsSlotsIds: PositionsSlotId[];
    slot: MassAssignSlotsQuery['event']['positionsSlots']['nodes'][0];

    change(name: string, value: any): void;
}

export const MassAssignSlotRow = (props: IMassAssignSlotRowProps) => {
    const translate = useTranslate();
    const { isRenamed, finalName, rangeString } = usePositionSlotIsRenamedRange(props.slot);

    return (
        <Row>
            <Cell width={48} css={{ paddingRight: 0 }}>
                <Checkbox
                    state={
                        props.positionsSlotsIds.includes(props.slot.id) ? 'checked' : 'unchecked'
                    }
                    onClick={(state) => {
                        props.change(
                            'massAssign.positionsSlotsIds',
                            addOrRemove(props.positionsSlotsIds, props.slot.id, state === 'checked')
                        );
                    }}
                />
            </Cell>

            <Cell direction="column" gap="1" align="start" justify="center">
                <Box font="gray900 textSm medium">{props.slot.position.name}</Box>
                <Box color="gray500">{props.slot.positionCategory.name}</Box>
            </Cell>

            <Cell direction="column" gap="1" align="start" justify="center">
                <Box color="gray900">{finalName}</Box>
                {isRenamed && <Box color="gray500">{rangeString}</Box>}
            </Cell>

            <Cell>
                <Badge
                    leftIcon="user-group"
                    color={
                        props.slot.assignedResources >= props.slot.resources ? 'success' : 'warning'
                    }
                >
                    {translate(
                        '_1_2_membres_04485',
                        props.slot.assignedResources,
                        props.slot.resources
                    )}
                </Badge>
            </Cell>
        </Row>
    );
};
