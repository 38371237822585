import { Button } from 'common-front/src/designSystem/components/button';
import {
    closeOtherDropdowns,
    Dropdown
} from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

interface IDelegationsHeaderProps {
    name: string;
}

export const DelegationsHeader = (props: IDelegationsHeaderProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();

    return (
        <Flex width={1} gap="4">
            <Box font="gray900 displaySm semiBold" css={{ flex: '1' }}>
                {props.name}
            </Box>

            <Button
                onClick={() => {
                    history.push(HeaventPaths.CREATE_DELEGATION(organizationId, eventId));
                }}
            >
                {translate('cr_er_une_d_l_g_23035')}
            </Button>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        icon="file-import"
                        onClick={(e) => {
                            closeOtherDropdowns(e.target);

                            history.push(
                                HeaventPaths.EVENT_DELEGATIONS_IMPORT(organizationId, eventId)
                            );
                        }}
                    >
                        {translate('importer_des_d_05454')}
                    </ItemIcon>
                </Menu>
            </Dropdown>
        </Flex>
    );
};
