import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { AccreditationSlotsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { NumberService } from 'common/src/services/numberService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';

interface IAccreditationSlotsSlotRowProps {
    slot: AccreditationSlotsQuery['event']['accreditationsSlots']['nodes'][0];
}

export const AccreditationSlotsSlotRow = ({ slot }: IAccreditationSlotsSlotRowProps) => {
    const dateTimeService = useService(DateTimeService);
    const numberService = useService(NumberService);

    return (
        <Row>
            <Cell>
                <Box font="gray900 textSm medium">{slot.name}</Box>
            </Cell>

            <Cell>
                {slot.date
                    ? dateTimeService.toLocaleString(
                          slot.date,
                          LocaleFormats.DateOnly.WeekdayLongMonthLong
                      )
                    : '-'}
            </Cell>

            <Cell>{numberService.toNumberOrInfinity(slot.maxResources)}</Cell>
        </Row>
    );
};
