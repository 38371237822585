import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import Sitemap from 'svgComponents/fontawesome/sitemap.svg';
import User from 'svgComponents/fontawesome/user.svg';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { RichText } from '../../../designSystem/components/richEditor/richText';
import { Spacer } from '../../../designSystem/components/spacer';
import { Span } from '../../../designSystem/components/span';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { DateTimeService } from '../../../services/dateTimeService';
import { useService } from '../../../util/dependencies/dependencies';
import { LocaleFormats } from '../../../util/luxon';

interface IP2NPositionProps {
    psui: DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'][0];
}

export const P2NPosition = (props: IP2NPositionProps) => {
    const dateTimeService = useService(DateTimeService);
    const category = props.psui.positionCategory;
    const position = props.psui.position;
    const slot = props.psui.positionSlot;
    const leaders = sortBy(
        uniqBy(position.leaders.concat(category.leaders), (l) => l.userInfo.id),
        (l) => l.userInfo.name
    )
        .map((l) => l.userInfo.name)
        .join(', ');

    return (
        <Flex direction="column">
            <Box
                css={{
                    color: 'black',
                    fontWeight: '700',
                    textTransform: 'uppercase'
                }}
            >
                {dateTimeService.toLocaleString(
                    slot.range.start!,
                    LocaleFormats.DateOnly.WeekdayLongMonthLong
                )}
            </Box>

            <Spacer height="2" />

            <Flex align="center" gap="2">
                <Flex
                    align="center"
                    justify="center"
                    height={16}
                    width={16}
                    css={{
                        '& svg': {
                            fill: 'black',
                            height: '100%',
                            width: '100%'
                        }
                    }}
                >
                    <Sitemap />
                </Flex>

                <Box color="gray800">
                    <Span css={{ fontWeight: '600' }}>{position.name}</Span> | De{' '}
                    {slot.range.start!.toFormat('H:mm')} à {slot.range.end!.toFormat('H:mm')}
                </Box>
            </Flex>

            <Spacer height="1" />

            <Flex gap="2">
                <Flex
                    align="center"
                    justify="center"
                    height={16}
                    width={16}
                    css={{
                        '& svg': {
                            fill: 'black',
                            height: '100%',
                            width: '100%'
                        }
                    }}
                >
                    <User />
                </Flex>

                <Box color="gray800">
                    <Span css={{ fontWeight: '600' }}>Responsable : </Span>
                    {leaders}
                </Box>
            </Flex>

            <Spacer height="2" />

            <Box css={{ color: 'black' }}>
                <RichText
                    text={position.description}
                    css={{
                        '& > p:first-child': { marginTop: 0 },
                        '& > p:last-child': { marginBottom: 0 }
                    }}
                />
            </Box>
        </Flex>
    );
};
