import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { theme } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

export const Typography = () => {
    const [text, setText] = React.useState(`Texte d'exemple`);
    const [color, setColor] = React.useState('gray900');
    const [size, setSize] = React.useState('2');
    const [weight, setWeight] = React.useState('1');

    return (
        <Flex direction="column" gap="6">
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Typography
            </Box>

            <Flex gap="3">
                <select
                    value={color}
                    onChange={(e) => {
                        setColor(e.target.value);
                    }}
                >
                    {Object.keys(theme.colors).map((c) => (
                            <option value={c} key={c}>
                                {c}
                            </option>
                        ))}
                </select>

                <select
                    value={size}
                    onChange={(e) => {
                        setSize(e.target.value);
                    }}
                >
                    {Object.entries(theme.fontSizes).map(([s, { value }]) => (
                            <option value={s} key={s}>
                                {value}
                            </option>
                        ))}
                </select>

                <select
                    value={weight}
                    onChange={(e) => {
                        setWeight(e.target.value);
                    }}
                >
                    {Object.entries(theme.fontWeights).map(([w, { value }]) => (
                            <option value={w} key={w}>
                                {value}
                            </option>
                        ))}
                </select>

                <input
                    type="text"
                    value={text}
                    onChange={(e) => {
                        setText(e.target.value);
                    }}
                />
            </Flex>

            <Flex>
                <Box color={color as any} fontSize={size as any} fontWeight={weight as any}>
                    {text}
                </Box>
            </Flex>
        </Flex>
    );
};
