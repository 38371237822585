import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { DelegationId, DelegationsSortAttributes } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useDelegationsMassDeleteMutation } from '../../generated/graphqlHooks';
import {
    EventDelegationsMassEditModal,
    OrganizationDelegationsMassEditModal
} from '../massEdit/delegationsMassEditModal';

export const DelegationsActions = () => {
    const translate = useTranslate();
    const {
        eventId,
        isSegmentSelected,
        massEditSegmentId,
        massEditSelectedIds,
        numberOfSelected,
        onMassEditSuccess,
        organizationId,
        reload,
        segment
    } = useSegmentsContext<DelegationId, DelegationsSortAttributes>();
    const { mutate: delegationsMassDelete } = useDelegationsMassDeleteMutation();
    const [isMassEditVisible, setIsMassEditVisible] = React.useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const finalNumberOfSelected = isSegmentSelected ? segment.count : numberOfSelected;

    return (
        <>
            <Button
                onClick={() => {
                    setIsMassEditVisible(true);
                }}
            >
                {translate('_diter_une_prop_26724')}
            </Button>

            <Dropdown>
                <Trigger>
                    <Button color="white" leftIcon="ellipsis-vertical" />
                </Trigger>

                <Menu placement="bottom-end">
                    <ItemIcon
                        color="red"
                        icon="trash-can"
                        onClick={() => {
                            setIsDeleteModalOpen(true);
                        }}
                    >
                        {translate('supprimer_43083')}
                    </ItemIcon>
                </Menu>
            </Dropdown>

            {isMassEditVisible &&
                (eventId ? (
                    <EventDelegationsMassEditModal
                        eventId={eventId!}
                        ids={massEditSelectedIds}
                        organizationId={organizationId}
                        segmentId={massEditSegmentId}
                        onClose={() => {
                            setIsMassEditVisible(false);
                        }}
                        onSuccess={onMassEditSuccess}
                    />
                ) : (
                    <OrganizationDelegationsMassEditModal
                        ids={massEditSelectedIds}
                        organizationId={organizationId}
                        segmentId={massEditSegmentId}
                        onClose={() => {
                            setIsMassEditVisible(false);
                        }}
                        onSuccess={onMassEditSuccess}
                    />
                ))}

            {isDeleteModalOpen && (
                <DeleteModal
                    text={translate('suppression_de_99024', finalNumberOfSelected)}
                    subtext={[translate('_tes_vous_s_r_d_34990')]}
                    buttonText={translate('supprimer_43083')}
                    onDelete={async () => {
                        await delegationsMassDelete({
                            organizationId,
                            eventId,
                            delegationsIds: massEditSelectedIds,
                            segmentId: massEditSegmentId
                        });

                        reload();
                    }}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                />
            )}
        </>
    );
};
