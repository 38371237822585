import { Images } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

interface IAuthPageProps {
    children: React.ReactNode;
}

export const AuthPage = (props: IAuthPageProps) => (
        <Flex align="center" height={1} width={1}>
            <Flex
                direction="column"
                align="center"
                justify="center"
                height={1}
                css={{
                    background: '$primary700',
                    color: 'white',
                    flex: '1',
                    fontSize: '28px'
                }}
            >
                <Box textAlign="center">
                    Basile, la solution de gestion bénévoles <br />
                    pour les organisateurs.
                </Box>

                <Spacer height="9" />

                <Box textAlign="center">
                    Notre mission est de : <br /> <b>Mobiliser</b>, <b>Encadrer</b> et{' '}
                    <b>Fidéliser</b> <br />
                    votre communauté de bénévoles.
                </Box>
            </Flex>

            <Flex direction="column" align="center" height={1} css={{ flex: '1' }}>
                <Spacer height="10" />

                <Box>
                    <img src={Images.AmicaleLogo} height="100" alt="Logo Basile" />
                </Box>

                <Box height={148} />

                <Flex direction="column" width={360}>
                    {props.children}
                </Flex>
            </Flex>
        </Flex>
    );
