import { Empty } from 'common-front/src/components/empty/empty';
import { useUserUpdateSlotsInfosQuery } from 'common-front/src/generated/graphqlHooks';
import { RegisterSlotDisplay } from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { UserUpdateSlotsForm } from './userUpdateSlotsForm';

export const UserUpdateSlotsLoader = () => {
    const { organizationId, eventId, userInfoId } = useParams();
    const { data, loader } = useUserUpdateSlotsInfosQuery({ organizationId, eventId, userInfoId });
    const forms = React.useMemo(() => (data.event?.forms ?? []).filter((form) => form.slotDisplay !== RegisterSlotDisplay.Hide), [data.organization, data.event]);

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={HeaventPaths.EDIT_USER_SLOTS_FORM(
                        ':organizationId',
                        ':eventId',
                        ':userInfoId',
                        ':formId'
                    )}
                    children={
                        <UserUpdateSlotsForm
                            event={data.event}
                            forms={forms}
                            userInfo={data.organization.userInfo}
                        />
                    }
                />

                <Route
                    children={
                        <Empty
                            path={HeaventPaths.EDIT_USER_SLOTS_FORM(
                                organizationId,
                                eventId,
                                userInfoId,
                                forms[0].id
                            )}
                            replace={true}
                        />
                    }
                />
            </Switch>
        );
    }
};
