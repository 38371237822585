import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { FormWizard, FormWizardPage } from 'common-front/src/components/form/formWizard';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Portal } from 'common-front/src/components/portal';
import {
    EventId,
    MassAssignStrategy,
    MassPreAssignLevel,
    SegmentId,
    UsersInfoId
} from 'common/src/generated/types';
import {
    IMassPreAssignValues,
    MassPreAssignInputService
} from 'common/src/input/massPreAssignInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useVolunteersRegistrationsMassPreAssignMutation } from '../../generated/graphqlHooks';
import { MassPreAssignStep1 } from './massPreAssignStep1';
import { MassPreAssignStep2Categories } from './massPreAssignStep2Categories';
import { MassPreAssignStep2Positions } from './massPreAssignStep2Positions';

interface IMassPreAssignModalProps {
    eventId: EventId;
    numberOfSelected: number;
    segmentId: Emptyable<SegmentId>;
    usersInfosIds: UsersInfoId[];

    onClose(): void;
    onSuccess(): void;
}

export const MassPreAssignModal = (props: IMassPreAssignModalProps) => {
    const translate = useTranslate();
    const massPreAssignInput = useService(MassPreAssignInputService);
    const { mutate } = useVolunteersRegistrationsMassPreAssignMutation();

    return (
        <Portal>
            <FullScreenPopup
                category={translate('pr_affectation_12256')}
                title={translate('pr_affecter_le_71377')}
                close={props.onClose}
            >
                <CenteredContainer>
                    <FormWizard
                        initialValues={{
                            massPreAssign: {
                                level: MassPreAssignLevel.Categories,
                                segmentId: props.segmentId,
                                strategy: MassAssignStrategy.Add,
                                positionsIds: [],
                                positionsCategoriesIds: [],
                                usersInfosIds: props.usersInfosIds
                            }
                        }}
                        isEdit={false}
                        onSubmit={async (values: IMassPreAssignValues) => {
                            await mutate({
                                eventId: props.eventId,
                                massPreAssign: values.massPreAssign
                            });

                            props.onSuccess();
                            props.onClose();
                        }}
                    >
                        <FormWizardPage
                            title={translate('initialisation_38640')}
                            schema={massPreAssignInput.massPreAssignStep1Schema()}
                            render={({ handleSubmit }) => <MassPreAssignStep1 handleSubmit={handleSubmit} />}
                        />

                        <FormWizardPage
                            title={translate('pr_affectation_68999')}
                            schema={massPreAssignInput.massPreAssignStep2Schema()}
                            render={(formProps: FormRenderProps<IMassPreAssignValues>) => {
                                if (
                                    formProps.values.massPreAssign.level ===
                                    MassPreAssignLevel.Categories
                                ) {
                                    return (
                                        <MassPreAssignStep2Categories
                                            eventId={props.eventId}
                                            numberOfSelected={props.numberOfSelected}
                                            values={formProps.values}
                                            change={formProps.form.change}
                                            handleSubmit={formProps.handleSubmit}
                                        />
                                    );
                                } else {
                                    return (
                                        <MassPreAssignStep2Positions
                                            eventId={props.eventId}
                                            numberOfSelected={props.numberOfSelected}
                                            values={formProps.values}
                                            change={formProps.form.change}
                                            handleSubmit={formProps.handleSubmit}
                                        />
                                    );
                                }
                            }}
                        />
                    </FormWizard>
                </CenteredContainer>
            </FullScreenPopup>
        </Portal>
    );
};
