import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import {
    EventUserProfilePrivateFields,
    OrganizationUserProfilePrivateFields
} from './privateFields/userProfilePrivateFields';
import { EventUserProfileTabs, OrganizationUserProfileTabs } from './userProfileTabs';

export const UserProfile = () => {
    const {
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();

    return (
        <Flex
            direction="column"
            gap="6"
            width={1}
            css={{
                background: '$gray50',
                height: 'fit-content',
                minHeight: '100%',
                padding: '$8 $7'
            }}
        >
            <Flex
                align="center"
                gap="2"
                css={{
                    color: '$primary700',
                    cursor: 'pointer'
                }}
            >
                <Box fontSize="textXs">
                    <I icon="arrow-left" />
                </Box>

                <Box>Retour</Box>
            </Flex>

            <Flex gap="6">
                <Box width={348}>
                    {eventId ? (
                        <EventUserProfilePrivateFields />
                    ) : (
                        <OrganizationUserProfilePrivateFields />
                    )}
                </Box>

                <Box css={{ flex: '1' }}>
                    {eventId ? (
                        <EventUserProfileTabs
                            basePath={HeaventPaths.EVENT_USER_PROFILE(
                                organizationId,
                                eventId,
                                userInfoId
                            )}
                        />
                    ) : (
                        <OrganizationUserProfileTabs
                            basePath={HeaventPaths.COMMUNITY_USER_PROFILE(
                                organizationId,
                                userInfoId
                            )}
                        />
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};
