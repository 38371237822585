import { RadioRow } from 'common-front/src/designSystem/form/radioRow';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { IWeezeventConnectionValues } from 'common/src/input/weezeventInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useWeezeventEventsQuery } from '../../../../generated/graphqlHooks';

interface IWeezeventConnectionStep2Props {
    values: IWeezeventConnectionValues;
}

export const WeezeventConnectionStep2 = (props: IWeezeventConnectionStep2Props) => {
    const translate = useTranslate();
    const { data, loader } = useWeezeventEventsQuery({
        apiKey: props.values.weezevent.apiKey,
        email: props.values.weezevent.email,
        password: props.values.weezevent.password
    });

    return (
        loader || (
            <>
                <Box color="gray900" fontWeight="medium">
                    {translate('_quel_venemen_13790')}
                </Box>

                <Flex direction="column" css={{ overflowY: 'auto' }}>
                    {data.weezevent.events.map((event) => (
                            <React.Fragment key={event.id}>
                                <Spacer height="3" />

                                <RadioRow name="weezevent.idEvent" value={event.id}>
                                    {event.name}
                                </RadioRow>
                            </React.Fragment>
                        ))}
                </Flex>
            </>
        )
    );
};
