import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserPlanning } from 'common/src/documents/userPlanning/userPlanning';
import { DocumentType } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useUserPlanningDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentsAssignedUserSelector } from './documentsAssignedUserSelector';
import { DocumentShadow } from './documentShadow';

export const UserPlanningDocument = () => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { data, loader } = useUserPlanningDocumentQuery({ eventId, userInfoId });

    return (
        <Flex direction="column" height={1} width={1} css={{ overflowY: 'auto', padding: '$7' }}>
            <DocumentHeader
                documentType={DocumentType.UserPlanning}
                endAt={data.event?.endAt}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segments={data.event?.segments ?? []}
                showDate={true}
                startAt={data.event?.startAt}
                title={translate('plannings_indiv_80073')}
            />

            <Spacer height="7" />

            {loader || (
                <Flex direction="column" css={{ flex: '1' }}>
                    <DocumentsAssignedUserSelector
                        getPath={(newUserInfoId) => HeaventPaths.DOCUMENTS_USER_PLANNING(
                                organizationId,
                                eventId,
                                newUserInfoId
                            )}
                    />

                    <DocumentShadow>
                        <UserPlanning event={data.event} />
                    </DocumentShadow>
                </Flex>
            )}
        </Flex>
    );
};
