import { capitalize, sum } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { Cell } from '../../designSystem/components/table/cell';
import { HeaderCell } from '../../designSystem/components/table/headerCell';
import { HeaderRow } from '../../designSystem/components/table/headerRow';
import { Row } from '../../designSystem/components/table/row';
import { Table } from '../../designSystem/components/table/table';
import { DateTimeService } from '../../services/dateTimeService';
import { DurationService } from '../../services/durationService';
import { isNonEmptyArray } from '../../util/array';
import { useService, useTranslate } from '../../util/dependencies/dependencies';
import { LocaleFormats } from '../../util/luxon';
import { IGroupedAssignment } from './groupAssignmentsByPositionDay';

interface IUserPlanningListTableProps {
    assignments: IGroupedAssignment[];
    showHours: boolean;
}

export const UserPlanningListTable = (props: IUserPlanningListTableProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const durationService = useService(DurationService);

    return (
        <Flex css={{ padding: '$6 $8' }}>
            <Table>
                <HeaderRow>
                    <HeaderCell css={{ px: '$3' }}>{translate('nom_de_la_missi_64605')}</HeaderCell>
                    <HeaderCell width={380} css={{ px: '$3' }}>
                        {translate('Date')}
                    </HeaderCell>
                    {props.showHours && (
                        <HeaderCell width={120} css={{ px: '$3' }}>
                            {translate('total_07173')}
                        </HeaderCell>
                    )}
                </HeaderRow>

                {props.assignments.map((assignment, index) => {
                    const hasSlotsNames = isNonEmptyArray(assignment.slotsNames);
                    const minutes = sum(
                        assignment.ranges.map((i) => i.toDuration('minutes').minutes)
                    );

                    return (
                        <Row key={index} css={{ height: hasSlotsNames ? '54px' : '44px' }}>
                            <Cell align="center" css={{ px: '$3' }}>
                                <Box
                                    font="gray900 textSm medium"
                                    width={1}
                                    css={{ clamp: '2' }}
                                    title={assignment.name}
                                >
                                    {assignment.name}
                                </Box>
                            </Cell>

                            <Cell
                                direction="column"
                                justify="center"
                                gap="1"
                                width={380}
                                css={{ px: '$3' }}
                            >
                                {hasSlotsNames && (
                                    <Box color="gray900" width={1}>
                                        {assignment.slotsNames.join(', ')}
                                    </Box>
                                )}

                                <Box width={1}>
                                    {capitalize(
                                        dateTimeService.toLocaleString(
                                            assignment.ranges[0].start!,
                                            LocaleFormats.DateOnly.WeekdayShortMonthLong
                                        )
                                    )}{' '}
                                    {assignment.ranges
                                        .map((range) => `${range.start!.toFormat(
                                                'HH:mm'
                                            )}-${range.end!.toFormat('HH:mm')}`)
                                        .join(' | ')}
                                </Box>
                            </Cell>

                            {props.showHours && (
                                <Cell width={120} css={{ px: '$3' }}>
                                    {durationService.minutesToHoursMinutesString(minutes)}
                                </Cell>
                            )}
                        </Row>
                    );
                })}
            </Table>
        </Flex>
    );
};
