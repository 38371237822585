import { Empty } from 'common-front/src/components/empty/empty';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { Box } from 'common/src/designSystem/components/box';
import { Grid } from 'common/src/designSystem/components/grid';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useFormQuery } from '../../generated/graphqlHooks';
import { FormElements } from '../update/elements/formElements';
import { FormEmails } from '../update/emails/formEmails';
import { FormParameters } from '../update/parameters/formParameters';
import { FormContextProvider, useFormContext } from './formContext';
import { FormMenu } from './formMenu';
import { FormIntegration } from './integration/formIntegration';
import { FormPreview } from './preview/formPreview';

interface IFormProps {
    closePath: string;
}

const Form = (props: IFormProps) => {
    const translate = useTranslate();
    const { basePath, organizationId, formId, isSaving, setIsSaving } = useFormContext();
    const { data } = useFormQuery({ organizationId, formId });

    return (
        <FullScreenPopup
            color="dark"
            category={translate('cr_ation_d_un_f_67278')}
            closePath={props.closePath}
            title={data.organization?.form.name ?? '-'}
            button={
                <Button
                    isLoading={isSaving}
                    onClick={() => {
                        setIsSaving(true);
                    }}
                >
                    {translate('enregistrer_06519')}
                </Button>
            }
        >
            <Grid gridtemplatecolumns="1fr" gridtemplaterows="55px 1fr" height={1} width={1}>
                <FormMenu />

                <Box css={{ overflowY: 'auto' }}>
                    <Switch>
                        <Route path={`${basePath}/elements`} children={<FormElements />} />

                        <Route path={`${basePath}/parameters`} children={<FormParameters />} />

                        <Route path={`${basePath}/integration`} children={<FormIntegration />} />

                        <Route path={`${basePath}/emails`} children={<FormEmails />} />

                        <Route path={`${basePath}/preview`} children={<FormPreview />} />

                        <Route children={<Empty path={`${basePath}/elements`} replace={true} />} />
                    </Switch>
                </Box>
            </Grid>
        </FullScreenPopup>
    );
};

export const EventForm = () => {
    const { organizationId, eventId, formId } = useParams();

    return (
        <FormContextProvider
            basePath={HeaventPaths.FORM(organizationId, eventId, formId)}
            eventId={eventId}
            formId={formId}
            organizationId={organizationId}
        >
            <Form closePath={HeaventPaths.FORMS(organizationId, eventId)} />
        </FormContextProvider>
    );
};

export const OrganizationForm = () => {
    const { organizationId, formId } = useParams();

    return (
        <FormContextProvider
            basePath={HeaventPaths.COMMUNITY_FORM(organizationId, formId)}
            eventId={null}
            formId={formId}
            organizationId={organizationId}
        >
            <Form closePath={HeaventPaths.COMMUNITY_FORMS(organizationId)} />
        </FormContextProvider>
    );
};
