import * as React from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../components/reactFinalForm/isInvalid';
import { IconSelect as IconSelectComponent } from '../components/iconSelect';
import { BaseInputProps } from '../components/input/baseInputProps';

interface IIconSelectProps extends BaseInputProps {
    name: string;
}

export const IconSelect = (props: IIconSelectProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => {
                const invalid = isInvalid(fieldProps.meta);

                return (
                    <IconSelectComponent
                        {...props}
                        hint={invalid ? fieldProps.meta.error : props.hint}
                        state={invalid ? 'error' : props.state}
                        value={fieldProps.input.value}
                        onChange={(value) => {
                            fieldProps.input.onChange(value);
                        }}
                        onBlur={fieldProps.input.onBlur}
                        onFocus={fieldProps.input.onFocus}
                    />
                );
            }}
        />
    );
