import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { MassPreAssignPositionsQuery, PositionId } from 'common/src/generated/types';
import { addOrRemove } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IMassPreAssignPositionRowProps {
    position: MassPreAssignPositionsQuery['event']['positions']['nodes'][0];
    positionsIds: PositionId[];

    change(name: string, value: any): void;
}

export const MassPreAssignPositionRow = (props: IMassPreAssignPositionRowProps) => {
    const translate = useTranslate();

    return (
        <Row>
            <Cell width={48} css={{ paddingRight: 0 }}>
                <Checkbox
                    state={props.positionsIds.includes(props.position.id) ? 'checked' : 'unchecked'}
                    onClick={(state) => {
                        props.change(
                            'massPreAssign.positionsIds',
                            addOrRemove(props.positionsIds, props.position.id, state === 'checked')
                        );
                    }}
                />
            </Cell>

            <Cell direction="column" gap="1" align="start" justify="center">
                <Box font="gray900 textSm medium">{props.position.name}</Box>
                <Box color="gray500">{props.position.positionCategoryName}</Box>
            </Cell>

            <Cell>
                <Badge
                    leftIcon="user-group"
                    color={
                        props.position.assignedResources >= props.position.resources
                            ? 'success'
                            : 'warning'
                    }
                >
                    {translate(
                        '_1_2_membres_04485',
                        props.position.assignedResources,
                        props.position.resources
                    )}
                </Badge>
            </Cell>
        </Row>
    );
};
