import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IDocumentShadowProps {
    width?: number;
    children: React.ReactNode;
}

export const DocumentShadow = (props: IDocumentShadowProps) => (
        <Flex
            justify="center"
            height={1}
            css={{
                background: '$gray50',
                border: '1px solid $gray200',
                flex: '1',
                padding: '$7',
                overflowX: 'hidden',
                overflowY: 'auto'
            }}
        >
            <Flex
                direction="column"
                width={props.width}
                css={{
                    boxShadow: '$md',
                    borderRadius: '$2',
                    height: 'fit-content',
                    overflow: 'hidden'
                }}
            >
                {props.children}
            </Flex>
        </Flex>
    );
