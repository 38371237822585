import { API_BASE_URL } from 'common-front/src/consts';
import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { getToken } from 'common-front/src/util/aws/cognito';
import { LocalStorageKeys } from 'common-front/src/util/localStorage';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { ALL_INVOICESTATE, InvoiceState } from 'common/src/generated/types';
import { useLocalStorage } from 'common/src/util/dependencies/dependencies';
import { HeaventHeaders } from 'common/src/util/headers';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useBackofficeInvoicesQuery } from '../../generated/graphqlHooks';
import { BackofficeInvoiceRow } from './backofficeInvoiceRow';
import { BackofficeInvoicesColumns } from './backofficeInvoicesColumns';

export const BackofficeInvoices = () => {
    const { history, translate } = useHeavent();
    const localStorage = useLocalStorage();
    const enumToOptions = useEnumToOptions();
    const [id, idDebounced, setId] = useStateDebounce('');
    const [state, setState] = React.useState<InvoiceState | ''>('');
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading, reload } = useBackofficeInvoicesQuery({
        name: isNonEmptyString(idDebounced) ? idDebounced : undefined,
        state: isNonEmptyString(state) ? (state as InvoiceState) : undefined,
        offset
    });
    const [selectedColumns, _setSelectedColumns] = React.useState(() => {
        const localStorageColumns = localStorage.getItem(
            LocalStorageKeys.BACKOFFICE_INVOICES_COLUMNS
        );

        if (localStorageColumns) {
            return JSON.parse(localStorageColumns) as BackofficeInvoicesColumns[];
        } else {
            return [];
        }
    });
    const setSelectedColumns = React.useCallback(
        (newSelectedColumns: BackofficeInvoicesColumns[]) => {
            _setSelectedColumns(newSelectedColumns);
            localStorage.setItem(
                LocalStorageKeys.BACKOFFICE_INVOICES_COLUMNS,
                JSON.stringify(newSelectedColumns)
            );
        },
        [_setSelectedColumns]
    );
    const exportInvoices = React.useCallback(async (date: string) => {
        try {
            const result = await fetch(`${API_BASE_URL}invoice/all?date=${date}`, {
                method: 'post',
                headers: {
                    [HeaventHeaders.UserToken]: (await getToken()) || ''
                }
            });
            const { link } = await result.json();

            window.open(link, '_blank');
        } catch {
            window.alert(translate('impossible_d_ex_55872'));
        }
    }, []);
    const showColumn = (column: BackofficeInvoicesColumns) => selectedColumns.includes(column);

    return (
        <Box height={1} width={1} css={{ overflowY: 'auto' }}>
            <Flex direction="column" width={1} css={{ padding: '$7' }}>
                <Flex gap="4">
                    <Box
                        color="gray900"
                        fontSize="displayXs"
                        fontWeight="semiBold"
                        css={{ flex: '1' }}
                    >
                        {translate('factures_03552')}
                    </Box>

                    <Box>
                        <RichSelect
                            multiple={true}
                            placeholder="Colonnes visibles"
                            isSelectAllVisible={true}
                            values={selectedColumns}
                            onChange={setSelectedColumns}
                        >
                            <option value="organization">{translate('organisation_71863')}</option>
                            <option value="date">{translate('Date')}</option>
                            <option value="amount">{translate('montant_55595')}</option>
                            <option value="state">{translate('status_06428')}</option>
                            <option value="transactionId">
                                {translate('id_de_la_transa_98869')}
                            </option>
                        </RichSelect>
                    </Box>

                    <Button
                        onClick={() => {
                            history.push(HeaventPaths.HOME);
                        }}
                    >
                        Home
                    </Button>

                    <Dropdown>
                        <Trigger>
                            <Button color="white" leftIcon="ellipsis-vertical" />
                        </Trigger>

                        <Menu placement="bottom-end">
                            <ItemIcon
                                icon="eye"
                                onClick={() => {
                                    history.push(HeaventPaths.BACKOFFICE_ORGANIZATIONS);
                                }}
                            >
                                {translate('voir_les_organi_78756')}
                            </ItemIcon>

                            <ItemIcon
                                icon="file-export"
                                onClick={() => exportInvoices('this-month')}
                            >
                                {translate('exporter_les_fa_11017')}
                            </ItemIcon>

                            <ItemIcon
                                icon="file-export"
                                onClick={() => exportInvoices('last-month')}
                            >
                                {translate('exporter_les_fa_01571')}
                            </ItemIcon>

                            <ItemIcon
                                icon="file-export"
                                onClick={() => exportInvoices('all')}
                            >
                                {translate('exporter_toutes_46318')}
                            </ItemIcon>
                        </Menu>
                    </Dropdown>
                </Flex>

                <Spacer height="7" />

                <TableFilters
                    filters={
                        <Flex align="center" gap="3">
                            <Box width={320}>
                                <TextInput
                                    icon="magnifying-glass"
                                    placeholder={translate('id_ou_nom_25064')}
                                    value={id}
                                    onChange={(newId: string) => {
                                        setId(newId);
                                        setOffset(0);
                                    }}
                                />
                            </Box>

                            <Box width={200}>
                                <Select
                                    value={state}
                                    onChange={(newState: InvoiceState | '') => {
                                        setState(newState);
                                    }}
                                >
                                    <option value="">{translate('pay_es_et_en_at_04874')}</option>
                                    {enumToOptions(ALL_INVOICESTATE)}
                                </Select>
                            </Box>
                        </Flex>
                    }
                    headerCells={
                        <>
                            <HeaderCell>{translate('num_ro_de_factu_68150')}</HeaderCell>
                            {showColumn(BackofficeInvoicesColumns.Organization) && (
                                <HeaderCell>{translate('organisation_71863')}</HeaderCell>
                            )}
                            {showColumn(BackofficeInvoicesColumns.Date) && (
                                <HeaderCell>{translate('Date')}</HeaderCell>
                            )}
                            {showColumn(BackofficeInvoicesColumns.Amount) && (
                                <HeaderCell>{translate('montant_55595')}</HeaderCell>
                            )}
                            {showColumn(BackofficeInvoicesColumns.State) && (
                                <HeaderCell>{translate('status_06428')}</HeaderCell>
                            )}
                            {showColumn(BackofficeInvoicesColumns.TransactionId) && (
                                <HeaderCell>{translate('id_de_la_transa_98869')}</HeaderCell>
                            )}
                            <HeaderCell />
                            <HeaderCell />
                        </>
                    }
                    numberOfPages={data.backoffice?.invoices.numberOfPages ?? 0}
                    offset={offset}
                    rows={
                        isLoading ? (
                            <>
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                                <RowSkeleton bx={true} />
                            </>
                        ) : (
                            <>
                                {data.backoffice.invoices.nodes.map((invoice) => (
                                        <BackofficeInvoiceRow
                                            key={invoice.id}
                                            invoice={invoice}
                                            reload={reload}
                                            showColumn={showColumn}
                                        />
                                    ))}
                            </>
                        )
                    }
                    title={translate('liste_des_factu_86735')}
                    totalCount={data.backoffice?.invoices.totalCount ?? 0}
                    setOffset={setOffset}
                />
            </Flex>
        </Box>
    );
};
