import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { useTranslate } from '../../util/dependencies/dependencies';
import { Emptyable } from '../../util/emptyable';
import { isNonEmptyString } from '../../util/string';

interface IPositionSheetHeaderProps {
    acronym: Emptyable<string>;
    name: string;
}

export const PositionSheetHeader = (props: IPositionSheetHeaderProps) => {
    const translate = useTranslate();
    const name = isNonEmptyString(props.acronym) ? `${props.name} (${props.acronym})` : props.name;

    return (
        <Flex
            direction="column"
            css={{
                background: '$gray100',
                padding: '$7 56px'
            }}
        >
            <Box color="gray900" fontSize="displayXs" fontWeight="medium">
                {name}
            </Box>

            <Box color="gray800">{translate('fiche_de_missio_00324')}</Box>
        </Flex>
    );
};
