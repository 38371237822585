import { Separator } from 'common-front/src/designSystem/components/separator';
import { styled, CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

export const ParameterSeparator = (props: React.ComponentProps<typeof Separator>) => (
        <Separator
            color={props.color}
            css={
                {
                    display: 'none',
                    '@tablet': {
                        display: 'block'
                    },
                    ...props.css
                } as CSS
            }
            direction={props.direction}
        />
    );

const _dt = styled('dt', {
    color: '$gray800',
    fontSize: '$textSm',
    fontWeight: '$medium',
    '@tablet': {
        color: '$gray500',
        fontWeight: '$regular',
        gridColumn: 1
    }
});

export const Label: React.FunctionComponent = ({ children, ...props }) => <_dt {...props}>{children}</_dt>;

const _dd = styled('dd', {
    color: '$gray500',
    marginInline: 0,
    '@tablet': {
        color: '$gray800',
        gridColumn: 2
    }
});

export const Value: React.FunctionComponent = ({ children, ...props }) => <_dd {...props}>{children}</_dd>;

const _div = styled('div', {
    display: 'grid',
    gridTemplateColumns: 'auto',
    rowGap: '$1',
    '@tablet': {
        columnGap: '$3',
        gridTemplateColumns: 'auto auto',
        justifyContent: 'space-between'
    }
});

export const Parameter: React.FunctionComponent = ({ children, ...props }) => <_div {...props}>{children}</_div>;

const _dl = styled('dl', {
    display: 'flex',
    flexDirection: 'column',
    gap: '$4',
    marginBlock: 0,
    marginInline: 0
});

export const PositionParametersList: React.FunctionComponent = ({ children, ...props }) => <_dl {...props}>{children}</_dl>;
