import { SelectSegmentMobile } from 'common-front/src/segments/mobile/selectSegmentMobile';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { SegmentId, VolunteersSegmentsMobileQuery } from 'common/src/generated/types';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { find } from 'lodash-es';
import * as React from 'react';
import { VolunteersListMobile } from './volunteersListMobile';
import { I } from 'common/src/designSystem/components/i';

const _h1 = styled('h1', {
    fontSize: '$displaySm',
    fontWeight: '$semiBold',
    margin: 0
});

const _i = styled(I, {
    color: '$gray800',
    fontSize: '$textLg',
    padding: '$2'
});

interface IVolunteersMobileProps {
    event: VolunteersSegmentsMobileQuery['event'];
}

export const VolunteersMobile = ({ event }: IVolunteersMobileProps) => {
    const { organizationId, eventId, segmentId } = useParams();
    const history = useHistory();
    const translate = useTranslate();
    const openSearch = () => {
        history.push(HeaventPaths.VOLUNTEERS_SEARCH(organizationId, eventId));
    };

    return (
        <Flex direction="column" gap="6" css={{ flex: 1, minHeight: '100%', padding: '$6 $3' }}>
            <Flex gap="4" justify="between" align="center">
                <_h1>{translate('membres_11310')}</_h1>
                <_i icon="search" onClick={openSearch} />
            </Flex>
            <Box>
                <SelectSegmentMobile
                    segments={event.segments}
                    segmentId={segmentId}
                    onChange={(newSegmentId: SegmentId) =>
                        history.push(
                            HeaventPaths.VOLUNTEERS_SEGMENT(organizationId, eventId, newSegmentId)
                        )
                    }
                />
            </Box>
            <Flex direction="column" css={{ flex: 1 }}>
                <VolunteersListMobile segment={find(event.segments, { id: segmentId })!} />
            </Flex>
        </Flex>
    );
};
