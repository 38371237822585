import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { Table } from 'common/src/designSystem/components/table/table';
import { OrganizationId, SkidataConnectionInfosQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { startAtEndAtToString } from 'common/src/vo/event';
import * as React from 'react';
import {
    useSkidataConnectionInfoSeaonEventAddMutation,
    useSkidataConnectionInfoSeaonEventDeleteMutation,
    useSkidataConnectionInfoSynchronizedEventAddMutation,
    useSkidataConnectionInfoSynchronizedEventDeleteMutation
} from '../../generated/graphqlHooks';

interface ISkidataEventsListProps {
    events: SkidataConnectionInfosQuery['organization']['events']['nodes'];
    eventType: 'all' | 'synchronized' | 'season';
    organizationId: OrganizationId;
    subtitle: string;
    title: string;

    reload(): void;
}

export const SkidataEventsList = (props: ISkidataEventsListProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const { mutate: seaonEventAdd } = useSkidataConnectionInfoSeaonEventAddMutation();
    const { mutate: seaonEventDelete } = useSkidataConnectionInfoSeaonEventDeleteMutation();
    const { mutate: synchronizedEventAdd } = useSkidataConnectionInfoSynchronizedEventAddMutation();
    const { mutate: synchronizedEventDelete } =
        useSkidataConnectionInfoSynchronizedEventDeleteMutation();

    return (
        <Flex gap="3" width={1}>
            <Flex direction="column" gap="1" width={280}>
                <Box color="gray700" fontWeight="medium">
                    {props.title}
                </Box>

                <Box color="gray500">{props.subtitle}</Box>
            </Flex>

            <Box width={1}>
                <Table>
                    <HeaderRow>
                        <HeaderCell>{translate('nom_de_l_v_nem_08711')}</HeaderCell>
                        <HeaderCell>{translate('Date')}</HeaderCell>
                        {props.eventType === 'all' && (
                            <>
                                <HeaderCell />
                                <HeaderCell />
                            </>
                        )}
                        {props.eventType === 'synchronized' && <HeaderCell />}
                        {props.eventType === 'season' && <HeaderCell />}
                    </HeaderRow>

                    {isNonEmptyArray(props.events) ? (
                        props.events.map((event) => (
                                <Row key={event.id}>
                                    <Cell>
                                        <Box color="gray800" width={1}>
                                            {event.name}
                                        </Box>
                                    </Cell>
                                    <Cell>
                                        {startAtEndAtToString(
                                            dateTimeService,
                                            event.startAt,
                                            event.endAt
                                        )}
                                    </Cell>
                                    {props.eventType === 'all' && (
                                        <>
                                            <Cell
                                                onClick={async () => {
                                                    await synchronizedEventAdd({
                                                        organizationId: props.organizationId,
                                                        eventId: event.id
                                                    });

                                                    props.reload();
                                                }}
                                            >
                                                <Box
                                                    color="primary700"
                                                    fontWeight="medium"
                                                    width={1}
                                                    css={{ cursor: 'pointer' }}
                                                >
                                                    {translate('synchroniser_34754')}
                                                </Box>
                                            </Cell>
                                            <Cell
                                                onClick={async () => {
                                                    await seaonEventAdd({
                                                        organizationId: props.organizationId,
                                                        eventId: event.id
                                                    });

                                                    props.reload();
                                                }}
                                            >
                                                <Box
                                                    color="primary700"
                                                    fontWeight="medium"
                                                    width={1}
                                                    css={{ cursor: 'pointer' }}
                                                >
                                                    {translate('_v_nement_saiso_67885')}
                                                </Box>
                                            </Cell>
                                        </>
                                    )}
                                    {props.eventType === 'synchronized' && (
                                        <Cell
                                            onClick={async () => {
                                                await synchronizedEventDelete({
                                                    organizationId: props.organizationId,
                                                    eventId: event.id
                                                });

                                                props.reload();
                                            }}
                                        >
                                            <Box
                                                color="error700"
                                                fontWeight="medium"
                                                width={1}
                                                css={{ cursor: 'pointer' }}
                                            >
                                                {translate('supprimer_43083')}
                                            </Box>
                                        </Cell>
                                    )}
                                    {props.eventType === 'season' && (
                                        <Cell
                                            onClick={async () => {
                                                await seaonEventDelete({
                                                    organizationId: props.organizationId,
                                                    eventId: event.id
                                                });

                                                props.reload();
                                            }}
                                        >
                                            <Box
                                                color="error700"
                                                fontWeight="medium"
                                                width={1}
                                                css={{ cursor: 'pointer' }}
                                            >
                                                {translate('supprimer_43083')}
                                            </Box>
                                        </Cell>
                                    )}
                                </Row>
                            ))
                    ) : (
                        <Row css={{ height: 'auto' }}>
                            <Cell>
                                <Flex
                                    direction="column"
                                    align="center"
                                    gap="4"
                                    width={1}
                                    css={{ padding: '$8' }}
                                >
                                    <IconBackground color="primary" icon="magnifying-glass" />

                                    <Box fontSize="textMd" fontWeight="medium">
                                        {translate('aucun_v_nement_39620')}
                                    </Box>
                                </Flex>
                            </Cell>
                        </Row>
                    )}
                </Table>
            </Box>
        </Flex>
    );
};
