import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

const _i = styled('i', {
    fontSize: '18px'
});

export const NavigationLinkIcon = ({
    active,
    className
}: {
    active?: boolean;
    className: string;
}) => <_i className={`${active ? 'fa-solid' : 'fa-regular'} ${className}`}></_i>;
