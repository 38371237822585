import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';

export const BadgePastourelleBack = () => (
        <Flex
            direction="column"
            height={597}
            width={435}
            css={{
                background: `url(https://assets.recrewteer.com/badges/pastourelle2024/Badge_verso.png) no-repeat`,
                backgroundSize: 'cover',
                overflow: 'hidden',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    height: '518px',
                    left: '25px',
                    position: 'absolute',
                    top: '15px',
                    width: '385px'
                }}
            >
                <img
                    src="https://assets.recrewteer.com/badges/pastourelle2024/contact.png"
                    height="100%"
                    width="100%"
                />
            </Box>
        </Flex>
    );
