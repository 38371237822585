import { Button } from 'common-front/src/designSystem/components/button';
import { CheckboxText, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Table } from 'common/src/designSystem/components/table/table';
import {
    CustomFieldId,
    CustomFieldsSortAttributes,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { reorder } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { PossibleColumn } from 'common/src/vo/segment';
import { uniqBy } from 'lodash-es';
import * as React from 'react';
import { DragDropContext, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { VolunteersExportColumnsSelectorDropdown } from './volunteersExportColumnsSelectorDropdown';
import { VolunteersExportColumnsSelectorRow } from './volunteersExportColumnsSelectorRow';

interface IVolunteersExportColumnsSelectorProps {
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    filesState: ICheckboxState;

    filesStateOnClick(): any;
}

export const VolunteersExportColumnsSelector = ({
    customFields,
    columns,
    filesState,
    filesStateOnClick
}: IVolunteersExportColumnsSelectorProps) => {
    const translate = useTranslate();
    const {
        columnsExport,
        isAdmin,
        isSaveVisible,
        possibleColumns,
        setColumnsExport,
        setIsSaveSegmentVisible
    } = useSegmentsContext<CustomFieldId, CustomFieldsSortAttributes>();
    const [isColumnSearchOpen, setIsColumnSearchOpen] = React.useState(false);
    const customFieldCategories = React.useMemo(() => uniqBy(
            [
                {
                    id: undefined,
                    name: translate('default_columns')
                },
                ...customFields.map((category) => ({
                        id: category.customFieldCategoryId,
                        name: category.customFieldCategoryName
                    }))
            ],
            'id'
        ), [customFields]);

    const onDragEnd = React.useCallback(
        ({ reason, source, destination }) => {
            if (reason === 'DROP' && destination && source.index !== destination.index) {
                setColumnsExport(reorder(columnsExport, source.index, destination.index));
            }
        },
        [columnsExport, setColumnsExport]
    );

    return (
        <>
            <Box font="gray900 textMd medium">{translate('personnalisatio_06026')}</Box>

            <Spacer height="2" />

            <Flex width={1}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="customFields" type="customFields">
                        {(droppableProvided: DroppableProvided) => (
                                <Flex
                                    width={1}
                                    {...droppableProvided.droppableProps}
                                    ref={droppableProvided.innerRef}
                                >
                                    <Table
                                        css={{
                                            border: '1px solid $gray200',
                                            borderRadius: '$1'
                                        }}
                                    >
                                        <HeaderRow
                                            css={{
                                                border: 'unset',
                                                borderBottom: '1px solid $gray200'
                                            }}
                                        >
                                            <HeaderCell
                                                css={{
                                                    padding: '$3 $6'
                                                }}
                                            >
                                                {translate('liste_de_vos_colonnes')}
                                            </HeaderCell>
                                        </HeaderRow>

                                        <Box css={{ padding: '$4' }}>
                                            {columns.map((column, index) => (
                                                    <VolunteersExportColumnsSelectorRow
                                                        key={column.slug}
                                                        column={column}
                                                        index={index}
                                                    />
                                                ))}

                                            {droppableProvided.placeholder}

                                            <Flex
                                                align="center"
                                                gap="4"
                                                width={1}
                                                css={{
                                                    cursor: 'pointer !important',
                                                    padding: '$3 $3'
                                                }}
                                                onClick={() => {
                                                    setIsColumnSearchOpen(!isColumnSearchOpen);
                                                }}
                                                onMouseLeave={(e) => {
                                                    closeOtherDropdowns(e.target);
                                                }}
                                            >
                                                <VolunteersExportColumnsSelectorDropdown
                                                    customFields={customFields}
                                                    customFieldCategories={customFieldCategories}
                                                    possibleColumns={possibleColumns}
                                                />
                                            </Flex>
                                        </Box>
                                    </Table>
                                </Flex>
                            )}
                    </Droppable>
                </DragDropContext>
            </Flex>

            <Spacer height="6" />

            <CheckboxText state={filesState} onClick={filesStateOnClick}>
                {translate('include_files_in_export')}
            </CheckboxText>

            <Spacer height="3" />

            {isAdmin && isSaveVisible && (
                <Button
                    color="invisible"
                    onClick={() => {
                        setIsSaveSegmentVisible(true);
                    }}
                >
                    {translate('enregistrer_la_22884')}
                </Button>
            )}
        </>
    );
};
