import { UpdateCell } from 'common-front/src/components/updateCell';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { DelegationProperty, DelegationsDelegationFragment } from 'common/src/generated/types';
import * as React from 'react';
import { useDelegationUpdateFieldMutation } from '../../../generated/graphqlHooks';

interface IDelegationsUpdateCellProps<T> {
    delegation: DelegationsDelegationFragment;
    cellCss: any;
    children: React.ReactNode;
    initialValue: T;
    property: DelegationProperty;

    reload(): void;
    renderInput(value: T, setValue: (v: T) => void): React.ReactNode;
}

export const DelegationsUpdateCell = <T extends {}>(props: IDelegationsUpdateCellProps<T>) => {
    const { eventId, organizationId } = useSegmentsContext();
    const { mutate } = useDelegationUpdateFieldMutation();

    return (
        <UpdateCell
            cellCss={props.cellCss}
            initialValue={props.initialValue}
            mutate={async (value) => mutate({
                    organizationId,
                    eventId,
                    delegationId: props.delegation.id,
                    property: props.property,
                    value
                })}
            reload={props.reload}
            renderInput={props.renderInput}
        >
            {props.children}
        </UpdateCell>
    );
};
