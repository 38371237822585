import { uniq } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import {
    DocumentUserCustomBadgeFragment,
    VolunteerRegistrationState
} from '../../../generated/types';
import { isNonEmptyArray } from '../../../util/array';

interface IBadgeParis20kmFrontProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeParis20kmFront = (props: IBadgeParis20kmFrontProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;
    const accreditations = vr.accreditationsUsersInfos.map(
        ({ accreditation }) => accreditation.name
    );
    const positions = uniq(vr.positionsSlotsUsersInfos.map((psui) => psui.position.name));
    const color = accreditations.includes('ACCES VIOLET')
        ? '#772a8a'
        : accreditations.includes('ACCES BLEU CLAIR')
        ? '#57c9e9'
        : accreditations.includes('ACCES VERT')
        ? '#078e45'
        : accreditations.includes('ACCES BLEU FONCE')
        ? '#2c358c'
        : accreditations.includes('ACCES JAUNE')
        ? '#fee901'
        : '#fea101';

    return (
        <Flex
            height={476}
            width={368}
            css={{
                background: `url(https://assets.recrewteer.com/badges/paris20km/paris20kmRecto_3.png) no-repeat`,
                backgroundColor: color,
                backgroundSize: 'cover',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    borderRadius: '107px',
                    height: '107px',
                    left: '131px',
                    overflow: 'hidden',
                    position: 'absolute',
                    top: '127px',
                    width: '107px'
                }}
            >
                {userInfo.picture && <img src={userInfo.picture.url} height="100%" width="100%" />}
            </Box>

            <Box
                css={{
                    color: 'white',
                    fontSize: '18px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '242px',
                    width: '100%'
                }}
            >
                {userInfo.name}
            </Box>

            <Flex
                direction="column"
                css={{
                    color: 'white',
                    fontSize: '12px',
                    gap: '2px',
                    position: 'absolute',
                    top: '271px',
                    width: '100%'
                }}
            >
                {vr.state === VolunteerRegistrationState.NotApplicable ? (
                    <Box width={1} textAlign="center">
                        {userInfo.paris20kmSociete}
                    </Box>
                ) : userInfo.paris20kmStatut === 'Direction' ? (
                    isNonEmptyArray(userInfo.paris20kmDomaine) ? (
                        userInfo.paris20kmDomaine.map((domaine, index) => (
                                <Box key={index} width={1} textAlign="center">
                                    {domaine}
                                </Box>
                            ))
                    ) : null
                ) : (
                    positions.map((position, index) => (
                            <Box key={index} width={1} textAlign="center">
                                {position}
                            </Box>
                        ))
                )}
            </Flex>

            <Box
                css={{
                    color: 'white',
                    fontSize: '32px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    top: '364px',
                    width: '100%'
                }}
            >
                {userInfo.paris20kmStatut}
            </Box>
        </Flex>
    );
};
