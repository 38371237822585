import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Separator } from 'common-front/src/designSystem/components/separator';
import {
    useUserInfoDelegationDeleteMutation,
    useVolunteerRegistrationDeleteMutation
} from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    AccreditationState,
    EventId,
    Feature,
    OrganizationId,
    VolunteerRegistrationState,
    VolunteersQuery
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import {
    useUpdateStateMutation,
    useVolunteerRegistrationAccreditationStateUpdateMutation
} from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';

interface IVolunteerRowDropdownProps {
    eventId: EventId;
    organizationId: OrganizationId;
    volunteerRegistration: VolunteersQuery['event']['volunteersRegistrations']['nodes'][0];

    reload(): void;
}

export const VolunteerRowDropdown = ({
    eventId,
    organizationId,
    reload,
    volunteerRegistration
}: IVolunteerRowDropdownProps) => {
    const { history, translate } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const {
        event: {
            permissions: { isEventAdmin }
        }
    } = useEventContext();
    const state = volunteerRegistration.state;
    const accreditationState = volunteerRegistration.accreditationState;
    const { mutate: updateState, isLoading: isUpdateStateLoading } = useUpdateStateMutation();
    const { mutate: updateAccreditationState, isLoading: isUpdateAccreditationStateLoading } =
        useVolunteerRegistrationAccreditationStateUpdateMutation();
    const { mutate: userInfoDelegationDelete } = useUserInfoDelegationDeleteMutation();
    const { mutate: volunteerRegistrationDelete } = useVolunteerRegistrationDeleteMutation();
    const showChangeToWaitingAssignment = state !== VolunteerRegistrationState.WaitingAssignment;
    const showChangeToRefused = state !== VolunteerRegistrationState.Refused;
    const showChangeToNotApplicable =
        state === VolunteerRegistrationState.WaitingAssignment ||
        state === VolunteerRegistrationState.Assigned;
    const showEditAssignment = state === VolunteerRegistrationState.Assigned;
    const showEditPreAssignment = state === VolunteerRegistrationState.PreAssigned;
    const showAssign = state === VolunteerRegistrationState.WaitingAssignment;
    const showChangeToWaitingAccreditation =
        accreditationState !== AccreditationState.WaitingAccreditation;
    const showToAccreditationRefused = accreditationState !== AccreditationState.Refused;
    const showChangeToAccreditationNotApplicable =
        accreditationState === AccreditationState.WaitingAccreditation ||
        accreditationState === AccreditationState.Accredited;
    const showEditAccreditation = accreditationState === AccreditationState.Accredited;
    const showAccredit = accreditationState === AccreditationState.WaitingAccreditation;
    const [isRemoveFromDelegationOpen, setIsRemoveFromDelegationOpen] = React.useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const showDelegationRemove = hasFeature(Feature.Delegation) && volunteerRegistration.delegation;

    return (
        <>
            <DropdownEllipsis>
                {showChangeToWaitingAssignment && (
                    <ItemIcon
                        icon="arrow-right-arrow-left"
                        onClick={async () => {
                            if (!isUpdateStateLoading) {
                                await updateState({
                                    eventId,
                                    volunteerRegistrationId: volunteerRegistration.id,
                                    state: VolunteerRegistrationState.WaitingAssignment
                                });

                                reload();
                            }
                        }}
                    >
                        {translate('changer_le_stat_90266')}
                    </ItemIcon>
                )}

                {showChangeToRefused && (
                    <ItemIcon
                        icon="arrow-right-arrow-left"
                        onClick={async () => {
                            if (!isUpdateStateLoading) {
                                await updateState({
                                    eventId,
                                    volunteerRegistrationId: volunteerRegistration.id,
                                    state: VolunteerRegistrationState.Refused
                                });

                                reload();
                            }
                        }}
                    >
                        {translate('changer_le_stat_68392')}
                    </ItemIcon>
                )}

                {showChangeToNotApplicable && (
                    <ItemIcon
                        icon="arrow-right-arrow-left"
                        onClick={async () => {
                            if (!isUpdateStateLoading) {
                                await updateState({
                                    eventId,
                                    volunteerRegistrationId: volunteerRegistration.id,
                                    state: VolunteerRegistrationState.NotApplicable
                                });

                                reload();
                            }
                        }}
                    >
                        {translate('changer_le_stat_62507')}
                    </ItemIcon>
                )}

                {showEditAssignment && (
                    <ItemIcon
                        icon="pen"
                        onClick={() => {
                            if (!isUpdateStateLoading) {
                                history.push(
                                    HeaventPaths.ASSIGNMENT(
                                        organizationId,
                                        eventId,
                                        volunteerRegistration.userInfo.id
                                    )
                                );
                            }
                        }}
                    >
                        {translate('_diter_les_affe_42283')}
                    </ItemIcon>
                )}

                {showEditPreAssignment && (
                    <ItemIcon
                        icon="pen"
                        onClick={() => {
                            if (!isUpdateStateLoading) {
                                history.push(
                                    HeaventPaths.PRE_ASSIGNMENT(
                                        organizationId,
                                        eventId,
                                        volunteerRegistration.userInfo.id
                                    )
                                );
                            }
                        }}
                    >
                        {translate('_diter_les_pr_10213')}
                    </ItemIcon>
                )}

                {showAssign && (
                    <ItemIcon
                        icon="wand-magic-sparkles"
                        onClick={() => {
                            if (!isUpdateStateLoading) {
                                history.push(
                                    HeaventPaths.ASSIGNMENT(
                                        organizationId,
                                        eventId,
                                        volunteerRegistration.userInfo.id
                                    )
                                );
                            }
                        }}
                    >
                        {translate('affecter_94255')}
                    </ItemIcon>
                )}

                {hasFeature(Feature.Accreditation) && (
                    <>
                        <Separator direction="horizontal" color="gray100" />

                        {showChangeToWaitingAccreditation && (
                            <ItemIcon
                                icon="arrow-right-arrow-left"
                                onClick={async () => {
                                    if (!isUpdateAccreditationStateLoading) {
                                        await updateAccreditationState({
                                            eventId,
                                            volunteerRegistrationId: volunteerRegistration.id,
                                            state: AccreditationState.WaitingAccreditation
                                        });

                                        reload();
                                    }
                                }}
                            >
                                {translate('change_le_statu_93385')}
                            </ItemIcon>
                        )}

                        {showToAccreditationRefused && (
                            <ItemIcon
                                icon="arrow-right-arrow-left"
                                onClick={async () => {
                                    if (!isUpdateAccreditationStateLoading) {
                                        await updateAccreditationState({
                                            eventId,
                                            volunteerRegistrationId: volunteerRegistration.id,
                                            state: AccreditationState.Refused
                                        });

                                        reload();
                                    }
                                }}
                            >
                                {translate('changer_le_stat_68392')}
                            </ItemIcon>
                        )}

                        {showChangeToAccreditationNotApplicable && (
                            <ItemIcon
                                icon="arrow-right-arrow-left"
                                onClick={async () => {
                                    if (!isUpdateAccreditationStateLoading) {
                                        await updateAccreditationState({
                                            eventId,
                                            volunteerRegistrationId: volunteerRegistration.id,
                                            state: AccreditationState.NotApplicable
                                        });

                                        reload();
                                    }
                                }}
                            >
                                {translate('changer_le_stat_62507')}
                            </ItemIcon>
                        )}

                        {showEditAccreditation && (
                            <ItemIcon
                                icon="pen"
                                onClick={() => {
                                    if (!isUpdateAccreditationStateLoading) {
                                        history.push(
                                            HeaventPaths.ACCREDIT(
                                                organizationId,
                                                eventId,
                                                volunteerRegistration.userInfo.id
                                            )
                                        );
                                    }
                                }}
                            >
                                {translate('_diter_les_accr_86042')}
                            </ItemIcon>
                        )}

                        {showAccredit && (
                            <ItemIcon
                                icon="badge-check"
                                onClick={() => {
                                    if (!isUpdateAccreditationStateLoading) {
                                        history.push(
                                            HeaventPaths.ACCREDIT(
                                                organizationId,
                                                eventId,
                                                volunteerRegistration.userInfo.id
                                            )
                                        );
                                    }
                                }}
                            >
                                {translate('accr_diter_70543')}
                            </ItemIcon>
                        )}
                    </>
                )}

                {(showDelegationRemove || isEventAdmin) && (
                    <Separator direction="horizontal" color="gray100" />
                )}

                {showDelegationRemove && (
                    <ItemIcon
                        icon="minus"
                        color="red"
                        onClick={() => {
                            setIsRemoveFromDelegationOpen(true);
                        }}
                    >
                        {translate('retirer_de_la_d_64963')}
                    </ItemIcon>
                )}

                {isEventAdmin && (
                    <ItemIcon
                        icon="trash-can"
                        color="red"
                        onClick={() => {
                            setIsDeleteOpen(true);
                        }}
                    >
                        {translate('d_sinscrire_de_06416')}
                    </ItemIcon>
                )}
            </DropdownEllipsis>

            {showDelegationRemove && isRemoveFromDelegationOpen && (
                <DeleteModal
                    text={translate('suppression_de_73669', volunteerRegistration.userInfo.name)}
                    subtext={[
                        translate('_tes_vous_sur_d_34901', volunteerRegistration.delegation!.name)
                    ]}
                    buttonText={translate('retirer_de_la_d_64963')}
                    onDelete={async () => {
                        await userInfoDelegationDelete({
                            organizationId,
                            eventId,
                            userInfoId: volunteerRegistration.userInfo.id,
                            delegationId: volunteerRegistration.delegation!.id
                        });

                        reload();
                    }}
                    onClose={() => {
                        setIsRemoveFromDelegationOpen(false);
                    }}
                />
            )}

            {isEventAdmin && isDeleteOpen && (
                <DeleteModal
                    text={translate('suppression_de_73669', volunteerRegistration.userInfo.name)}
                    subtext={[translate('_tes_vous_sur_d_79932')]}
                    buttonText={translate('d_sinscrire_de_06416')}
                    onDelete={async () => {
                        await volunteerRegistrationDelete({
                            eventId,
                            volunteerRegistrationId: volunteerRegistration.id
                        });

                        reload();
                    }}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                />
            )}
        </>
    );
};
