import { Event, PositionsSlotInput } from 'common/src/generated/types';
import { ICreateUpdatePositionValues } from 'common/src/input/positionInput';
import { PositionsSlotInputService } from 'common/src/input/positionsSlotInput';
import { removeAtIndex } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { toInterval } from 'common/src/util/luxon';
import { repeatSlot } from 'common/src/vo/positionSlot';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { CreateSlots } from '../../slots/create/createSlots';
import { CreatePositionSlot } from './createPositionSlot';

interface ICreatePositionSlotsProps {
    event: Pick<Event, 'startAt' | 'endAt'>;
    values: ICreateUpdatePositionValues;

    change(name: string, value: any): void;
}

export const CreatePositionSlots = (props: ICreatePositionSlotsProps) => {
    const positionsSlotInput = useService(PositionsSlotInputService);
    const [isEditOpenIndex, setIsEditOpenIndex] = React.useState(-1);
    const updateSlots = React.useCallback(
        (newSlot: PositionsSlotInput, index: number) => {
            const newSlots = [...props.values.position.slots];
            newSlots[index] = newSlot;

            props.change('position.slots', newSlots);
        },
        [props.values.position.slots, props.change]
    );
    const matchDateFilter = React.useCallback(
        (slot: PositionsSlotInput, _date: DateTime, interval: Interval) => toInterval(slot).overlaps(interval),
        []
    );
    const onNewSlot = React.useCallback(() => {
        props.change(
            'position.slots',
            props.values.position.slots.concat([
                positionsSlotInput.positionsSlotInputDefault(props.event.startAt)
            ])
        );

        setIsEditOpenIndex(props.values.position.slots.length);
    }, [props.values.position.slots, props.change]);
    const onRepeat = React.useCallback(
        (slot: PositionsSlotInput, value: number, unit: string) => {
            const newSlots = repeatSlot(slot, value, unit);

            props.change('position.slots', props.values.position.slots.concat(newSlots));
        },
        [props.values.position.slots, props.change]
    );
    const onRemove = React.useCallback(
        (index: number) => {
            props.change('position.slots', removeAtIndex(props.values.position.slots, index));
        },
        [props.values.position.slots, props.change]
    );

    return (
        <CreateSlots
            event={props.event}
            slots={props.values.position.slots}
            change={props.change}
            matchDateFilter={matchDateFilter}
            onNewSlot={onNewSlot}
            renderSlot={(slot) => (
                    <CreatePositionSlot
                        key={slot.index}
                        event={props.event}
                        index={slot.index}
                        initialIsEditOpen={slot.index === isEditOpenIndex}
                        slot={slot}
                        onRepeat={onRepeat}
                        remove={onRemove}
                        updateSlots={updateSlots}
                    />
                )}
        />
    );
};
