import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AssignmentSlotsQuery,
    PositionId,
    PositionsCategoryId,
    PositionsSlotId,
    UsersInfo
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { splitSlotsPreAssign } from 'common/src/vo/positionSlot';
import { Interval } from 'luxon';
import * as React from 'react';
import { AssignmentCategory } from './assignmentCategory';

interface IAssignmentCategoriesProps {
    positionsSlots: AssignmentSlotsQuery['event']['positionsSlots']['nodes'];
    userInfo: Pick<UsersInfo, 'name' | 'fields'>;
    wishedPositionsCategoriesIds: PositionsCategoryId[];
    wishedPositionsIds: PositionId[];
    wishedPositionsSlotsIds: PositionsSlotId[];
    wishedRanges: Interval[];
}

export const AssignmentCategories = (props: IAssignmentCategoriesProps) => {
    const translate = useTranslate();
    const positionsCategories = React.useMemo(() => splitSlotsPreAssign(
            props.positionsSlots,
            props.wishedPositionsCategoriesIds,
            props.wishedPositionsIds,
            props.wishedPositionsSlotsIds,
            props.wishedRanges,
            props.userInfo.fields
        ), [
        props.positionsSlots,
        props.userInfo,
        props.wishedPositionsCategoriesIds,
        props.wishedPositionsIds,
        props.wishedPositionsSlotsIds,
        props.wishedRanges
    ]);

    return (
        <>
            <Box font="gray900 textMd medium">
                {translate(
                    'cat_gorie_mis_36044',
                    positionsCategories.wishedPositionsCategories.length
                )}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('cat_gories_ou_m_71206')}</Box>

            <Spacer height="3" />

            {positionsCategories.wishedPositionsCategories.map((category) => (
                    <React.Fragment key={category.id}>
                        <AssignmentCategory category={category} />

                        <Spacer height="3" />
                    </React.Fragment>
                ))}

            <Spacer height="6" />

            <Box font="gray900 textMd medium">
                {translate(
                    'autre_cat_gorie_21569',
                    positionsCategories.possiblePositionsCategories.length
                )}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('cat_gories_ou_m_50063')}</Box>

            <Spacer height="3" />

            {positionsCategories.possiblePositionsCategories.map((category) => (
                    <React.Fragment key={category.id}>
                        <AssignmentCategory category={category} />

                        <Spacer height="3" />
                    </React.Fragment>
                ))}

            <Spacer height="6" />

            <Box font="gray900 textMd medium">
                {translate(
                    'autre_cat_gorie_64824',
                    positionsCategories.otherPositionsCategories.length
                )}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('cat_gories_ou_m_74482')}</Box>

            <Spacer height="3" />

            {positionsCategories.otherPositionsCategories.map((category) => (
                    <React.Fragment key={category.id}>
                        <AssignmentCategory category={category} />

                        <Spacer height="3" />
                    </React.Fragment>
                ))}
        </>
    );
};
