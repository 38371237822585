import { CreateUpdateCategoryModal } from 'common-front/src/components/createUpdateCategoryModal';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { RightPanelFooter } from 'common-front/src/designSystem/components/rightPanel/rightPanelFooter';
import { RightPanelHeader } from 'common-front/src/designSystem/components/rightPanel/rightPanelHeader';
import { RightPanelLoader } from 'common-front/src/designSystem/components/rightPanel/rightPanelLoader';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { FileS3Input } from 'common-front/src/designSystem/form/file/fileS3Input';
import { RadioRow } from 'common-front/src/designSystem/form/radioRow';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CreateCustomFieldInfosFragment,
    CustomFieldId,
    CustomFieldInput,
    CustomFieldsCategoryId,
    CustomFieldVariety,
    FieldType,
    FormCustomFieldFragment,
    OrganizationId
} from 'common/src/generated/types';
import {
    CustomFieldInputService,
    ICreateUpdateCustomFieldValues
} from 'common/src/input/customFieldInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { CREATABLE_FIELD_TYPES } from 'common/src/vo/customField';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';
import {
    useCustomFieldCategoryCreateMutation,
    useCustomFieldCreateMutation,
    useCustomFieldToEditQuery,
    useCustomFieldUpdateMutation,
    useOrganizationInfosQuery
} from '../../generated/graphqlHooks';
import { CreateUpdateSelectValues } from './createUpdateSelectValues';

interface ICreateUpdateCustomFieldProps {
    canDelete: boolean;
    customFieldId: Emptyable<CustomFieldId>;
    initialValues: ICreateUpdateCustomFieldValues;
    isEdit: boolean;
    isFromForm?: boolean;
    isSelectV2: boolean;
    organization: CreateCustomFieldInfosFragment;
    organizationId: OrganizationId;

    mutate(args: {
        organizationId: OrganizationId;
        customField: CustomFieldInput;
    }): Promise<{ customField: FormCustomFieldFragment }>;
    onClose(): void;
    onSuccess(customField: FormCustomFieldFragment): void;
}

const CreateUpdateCustomField = (props: ICreateUpdateCustomFieldProps) => {
    const { translate } = useHeavent();
    const customFieldInput = useService(CustomFieldInputService);
    const enumToOptions = useEnumToOptions();
    const validateService = useService(ValidateService);
    const { mutate: create } = useCustomFieldCategoryCreateMutation();
    const panelBodyRef = React.useRef<HTMLDivElement | null>(null);
    const otherCustomFields = React.useMemo(() => props.organization.customFields.nodes.filter((cf) => cf.id !== props.customFieldId), [props.customFieldId, props.organization]);
    const [categories, setCategories] = React.useState(
        props.organization.customFieldsCategories.nodes
    );
    const [currentFieldType, setFieldType] = React.useState(
        props.initialValues.customField.fieldType
    );
    const [currentHasCondition, setHasCondition] = React.useState(
        props.initialValues.customField.hasCondition
    );
    const [conditionCustomField, setConditionCustomField] = React.useState<
        (typeof otherCustomFields)[0] | undefined
    >(
        otherCustomFields.find((customField) => customField.id === props.initialValues.customField.conditionCustomFieldId)
    );
    const [isCreateCategoryOpen, setIsCreateCategoryOpen] = React.useState(false);

    return (
        <Form
            hideErrors={true}
            initialValues={props.initialValues}
            onShowErrors={() => {
                if (panelBodyRef.current) {
                    panelBodyRef.current.scrollTop = 0;
                }
            }}
            validate={validateService.validateForForm(
                customFieldInput.createUpdateCustomFieldSchema()
            )}
            onSubmit={async (values: ICreateUpdateCustomFieldValues) => {
                const { customField } = await props.mutate({
                    organizationId: props.organizationId,
                    customField: {
                        ...values.customField,
                        description: values.customField.description || ''
                    }
                });

                props.onClose();
                props.onSuccess(customField);
            }}
            render={({ form, handleSubmit, submitting, values }) => (
                    <>
                        <RightPanel size="md" onClose={props.onClose}>
                            <RightPanelHeader>
                                {props.isEdit
                                    ? translate(
                                          'mise_jour_de_17055',
                                          props.initialValues.customField.name
                                      )
                                    : translate('nouveau_champ_p_27686')}
                            </RightPanelHeader>

                            <RightPanelBody ref={panelBodyRef}>
                                <OnChange name="customField.fieldType">
                                    {(fieldType: FieldType) => {
                                        setFieldType(fieldType);

                                        if (fieldType === FieldType.Validation) {
                                            form.change('customField.isPrivate', false);
                                        }
                                    }}
                                </OnChange>

                                <OnChange name="customField.hasCondition">
                                    {(hasCondition: boolean) => {
                                        form.change(
                                            'customField.conditionCustomFieldId',
                                            otherCustomFields[0].id
                                        );

                                        setHasCondition(hasCondition);
                                    }}
                                </OnChange>

                                <OnChange name="customField.conditionCustomFieldId">
                                    {(conditionCustomFieldId: CustomFieldId) => {
                                        const currentCustomField = otherCustomFields.find(
                                            (customField) => customField.id === conditionCustomFieldId
                                        );

                                        if (currentCustomField) {
                                            form.change(
                                                'customField.conditionValue',
                                                currentCustomField.fieldType === FieldType.Select
                                                    ? []
                                                    : true
                                            );

                                            setConditionCustomField(currentCustomField);
                                        }
                                    }}
                                </OnChange>

                                <Spacer height="7" />

                                <FormErrors />

                                {!props.isEdit && (
                                    <>
                                        <Box font="gray800 textSm medium">
                                            {translate('typologie_34041')}
                                        </Box>

                                        <Spacer height="2" />

                                        <RadioRow
                                            name="customField.variety"
                                            value={CustomFieldVariety.UserInfo}
                                        >
                                            <Flex direction="column">
                                                <Box>{translate('membres_11310')}</Box>
                                                <Box fontWeight="regular">
                                                    {translate('champ_personnal_26972')}
                                                </Box>
                                            </Flex>
                                        </RadioRow>

                                        <Spacer height="3" />

                                        <RadioRow
                                            name="customField.variety"
                                            value={CustomFieldVariety.Position}
                                        >
                                            <Flex direction="column">
                                                <Box>{translate('missions_63972')}</Box>
                                                <Box fontWeight="regular">
                                                    {translate('champ_personnal_47056')}
                                                </Box>
                                            </Flex>
                                        </RadioRow>

                                        <Spacer height="6" />
                                    </>
                                )}

                                <TextInput
                                    label={translate('nom_du_champ_48329')}
                                    name="customField.name"
                                />

                                <Spacer height="6" />

                                <TextInput
                                    label={
                                        <LabelOptional>
                                            {translate('sous_titre_97472')}
                                        </LabelOptional>
                                    }
                                    name="customField.description"
                                />

                                <Spacer height="6" />

                                <Select
                                    label={translate('type_35427')}
                                    name="customField.fieldType"
                                    state={props.isEdit ? 'disabled' : 'active'}
                                >
                                    {enumToOptions(CREATABLE_FIELD_TYPES)}
                                </Select>

                                <Spacer height="6" />

                                <RichSelect
                                    isSearchVisible={true}
                                    isCreateVisible={true}
                                    label={translate('cat_gorie_00291')}
                                    name="customField.customFieldCategoryId"
                                    placeholder={translate('rechercher_ou_c_16469')}
                                    onCreateClick={() => {
                                        setIsCreateCategoryOpen(true);
                                    }}
                                >
                                    {categories.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                </RichSelect>

                                {currentFieldType === FieldType.Select && (
                                    <>
                                        <Spacer height="6" />

                                        <CreateUpdateSelectValues
                                            isEdit={props.isEdit}
                                            isSelectV2={props.isSelectV2}
                                            values={values.customField.values}
                                            change={form.change}
                                        />

                                        <>
                                            <Spacer height="6" />

                                            <Box color="gray800" fontWeight="semiBold">
                                                {translate('est_ce_qu_il_es_10680')}
                                            </Box>

                                            <Spacer height="2" />

                                            <CheckboxText
                                                name="customField.canSelectMultiple"
                                                disabled={props.isEdit}
                                            >
                                                {translate('oui_il_est_pos_25233')}
                                            </CheckboxText>
                                        </>
                                    </>
                                )}

                                {currentFieldType === FieldType.Validation && (
                                    <>
                                        <Spacer height="6" />

                                        <FileS3Input
                                            accept={[Accept.Images, Accept.Pdf]}
                                            acl="public-read"
                                            label={
                                                <LabelOptional>
                                                    {translate('document_87149')}
                                                </LabelOptional>
                                            }
                                            prefix="customField.document."
                                        />
                                    </>
                                )}

                                {values.customField.variety === CustomFieldVariety.UserInfo && (
                                    <>
                                        <HorizontalSpacerSeparator height="6" />

                                        <Box
                                            color="gray800"
                                            fontSize="textMd"
                                            fontWeight="semiBold"
                                        >
                                            {translate('conditions_77756')}
                                        </Box>

                                        <Spacer height="4" />

                                        {currentFieldType !== FieldType.Validation && (
                                            <>
                                                <Box color="gray800" fontWeight="semiBold">
                                                    {translate('ce_champ_est_il_98722')}
                                                </Box>

                                                <Spacer height="2" />

                                                {props.isFromForm ? (
                                                    <Tooltip>
                                                        <Trigger>
                                                            <Box>
                                                                <CheckboxText
                                                                    disabled={true}
                                                                    name="customField.isPrivate"
                                                                >
                                                                    {translate(
                                                                        'oui_le_champ_n_99925'
                                                                    )}
                                                                </CheckboxText>
                                                            </Box>
                                                        </Trigger>

                                                        <Content placement="top">
                                                            {translate('vous_ne_pouvez_76741')}
                                                        </Content>
                                                    </Tooltip>
                                                ) : (
                                                    <CheckboxText name="customField.isPrivate">
                                                        {translate('oui_le_champ_n_99925')}
                                                    </CheckboxText>
                                                )}
                                            </>
                                        )}

                                        {currentFieldType !== FieldType.Validation &&
                                            otherCustomFields.length > 0 && <Spacer height="6" />}

                                        {otherCustomFields.length > 0 && (
                                            <>
                                                <Box color="gray800" fontWeight="semiBold">
                                                    {translate('l_affichage_de_99189')}
                                                </Box>

                                                <Spacer height="2" />

                                                <CheckboxText name="customField.hasCondition">
                                                    {translate('oui_le_champ_d_00513')}
                                                </CheckboxText>
                                            </>
                                        )}

                                        {currentHasCondition && (
                                            <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                                <Spacer height="4" />

                                                <Select
                                                    label={translate('nom_du_champ_do_17104')}
                                                    name="customField.conditionCustomFieldId"
                                                    parseInt={true}
                                                >
                                                    {otherCustomFields.map((customField) => (
                                                            <option
                                                                value={customField.id}
                                                                key={customField.id}
                                                            >
                                                                {customField.name}
                                                            </option>
                                                        ))}
                                                </Select>

                                                <Spacer height="4" />

                                                {conditionCustomField &&
                                                    conditionCustomField.fieldType ===
                                                        FieldType.Checkbox && (
                                                        <Select
                                                            name="customField.conditionValue"
                                                            label={translate(
                                                                'pour_quelle_val_60529',
                                                                conditionCustomField.name
                                                            )}
                                                            parseBoolean={true}
                                                        >
                                                            <option value="true">
                                                                {translate('true')}
                                                            </option>
                                                            <option value="false">
                                                                {translate('false')}
                                                            </option>
                                                        </Select>
                                                    )}

                                                {conditionCustomField &&
                                                    conditionCustomField.fieldType ===
                                                        FieldType.Select && (
                                                        <RichSelect
                                                            key={conditionCustomField.id}
                                                            isSearchVisible={true}
                                                            multiple={true}
                                                            label={translate(
                                                                'pour_quelle_val_60529',
                                                                conditionCustomField.name
                                                            )}
                                                            name="customField.conditionValue"
                                                        >
                                                            {conditionCustomField.values.map(
                                                                ({ id, value }) => (
                                                                        <option key={id} value={id}>
                                                                            {value}
                                                                        </option>
                                                                    )
                                                            )}
                                                        </RichSelect>
                                                    )}
                                            </Flex>
                                        )}
                                    </>
                                )}

                                <Spacer height="7" />
                            </RightPanelBody>

                            <RightPanelFooter>
                                <Flex justify="end" gap="3">
                                    <Button onClick={handleSubmit} isLoading={submitting}>
                                        {props.isEdit
                                            ? translate('mettre_jour_l_31784')
                                            : translate('cr_er_le_champ_05516')}
                                    </Button>

                                    <Button color="white" onClick={props.onClose}>
                                        {translate('annuler_48254')}
                                    </Button>
                                </Flex>
                            </RightPanelFooter>
                        </RightPanel>

                        {isCreateCategoryOpen && (
                            <CreateUpdateCategoryModal
                                initialName={''}
                                isEdit={false}
                                mutate={async (name) => {
                                    const { customFieldCategoryCreate } = await create({
                                        organizationId: props.organizationId,
                                        category: { name }
                                    });

                                    return customFieldCategoryCreate;
                                }}
                                onClose={() => {
                                    setIsCreateCategoryOpen(false);
                                }}
                                onSuccess={(category: {
                                    id: CustomFieldsCategoryId;
                                    name: string;
                                }) => {
                                    setCategories([...categories, category]);

                                    form.change('customField.customFieldCategoryId', category.id);
                                }}
                            />
                        )}
                    </>
                )}
        />
    );
};

interface ICreateCustomFieldProps {
    isFromForm?: boolean;
    organizationId: OrganizationId;

    onClose(): void;
    onSuccess(customField: FormCustomFieldFragment): void;
}

export const CreateCustomField = (props: ICreateCustomFieldProps) => {
    const customFieldInput = useService(CustomFieldInputService);
    const { data, loader } = useOrganizationInfosQuery({ organizationId: props.organizationId });
    const { mutate } = useCustomFieldCreateMutation({ redirectOnSuccess: true });

    if (loader) {
        return <RightPanelLoader path={HeaventPaths.CUSTOM_FIELDS(props.organizationId)} />;
    } else {
        return (
            <CreateUpdateCustomField
                canDelete={true}
                customFieldId={null}
                initialValues={{
                    customField: customFieldInput.customFieldInputDefault(
                        null,
                        data.organization.customFieldsCategories.nodes
                    )
                }}
                isEdit={false}
                isFromForm={props.isFromForm}
                isSelectV2={true}
                organization={data.organization}
                organizationId={props.organizationId}
                mutate={mutate}
                onClose={props.onClose}
                onSuccess={props.onSuccess}
            />
        );
    }
};

interface IUpdateCustomFieldProps {
    customFieldId: CustomFieldId;
    organizationId: OrganizationId;

    onClose(): void;
    onSuccess(customField: FormCustomFieldFragment): void;
}

export const UpdateCustomFieldWithParams = (props: IUpdateCustomFieldProps) => {
    const customFieldInput = useService(CustomFieldInputService);
    const { data, loader } = useCustomFieldToEditQuery({
        organizationId: props.organizationId,
        customFieldId: props.customFieldId
    });
    const { mutate } = useCustomFieldUpdateMutation({ redirectOnSuccess: true });

    if (loader) {
        return <RightPanelLoader path={HeaventPaths.CUSTOM_FIELDS(props.organizationId)} />;
    } else {
        return (
            <CreateUpdateCustomField
                canDelete={data.organization.customField.canDelete}
                customFieldId={props.customFieldId}
                initialValues={{
                    customField: customFieldInput.customFieldInputDefault(
                        data.organization.customField,
                        data.organization.customFieldsCategories.nodes
                    )
                }}
                isEdit={true}
                isSelectV2={data.organization.customField.isSelectV2}
                organization={data.organization}
                organizationId={props.organizationId}
                mutate={(values) => mutate({
                        ...values,
                        customFieldId: props.customFieldId
                    })}
                onClose={props.onClose}
                onSuccess={props.onSuccess}
            />
        );
    }
};

export const UpdateCustomField = (
    props: Omit<IUpdateCustomFieldProps, 'customFieldId' | 'organizationId'>
) => {
    const {
        params: { organizationId, customFieldId }
    } = useHeavent();
    return (
        <UpdateCustomFieldWithParams
            customFieldId={customFieldId}
            organizationId={organizationId}
            {...props}
        />
    );
};
