import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

interface IAccordionProps {
    children: React.ReactNode;
    css?: CSS;
    title: string;
}

export const Accordion = (props: IAccordionProps) => {
    const [isOpen, setIsOpen] = React.useState(true);

    return (
        <Flex
            direction="column"
            gap="4"
            css={{
                borderTop: '1px solid $gray200',
                padding: '$6',
                ...(props.css as any)
            }}
        >
            <Flex
                align="center"
                gap="4"
                css={{ cursor: 'pointer', userSelect: 'none' }}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <Box color="gray500" fontSize="textXs">
                    <I icon={isOpen ? 'chevron-down' : 'chevron-right'} />
                </Box>

                <Box color="gray800" fontWeight="semiBold">
                    {props.title}
                </Box>
            </Flex>

            {isOpen && props.children}
        </Flex>
    );
};
