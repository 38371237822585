import { I } from 'common/src/designSystem/components/i';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

const _RadioRow = styled('div', {
    alignItems: 'center',
    borderRadius: '$2',
    cursor: 'pointer',
    display: 'flex',
    gap: '$4',
    padding: '$4',
    userSelect: 'none',
    '&:hover': {
        boxShadow: '$xs'
    },
    '& .radio-row-text': {
        flex: 1,
        fontWeight: '$medium'
    },
    '& .radio-row-button': {
        alignItems: 'center',
        borderRadius: '16px',
        color: 'white',
        display: 'flex',
        fontSize: '10px',
        height: '16px',
        justifyContent: 'center',
        width: '16px'
    },
    variants: {
        state: {
            unchecked: {
                background: 'white',
                border: '1px solid $gray200',
                '&:hover': {
                    background: '$gray50'
                },
                '& .radio-row-text': {
                    color: '$gray700'
                },
                '& .radio-row-button': {
                    background: 'white',
                    border: '1px solid $gray300'
                }
            },
            checked: {
                background: '$primary100',
                border: '1px solid $primary700',
                boxShadow: '$xs',
                '& .radio-row-text': {
                    color: '$primary800'
                },
                '& .radio-row-button': {
                    background: '$primary700',
                    border: '1px solid $primary700'
                }
            }
        }
    },
    defaultVariants: {
        state: 'unchecked'
    }
});

export type IRadioRowState = 'unchecked' | 'checked';

interface IRadioRowProps {
    children: React.ReactNode;
    state?: IRadioRowState;

    onClick(state: IRadioRowState): void;
}

export const RadioRow = (props: IRadioRowProps) => (
        <_RadioRow
            state={props.state}
            onClick={() => {
                if (props.state === 'unchecked' || !props.state) {
                    props.onClick('checked');
                } else {
                    props.onClick('unchecked');
                }
            }}
        >
            <div className="radio-row-text">{props.children}</div>

            <div className="radio-row-button">
                {props.state === 'checked' && <I icon="check" />}
            </div>
        </_RadioRow>
    );
