import { ColorInput } from 'common-front/src/designSystem/form/colorInput';
import { FloatInput } from 'common-front/src/designSystem/form/floatInput';
import { IconSelect } from 'common-front/src/designSystem/form/iconSelect';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { AccreditationPositionProperty, Tag, Trace } from 'common/src/generated/types';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IMassEditFieldInputProps {
    categories: Array<{ id: number; name: string }>;
    property: AccreditationPositionProperty;
    tags: Array<Pick<Tag, 'id' | 'name'>>;
    traces: Array<Pick<Trace, 'id' | 'name'>>;
    value: any;
}

export const MassEditFieldInput = (props: IMassEditFieldInputProps) => {
    const translate = useTranslate();
    const translatedProperty = translate(props.property);

    switch (props.property) {
        case AccreditationPositionProperty.Acronym:
            return (
                <TextInput
                    name="massEdit.value"
                    label={translate('nouvelle_valeur_39039', translatedProperty)}
                />
            );
        case AccreditationPositionProperty.Address:
            return (
                <TextInput
                    name="massEdit.value"
                    label={translate('nouvelle_valeur_39039', translatedProperty)}
                />
            );
        case AccreditationPositionProperty.Category:
            return (
                <RichSelect
                    isSearchVisible={true}
                    name="massEdit.value"
                    label={translate('nouvelle_valeur_39039', translatedProperty)}
                >
                    {props.categories.map((category) => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                </RichSelect>
            );
        case AccreditationPositionProperty.Color:
            return (
                <ColorInput
                    name="massEdit.value"
                    label={translate('nouvelle_valeur_39039', translatedProperty)}
                />
            );
        case AccreditationPositionProperty.Description:
            return (
                <Box width={1} css={{ overflow: 'hidden auto' }}>
                    <RichEditor
                        name="massEdit.value"
                        label={translate('nouvelle_valeur_39039', translatedProperty)}
                    />
                </Box>
            );
        case AccreditationPositionProperty.Icon:
            return (
                <IconSelect
                    name="massEdit.value"
                    label={translate('nouvelle_valeur_39039', translatedProperty)}
                />
            );
        case AccreditationPositionProperty.Latitude:
            return (
                <FloatInput
                    name="massEdit.value"
                    label={translate('nouvelle_valeur_39039', translatedProperty)}
                />
            );
        case AccreditationPositionProperty.Longitude:
            return (
                <FloatInput
                    name="massEdit.value"
                    label={translate('nouvelle_valeur_39039', translatedProperty)}
                />
            );
        case AccreditationPositionProperty.Name:
            throw new Error('Should not happen');
        case AccreditationPositionProperty.Tag:
            return (
                <RichSelect
                    isSearchVisible={true}
                    multiple={true}
                    label={translate('nouvelle_valeur_39039', translatedProperty)}
                    name="massEdit.value"
                >
                    {props.tags.map((tag) => (
                            <option key={tag.id} value={tag.id}>
                                {tag.name}
                            </option>
                        ))}
                </RichSelect>
            );
        case AccreditationPositionProperty.Traces:
            return (
                <RichSelect
                    isSearchVisible={true}
                    multiple={true}
                    label={translate('nouvelle_valeur_39039', translate('Traces'))}
                    name="massEdit.value"
                >
                    {props.traces.map((trace) => (
                            <option key={trace.id} value={trace.id}>
                                {trace.name}
                            </option>
                        ))}
                </RichSelect>
            );
        default:
            return assertUnreachable(props.property);
    }
};
