import { RadioText } from 'common-front/src/designSystem/form/radio';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { AccreditationDisplay, AccreditationsSlotInput, Event } from 'common/src/generated/types';
import { ICreateUpdateAccreditationValues } from 'common/src/input/accreditationInput';
import { AccreditationsSlotInputService } from 'common/src/input/accreditationsSlotInput';
import { removeAtIndex } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { repeatSlot } from 'common/src/vo/accreditationSlot';
import { DateTime } from 'luxon';
import * as React from 'react';
import { CreateSlots } from '../../slots/create/createSlots';
import { CreateAccreditationSlot } from './createAccreditationSlot';

interface ICreateAccreditationSlotsProps {
    event: Pick<Event, 'startAt' | 'endAt'>;
    values: ICreateUpdateAccreditationValues;

    change(name: string, value: any): void;
}

export const CreateAccreditationSlots = (props: ICreateAccreditationSlotsProps) => {
    const translate = useTranslate();
    const accreditationsSlotInput = useService(AccreditationsSlotInputService);
    const [isEditOpenIndex, setIsEditOpenIndex] = React.useState(-1);
    const updateSlots = React.useCallback(
        (newSlot: AccreditationsSlotInput, index: number) => {
            const newSlots = [...props.values.accreditation.slots];
            newSlots[index] = newSlot;

            props.change('accreditation.slots', newSlots);
        },
        [props.values.accreditation.slots, props.change]
    );
    const matchDateFilter = React.useCallback((slot: AccreditationsSlotInput, date: DateTime) => {
        if (slot.date?.isValid) {
            return slot.date.startOf('day') === date.startOf('day');
        } else {
            return false;
        }
    }, []);
    const onNewSlot = React.useCallback(() => {
        props.change(
            'accreditation.slots',
            props.values.accreditation.slots.concat([
                accreditationsSlotInput.accreditationsSlotInputDefault()
            ])
        );

        setIsEditOpenIndex(props.values.accreditation.slots.length);
    }, [props.values.accreditation.slots, props.change]);
    const onRepeat = React.useCallback(
        (slot: AccreditationsSlotInput, value: number, unit: string) => {
            const newSlots = repeatSlot(slot, value, unit);

            props.change('accreditation.slots', props.values.accreditation.slots.concat(newSlots));
        },
        [props.values.accreditation.slots, props.change]
    );
    const onRemove = React.useCallback(
        (index: number) => {
            props.change(
                'accreditation.slots',
                removeAtIndex(props.values.accreditation.slots, index)
            );
        },
        [props.values.accreditation.slots, props.change]
    );

    return (
        <Flex direction="column" gap="7">
            <CreateSlots
                event={props.event}
                slots={props.values.accreditation.slots}
                change={props.change}
                matchDateFilter={matchDateFilter}
                onNewSlot={onNewSlot}
                renderSlot={(slot) => (
                        <CreateAccreditationSlot
                            key={slot.index}
                            event={props.event}
                            index={slot.index}
                            initialIsEditOpen={slot.index === isEditOpenIndex}
                            slot={slot}
                            onRepeat={onRepeat}
                            remove={onRemove}
                            updateSlots={updateSlots}
                        />
                    )}
            />

            <Flex direction="column">
                <Box font="gray900 textMd medium">{translate('type_d_affichag_09962')}</Box>

                <Spacer height="3" />

                <Box font="gray800 textSm medium">{translate('comment_souhait_62602')}</Box>

                <Spacer height="1" />

                <RadioText
                    name="accreditation.accreditationDisplay"
                    value={AccreditationDisplay.List}
                >
                    {translate('afficher_sous_f_71276')}
                </RadioText>

                <Spacer height="2" />

                <RadioText
                    name="accreditation.accreditationDisplay"
                    value={AccreditationDisplay.Radio}
                >
                    {translate('afficher_sous_f_61997')}
                </RadioText>

                <Spacer height="2" />

                <RadioText
                    name="accreditation.accreditationDisplay"
                    value={AccreditationDisplay.Calendar}
                >
                    {translate('afficher_sous_f_15380')}
                </RadioText>
            </Flex>
        </Flex>
    );
};
