import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';
import { FullScreenPopup } from './fullScreenPopup/fullScreenPopup';

interface IAssignPopupProps {
    button: React.ReactNode;
    children: React.ReactNode;
    popup: {
        title: string;
        category: string;
        closePath: string;
    };
}

export const AssignPopup = (props: IAssignPopupProps) => (
        <FullScreenPopup
            category={props.popup.category}
            closePath={props.popup.closePath}
            title={props.popup.title}
        >
            <Flex direction="column" height={1} width={1} css={{ overflow: 'hidden' }}>
                <Flex
                    justify="center"
                    css={{ background: '$gray50', flex: '1', overflow: 'auto', py: '$9' }}
                >
                    <Flex
                        direction="column"
                        width={1170}
                        css={{
                            height: 'fit-content',
                            overflow: 'hidden'
                        }}
                    >
                        {props.children}
                    </Flex>
                </Flex>

                <Flex
                    align="center"
                    justify="center"
                    height={72}
                    width={1}
                    css={{
                        boxShadow:
                            '0px -4px 8px -2px rgba(16, 24, 40, 0.1), 0px -2px 4px -2px rgba(16, 24, 40, 0.06)'
                    }}
                >
                    {props.button}
                </Flex>
            </Flex>
        </FullScreenPopup>
    );
