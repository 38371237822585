import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { DelegationsPaths } from 'common/src/delegationsPaths';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventQuery, Feature } from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { AccreditationsAccredit } from '../../accreditations/accredit/accreditationsAccredit';
import {
    CreateAccreditation,
    UpdateAccreditation
} from '../../accreditations/create/createUpdateAccreditation';
import { AccreditationsHasFeature } from '../../accreditations/list/accreditationsHasFeature';
import { Accreditation } from '../../accreditations/show/accreditation';
import { Accredit } from '../../accreditationsAssignments/accredit/accredit';
import { AccreditationsAssignments } from '../../accreditationsAssignments/list/accreditationsAssignments';
import { Api } from '../../api/api';
import { Weezevent } from '../../api/integration/weezevent/weezevent';
import { Assignment } from '../../assignments/assign/assignment';
import { Assignments } from '../../assignments/list/assignments';
import { TeamAssignment } from '../../assignments/teamAssignment/teamAssignment';
import { CreateCampaign, UpdateCampaign } from '../../campaigns/create/createUpdateCampaign';
import { Campaigns } from '../../campaigns/list/campaigns';
import { EventCampaign } from '../../campaigns/show/campaign';
import { Dashboard } from '../../dashboard/dashboard';
import {
    CreateEventDelegation,
    UpdateEventDelegation
} from '../../delegations/create/createUpdateDelegation';
import { DelegationsImport } from '../../delegations/import/delegationsImport';
import { DelegationsHasFeature } from '../../delegations/list/delegationsHasFeature';
import { Delegation } from '../../delegations/show/delegation';
import { DocumentsLoader } from '../../documents/list/documentsLoader';
import { PositionPlanningDocument } from '../../documents/show/positionPlanningDocument';
import { PositionSheetDocument } from '../../documents/show/positionSheetDocument';
import { PositionsPlanningDocument } from '../../documents/show/positionsPlanningDocument';
import { PositionsVolunteersPlanningDocument } from '../../documents/show/positionsVolunteersPlanningDocument';
import { UserAssignmentsDocument } from '../../documents/show/userAssignmentsDocument';
import { UserBadgeDocument } from '../../documents/show/userBadgeDocument';
import { UserCertificateDocument } from '../../documents/show/userCertificateDocument';
import { UserCustomBadge } from '../../documents/show/userCustomBadge';
import { UserPlanningDaysDocument } from '../../documents/show/userPlanningDaysDocument';
import { UserPlanningDocument } from '../../documents/show/userPlanningDocument';
import { UserPlanningListDocument } from '../../documents/show/userPlanningListDocument';
import { UsersListingDocument } from '../../documents/show/usersListingDocument';
import { Emails } from '../../emails/emails';
import { FilesImport } from '../../files/import/filesImport';
import { EventForms } from '../../forms/list/forms';
import { EventForm } from '../../forms/show/form';
import { useEventQuery } from '../../generated/graphqlHooks';
import { OrganizationComponent } from '../../organization/organizationComponent';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CreatePosition, UpdatePosition } from '../../positions/create/createUpdatePosition';
import { PositionsLoader } from '../../positions/list/positionsLoader';
import { Position } from '../../positions/show/position';
import { PositionCategory } from '../../positionsCategories/show/positionCategory';
import { PositionsSlotsAssignVolunteers } from '../../positionsSlots/assignVolunteers/positionsSlotsAssignVolunteers';
import { UserProfile } from '../../users/profile/userProfile';
import { UpdateUserLoaderEvent } from '../../users/update/updateUserLoader';
import { UserUpdateAccreditationsLoader } from '../../users/updateAccreditations/userUpdateAccreditationsLoader';
import { UserUpdateMissionsLoader } from '../../users/updateMissions/userUpdateMissionsLoader';
import { UserUpdateSlotsLoader } from '../../users/updateSlots/userUpdateSlotsLoader';
import { VolunteersImport } from '../../volunteers/import/volunteersImport';
import { VolunteersLoader } from '../../volunteers/list/volunteersLoader';
import { UpdateVolunteer } from '../../volunteers/update/updateVolunteer';
import { EventContextProvider, useEventContext } from './eventContext';
import { EventMobile } from './eventMobile';
import { MenuItem } from './menuItem';
import * as Rules from './rules';

interface IEventComponentProps {
    event: EventQuery['event'];
}

const EventComponent = (props: IEventComponentProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const { numberOfWaitingAssigments, numberOfWaitingAccreditations, updateLocalEvent } =
        useEventContext();
    const isEventAdmin = props.event.permissions.isEventAdmin;
    const isPositionAdmin = Rules.isPositionAdmin(props.event);

    return (
        <Flex height={1} width={1}>
            <nav>
                <Flex
                    align="center"
                    direction="column"
                    gap="1"
                    css={{
                        background: '$gray100',
                        borderRight: '1px solid $gray200',
                        height: '100%',
                        width: '64px'
                    }}
                >
                    <Spacer height="10" />

                    <MenuItem
                        icon="house"
                        path={HeaventPaths.DASHBOARD(organizationId, eventId)}
                        title={translate('tableau_de_bord_24627')}
                    />

                    <MenuItem
                        icon="sitemap"
                        path={HeaventPaths.POSITIONS(organizationId, eventId)}
                        title={translate('missions_63972')}
                        isActive={(pathname: string) => (
                                (pathname.endsWith('/positions') ||
                                    pathname.match(/\/positions\/\d+\/list$/) !== null ||
                                    pathname.match(/\/positions\/\d+\/tiles$/) !== null ||
                                    pathname.match(/\/positions\/\d+\/map/) !== null ||
                                    pathname.match(/\/positions\/\d+\/calendar/) !== null ||
                                    pathname.match(/\/position\/\d+/) !== null ||
                                    pathname.match(/\/category\/\d+/) !== null) &&
                                !pathname.includes('documents')
                            )}
                        onClick={() => updateLocalEvent({ positionsSegmentId: null })}
                    />

                    <MenuItem
                        icon="user-group"
                        path={HeaventPaths.VOLUNTEERS(organizationId, eventId)}
                        title={translate('membres_11310')}
                        isActive={(pathname: string) => (
                                pathname.endsWith('/volunteers') ||
                                pathname.match(/\/volunteers\/\d+/) !== null
                            )}
                        onClick={() => updateLocalEvent({ volunteersSegmentId: null })}
                    />

                    {hasFeature(Feature.Delegation) && isEventAdmin && (
                        <MenuItem
                            icon="people-group"
                            path={HeaventPaths.DELEGATIONS(organizationId, eventId)}
                            title={translate('d_l_gations_78318')}
                            isActive={(pathname) => (
                                    pathname.match(/\/delegations\/\d+/) !== null ||
                                    pathname.match(/\/delegation\/\d+/) !== null
                                )}
                            onClick={() => updateLocalEvent({ delegationsSegmentId: null })}
                        />
                    )}

                    {isPositionAdmin && (
                        <MenuItem
                            icon="wand-magic-sparkles"
                            path={HeaventPaths.ASSIGNMENTS(organizationId, eventId)}
                            title={`${translate(
                                'affectations_de_07531'
                            )} (${numberOfWaitingAssigments})`}
                            isActive={(pathname: string) => (
                                    pathname.endsWith('/assignments') ||
                                    pathname.endsWith('/assignments/blank') ||
                                    pathname.match(/\/assignment\/\d+/) !== null ||
                                    pathname.match(/\/pre-assignment\/\d+/) !== null
                                )}
                        />
                    )}

                    {isEventAdmin && hasFeature(Feature.Accreditation) && (
                        <MenuItem
                            icon="sparkles"
                            path={HeaventPaths.ACCREDITATIONS_ASSIGNMENTS(organizationId, eventId)}
                            title={`${translate(
                                'affectations_de_99700'
                            )} (${numberOfWaitingAccreditations})`}
                            isActive={(pathname: string) => (
                                    pathname.endsWith('/accreditations-assignments') ||
                                    pathname.match(/\/accredit\/\d+/) !== null
                                )}
                        />
                    )}

                    {isEventAdmin && (
                        <MenuItem
                            icon="file-lines"
                            path={HeaventPaths.FORMS(organizationId, eventId)}
                            title={translate('formulaires_39300')}
                        />
                    )}

                    {isPositionAdmin && (
                        <MenuItem
                            icon="paper-plane"
                            path={HeaventPaths.CAMPAIGNS(organizationId, eventId)}
                            title={translate('campagnes_31372')}
                            isActive={(pathname: string) => (
                                    pathname.endsWith('/campaigns') ||
                                    pathname.match(/\/campaign\/\d+/) !== null
                                )}
                        />
                    )}

                    {isEventAdmin && (
                        <MenuItem
                            icon="badge-check"
                            path={HeaventPaths.ACCREDITATIONS(organizationId, eventId)}
                            title={translate('accr_ditations_39450')}
                            isActive={(pathname: string) => (
                                    pathname.match(/\/accreditations\/\d+/) !== null ||
                                    pathname.match(/\/accreditation\/\d+/) !== null
                                )}
                            onClick={() => updateLocalEvent({ accreditationsSegmentId: null })}
                        />
                    )}

                    {isEventAdmin && (
                        <MenuItem
                            icon="at"
                            path={HeaventPaths.EVENT_EMAILS_PARAMETERS(organizationId, eventId)}
                            title={translate('e_mails_09850')}
                            isActive={(pathname: string) => (
                                    pathname.endsWith('/emails/parameters') ||
                                    pathname.endsWith('/emails/templates') ||
                                    pathname.endsWith('/emails/dns')
                                )}
                        />
                    )}

                    {isEventAdmin && (
                        <MenuItem
                            icon="folders"
                            path={HeaventPaths.DOCUMENTS(organizationId, eventId)}
                            title={translate('documents_87028')}
                            isActive={(pathname: string) => (
                                    pathname.endsWith('/documents') ||
                                    pathname.match(/\/documents\//) !== null
                                )}
                        />
                    )}

                    {isEventAdmin && (
                        <MenuItem
                            icon="code-branch"
                            path={HeaventPaths.API_API(organizationId, eventId)}
                            title={translate('api_et_int_grat_54084')}
                        />
                    )}
                </Flex>
            </nav>

            <Box
                css={{
                    flex: '1',
                    height: '100%',
                    overflowX: 'hidden'
                }}
            >
                <Switch>
                    <Route
                        exact
                        path={HeaventPaths.DASHBOARD(':organizationId', ':eventId')}
                        children={<Dashboard />}
                    />

                    <Route
                        path={HeaventPaths.POSITIONS(':organizationId', ':eventId')}
                        children={<PositionsLoader />}
                    />

                    <Route
                        path={HeaventPaths.POSITION_CATEGORY(
                            ':organizationId',
                            ':eventId',
                            ':positionCategoryId'
                        )}
                        children={<PositionCategory />}
                    />

                    <Route
                        path={HeaventPaths.POSITION(':organizationId', ':eventId', ':positionId')}
                        children={<Position />}
                    />

                    <Route
                        path={HeaventPaths.VOLUNTEERS(':organizationId', ':eventId')}
                        children={<VolunteersLoader />}
                    />

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.EDIT_VOLUNTEER(
                                ':organizationId',
                                ':eventId',
                                ':volunteerRegistrationId'
                            )}
                            children={<UpdateVolunteer />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            path={[
                                HeaventPaths.EDIT_USER(
                                    ':organizationId',
                                    ':eventId',
                                    ':userInfoId'
                                ),
                                HeaventPaths.EDIT_USER_DELEGATION(
                                    ':organizationId',
                                    ':eventId',
                                    ':delegationId',
                                    ':userInfoId'
                                )
                            ]}
                            children={<UpdateUserLoaderEvent />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_USER_ACCREDITATIONS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                            children={<UserUpdateAccreditationsLoader />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_USER_MISSIONS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                            children={<UserUpdateMissionsLoader />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EDIT_USER_SLOTS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                            children={<UserUpdateSlotsLoader />}
                        />
                    )}

                    {hasFeature(Feature.Delegation) && isEventAdmin && (
                        <Route
                            path={HeaventPaths.DELEGATIONS(':organizationId', ':eventId')}
                            children={<DelegationsHasFeature />}
                        />
                    )}

                    {hasFeature(Feature.Delegation) && isEventAdmin && (
                        <Route
                            path={DelegationsPaths.DELEGATION({
                                organizationId: ':organizationId',
                                eventId: ':eventId',
                                delegationId: ':delegationId'
                            })}
                            children={<Delegation />}
                        />
                    )}

                    {isPositionAdmin && (
                        <Route
                            path={HeaventPaths.ASSIGNMENTS(':organizationId', ':eventId')}
                            children={<Assignments />}
                        />
                    )}

                    {isPositionAdmin && (
                        <Route
                            path={HeaventPaths.ASSIGNMENT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                            children={<Assignment />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.TEAM_ASSIGNMENT(
                                ':organizationId',
                                ':eventId',
                                ':teamCode'
                            )}
                            children={<TeamAssignment />}
                        />
                    )}

                    {isPositionAdmin && (
                        <Route
                            path={HeaventPaths.PRE_ASSIGNMENT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                            children={<Assignment />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.ACCREDITATIONS_ASSIGNMENTS(
                                ':organizationId',
                                ':eventId'
                            )}
                            children={<AccreditationsAssignments />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.ACCREDIT(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                            children={<Accredit />}
                        />
                    )}

                    <Route
                        path={HeaventPaths.FORMS(':organizationId', ':eventId')}
                        children={<EventForms />}
                    />

                    {isPositionAdmin && (
                        <Route
                            path={HeaventPaths.CAMPAIGNS(':organizationId', ':eventId')}
                            children={<Campaigns />}
                        />
                    )}

                    {isPositionAdmin && (
                        <Route
                            path={HeaventPaths.CAMPAIGN(
                                ':organizationId',
                                ':eventId',
                                ':campaignId'
                            )}
                            children={<EventCampaign />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.EVENT_EMAILS(':organizationId', ':eventId')}
                            children={<Emails />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_USER_PLANNING(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                            children={<UserPlanningDocument />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_USER_PLANNING_LIST(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                            children={<UserPlanningListDocument />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_USER_PLANNING_DAYS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                            children={<UserPlanningDaysDocument />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_ASSIGNMENTS(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                            children={<UserAssignmentsDocument />}
                        />
                    )}

                    <Route
                        exact
                        path={HeaventPaths.DOCUMENTS_VOLUNTEERS_LISTING(
                            ':organizationId',
                            ':eventId',
                            ':segmentId'
                        )}
                        children={<UsersListingDocument />}
                    />

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_BADGE(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId',
                                'generic'
                            )}
                            children={<UserBadgeDocument />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_BADGE(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId',
                                ':badgeType'
                            )}
                            children={<UserCustomBadge />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_CERTIFICATE(
                                ':organizationId',
                                ':eventId',
                                ':userInfoId'
                            )}
                            children={<UserCertificateDocument />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_POSITION_PLANNING(
                                ':organizationId',
                                ':eventId',
                                ':positionId'
                            )}
                            children={<PositionPlanningDocument />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_POSITIONS_VOLUNTEERS_PLANNING(
                                ':organizationId',
                                ':eventId',
                                ':segmentId'
                            )}
                            children={<PositionsVolunteersPlanningDocument />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_POSITION(
                                ':organizationId',
                                ':eventId',
                                ':positionId'
                            )}
                            children={<PositionSheetDocument />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS_PLANNING(
                                ':organizationId',
                                ':eventId',
                                ':segmentId'
                            )}
                            children={<PositionsPlanningDocument />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.DOCUMENTS(':organizationId', ':eventId')}
                            children={<DocumentsLoader />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.API_WEEZEVENT(':organizationId', ':eventId')}
                            children={<Weezevent />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.API(':organizationId', ':eventId')}
                            children={<Api />}
                        />
                    )}

                    {isEventAdmin && (
                        <Route
                            path={HeaventPaths.ACCREDITATIONS(':organizationId', ':eventId')}
                            children={<AccreditationsHasFeature />}
                        />
                    )}

                    {hasFeature(Feature.Accreditation) && isEventAdmin && (
                        <Route
                            path={HeaventPaths.ACCREDITATION(
                                ':organizationId',
                                ':eventId',
                                ':accreditationId'
                            )}
                            children={<Accreditation />}
                        />
                    )}

                    <Route
                        path={HeaventPaths.EVENT_USER_PROFILE(
                            ':organizationId',
                            ':eventId',
                            ':userInfoId'
                        )}
                        children={<UserProfile />}
                    />

                    <Route
                        children={
                            <Empty
                                path={HeaventPaths.DASHBOARD(organizationId, eventId)}
                                replace={true}
                            />
                        }
                    />
                </Switch>
            </Box>
        </Flex>
    );
};

interface IEventRoutesProps {
    event: EventQuery['event'];
}

const EventRoutes = (props: IEventRoutesProps) => {
    const { organizationId, eventId } = useParams();
    const isEventAdmin = props.event.permissions.isEventAdmin;
    const isPositionCategoryAdmin = Rules.isPositionCategoryAdmin(props.event);
    const isPositionAdmin = Rules.isPositionAdmin(props.event);

    return (
        <Switch>
            {isPositionCategoryAdmin && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_POSITION(':organizationId', ':eventId', ':options?')}
                    children={<CreatePosition />}
                />
            )}

            {isPositionAdmin && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_POSITION(':organizationId', ':eventId', ':positionId')}
                    children={<UpdatePosition />}
                />
            )}

            {isPositionAdmin && (
                <Route
                    path={HeaventPaths.POSITION_SLOT_ASSIGN_VOLUNTEERS(
                        ':organizationId',
                        ':eventId',
                        ':positionId',
                        ':positionSlotId'
                    )}
                    children={<PositionsSlotsAssignVolunteers />}
                />
            )}

            {isPositionAdmin && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_CAMPAIGN(':organizationId', ':eventId')}
                    children={<CreateCampaign />}
                />
            )}

            {isPositionAdmin && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_CAMPAIGN(':organizationId', ':eventId', ':campaignId')}
                    children={<UpdateCampaign />}
                />
            )}

            {isEventAdmin && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_ACCREDITATION(
                        ':organizationId',
                        ':eventId',
                        ':options?'
                    )}
                    children={<CreateAccreditation />}
                />
            )}

            {isEventAdmin && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_ACCREDITATION(
                        ':organizationId',
                        ':eventId',
                        ':accreditationId'
                    )}
                    children={<UpdateAccreditation />}
                />
            )}

            {isEventAdmin && (
                <Route
                    path={HeaventPaths.ACCREDITATION_ACCREDIT(
                        ':organizationId',
                        ':eventId',
                        ':accreditationId'
                    )}
                    children={<AccreditationsAccredit />}
                />
            )}

            {isEventAdmin && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_DELEGATION(':organizationId', ':eventId')}
                    children={<CreateEventDelegation />}
                />
            )}

            {isEventAdmin && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_DELEGATION(
                        ':organizationId',
                        ':eventId',
                        ':delegationId'
                    )}
                    children={<UpdateEventDelegation />}
                />
            )}

            {isEventAdmin && (
                <Route
                    path={HeaventPaths.FORM(':organizationId', ':eventId', ':formId')}
                    children={<EventForm />}
                />
            )}

            {isEventAdmin && (
                <Route
                    path={HeaventPaths.EVENT_DELEGATIONS_IMPORT(':organizationId', ':eventId')}
                    children={
                        <DelegationsImport
                            fromPath={HeaventPaths.DELEGATIONS(organizationId, eventId)}
                        />
                    }
                />
            )}

            {isEventAdmin && (
                <Route
                    path={HeaventPaths.EVENT_VOLUNTEERS_IMPORT(':organizationId', ':eventId')}
                    children={
                        <VolunteersImport
                            fromPath={HeaventPaths.VOLUNTEERS(organizationId, eventId)}
                        />
                    }
                />
            )}

            {isEventAdmin && (
                <Route
                    path={HeaventPaths.EVENT_FILES_IMPORT(':organizationId', ':eventId')}
                    children={
                        <FilesImport fromPath={HeaventPaths.VOLUNTEERS(organizationId, eventId)} />
                    }
                />
            )}

            <Route
                children={
                    <OrganizationComponent>
                        <EventComponent event={props.event} />
                    </OrganizationComponent>
                }
            />
        </Switch>
    );
};

export const Event = () => {
    const { eventId } = useParams();
    const { data, loader } = useEventQuery({ eventId });
    const { setSelectedEventId } = useOrganizationContext();
    const onMobile = useMobileQuery();

    React.useEffect(() => {
        setSelectedEventId(eventId);

        return () => {
            setSelectedEventId();
        };
    }, [eventId]);

    if (loader) {
        return loader;
    } else {
        const assignmentsStats = data.event.assignmentsStats;

        return (
            <EventContextProvider
                event={data.event}
                numberOfWaitingAccreditations={
                    data.event.accreditationsStats.numberOfWaitingAccreditation
                }
                numberOfWaitingAssigments={
                    data.event.permissions.isEventAdmin
                        ? assignmentsStats.numberOfWaitingAssignment +
                          assignmentsStats.numberOfPreAssigned
                        : assignmentsStats.numberOfPreAssigned
                }
            >
                {onMobile ? <EventMobile /> : <EventRoutes event={data.event} />}
            </EventContextProvider>
        );
    }
};
