import { FullPageHeader } from 'common-front/src/components/mobileFullPage/fullPageHeader';
import { FullPageHeaderText } from 'common-front/src/components/mobileFullPage/fullPageHeaderText';
import { Box } from 'common/src/designSystem/components/box';
import { PositionQuery } from 'common/src/generated/types';
import * as React from 'react';

interface IPositionHeaderMobileProps {
    back: () => void;
    position: PositionQuery['event']['position'];
}

export const PositionHeaderMobile = ({ back, position }: IPositionHeaderMobileProps) => (
        <FullPageHeader back={back}>
            <FullPageHeaderText css={{ minHeight: '40px' }}>
                <Box textAlign="center" color="gray900" fontWeight="medium">
                    {position.name}
                </Box>
                <Box textAlign="center" color="gray500" fontSize="textXs">
                    {position.positionCategoryName}
                </Box>
            </FullPageHeaderText>
        </FullPageHeader>
    );
