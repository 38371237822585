import { groupBy, minBy, sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { slotsToString } from '../ambert/planningAmbert';

const PETIT_DEJEUNER_ID = 2927;
const PETIT_DEJEUNER_GAROTRI_ID = 2938;
const PANIER_REPAS_ID = 2928;
const SOMME_100_ID = 2931;
const SOMME_150_ID = 2932;
const CATERING_TECHNIQUE_ID = 2929;
const SNACK_ID = 2930;
const TICKET_REPAS_ID = 2933;

interface IPlanningGarorockProps {
    event: DocumentUserCustomBadgeFragment;
}

export const PlanningGarorock = (props: IPlanningGarorockProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const byDates = sortBy(
        Object.values(
            groupBy(vr.positionsSlotsUsersInfos, (psui) =>
                psui.positionSlot.range.start!.startOf('day').toMillis()
            )
        ),
        (psuis) => psuis[0].positionSlot.range.start!.startOf('day')
    );
    const hasPlanning = ui.garorockTypeBenevole === 'MRP';
    const petitDejeunerSlots = sortBy(
        vr.accreditationsUsersInfos.flatMap((aui) => {
            if (aui.accreditation.id === PETIT_DEJEUNER_ID && aui.accreditationSlot.date?.isValid) {
                return [aui.accreditationSlot];
            } else {
                return [];
            }
        }),
        (s) => s.date!.toMillis() ?? 0
    )
        .map((s) => s.name)
        .join(', ');
    const petitDejeunerGaroTriSlots = sortBy(
        vr.accreditationsUsersInfos.flatMap((aui) => {
            if (
                aui.accreditation.id === PETIT_DEJEUNER_GAROTRI_ID &&
                aui.accreditationSlot.date?.isValid
            ) {
                return [aui.accreditationSlot];
            } else {
                return [];
            }
        }),
        (s) => s.date!.toMillis() ?? 0
    )
        .map((s) => s.name)
        .join(', ');
    const panierRepasSlots = sortBy(
        vr.accreditationsUsersInfos.flatMap((aui) => {
            if (aui.accreditation.id === PANIER_REPAS_ID && aui.accreditationSlot.date?.isValid) {
                return [aui.accreditationSlot];
            } else {
                return [];
            }
        }),
        (s) => s.date!.toMillis() ?? 0
    )
        .map((s) => s.name)
        .join(', ');
    const hasSomme = vr.accreditationsUsersInfos.some(
        (aui) => aui.accreditation.id === SOMME_100_ID || aui.accreditation.id === SOMME_150_ID
    );
    const cateringTechniqueSlots = sortBy(
        vr.accreditationsUsersInfos.flatMap((aui) => {
            if (
                aui.accreditation.id === CATERING_TECHNIQUE_ID &&
                aui.accreditationSlot.date?.isValid
            ) {
                return [aui.accreditationSlot];
            } else {
                return [];
            }
        }),
        (s) => s.date!.toMillis() ?? 0
    )
        .map((s) => s.name)
        .join(', ');
    const snackSlots = sortBy(
        vr.accreditationsUsersInfos.flatMap((aui) => {
            if (aui.accreditation.id === SNACK_ID && aui.accreditationSlot.date?.isValid) {
                return [aui.accreditationSlot];
            } else {
                return [];
            }
        }),
        (s) => s.date!.toMillis() ?? 0
    )
        .map((s) => s.name)
        .join(', ');
    const hasTicketRepas = vr.accreditationsUsersInfos.some(
        (aui) => aui.accreditation.id === TICKET_REPAS_ID
    );

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/garorock/planning.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$inter',
                position: 'relative'
            }}
        >
            <Flex
                direction="column"
                gap="5"
                css={{
                    position: 'absolute',
                    px: '96px',
                    top: '240px'
                }}
            >
                {hasPlanning && (
                    <>
                        <Flex direction="column" gap="4">
                            {byDates.map((psuis, index) => {
                                const first = psuis[0];
                                const byMission = sortBy(
                                    Object.values(groupBy(psuis, (psui) => psui.position.id)),
                                    (psuis) =>
                                        minBy(psuis, (psui) =>
                                            psui.positionSlot.range.start!.toMillis()
                                        )!.positionSlot.range.start!.toMillis()
                                );

                                return (
                                    <Flex key={index} direction="column">
                                        <Box
                                            css={{
                                                color: '#faf5dc',
                                                fontSize: '16px',
                                                fontWeight: '700',
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            {first.positionSlot.range.start!.toFormat(
                                                'cccc dd MMMM',
                                                {
                                                    locale: 'fr'
                                                }
                                            )}
                                        </Box>

                                        {byMission.map((slots, slotIndex) => {
                                            const firstSlot = slots[0];

                                            return (
                                                <Box
                                                    key={slotIndex}
                                                    css={{
                                                        color: '#faf5dc',
                                                        fontSize: '16px'
                                                    }}
                                                >
                                                    {firstSlot.position.name} :{' '}
                                                    {slotsToString(
                                                        slots.map(
                                                            ({ positionSlot }) => positionSlot.range
                                                        )
                                                    )}
                                                </Box>
                                            );
                                        })}
                                    </Flex>
                                );
                            })}
                        </Flex>

                        <Flex justify="center" width={602}>
                            <Box height={4} width={332}>
                                <img
                                    src="https://assets.recrewteer.com/badges/garorock/dot.png"
                                    height="100%"
                                    width="100%"
                                />
                            </Box>
                        </Flex>
                    </>
                )}

                <Flex
                    direction="column"
                    gap="5"
                    css={{
                        color: '#faf5dc',
                        fontSize: '16px'
                    }}
                >
                    {isNonEmptyString(petitDejeunerSlots) && (
                        <Flex direction="column">
                            <Box>
                                Un petit déjeuner au catering bénévole est prévu pour toi le(s) :
                            </Box>

                            <Box fontWeight="medium">{petitDejeunerSlots}</Box>
                        </Flex>
                    )}

                    {isNonEmptyString(petitDejeunerGaroTriSlots) && (
                        <Flex direction="column">
                            <Box>Un petit déjeuner au Garotri est prévu pour toi le(s) :</Box>

                            <Box fontWeight="medium">{petitDejeunerGaroTriSlots}</Box>
                        </Flex>
                    )}

                    {isNonEmptyString(panierRepasSlots) && (
                        <Flex direction="column">
                            <Box>Un panier repas est prévu pour toi le(s) :</Box>

                            <Box fontWeight="medium">{panierRepasSlots}</Box>
                        </Flex>
                    )}

                    {hasSomme && (
                        <Box>
                            Une somme d’argent te sera crédité sur ta carte pour tes jours de
                            présence
                        </Box>
                    )}

                    {isNonEmptyString(cateringTechniqueSlots) && (
                        <Flex direction="column">
                            <Box>Un repas au catering technique est prévu pour toi le(s) :</Box>

                            <Box fontWeight="medium">{cateringTechniqueSlots}</Box>
                        </Flex>
                    )}

                    {isNonEmptyString(snackSlots) && (
                        <Flex direction="column">
                            <Box>
                                Un repas au Garo snack d’une valeur de 6€ est prévu pour toi le(s) :
                            </Box>

                            <Box fontWeight="medium">{snackSlots}</Box>
                        </Flex>
                    )}

                    {hasTicketRepas && (
                        <Box>
                            Des tickets repas te seront fournis pour les utiliser sur tous les
                            stands du festival
                        </Box>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
