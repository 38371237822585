import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { ALL_EMAILSTATE, CampaignQuery, EmailState } from 'common/src/generated/types';
import { useHistory, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useCampaignEmailsQuery } from '../../generated/graphqlHooks';
import { UserOverlayOrganization } from '../../users/overlay/userOverlay';
import { UserPanel } from '../../users/show/userPanel';
import { CampaignKpi } from '../campaignKpi';
import { useCampaignContext } from './campaignContext';
import { CampaignEmailRow } from './campaignEmailRow';

interface ICampaignEmailProps {
    campaign: CampaignQuery['organization']['campaign'];
}

export const CampaignEmail = (props: ICampaignEmailProps) => {
    const translate = useTranslate();
    const { organizationId, campaignId } = useParams();
    const history = useHistory();
    const {
        campaignDashboardPath,
        campaignDashboardUserRoutePath,
        isOrganizationCampaign,
        getCampaignDashboardUserPath
    } = useCampaignContext();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [state, setState] = React.useState<'' | EmailState>('');
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading, reload } = useCampaignEmailsQuery({
        organizationId,
        campaignId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        state: state !== '' ? state : undefined,
        offset
    });
    const enumToOptions = useEnumToOptions();

    return (
        <>
            <Flex gap="6">
                <CampaignKpi
                    title={translate('nombre_de_desti_21374')}
                    content={props.campaign.numberOfRecipients}
                />

                <CampaignKpi
                    title={translate('nombre_d_ouvert_84187')}
                    content={props.campaign.numberOfOpen ?? 0}
                />

                <CampaignKpi
                    title={translate('nombre_de_clics_64232')}
                    content={props.campaign.numberOfClick ?? 0}
                />
            </Flex>

            <Spacer height="7" />

            <TableFilters
                filters={
                    <>
                        <Box width={200}>
                            <Select
                                value={state}
                                onChange={(newState: '' | EmailState) => {
                                    setState(newState);
                                    setOffset(0);
                                }}
                            >
                                <option value="">{translate('tous_les_status_67644')}</option>

                                {enumToOptions(
                                    ALL_EMAILSTATE.filter((s) => s !== EmailState.Queued)
                                )}
                            </Select>
                        </Box>

                        <Box width={320}>
                            <TextInput
                                icon="magnifying-glass"
                                placeholder={translate('rechercher_un_d_02329')}
                                value={name}
                                onChange={(newName: string) => {
                                    setName(newName);
                                    setOffset(0);
                                }}
                            />
                        </Box>
                    </>
                }
                headerCells={
                    <>
                        <HeaderCell>{translate('nom_du_membre_69353')}</HeaderCell>
                        <HeaderCell>{translate('date_d_envoi_74668')}</HeaderCell>
                        <HeaderCell>{translate('status_06428')}</HeaderCell>
                    </>
                }
                numberOfPages={data.organization?.campaign.emails.numberOfPages ?? 0}
                offset={offset}
                rows={
                    isLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : (
                        <>
                            {data.organization.campaign.emails.nodes.map((email, index) => <CampaignEmailRow key={index} email={email} />)}
                        </>
                    )
                }
                title={translate('liste_des_desti_16448')}
                totalCount={data.organization?.campaign.emails.totalCount ?? 0}
                setOffset={setOffset}
            />

            <Switch>
                <Route
                    path={campaignDashboardUserRoutePath}
                    children={
                        isOrganizationCampaign ? (
                            <UserOverlayOrganization
                                getBasePath={getCampaignDashboardUserPath}
                                onClose={() => {
                                    history.push(campaignDashboardPath);
                                }}
                                onDelete={() => {
                                    history.push(campaignDashboardPath);

                                    reload();
                                }}
                            />
                        ) : (
                            <RightPanel
                                size="md"
                                onClose={() => {
                                    history.push(campaignDashboardPath);
                                }}
                            >
                                <RightPanelBody>
                                    <UserPanel
                                        baseRoutePath={campaignDashboardUserRoutePath}
                                        getBasePath={getCampaignDashboardUserPath}
                                        onDelete={() => {
                                            history.push(campaignDashboardPath);

                                            reload();
                                        }}
                                        onUpdateState={reload}
                                    />
                                </RightPanelBody>
                            </RightPanel>
                        )
                    }
                />
            </Switch>
        </>
    );
};
