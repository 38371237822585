import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Span } from 'common/src/designSystem/components/span';
import { EventId, FormId, FormListFragment, OrganizationId } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { useFormDeleteMutation, useFormDuplicateMutation } from '../../generated/graphqlHooks';
import { FormDuplicateOtherEventModal } from './formDuplicateOtherEventModal';

interface IFormRowProps {
    canDuplicate: boolean;
    eventId: Emptyable<EventId>;
    form: FormListFragment;
    organizationId: OrganizationId;

    getFormPath(formId: FormId): string;
    reload(): void;
}

export const FormRow = (props: IFormRowProps) => {
    const { history, translate } = useHeavent();
    const { mutate: formDuplicate } = useFormDuplicateMutation();
    const { mutate: formDelete } = useFormDeleteMutation();
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = React.useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <>
            <Flex
                align="center"
                gap="6"
                width={1}
                css={{
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    boxShadow: '$xs',
                    cursor: 'pointer',
                    padding: '$4 $6'
                }}
                onClick={() => {
                    history.push(props.getFormPath(props.form.id));
                }}
            >
                <Box color="gray800" fontWeight="semiBold" css={{ flex: '1' }}>
                    {props.form.name}
                </Box>

                <Box color="gray500">
                    {translate('nombre_de_champ_91879')}{' '}
                    <Span color="gray800">{props.form.numberOfCustomFields}</Span>
                </Box>

                <Dropdown>
                    <Trigger>
                        <Button color="white" leftIcon="ellipsis-vertical" size="sm" />
                    </Trigger>

                    <Menu placement="bottom-end">
                        {props.canDuplicate && (
                            <ItemIcon
                                icon="clone"
                                onClick={async (e) => {
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.stopPropagation();

                                    await formDuplicate({
                                        organizationId: props.organizationId,
                                        eventId: props.eventId,
                                        formId: props.form.id
                                    });

                                    props.reload();
                                }}
                            >
                                {translate('dupliquer_28256')}
                            </ItemIcon>
                        )}

                        {props.eventId && props.canDuplicate && (
                            <ItemIcon
                                icon="clone"
                                onClick={() => {
                                    setIsDuplicateModalOpen(true);
                                }}
                            >
                                {translate('dupliquer_sur_u_11076')}
                            </ItemIcon>
                        )}

                        <ItemIcon
                            icon="trash-can"
                            color="red"
                            onClick={(e) => {
                                e.nativeEvent.stopImmediatePropagation();
                                e.stopPropagation();

                                setIsDeleteOpen(true);
                            }}
                        >
                            {translate('supprimer_43083')}
                        </ItemIcon>
                    </Menu>
                </Dropdown>
            </Flex>

            {isDuplicateModalOpen && (
                <FormDuplicateOtherEventModal
                    eventId={props.eventId!}
                    organizationId={props.organizationId}
                    formId={props.form.id}
                    onClose={() => {
                        setIsDuplicateModalOpen(false);
                    }}
                />
            )}

            {isDeleteOpen && (
                <DeleteModal
                    text={translate('suppression_de_73669', props.form.name)}
                    subtext={translate('_tes_vous_s_r_d_75358')}
                    buttonText={translate('supprimer_43083')}
                    onDelete={async () => {
                        await formDelete({
                            organizationId: props.organizationId,
                            eventId: props.eventId,
                            formId: props.form.id
                        });

                        props.reload();
                    }}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                />
            )}
        </>
    );
};
