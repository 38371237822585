import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Accept } from '../../util/accept';
import { FileInput, FileInputValue } from '../components/file/fileInput';
import { FileS3Input } from '../components/file/fileS3Input';
import { FileS3InputValue } from '../components/file/fileS3InputValue';

export const Files = () => {
    const translate = useTranslate();
    const [image, setImage] = React.useState<FileInputValue | undefined>(undefined);
    const [file, setFile] = React.useState<FileS3InputValue | null>(null);

    return (
        <Flex direction="column" gap="6" width={1}>
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Files
            </Box>

            <Flex gap="3" width={1}>
                <FileInput
                    accept={Accept.Images}
                    format="base64"
                    label={translate('choisir_une_ima_74768')}
                    value={image}
                    onChange={setImage}
                />

                {image && <img src={image.content} height="100px" />}
            </Flex>

            <Flex width={1}>
                <FileS3Input accept={Accept.Images} acl="private" value={file} onChange={setFile} />
            </Flex>
        </Flex>
    );
};
