import { Item } from 'common-front/src/designSystem/components/topMenu/item';
import { TopMenu } from 'common-front/src/designSystem/components/topMenu/topMenu';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useFormContext } from './formContext';

export const FormMenu = () => {
    const translate = useTranslate();
    const { basePath } = useFormContext();

    return (
        <TopMenu height={1} width={1}>
            <Item path={`${basePath}/elements`}>{translate('champs_91671')}</Item>

            <Item
                path={`${basePath}/parameters`}
                isActive={(pathname) => pathname.match(/\/form\/\d+\/parameters/) !== null}
            >
                {translate('param_tres_78420')}
            </Item>

            <Item path={`${basePath}/integration`}>{translate('int_gration_10662')}</Item>

            <Item path={`${basePath}/emails`}>{translate('mod_les_e_mail_91257')}</Item>

            <Item path={`${basePath}/preview`}>{translate('aper_u_25595')}</Item>
        </TopMenu>
    );
};
