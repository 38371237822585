import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    EventId,
    OrganizationId,
    PositionId,
    PositionsPositionFragment,
    PositionState,
    SegmentCustomFieldFragment,
    SegmentId
} from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { PositionsAcronymCell } from './cells/positionsAcronymCell';
import { PositionsCategoryCell } from './cells/positionsCategoryCell';
import { PositionsColorCell } from './cells/positionsColorCell';
import { PositionsIconCell } from './cells/positionsIconCell';
import { PositionsNameCell } from './cells/positionsNameCell';
import { PositionsTagsCell } from './cells/positionsTagsCell';
import { PositionRowDropdown } from './positionRowDropdown';
import { PositionRowResources } from './positionRowResources';

interface IPositionRowProps {
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    eventId: EventId;
    organizationId: OrganizationId;
    position: PositionsPositionFragment;
    segmentId: SegmentId;
    state: ICheckboxState;

    reload(): void;
    reloadPosition(id: PositionId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const PositionRow = (props: IPositionRowProps) => {
    const { history, translate } = useHeavent();
    const {
        event: {
            permissions: { isEventAdmin }
        },
        isPositionCategoryAdmin
    } = useEventContext();
    const intervalService = useService(IntervalService);
    const fieldService = useService(FieldService);
    const slugToCustomField = React.useMemo(() => new Map(props.customFields.map((cf) => [cf.slug, cf])), [props.customFields]);
    const reloadPosition = React.useCallback(() => {
        props.reloadPosition(props.position.id);
    }, [props.position, props.reloadPosition]);

    return (
        <Row
            css={{
                '& .h-preview': {
                    display: 'none'
                },
                '&:hover': {
                    background: '$gray100'
                },
                '&:hover .h-preview': {
                    display: 'flex'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            {isEventAdmin && (
                <Cell width={48} css={{ paddingRight: 0 }}>
                    <Checkbox
                        state={props.state}
                        onClick={(newState, e) => {
                            e.nativeEvent.stopImmediatePropagation();
                            e.stopPropagation();

                            props.toggleRow(`p${props.position.id}`, newState);
                        }}
                    />
                </Cell>
            )}

            {props.columns.map((field, index) => {
                const firstCellCss = index === 0 && isEventAdmin ? { paddingLeft: '$3' } : {};
                const customField = slugToCustomField.get(field.slug);

                return field.slug === 'category' ? (
                    <PositionsCategoryCell
                        key={field.slug}
                        firstCellCss={firstCellCss}
                        position={props.position}
                        reload={reloadPosition}
                    />
                ) : field.slug === 'name' ? (
                    <PositionsNameCell
                        key={field.slug}
                        firstCellCss={firstCellCss}
                        position={props.position}
                        reload={reloadPosition}
                    />
                ) : field.slug === 'slot' ? (
                    <Cell key={field.slug} css={firstCellCss}>
                        {intervalService.toDisplayString(props.position.range)}
                    </Cell>
                ) : field.slug === 'resources' ? (
                    <Cell key={field.slug} width={256} css={firstCellCss}>
                        <PositionRowResources
                            assignedResources={props.position.assignedResources}
                            resources={props.position.resources}
                        />
                    </Cell>
                ) : field.slug === 'tags' ? (
                    <PositionsTagsCell
                        key={field.slug}
                        firstCellCss={firstCellCss}
                        position={props.position}
                        reload={reloadPosition}
                    />
                ) : field.slug === 'address' ? (
                    <Cell key={field.slug} css={firstCellCss}>
                        {props.position.address}
                    </Cell>
                ) : field.slug === 'latitude' ? (
                    <Cell key={field.slug} css={firstCellCss}>
                        {props.position.latitude}
                    </Cell>
                ) : field.slug === 'longitude' ? (
                    <Cell key={field.slug} css={firstCellCss}>
                        {props.position.longitude}
                    </Cell>
                ) : field.slug === 'isVisible' ? (
                    <Cell key={field.slug} css={firstCellCss}>
                        <Flex align="center" gap="3" width={1}>
                            {props.position.isVisible ? (
                                <>
                                    <Box>
                                        <I icon="square-check" />
                                    </Box>

                                    <Box>{translate('visible_27909')}</Box>
                                </>
                            ) : (
                                <>
                                    <Box>
                                        <I icon="square" />
                                    </Box>

                                    <Box>{translate('non_visible_30040')}</Box>
                                </>
                            )}
                        </Flex>
                    </Cell>
                ) : field.slug === 'acronym' ? (
                    <PositionsAcronymCell
                        key={field.slug}
                        firstCellCss={firstCellCss}
                        position={props.position}
                        reload={reloadPosition}
                    />
                ) : field.slug === 'color' ? (
                    <PositionsColorCell
                        key={field.slug}
                        firstCellCss={firstCellCss}
                        position={props.position}
                        reload={reloadPosition}
                    />
                ) : field.slug === 'icon' ? (
                    <PositionsIconCell
                        key={field.slug}
                        firstCellCss={firstCellCss}
                        position={props.position}
                        reload={reloadPosition}
                    />
                ) : field.slug === 'state' ? (
                    <Cell key={field.slug} css={firstCellCss}>
                        {props.position.state === PositionState.Full ? (
                            <Badge color="success">{translate(props.position.state)}</Badge>
                        ) : (
                            <Badge color="warning">{translate(props.position.state)}</Badge>
                        )}
                    </Cell>
                ) : field.slug === 'fillingRate' ? (
                    <Cell key={field.slug} css={firstCellCss}>
                        {props.position.fillingRate}%
                    </Cell>
                ) : (
                    <Cell key={field.slug} css={firstCellCss}>
                        {customField
                            ? fieldService.getValueString(customField, props.position, {
                                  fileReturnValue: 'name'
                              })
                            : ''}
                    </Cell>
                );
            })}

            {isPositionCategoryAdmin() && (
                <CellControls justify="center">
                    {isPositionCategoryAdmin(props.position.positionCategoryId) && (
                        <>
                            <Tooltip>
                                <Trigger>
                                    <Box>
                                        <I
                                            icon="pen"
                                            onClick={() => {
                                                history.push(
                                                    HeaventPaths.EDIT_POSITION(
                                                        props.organizationId,
                                                        props.eventId,
                                                        props.position.id
                                                    )
                                                );
                                            }}
                                        />
                                    </Box>
                                </Trigger>

                                <Content placement="top">{translate('_diter_62574')}</Content>
                            </Tooltip>

                            <PositionRowDropdown
                                position={props.position}
                                organizationId={props.organizationId}
                                eventId={props.eventId}
                                reload={props.reload}
                            />
                        </>
                    )}
                </CellControls>
            )}
        </Row>
    );
};
