import { groupBy, maxBy, minBy, sortBy } from 'lodash-es';
import { Interval } from 'luxon';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';

export const slotsToString = (ranges: Interval[]) => sortBy(ranges, (r) => r.start!.toMillis())
        .map((range) => `${range.start!.toFormat('H:mm')}-${range.end!.toFormat('H:mm')}`)
        .join(' | ');

interface IPlanningAmbertProps {
    event: DocumentUserCustomBadgeFragment;
}

export const PlanningAmbert = (props: IPlanningAmbertProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const minDate = minBy(vr.positionsSlotsUsersInfos, (psui) =>
        psui.positionSlot.range.start!.toMillis()
    )!.positionSlot.range.start!;
    const maxDate = maxBy(vr.positionsSlotsUsersInfos, (psui) =>
        psui.positionSlot.range.end!.toMillis()
    )!.positionSlot.range.end!;
    const byDates = sortBy(
        Object.values(
            groupBy(vr.positionsSlotsUsersInfos, (psui) =>
                psui.positionSlot.range.start!.startOf('day').toMillis()
            )
        ),
        (psuis) => psuis[0].positionSlot.range.start!.startOf('day')
    );

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/ambert/masque_planning_v2.png) no-repeat`,
                backgroundSize: 'cover',
                color: '#00414e',
                fontFamily: '$futura',
                fontWeight: '500',
                pageBreakBefore: 'always',
                position: 'relative',
                px: '70px'
            }}
        >
            <Flex
                direction="column"
                gap="7"
                css={{
                    position: 'absolute',
                    top: '378px'
                }}
            >
                <Flex direction="column" gap="2">
                    <Box
                        css={{
                            color: '#668245',
                            fontSize: '24px',
                            fontWeight: '700'
                        }}
                    >
                        Affectation {ui.name}
                    </Box>

                    <Box fontSize="textMd">
                        Période : du {minDate.toFormat('cccc dd MMMM', { locale: 'fr' })} au{' '}
                        {maxDate.toFormat('cccc dd MMMM yyyy', { locale: 'fr' })}
                    </Box>
                </Flex>

                <Flex direction="column" gap="3">
                    {byDates.map((psuis, index) => {
                        const first = psuis[0];
                        const byMission = sortBy(
                            Object.values(groupBy(psuis, (psui) => psui.position.id)),
                            (psuis) =>
                                minBy(psuis, (psui) =>
                                    psui.positionSlot.range.start!.toMillis()
                                )!.positionSlot.range.start!.toMillis()
                        );

                        return (
                            <Flex key={index} direction="column">
                                <Box
                                    css={{
                                        fontWeight: '700',
                                        textTransform: 'capitalize'
                                    }}
                                >
                                    {first.positionSlot.range.start!.toFormat('cccc dd MMMM', {
                                        locale: 'fr'
                                    })}
                                </Box>

                                {byMission.map((slots, slotIndex) => {
                                    const firstSlot = slots[0];

                                    return (
                                        <Box key={slotIndex}>
                                            {firstSlot.position.name} :{' '}
                                            {slotsToString(
                                                slots.map(({ positionSlot }) => positionSlot.range)
                                            )}
                                        </Box>
                                    );
                                })}
                            </Flex>
                        );
                    })}
                </Flex>
            </Flex>
        </Flex>
    );
};
