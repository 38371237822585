import { Button } from 'common-front/src/designSystem/components/button';
import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { Label } from 'common-front/src/designSystem/components/input/label';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { TextInput as TextInputForm } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { Table } from 'common/src/designSystem/components/table/table';
import { AccreditationId, AccreditationsSlotId, Maybe } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isFuzzyMatch } from 'common/src/util/string';
import { fullName } from 'common/src/vo/accreditationSlot';
import { findIndex } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';

type AccreditationSlot = {
    id: AccreditationsSlotId;
    name: string;
    date?: Maybe<DateTime>;
    isDisabled?: boolean;
};

type Accreditation = {
    id: AccreditationId;
    categoryName: string;
    hiddenSlotId?: AccreditationsSlotId;
    isDefault: boolean;
    name: string;
    slots: AccreditationSlot[];
    total?: number | null;
};

interface IAccreditationsTableProps {
    accreditations: Accreditation[];
    values: ICreateUpdateDelegationValues['delegation']['accreditations'];

    change(name: string, value: any): void;
    setIsAddAccreditationOpen(_: boolean): void;
}

export const AccreditationsTable = ({
    accreditations,
    setIsAddAccreditationOpen,
    change,
    values
}: IAccreditationsTableProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);

    const [limit, setLimit] = React.useState(25);
    const [offset, setOffset] = React.useState(0);
    const [searchText, setSearchText] = React.useState('');

    const slots = React.useMemo(
        () => accreditations.flatMap((accreditation) => accreditation.slots),
        [accreditations]
    );
    const filteredValues = React.useMemo(
        () =>
            values
                .filter((ad) => {
                    const accreditation = accreditations.find(
                        (accreditation) => accreditation.id == ad.accreditationId
                    );
                    return (
                        accreditation &&
                        (isFuzzyMatch(accreditation.name, searchText) ||
                            isFuzzyMatch(accreditation.name, searchText))
                    );
                })
                .slice(offset, offset + limit),
        [values, offset, limit, searchText]
    );
    const accreditationIdToAccreditation = React.useMemo(() => Object.fromEntries(accreditations.map((a) => [a.id, a])), [accreditations]);
    const slotIdToSlot = React.useMemo(() => Object.fromEntries(slots.map((s) => [s.id, s])), [slots]);

    return (
        <Table>
            <Row css={{ padding: '$4', borderTop: '1px solid $gray200', borderBottom: 'none' }}>
                <Flex width={320}>
                    <TextInput
                        value={searchText}
                        onChange={(newName: string) => {
                            setSearchText(newName);
                        }}
                        placeholder={translate('rechercher_une_65646')}
                        icon="magnifying-glass"
                    />
                </Flex>

                <Box css={{ flex: '1' }} />

                <Button
                    leftIcon="plus"
                    onClick={() => {
                        setIsAddAccreditationOpen(true);
                    }}
                >
                    {translate('add_accreditation')}
                </Button>
            </Row>

            <HeaderRow>
                <HeaderCell>{translate('nom_de_l_accr_d_11908')}</HeaderCell>
                <HeaderCell>{translate('Category')}</HeaderCell>
                <HeaderCell width={100}>Visibilité</HeaderCell>
                <HeaderCell>{translate('type_35427')}</HeaderCell>
                <HeaderCell width={130}>{translate('total_07173')}</HeaderCell>
            </HeaderRow>

            {filteredValues.map((ad) => {
                const accreditation = accreditationIdToAccreditation[ad.accreditationId];
                const slot = slotIdToSlot[ad.accreditationSlotId];
                const index = findIndex(
                    values,
                    (val) => val.accreditationSlotId === ad.accreditationSlotId
                )!;
                const prefix = `delegation.accreditations[${index}]`;

                return (
                    <Row key={ad.accreditationSlotId}>
                        <Cell>
                            <Flex direction="column">
                                <Box>{accreditation.name}</Box>
                                {isNonEmptyArray(accreditation.slots) && (
                                    <Box color="gray500">
                                        {fullName(dateTimeService, slot!, accreditation.name)}
                                    </Box>
                                )}
                            </Flex>
                        </Cell>
                        <Cell>{accreditation.categoryName}</Cell>
                        <Cell width={100}>
                            <Button
                                color="white"
                                disabled={!ad.isDefault || slot?.isDisabled}
                                leftIcon={ad.isVisible ? 'eye' : 'eye-slash'}
                                onClick={() => {
                                    change(`${prefix}.isVisible`, !ad.isVisible);
                                }}
                            />
                        </Cell>
                        <Cell>
                            <CheckboxText
                                disabled={slot?.isDisabled}
                                state={ad.isDefault ? 'checked' : 'unchecked'}
                                onClick={(state) => {
                                    change(`${prefix}.isDefault`, state === 'checked');

                                    if (state === 'unchecked') {
                                        change(`${prefix}.isVisible`, true);
                                    }
                                }}
                            >
                                <Label>{translate('default_accreditation')}</Label>
                            </CheckboxText>
                        </Cell>
                        <Cell width={130}>
                            <TextInputForm
                                name={`${prefix}.maxResources`}
                                type="number"
                                min={0}
                                parseInt={true}
                            />
                        </Cell>
                    </Row>
                );
            })}

            <PaginationRow css={{ borderTop: 'none' }}>
                <PaginationCell
                    limit={limit}
                    numberOfPages={accreditations.length / limit}
                    offset={offset}
                    showLimits={true}
                    totalCount={accreditations.length}
                    setLimit={setLimit}
                    setOffset={setOffset}
                />
            </PaginationRow>
        </Table>
    );
};
