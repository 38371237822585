import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FieldType, OrganizationId } from 'common/src/generated/types';
import { IMassConditionsValues } from 'common/src/input/massConditionsInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useMassConditionsCustomFieldsQuery } from '../../generated/graphqlHooks';

interface IMassConditionsStep2Props {
    organizationId: OrganizationId;
    values: IMassConditionsValues;

    change(field: string, value: any): void;
}

export const MassConditionsStep2 = (props: IMassConditionsStep2Props) => {
    const translate = useTranslate();
    const { data, loader } = useMassConditionsCustomFieldsQuery({
        organizationId: props.organizationId
    });
    const selectedCustomField = React.useMemo(() => {
        if (data.organization && props.values.massConditions.customFieldId) {
            return data.organization.customFields.nodes.find(
                (cf) => cf.id === props.values.massConditions.customFieldId
            );
        } else {
            return undefined;
        }
    }, [data.organization, props.values.massConditions.customFieldId]);

    React.useEffect(() => {
        if (selectedCustomField?.fieldType === FieldType.Checkbox) {
            props.change('massConditions.value', true);
        } else if (selectedCustomField?.fieldType === FieldType.Select) {
            props.change('massConditions.value', []);
        }
    }, [selectedCustomField]);

    return (
        loader || (
            <>
                <Select
                    label={translate('CustomField')}
                    name="massConditions.customFieldId"
                    parseInt={true}
                >
                    <option value={-1}>{translate('s_lectionner_un_01451')}</option>
                    {data.organization.customFields.nodes.map((customField) => (
                            <option key={customField.id} value={customField.id}>
                                {customField.name}
                            </option>
                        ))}
                </Select>

                {selectedCustomField?.fieldType === FieldType.Select && (
                    <>
                        <Spacer height="6" />

                        <RichSelect
                            isSearchVisible={true}
                            multiple={true}
                            label={translate('mission_accessi_77261')}
                            name="massConditions.value"
                        >
                            {selectedCustomField.values.map(({ id, value }) => (
                                    <option key={id} value={id}>
                                        {value}
                                    </option>
                                ))}
                        </RichSelect>
                    </>
                )}

                {selectedCustomField?.fieldType === FieldType.Checkbox && (
                    <>
                        <Spacer height="6" />

                        <Select
                            label={translate('mission_accessi_77261')}
                            name="massConditions.value"
                            parseBoolean={true}
                        >
                            <option value="true">{translate('true')}</option>
                            <option value="false">{translate('false')}</option>
                        </Select>
                    </>
                )}
            </>
        )
    );
};
