import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate, Operator, Predicate } from 'common/src/vo/segment';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { RadioText } from '../../designSystem/components/radio';
import { RichSelect } from '../../designSystem/components/richSelect/richSelect';

interface IAddConditionFilterProps {
    filterPredicate: FilterPredicate;

    onAdd(predicate: Predicate): void;
}

export const AddConditionFilter = (props: IAddConditionFilterProps) => {
    const translate = useTranslate();
    const [operator, setOperator] = React.useState<Operator>(
        props.filterPredicate.predicate?.operator ?? 'is'
    );
    const values = props.filterPredicate.filter.values ?? [];
    const [value, setValue] = React.useState<any[]>(
        isNonEmptyArray(props.filterPredicate.predicate?.value)
            ? props.filterPredicate.predicate!.value
            : []
    );
    const valid = operator === 'is' || operator === 'isNot' || isNonEmptyArray(value);

    return (
        <>
            <RadioText
                state={operator === 'is' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('is');
                }}
            >
                {translate('champ_dont_un_a_44854')}
            </RadioText>

            <Spacer height="3" />

            <RadioText
                state={operator === 'isNot' ? 'checked' : 'unchecked'}
                onClick={() => {
                    setOperator('isNot');
                }}
            >
                {translate('champ_d_pendant_33676')}
            </RadioText>

            {values.length > 0 && (
                <>
                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'contains' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('contains');
                        }}
                    >
                        {translate('d_pends_du_cham_39787')}
                    </RadioText>

                    {operator === 'contains' && (
                        <>
                            <Spacer height="1" />

                            <Flex>
                                <Spacer width="6" />
                                <RichSelect
                                    isSearchVisible={true}
                                    isSelectAllVisible={true}
                                    multiple={true}
                                    values={value}
                                    onChange={setValue}
                                    placeholder={translate('s_lectionner_de_52380')}
                                >
                                    {values.map(({ id, name }) => (
                                            <option value={id} key={id}>
                                                {name}
                                            </option>
                                        ))}
                                </RichSelect>
                            </Flex>

                            <Spacer height="1" />
                        </>
                    )}
                </>
            )}

            <Spacer height="7" />

            <Flex>
                <Button
                    disabled={!valid}
                    onClick={() => {
                        props.onAdd({
                            operator,
                            value,
                            slug: props.filterPredicate.filter.slug
                        });
                    }}
                >
                    {translate('ajouter_le_filt_54222')}
                </Button>
            </Flex>
        </>
    );
};
