import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Delegation } from 'common/src/generated/types';
import * as React from 'react';
import { useDelegationDeleteMutation } from '../../generated/graphqlHooks';

interface IDelegationDeleteModalProps {
    delegation: Pick<Delegation, 'id' | 'name'>;

    onClose(): void;
    onSuccess(): void;
}

export const DelegationDeleteModal = (props: IDelegationDeleteModalProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { mutate } = useDelegationDeleteMutation();

    return (
        <DeleteModal
            text={translate('suppression_de_73669', props.delegation.name)}
            subtext={translate('_tes_vous_s_r_d_66412')}
            buttonText={translate('supprimer_43083')}
            onDelete={async () => {
                await mutate({ organizationId, eventId, delegationId: props.delegation.id });

                props.onSuccess();
            }}
            onClose={props.onClose}
        />
    );
};
