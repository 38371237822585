import { Form } from 'common-front/src/components/form/form';
import { Submit } from 'common-front/src/components/reactFinalForm/input/submit';
import { Button } from 'common-front/src/designSystem/components/button';
import { PasswordInput } from 'common-front/src/designSystem/form/passwordInput';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Images } from 'common-front/src/util/assets';
import { authenticateUser } from 'common-front/src/util/aws/cognito';
import { LocalStorageKeys } from 'common-front/src/util/localStorage';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { AuthInputService, ISignInPasswordValues } from 'common/src/input/authInput';
import { ValidateService } from 'common/src/services/validateService';
import {
    useHistory,
    useLocalStorage,
    useService,
    useTranslate
} from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { AuthPage, authRoute } from './authPage';
import { Link } from './link';

interface ISignInEmailProps {
    onSuccess(token: string): void;
}

export const SignInEmail = (props: ISignInEmailProps) => {
    const translate = useTranslate();
    const authInput = useService(AuthInputService);
    const validateService = useService(ValidateService);
    const history = useHistory();
    const localStorage = useLocalStorage();

    return (
        <AuthPage
            link={
                <Link>
                    <Box color="gray500">{translate('pas_encore_sur_13386')}</Box>

                    <Box
                        color="primary700"
                        css={{ cursor: 'pointer' }}
                        onClick={() => {
                            history.push(HeaventPaths.AUTH_SIGN_UP_CREDENTIALS);
                        }}
                    >
                        {translate('ouvrez_un_compt_91337')}
                    </Box>
                </Link>
            }
        >
            <Form
                direction="column"
                width={1}
                initialValues={{
                    email: '',
                    password: ''
                }}
                validate={(values: ISignInPasswordValues) => validateService.validateForForm(authInput.signInPasswordSchema())(
                        values
                    )}
                onSubmit={async (values: ISignInPasswordValues) => {
                    try {
                        props.onSuccess(await authenticateUser(values.email, values.password));

                        return {};
                    } catch (e) {
                        if (
                            (e.code === 'NotAuthorizedException' &&
                                e.message === 'Incorrect username or password.') ||
                            (e.code === 'UserNotFoundException' &&
                                e.message === 'User does not exist.')
                        ) {
                            return {
                                email: translate('adresse_e_mail_73393')
                            };
                        } else {
                            throw e;
                        }
                    }
                }}
                render={({ handleSubmit, submitting, values }) => (
                        <>
                            <Box
                                color="gray900"
                                fontSize="textXl"
                                fontWeight="medium"
                                textAlign="center"
                            >
                                {translate('ravi_de_vous_re_24368')}
                            </Box>

                            <Spacer height="1" />

                            <Box color="gray500" textAlign="center">
                                {translate('identifiez_vous_57743')}
                            </Box>

                            <Spacer height="7" />

                            <TextInput
                                autoComplete="email"
                                label={translate('adresse_e_mail_60930')}
                                placeholder={translate('entrez_votre_ad_42451')}
                                name="email"
                                isEmail={true}
                            />

                            <Spacer height="4" />

                            <PasswordInput
                                label={translate('mot_de_passe_41738')}
                                placeholder={translate('entrez_votre_mo_51874')}
                                name="password"
                                onForgotPasswordClick={() => {
                                    localStorage.setItem(LocalStorageKeys.AUTH_EMAIL, values.email);

                                    history.push(HeaventPaths.AUTH_FORGOT_PASSWORD);
                                }}
                            />

                            <Submit />

                            <Spacer height="6" />

                            <Button
                                onClick={handleSubmit}
                                isLoading={submitting}
                                textAlign="center"
                            >
                                {translate('se_connecter_63820')}
                            </Button>

                            <Spacer height="6" />

                            <Flex align="center" gap="4">
                                <Box
                                    css={{
                                        borderTop: '1px solid $gray200',
                                        flex: '1',
                                        height: '1'
                                    }}
                                />

                                <Box color="gray500" fontSize="textXs">
                                    {translate('ou_67404')}
                                </Box>

                                <Box
                                    css={{
                                        borderTop: '1px solid $gray200',
                                        flex: '1',
                                        height: '1'
                                    }}
                                />
                            </Flex>

                            <Spacer height="6" />

                            <Flex
                                align="center"
                                justify="center"
                                gap="3"
                                css={{
                                    border: '1px solid $gray300',
                                    borderRadius: '$2',
                                    cursor: 'pointer',
                                    height: '40px'
                                }}
                                onClick={() => {
                                    localStorage.setItem(LocalStorageKeys.AUTH_EMAIL, values.email);

                                    history.push(HeaventPaths.AUTH_SIGN_IN_CODE);
                                }}
                            >
                                <Box color="gray500" fontSize="textMd">
                                    <I icon="envelope" />
                                </Box>

                                <Box color="gray700">{translate('se_connecter_av_59438')}</Box>
                            </Flex>

                            <Spacer height="4" />

                            <Flex
                                align="center"
                                justify="center"
                                gap="3"
                                css={{
                                    border: '1px solid $gray300',
                                    borderRadius: '$2',
                                    cursor: 'pointer',
                                    height: '40px'
                                }}
                                onClick={() => {
                                    window.location.href = authRoute('Google');
                                }}
                            >
                                <Box>
                                    <img src={Images.Google} height="24px" alt="Google logo" />
                                </Box>

                                <Box color="gray700">{translate('se_connecter_av_86208')}</Box>
                            </Flex>
                        </>
                    )}
            />
        </AuthPage>
    );
};
