import { Flex } from 'common/src/designSystem/components/flex';
import { DocumentsQuery, PositionId, UsersInfoId } from 'common/src/generated/types';
import * as React from 'react';
import { DocumentsEvent } from './documentsEvent';
import { DocumentsMembers } from './documentsMembers';
import { DocumentsPositions } from './documentsPositions';

interface IDocumentsProps {
    event: DocumentsQuery['event'];
}

export const Documents = (props: IDocumentsProps) => (
        <Flex direction="column" gap="7" css={{ paddingTop: '$7' }}>
            <DocumentsMembers
                assignedUserInfoId={
                    props.event.assignedVolunteersRegistrations.nodes[0]?.userInfoId ??
                    (-1 as UsersInfoId)
                }
                numberOfAssignedVolunteersRegistrations={
                    props.event.assignedVolunteersRegistrations.totalCount
                }
                numberOfVolunteersRegistrations={props.event.volunteersRegistrations.totalCount}
                userInfoId={
                    props.event.volunteersRegistrations.nodes[0]?.userInfoId ?? (-1 as UsersInfoId)
                }
            />

            <DocumentsPositions
                numberOfPositions={props.event.positions.totalCount}
                positionId={props.event.positions.nodes[0]?.id ?? (-1 as PositionId)}
            />

            <DocumentsEvent
                positionsSegmentId={props.event.positionsSegments[0].id}
                volunteersSegmentId={props.event.volunteersSegments[0].id}
            />
        </Flex>
    );
