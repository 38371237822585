import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    CampaignId,
    CampaignsQuery,
    CampaignState,
    EventId,
    OrganizationId
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import {
    useCampaignDeleteMutation,
    useCampaignDuplicateMutation,
    useCampaignSendMutation
} from '../../generated/graphqlHooks';

interface ICampaignRowDropdownProps {
    campaign: CampaignsQuery['event']['campaigns']['nodes'][0];
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;

    getCampaignEditPath(id: CampaignId): string;
    reload(): void;
}

export const CampaignRowDropdown = (props: ICampaignRowDropdownProps) => {
    const { history, translate } = useHeavent();
    const { mutate: campaignDuplicate } = useCampaignDuplicateMutation();
    const { mutate: campaignSend } = useCampaignSendMutation();
    const { mutate: campaignDelete } = useCampaignDeleteMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);
    const canEdit =
        props.campaign.state === CampaignState.Draft ||
        props.campaign.state === CampaignState.Scheduled;

    return (
        <>
            <DropdownEllipsis>
                {canEdit && (
                    <ItemIcon
                        icon="pen"
                        onClick={() => {
                            history.push(props.getCampaignEditPath(props.campaign.id));
                        }}
                    >
                        {translate('_diter_62574')}
                    </ItemIcon>
                )}

                {canEdit && (
                    <ItemIcon
                        icon="paper-plane"
                        onClick={async () => {
                            await campaignSend({
                                organizationId: props.organizationId,
                                eventId: props.eventId,
                                campaignId: props.campaign.id
                            });

                            props.reload();
                        }}
                    >
                        {translate('envoyer_26609')}
                    </ItemIcon>
                )}

                <ItemIcon
                    icon="clone"
                    onClick={async () => {
                        const {
                            campaignDuplicate: { id }
                        } = await campaignDuplicate({
                            organizationId: props.organizationId,
                            eventId: props.eventId,
                            campaignId: props.campaign.id
                        });

                        history.push(props.getCampaignEditPath(id));
                    }}
                >
                    {translate('dupliquer_28256')}
                </ItemIcon>

                <ItemIcon
                    icon="trash-can"
                    color="red"
                    onClick={(e) => {
                        closeOtherDropdowns(e.target);
                        setIsDeleteOpen(true);
                    }}
                >
                    {translate('supprimer_43083')}
                </ItemIcon>
            </DropdownEllipsis>

            {isDeleteOpen && (
                <DeleteModal
                    text={translate('suppression_de_73669', props.campaign.name)}
                    subtext={translate('_tes_vous_s_r_d_58075')}
                    buttonText={translate('supprimer_43083')}
                    onDelete={async () => {
                        await campaignDelete({
                            organizationId: props.organizationId,
                            eventId: props.eventId,
                            campaignId: props.campaign.id
                        });

                        props.reload();
                    }}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                />
            )}
        </>
    );
};
