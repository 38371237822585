import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { EventId, OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { Flex } from 'common/src/designSystem/components/flex';
import { Box } from 'common/src/designSystem/components/box';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { UserProfileHistoryCampaigns } from './userProfileHistoryCampaigns';
import { UserProfileHistoryEvents } from './userProfileHistoryEvents';

interface IUserProfileHistoryProps {
    basePath: string;
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;
}

export const UserProfileHistory = (props: IUserProfileHistoryProps) => {
    const { translate } = useHeavent();

    return (
        <>
            <Spacer height="7" />

            <Flex
                direction="column"
                css={{
                    background: 'white',
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    boxShadow: '$xs',
                    overflow: 'hidden'
                }}
            >
                <Flex align="center" css={{ padding: '$5 $6' }}>
                    <Flex direction="column">
                        <Box font="gray900 textMd semiBold">Historique</Box>
                    </Flex>
                </Flex>

                <Tabs>
                    <TabList css={{ px: '$6' }}>
                        <Tab path={`${props.basePath}/history/campaigns`}>
                            {translate('campagnes_31372')}
                        </Tab>
                        <Tab path={`${props.basePath}/history/events`}>
                            {translate('_v_nements_53672')}
                        </Tab>
                    </TabList>

                    <TabPanel path={`${props.basePath}/history/campaigns`}>
                        <UserProfileHistoryCampaigns
                            eventId={props.eventId}
                            organizationId={props.organizationId}
                            userInfoId={props.userInfoId}
                        />
                    </TabPanel>

                    <TabPanel path={`${props.basePath}/history/events`}>
                        <UserProfileHistoryEvents
                            eventId={props.eventId}
                            organizationId={props.organizationId}
                            userInfoId={props.userInfoId}
                        />
                    </TabPanel>
                </Tabs>
            </Flex>
        </>
    );
};
