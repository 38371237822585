import { CSS } from '@stitches/react';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IMetricCardProps {
    button?: React.ReactNode;
    css?: CSS;
    children: React.ReactNode;
}

export const MetricCard = (props: IMetricCardProps) => (
        <Flex
            direction="column"
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                flex: '1',
                ...(props.css as any)
            }}
        >
            <Flex
                direction="column"
                gap="2"
                css={{
                    flex: '1',
                    padding: '$6'
                }}
            >
                {props.children}
            </Flex>

            {props.button && (
                <Flex justify="end" css={{ borderTop: '1px solid $gray200', padding: '$4 $6' }}>
                    {props.button}
                </Flex>
            )}
        </Flex>
    );
