import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { styled } from 'common/src/designSystem/components/stitches';
import { AccreditationQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { displayConditionValue } from 'common/src/vo/positionCustomField';
import * as React from 'react';

const FieldName = styled('div', {
    color: '$gray800',
    fontSize: '$textSm',
    fontWeight: '$medium',
    '@tablet': {
        color: '$gray500',
        fontWeight: '$regular'
    }
});

const FieldValue = styled('div', {
    color: '$gray500',
    '@tablet': {
        color: '$gray800',
        fontWeight: '$medium'
    }
});

interface IAccreditationCustomFieldsProps {
    customFields: AccreditationQuery['event']['accreditation']['accreditationsCustomFields'];
}

export const AccreditationCustomFields = (props: IAccreditationCustomFieldsProps) => {
    const onMobile = useMobileQuery();
    const translate = useTranslate();

    return (
        <DetailBox title={translate('conditions_77756')}>
            {isNonEmptyArray(props.customFields) ? (
                props.customFields.map(({ customField, conditionValue }, index) => (
                        <React.Fragment key={index}>
                            {index !== 0 && (
                                <>
                                    <Spacer height="4" />
                                    {!onMobile && (
                                        <>
                                            <Separator direction="horizontal" />
                                            <Spacer height="4" />
                                        </>
                                    )}
                                </>
                            )}

                            <FieldName>{customField.name}</FieldName>

                            <Spacer height="1" />

                            <FieldValue>
                                {displayConditionValue(translate, customField, conditionValue)}
                            </FieldValue>
                        </React.Fragment>
                    ))
            ) : (
                <Box color="gray500" width={1} textAlign="center">
                    {translate('aucune_conditio_36491')}
                </Box>
            )}
        </DetailBox>
    );
};
