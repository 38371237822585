import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Item } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu as DropdownMenu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Images } from 'common-front/src/util/assets';
import { signOut } from 'common-front/src/util/aws/cognito';
import { Link } from 'common-front/src/util/link';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { theme } from 'common/src/designSystem/components/stitches';
import { CommonEnvVars, getAdminsEmails } from 'common/src/envVars';
import { HeaventEnv } from 'common/src/heaventEnv';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import Logo from 'svgComponents/logo/logo.svg';
import { useOrganizationsQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../organizationContext';
import { EventsMenu, getMenuItemStyles } from './eventsMenu';

const getLogo = () => {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return <img src={Images.AmicaleLogo} height="24px" alt="Logo" />;
        case HeaventEnv.Mso:
            return <img src={Images.MsoLogo2} height="24px" alt="Logo" />;
        case HeaventEnv.Recrewteer:
            return <Logo />;
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
};

const getStyles = () => {
    switch (CommonEnvVars.HEAVENT_ENV) {
        case HeaventEnv.Amicale:
            return { background: 'white', boxShadow: theme.shadows.sm.value };
        case HeaventEnv.Mso:
            return { background: theme.colors.cyan.value, boxShadow: theme.shadows.sm.value };
        case HeaventEnv.Recrewteer:
            return { background: theme.colors.blueGray900.value };
        default:
            return assertUnreachable(CommonEnvVars.HEAVENT_ENV);
    }
};

interface IMenuItemProps {
    link: string;
    children: React.ReactNode;

    isActive?(pathname: string): boolean;
    onClick?(): Promise<any>;
}

const MenuItem = (props: IMenuItemProps) => {
    const { pathname } = useLocation();
    const isActive = props.isActive ? props.isActive(pathname) : props.link === pathname;

    return (
        <Link
            to={props.link}
            onClick={async () => {
                if (props.onClick) {
                    await props.onClick();
                }
            }}
        >
            <Flex
                css={{
                    ...getMenuItemStyles(isActive),
                    cursor: 'pointer',
                    px: '$4',
                    blockSize: '100%',
                    alignItems: 'center'
                }}
            >
                {props.children}
            </Flex>
        </Link>
    );
};

export const OrganizationMenu = () => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const { organization, user, updateLocalOrganization } = useOrganizationContext();
    const { data } = useOrganizationsQuery({});
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const organizations = React.useMemo(() => sortBy(
            (data.organizations || []).filter(
                ({ permissions }) =>
                    permissions.isOrganizationAdmin ||
                    permissions.isOrganizationEventAdmin ||
                    permissions.isOrganizationEventLeader
            ),
            (o) => o.name
        ), [data.organizations]);

    return (
        <Flex
            align="center"
            justify="between"
            height={1}
            width={1}
            css={{
                ...getStyles(),
                color: 'white',
                cursor: 'pointer',
                position: 'relative',
                px: '$6'
            }}
        >
            <Flex
                align="center"
                height={1}
                width={300}
                css={{
                    '& svg': { height: '50%' }
                }}
                onClick={() => {
                    history.push(HeaventPaths.EVENTS(organizationId));
                }}
            >
                {getLogo()}
            </Flex>

            <nav style={{ blockSize: '100%', display: 'flex' }}>
                <Flex justify="center" align="center">
                    <EventsMenu />

                    {organization.permissions.isOrganizationAdmin && (
                        <MenuItem
                            link={HeaventPaths.CUSTOM_FIELDS(organizationId)}
                            isActive={(pathname) => pathname.startsWith(
                                    HeaventPaths.CUSTOM_FIELDS(organizationId)
                                )}
                            onClick={async () =>
                                await updateLocalOrganization({ customFieldsSegmentId: null })
                            }
                        >
                            {translate('champs_de_formu_64126')}
                        </MenuItem>
                    )}

                    {organization.permissions.isOrganizationAdmin && (
                        <MenuItem
                            link={CommunityPaths.COMMUNITY_DASHBOARD(organizationId)}
                            isActive={(pathname) => pathname.startsWith(
                                    CommunityPaths.COMMUNITY(organizationId)
                                )}
                        >
                            {translate('communaut_20896')}
                        </MenuItem>
                    )}

                    <MenuItem
                        link={HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(organizationId)}
                        isActive={(pathname) => pathname.startsWith(
                                HeaventPaths.ORGANIZATION_PARAMETERS(organizationId)
                            )}
                    >
                        {translate('param_tres_78420')}
                    </MenuItem>
                </Flex>
            </nav>

            <Dropdown isOpen={isDropdownOpen} onStateChange={setIsDropdownOpen}>
                <Trigger>
                    <Flex
                        align="center"
                        justify="end"
                        gap="3"
                        width={300}
                        css={{
                            color: getMenuItemStyles(false).color,
                            cursor: 'default'
                        }}
                    >
                        <Avatar
                            email={user.email}
                            name={user.userInfo.name}
                            image={user.userInfo.picture?.url}
                            size={32}
                        />

                        <Box
                            css={{
                                maxWidth: 'calc(100% - 66px)'
                            }}
                        >
                            <Box fontWeight="semiBold" css={{ ellipsis: true }}>
                                {user.userInfo.firstName || user.email}
                            </Box>
                            <Box fontSize="textXs" css={{ ellipsis: true }}>
                                {organization.name}
                            </Box>
                        </Box>

                        <div>
                            {isDropdownOpen ? (
                                <i className="far fa-angle-up" />
                            ) : (
                                <i className="far fa-angle-down" />
                            )}
                        </div>
                    </Flex>
                </Trigger>

                <DropdownMenu placement="bottom-end" width={300} maxHeight={500}>
                    <Item>
                        <Flex align="center" gap="3" width={1}>
                            <Avatar
                                email={user.email}
                                name={user.userInfo.name}
                                image={user.userInfo.picture?.url}
                                size={32}
                            />

                            <Box
                                css={{
                                    width: 'calc(100% - 44px)'
                                }}
                            >
                                <Box fontWeight="semiBold" css={{ ellipsis: true }}>
                                    {user.userInfo.name || user.email}
                                </Box>
                                {isNonEmptyString(user.userInfo.name) && (
                                    <Box color="gray500" css={{ ellipsis: true }}>
                                        {user.email}
                                    </Box>
                                )}
                            </Box>
                        </Flex>
                    </Item>

                    <Separator direction="horizontal" color="gray100" />

                    {organizations.map(({ id, name }) => (
                            <Item
                                key={id}
                                onClick={async () => {
                                    await updateLocalOrganization({ eventsSegmentId: null });
                                    // This needs to be `location.href` to fully clear the cached
                                    // `eventsSegmentId`.
                                    // TODO - is there a better/cleaner way to avoid this bug?
                                    location.href = HeaventPaths.EVENTS(id);
                                }}
                            >
                                {name}
                            </Item>
                        ))}

                    <Separator direction="horizontal" color="gray100" />

                    {organization.permissions.isOrganizationAdmin && (
                        <>
                            <Item
                                onClick={() => setIsDropdownOpen(false)}
                                to={HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(organizationId)}
                            >
                                {translate('mon_profil_48224')}
                            </Item>
                            <Item
                                onClick={() => setIsDropdownOpen(false)}
                                to={HeaventPaths.ORGANIZATION_PARAMETERS_INFOS(organizationId)}
                            >
                                {translate('organisation_71863')}
                            </Item>
                            <Item
                                onClick={() => setIsDropdownOpen(false)}
                                to={HeaventPaths.ORGANIZATION_PARAMETERS_TEAM(organizationId)}
                            >
                                {translate('utilisateurs_04975')}
                            </Item>
                            <Separator direction="horizontal" color="gray100" />
                        </>
                    )}

                    {getAdminsEmails().includes(user.email) && (
                        <Item to={HeaventPaths.BACKOFFICE}>{translate('back_office_83072')}</Item>
                    )}

                    <Item color="red" onClick={() => signOut()}>
                        {translate('se_d_connecter_74151')}
                    </Item>
                </DropdownMenu>
            </Dropdown>
        </Flex>
    );
};
