import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useCampaignsSegmentsQuery } from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { CommunityCampaigns } from './communityCampaigns';

export const CommunityCampaignsLoader = () => {
    const {
        params: { organizationId }
    } = useHeavent();
    const { localOrganization } = useOrganizationContext();
    const { data, loader, reload } = useCampaignsSegmentsQuery({ organizationId });
    const segmentsIds = React.useMemo(() => (data.organization?.segmentsFolders ?? [])
            .flatMap((sf) => sf.segments)
            .map((s) => s.id), [data.organization]);

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={HeaventPaths.COMMUNITY_CAMPAIGNS_SEGMENT(':organizationId', ':segmentId')}
                    children={
                        <CommunityCampaigns organization={data.organization} reload={reload} />
                    }
                />

                <Route
                    children={
                        <Empty
                            path={HeaventPaths.COMMUNITY_CAMPAIGNS_SEGMENT(
                                organizationId,
                                localOrganization?.campaignsSegmentId &&
                                    segmentsIds.includes(localOrganization.campaignsSegmentId)
                                    ? localOrganization?.campaignsSegmentId
                                    : segmentsIds[0]
                            )}
                            replace={true}
                        />
                    }
                />
            </Switch>
        );
    }
};
