import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { NumberService } from 'common/src/services/numberService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';

interface IPositionRowResourcesProps {
    assignedResources: number;
    resources: Emptyable<number>;
}

export const PositionRowResources = (props: IPositionRowResourcesProps) => {
    const translate = useTranslate();
    const numberService = useService(NumberService);

    return (
        <Flex
            align="center"
            width={1}
            gap="2"
            css={{
                '& > div': {
                    width: '100%'
                }
            }}
        >
            <Box>
                {translate(
                    '_1_2_membres_04485',
                    props.assignedResources,
                    numberService.toNumberOrInfinity(props.resources)
                )}
            </Box>

            <ProgressBar
                percent={props.assignedResources / (props.resources || props.assignedResources)}
            />
        </Flex>
    );
};
