import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';

interface IHighfiveZoneProps {
    color: string;
    name: string;
    src: string;
}

export const HighfiveZone = (props: IHighfiveZoneProps) => (
        <Flex align="center" gap="2">
            <Flex
                align="center"
                justify="center"
                height={24}
                width={28}
                css={{
                    background: props.color,
                    color: 'white',
                    fontSize: '$textMd',
                    fontWeight: '$bold'
                }}
            >
                <img src={props.src} height="14px" width="14px" />
            </Flex>

            <Box color="gray800" fontSize="textXs" fontWeight="bold">
                {props.name}
            </Box>
        </Flex>
    );
