import * as React from 'react';
import { Avatar } from '../../../components/avatar/avatar';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

const getFrontBackground = (population: string, badge: string) => {
    switch (population) {
        case 'Direction':
            return `https://assets.recrewteer.com/badges/doxart/Direction_Recto.png`;
        case 'Organisation':
            return `https://assets.recrewteer.com/badges/doxart/Orga_Recto.png`;
        case 'Bénévole':
            if (badge === 'Violet (Orga/Direction)') {
                return `https://assets.recrewteer.com/badges/doxart/Benevole_purple_recto.png`;
            } else {
                return `https://assets.recrewteer.com/badges/doxart/Benevole_green_recto_photo.png`;
            }
        case 'Artiste':
            return `https://assets.recrewteer.com/badges/doxart/Artisite_Recto.png`;
        case 'Invité Artiste':
            return `https://assets.recrewteer.com/badges/doxart/Invitation_artiste.jpg`;
        case 'Agora':
            return `https://assets.recrewteer.com/badges/doxart/Agora.jpg`;
        case 'Photographe':
            return `https://assets.recrewteer.com/badges/doxart/Photographe_or_Recto.png`;
        case 'Technicien':
            return `https://assets.recrewteer.com/badges/doxart/Technicien_green_recto.png`;
        case "Chef.fe d'équipe":
            return `https://assets.recrewteer.com/badges/doxart/Chef_equipe.jpg`;
        case 'Prestataire':
            return `https://assets.recrewteer.com/badges/doxart/Prestataire.jpg`;
        case 'Prévention':
            return `https://assets.recrewteer.com/badges/doxart/Prevention.jpg`;
        default:
            return '';
    }
};

const getTop = (population: string) => {
    switch (population) {
        case 'Bénévole':
        case 'Prestataire':
        case 'Prévention':
        case "Chef.fe d'équipe":
            return 200;
        default:
            return 210;
    }
};

interface IBadgeDoxartProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeDoxart = (props: IBadgeDoxartProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = ui.doxartPopulation;
    const badge = ui.doxartBadge;
    const position = vr.positionsSlotsUsersInfos[0]?.positionCategory.name;
    const showPicture = [
        'Direction',
        'Organisation',
        'Photographe',
        'Bénévole',
        "Chef.fe d'équipe"
    ].includes(population);
    const showPosition = ['Bénévole', "Chef.fe d'équipe"].includes(population);

    return (
        <>
            <Flex
                height={397}
                width={280}
                css={{
                    background: `url(${getFrontBackground(population, badge)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$lemonMilk',
                    position: 'relative'
                }}
            >
                <Flex
                    direction="column"
                    width={1}
                    css={{
                        color: 'black',
                        position: 'absolute',
                        top: `${getTop(population)}px`
                    }}
                >
                    {showPicture && (
                        <>
                            {' '}
                            <Flex justify="center" width={1}>
                                <Avatar
                                    size={56}
                                    email={ui.nameOrEmail}
                                    name={ui.name}
                                    image={ui.picture?.url}
                                />
                            </Flex>
                            <Spacer height="2" />
                        </>
                    )}

                    <Box
                        textAlign="center"
                        width={1}
                        css={{
                            fontWeight: '500',
                            textTransform: 'uppercase'
                        }}
                    >
                        {ui.firstName}
                    </Box>

                    <Box
                        textAlign="center"
                        width={1}
                        css={{
                            fontWeight: '500',
                            textTransform: 'uppercase'
                        }}
                    >
                        {ui.lastName}
                    </Box>

                    {population === 'Prestataire' && (
                        <>
                            <Spacer height="2" />

                            <Box
                                textAlign="center"
                                width={1}
                                css={{
                                    fontSize: '12px',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {ui.doxartEntreprise}
                            </Box>
                        </>
                    )}

                    {showPosition && (
                        <>
                            <Spacer height="2" />

                            <Box
                                textAlign="center"
                                width={1}
                                css={{
                                    fontSize: '12px',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {position}
                            </Box>
                        </>
                    )}
                </Flex>
            </Flex>
        </>
    );
};
