import { Empty } from 'common-front/src/components/empty/empty';
import { H_NEW_VERSION } from 'common-front/src/components/graphql/graphql';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { CommonEnvVars } from 'common/src/envVars';
import { HeaventEnv, heaventEnvDisplayName } from 'common/src/heaventEnv';
import { useLocation, useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { OrganizationsPaths, PathComponents } from 'common/src/util/paths/organizationsPaths';
import * as Organization from 'common/src/vo/organization';
import { WEEEVENT_ORGANIZATIONS_IDS } from 'common/src/vo/organization';
import * as React from 'react';
import { OrganizationMenu } from './menu/organizationMenu';
import { useOrganizationContext } from './organizationContext';

// for some organizations we don't want to display the overdue header
const ORGANIZATIONS_IDS_NO_OVERDUE = WEEEVENT_ORGANIZATIONS_IDS;

const NEW_VERSION_HEIGHT = 40;
const TRIAL_OVERDUE_HEIGHT = 64;
const MENU_HEIGHT = 64;

interface IOrganizationComponentProps {
    children: React.ReactNode;
}

export const OrganizationComponent = (props: IOrganizationComponentProps) => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const location = useLocation();
    const { organization } = useOrganizationContext();
    const [displayNewVersion, setDisplayNewVersion] = React.useState(false);
    const remainingDays = Organization.remainingDays(organization);
    const showTrial = Organization.showTrial(remainingDays);
    const overdueInvoice = organization.invoices.length > 0;
    const newVersionListener = React.useCallback(() => {
        setDisplayNewVersion(true);
    }, [setDisplayNewVersion]);

    React.useEffect(() => {
        document.addEventListener(H_NEW_VERSION, newVersionListener);

        return () => {
            document.removeEventListener(H_NEW_VERSION, newVersionListener);
        };
    }, []);

    if (organization.isBlocked && !location.pathname.endsWith(PathComponents.STATES.BLOCKED)) {
        return <Empty path={OrganizationsPaths.ORGANIZATION_IS_BLOCKED(organizationId)} />;
    } else if (
        !organization.isBlocked &&
        location.pathname.endsWith(PathComponents.STATES.BLOCKED)
    ) {
        return <Empty path={HeaventPaths.ORGANIZATION(organizationId)} />;
    } else if (remainingDays <= 0 && !location.pathname.endsWith(PathComponents.STATES.EXPIRED)) {
        return <Empty path={OrganizationsPaths.ORGANIZATION_IS_EXPIRED(organizationId)} />;
    } else if (remainingDays > 0 && location.pathname.endsWith(PathComponents.STATES.EXPIRED)) {
        return <Empty path={HeaventPaths.ORGANIZATION(organizationId)} />;
    } else {
        const displayOverdue =
            overdueInvoice &&
            CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer &&
            !ORGANIZATIONS_IDS_NO_OVERDUE.includes(organizationId);
        const contentHeightToRemove =
            MENU_HEIGHT +
            (displayNewVersion ? NEW_VERSION_HEIGHT : 0) +
            (showTrial || displayOverdue ? TRIAL_OVERDUE_HEIGHT : 0);

        return (
            <Flex direction="column" height={1} width={1} css={{ overflow: 'hidden' }}>
                {displayNewVersion && (
                    <Flex
                        align="center"
                        justify="between"
                        gap="1"
                        height={NEW_VERSION_HEIGHT}
                        width={1}
                        css={{ background: '$primary700', px: '$6' }}
                    >
                        <Box />

                        <Flex gap="1">
                            <Box color="white">{translate('une_nouvelle_ve_69718')}</Box>

                            <Box
                                color="white"
                                css={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline'
                                }}
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                {translate('cliquez_pour_re_36838')}
                            </Box>
                        </Flex>

                        <Box
                            color="white"
                            fontSize="textMd"
                            css={{ cursor: 'pointer' }}
                            onClick={() => {
                                setDisplayNewVersion(false);
                            }}
                        >
                            <I icon="xmark" />
                        </Box>
                    </Flex>
                )}

                {showTrial && !displayOverdue && (
                    <Flex
                        align="center"
                        justify="center"
                        height={TRIAL_OVERDUE_HEIGHT}
                        width={1}
                        css={{ background: '$primary700', color: 'white' }}
                    >
                        {translate(
                            'trial_remaining_days',
                            remainingDays,
                            CommonEnvVars.EMAIL_FROM,
                            heaventEnvDisplayName()
                        )}
                    </Flex>
                )}

                {displayOverdue && (
                    <Flex
                        align="center"
                        justify="center"
                        height={TRIAL_OVERDUE_HEIGHT}
                        width={1}
                        css={{
                            background: '$primary700',
                            color: 'white',
                            '& > a': {
                                color: 'white',
                                textDecoration: 'underline'
                            }
                        }}
                        dangerouslySetInnerHTML={{
                            __html: translate(
                                'sauf_erreur_de_03325',
                                organization.invoices[0].url,
                                organization.invoices[0].invoiceNumber
                            )
                        }}
                    />
                )}

                <Box height={MENU_HEIGHT} width={1}>
                    <OrganizationMenu />
                </Box>

                <Box width={1} css={{ height: `calc(100% - ${contentHeightToRemove}px)` }}>
                    {props.children}
                </Box>
            </Flex>
        );
    }
};
