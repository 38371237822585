import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

const getBackground = (statut: string) => {
    switch (statut) {
        case 'Partenaire/Exposant':
            return 'https://assets.recrewteer.com/badges/reims/reimsPartenairesRecto.png';
        case 'Presse':
            return 'https://assets.recrewteer.com/badges/reims/reimsPressRecto.png';
        case 'VIP':
            return 'https://assets.recrewteer.com/badges/reims/reimsVIPRecto.png';
        case 'Staff':
            return 'https://assets.recrewteer.com/badges/reims/reimsStaffRecto.png';
        case 'Prestataire':
            return 'https://assets.recrewteer.com/badges/reims/reimsPrestataireRecto.png';
        default:
            return 'https://assets.recrewteer.com/badges/reims/reimsBenevolesRecto.png';
    }
};

interface IBadgeReimsFrontProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeReimsFront = (props: IBadgeReimsFrontProps) => {
    const userInfo = props.event.volunteerRegistration.userInfo;
    const accreditations = props.event.volunteerRegistration.accreditationsUsersInfos.map(
        ({ accreditation }) => accreditation.acronym
    );

    return (
        <Flex
            height={597}
            width={435}
            css={{
                background: `url(${getBackground(userInfo.reimsStatut)}) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    fontSize: '32px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '143px',
                    width: '100%'
                }}
            >
                {userInfo.firstName}
            </Box>

            <Box
                css={{
                    fontSize: '32px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '182px',
                    width: '100%'
                }}
            >
                {userInfo.lastName}
            </Box>

            {accreditations.includes('DEP') && (
                <Box
                    css={{
                        background:
                            'url(https://assets.recrewteer.com/badges/reims/reimsPictoZoneDepart.png) no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '52px',
                        height: '52px',
                        left: '103px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '266px',
                        width: '52px'
                    }}
                />
            )}

            {accreditations.includes('ZA') && (
                <Box
                    css={{
                        background:
                            'url(https://assets.recrewteer.com/badges/reims/reimsPictoZoneArrivee.png) no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '52px',
                        height: '52px',
                        left: '195px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '266px',
                        width: '52px'
                    }}
                />
            )}

            {accreditations.includes('LOG') && (
                <Box
                    css={{
                        background:
                            'url(https://assets.recrewteer.com/badges/reims/remisPictoLogistique.png) no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '52px',
                        height: '52px',
                        left: '287px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '266px',
                        width: '52px'
                    }}
                />
            )}
            {accreditations.includes('VIL') && (
                <Box
                    css={{
                        background:
                            'url(https://assets.recrewteer.com/badges/reims/reimsPictoVillage.png) no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '52px',
                        height: '52px',
                        left: '103px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '338px',
                        width: '52px'
                    }}
                />
            )}

            {accreditations.includes('VIP') && (
                <Box
                    css={{
                        background:
                            'url(https://assets.recrewteer.com/badges/reims/reimsPictoVIP.png) no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '52px',
                        height: '52px',
                        left: '195px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '338px',
                        width: '52px'
                    }}
                />
            )}

            {accreditations.includes('ENT') && (
                <Box
                    css={{
                        background:
                            'url(https://assets.recrewteer.com/badges/reims/remisPictoEntreprise.png) no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '52px',
                        height: '52px',
                        left: '287px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '338px',
                        width: '52px'
                    }}
                />
            )}
        </Flex>
    );
};
