import * as React from 'react';
import { Avatar } from '../../../../components/avatar/avatar';
import { Box } from '../../../../designSystem/components/box';
import { Flex } from '../../../../designSystem/components/flex';
import { Spacer } from '../../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment } from '../../../../generated/types';

interface IUltraMarinOrganisationBackProps {
    event: DocumentUserCustomBadgeFragment;
}

export const UltraMarinOrganisationBack = (props: IUltraMarinOrganisationBackProps) => {
    const userInfo = props.event.volunteerRegistration.userInfo;
    const accreditations = props.event.volunteerRegistration.accreditationsUsersInfos
        .map(({ accreditation }) => accreditation.acronym)
        .join(', ');

    return (
        <Flex
            direction="column"
            align="center"
            height={396}
            width={280}
            css={{
                background: `url(https://assets.recrewteer.com/badges/ultraMarin/ultraMarinOrganisationVerso.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$brito',
                overflow: 'hidden',
                paddingTop: '44px'
            }}
        >
            <Avatar
                size={64}
                name={userInfo.name}
                email={userInfo.email}
                image={userInfo.picture?.url}
                border="1px solid black"
            />

            <Spacer height="3" />

            <Box
                width={1}
                color="white"
                textAlign="center"
                css={{ color: 'white', fontSize: '10px', fontWeight: '700' }}
            >
                {userInfo.name}
            </Box>

            <Box
                css={{
                    color: 'white',
                    fontSize: '8px',
                    fontWeight: '400',
                    paddingTop: '172px'
                }}
            >
                ACCRÉDITATIONS
            </Box>

            <Box
                css={{
                    color: 'white',
                    fontSize: '10px',
                    fontWeight: '300'
                }}
            >
                {accreditations}
            </Box>
        </Flex>
    );
};
