import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { Box } from 'common/src/designSystem/components/box';
import { EventId, PositionsCategory } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { usePositionCategoryDescriptionUpdateMutation } from '../../generated/graphqlHooks';

interface IUpdateDescriptionModalProps {
    eventId: EventId;
    positionCategory: Pick<PositionsCategory, 'id' | 'description'>;

    onClose(): void;
    reload(): void;
}

export const UpdateDescriptionModal = (props: IUpdateDescriptionModalProps) => {
    const translate = useTranslate();
    const { mutate } = usePositionCategoryDescriptionUpdateMutation();

    return (
        <FormModal
            size="md"
            buttonText={translate('mettre_jour_l_18158')}
            icon="text"
            initialValues={{
                positionCategory: { description: props.positionCategory.description }
            }}
            subtitle={translate('la_description_98866')}
            title={translate('ajouter_une_des_10769')}
            onClose={props.onClose}
            onSubmit={async (values) => {
                await mutate({
                    eventId: props.eventId,
                    positionCategoryId: props.positionCategory.id,
                    description: values.positionCategory?.description ?? ''
                });

                props.reload();
                props.onClose();
            }}
        >
            <Box width={1} css={{ overflow: 'hidden auto' }}>
                <RichEditor
                    name="positionCategory.description"
                    label={translate('description_58935')}
                />
            </Box>
        </FormModal>
    );
};
