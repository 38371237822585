import { Map } from 'common-front/src/components/map/map';
import { MapLoaded } from 'common-front/src/components/map/mapLoaded';
import { Box } from 'common/src/designSystem/components/box';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { extractCoordinates } from 'common/src/util/map';
import * as React from 'react';
import { usePositionMapQuery } from '../../generated/graphqlHooks';

export const PositionMap = () => {
    const { eventId, positionId } = useParams();
    const { data, loader } = usePositionMapQuery({
        eventId,
        positionId
    });
    const [isLoadedVisible, setIsLoadedVisible] = React.useState(false);

    if (loader) {
        return loader;
    } else {
        const position = data.eventPublic.position;

        return (
            <>
                <Box
                    height={1}
                    width={1}
                    css={{
                        '& .gm-style-cc': {
                            display: 'none'
                        }
                    }}
                >
                    <Map
                        point={extractCoordinates(position)}
                        layer={position.layer}
                        initialZoom={position.zoom}
                        traces={position.traces}
                        mapOptions={{
                            disableDefaultUI: true,
                            keyboardShortcuts: false
                        }}
                        onTilesloaded={() => {
                            setIsLoadedVisible(true);
                        }}
                    />
                </Box>

                {isLoadedVisible && <MapLoaded />}
            </>
        );
    }
};
