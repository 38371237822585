import { ColorInput } from 'common-front/src/designSystem/components/colorInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    AccreditationPositionProperty,
    PositionsPositionFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsColorCellProps {
    firstCellCss: any;
    position: PositionsPositionFragment;

    reload(): void;
}

export const PositionsColorCell = (props: IPositionsColorCellProps) => {
    const { translate } = useHeavent();

    return (
        <PositionsUpdateCell
            cellCss={props.firstCellCss}
            initialValue={props.position.color}
            position={props.position}
            property={AccreditationPositionProperty.Color}
            reload={props.reload}
            renderInput={(value, setValue) => <ColorInput label={translate('Color')} value={value} onChange={setValue} />}
        >
            <Flex align="center" gap="3" width={1}>
                <Box
                    css={{
                        background: props.position.color,
                        borderRadius: '2px',
                        height: '24px',
                        width: '24px'
                    }}
                />

                <Box>{props.position.color}</Box>
            </Flex>
        </PositionsUpdateCell>
    );
};
