import { Button } from 'common-front/src/designSystem/components/button';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { theme } from 'common/src/designSystem/components/stitches';
import {
    Feature,
    UserPanelQuery,
    UsersInfoId,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { PathComponents } from 'common/src/util/paths/commonPaths';
import { UserInfoIdPathParam } from 'common/src/util/pathsTypes';
import { isNonEmptyString } from 'common/src/util/string';
import { getStateBadgeColorIcon } from 'common/src/vo/volunteerRegistration';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { useUserPanelQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { Actions } from './actions/actions';
import { Comments } from './comments/comments';
import { Informations } from './informations';
import { Registration } from './registration/registration';
import { UserPanelAccreditations } from './userPanelAccreditations';

interface IUserPanelComponentProps {
    baseRoutePath: string;
    data: UserPanelQuery;
    userInfoId: UsersInfoId;

    getBasePath(userInfoId: UserInfoIdPathParam, isRouteComponent?: boolean): string;
    onDelete(): void;
    onUpdateState(): void;
    reload(): void;
}

const UserPanelComponent = (props: IUserPanelComponentProps) => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const { hasFeature } = useOrganizationContext();
    const {
        event: {
            permissions: { isEventAdmin }
        },
        isPositionAdmin
    } = useEventContext();
    const basePath = React.useMemo(
        () => props.getBasePath(props.userInfoId),
        [props.userInfoId, props.getBasePath]
    );
    const volunteerRegistration = props.data.event.volunteerRegistration;
    const userInfo = volunteerRegistration.userInfo;
    const { color, leftIcon } = getStateBadgeColorIcon(volunteerRegistration.state);
    const volunteerRegistrationId = volunteerRegistration.id;

    return (
        <Flex direction="column" align="center">
            <Spacer height="10" />

            <Box css={{ position: 'relative' }}>
                <Avatar
                    size={96}
                    image={userInfo.picture?.url}
                    email={userInfo.email}
                    name={userInfo.name}
                />

                <Flex
                    align="center"
                    justify="center"
                    height={24}
                    width={24}
                    css={{
                        background: theme.colors[`${color}100`].value,
                        border: '2px solid white',
                        borderRadius: '24px',
                        bottom: '0',
                        color: theme.colors[`${color}700`].value,
                        fontSize: '11px',
                        right: '0',
                        position: 'absolute'
                    }}
                >
                    <I icon={leftIcon} />
                </Flex>
            </Box>

            <Spacer height="6" />

            <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                {isNonEmptyString(userInfo.name) ? userInfo.name : '-'}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{userInfo.email}</Box>

            <Spacer height="7" />

            <Tabs>
                <TabList center={true}>
                    <Tab path={`${basePath}/${PathComponents.SECTIONS.INFORMATION}`}>
                        {translate('informations_22954')}
                    </Tab>
                    <Tab path={`${basePath}/registration`}>{translate('missions_63972')}</Tab>
                    {hasFeature(Feature.Accreditation) && isEventAdmin && (
                        <Tab path={`${basePath}/accreditations`}>
                            {translate('accr_ditations_39450')}
                        </Tab>
                    )}
                    {(isPositionAdmin() ||
                        volunteerRegistration.state === VolunteerRegistrationState.Assigned) && (
                        <Tab path={`${basePath}/actions`}>{translate('actions_21689')}</Tab>
                    )}
                    {isEventAdmin && (
                        <Tab path={`${basePath}/comments`}>
                            {translate('commentaires_97857', userInfo.numberOfComments)}
                        </Tab>
                    )}
                </TabList>

                <TabPanel path={`${props.baseRoutePath}/${PathComponents.SECTIONS.INFORMATION}`}>
                    <Informations
                        editButton={
                            isEventAdmin && (
                                <>
                                    <Spacer height="6" />

                                    <Button
                                        to={HeaventPaths.EDIT_VOLUNTEER(
                                            organizationId,
                                            eventId,
                                            volunteerRegistrationId
                                        )}
                                    >
                                        <Box width={1} textAlign="center">
                                            {translate('modifier_les_in_53003')}
                                        </Box>
                                    </Button>
                                </>
                            )
                        }
                        fieldsToDisplay={props.data.event.formsCustomsFields}
                        privateCustomFields={props.data.organization.customFields.nodes}
                        userInfo={userInfo}
                    />
                </TabPanel>

                <TabPanel path={`${props.baseRoutePath}/registration`}>
                    <Registration
                        organizationId={organizationId}
                        eventId={eventId}
                        volunteerRegistration={volunteerRegistration}
                        reload={props.reload}
                    />
                </TabPanel>

                {hasFeature(Feature.Accreditation) && isEventAdmin && (
                    <TabPanel path={`${props.baseRoutePath}/accreditations`}>
                        <UserPanelAccreditations
                            accreditationsUsersInfos={
                                volunteerRegistration.accreditationsUsersInfos
                            }
                            eventId={eventId}
                            organizationId={organizationId}
                        />
                    </TabPanel>
                )}

                {(isPositionAdmin() ||
                    volunteerRegistration.state === VolunteerRegistrationState.Assigned) && (
                    <TabPanel path={`${props.baseRoutePath}/actions`}>
                        <Actions
                            accreditationState={
                                props.data.event.volunteerRegistration.accreditationState
                            }
                            organizationId={organizationId}
                            eventId={eventId}
                            isWeezeventConnected={isNonEmptyString(
                                props.data.event.weezeventConnectionInfos?.apiKey
                            )}
                            links={null}
                            state={props.data.event.volunteerRegistration.state}
                            userInfo={props.data.event.volunteerRegistration.userInfo}
                            volunteerRegistrationId={volunteerRegistrationId}
                            onDelete={props.onDelete}
                            onUpdateState={props.onUpdateState}
                        />
                    </TabPanel>
                )}

                {isEventAdmin && (
                    <TabPanel path={`${props.baseRoutePath}/comments`}>
                        <Comments showEvent={false} />
                    </TabPanel>
                )}
            </Tabs>
        </Flex>
    );
};

interface IUserPanelProps {
    baseRoutePath: string;
    userInfoId?: UsersInfoId;

    getBasePath(userInfoId: UsersInfoId): string;
    onDelete(): void;
    onUpdateState(): void;
}

export const UserPanel = (props: IUserPanelProps) => {
    const { organizationId, eventId, userInfoId } = useParams();
    const { data, loader, reload } = useUserPanelQuery({
        organizationId,
        eventId,
        userInfoId: props.userInfoId || userInfoId
    });

    return (
        loader || (
            <UserPanelComponent
                data={data}
                baseRoutePath={props.baseRoutePath}
                getBasePath={props.getBasePath}
                onDelete={props.onDelete}
                onUpdateState={() => {
                    props.onUpdateState();

                    reload();
                }}
                reload={reload}
                userInfoId={data.event.volunteerRegistration.userInfo.id}
            />
        )
    );
};
