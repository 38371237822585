import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeRoiArthurProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeRoiArthur = (props: IBadgeRoiArthurProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/roiarthur/roiarthur.jpg) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Box
                width={320}
                css={{
                    color: '#181d3a',
                    fontSize: '18px',
                    fontWeight: '700',
                    left: '426px',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '89px'
                }}
            >
                {ui.name}
            </Box>

            <Box
                width={320}
                css={{
                    color: '#181d3a',
                    fontSize: '12px',
                    left: '426px',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '116px'
                }}
            >
                Numéro code-barres : {vr.weezeventParticipantInfo?.idBarcode}
            </Box>

            <Box
                height={114}
                width={114}
                css={{
                    left: '530px',
                    position: 'absolute',
                    top: '144px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=114&width=114`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
