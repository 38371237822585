import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { SegmentId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { DocumentRow } from './documentRow';

interface IDocumentsEventProps {
    positionsSegmentId: SegmentId;
    volunteersSegmentId: SegmentId;
}

export const DocumentsEvent = (props: IDocumentsEventProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();

    return (
        <Flex direction="column" gap="2">
            <Box font="gray900 textLg semiBold">{translate('_v_nement_22345')}</Box>

            <DocumentRow
                color="pink"
                icon="list-ul"
                title={translate('liste_des_membr_89037')}
                text={translate('nombre_de_membr_87049')}
                path={HeaventPaths.DOCUMENTS_VOLUNTEERS_LISTING(
                    organizationId,
                    eventId,
                    props.volunteersSegmentId
                )}
            />

            <DocumentRow
                color="purple"
                icon="calendar-day"
                title={translate('planning_d_un_g_23684')}
                text={translate('nombre_de_missi_56719')}
                path={HeaventPaths.DOCUMENTS_POSITIONS_VOLUNTEERS_PLANNING(
                    organizationId,
                    eventId,
                    props.positionsSegmentId
                )}
            />

            <DocumentRow
                color="purple"
                icon="calendar-day"
                title={translate('planning_de_l_96909')}
                text={translate('nombre_de_missi_56719')}
                path={HeaventPaths.DOCUMENTS_PLANNING(
                    organizationId,
                    eventId,
                    props.positionsSegmentId
                )}
            />
        </Flex>
    );
};
