import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { noop } from 'lodash-es';
import * as React from 'react';
import { IRadioSize, IRadioState, Radio, RadioText } from '../components/radio';

export const Radios = () => {
    const [size, setSize] = React.useState<IRadioSize>('sm');
    const [state, setState] = React.useState<IRadioState>('unchecked');
    const [disabled, setDisabled] = React.useState(false);
    const [text, setText] = React.useState('Remember me!');
    const [subtext, setSubtext] = React.useState('');

    return (
        <Flex direction="column" gap="6">
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Radios
            </Box>

            <Flex gap="3">
                <Radio onClick={noop} />

                <Radio disabled onClick={noop} />

                <Radio state="checked" onClick={noop} />

                <Radio state="checked" disabled onClick={noop} />
            </Flex>

            <Flex gap="3">
                <Radio size="md" onClick={noop} />

                <Radio size="md" disabled onClick={noop} />

                <Radio size="md" state="checked" onClick={noop} />

                <Radio size="md" state="checked" disabled onClick={noop} />
            </Flex>

            <Flex gap="3">
                <RadioText onClick={noop}>Remember me!</RadioText>

                <RadioText disabled onClick={noop}>
                    Remember me!
                </RadioText>

                <RadioText state="checked" onClick={noop}>
                    Remember me!
                </RadioText>

                <RadioText state="checked" disabled onClick={noop}>
                    Remember me!
                </RadioText>
            </Flex>

            <Flex gap="3">
                <RadioText size="md" onClick={noop}>
                    Remember me!
                </RadioText>

                <RadioText size="md" disabled onClick={noop}>
                    Remember me!
                </RadioText>

                <RadioText size="md" state="checked" onClick={noop}>
                    Remember me!
                </RadioText>

                <RadioText size="md" state="checked" disabled onClick={noop}>
                    Remember me!
                </RadioText>
            </Flex>

            <Flex gap="3">
                <RadioText subtext="Save my login details for next time." onClick={noop}>
                    Remember me!
                </RadioText>

                <RadioText subtext="Save my login details for next time." disabled onClick={noop}>
                    Remember me!
                </RadioText>

                <RadioText
                    subtext="Save my login details for next time."
                    state="checked"
                    onClick={noop}
                >
                    Remember me!
                </RadioText>

                <RadioText
                    subtext="Save my login details for next time."
                    state="checked"
                    disabled
                    onClick={noop}
                >
                    Remember me!
                </RadioText>
            </Flex>

            <Flex gap="3">
                <RadioText subtext="Save my login details for next time." size="md" onClick={noop}>
                    Remember me!
                </RadioText>

                <RadioText
                    subtext="Save my login details for next time."
                    size="md"
                    disabled
                    onClick={noop}
                >
                    Remember me!
                </RadioText>

                <RadioText
                    subtext="Save my login details for next time."
                    size="md"
                    state="checked"
                    onClick={noop}
                >
                    Remember me!
                </RadioText>

                <RadioText
                    subtext="Save my login details for next time."
                    size="md"
                    state="checked"
                    disabled
                    onClick={noop}
                >
                    Remember me!
                </RadioText>
            </Flex>

            <Flex align="center" gap="3">
                <select
                    value={size}
                    onChange={(e) => {
                        setSize(e.target.value as IRadioSize);
                    }}
                >
                    <option value="sm">sm</option>
                    <option value="md">md</option>
                </select>

                <select
                    value={state}
                    onChange={(e) => {
                        setState(e.target.value as IRadioState);
                    }}
                >
                    <option value="unchecked">unchecked</option>
                    <option value="checked">checked</option>
                </select>

                <input
                    type="checkbox"
                    checked={disabled}
                    onChange={(e) => {
                        setDisabled(e.target.checked);
                    }}
                />

                <Box color="gray800">Disabled</Box>

                <input
                    type="text"
                    placeholder="Texte"
                    value={text}
                    onChange={(e) => {
                        setText(e.target.value);
                    }}
                />

                <input
                    type="text"
                    placeholder="Sous-texte"
                    value={subtext}
                    onChange={(e) => {
                        setSubtext(e.target.value);
                    }}
                />
            </Flex>

            <Flex direction="column" gap="3">
                <Radio state={state} size={size} disabled={disabled} onClick={setState} />

                <RadioText
                    state={state}
                    size={size}
                    disabled={disabled}
                    subtext={subtext}
                    onClick={setState}
                >
                    {text}
                </RadioText>
            </Flex>
        </Flex>
    );
};
