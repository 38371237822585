import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import {
    AccreditationPositionProperty,
    PositionsPositionFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { PositionsTagsCellInput } from './positionsTagsCellInput';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsTagsCellProps {
    firstCellCss: any;
    position: PositionsPositionFragment;

    reload(): void;
}

export const PositionsTagsCell = (props: IPositionsTagsCellProps) => {
    const badges = React.useMemo(() => props.position.tags.map((tag) => ({
            id: tag.id,
            color: 'gray',
            icon: null,
            text: tag.name
        })), [props.position.tags]);

    return (
        <PositionsUpdateCell
            cellCss={props.firstCellCss}
            initialValue={props.position.tags.map((t) => t.id)}
            position={props.position}
            property={AccreditationPositionProperty.Tag}
            reload={props.reload}
            renderInput={(value, setValue) => <PositionsTagsCellInput value={value} setValue={setValue} />}
        >
            <BadgesPlus badges={badges} />
        </PositionsUpdateCell>
    );
};
