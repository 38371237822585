import { Spacer } from 'common/src/components/spacer/spacer';
import { Box } from 'common/src/designSystem/components/box';
import { CSS } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

interface IPageProps {
    children: React.ReactNode;
    css?: CSS;
    space?: number;
}

export const Page = (props: IPageProps) => (
        <Box
            css={{
                height: '100%',
                overflowY: 'auto',
                padding: '0 $7',
                width: '100%',
                ...(props.css as any)
            }}
        >
            <Spacer height={props.space ?? 32} />

            {props.children}

            <Spacer height={props.space ?? 32} />
        </Box>
    );
