import { groupBy } from 'lodash-es';
import { Interval } from 'luxon';
import * as React from 'react';
import { Avatar } from '../../../../components/avatar/avatar';
import { Box } from '../../../../designSystem/components/box';
import { Flex } from '../../../../designSystem/components/flex';
import { Spacer } from '../../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment } from '../../../../generated/types';

export function ultraMarinRangeToString(interval: Interval) {
    const start = interval.start!.toLocaleString(
        {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
        },
        { locale: 'fr' }
    );
    const startTime = interval.start!.toFormat('HH:mm');
    const end = interval.end!.toLocaleString(
        {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
        },
        { locale: 'fr' }
    );
    const endTime = interval.end!.toFormat('HH:mm');

    return `${start} ${startTime} - ${end} ${endTime}`;
}

interface IUltraMarinBenevoleBackProps {
    event: DocumentUserCustomBadgeFragment;
}

export const UltraMarinBenevoleBack = (props: IUltraMarinBenevoleBackProps) => {
    const userInfo = props.event.volunteerRegistration.userInfo;
    const assignments = Object.entries(
        groupBy(
            props.event.volunteerRegistration.positionsSlotsUsersInfos,
            (psui) => psui.position.id
        )
    );
    const accreditations = props.event.volunteerRegistration.accreditationsUsersInfos
        .map(({ accreditation }) => accreditation.acronym)
        .join(', ');

    return (
        <Flex
            direction="column"
            align="center"
            height={396}
            width={280}
            css={{
                background: `url(https://assets.recrewteer.com/badges/ultraMarin/ultraMarinBenevoleVerso.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$brito',
                overflow: 'hidden',
                paddingBottom: '60px',
                paddingTop: '44px'
            }}
        >
            <Avatar
                size={64}
                name={userInfo.name}
                email={userInfo.email}
                image={userInfo.picture?.url}
                border="1px solid #30388a"
            />

            <Spacer height="3" />

            <Box
                width={1}
                color="white"
                textAlign="center"
                css={{ fontSize: '10px', fontWeight: '700' }}
            >
                {userInfo.name}
            </Box>

            <Spacer height="3" />

            <Flex direction="column" align="center" css={{ flex: '1' }}>
                {assignments.map(([_, psuis], index) => {
                    const leaders = psuis[0].position.leaders.concat(
                        psuis.flatMap(({ positionCategory }) => positionCategory.leaders)
                    );

                    return (
                        <React.Fragment key={index}>
                            {index !== 0 && (
                                <>
                                    <Spacer height="2" />
                                    <Box
                                        css={{
                                            background: '#30388a',
                                            borderRadius: '1px',
                                            height: '1px',
                                            width: '80px'
                                        }}
                                    />
                                    <Spacer height="2" />
                                </>
                            )}

                            <Box
                                css={{
                                    color: 'white',
                                    fontSize: '11px',
                                    fontWeight: '400',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    width: '200px'
                                }}
                            >
                                {psuis[0].position.name}
                            </Box>

                            <Spacer height="2" />

                            {psuis.map(({ positionSlot }, slotIndex) => (
                                    <Box
                                        key={slotIndex}
                                        css={{
                                            color: 'white',
                                            fontSize: '11px',
                                            fontWeight: '300'
                                        }}
                                    >
                                        {ultraMarinRangeToString(positionSlot.range)}
                                    </Box>
                                ))}

                            <Spacer height="1" />

                            <Box
                                css={{
                                    color: 'white',
                                    fontSize: '10px',
                                    fontWeight: '300'
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: leaders
                                        .map(
                                            (leader) =>
                                                `${leader.userInfo.firstName} : ${leader.userInfo.phone?.internationalFormat}`
                                        )
                                        .join('<br />')
                                }}
                            />
                        </React.Fragment>
                    );
                })}
            </Flex>

            <Spacer height="3" />

            <Box
                css={{
                    color: 'white',
                    fontSize: '8px',
                    fontWeight: '400'
                }}
            >
                ACCRÉDITATIONS
            </Box>

            <Box
                css={{
                    color: 'white',
                    fontSize: '10px',
                    fontWeight: '300'
                }}
            >
                {accreditations}
            </Box>
        </Flex>
    );
};
