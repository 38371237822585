import { Box } from 'common/src/designSystem/components/box';
import { Grid } from 'common/src/designSystem/components/grid';
import * as React from 'react';
import { OrganizationComponent } from '../organization/organizationComponent';
import { CommunityMenu } from './communityMenu';

interface ICommunityContainerProps {
    children: React.ReactNode;
}

export const CommunityContainer = (props: ICommunityContainerProps) => (
        <OrganizationComponent>
            <Grid gridtemplatecolumns="auto" gridtemplaterows="55px 1fr" height={1} width={1}>
                <CommunityMenu />

                <Box css={{ overflowY: 'auto' }}>{props.children}</Box>
            </Grid>
        </OrganizationComponent>
    );
