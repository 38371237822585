import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { CustomFieldsCategory, OrganizationId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useCustomFieldCategoryDeleteMutation } from '../../generated/graphqlHooks';

interface IDeleteCustomFieldCategoryProps {
    customFieldCategory: Pick<CustomFieldsCategory, 'id' | 'name'>;
    organizationId: OrganizationId;

    onSuccess(): void;
    onClose(): void;
}

export const DeleteCustomFieldCategory = (props: IDeleteCustomFieldCategoryProps) => {
    const translate = useTranslate();
    const { mutate } = useCustomFieldCategoryDeleteMutation();

    return (
        <DeleteModal
            text={translate('suppression_de_73669', props.customFieldCategory.name)}
            subtext={[translate('_tes_vous_s_r_d_50954')]}
            buttonText={translate('supprimer_43083')}
            onDelete={async () => {
                await mutate({
                    organizationId: props.organizationId,
                    customFieldCategoryId: props.customFieldCategory.id
                });

                props.onSuccess();
            }}
            onClose={props.onClose}
        />
    );
};
