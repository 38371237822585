import { CheckboxText, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { DropdownFilters } from 'common-front/src/designSystem/components/dropdownFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IMassAccreditFiltersProps {
    excludeEmpty: boolean;
    excludeFull: boolean;
    name: string;

    setExcludeEmpty(excludeFull: boolean): void;
    setExcludeFull(excludeFull: boolean): void;
    setName(name: string): void;
    setOffset(offset: number): void;
}

export const MassAccreditFilters = (props: IMassAccreditFiltersProps) => {
    const translate = useTranslate();

    const [areFiltersOpen, setAreFiltersOpen] = React.useState(false);
    const [isExcludeEmpty, setIsExcludeEmpty] = React.useState<ICheckboxState>(
        props.excludeEmpty ? 'checked' : 'unchecked'
    );
    const [isExcludeFull, setIsExcludeFull] = React.useState<ICheckboxState>(
        props.excludeFull ? 'checked' : 'unchecked'
    );

    const apply = () => {
        props.setExcludeEmpty(isExcludeEmpty === 'checked');
        props.setExcludeFull(isExcludeFull === 'checked');

        setAreFiltersOpen(false);
    };
    const reinit = () => {
        props.setExcludeEmpty(false);
        setIsExcludeEmpty('unchecked');
        props.setExcludeFull(false);
        setIsExcludeFull('unchecked');
        props.setName('');
        props.setOffset(0);

        setAreFiltersOpen(false);
    };

    return (
        <>
            <Box width={320}>
                <TextInput
                    value={props.name}
                    onChange={(newName: string) => {
                        props.setOffset(0);
                        props.setName(newName);
                    }}
                    placeholder={translate('rechercher_une_65646')}
                    icon="magnifying-glass"
                />
            </Box>

            <DropdownFilters
                color="white"
                isOpen={areFiltersOpen}
                showButtons={true}
                apply={apply}
                onStateChange={setAreFiltersOpen}
                reinit={reinit}
            >
                <CheckboxText state={isExcludeEmpty} onClick={setIsExcludeEmpty}>
                    {translate('cacher_les_cr_n_76382')}
                </CheckboxText>

                <CheckboxText state={isExcludeFull} onClick={setIsExcludeFull}>
                    {translate('cacher_les_cr_n_31908')}
                </CheckboxText>
            </DropdownFilters>
        </>
    );
};
