import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (population: string, statut: string) => {
    switch (population) {
        case 'Technicien·nes':
            return `https://assets.recrewteer.com/badges/dubcamp/technicien.jpg`;
        case 'Pro':
            return `https://assets.recrewteer.com/badges/dubcamp/visite.jpg`;
        case 'Média':
            if (statut === 'Média sans accès CT') {
                return `https://assets.recrewteer.com/badges/dubcamp/media_1.jpg`;
            } else {
                return `https://assets.recrewteer.com/badges/dubcamp/media_2.jpg`;
            }
        default:
            return `https://assets.recrewteer.com/badges/dubcamp/presta.jpg`;
    }
};

interface IBadgeDubcampProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeDubcamp = (props: IBadgeDubcampProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(${getBackground(
                    ui.dubcampPopulation,
                    ui.dubcampStatutDCF24
                )}) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    color: '#7a003b',
                    fontSize: '28px',
                    fontWeight: '700',
                    left: '100px',
                    position: 'absolute',
                    top: '317px'
                }}
            >
                {ui.name}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontSize: '20px',
                    left: '100px',
                    position: 'absolute',
                    textTransform: 'capitalize',
                    top: '351px'
                }}
            >
                {ui.dubcampPopulation}
            </Box>

            <Flex
                direction="column"
                align="center"
                gap="1"
                css={{
                    position: 'absolute',
                    right: '100px',
                    top: '290px'
                }}
            >
                <Box height={90} width={90}>
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=90&width=90`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>

                <Box css={{ color: 'black' }}>{vr.weezeventParticipantInfo?.idBarcode ?? ''}</Box>
            </Flex>
        </Flex>
    );
};
