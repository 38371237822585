import { chunk } from 'lodash-es';
import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgesFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { BadgeJardinMichelFront } from './badgeJardinMichelFront';

interface IBadgesJardinMichelProps {
    event: DocumentUserCustomBadgesFragment;
}

export const BadgesJardinMichel = (props: IBadgesJardinMichelProps) => (
        <>
            {chunk(props.event.volunteersRegistrations.nodes, 4).map((vrs, index) => (
                    <React.Fragment key={`page-${index}`}>
                        <Flex
                            align="center"
                            justify="center"
                            wrap="wrap"
                            gap="7"
                            height={A4_SIZES['96dpi'].height}
                            width={A4_SIZES['96dpi'].width}
                            css={{
                                overflow: 'hidden',
                                pageBreakBefore: 'always'
                            }}
                        >
                            {vrs.map((vr) => (
                                    <BadgeJardinMichelFront
                                        key={vr.userInfo.id}
                                        displayBorder={true}
                                        event={{
                                            ...props.event,
                                            volunteerRegistration: vr
                                        }}
                                    />
                                ))}
                        </Flex>
                    </React.Fragment>
                ))}
        </>
    );
