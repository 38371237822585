import { MutationNames } from '../generated/mutationsNames';

export enum ExcludedMutationNames {
    AccreditationsEmailsEstimate = MutationNames.AccreditationsEmailsEstimate,
    AssignmentsEmailsEstimate = MutationNames.AssignmentsEmailsEstimate,
    BackofficeInvoiceMarkAsPaid = MutationNames.BackofficeInvoiceMarkAsPaid,
    BackofficeOrganizationUpdate = MutationNames.BackofficeOrganizationUpdate,
    BackofficeOrganizationUserAdd = MutationNames.BackofficeOrganizationUserAdd,
    BackofficeOrganizationUserRemove = MutationNames.BackofficeOrganizationUserRemove,
    CampaignEstimate = MutationNames.CampaignEstimate,
    DelegationLock = MutationNames.DelegationLock,
    DelegationUnlock = MutationNames.DelegationUnlock,
    DelegationsImportResult = MutationNames.DelegationsImportResult,
    DomainDkimVerificationRetry = MutationNames.DomainDkimVerificationRetry,
    EmailEventInviteResend = MutationNames.EmailEventInviteResend,
    EmailFormInviteSend = MutationNames.EmailFormInviteSend,
    EmailOrganizationInviteResend = MutationNames.EmailOrganizationInviteResend,
    EmailResend = MutationNames.EmailResend,
    EmailSenderVerificationResend = MutationNames.EmailSenderVerificationResend,
    EventDocumentsExport = MutationNames.EventDocumentsExport,
    EventDocumentsExportResult = MutationNames.EventDocumentsExportResult,
    EventLayerUpdate = MutationNames.EventLayerUpdate,
    EventLinksUpdate = MutationNames.EventLinksUpdate,
    EventProfilePrivateFieldsUpdate = MutationNames.EventProfilePrivateFieldsUpdate,
    ExportResult = MutationNames.ExportResult,
    FormRegister = MutationNames.FormRegister,
    LoginCodeEmailCreate = MutationNames.LoginCodeEmailCreate,
    OrganizationArchive = MutationNames.OrganizationArchive,
    OrganizationProfilePrivateFieldsUpdate = MutationNames.OrganizationProfilePrivateFieldsUpdate,
    PositionUpdateColor = MutationNames.PositionUpdateColor,
    SegmentFolderMoveDown = MutationNames.SegmentFolderMoveDown,
    SegmentFolderMoveUp = MutationNames.SegmentFolderMoveUp,
    SegmentMoveDown = MutationNames.SegmentMoveDown,
    SegmentMoveUp = MutationNames.SegmentMoveUp,
    SegmentsFoldersReorder = MutationNames.SegmentsFoldersReorder,
    SkidataConnectionInfoSeaonEventAdd = MutationNames.SkidataConnectionInfoSeaonEventAdd,
    SkidataConnectionInfoSeaonEventDelete = MutationNames.SkidataConnectionInfoSeaonEventDelete,
    SkidataConnectionInfoSynchronizedEventAdd = MutationNames.SkidataConnectionInfoSynchronizedEventAdd,
    SkidataConnectionInfoSynchronizedEventDelete = MutationNames.SkidataConnectionInfoSynchronizedEventDelete,
    SkidataSynchronize = MutationNames.SkidataSynchronize,
    TextMessageRepliesRead = MutationNames.TextMessageRepliesRead,
    TranslationUpdate = MutationNames.TranslationUpdate,
    UpdateState = MutationNames.UpdateState,
    UserCreate = MutationNames.UserCreate,
    UserDelete = MutationNames.UserDelete,
    UserInfoAdminDelete = MutationNames.UserInfoAdminDelete,
    UserInfoAndTermsUpdate = MutationNames.UserInfoAndTermsUpdate,
    UserInfoDelegationDelete = MutationNames.UserInfoDelegationDelete,
    UserOrganizationUpdate = MutationNames.UserOrganizationUpdate,
    UserPositionCategoryUpdate = MutationNames.UserPositionCategoryUpdate,
    UserPositionUpdate = MutationNames.UserPositionUpdate,
    UserProfileUpdate = MutationNames.UserProfileUpdate,
    UsersInfosDelegationAdd = MutationNames.UsersInfosDelegationAdd,
    UsersInfosExport = MutationNames.UsersInfosExport,
    VolunteerRegistrationAccreditationStateUpdate = MutationNames.VolunteerRegistrationAccreditationStateUpdate,
    VolunteerRegistrationCreate = MutationNames.VolunteerRegistrationCreate,
    VolunteersExport = MutationNames.VolunteersExport,
    VolunteersImportResult = MutationNames.VolunteersImportResult,
    WeezeventVolunteerSynchronize = MutationNames.WeezeventVolunteerSynchronize,
    WeezeventVolunteersSynchronize = MutationNames.WeezeventVolunteersSynchronize
}
