import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import {
    AccreditationPositionProperty,
    AccreditationsAccreditationFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsTagsCellInput } from './accreditationsTagsCellInput';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsTagsCellProps {
    accreditation: AccreditationsAccreditationFragment;
    firstCellCss: any;

    reload(): void;
}

export const AccreditationsTagsCell = (props: IAccreditationsTagsCellProps) => {
    const badges = React.useMemo(() => props.accreditation.tags.map((tag) => ({
            id: tag.id,
            color: 'gray',
            icon: null,
            text: tag.name
        })), [props.accreditation.tags]);

    return (
        <AccreditationsUpdateCell
            accreditation={props.accreditation}
            cellCss={props.firstCellCss}
            initialValue={props.accreditation.tags.map((t) => t.id)}
            property={AccreditationPositionProperty.Tag}
            reload={props.reload}
            renderInput={(value, setValue) => <AccreditationsTagsCellInput value={value} setValue={setValue} />}
        >
            <BadgesPlus badges={badges} />
        </AccreditationsUpdateCell>
    );
};
