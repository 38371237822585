import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, SendAssignmentsEmailsInput } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useSendAssignmentsEmailsMutation } from '../../generated/graphqlHooks';

interface ISendAssignmentsEmailsConfirmationModalProps {
    eventId: EventId;
    numberOfEmails: number;
    sendAssignmentsEmails: SendAssignmentsEmailsInput;

    onClose(): void;
    reload(): void;
}

export const SendAssignmentsEmailsConfirmationModal = (
    props: ISendAssignmentsEmailsConfirmationModalProps
) => {
    const translate = useTranslate();
    const { mutate, isLoading } = useSendAssignmentsEmailsMutation();

    return (
        <Modal size="md" onClose={props.onClose}>
            <IconBackground color="warning" icon="triangle-exclamation" css={{ margin: 'auto' }} />

            <Spacer height="4" />

            <Box font="gray900 textLg medium" textAlign="center">
                {translate('_tes_vous_sur_d_58206', props.numberOfEmails)}
            </Box>

            <Spacer height="1" />

            <Box color="gray500" textAlign="center">
                {translate('les_membres_pr_53976')}
            </Box>

            <Spacer height="7" />

            <Flex justify="end" gap="3">
                <Button
                    isLoading={isLoading}
                    onClick={async () => {
                        await mutate({
                            eventId: props.eventId,
                            sendAssignmentsEmails: props.sendAssignmentsEmails
                        });

                        props.reload();
                        props.onClose();
                    }}
                >
                    {translate('confirmer_l_env_08749')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
