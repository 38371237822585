import { keyBy, range, sortBy, uniq } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyArray } from '../../../util/array';
import { isNonEmptyString } from '../../../util/string';

interface IBelgiumHouseBadgeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BelgiumHouseBadge = (props: IBelgiumHouseBadgeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const acronymsAndColors = vr.accreditationsUsersInfos.flatMap((aui) =>
        isNonEmptyString(aui.accreditation.acronym)
            ? [{ acronym: aui.accreditation.acronym, color: aui.accreditation.color }]
            : []
    );
    const numberAcronymsAndColors = sortBy(
        uniq(
            acronymsAndColors.flatMap((ac) =>
                isNaN(parseInt(ac.acronym, 10))
                    ? []
                    : [{ acronym: parseInt(ac.acronym, 10), color: ac.color }]
            )
        )
    );
    const numberAcronyms = numberAcronymsAndColors.map((ac) => ac.acronym);
    const colorsByNumberAcronyms = keyBy(numberAcronymsAndColors, 'acronym');
    const hasAllAccess =
        vr.accreditationsUsersInfos.some((aui) => aui.accreditation.id === 3081) ||
        range(1, 13).every((z) => numberAcronyms.includes(z));
    const days = vr.accreditationsUsersInfos.flatMap((aui) => aui.accreditationSlot.date?.day || []);

    return (
        <Flex
            height={530}
            width={333}
            css={{
                background: `url(https://assets.recrewteer.com/badges/belgiumHouse/badge.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$gothamRoundedBook',
                position: 'relative'
            }}
        >
            <Box
                width={1}
                textAlign="center"
                css={{
                    color: 'white',
                    fontFamily: '$gothamRoundedMedium',
                    fontSize: '26px',
                    fontStyle: 'normal',
                    fontWeight: '350',
                    lineHeight: 'normal',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '139px'
                }}
            >
                {ui.fields.cf33876}
            </Box>

            <Box
                height={68}
                width={62}
                css={{
                    display: 'flex',
                    justifyContent: 'center',
                    left: '30px',
                    overflow: 'hidden',
                    position: 'absolute',
                    top: '182px'
                }}
            >
                {isNonEmptyString(ui.picture?.url) && <img src={ui.picture!.url} height="100%" />}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontFamily: '$gothamRoundedMedium',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '350',
                    left: '107px',
                    lineHeight: 'normal',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '187px'
                }}
            >
                {ui.firstName}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontFamily: '$gothamRoundedMedium',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '350',
                    left: '107px',
                    lineHeight: 'normal',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '206px'
                }}
            >
                {ui.lastName}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '325',
                    left: '107px',
                    lineHeight: 'normal',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '228px'
                }}
            >
                {ui.fields.cf33875 ?? ''}
            </Box>

            {hasAllAccess && (
                <Flex
                    width="100%"
                    css={{
                        color: '#E40613',
                        fontFamily: '$gothamRoundedBold',
                        fontSize: '32px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        left: '0',
                        lineHeight: 'normal',
                        justifyContent: 'center',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '263px'
                    }}
                >
                    All access
                </Flex>
            )}

            {isNonEmptyArray(numberAcronyms) && !hasAllAccess && (
                <Flex
                    wrap="wrap"
                    width={268}
                    css={{
                        gap: '2px 8px',
                        left: '33px',
                        position: 'absolute',
                        top: '257px'
                    }}
                >
                    {range(1, 13).map((n) => (
                            <Flex
                                key={n}
                                align="center"
                                justify="center"
                                height={24}
                                width={38}
                                css={{
                                    background: numberAcronyms.includes(n)
                                        ? colorsByNumberAcronyms[n].color
                                        : '#d9d9d9',
                                    fontSize: '19px',
                                    fontStyle: 'normal',
                                    fontWeight: '325',
                                    lineHeight: '24px',
                                    textTransform: 'uppercase',
                                    visibility: numberAcronyms.includes(n) ? 'visible' : 'hidden'
                                }}
                            >
                                {n}
                            </Flex>
                        ))}
                </Flex>
            )}

            <Box
                height={67}
                width={67}
                css={{
                    background: 'white',
                    left: '35px',
                    position: 'absolute',
                    top: '323px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=67&width=67`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>

            <Flex
                wrap="wrap"
                width={190}
                css={{
                    gap: '0 6px',
                    left: '120px',
                    position: 'absolute',
                    top: '335px'
                }}
            >
                {range(26, 32)
                    .concat(range(1, 12))
                    .map((day) => (
                            <Box
                                key={day}
                                css={{
                                    fontSize: '19px',
                                    fontStyle: 'normal',
                                    fontWeight: 325,
                                    lineHeight: 'normal',
                                    textTransform: 'uppercase',
                                    visibility: days.includes(day) ? 'visible' : 'hidden'
                                }}
                            >
                                {day}
                            </Box>
                        ))}
            </Flex>
        </Flex>
    );
};
