import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { Item } from 'common-front/src/designSystem/components/dropdown/item';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { CustomFieldsQuery, OrganizationId, SegmentId } from 'common/src/generated/types';
import { useHistory, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import {
    useCustomFieldDeleteMutation,
    useCustomFieldDuplicateMutation
} from '../../generated/graphqlHooks';

interface ICustomFieldRowDropdownProps {
    customField: CustomFieldsQuery['organization']['customFields']['nodes'][0];
    organizationId: OrganizationId;
    segmentId: SegmentId;

    reload(): void;
}

export const CustomFieldRowDropdown = (props: ICustomFieldRowDropdownProps) => {
    const translate = useTranslate();
    const history = useHistory();
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const { mutate: duplicateCustomField } = useCustomFieldDuplicateMutation();
    const { mutate: deleteCustomField } = useCustomFieldDeleteMutation();
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <>
            <DropdownEllipsis isOpen={isMenuOpen} onStateChange={setIsMenuOpen}>
                <Item
                    onClick={(e) => {
                        closeOtherDropdowns(e.target);

                        history.push(
                            HeaventPaths.EDIT_CUSTOM_FIELD(
                                props.organizationId,
                                props.segmentId,
                                props.customField.id
                            )
                        );
                    }}
                >
                    <Flex align="center" gap="3">
                        <I icon="pen" />
                        {translate('_diter_62574')}
                    </Flex>
                </Item>

                {props.customField.canDelete && (
                    <Item
                        onClick={async (e) => {
                            closeOtherDropdowns(e.target);

                            const {
                                customFieldDuplicate: { id }
                            } = await duplicateCustomField({
                                organizationId: props.organizationId,
                                id: props.customField.id
                            });

                            props.reload();

                            history.push(
                                HeaventPaths.EDIT_CUSTOM_FIELD(
                                    props.organizationId,
                                    props.segmentId,
                                    id
                                )
                            );
                        }}
                    >
                        <Flex align="center" gap="3">
                            <I icon="clone" />
                            {translate('dupliquer_28256')}
                        </Flex>
                    </Item>
                )}

                {props.customField.canDelete && (
                    <Item
                        color="red"
                        onClick={() => {
                            setIsMenuOpen(false);
                            setIsDeleteOpen(true);
                        }}
                    >
                        <Flex align="center" gap="3">
                            <I icon="trash-can" />
                            {translate('supprimer_43083')}
                        </Flex>
                    </Item>
                )}
            </DropdownEllipsis>

            {isDeleteOpen && (
                <DeleteModal
                    text={translate('suppression_de_73669', props.customField.name)}
                    subtext={translate('_tes_vous_s_r_d_96670')}
                    buttonText={translate('supprimer_43083')}
                    onDelete={async () => {
                        await deleteCustomField({
                            organizationId: props.organizationId,
                            id: props.customField.id
                        });

                        props.reload();
                    }}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                />
            )}
        </>
    );
};
