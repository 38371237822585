import { Empty } from 'common-front/src/components/empty/empty';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import { OrganizationsPaths } from 'common/src/util/paths/organizationsPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Community } from '../community/community';
import { CustomFieldsLoader } from '../customFields/list/customFieldsLoader';
import { CreateEvent, UpdateEvent } from '../events/create/createUpdateEvent';
import { EventsLoader } from '../events/list/eventsLoader';
import { EventsMobile } from '../events/mobile/eventsMobile';
import { Event } from '../events/show/event';
import {
    CreateEmailSender,
    UpdateEmailSender
} from '../organizationParameters/emails/senders/create/createUpdateEmailSender';
import { EmailSenderPreview } from '../organizationParameters/emails/senders/preview/emailSenderPreview';
import { OrganizationParametersMobile } from '../organizationParameters/mobile/organizationParametersMobile';
import { OrganizationParameters } from '../organizationParameters/organizationParameters';
import { UserProfile } from '../users/profile/userProfile';
import { ArchiveOrganization } from './archiveOrganization';
import { OrganizationBlocked } from './blocked/organizationBlocked';
import { OrganizationExpired } from './expired/organizationExpired';
import { OrganizationComponent } from './organizationComponent';
import { OrganizationContextProvider, useOrganizationContext } from './organizationContext';
import { AcceptTerms } from './terms/acceptTerms';

const OrganizationRoutes = () => {
    const { organization } = useOrganizationContext();
    const isOrganizationAdmin = organization.permissions.isOrganizationAdmin;
    const onMobile = useMobileQuery();

    return (
        <Switch>
            {isOrganizationAdmin && (
                <Route
                    exact
                    path={HeaventPaths.CREATE_EVENT(':organizationId')}
                    children={<CreateEvent />}
                />
            )}

            {isOrganizationAdmin && (
                <Route
                    exact
                    path={HeaventPaths.EDIT_EVENT(':organizationId', ':eventId')}
                    children={<UpdateEvent />}
                />
            )}

            <Route
                path={HeaventPaths.EVENTS(':organizationId')}
                children={
                    onMobile ? (
                        <EventsMobile />
                    ) : (
                        <OrganizationComponent>
                            <EventsLoader />
                        </OrganizationComponent>
                    )
                }
            />

            <Route path={HeaventPaths.EVENT(':organizationId', ':eventId')} children={<Event />} />

            {isOrganizationAdmin && (
                <Route
                    path={HeaventPaths.CUSTOM_FIELDS(':organizationId')}
                    children={
                        <OrganizationComponent>
                            <CustomFieldsLoader />
                        </OrganizationComponent>
                    }
                />
            )}

            {isOrganizationAdmin && (
                <Route
                    path={HeaventPaths.CREATE_ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                        ':organizationId'
                    )}
                    children={<CreateEmailSender />}
                />
            )}

            {isOrganizationAdmin && (
                <Route
                    path={HeaventPaths.EDIT_ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                        ':organizationId',
                        ':emailSenderId'
                    )}
                    children={<UpdateEmailSender />}
                />
            )}

            {isOrganizationAdmin && (
                <Route
                    path={HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS_PREVIEW(
                        ':organizationId',
                        ':emailSenderId'
                    )}
                    children={<EmailSenderPreview />}
                />
            )}

            <Route
                path={HeaventPaths.ORGANIZATION_PARAMETERS(':organizationId')}
                children={
                    onMobile ? (
                        <OrganizationParametersMobile />
                    ) : (
                        <OrganizationComponent>
                            <OrganizationParameters />
                        </OrganizationComponent>
                    )
                }
            />

            <Route
                path={HeaventPaths.COMMUNITY_USER_PROFILE(':organizationId', ':userInfoId')}
                children={
                    <OrganizationComponent>
                        <UserProfile />
                    </OrganizationComponent>
                }
            />

            <Route path={CommunityPaths.COMMUNITY(':organizationId')} children={<Community />} />

            {isOrganizationAdmin && (
                <Route
                    exact
                    path={OrganizationsPaths.ARCHIVE_ORGANIZATION(':organizationId')}
                    children={<ArchiveOrganization />}
                />
            )}

            <Route
                path={OrganizationsPaths.ORGANIZATION_IS_EXPIRED(':organizationId')}
                children={
                    <OrganizationComponent>
                        <OrganizationExpired />
                    </OrganizationComponent>
                }
            />

            <Route
                path={OrganizationsPaths.ORGANIZATION_IS_BLOCKED(':organizationId')}
                children={
                    <OrganizationComponent>
                        <OrganizationBlocked />
                    </OrganizationComponent>
                }
            />

            <Route
                exact
                path={HeaventPaths.ACCEPT_TERMS_AND_CONDITIONS(':organizationId')}
                children={<AcceptTerms />}
            />

            <Route
                children={<Empty path={HeaventPaths.EVENTS(organization.id)} replace={true} />}
            />
        </Switch>
    );
};

export const Organization = () => {
    const { organizationId } = useParams();

    return (
        <OrganizationContextProvider organizationId={organizationId}>
            <OrganizationRoutes />
        </OrganizationContextProvider>
    );
};
