import { Blank } from 'common-front/src/components/blank/blank';
import { Svgs } from 'common-front/src/util/assets';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const VolunteersListBlankMobile = () => {
    const translate = useTranslate();

    return (
        <Blank
            imageSrc={Svgs.MembersBlank}
            title={translate('il_n_y_a_pas_de_22623')}
            subtitle={translate('diffusez_le_for_36054')}
            css={{ flex: 1, padding: '0 $4' }}
        />
    );
};
