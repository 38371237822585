import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Images } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, OrganizationId, UserEmail } from 'common/src/generated/types';
import {
    IVolunteerRegistrationAdminCreateValues,
    VolunteerRegistrationInputService
} from 'common/src/input/volunteerRegistrationInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useUserInfoAdminCreateMutation } from '../../generated/graphqlHooks';

interface ICreateNewVolunteerProps {
    organizationId: OrganizationId;
    eventId?: EventId;
    showSendEmail: boolean;
    subtitle: string;

    onClose(): void;
    reload(): void;
}

export const CreateNewVolunteer = (props: ICreateNewVolunteerProps) => {
    const translate = useTranslate();
    const volunteerRegistrationInput = useService(VolunteerRegistrationInputService);
    const validateService = useService(ValidateService);
    const { mutate, isLoading } = useUserInfoAdminCreateMutation();

    return (
        <Form
            hideErrors={true}
            initialValues={{
                email: '' as UserEmail,
                sendVolunteerRegistrationEmail: false,
                firstName: '',
                lastName: ''
            }}
            validate={validateService.validateForForm(
                volunteerRegistrationInput.adminCreateSchema()
            )}
            onSubmit={async (values: IVolunteerRegistrationAdminCreateValues) => {
                await mutate({
                    organizationId: props.organizationId,
                    eventId: props.eventId,
                    email: values.email,
                    sendVolunteerRegistrationEmail: values.sendVolunteerRegistrationEmail,
                    firstName: values.firstName,
                    lastName: values.lastName
                });

                props.reload();
                props.onClose();
            }}
            render={({ handleSubmit }) => (
                    <Modal size="md" onClose={props.onClose}>
                        <>
                            <Box color="gray800" css={{ height: '56px', textAlign: 'center' }}>
                                <img alt="Avatars" src={Images.AvatarGroup} height="100%" />
                            </Box>

                            <Spacer height="6" />

                            <Box
                                color="gray800"
                                fontSize="textLg"
                                fontWeight="medium"
                                textAlign="center"
                            >
                                {translate('cr_er_un_nouvea_99331')}
                            </Box>

                            <Spacer height="1" />

                            <Box color="gray500" textAlign="center">
                                {props.subtitle}
                            </Box>

                            <Spacer height="6" />

                            <FormErrors />

                            <TextInput label={translate('Email')} name="email" isEmail={true} />

                            <Spacer height="4" />

                            <Flex gap="4">
                                <TextInput
                                    label={
                                        <LabelOptional>{translate('pr_nom_66988')}</LabelOptional>
                                    }
                                    name="firstName"
                                />

                                <TextInput
                                    label={<LabelOptional>{translate('last_name')}</LabelOptional>}
                                    name="lastName"
                                />
                            </Flex>

                            <Spacer height="7" />

                            <Flex align="center" justify="end" gap="3">
                                {props.showSendEmail && (
                                    <CheckboxText name="sendVolunteerRegistrationEmail">
                                        {translate('envoyer_l_e_mai_35622')}
                                    </CheckboxText>
                                )}

                                <Button isLoading={isLoading} onClick={handleSubmit}>
                                    {translate('cr_er_82895')}
                                </Button>

                                <Button color="white" onClick={props.onClose}>
                                    {translate('annuler_48254')}
                                </Button>
                            </Flex>
                        </>
                    </Modal>
                )}
        />
    );
};
