import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (population: string) => {
    switch (population) {
        case 'Cavaliers':
        case 'Groom':
            return 'https://assets.recrewteer.com/badges/jivahill/v2/Violet_Recto.jpg';
        case 'Staff':
        case 'Staff interne':
            return 'https://assets.recrewteer.com/badges/jivahill/v2/Bleu_clair_Recto.jpg';
        case 'Officiels':
        case 'Juges':
        case 'Secrétaires de juges':
            return 'https://assets.recrewteer.com/badges/jivahill/v2/Bleu_Recto.jpg';
        case 'Bénévoles':
            return 'https://assets.recrewteer.com/badges/jivahill/v2/Vert_Recto.png';
        default:
            return '';
    }
};

const getColor = (population: string) => {
    switch (population) {
        case 'Cavaliers':
        case 'Groom':
            return '#4a0a58';
        case 'Staff':
        case 'Staff interne':
            return '#7687a3';
        case 'Officiels':
        case 'Juges':
        case 'Secrétaires de juges':
            return '#1d2b52';
        case 'Bénévoles':
            return '#658172';
        default:
            return '';
    }
};

interface IBadgeJivahillProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeJivahill = (props: IBadgeJivahillProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const accreditationAccess = vr.accreditationsUsersInfos.find(
        (aui) => aui.accreditationCategory.id === 1640
    )?.accreditation.name;
    const accreditationRepas = vr.accreditationsUsersInfos.find(
        (aui) => aui.accreditationCategory.id === 1606
    )?.accreditation.name;
    const days = vr.accreditationsUsersInfos
        .filter((aui) => aui.accreditation.id === 3407)
        .map((aui) => aui.accreditationSlot.name);
    const population = ui.jivahillPopulation || 'Bénévoles';

    return (
        <>
            <Flex
                height={559}
                width={397}
                css={{
                    background: `url(${getBackground(population)}) no-repeat`,
                    backgroundSize: 'cover',
                    color: 'white',
                    fontFamily: '$arimo',
                    position: 'relative'
                }}
            >
                <Box
                    height={90}
                    width={90}
                    css={{
                        left: '67px',
                        position: 'absolute',
                        top: '222px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    css={{
                        fontSize: '18px',
                        left: '166px',
                        position: 'absolute',
                        top: '223px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        fontSize: '18px',
                        left: '166px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '245px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    css={{
                        fontSize: '16px',
                        fontWeight: '600',
                        left: '166px',
                        position: 'absolute',
                        top: '272px'
                    }}
                >
                    {accreditationAccess}
                </Box>

                <Box
                    css={{
                        fontSize: '16px',
                        fontWeight: '600',
                        left: '166px',
                        position: 'absolute',
                        top: '294px'
                    }}
                >
                    {accreditationRepas}
                </Box>

                <Flex
                    css={{
                        gap: '$4',
                        left: '67px',
                        position: 'absolute',
                        top: '362px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={29}
                        width={29}
                        css={{
                            background: getColor(population),
                            fontSize: '18px',
                            fontWeight: '700',
                            visibility: days.includes('Ma') ? 'visible' : 'hidden'
                        }}
                    >
                        M
                    </Flex>

                    <Flex
                        align="center"
                        justify="center"
                        height={29}
                        width={29}
                        css={{
                            background: getColor(population),
                            fontSize: '18px',
                            fontWeight: '700',
                            visibility: days.includes('Me') ? 'visible' : 'hidden'
                        }}
                    >
                        M
                    </Flex>

                    <Flex
                        align="center"
                        justify="center"
                        height={29}
                        width={29}
                        css={{
                            background: getColor(population),
                            fontSize: '18px',
                            fontWeight: '700',
                            visibility: days.includes('J') ? 'visible' : 'hidden'
                        }}
                    >
                        J
                    </Flex>

                    <Flex
                        align="center"
                        justify="center"
                        height={29}
                        width={29}
                        css={{
                            background: getColor(population),
                            fontSize: '18px',
                            fontWeight: '700',
                            visibility: days.includes('V') ? 'visible' : 'hidden'
                        }}
                    >
                        V
                    </Flex>

                    <Flex
                        align="center"
                        justify="center"
                        height={29}
                        width={29}
                        css={{
                            background: getColor(population),
                            fontSize: '18px',
                            fontWeight: '700',
                            visibility: days.includes('S') ? 'visible' : 'hidden'
                        }}
                    >
                        S
                    </Flex>

                    <Flex
                        align="center"
                        justify="center"
                        height={29}
                        width={29}
                        css={{
                            background: getColor(population),
                            fontSize: '18px',
                            fontWeight: '700',
                            visibility: days.includes('D') ? 'visible' : 'hidden'
                        }}
                    >
                        D
                    </Flex>
                </Flex>

                <Box
                    height={46}
                    width={46}
                    css={{
                        bottom: '12px',
                        left: '12px',
                        position: 'absolute'
                    }}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=ffffff&height=46&width=46`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>
            </Flex>

            <Flex
                height={559}
                width={397}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/jivahill/Verso.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />
        </>
    );
};
