import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { FileS3Input } from 'common-front/src/designSystem/form/file/fileS3Input';
import { Accept } from 'common-front/src/util/accept';
import { Flex } from 'common/src/designSystem/components/flex';
import { MAX_CSV_SIZE } from 'common/src/input/csvInput';
import {
    DelegationsImportInput,
    DelegationsImportInputService
} from 'common/src/input/delegationsImportInput';
import { NumberService } from 'common/src/services/numberService';
import { ValidateService } from 'common/src/services/validateService';
import { useParams, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useDelegationsImportMutation } from '../../generated/graphqlHooks';
import { useNotificationContext } from '../../notifications/notificationContext';
import { DelegationsImportProcessingAlert } from './delegationsImportProssessingAlert';

const removeFileFromInput = (
    input: DelegationsImportInput & {
        csv: {
            file?: File;
        };
    }
) => {
    const csvCopy = { ...input.csv };

    delete csvCopy.file;

    return {
        ...input,
        csv: csvCopy
    };
};

export const DelegationsImport = ({ fromPath }: { fromPath: string }) => {
    const translate = useTranslate();
    const delegationsImportInput = useService(DelegationsImportInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const { eventId, organizationId } = useParams();
    const { mutate } = useDelegationsImportMutation();
    const { checkDelegationsImport } = useNotificationContext();

    return (
        <Form
            height={1}
            initialValues={{ input: delegationsImportInput.default().input }}
            validate={validateService.validateForForm(delegationsImportInput.schema())}
            onSubmit={async ({ input }) => {
                const inputWithoutFile = removeFileFromInput(input);

                try {
                    const { jobId } = await mutate({
                        eventId,
                        organizationId,
                        input: inputWithoutFile
                    });
                    if (centeredContainerRef.current) {
                        centeredContainerRef.current.scrollTop = 0;
                    }
                    checkDelegationsImport(organizationId, jobId);
                } catch (e) {
                    /* Already displayed in <FormErrors />. */
                }
            }}
            hideErrors={true}
            onShowErrors={() => {
                if (centeredContainerRef.current) {
                    centeredContainerRef.current.scrollTop = 0;
                }
            }}
            render={({ form, handleSubmit, submitting }) => (
                    <FullScreenPopup
                        color="dark"
                        category={translate('import_de_d_l_g_69356')}
                        title={translate('nouvel_import_48306')}
                        closePath={fromPath}
                        button={
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                {translate('importer_la_lis_12866')}
                            </Button>
                        }
                    >
                        <CenteredContainer ref={centeredContainerRef}>
                            {form.getState().submitSucceeded && (
                                <DelegationsImportProcessingAlert />
                            )}
                            <FormErrors />

                            <Flex direction="column" gap="5">
                                <FormBox
                                    hideToggle={true}
                                    initialIsOpen={true}
                                    subtitle={translate(
                                        's_lectionnez_vo_125161',
                                        NumberService.bytesToGigabytes(MAX_CSV_SIZE)
                                    )}
                                    title={translate('votre_fichier_d_51776')}
                                >
                                    <FileS3Input
                                        accept={Accept.Csv}
                                        acl="private"
                                        prefix="input.csv."
                                        withFileField={true}
                                    />
                                </FormBox>
                            </Flex>
                        </CenteredContainer>
                    </FullScreenPopup>
                )}
        />
    );
};
