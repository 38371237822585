import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { BadgeRecrewdaysA6Back } from './badgeRecrewdaysA6Back';
import { BadgeRecrewdaysA6Front } from './badgeRecrewdaysA6Front';

interface IRecrewdaysA6Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeRecrewdaysA6 = (props: IRecrewdaysA6Props) => (
        <>
            <BadgeRecrewdaysA6Front event={props.event} />

            <BadgeRecrewdaysA6Back event={props.event} />
        </>
    );
