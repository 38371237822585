import { compact, sortBy, uniqBy } from 'lodash-es';
import { Interval } from 'luxon';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { RichText } from '../../designSystem/components/richEditor/richText';
import { AssigmentEmailFragment, PositionPdfInfosFragment } from '../../generated/types';
import { IntervalService } from '../../services/intervalService';
import { useEnvVars, useService, useTranslate } from '../../util/dependencies/dependencies';
import { Emptyable } from '../../util/emptyable';
import { googleMapsHref } from '../../util/googleMaps';
import { isNonEmptyString } from '../../util/string';
import { leaderInfos } from '../../vo/leader';
import { hasAddress, hasAddressOrCoordinates, hasCoordinates } from '../../vo/position';

type PositionRange = {
    name: Emptyable<string>;
    range: Interval;
};

type HideResourcesPositionsInfosProps = {
    displayPositionName: true;
    leadersData: string;
    position: AssigmentEmailFragment['position'];
    ranges: PositionRange[];
    showResourcesInfos: false;
};

type ShowResourcesPositionsInfosProps = {
    displayPositionName: false;
    leadersData: string;
    position: PositionPdfInfosFragment;
    ranges: PositionRange[];
    showResourcesInfos: true;
};

type IPositionSheetInfosProps = HideResourcesPositionsInfosProps | ShowResourcesPositionsInfosProps;

export const PositionSheetsInfos = (props: IPositionSheetInfosProps) => {
    const translate = useTranslate();
    const { HEAVENT_EXTERNAL_DOMAIN } = useEnvVars();
    const intervalService = useService(IntervalService);
    const ranges = React.useMemo(
        () =>
            sortBy(props.ranges, (r) => r.range.start!.toMillis()).map(({ name, range }) =>
                compact([
                    isNonEmptyString(name) ? name : null,
                    intervalService.toDisplayString(range, { formats: { displayWeekday: true } })
                ]).join(' - ')
            ),
        [props.ranges]
    );
    const leaders = uniqBy(
        props.position.leaders.concat(props.position.category.leaders),
        (l) => l.userInfo.id
    );

    return (
        <Flex
            direction="column"
            gap="6"
            css={{
                background: 'white',
                padding: '$7 56px'
            }}
        >
            {props.displayPositionName && (
                <Box color="gray800" fontSize="textMd" fontWeight="medium">
                    {props.position.category.name} | {props.position.name}
                </Box>
            )}

            <Flex gap="9">
                <Box color="gray500" width={150}>
                    {translate('date_de_la_miss_70346')}
                </Box>
                <Flex direction="column" css={{ flex: '1' }}>
                    {ranges.map((range, index) => (
                        <Box key={index} color="gray800" css={{ textTransform: 'capitalize' }}>
                            {range}
                        </Box>
                    ))}
                </Flex>
            </Flex>

            {leaders.map((leader, index) => (
                <Flex key={index} gap="9">
                    <Box color="gray500" width={150}>
                        {translate('reponsable_82458')}
                    </Box>
                    <Box
                        color="gray800"
                        css={{ flex: '1' }}
                        dangerouslySetInnerHTML={{
                            __html: leaderInfos(leader, props.leadersData)
                        }}
                    />
                </Flex>
            ))}

            {isNonEmptyString(props.position.description) && (
                <Flex gap="9">
                    <Box color="gray500" width={150}>
                        {translate('description_58935')}
                    </Box>
                    <Box css={{ flex: '1' }}>
                        <RichText text={props.position.description} />
                    </Box>
                </Flex>
            )}

            {hasAddressOrCoordinates(props.position) && (
                <Flex gap="9">
                    <Box color="gray500" width={150}>
                        {translate('localisation_38416')}
                    </Box>
                    <Box color="gray800" css={{ flex: '1' }}>
                        {hasAddress(props.position) && props.position.address}

                        {hasCoordinates(props.position) && (
                            <a
                                href={googleMapsHref(
                                    props.position.latitude!,
                                    props.position.longitude!
                                )}
                            >
                                <img
                                    src={`https://api.${HEAVENT_EXTERNAL_DOMAIN}/image/event/${props.position.eventId}/position/${props.position.id}.png`}
                                    width="100%"
                                    alt="Position map"
                                />
                            </a>
                        )}
                    </Box>
                </Flex>
            )}

            {props.showResourcesInfos && (
                <>
                    <Flex gap="9">
                        <Box color="gray500" width={150}>
                            {translate('nombre_de_cr_ne_40593')}
                        </Box>
                        <Box color="gray800" css={{ flex: '1' }}>
                            {props.position.numberOfSlots}
                        </Box>
                    </Flex>

                    <Flex gap="9">
                        <Box color="gray500" width={150}>
                            {translate('nombre_de_membr_08851')}
                        </Box>
                        <Box color="gray800" css={{ flex: '1' }}>
                            {props.position.assignedResources}
                        </Box>
                    </Flex>
                </>
            )}
        </Flex>
    );
};
