import { uniqBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Avatar } from '../../../components/avatar/avatar';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { AccreditationsSlotId, DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';
import { JardinMichelAccreditation } from './jardinMichelAccreditation';

const getFrontBackground = (profil: string, isMinor: boolean) => {
    switch (profil) {
        case 'STAFF':
            return 'https://assets.recrewteer.com/badges/jardinmichel/Staff_recto.png';
        case 'ORGANISATION':
            return 'https://assets.recrewteer.com/badges/jardinmichel/Orga_recto.png';
        case 'TECHNICIEN':
            return 'https://assets.recrewteer.com/badges/jardinmichel/Technicien_recto.png';
        case 'ASSOCIATION':
            return 'https://assets.recrewteer.com/badges/jardinmichel/Asso_recto.png';
        case 'EXPOSANT':
            return 'https://assets.recrewteer.com/badges/jardinmichel/Exposant_recto.png';
        case 'RESTAURATEUR':
            return 'https://assets.recrewteer.com/badges/jardinmichel/Restaurateur_recto.png';
        case 'MEDIA':
            return 'https://assets.recrewteer.com/badges/jardinmichel/Media_recto.png';
        case 'ÉLU':
            return 'https://assets.recrewteer.com/badges/jardinmichel/elu_recto.png';
        case 'PRESTATAIRE':
            return 'https://assets.recrewteer.com/badges/jardinmichel/Presta_recto.png';
        default:
            if (isMinor) {
                return 'https://assets.recrewteer.com/badges/jardinmichel/Benevole_mineur_recto_v2.png';
            } else {
                return 'https://assets.recrewteer.com/badges/jardinmichel/Benevole_recto.png';
            }
    }
};

const getText = (
    profil: string,
    ui: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo'],
    psui: any
) => {
    switch (profil) {
        case 'STAFF':
            return ui.jardinMichelcf29044;
        case 'ORGANISATION':
            return ui.jardinMichelcf29043;
        case 'TECHNICIEN':
            return ui.jardinMichelcf26833;
        case 'ASSOCIATION':
        case 'EXPOSANT':
        case 'RESTAURATEUR':
            return ui.jardinMichelcf26833;
        case 'MEDIA':
            return ui.jardinMichelcf26833;
        case 'ÉLU':
            return '';
        case 'PRESTATAIRE':
            return ui.jardinMichelcf26833;
        default:
            return isNonEmptyString(psui?.position?.fields?.cf29159)
                ? psui.position.fields.cf29159
                : psui?.position?.name ?? '';
    }
};

const FRIDAY_ACCRED_ID = 4774 as AccreditationsSlotId;
const SATURDAY_ACCRED_ID = 4775 as AccreditationsSlotId;
const SUNDAY_ACCRED_ID = 4776 as AccreditationsSlotId;

interface IBadgeJardinMichelFrontProps {
    displayBorder?: boolean;
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeJardinMichelFront = (props: IBadgeJardinMichelFrontProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const psui = uniqBy(vr.positionsSlotsUsersInfos, (psui) => psui.position.id)[0];
    const accreditationsSlotsIds = vr.accreditationsUsersInfos.map(
        (aui) => aui.accreditationSlot.id
    );
    const hasFriday = accreditationsSlotsIds.includes(FRIDAY_ACCRED_ID);
    const hasSaturday = accreditationsSlotsIds.includes(SATURDAY_ACCRED_ID);
    const hasSunday = accreditationsSlotsIds.includes(SUNDAY_ACCRED_ID);
    const dob = DateTime.fromISO(ui.fields.dateOfBirth, { zone: 'utc' });

    return (
        <Flex
            height={378}
            width={283}
            css={{
                background: `url(${getFrontBackground(
                    ui.jardinMichelProfil,
                    dob.isValid &&
                        dob >= DateTime.fromFormat('2006-05-31', 'yyyy-MM-dd', { zone: 'utc' })
                )}) no-repeat`,
                backgroundSize: 'cover',
                border: props.displayBorder ? '0.5px solid black' : '',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    color: '#132439',
                    fontFamily: '$inter',
                    fontSize: '14px',
                    fontWeight: '600',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '63px',
                    width: '100%'
                }}
            >
                {getText(ui.jardinMichelProfil, ui, psui)}
            </Box>

            <Box
                css={{
                    height: '96px',
                    left: '87px',
                    position: 'absolute',
                    top: '110px',
                    width: '110px'
                }}
            >
                <Avatar size={110} name={ui.name} email={ui.email} image={ui.picture?.url} />
            </Box>

            <Flex
                direction="column"
                justify="center"
                css={{
                    fontFamily: '$inter',
                    gap: '10px',
                    left: '237px',
                    position: 'absolute',
                    top: '101px'
                }}
            >
                <JardinMichelAccreditation color="#ea5198" isSelected={hasFriday} text="Ven" />

                <JardinMichelAccreditation color="#ffd64f" isSelected={hasSaturday} text="Sam" />

                <JardinMichelAccreditation color="#00c0f3" isSelected={hasSunday} text="Dim" />
            </Flex>

            <Box
                css={{
                    color: 'black',
                    fontFamily: '$inter',
                    fontSize: '16px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    top: '232px',
                    width: '100%'
                }}
            >
                {ui.firstName}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontFamily: '$inter',
                    fontSize: '16px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    top: '253px',
                    width: '100%'
                }}
            >
                {ui.lastName}
            </Box>
        </Flex>
    );
};
