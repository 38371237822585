import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { UsersInfoId } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import * as React from 'react';
import { useDocumentsAssignedUsersQuery } from '../../generated/graphqlHooks';

interface IDocumentsAssignedUserSelectorProps {
    getPath(userInfoId: UsersInfoId): string;
}

export const DocumentsAssignedUserSelector = (props: IDocumentsAssignedUserSelectorProps) => {
    const {
        history,
        translate,
        params: { eventId, userInfoId }
    } = useHeavent();
    const { data, isLoading } = useDocumentsAssignedUsersQuery({ eventId });

    return (
        <Flex
            align="center"
            gap="4"
            css={{
                background: '$gray100',
                border: '1px solid $gray200',
                borderBottom: 'none',
                padding: '$3 $7'
            }}
        >
            <Box font="gray900 textMd semiBold">{translate('aper_u_pour_le_81451')}</Box>

            <Box width={320}>
                {isLoading ? (
                    <Skeleton height={40} width={1} borderRadius="$1" />
                ) : (
                    <RichSelect
                        isSearchVisible={true}
                        values={[userInfoId]}
                        onChange={(newValue) => {
                            if (isNonEmptyArray(newValue)) {
                                history.push(props.getPath(newValue[0]));
                            }
                        }}
                    >
                        {data.event.volunteersRegistrations.nodes.map((vr) => (
                                <option key={vr.userInfo.id} value={vr.userInfo.id}>
                                    {vr.userInfo.name}
                                </option>
                            ))}
                    </RichSelect>
                )}
            </Box>
        </Flex>
    );
};
