import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

const getBackground = (statut: string) => {
    switch (statut) {
        case 'Partenaire/Exposant':
            return 'https://assets.recrewteer.com/badges/reims/reimsPartenairesVerso.jpg';
        case 'Presse':
            return 'https://assets.recrewteer.com/badges/reims/reimsPressVerso.png';
        case 'VIP':
            return 'https://assets.recrewteer.com/badges/reims/reimsVIPVerso.png';
        case 'Staff':
            return 'https://assets.recrewteer.com/badges/reims/reimsStaffVerso.png';
        case 'Prestataire':
            return 'https://assets.recrewteer.com/badges/reims/reimsPrestataireVerso.png';
        default:
            return 'https://assets.recrewteer.com/badges/reims/reimsBenevolesVerso.png';
    }
};

interface IBadgeReimsBackProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeReimsBack = (props: IBadgeReimsBackProps) => {
    const userInfo = props.event.volunteerRegistration.userInfo;

    return (
        <Flex
            height={597}
            width={435}
            css={{
                background: `url(${getBackground(userInfo.reimsStatut)}) no-repeat`,
                backgroundSize: 'cover',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        />
    );
};
