import { ToggleText } from 'common-front/src/designSystem/components/toggle';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId } from 'common/src/generated/types';
import { IUpdateFormParametersAccreditationsValues } from 'common/src/input/formParametersAccreditationsInput';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormParametersAccreditationsHiddenDisplayedSelects } from './formParametersAccreditationsHiddenDisplayedSelects';

interface IFormParametersAccreditationsHiddenDisplayedProps {
    eventId: EventId;
    values: IUpdateFormParametersAccreditationsValues;

    change(name: string, value: any): void;
}

export const FormParametersAccreditationsHiddenDisplayed = (
    props: IFormParametersAccreditationsHiddenDisplayedProps
) => {
    const translate = useTranslate();
    const [isOpen, setIsOpen] = React.useState(
        isNonEmptyArray(props.values.form.hiddenAccreditationsCategoriesIds) ||
            isNonEmptyArray(props.values.form.hiddenAccreditationsIds) ||
            isNonEmptyArray(props.values.form.hiddenAccreditationsSlotsIds) ||
            isNonEmptyArray(props.values.form.displayedAccreditationsCategoriesIds) ||
            isNonEmptyArray(props.values.form.displayedAccreditationsIds) ||
            isNonEmptyArray(props.values.form.displayedAccreditationsSlotsIds)
    );

    return (
        <>
            <ToggleText
                value={isOpen}
                onChange={(value) => {
                    setIsOpen(value);

                    if (!value) {
                        props.change('form.hiddenAccreditationsCategoriesIds', []);
                        props.change('form.hiddenAccreditationsIds', []);
                        props.change('form.hiddenAccreditationsSlotsIds', []);
                        props.change('form.displayedAccreditationsCategoriesIds', []);
                        props.change('form.displayedAccreditationsIds', []);
                        props.change('form.displayedAccreditationsSlotsIds', []);
                    }
                }}
            >
                {translate('afficher_masq_37398')}
            </ToggleText>

            {isOpen && (
                <>
                    <Spacer height="4" />

                    <FormParametersAccreditationsHiddenDisplayedSelects
                        eventId={props.eventId}
                        values={props.values}
                        change={props.change}
                    />
                </>
            )}
        </>
    );
};
