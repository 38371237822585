import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Position } from 'common/src/generated/types';
import * as React from 'react';
import { usePositionDeleteMutation } from '../generated/graphqlHooks';

interface IDeletePositionProps {
    position: Pick<Position, 'id' | 'name' | 'assignedResources'>;

    onSuccess(): void;
    onClose(): void;
}

export const DeletePosition = (props: IDeletePositionProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const { mutate } = usePositionDeleteMutation();
    const extraContent: string[] = [];

    if (props.position.assignedResources > 0) {
        extraContent.push(translate('_1_membre_est_66702', props.position.assignedResources));
    }

    return (
        <DeleteModal
            text={translate('suppression_de_73669', props.position.name)}
            subtext={[
                translate('_tes_vous_s_r_d_01544'),
                translate('cela_supprimera_15744'),
                ...extraContent
            ]}
            buttonText={translate('supprimer_43083')}
            onDelete={async () => {
                await mutate({
                    eventId,
                    positionId: props.position.id
                });

                props.onSuccess();
            }}
            onClose={props.onClose}
        />
    );
};
