import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

interface IWhiteProps {
    left: number;
}

const White = (props: IWhiteProps) => (
        <Box
            height={40}
            width={40}
            css={{
                background: `url(https://assets.recrewteer.com/badges/valbon/croix.png) no-repeat`,
                backgroundSize: 'cover',
                left: `${props.left}px`,
                position: 'absolute',
                top: `245px`
            }}
        />
    );

interface IBadgeValbonProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeValbon = (props: IBadgeValbonProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const accreditationsIds: number[] = vr.accreditationsUsersInfos.map(
        (aui) => aui.accreditation.id
    );

    return (
        <Flex
            height={321}
            width={204}
            css={{
                background: `url(https://assets.recrewteer.com/badges/valbon/recto.jpg) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Box
                height={73}
                width={71}
                css={{
                    left: '12px',
                    position: 'absolute',
                    top: '128px'
                }}
            >
                {isNonEmptyString(ui.picture?.url) && (
                    <img src={ui.picture!.url} height="100%" width="100%" />
                )}
            </Box>

            <Flex
                direction="column"
                css={{ color: '#371b5c', left: '91px', position: 'absolute', top: '142px' }}
            >
                <Box css={{ fontSize: '11px' }}>{ui.fields.cf31710}</Box>

                <Spacer height="1" />

                <Box css={{ fontSize: '11px', fontWeight: '700', textTransform: 'uppercase' }}>
                    {ui.fields.cf31708 || ui.lastName}
                </Box>
                <Box css={{ fontSize: '11px', fontWeight: '700' }}>{ui.firstName}</Box>
            </Flex>

            <Box
                css={{
                    color: '#371b5c',
                    fontFamily: '$paris2024',
                    fontSize: '14px',
                    fontWeight: '700',
                    left: '12px',
                    lineHeight: '18px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '212px'
                }}
            >
                {ui.valbonPopulation}
            </Box>

            {!accreditationsIds.includes(2680) && <White left={9} />}
            {!accreditationsIds.includes(2681) && <White left={58} />}
            {!accreditationsIds.includes(2620) && <White left={106} />}
            {!accreditationsIds.includes(2619) && <White left={155} />}
        </Flex>
    );
};
