import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

const getFrontBackground = (statut: string) => {
    switch (statut) {
        case 'Organisateur':
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/Organisation_Recto.png';
        case 'Bénévole':
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/Benevole_Recto.png';
        case 'Partenaire':
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/Partenaire_Recto.png';
        case 'Officiel':
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/Officiel_Recto.png';
        case 'Exposant':
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/Exposant_Recto.png';
        case 'Prestataire':
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/Intervenant_Recto.png';
        default:
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/Benevole_Recto.png';
    }
};

const getBackBackground = (statut: string) => {
    switch (statut) {
        case 'Organisateur':
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/v2/Benevole_Organisation_Verso.png';
        case 'Bénévole':
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/v2/Benevole_Verso.png';
        case 'Partenaire':
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/verso_sans_Dotation_4.png';
        case 'Exposant':
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/Exposant_Verso.png';
        case 'Prestataire':
        case 'Officiel':
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/intervenant_verso_v2.png';
        default:
            return 'https://assets.recrewteer.com/badges/triathlonLaRochelle/verso_sans_Dotation_4.png';
    }
};

interface ITriathlonLaRochelleBadgeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const TriathlonLaRochelleBadge = (props: ITriathlonLaRochelleBadgeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const statut = ui.triathlonLaRochelleStatut || 'Organisateur';
    const repas = sortBy(
        vr.accreditationsUsersInfos.filter((aui) => aui.accreditationCategory.name === 'Repas'),
        (aui) => aui.accreditation.acronym
    );
    const zones = sortBy(
        vr.accreditationsUsersInfos.filter((aui) => aui.accreditationCategory.name === 'Zone'),
        (aui) => aui.accreditation.acronym
    );
    const psuis = sortBy(vr.positionsSlotsUsersInfos, (psui) =>
        psui.positionSlot.range.start!.toMillis()
    );
    const displayOrganisme = statut !== 'Organisateur' && statut !== 'Bénévole';
    const displayPositions = statut === 'Organisateur' || statut == 'Bénévole';

    return (
        <>
            <Flex
                height={597}
                width={435}
                css={{
                    background: `url(${getFrontBackground(statut)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$montserrat',
                    position: 'relative'
                }}
            >
                <Box
                    width={1}
                    textAlign="center"
                    css={{
                        color: 'black',
                        fontSize: '22px',
                        fontWeight: '700',
                        position: 'absolute',
                        top: displayOrganisme ? '339px' : '373px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    width={1}
                    textAlign="center"
                    css={{
                        color: 'black',
                        fontSize: '22px',
                        fontWeight: '700',
                        position: 'absolute',
                        top: displayOrganisme ? '368px' : '402px'
                    }}
                >
                    {ui.lastName}
                </Box>

                {displayOrganisme && (
                    <Box
                        width={1}
                        textAlign="center"
                        css={{
                            color: 'black',
                            fontSize: '18px',
                            fontWeight: '500',
                            position: 'absolute',
                            top: '409px'
                        }}
                    >
                        {ui.fields.cf27673}
                    </Box>
                )}

                <Flex
                    css={{
                        gap: '4px',
                        position: 'absolute',
                        px: '40px',
                        top: '481px'
                    }}
                >
                    {zones.map((aui) => (
                            <Flex
                                key={aui.accreditationSlot.id}
                                css={{
                                    background: aui.accreditation.color,
                                    borderRadius: '$1',
                                    color: 'black',
                                    fontSize: '10px',
                                    fontWeight: '500',
                                    padding: '4px'
                                }}
                            >
                                {aui.accreditation.name}
                            </Flex>
                        ))}
                </Flex>
            </Flex>

            <Flex
                height={597}
                width={435}
                css={{
                    background: `url(${getBackBackground(statut)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$montserrat',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
            >
                {displayPositions && (
                    <Flex
                        direction="column"
                        gap="2"
                        width={1}
                        css={{
                            position: 'absolute',
                            top: '83px'
                        }}
                    >
                        {psuis.map((psui) => (
                                <Box
                                    width={1}
                                    textAlign="center"
                                    css={{
                                        color: 'black',
                                        fontSize: '12px',
                                        fontWeight: '500'
                                    }}
                                >
                                    {psui.position.name} :{' '}
                                    {psui.positionSlot.range.start!.toFormat(
                                        `dd MMMM yyyy H'h'mm`,
                                        { locale: 'fr' }
                                    )}{' '}
                                    à {psui.positionSlot.range.end!.toFormat(`H'h'mm`)}
                                </Box>
                            ))}
                    </Flex>
                )}

                <Flex
                    css={{
                        gap: '40px',
                        position: 'absolute',
                        px: '66px',
                        top: '487px'
                    }}
                >
                    {repas.map((aui) => (
                            <Flex
                                key={aui.accreditationSlot.id}
                                direction="column"
                                align="center"
                                gap="1"
                            >
                                <Box
                                    height={28}
                                    width={28}
                                    css={{
                                        border: '2px solid white',
                                        borderRadius: '6px'
                                    }}
                                />

                                <Box
                                    textAlign="center"
                                    css={{
                                        color: 'white',
                                        fontSize: '12px',
                                        fontWeight: '500',
                                        lineHeight: '11px'
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: aui.accreditation.name.split(' ').join('<br />')
                                    }}
                                />
                            </Flex>
                        ))}
                </Flex>
            </Flex>
        </>
    );
};
