import { Empty } from 'common-front/src/components/empty/empty';
import { DelegationAccreditations } from 'common-front/src/delegations/accreditations/show/delegationAccreditations';
import { DelegationMembers } from 'common-front/src/delegations/members/show/delegationMembers';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { TabViewResponsive } from 'common-front/src/spaces/tabViewReponsive';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Feature } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DelegationsPaths } from 'common/src/util/paths/delegationsPaths';
import { UserInfoIdPathParam } from 'common/src/util/pathsTypes';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useEventContext } from '../../events/show/eventContext';
import { useDelegationQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { UserOverlayEvent, UserOverlayOrganization } from '../../users/overlay/userOverlay';
import { DelegationDashboard } from './dashboard/delegationDashboard';
import { DelegationHeader } from './delegationHeader';

export const Delegation = () => {
    const {
        history,
        params: { organizationId, eventId, delegationId },
        translate
    } = useHeavent();

    const { hasFeature } = useOrganizationContext();
    const isEventAdmin = useEventContext().event?.permissions.isEventAdmin;

    const [reloadKey, setReloadKey] = React.useState(1);
    const { data, loader, reload } = useDelegationQuery({ organizationId, delegationId });

    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);

    return (
        loader || (
            <Flex direction="column" width={1} css={{ padding: '$7' }}>
                <DelegationHeader
                    delegation={data.organization.delegation}
                    reload={() => {
                        setReloadKey(reloadKey + 1);
                    }}
                />

                <Spacer height="7" />

                <Switch>
                    <Route
                        exact
                        path={[
                            DelegationsPaths.DELEGATION(':organizationId', null, ':delegationId'),
                            DelegationsPaths.DELEGATION(
                                ':organizationId',
                                ':eventId',
                                ':delegationId'
                            )
                        ]}
                        children={
                            <Empty
                                path={DelegationsPaths.DELEGATION_DASHBOARD(
                                    organizationId,
                                    eventId,
                                    delegationId
                                )}
                                replace={true}
                            />
                        }
                    />

                    <Route
                        children={
                            <Flex direction="column">
                                <TabViewResponsive
                                    sections={[
                                        {
                                            id: 1,
                                            title: translate('tableau_de_bord_24627'),
                                            getPath: (isRouteComponent: boolean = false) =>
                                                DelegationsPaths.DELEGATION_DASHBOARD(
                                                    isRouteComponent
                                                        ? ':organizationId'
                                                        : organizationId,
                                                    isRouteComponent
                                                        ? eventId
                                                            ? ':eventId'
                                                            : undefined
                                                        : eventId,
                                                    isRouteComponent
                                                        ? ':delegationId'
                                                        : delegationId
                                                ),
                                            elem: <DelegationDashboard />
                                        },
                                        {
                                            id: 2,
                                            title: translate(
                                                'membres_125561',
                                                data.organization.delegation.numberOfResources
                                            ),
                                            getPath: (isRouteComponent: boolean = false) =>
                                                DelegationsPaths.DELEGATION_MEMBERS(
                                                    isRouteComponent
                                                        ? ':organizationId'
                                                        : organizationId,
                                                    isRouteComponent
                                                        ? eventId
                                                            ? ':eventId'
                                                            : undefined
                                                        : eventId,
                                                    isRouteComponent
                                                        ? ':delegationId'
                                                        : delegationId
                                                ),
                                            elem: (
                                                <DelegationMembers
                                                    canEditMembers={true}
                                                    customFields={
                                                        data.organization.customFields.nodes
                                                    }
                                                    delegation={data.organization.delegation}
                                                    getEditPath={(userInfoId, formId) =>
                                                        eventId
                                                            ? HeaventPaths.EDIT_USER_DELEGATION_FORM(
                                                                  organizationId,
                                                                  eventId,
                                                                  delegationId,
                                                                  userInfoId,
                                                                  formId
                                                              )
                                                            : HeaventPaths.COMMUNITY_EDIT_USER_DELEGATION(
                                                                  organizationId,
                                                                  delegationId,
                                                                  userInfoId
                                                              )
                                                    }
                                                    isEventAdmin={isEventAdmin}
                                                    onClick={(memberId) => {
                                                        history.push(
                                                            DelegationsPaths.DELEGATION_MEMBER_INFORMATION(
                                                                organizationId,
                                                                eventId,
                                                                delegationId,
                                                                memberId
                                                            )
                                                        );
                                                    }}
                                                    showAccreditActions={true}
                                                    showDeleteFromEvent={isEventAdmin}
                                                    showDocumentsDownload={true}
                                                    userOverlay={() => {
                                                        const getBasePath = (
                                                            userInfoId: UserInfoIdPathParam,
                                                            isRouteComponent?: boolean
                                                        ) => DelegationsPaths.DELEGATION_MEMBER(
                                                                isRouteComponent
                                                                    ? ':organizationId'
                                                                    : organizationId,
                                                                eventId
                                                                    ? isRouteComponent
                                                                        ? ':eventId'
                                                                        : eventId
                                                                    : undefined,
                                                                isRouteComponent
                                                                    ? ':delegationId'
                                                                    : delegationId,
                                                                isRouteComponent
                                                                    ? ':userInfoId'
                                                                    : userInfoId
                                                            );
                                                        const onCloseAndDelete = () => {
                                                            history.push(
                                                                DelegationsPaths.DELEGATION_MEMBERS(
                                                                    organizationId,
                                                                    eventId,
                                                                    delegationId
                                                                )
                                                            );
                                                        };

                                                        return eventId ? (
                                                            <UserOverlayEvent
                                                                getBasePath={getBasePath}
                                                                onClose={onCloseAndDelete}
                                                                onDelete={() => {
                                                                    onCloseAndDelete();

                                                                    reload();
                                                                }}
                                                                onUpdateState={reload}
                                                            />
                                                        ) : (
                                                            <UserOverlayOrganization
                                                                getBasePath={getBasePath}
                                                                onClose={onCloseAndDelete}
                                                                onDelete={() => {
                                                                    onCloseAndDelete();

                                                                    reload();
                                                                }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            )
                                        },
                                        ...(eventId && hasFeature(Feature.Accreditation)
                                            ? [
                                                  {
                                                      id: 3,
                                                      getPath: (
                                                          isRouteComponent: boolean = false
                                                      ) =>
                                                          DelegationsPaths.DELEGATION_ACCREDITATIONS(
                                                              isRouteComponent
                                                                  ? ':organizationId'
                                                                  : organizationId,
                                                              isRouteComponent
                                                                  ? ':eventId'
                                                                  : eventId,
                                                              isRouteComponent
                                                                  ? ':delegationId'
                                                                  : delegationId
                                                          ),
                                                      title: translate('accr_ditations_39450'),
                                                      elem: (
                                                          <DelegationAccreditations
                                                              isEventAdmin={isEventAdmin}
                                                          />
                                                      )
                                                  }
                                              ]
                                            : [])
                                    ]}
                                />
                            </Flex>
                        }
                    />
                </Switch>
            </Flex>
        )
    );
};
