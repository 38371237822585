import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    EventId,
    SegmentCustomFieldFragment,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { useVolunteerRegistrationUpdateFieldMutation } from '../../../generated/graphqlHooks';

interface IVolunteerRegistrationCheckboxCellProps {
    customField: SegmentCustomFieldFragment;
    eventId: EventId;
    field: PossibleColumn;
    firstCellCss: any;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
}

export const VolunteerRegistrationCheckboxCell = (
    props: IVolunteerRegistrationCheckboxCellProps
) => {
    const { mutate } = useVolunteerRegistrationUpdateFieldMutation();
    const fieldService = useService(FieldService);
    const { isEditMode } = useSegmentsContext();
    const userInfo = props.volunteerRegistration.userInfo;
    const value = fieldService.getValue<boolean>(props.customField, userInfo);
    const valueString = fieldService.getValueString(props.customField, userInfo);
    const state = value === true ? 'checked' : value === false ? 'unchecked' : 'indeterminate';
    const onClick = async (newState: ICheckboxState) => {
        await mutate({
            eventId: props.eventId,
            volunteerRegistrationId: props.volunteerRegistration.id,
            slug: props.field.slug,
            value: newState === 'checked'
        });

        props.reload();
    };

    if (isEditMode) {
        return (
            <Cell
                css={{
                    ...props.firstCellCss,
                    border: '2px solid transparent',
                    '& > .h-checkbox-cell-hover': {
                        display: 'none'
                    },
                    '&:hover': {
                        border: '2px solid $primary700'
                    },
                    '&:hover > .h-checkbox-cell-default': {
                        display: 'none'
                    },
                    '&:hover > .h-checkbox-cell-hover': {
                        display: 'block'
                    }
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
            >
                <Box className="h-checkbox-cell-default">{valueString}</Box>

                <Box className="h-checkbox-cell-hover">
                    <Checkbox state={state} onClick={onClick} />
                </Box>
            </Cell>
        );
    } else {
        return (
            <Cell
                css={{
                    ...props.firstCellCss,
                    border: '2px solid transparent'
                }}
            >
                {valueString}
            </Cell>
        );
    }
};
