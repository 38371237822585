import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useSegmentsContext } from '../segmentsContext';

interface ISelectSegmentProps {
    pageSelectedText: string;
    segmentSelectedText: string;
}

export const SelectSegment = (props: ISelectSegmentProps) => {
    const translate = useTranslate();
    const { toggleSelectAll, setIsSegmentSelected, isSegmentSelected, segment } =
        useSegmentsContext();
    const clearAll = React.useCallback(() => {
        toggleSelectAll('unchecked');
        setIsSegmentSelected(false);
    }, [setIsSegmentSelected, toggleSelectAll]);
    const selectAll = React.useCallback(() => {
        setIsSegmentSelected(true);
    }, [setIsSegmentSelected]);

    return (
        <Flex
            align="center"
            gap="3"
            css={{
                background: '$gray50',
                borderRadius: '$1',
                padding: '$3 $4'
            }}
        >
            <Box fontSize="textMd" fontWeight="medium">
                <I icon="circle-exclamation" />
            </Box>

            {isSegmentSelected ? (
                <Box color="gray700" fontWeight="medium" css={{ flex: '1' }}>
                    {props.segmentSelectedText}
                </Box>
            ) : (
                <Box color="gray700" fontWeight="medium" css={{ flex: '1' }}>
                    {props.pageSelectedText}
                </Box>
            )}

            {isSegmentSelected ? (
                <Box
                    color="primary700"
                    fontWeight="medium"
                    css={{ cursor: 'pointer' }}
                    onClick={clearAll}
                >
                    {translate('effacer_la_s_le_85095')}
                </Box>
            ) : (
                <Box
                    color="primary700"
                    fontWeight="medium"
                    css={{ cursor: 'pointer' }}
                    onClick={selectAll}
                >
                    {translate('s_lectionner_le_71777', segment.count, segment.name)}
                </Box>
            )}
        </Flex>
    );
};
