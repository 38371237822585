import { Map } from 'common-front/src/components/map/map';
import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { Table } from 'common/src/designSystem/components/table/table';
import { PositionRightPanelQuery, PositionSlotsQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { getColorByBackgroundColor } from 'common/src/util/color';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { extractCoordinates } from 'common/src/util/map';
import { isNonEmptyString } from 'common/src/util/string';
import { hasAddress, hasAddressOrCoordinates, hasCoordinates } from 'common/src/vo/position';
import { displayConditionValue } from 'common/src/vo/positionCustomField';
import * as React from 'react';
import { PositionRowResources } from '../list/list/positionRowResources';

interface IPositionRightPanelBodyProps {
    areSlotsLoading: boolean;
    numberOfPages: number;
    offset: number;
    position: PositionRightPanelQuery['event']['position'];
    slots: PositionSlotsQuery['event']['positionsSlots']['nodes'];
    totalCount: number;

    setOffset(offset: number): void;
}

export const PositionRightPanelBody = (props: IPositionRightPanelBodyProps) => {
    const translate = useTranslate();
    const hasLocation = hasAddressOrCoordinates(props.position);

    return (
        <Flex direction="column" width={1}>
            <Flex
                direction="column"
                align="center"
                css={{
                    paddingTop: '78px'
                }}
            >
                <Flex
                    align="center"
                    justify="center"
                    height={64}
                    width={64}
                    css={{
                        background: props.position.color,
                        borderRadius: '12px',
                        color: getColorByBackgroundColor(props.position.color),
                        fontSize: '26px'
                    }}
                >
                    <I icon={fromIconVO(props.position.icon)} />
                </Flex>

                <Spacer height="6" />

                <Box font="gray900 displayXs semiBold">{props.position.name}</Box>

                <Spacer height="1" />

                <Box color="gray500">{props.position.positionCategoryName}</Box>
            </Flex>

            <Spacer height="9" />

            <Accordion title={translate('cr_neaux_1_68650', props.totalCount)}>
                <Table>
                    <HeaderRow>
                        <HeaderCell>{translate('liste_des_cr_ne_94056')}</HeaderCell>
                    </HeaderRow>

                    {props.areSlotsLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : (
                        props.slots.map((slot) => (
                                <Row key={slot.id}>
                                    <Cell>{slot.nameOrRange}</Cell>
                                    <Cell>
                                        <PositionRowResources
                                            assignedResources={slot.assignedResources}
                                            resources={slot.resources}
                                        />
                                    </Cell>
                                </Row>
                            ))
                    )}

                    <PaginationRow>
                        <PaginationCell
                            numberOfPages={props.numberOfPages}
                            offset={props.offset}
                            totalCount={props.totalCount}
                            setOffset={props.setOffset}
                        />
                    </PaginationRow>
                </Table>
            </Accordion>

            <Accordion title={translate('responsables_67012', props.position.leaders.length)}>
                {props.position.leaders.length === 0 ? (
                    <Box color="gray500">{translate('aucun_reponsabl_38504')}</Box>
                ) : (
                    <Flex direction="column" gap="3" width={1}>
                        {props.position.leaders.map((leader) => (
                                <Flex
                                    key={leader.userInfo.id}
                                    css={{
                                        border: '1px solid $gray200',
                                        borderRadius: '$1',
                                        boxShadow: '$xs',
                                        padding: '$3 $4'
                                    }}
                                >
                                    <AvatarNameEmail
                                        userInfo={leader.userInfo}
                                        hasName={isNonEmptyString(leader.userInfo.name)}
                                        badge={
                                            leader.canEdit ? (
                                                <Badge color="gray">
                                                    {translate('_diteur_26621')}
                                                </Badge>
                                            ) : (
                                                <Badge color="success">
                                                    {translate('lecture_seule_63227')}
                                                </Badge>
                                            )
                                        }
                                    />
                                </Flex>
                            ))}
                    </Flex>
                )}
            </Accordion>

            <Accordion title={translate('description_58935')}>
                {isNonEmptyString(props.position.description) ? (
                    <RichText text={props.position.description} />
                ) : (
                    <Box color="gray500">{translate('aucune_descript_25737')}</Box>
                )}
            </Accordion>

            <Accordion title={translate('localisation_38416')}>
                {hasLocation ? (
                    <>
                        {hasCoordinates(props.position) && (
                            <Box height={380} width={1}>
                                <Map
                                    point={extractCoordinates(props.position)}
                                    layer={props.position.layer}
                                    initialZoom={props.position.zoom}
                                    traces={props.position.traces}
                                />
                            </Box>
                        )}

                        {hasAddress(props.position) && (
                            <Box color="gray800">{props.position.address}</Box>
                        )}
                    </>
                ) : (
                    <Box color="gray500">{translate('aucune_localisa_78216')}</Box>
                )}
            </Accordion>

            <Accordion title={translate('conditions_77756')}>
                {isNonEmptyArray(props.position.positionsCustomFields) ? (
                    <Flex direction="column" gap="6">
                        {props.position.positionsCustomFields.map((pcf, index) => (
                                <Flex key={index} direction="column" gap="1">
                                    <Box font="gray800 textSm medium">{pcf.customField.name}</Box>

                                    <Box color="gray500">
                                        {displayConditionValue(
                                            translate,
                                            pcf.customField,
                                            pcf.conditionValue
                                        )}
                                    </Box>
                                </Flex>
                            ))}
                    </Flex>
                ) : (
                    <Box color="gray500">{translate('aucune_conditio_36491')}</Box>
                )}
            </Accordion>

            <Accordion title={translate('tags_79499')}>
                {isNonEmptyArray(props.position.tags) ? (
                    <Flex gap="2" wrap="wrap">
                        {props.position.tags.map((tag) => <Badge key={tag.id}>{tag.name}</Badge>)}
                    </Flex>
                ) : (
                    <Box color="gray500">{translate('aucun_tag_ajout_63348')}</Box>
                )}
            </Accordion>

            <Accordion title={translate('note_priv_e_06303')}>
                {isNonEmptyString(props.position.privateNote) ? (
                    <RichText text={props.position.privateNote} />
                ) : (
                    <Box color="gray500">{translate('aucune_note_pri_86832')}</Box>
                )}
            </Accordion>
        </Flex>
    );
};
