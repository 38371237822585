import { uniqBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Avatar } from '../../../components/avatar/avatar';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { RichText } from '../../../designSystem/components/richEditor/richText';
import { Spacer } from '../../../designSystem/components/spacer';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment, PecbadgeDocumentOptions } from '../../../generated/types';
import { LocaleFormats } from '../../../util/luxon';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (isAccorArena: boolean) => {
    if (isAccorArena) {
        return `https://assets.recrewteer.com/badges/pec/accorArena/bg_accor_v4.jpg`;
    } else {
        return 'https://assets.recrewteer.com/badges/pec/adidasArena/Contremarque_adidas_orga.jpg';
    }
};

interface IBadgePecProps {
    event: DocumentUserCustomBadgeFragment;
    options: PecbadgeDocumentOptions;
}

export const BadgePec = (props: IBadgePecProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const isAccorArena = props.event.tags.some((t) => t.name === '🏟️ Accor Arena');
    const acronyms: string[] = vr.accreditationsUsersInfos.map((aui) => aui.accreditation.acronym!);
    const dates: DateTime[] = uniqBy(
        vr.accreditationsUsersInfos.flatMap((aui) => {
            if (aui.accreditationSlot.date) {
                return [aui.accreditationSlot.date];
            } else {
                return [];
            }
        }),
        (d) => d.startOf('day').toMillis()
    );
    const moreThanOneRow = dates.length > 14;
    const psui = vr.positionsSlotsUsersInfos[0];

    return (
        <>
            <Flex
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
                css={{
                    background: `url(${getBackground(isAccorArena)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: isAccorArena ? '$montserrat' : '$franklinGothic',
                    position: 'relative'
                }}
            >
                {isNonEmptyString(props.options.header?.content) && (
                    <Box
                        height={217}
                        width={706}
                        css={{
                            borderTopLeftRadius: '10px',
                            borderTopRightRadius: '10px',
                            left: '44px',
                            overflow: 'hidden',
                            position: 'absolute',
                            top: '40px'
                        }}
                    >
                        <img src={props.options.header.content} height="100%" width="100%" />
                    </Box>
                )}

                <Box
                    css={{
                        left: '92px',
                        position: 'absolute',
                        top: '285px'
                    }}
                >
                    <Avatar size={56} name={ui.name} email={ui.email} image={ui.picture?.url} />
                </Box>

                <Box
                    css={{
                        color: 'black',
                        fontSize: '22px',
                        fontWeight: '700',
                        left: '172px',
                        position: 'absolute',
                        top: '296px'
                    }}
                >
                    {ui.name}
                </Box>

                <Box
                    css={{
                        color: '#0000e3',
                        fontSize: '18px',
                        fontWeight: '700',
                        left: '172px',
                        position: 'absolute',
                        top: '324px'
                    }}
                >
                    {vr.delegation?.name ?? ''}
                </Box>

                <Box
                    height={80}
                    width={80}
                    css={{
                        left: '614px',
                        position: 'absolute',
                        top: '281px'
                    }}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=80&width=80`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>

                <Flex
                    direction="column"
                    width={610}
                    css={{
                        left: '92px',
                        position: 'absolute',
                        top: '489px'
                    }}
                >
                    {dates.length > 1 && (
                        <>
                            <Box height={20} width={1}>
                                <img
                                    src="https://assets.recrewteer.com/badges/pec/accorArena/v2/jour_accred.png"
                                    height="100%"
                                    width="100%"
                                />
                            </Box>

                            <Spacer height="2" />

                            <Flex
                                wrap="wrap"
                                width={1}
                                css={{
                                    gap: '6px'
                                }}
                            >
                                {dates.map((date) => (
                                        <Flex
                                            key={date.toMillis()}
                                            align="center"
                                            justify="center"
                                            direction="column"
                                            css={{
                                                border: '1px solid black',
                                                borderRadius: '$1',
                                                padding: '4px 6px'
                                            }}
                                        >
                                            <Box css={{ fontSize: '16px', fontWeight: '700' }}>
                                                {date.toFormat('d')}
                                            </Box>

                                            <Box
                                                css={{
                                                    fontSize: '10px',
                                                    fontWeight: '500',
                                                    textTransform: 'uppercase'
                                                }}
                                            >
                                                {date.toFormat('MMM', { locale: 'fr' })}
                                            </Box>
                                        </Flex>
                                    ))}
                            </Flex>

                            <Spacer height="5" />
                        </>
                    )}

                    <Box height={20} width={1}>
                        <img
                            src="https://assets.recrewteer.com/badges/pec/accorArena/v2/porte.png"
                            height="100%"
                            width="100%"
                        />
                    </Box>

                    <Spacer height="2" />

                    <Flex direction={moreThanOneRow ? 'row' : 'column'} css={{ gap: '10px' }}>
                        <Flex direction={moreThanOneRow ? 'column' : 'row'} css={{ gap: '10px' }}>
                            {acronyms.includes('P38') && (
                                <Flex direction="column">
                                    <Box css={{ fontWeight: '600' }}>Porte 38 (selon horaire)</Box>
                                    <Box>Accor Arena</Box>
                                    <Box>8 Bd de Bercy,</Box>
                                    <Box>75012 Paris</Box>
                                </Flex>
                            )}

                            {acronyms.includes('P30') && (
                                <Flex direction="column">
                                    <Box css={{ fontWeight: '600' }}>Porte 30</Box>
                                    <Box>Accor Arena</Box>
                                    <Box>222 Quai de Bercy,</Box>
                                    <Box>75012 Paris</Box>
                                </Flex>
                            )}

                            {acronyms.includes('P4') && (
                                <Flex direction="column">
                                    <Box css={{ fontWeight: '600' }}>Porte 4</Box>
                                    <Box>Accor Arena</Box>
                                    <Box>8 Bd de Bercy,</Box>
                                    <Box>75012 Paris</Box>
                                </Flex>
                            )}
                        </Flex>

                        <Box height={moreThanOneRow ? 190 : 305} width={moreThanOneRow ? 380 : 610}>
                            <img
                                src="https://assets.recrewteer.com/badges/pec/accorArena/v2/AA_Plan_Salle_3D_QuaiBercy_PlanOrganisateur 1.png"
                                height="100%"
                                width="100%"
                            />
                        </Box>
                    </Flex>
                </Flex>
            </Flex>

            {psui && (
                <Flex
                    height={A4_SIZES['96dpi'].height}
                    width={A4_SIZES['96dpi'].width}
                    css={{
                        background: `url(https://assets.recrewteer.com/badges/pec/accorArena/Fiche_Affectation_Accor.jpg) no-repeat`,
                        backgroundSize: 'cover',
                        fontFamily: isAccorArena ? '$montserrat' : '$franklinGothic',
                        pageBreakBefore: 'always',
                        position: 'relative'
                    }}
                >
                    {isNonEmptyString(props.options.header?.content) && (
                        <Box
                            height={217}
                            width={706}
                            css={{
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                                left: '44px',
                                overflow: 'hidden',
                                position: 'absolute',
                                top: '44px'
                            }}
                        >
                            <img src={props.options.header.content} height="100%" width="100%" />
                        </Box>
                    )}

                    <Flex
                        direction="column"
                        css={{
                            position: 'absolute',
                            px: '92px',
                            top: '381px'
                        }}
                    >
                        <Box
                            css={{
                                color: 'black',
                                fontSize: '14px',
                                fontWeight: '700'
                            }}
                        >
                            {psui.position.name}
                        </Box>

                        <Spacer height="1" />

                        <Box
                            color="gray800"
                            css={{ fontWeight: '600', textTransform: 'capitalize' }}
                        >
                            {psui.positionSlot.range.start!.toLocaleString(
                                LocaleFormats.DateOnly.WeekdayLongMonthLong,
                                {
                                    locale: 'fr'
                                }
                            )}
                        </Box>

                        <Spacer height="3" />

                        <Box css={{ color: 'black' }}>
                            <RichText
                                text={psui.position.description}
                                css={{
                                    '& > p:first-child': { marginTop: 0 },
                                    '& > p:last-child': { marginBottom: 0 }
                                }}
                            />
                        </Box>
                    </Flex>
                </Flex>
            )}
        </>
    );
};
