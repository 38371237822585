import { MapLoaded } from 'common-front/src/components/map/mapLoaded';
import { PositionsMap } from 'common-front/src/components/map/positionsMap';
import { AcronymIcon } from 'common-front/src/localDatabase';
import { Box } from 'common/src/designSystem/components/box';
import { ExportPositionsMapQuery, TraceId } from 'common/src/generated/types';
import { googleCoordinatesFromString } from 'common/src/util/map';
import * as React from 'react';

export const ExportPositionsMap = () => {
    const event = JSON.parse(
        (window as any).HEAVENT_EXPORT_POSITIONS_MAP_DATA
    ) as ExportPositionsMapQuery['event'];
    const [isLoadedVisible, setIsLoadedVisible] = React.useState(false);
    const { searchParams } = new URL(location.href);
    const center = googleCoordinatesFromString(searchParams.get('center'));
    const acronymIcon = (searchParams.get('acronymIcon') || 'icon') as AcronymIcon;
    const zoom = searchParams.get('zoom');
    const hiddenTracesIds =
        searchParams
            .get('hiddenTracesIds')
            ?.split(',')
            .map((id: string) => parseInt(id, 10) as TraceId) ?? [];

    return (
        <>
            <Box
                height={1}
                width={1}
                css={{
                    '& .gm-style-cc': {
                        display: 'none'
                    }
                }}
            >
                <PositionsMap
                    acronymIcon={acronymIcon}
                    hiddenTracesIds={hiddenTracesIds}
                    initialCenter={center}
                    initialZoom={zoom ? parseInt(zoom, 10) : null}
                    layer={event.layer}
                    mapOptions={{
                        fullscreenControl: false,
                        zoomControl: false
                    }}
                    positions={event.positions.nodes}
                    showMarker={false}
                    traces={event.traces}
                    onMapTilesloaded={() => {
                        setIsLoadedVisible(true);
                    }}
                />
            </Box>

            {isLoadedVisible && <MapLoaded />}
        </>
    );
};
