import { Empty } from 'common-front/src/components/empty/empty';
import { FullPageContent } from 'common-front/src/components/mobileFullPage/fullPageContent';
import { MobileFullPage } from 'common-front/src/components/mobileFullPage/mobileFullPage';
import { TabListMobile } from 'common-front/src/designSystem/components/tabs/mobile/tabListMobile';
import { TabMobile } from 'common-front/src/designSystem/components/tabs/mobile/tabMobile';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import {
    useHistory,
    useLocation,
    useParams,
    useTranslate
} from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { usePositionMobileQuery } from '../../../generated/graphqlHooks';
import { PositionHeaderMobile } from './positionHeaderMobile';
import { useState } from 'react';
import { PositionSlotsListMobile } from './positionSlots/positionSlotsListMobile';
import { PositionsSlotId } from 'common/src/generated/types';
import { PositionSettingsMobile } from './positionSettings/positionSettingsMobile';

export const PositionMobile = () => {
    const { organizationId, eventId, positionId } = useParams();
    const { data, loader } = usePositionMobileQuery({ eventId, positionId });
    const location = useLocation();
    const translate = useTranslate();
    const history = useHistory();
    const [locationState] = useState(location.state);
    const back = () => {
        if (locationState?.from === 'SEARCH_MOBILE') {
            history.push(HeaventPaths.POSITIONS_SEARCH(organizationId, eventId), {
                search: locationState?.search
            });
        } else {
            history.push(HeaventPaths.POSITIONS(organizationId, eventId));
        }
    };

    if (location.pathname === HeaventPaths.POSITION(organizationId, eventId, positionId)) {
        return <Empty path={HeaventPaths.POSITION_SETTINGS(organizationId, eventId, positionId)} />;
    }

    const paths = {
        settings: HeaventPaths.POSITION_SETTINGS(organizationId, eventId, positionId),
        slots: HeaventPaths.POSITION_SLOTS(organizationId, eventId, positionId)
    };

    const openSlot = (id: PositionsSlotId) => {
        history.push(HeaventPaths.POSITION_SLOT_MEMBERS(organizationId, eventId, positionId, id));
    };

    if (loader) {
        return loader;
    } else {
        return (
            <MobileFullPage>
                <PositionHeaderMobile back={back} position={data.event.position} />
                <FullPageContent>
                    <Tabs>
                        <TabListMobile css={{ margin: '$6 0' }}>
                            <TabMobile path={paths.settings}>
                                {translate('param_tres_78420')}
                            </TabMobile>
                            <TabMobile path={paths.slots}>{translate('cr_neaux_33401')}</TabMobile>
                        </TabListMobile>
                        <TabPanel path={paths.settings}>
                            <PositionSettingsMobile position={data.event.position} />
                        </TabPanel>
                        <TabPanel path={paths.slots}>
                            <PositionSlotsListMobile
                                open={openSlot}
                                eventId={eventId}
                                positionId={data.event.position.id}
                            />
                        </TabPanel>
                    </Tabs>
                </FullPageContent>
            </MobileFullPage>
        );
    }
};
