import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { SegmentsFoldersSegmentsFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { TextInput } from '../../designSystem/components/textInput';
import { useSegmentsContext } from '../segmentsContext';
import { CreateFolderModal } from './createFolderModal';
import { SegmentsFolder } from './segmentsFolder';

export interface ISegmentsProps {
    segmentsFolders: SegmentsFoldersSegmentsFragment[];

    onSegmentDelete(): Promise<any>;
}

export const Segments = (props: ISegmentsProps) => {
    const translate = useTranslate();
    const { setAreSegmentsOpen } = useSegmentsContext();
    const [isCreateFolderOpen, setIsCreateFolderOpen] = React.useState(false);
    const canDelete = React.useMemo(() => props.segmentsFolders.flatMap((sf) => sf.segments).length > 1, [props.segmentsFolders]);
    const [search, setSearch] = React.useState('');
    const segmentsFolders = React.useMemo(() => {
        if (isNonEmptyString(search)) {
            return props.segmentsFolders.filter((segmentFolder) => segmentFolder.segments.some((segment) => segment.name.toLowerCase().includes(search.toLowerCase())));
        } else {
            return props.segmentsFolders;
        }
    }, [props.segmentsFolders, search]);

    return (
        <>
            <Grid
                gridtemplaterows="1fr 48px"
                height={1}
                width={1}
                css={{
                    background: '$gray50',
                    overflow: 'hidden'
                }}
            >
                <Flex direction="column" width={1} css={{ overflowY: 'auto', padding: '$7 $4' }}>
                    <Flex align="center" gap="3">
                        <Box
                            color="gray900"
                            fontSize="textMd"
                            fontWeight="semiBold"
                            css={{ flex: '1' }}
                        >
                            {translate('vues_personnali_80652')}
                        </Box>

                        <Box
                            color="gray800"
                            fontSize="textLg"
                            css={{ cursor: 'pointer' }}
                            onClick={() => {
                                setAreSegmentsOpen(false);
                            }}
                        >
                            <I icon="arrow-left-from-line" />
                        </Box>
                    </Flex>

                    <Spacer height="6" />

                    <Box width={1}>
                        <TextInput
                            icon="magnifying-glass"
                            state="search"
                            placeholder="Rechercher"
                            value={search}
                            onChange={setSearch}
                        />
                    </Box>

                    <Spacer height="4" />

                    {segmentsFolders.map((segmentFolder, index) => (
                            <SegmentsFolder
                                key={segmentFolder.id}
                                canDeleteSegment={canDelete}
                                canDeleteSegmentFolder={props.segmentsFolders.length > 1}
                                isFirst={index === 0}
                                isLast={index === props.segmentsFolders.length - 1}
                                search={search}
                                segmentFolder={segmentFolder}
                                segmentsFolders={segmentsFolders}
                                onSegmentDelete={props.onSegmentDelete}
                            />
                        ))}
                </Flex>

                <Flex
                    align="center"
                    gap="4"
                    width={1}
                    css={{
                        borderTop: '1px solid $gray200',
                        cursor: 'pointer',
                        padding: '$3 $6'
                    }}
                    onClick={() => {
                        setIsCreateFolderOpen(true);
                    }}
                >
                    <I icon="plus" />

                    <Box color="gray700" fontWeight="medium">
                        {translate('nouveau_dossier_94479')}
                    </Box>
                </Flex>
            </Grid>

            {isCreateFolderOpen && (
                <CreateFolderModal
                    isEdit={false}
                    onClose={() => {
                        setIsCreateFolderOpen(false);
                    }}
                />
            )}
        </>
    );
};
