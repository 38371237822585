import { chunk } from 'lodash-es';
import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgesFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { BadgeDoxart } from './badgeDoxart';

interface IBadgesDoxartProps {
    event: DocumentUserCustomBadgesFragment;
}

export const BadgesDoxart = (props: IBadgesDoxartProps) => (
        <>
            {chunk(props.event.volunteersRegistrations.nodes, 8).map((vrs, index) => (
                    <Flex
                        key={`page-${index}`}
                        wrap="wrap"
                        height={A4_SIZES['96dpi'].height}
                        width={A4_SIZES['96dpi'].width}
                        css={{
                            pageBreakBefore: 'always'
                        }}
                    >
                        {vrs.map((vr) => (
                                <BadgeDoxart
                                    key={vr.userInfo.id}
                                    event={{
                                        ...props.event,
                                        volunteerRegistration: vr
                                    }}
                                />
                            ))}
                    </Flex>
                ))}
        </>
    );
