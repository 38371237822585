import { sum, uniq } from 'lodash-es';
import * as React from 'react';
import { Avatar } from '../../../components/avatar/avatar';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { IGroupedAssignment } from '../../userPlanningList/groupAssignmentsByPositionDay';

interface IEurockeennesHeaderProps {
    assignments: IGroupedAssignment[];
    event: DocumentUserCustomBadgeFragment;
}

export const EurockeennesHeader = (props: IEurockeennesHeaderProps) => {
    const userInfo = props.event.volunteerRegistration.userInfo;
    const numberOfMissions = uniq(props.assignments.map(({ name }) => name)).length;
    const ranges = props.assignments.flatMap((a) => a.ranges);
    const numberOfDays = uniq(ranges.map((r) => r.start!.startOf('day').toMillis())).length;
    const totalTime = sum(
        ranges.map((r) => r.length('hours'))
    );

    return (
        <>
            <Flex
                justify="center"
                width={1}
                css={{
                    paddingTop: '$6'
                }}
            >
                <img
                    src="https://assets.recrewteer.com/badges/eurockeennes/eurockeennesLogo.png"
                    height="56px"
                    width="103px"
                />
            </Flex>

            <Spacer height="7" />

            <Flex align="center" gap="3">
                <Avatar
                    size={40}
                    email={userInfo.nameOrEmail}
                    name={userInfo.name}
                    image={userInfo.picture?.url}
                />

                <Flex direction="column" gap="1">
                    <Box font="gray800 textMd semiBold">{userInfo.name}</Box>

                    <Flex gap="3">
                        <Box color="gray500">Nb. de missions : {numberOfMissions}</Box>

                        <Box color="gray500">Nb. de jours : {numberOfDays}</Box>

                        <Box color="gray500">Temps total affecté : {totalTime}h</Box>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};
