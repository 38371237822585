export const DOCUMENT_ROOT_CLASS = 'h-pdf-document-root';

export const A3_SIZES = {
    '96dpi': {
        height: 1587,
        width: 1123
    }
};

export const A4_SIZES = {
    '96dpi': {
        height: 1123,
        width: 794
    }
};

export const A5_SIZES = {
    '96dpi': {
        height: 794,
        width: 559
    }
};

export const A6_SIZES = {
    '96dpi': {
        height: 561,
        width: 397
    }
};

export const A7_SIZES = {
    '96dpi': {
        height: 397,
        width: 280
    }
};

export const PAGE_SIZES = {
    A4: {
        height: '297mm',
        width: '210mm'
    },
    A5: {
        height: '210mm',
        width: '148mm'
    },
    A6: {
        height: '148mm',
        width: '105mm'
    }
};
