import { Button } from 'common-front/src/designSystem/components/button';
import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import {
    AccreditationsCategoryId,
    AccreditationsSlotId,
    EventAccreditationsFragment
} from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { uniqBy } from 'lodash-es';
import * as React from 'react';
import { Accreditations } from './accreditationsSelectPanel';
import { AccreditationsSelectPanelAccreditationSlot } from './accreditationsSelectPanelAccreditationSlot';

interface IAccreditationsSelectPanelAccreditationProps {
    accreditationCategoryId: AccreditationsCategoryId;
    accreditation: EventAccreditationsFragment['accreditationsCategories'][number]['accreditations'][number];
    accreditationSlotsIdsValues: AccreditationsSlotId[];
    fullAccreditation: Accreditations[number];
    values: ICreateUpdateDelegationValues['delegation']['accreditations'];

    change(name: string, value: any): void;
}

export const AccreditationsSelectPanelAccreditation = ({
    accreditationCategoryId,
    accreditation,
    accreditationSlotsIdsValues,
    change,
    fullAccreditation,
    values
}: IAccreditationsSelectPanelAccreditationProps) => {
    const translate = useTranslate();

    const [isExpanded, setIsExpanded] = React.useState(true);

    const areAllSlotsSelected = React.useMemo(
        () =>
            !fullAccreditation.slots.some((slot) => !accreditationSlotsIdsValues.includes(slot.id)),
        [accreditation.slots, accreditationSlotsIdsValues]
    );
    const accreditationSlotsIds = React.useMemo(
        () => accreditation.slots.flatMap((slot) => slot.id),
        [accreditation.slots]
    );
    const accreditationValues = React.useMemo(
        () => values.filter((ad) => ad.accreditationId == accreditation.id),
        [values]
    );

    const isSelected = isNonEmptyArray(accreditationValues);

    return isNonEmptyArray(fullAccreditation.slots) ? (
        <Flex
            direction="column"
            gap="4"
            css={{
                padding: '$4 0',
                borderRadius: '5px',
                border: '1px solid $gray200',
                boxShadow: '$xs',
                background: '$white'
            }}
        >
            <Flex css={{ padding: '0 $5' }}>
                <Flex
                    gap="2"
                    align="center"
                    css={{ cursor: 'pointer' }}
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                >
                    <I icon={isExpanded ? 'chevron-down' : 'chevron-up'} />

                    {accreditation.name}
                </Flex>

                <Box css={{ flex: '1' }} />

                <Button
                    color="invisible"
                    onClick={() => {
                        change(
                            'delegation.accreditations',
                            !areAllSlotsSelected
                                ? uniqBy(
                                      [
                                          ...values,
                                          ...accreditation.slots.map((slot) => ({
                                                  accreditationCategoryId,
                                                  accreditationId: accreditation.id,
                                                  accreditationSlotId: slot.id,
                                                  isDefault: false,
                                                  isVisible: true
                                              }))
                                      ],
                                      (ad) => ad.accreditationSlotId
                                  )
                                : values.filter(
                                      (ad) =>
                                          !accreditationSlotsIds.includes(ad.accreditationSlotId)
                                  )
                        );
                    }}
                >
                    {!areAllSlotsSelected
                        ? translate('tout_s_lectionn_48027')
                        : translate('tout_des_lectio_13935')}
                </Button>
            </Flex>

            {isExpanded && (
                <>
                    <Separator direction="horizontal" />

                    <Flex direction="column" gap="4" css={{ padding: '0 $5' }}>
                        {accreditation.slots
                            .toSorted((a, b) => {
                                if (a.date && b.date) {
                                    const aDate = a.date.toJSDate();
                                    const bDate = b.date.toJSDate();
                                    if (aDate < bDate) {
                                        return -1;
                                    }
                                    if (aDate > bDate) {
                                        return 1;
                                    }
                                    return 0;
                                } else {
                                    if (a.name < b.name) {
                                        return -1;
                                    }
                                    if (a.name > b.name) {
                                        return 1;
                                    }
                                    return 0;
                                }
                            })
                            .map((slot) => (
                                    <AccreditationsSelectPanelAccreditationSlot
                                        key={slot.id}
                                        accreditationCategoryId={accreditationCategoryId}
                                        accreditationId={accreditation.id}
                                        accreditationName={accreditation.name}
                                        accreditationSlotsIdsValues={accreditationSlotsIdsValues}
                                        change={change}
                                        slot={slot}
                                        values={values}
                                    />
                                ))}
                    </Flex>
                </>
            )}
        </Flex>
    ) : (
        <CheckboxText
            css={{
                padding: '$4 $5',
                borderRadius: '5px',
                border: '1px solid $gray200',
                boxShadow: '$xs',
                background: '$white'
            }}
            onClick={() => {
                change(
                    'delegation.accreditations',
                    isSelected
                        ? values.filter((da) => da.accreditationId != accreditation.id)
                        : [
                              ...values,
                              {
                                  accreditationCategoryId,
                                  accreditationId: accreditation.id,
                                  accreditationSlotId: accreditation.hiddenSlotId,
                                  isDefault: false,
                                  isVisible: true
                              }
                          ]
                );
            }}
            state={isSelected ? 'checked' : 'unchecked'}
        >
            {accreditation.name}
        </CheckboxText>
    );
};
