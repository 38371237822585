import * as React from 'react';
import { Box } from '../../../../designSystem/components/box';
import { Flex } from '../../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../../generated/types';

interface IUltraMarinMediaFrontProps {
    event: DocumentUserCustomBadgeFragment;
}

export const UltraMarinMediaFront = (props: IUltraMarinMediaFrontProps) => (
        <Flex
            height={396}
            width={280}
            css={{
                background: `url(https://assets.recrewteer.com/badges/ultraMarin/ultraMarinMediaRecto.jpg) no-repeat`,
                backgroundSize: 'cover',
                overflow: 'hidden',
                paddingTop: '351px'
            }}
        >
            <Box
                css={{
                    color: 'white',
                    fontFamily: '$brito',
                    fontSize: '17px',
                    fontWeight: '700',
                    textAlign: 'center',
                    width: '100%'
                }}
            >
                {props.event.volunteerRegistration.userInfo.fields.cf18855 || ''}
            </Box>
        </Flex>
    );
