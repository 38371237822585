import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (population: string) => {
    switch (population) {
        case 'BACKSTAGE':
            return 'https://assets.recrewteer.com/badges/p2n/badgev2/Backstage.png';
        case 'ALL ACCES':
            return 'https://assets.recrewteer.com/badges/p2n/badgev2/Orga_All_access.png';
        case 'BÉNÉVOLE':
            return 'https://assets.recrewteer.com/badges/p2n/badgev2/Benevole.png';
        case 'GENDARME':
            return 'https://assets.recrewteer.com/badges/p2n/badgev2/Gendarme.png';
        case 'MÉDIA':
            return 'https://assets.recrewteer.com/badges/p2n/badgev2/Media.png';
        case 'PARTENAIRE':
            return 'https://assets.recrewteer.com/badges/p2n/badgev2/Partenaire.png';
        case 'POMPIER':
            return 'https://assets.recrewteer.com/badges/p2n/badgev2/Pompier.png';
        case 'PRESTATAIRE':
            return 'https://assets.recrewteer.com/badges/p2n/badgev2/Prestataire.png';
        case 'PRÉVENTION':
            return 'https://assets.recrewteer.com/badges/p2n/badgev2/Prevention.png';

        case 'SÉCURITÉ':
            return 'https://assets.recrewteer.com/badges/p2n/badgev2/Securite.png';
        case 'AFTER':
        case 'FRONTSTAGE':
        case 'LAISSEZ PASSER PIÉTON':
        case 'REPAS':
            return '';
        default:
            return '';
    }
};

interface IP2NBadgeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const P2NBadge = (props: IP2NBadgeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    const accreditationId = props.event.id === 3613 ? 2434 : 2081;

    const accreditations = sortBy(
        vr.accreditationsUsersInfos.filter(
            ({ accreditation }) => accreditation.id === accreditationId
        ),
        (aui) => aui.accreditationSlot.date?.toMillis() ?? 0
    )
        .map(({ accreditationSlot }) => accreditationSlot.name)
        .join('');

    return (
        <Flex
            height={325}
            width={208}
            css={{
                background: `url(${getBackground(ui.p2nBadge2024)}) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$now',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    color: 'black',
                    fontFamily: '$nowMedium',
                    fontSize: '16px',
                    fontWeight: '600',
                    position: 'absolute',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    top: '111px',
                    width: '100%'
                }}
            >
                {ui.firstName}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontFamily: '$nowMedium',
                    fontSize: '16px',
                    fontWeight: '600',
                    position: 'absolute',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    top: '139px',
                    width: '100%'
                }}
            >
                {ui.lastName}
            </Box>

            <Flex
                align="center"
                css={{
                    position: 'absolute',
                    top: '170px',
                    width: '100%'
                }}
            >
                <Box
                    css={{
                        color: 'black',
                        flex: '1',
                        fontFamily: '$nowMedium',
                        fontSize: '16px',
                        fontWeight: '600',
                        textAlign: 'center'
                    }}
                >
                    {accreditations}
                </Box>

                <Flex
                    width={169}
                    css={{
                        paddingRight: '5px'
                    }}
                >
                    <Box height={26} width={1}>
                        {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                            <img
                                src={`https://api.${
                                    CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                                }/barcode/code39?text=${encodeURIComponent(
                                    vr.weezeventParticipantInfo!.idBarcode
                                )}&color=000000&height=26&width=164`}
                                height="100%"
                                width="100%"
                            />
                        )}
                    </Box>
                </Flex>
            </Flex>

            {['PRESTATAIRE', 'PRÉVENTION', 'SÉCURITÉ'].some((_) => _ === ui.p2nBadge2024) && (
                <Flex
                    align="center"
                    css={{
                        position: 'absolute',
                        top: '249px',
                        width: '100%'
                    }}
                >
                    <Box
                        css={{
                            color: 'white',
                            flex: '1',
                            fontFamily: '$kobe',
                            fontSize: '12px',
                            fontWeight: '900',
                            lineHeight: '12.78px',
                            margin: '0 53px',
                            textAlign: 'center'
                        }}
                    >
                        {props.event.volunteerRegistration.delegation?.name.toUpperCase()}
                    </Box>
                </Flex>
            )}
        </Flex>
    );
};
