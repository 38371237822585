import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { getColorByBackgroundColor } from '../../../util/color';
import { Emptyable } from '../../../util/emptyable';
import { isNonEmptyString } from '../../../util/string';

interface IBadgePocBackProps {
    event: DocumentUserCustomBadgeFragment;
    logoUrl: Emptyable<string>;
}

export const BadgePocBack = (props: IBadgePocBackProps) => {
    const accreditations = props.event.volunteerRegistration.accreditationsUsersInfos.filter(
        (aui) => aui.accreditation.tags.map((t) => t.name).includes('Catering')
    );

    return (
        <Flex
            height={597}
            width={435}
            css={{
                background: `url(https://assets.recrewteer.com/badges/poc/pocVerso.png) no-repeat`,
                backgroundColor: '#37b3e9',
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Flex
                justify="center"
                width={1}
                css={{
                    position: 'absolute',
                    top: '60px'
                }}
            >
                {isNonEmptyString(props.logoUrl) && <img src={props.logoUrl} width="100px" />}
            </Flex>

            <Flex
                gap="2"
                wrap="wrap"
                css={{
                    position: 'absolute',
                    px: '39px',
                    top: '467px'
                }}
            >
                {accreditations.map((aui) => {
                    const accreditation = aui.accreditation;
                    const slot = aui.accreditationSlot;

                    return (
                        <Box
                            key={accreditation.id}
                            css={{
                                background: accreditation.color,
                                borderRadius: '$1',
                                color: getColorByBackgroundColor(accreditation.color),
                                fontSize: '11px',
                                padding: '$1'
                            }}
                        >
                            {slot.name || accreditation.name}
                        </Box>
                    );
                })}
            </Flex>
        </Flex>
    );
};
