import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IBelgiumHouseContremarqueProps {
    event: DocumentUserCustomBadgeFragment;
}

const getBackground = (type: 'Front' | 'Back', language: string): string => {
    switch (language) {
        case 'French (FR)':
            return `https://assets.recrewteer.com/badges/belgiumHouse/contremarque${type}Fr.jpg`;
        case 'Dutch (NL)':
            return `https://assets.recrewteer.com/badges/belgiumHouse/contremarque${type}Nl.jpg`;
        default:
            return `https://assets.recrewteer.com/badges/belgiumHouse/contremarque${type}En.jpg`;
    }
};

export const BelgiumHouseContremarque = (props: IBelgiumHouseContremarqueProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const days = uniqBy(
        sortBy(
            vr.accreditationsUsersInfos.flatMap((aui) => {
                if (aui.accreditationSlot.date) {
                    return [aui.accreditationSlot.date];
                } else {
                    return [];
                }
            }),
            (d) => d.startOf('day').toMillis()
        ),
        (d) => d.startOf('day').toMillis()
    );

    return (
        <>
            <Flex
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
                css={{
                    background: `url(${getBackground('Front', ui.belgiumHouseLanguage)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$gothamRoundedMedium',
                    position: 'relative'
                }}
            >
                <Box
                    height={76}
                    width={76}
                    css={{
                        borderRadius: '76px',
                        left: '56px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '286px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    css={{
                        color: 'black',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        left: '149px',
                        lineHeight: 'normal',
                        position: 'absolute',
                        top: '299px'
                    }}
                >
                    {ui.name}
                </Box>

                <Box
                    css={{
                        color: 'black',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        left: '149px',
                        lineHeight: 'normal',
                        position: 'absolute',
                        top: '326px'
                    }}
                >
                    {ui.fields.cf33875 ?? ''}
                </Box>

                <Box
                    height={80}
                    width={80}
                    css={{
                        left: '653px',
                        position: 'absolute',
                        top: '286px'
                    }}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=80&width=80`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>

                <Flex
                    width={612}
                    wrap="wrap"
                    css={{
                        gap: '8px 12px',
                        left: '93px',
                        position: 'absolute',
                        top: '515px'
                    }}
                >
                    {days.map((day, index) => (
                            <Flex
                                key={index}
                                align="center"
                                justify="center"
                                height={42}
                                width={92}
                                css={{
                                    background: 'white',
                                    border: '1px solid black',
                                    borderRadius: '8px',
                                    fontSize: '18px',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal'
                                }}
                            >
                                {day.toFormat('dd.MM')}
                            </Flex>
                        ))}
                </Flex>
            </Flex>

            <Flex
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
                css={{
                    background: `url(${getBackground('Back', ui.belgiumHouseLanguage)}) no-repeat`,
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />
        </>
    );
};
