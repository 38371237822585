import { Empty } from 'common-front/src/components/empty/empty';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useVolunteersSegmentsMobileQuery } from '../../../generated/graphqlHooks';
import { VolunteersMobile } from './volunteersMobile';
import { VolunteersSearchMobile } from '../search/volunteersSearchMobile';
import { VolunteersSearchUserSheetMobile } from '../search/volunteersSearchUserSheetMobile';
import { VolunteersUserSheetMobile } from './volunteersUserSheetMobile';

export const VolunteersLoaderMobile = () => {
    const { organizationId, eventId } = useParams();
    const { data, loader } = useVolunteersSegmentsMobileQuery({ eventId });

    if (loader) {
        return loader;
    } else {
        return (
            <>
                <Switch>
                    <Route
                        path={HeaventPaths.VOLUNTEERS_SEARCH(':organizationId', ':eventId')}
                        children={<VolunteersSearchMobile />}
                    />

                    <Route
                        path={HeaventPaths.VOLUNTEERS_SEGMENT(
                            ':organizationId',
                            ':eventId',
                            ':segmentId'
                        )}
                        children={<VolunteersMobile event={data.event} />}
                    />

                    <Route
                        children={
                            <Empty
                                path={HeaventPaths.VOLUNTEERS_SEGMENT(
                                    organizationId,
                                    eventId,
                                    data.event.segments[0].id
                                )}
                            />
                        }
                    />
                </Switch>
                <Switch>
                    <Route
                        path={HeaventPaths.VOLUNTEERS_SEARCH_USER(
                            ':organizationId',
                            ':eventId',
                            ':userInfoId'
                        )}
                        children={<VolunteersSearchUserSheetMobile />}
                    />
                    <Route
                        path={HeaventPaths.VOLUNTEERS_LIST_USER(
                            ':organizationId',
                            ':eventId',
                            ':segmentId',
                            ':userInfoId'
                        )}
                        children={<VolunteersUserSheetMobile />}
                    />
                </Switch>
            </>
        );
    }
};
