import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { Box } from 'common/src/designSystem/components/box';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { EventId, PositionCategoryQuery } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { UpdateDescriptionModal } from './updateDescriptionModal';

interface IPositionCategoryDescriptionProps {
    category: PositionCategoryQuery['event']['positionCategory'];
    eventId: EventId;

    reload(): void;
}

export const PositionCategoryDescription = (props: IPositionCategoryDescriptionProps) => {
    const translate = useTranslate();
    const { isPositionCategoryAdmin } = useEventContext();
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = React.useState(false);
    const hasDescription = isNonEmptyString(props.category.description);

    return (
        <>
            <DetailBox
                title={translate('description_58935')}
                rightIcon={
                    isPositionCategoryAdmin(props.category.id)
                        ? hasDescription
                            ? 'pen'
                            : 'plus'
                        : undefined
                }
                rightIconClick={() => {
                    setIsDescriptionModalOpen(true);
                }}
            >
                {hasDescription ? (
                    <RichText text={props.category.description} />
                ) : (
                    <Box color="gray500" width={1} textAlign="center">
                        {translate('aucune_descript_25737')}
                    </Box>
                )}
            </DetailBox>

            {isPositionCategoryAdmin(props.category.id) && isDescriptionModalOpen && (
                <UpdateDescriptionModal
                    eventId={props.eventId}
                    positionCategory={props.category}
                    onClose={() => {
                        setIsDescriptionModalOpen(false);
                    }}
                    reload={props.reload}
                />
            )}
        </>
    );
};
