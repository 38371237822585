import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { LeaderInfosFragment, LeaderInput } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { AssignLeadersModal } from '../../leaders/assign/assignLeadersModal';
import { PositionCategoryLeadersList } from './positionCategoryLeadersList';

interface IPositionCategoryEditableLeadersProps {
    leaders: LeaderInfosFragment[];
    onSave(leadersInput: LeaderInput[]): Promise<any>;
    reload(): void;
}

export const PositionCategoryEditableLeaders = (props: IPositionCategoryEditableLeadersProps) => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const {
        event: {
            permissions: { isEventAdmin }
        }
    } = useEventContext();
    const [isAssignModalOpen, setIsAssignModalOpen] = React.useState(false);

    return (
        <>
            <DetailBox
                title={translate('responsables_84923')}
                rightIcon={isEventAdmin ? 'pen' : undefined}
                rightIconClick={() => {
                    setIsAssignModalOpen(true);
                }}
            >
                <PositionCategoryLeadersList leaders={props.leaders} />
            </DetailBox>
            {isEventAdmin && isAssignModalOpen && (
                <AssignLeadersModal
                    currentLeaders={props.leaders}
                    eventId={eventId}
                    organizationId={organizationId}
                    onClose={() => {
                        setIsAssignModalOpen(false);
                    }}
                    onSave={async (leaders) => {
                        await props.onSave(leaders);

                        props.reload();
                    }}
                />
            )}
        </>
    );
};
