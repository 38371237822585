import { Alert } from 'common-front/src/designSystem/components/alert';
import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { Radio } from 'common-front/src/designSystem/components/radio';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { Avatar } from 'common/src/components/avatar/avatar';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { noop } from 'lodash-es';
import * as React from 'react';
import { useMergeMembersQuery, useUsersInfosMergeMutation } from '../../../generated/graphqlHooks';

interface IMergeMembersModalProps {
    numbersOfSelected: number;
    organizationId: OrganizationId;
    usersInfosIds: UsersInfoId[];

    onClose(): void;
    onSuccess(): void;
}

export const MergeMembersModal = (props: IMergeMembersModalProps) => {
    const translate = useTranslate();
    const { data, loader } = useMergeMembersQuery({
        organizationId: props.organizationId,
        usersInfosIds: props.usersInfosIds
    });
    const { mutate } = useUsersInfosMergeMutation();
    const [selectedUserInfoId, setSelectedUserInfoId] = React.useState<UsersInfoId | null>(null);

    return (
        <FormModal
            size="md"
            buttonText={translate('fusionner_les_92573', props.numbersOfSelected)}
            icon="sliders"
            initialValues={{}}
            title={translate('fusion_de_membr_34256')}
            onClose={props.onClose}
            onSubmit={async () => {
                if (selectedUserInfoId !== null) {
                    await mutate({
                        organizationId: props.organizationId,
                        mainUserInfoId: selectedUserInfoId,
                        toMergeUsersInfosIds: props.usersInfosIds.filter(
                            (id) => id !== selectedUserInfoId
                        )
                    });

                    props.onSuccess();
                    props.onClose();
                }
            }}
        >
            <Alert color="gray" leftIcon="circle-exclamation">
                {translate('toutes_les_info_39932', props.numbersOfSelected)}
            </Alert>

            <Spacer height="6" />

            <Box font="gray700 textSm medium">{translate('quel_membre_sou_80665')}</Box>

            <Spacer height="4" />

            <Flex direction="column" gap="4" css={{ flex: '1', overflowY: 'auto' }}>
                {loader
                    ? loader
                    : data.organization.usersInfos.nodes.map((userInfo, index) => (
                              <React.Fragment key={userInfo.id}>
                                  {index !== 0 && <Separator direction="horizontal" />}

                                  <Flex
                                      align="center"
                                      gap="3"
                                      css={{ cursor: 'pointer' }}
                                      onClick={() => {
                                          setSelectedUserInfoId(userInfo.id);
                                      }}
                                  >
                                      <Radio
                                          state={
                                              selectedUserInfoId === userInfo.id
                                                  ? 'checked'
                                                  : 'unchecked'
                                          }
                                          onClick={noop}
                                      />

                                      <Avatar
                                          size={48}
                                          email={userInfo.email}
                                          name={userInfo.name}
                                          image={userInfo.picture?.url}
                                      />

                                      <Flex direction="column" gap="1">
                                          <Box font="gray800 textSm medium">
                                              {isNonEmptyString(userInfo.name)
                                                  ? userInfo.name
                                                  : '-'}
                                          </Box>

                                          <Box color="gray500">{userInfo.email}</Box>
                                      </Flex>
                                  </Flex>
                              </React.Fragment>
                          ))}
            </Flex>
        </FormModal>
    );
};
