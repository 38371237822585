import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Segment, SegmentsFolderId } from 'common/src/generated/types';
import * as React from 'react';
import { Draggable, DraggableProvided, Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { SegmentsFoldersReorderSegment } from './segmentsFoldersReorderSegment';

export interface ISegmentFolder {
    id: SegmentsFolderId;
    name: string;
    segments: Array<Pick<Segment, 'id' | 'name'>>;
}

interface ISegmentsFoldersReorderSegmentFolderProps {
    index: number;
    segmentFolder: ISegmentFolder;
}

export const SegmentsFoldersReorderSegmentFolder = (
    props: ISegmentsFoldersReorderSegmentFolderProps
) => (
        <Draggable draggableId={`sf-${props.segmentFolder.id}`} index={props.index}>
            {(draggableProvided: DraggableProvided) => (
                    <Flex
                        direction="column"
                        css={{
                            background: 'white',
                            border: '1px solid $gray200',
                            borderRadius: '$2',
                            boxShadow: '$sm',
                            overflow: 'hidden'
                        }}
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                    >
                        <Flex
                            align="center"
                            gap="3"
                            css={{
                                padding: '$4'
                            }}
                        >
                            <Box
                                color="gray300"
                                fontSize="textMd"
                                css={{ cursor: 'pointer' }}
                                {...draggableProvided.dragHandleProps}
                            >
                                <I style="solid" icon="grip-vertical" />
                            </Box>

                            <Box font="gray800 textMd medium">{props.segmentFolder.name}</Box>
                        </Flex>

                        <Droppable droppableId={`drop-sf-${props.segmentFolder.id}`} type="segment">
                            {(droppableProvided: DroppableProvided) => (
                                    <Flex
                                        direction="column"
                                        gap="2"
                                        css={{
                                            background: '$gray100',
                                            padding: '$4'
                                        }}
                                        {...droppableProvided.droppableProps}
                                        ref={droppableProvided.innerRef}
                                    >
                                        {props.segmentFolder.segments.map((segment, index) => (
                                                <SegmentsFoldersReorderSegment
                                                    key={segment.id}
                                                    index={index}
                                                    segment={segment}
                                                />
                                            ))}

                                        {droppableProvided.placeholder}
                                    </Flex>
                                )}
                        </Droppable>
                    </Flex>
                )}
        </Draggable>
    );
