import { Button } from 'common-front/src/designSystem/components/button';
import { Dropdown } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { Menu } from 'common-front/src/designSystem/components/dropdown/menu';
import { Trigger } from 'common-front/src/designSystem/components/dropdown/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { styled } from 'common/src/designSystem/components/stitches';
import { CheckInState, UserPanelPositionSlotUserInfoFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { DurationService } from 'common/src/services/durationService';
import { IntervalService } from 'common/src/services/intervalService';
import { getColorByBackgroundColor } from 'common/src/util/color';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import {
    usePositionSlotUserInfoCheckInMutation,
    usePositionSlotUserInfoCheckInResetMutation,
    usePositionSlotUserInfoCheckOutMutation,
    useVolunteerRegistrationSlotUnassignMutation
} from '../../../generated/graphqlHooks';

const Icon = styled(Flex, {
    borderRadius: '$2',
    fontSize: '$textSm',
    height: '32px',
    width: '32px',
    '@tablet': {
        borderRadius: '$3',
        fontSize: '$textMd',
        height: '40px',
        width: '40px'
    }
});

interface IUserPanelPositionProps {
    positionSlotUserInfo: UserPanelPositionSlotUserInfoFragment;

    reload(): void;
}

export const UserPanelPosition = (props: IUserPanelPositionProps) => {
    const { history, translate } = useHeavent();
    const { isPositionViewer } = useEventContext();
    const dateTimeService = useService(DateTimeService);
    const durationService = useService(DurationService);
    const intervalService = useService(IntervalService);
    const { mutate: checkIn } = usePositionSlotUserInfoCheckInMutation();
    const { mutate: checkOut } = usePositionSlotUserInfoCheckOutMutation();
    const { mutate: checkInReset } = usePositionSlotUserInfoCheckInResetMutation();
    const { mutate: volunteerRegistrationSlotUnassign } =
        useVolunteerRegistrationSlotUnassignMutation();
    const onMobile = useMobileQuery();
    const timeDifference = React.useMemo(() => {
        if (props.positionSlotUserInfo.checkInAt && props.positionSlotUserInfo.checkOutAt) {
            return durationService.minutesToHoursMinutesString(
                Math.round(
                    props.positionSlotUserInfo.checkOutAt
                        .diff(props.positionSlotUserInfo.checkInAt)
                        .shiftTo('minutes').minutes
                )
            );
        } else {
            return '';
        }
    }, [props.positionSlotUserInfo]);
    const subtitle = React.useMemo(() => {
        const ps = props.positionSlotUserInfo.positionSlot;

        if (isNonEmptyString(ps.name)) {
            return `${ps.name} - ${intervalService.toDisplayString(ps.range)}`;
        } else {
            return intervalService.toDisplayString(ps.range);
        }
    }, [props.positionSlotUserInfo.positionSlot]);
    const canCheckIn = isPositionViewer(
        props.positionSlotUserInfo.positionCategoryId,
        props.positionSlotUserInfo.position.id
    );

    return (
        <Flex
            direction="column"
            width={1}
            css={{
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs',
                cursor: canCheckIn ? 'pointer' : 'auto',
                '@tablet': {
                    borderRadius: '$2'
                }
            }}
            onClick={() => {
                if (canCheckIn) {
                    history.push(
                        HeaventPaths.POSITION(
                            props.positionSlotUserInfo.organizationId,
                            props.positionSlotUserInfo.eventId,
                            props.positionSlotUserInfo.position.id
                        )
                    );
                }
            }}
        >
            <Flex
                align="center"
                gap="3"
                width={1}
                wrap="wrap"
                css={{
                    padding: '$3',
                    '@tablet': {
                        padding: '$3 $4'
                    }
                }}
            >
                <Icon
                    align="center"
                    justify="center"
                    css={{
                        background: props.positionSlotUserInfo.position.color,
                        color: getColorByBackgroundColor(props.positionSlotUserInfo.position.color)
                    }}
                >
                    <I icon={fromIconVO(props.positionSlotUserInfo.position.icon)} />
                </Icon>

                <Flex direction="column" gap="1" css={{ flex: '1', overflow: 'hidden' }}>
                    <Box
                        color="gray800"
                        css={{
                            fontWeight: '$medium',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            '@tablet': { fontWeight: '$semiBold' }
                        }}
                    >
                        {props.positionSlotUserInfo.position.name}
                    </Box>

                    <Box color="gray500" fontSize="textSm">
                        {subtitle}
                    </Box>
                </Flex>
            </Flex>

            {canCheckIn && (
                <Flex
                    align="center"
                    justify="between"
                    width={1}
                    css={{
                        borderTop: '1px solid $gray200',
                        padding: '$3',
                        '@tablet': { padding: '$3 $4' }
                    }}
                >
                    {props.positionSlotUserInfo.state === CheckInState.CheckedOut ? (
                        <Badge leftIcon="clock">
                            {translate('temps_total_79448', timeDifference)}
                        </Badge>
                    ) : props.positionSlotUserInfo.state === CheckInState.CheckedIn ? (
                        <Badge leftIcon="circle" leftIconStyle="solid" color="success">
                            {translate(
                                'point_le_1_63576',
                                dateTimeService.toLocaleString(
                                    props.positionSlotUserInfo.checkInAt!.toLocal(),
                                    onMobile ? LocaleFormats.ShortDateTime : LocaleFormats.DateTime
                                )
                            )}
                        </Badge>
                    ) : (
                        <Badge leftIcon="circle" leftIconStyle="solid" color="warning">
                            {translate('non_point_42145')}
                        </Badge>
                    )}

                    <Flex gap="3">
                        {props.positionSlotUserInfo.state === CheckInState.Waiting && (
                            <Button
                                size="sm"
                                onClick={async (e) => {
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.stopPropagation();

                                    await checkIn({
                                        eventId: props.positionSlotUserInfo.eventId,
                                        positionSlotUserInfoId: props.positionSlotUserInfo.id
                                    });

                                    props.reload();
                                }}
                            >
                                {translate('pointer_80927')}
                            </Button>
                        )}

                        {props.positionSlotUserInfo.state === CheckInState.CheckedIn && (
                            <Button
                                color="white"
                                size="sm"
                                onClick={async (e) => {
                                    e.nativeEvent.stopImmediatePropagation();
                                    e.stopPropagation();

                                    await checkOut({
                                        eventId: props.positionSlotUserInfo.eventId,
                                        positionSlotUserInfoId: props.positionSlotUserInfo.id
                                    });

                                    props.reload();
                                }}
                            >
                                {translate('d_pointer_25328')}
                            </Button>
                        )}

                        <Dropdown>
                            <Trigger>
                                <Box>
                                    <Button color="white" size="sm" rightIcon="ellipsis-vertical" />
                                </Box>
                            </Trigger>

                            <Menu placement="bottom-end">
                                {props.positionSlotUserInfo.state === CheckInState.CheckedOut && (
                                    <ItemIcon
                                        icon="arrows-rotate"
                                        onClick={async (e) => {
                                            e.nativeEvent.stopImmediatePropagation();
                                            e.stopPropagation();

                                            await checkInReset({
                                                eventId: props.positionSlotUserInfo.eventId,
                                                positionSlotUserInfoId:
                                                    props.positionSlotUserInfo.id
                                            });

                                            props.reload();
                                        }}
                                    >
                                        {translate('remettre_z_ro_11572')}
                                    </ItemIcon>
                                )}

                                <ItemIcon
                                    icon="user-xmark"
                                    color="red"
                                    onClick={async () => {
                                        const {
                                            volunteerRegistrationSlotUnassign: memberStillViewable
                                        } = await volunteerRegistrationSlotUnassign({
                                            eventId: props.positionSlotUserInfo.eventId,
                                            volunteerRegistrationId:
                                                props.positionSlotUserInfo.volunteerRegistrationId,
                                            positionSlotId:
                                                props.positionSlotUserInfo.positionSlot.id
                                        });
                                        if (memberStillViewable) {
                                            props.reload();
                                        } else {
                                            history.push(
                                                HeaventPaths.POSITION(
                                                    props.positionSlotUserInfo.organizationId,
                                                    props.positionSlotUserInfo.eventId,
                                                    props.positionSlotUserInfo.position.id
                                                )
                                            );
                                        }
                                    }}
                                >
                                    {translate('d_saffecter_du_47122')}
                                </ItemIcon>
                            </Menu>
                        </Dropdown>
                    </Flex>
                </Flex>
            )}
        </Flex>
    );
};
