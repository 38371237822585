import { Empty } from 'common-front/src/components/empty/empty';
import { MobileLayout } from 'common-front/src/components/mobileLayout/mobileLayout';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { DashboardMobile } from '../../dashboard/mobile/dashboardMobile';
import { EventSwitchSheetMobile } from '../../dashboard/mobile/eventSwitchSheetMobile';
import { useOrganizationContext } from '../../organization/organizationContext';
import { PositionsLoaderMobile } from '../../positions/mobile/list/positionsLoaderMobile';
import { PositionSwitchMobile } from '../../positions/mobile/show/positionSwitchMobile';
import { VolunteersLoaderMobile } from '../../volunteers/mobile/list/volunteersLoaderMobile';

export const EventMobile = () => {
    const { organizationId, eventId } = useParams();
    const { user } = useOrganizationContext();

    return (
        <>
            <Switch>
                <Route
                    path={HeaventPaths.DASHBOARD(':organizationId', ':eventId')}
                    children={
                        <MobileLayout user={user} eventId={eventId}>
                            <DashboardMobile />
                        </MobileLayout>
                    }
                />
                <Route
                    path={HeaventPaths.VOLUNTEERS(':organizationId', ':eventId')}
                    children={
                        <MobileLayout user={user} eventId={eventId}>
                            <VolunteersLoaderMobile />
                        </MobileLayout>
                    }
                />
                <Route
                    path={HeaventPaths.POSITIONS(':organizationId', ':eventId')}
                    children={
                        <MobileLayout user={user} eventId={eventId}>
                            <PositionsLoaderMobile />
                        </MobileLayout>
                    }
                />
                <Route
                    path={HeaventPaths.POSITION(':organizationId', ':eventId', ':positionId')}
                    children={<PositionSwitchMobile />}
                />
                <Route
                    children={<Empty path={HeaventPaths.DASHBOARD(organizationId, eventId)} />}
                />
            </Switch>
            <Switch>
                <Route
                    path={HeaventPaths.DASHBOARD_EVENT_SWITCH(':organizationId', ':eventId')}
                    children={<EventSwitchSheetMobile />}
                />
            </Switch>
        </>
    );
};
