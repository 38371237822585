import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';
import { FiseZone } from './fiseZone';

interface IFiseBadgeVideProps {
    event: DocumentUserCustomBadgeFragment;
}

export const FiseBadgeVide = (props: IFiseBadgeVideProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const aragonAui = vr.accreditationsUsersInfos.find((aui) => aui.accreditation.acronym === 'SA');
    const prodComAui = vr.accreditationsUsersInfos.find(
        (aui) => aui.accreditation.acronym === 'CP'
    );
    const vipAui = vr.accreditationsUsersInfos.find((aui) => aui.accreditation.acronym === 'VIP');
    const presseAui = vr.accreditationsUsersInfos.find((aui) => aui.accreditation.acronym === 'EP');
    const airesSportivesAui = vr.accreditationsUsersInfos.find(
        (aui) => aui.accreditation.acronym === 'AS'
    );
    const tribunesAui = vr.accreditationsUsersInfos.find(
        (aui) => aui.accreditation.acronym === 'TRI'
    );
    const accessAvecAccompagnant = ui.fiseSousPopulation === 'ACCES AUTORISÉ AVEC ACCOMPAGNANT';
    const accessH24AvecAccompagnant =
        ui.fiseSousPopulation === 'ACCES AUTORISÉ H24 AVEC ACCOMPAGNANT';

    return (
        <Flex
            height={559}
            width={387}
            css={{
                background: `url(https://assets.recrewteer.com/badges/fise/vide2.png) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$poppins',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    color: 'black',
                    fontSize: '16px',
                    fontWeight: '600',
                    left: '46px',
                    position: 'absolute',
                    top: '62px'
                }}
            >
                {ui.firstName}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontSize: '16px',
                    fontWeight: '600',
                    left: '46px',
                    position: 'absolute',
                    top: '95px'
                }}
            >
                {ui.lastName}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontSize: '16px',
                    fontWeight: '600',
                    left: '46px',
                    position: 'absolute',
                    top: '128px'
                }}
            >
                {ui.fields.cf25674 || ''}
            </Box>

            <Box
                css={{
                    height: '64px',
                    left: '285px',
                    position: 'absolute',
                    top: '75px',
                    width: '64px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=64&width=64`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>

            {(ui.fisePopulation === 'HURRICANE' ||
                ui.fisePopulation === 'PRESSE & MEDIAS' ||
                ui.fisePopulation === 'ORGANISATION') &&
                (accessAvecAccompagnant || accessH24AvecAccompagnant) && (
                    <Flex
                        align="center"
                        justify="center"
                        css={{
                            background: accessAvecAccompagnant ? '#686868' : '#067918',
                            color: 'white',
                            height: '20px',
                            fontSize: '10px',
                            fontWeight: '600',
                            left: '38px',
                            position: 'absolute',
                            top: '159px',
                            width: '320px'
                        }}
                    >
                        {ui.fiseSousPopulation}
                    </Flex>
                )}

            {aragonAui && (
                <Box
                    css={{
                        left: '48px',
                        position: 'absolute',
                        top: '215px'
                    }}
                >
                    <FiseZone aui={aragonAui} num={1} />
                </Box>
            )}

            {prodComAui && (
                <Box
                    css={{
                        left: '48px',
                        position: 'absolute',
                        top: '248px'
                    }}
                >
                    <FiseZone aui={prodComAui} num={2} />
                </Box>
            )}

            {vipAui && (
                <Box
                    css={{
                        left: '48px',
                        position: 'absolute',
                        top: '281px'
                    }}
                >
                    <FiseZone aui={vipAui} num={3} />
                </Box>
            )}

            {presseAui && (
                <Box
                    css={{
                        left: '212px',
                        position: 'absolute',
                        top: '215px'
                    }}
                >
                    <FiseZone aui={presseAui} num={4} />
                </Box>
            )}

            {airesSportivesAui && (
                <Box
                    css={{
                        left: '212px',
                        position: 'absolute',
                        top: '248px'
                    }}
                >
                    <FiseZone aui={airesSportivesAui} num={5} />
                </Box>
            )}

            {tribunesAui && (
                <Box
                    css={{
                        left: '212px',
                        position: 'absolute',
                        top: '281px'
                    }}
                >
                    <FiseZone aui={tribunesAui} num={6} />
                </Box>
            )}
        </Flex>
    );
};
