import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import { CustomFieldsSegmentsQuery, SegmentId, SegmentType } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { CustomFieldsSegmentsService } from 'common/src/vo/segments/customFieldsSegmentsService';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CustomFieldsContent } from './customFieldsContent';

interface ICustomFieldsProps {
    organization: CustomFieldsSegmentsQuery['organization'];

    reload(): void;
}

export const CustomFields = (props: ICustomFieldsProps) => {
    const {
        history,
        translate,
        params: { organizationId, segmentId }
    } = useHeavent();
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const segmentService = useService(CustomFieldsSegmentsService);
    const filters = React.useMemo(() => segmentService.getCustomFieldsFilters(props.organization), [props.organization]);
    const possibleColumns = React.useMemo(() => segmentService.getCustomFieldsPossibleColumns(), []);

    return (
        <SegmentsGrid
            filters={filters}
            filtersButtonText={translate('filtrer_les_cha_91574')}
            filtersSubtitle={translate('appliquer_des_f_27562')}
            initialLimit={localOrganization?.customFieldsLimit ?? DEFAULT_PAGINATION_LIMIT}
            initialSegmentsOpen={localOrganization?.areCustomFieldsSegmentsOpen ?? true}
            isAdmin={true}
            organizationId={organizationId}
            possibleColumns={possibleColumns}
            segmentId={segmentId}
            segmentType={SegmentType.CustomFields}
            segmentsFolders={props.organization.segmentsFolders}
            getSegmentPath={(id: SegmentId) => HeaventPaths.CUSTOM_FIELDS_SEGMENT(organizationId, id)}
            onSegmentClick={(selectedSegmentId) => {
                updateLocalOrganization({ customFieldsSegmentId: selectedSegmentId });
            }}
            onSegmentDelete={async () => {
                await updateLocalOrganization({ customFieldsSegmentId: null });

                history.push(HeaventPaths.CUSTOM_FIELDS(organizationId));
            }}
            onSegmentsToggle={async (areSegmentsOpen) => {
                await updateLocalOrganization({ areCustomFieldsSegmentsOpen: areSegmentsOpen });
            }}
            reload={props.reload}
            setLimit={(customFieldsLimit) => {
                updateLocalOrganization({ customFieldsLimit });
            }}
        >
            <CustomFieldsContent />
        </SegmentsGrid>
    );
};
