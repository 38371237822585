import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { Box } from 'common/src/designSystem/components/box';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { Accreditation } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IAccreditationDescriptionProps {
    accreditation: Pick<Accreditation, 'description'>;
}

export const AccreditationDescription = (props: IAccreditationDescriptionProps) => {
    const translate = useTranslate();

    return (
        <DetailBox title={translate('description_58935')}>
            {isNonEmptyString(props.accreditation.description) ? (
                <RichText text={props.accreditation.description} />
            ) : (
                <Box color="gray500" width={1} textAlign="center">
                    {translate('aucune_descript_25737')}
                </Box>
            )}
        </DetailBox>
    );
};
