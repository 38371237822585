import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

export const EventsEventSkeleton = () => (
        <Flex
            direction="column"
            css={{
                border: '1px solid $gray200',
                borderRadius: '12px',
                boxShadow: '$xs',
                cursor: 'pointer',
                padding: '$4'
            }}
        >
            <Skeleton height={22} width={65} borderRadius="16px" />

            <Spacer height="1" />

            <Skeleton height={25} width={1} borderRadius="$2" />

            <Spacer height="4" />

            <Skeleton height={46} width={1} borderRadius="$2" />
        </Flex>
    );
