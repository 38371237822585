import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { theme } from 'common/src/designSystem/components/stitches';
import { isNonEmptyArray } from 'common/src/util/array';
import { debugLog } from 'common/src/util/debug';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Form as FormComponent } from '../../components/form/form';
import { Accept } from '../../util/accept';
import { Button } from '../components/button';
import { FileInputValue } from '../components/file/fileInput';
import { ColorInput } from '../form/colorInput';
import { DateInput } from '../form/date/dateInput';
import { FileInput } from '../form/file/fileInput';
import { RichEditor } from '../form/richEditor';
import { RichSelect } from '../form/richSelect';
import { Select } from '../form/select/select';
import { Textarea } from '../form/textarea';
import { TextInput } from '../form/textInput';

interface IValues {
    name: string;
    date: DateTime;
    color: string;
    file: FileInputValue;
    option: string;
    description: string;
    note: string;
    creators: string[];
}

export const Form = () => (
        <Flex direction="column" gap="6" width={1}>
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Form
            </Box>

            <FormComponent
                direction="column"
                height={1}
                width={1}
                initialValues={{
                    name: '',
                    date: DateTime.invalid('Invalid'),
                    color: theme.colors.primary700.value,
                    file: {
                        name: '',
                        content: ''
                    },
                    option: '',
                    description: '',
                    note: '',
                    creators: []
                }}
                validate={(values: IValues) => {
                    const errors: Partial<Record<keyof IValues, string>> = {};

                    if (!isNonEmptyString(values.name)) {
                        errors.name = 'Le nom est obligatoire';
                    }

                    if (!DateTime.isDateTime(values.date) || !values.date.isValid) {
                        errors.date = 'La date est obligatoire';
                    }

                    if (!isNonEmptyString(values.color)) {
                        errors.color = 'La couleur est requise';
                    }

                    if (!isNonEmptyString(values.file?.content)) {
                        errors.file = 'Le fichier est requis';
                    }

                    if (!['1', '2'].includes(values.option)) {
                        errors.option = `L'option est requise`;
                    }

                    if (!isNonEmptyString(values.description)) {
                        errors.description = 'La description est requise';
                    }

                    if (!isNonEmptyString(values.note)) {
                        errors.note = 'La note est requise';
                    }

                    if (!isNonEmptyArray(values.creators)) {
                        errors.creators = 'Au moins un créateur requis';
                    }

                    return errors;
                }}
                onSubmit={(values: IValues) => {
                    debugLog(values);
                }}
                render={({ handleSubmit }) => (
                        <>
                            <TextInput name="name" label="Nom du test" />

                            <Spacer height="6" />

                            <DateInput name="date" label="Date du test" />

                            <Spacer height="6" />

                            <ColorInput name="color" label="Couleur du test" />

                            <Spacer height="6" />

                            <FileInput
                                accept={Accept.Images}
                                format={'base64'}
                                name="file"
                                label="Fichier du test"
                            />

                            <Spacer height="6" />

                            <Select name="option" label="Option du test">
                                <option value="">-</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                            </Select>

                            <Spacer height="6" />

                            <RichEditor name="description" label="Description du test" />

                            <Spacer height="6" />

                            <Textarea name="note" label="Note du test" />

                            <Spacer height="6" />

                            <RichSelect
                                isSearchVisible={true}
                                multiple={true}
                                name="creators"
                                label="Créateurs"
                            >
                                <option value="matt">Matthieu</option>
                                <option value="jc">Jean-Charles</option>
                                <option value="ben">Benjamin</option>
                                <option value="flo">Florent</option>
                            </RichSelect>

                            <Spacer height="6" />

                            <Flex>
                                <Button onClick={handleSubmit}>Créer</Button>
                            </Flex>

                            <Spacer height="6" />
                        </>
                    )}
            />
        </Flex>
    );
