import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { PastourelleAccreditations } from './pastourelleAccreditations';

interface IBadgePastourelleexposantA6FrontProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgePastourelleexposantFront = (props: IBadgePastourelleexposantA6FrontProps) => {
    const userInfo = props.event.volunteerRegistration.userInfo;

    return (
        <Flex
            direction="column"
            height={597}
            width={435}
            css={{
                background: `url(https://assets.recrewteer.com/badges/pastourelle2024/Badge_presta_recto.png) no-repeat`,
                backgroundSize: 'cover',
                overflow: 'hidden',
                position: 'relative'
            }}
        >
            <Box
                font="gray800 displayXs bold"
                textAlign="center"
                width={1}
                css={{
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '430px'
                }}
            >
                {userInfo.pastourelleStatut}
            </Box>

            <PastourelleAccreditations event={props.event} />
        </Flex>
    );
};
