import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IPositionSheetCroixRougePageProps {
    psui: DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'][number];
    userInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo'];
}

export const PositionSheetCroixRougePage = (props: IPositionSheetCroixRougePageProps) => (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/croixrouge/fiche_mission.jpg) no-repeat`,
                backgroundSize: 'cover',
                color: 'black',
                fontFamily: '$poppins',
                fontSize: '12px',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        >
            <Box
                height={120}
                width={120}
                css={{
                    left: '617px',
                    position: 'absolute',
                    top: '43px'
                }}
            >
                {isNonEmptyString(props.userInfo.fields.cf25139) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            props.userInfo.fields.cf25139
                        )}&color=000000&height=120&width=120`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>

            <Box
                css={{
                    fontSize: '27px',
                    fontWeight: '700',
                    left: '345px',
                    position: 'absolute',
                    top: '225px'
                }}
            >
                {props.userInfo.croixRougeDt}
                {props.psui.positionSlot.id}
            </Box>

            <Box
                css={{
                    left: '97px',
                    position: 'absolute',
                    top: '371px'
                }}
            >
                {props.userInfo.lastName}
            </Box>

            <Box
                css={{
                    left: '115px',
                    position: 'absolute',
                    top: '396px'
                }}
            >
                {props.userInfo.firstName}
            </Box>

            <Box
                css={{
                    left: '98px',
                    position: 'absolute',
                    top: '421px'
                }}
            >
                {props.userInfo.fields.cf25139}
            </Box>

            <Box
                css={{
                    left: '244px',
                    position: 'absolute',
                    top: '445px'
                }}
            >
                {props.userInfo.croixRougeDt}
            </Box>

            <Box
                css={{
                    left: '244px',
                    position: 'absolute',
                    top: '539px'
                }}
            >
                {props.psui.position.name}
            </Box>

            <Box
                css={{
                    left: '236px',
                    position: 'absolute',
                    top: '557px'
                }}
            >
                {props.psui.positionSlot.range.start!.toFormat('dd/MM/yyyy HH:mm', {
                    locale: 'fr'
                })}
            </Box>

            <Box
                css={{
                    left: '216px',
                    position: 'absolute',
                    top: '575px'
                }}
            >
                {props.psui.positionSlot.range.end!.toFormat('dd/MM/yyyy HH:mm', {
                    locale: 'fr'
                })}
            </Box>

            <Box
                css={{
                    left: '203px',
                    position: 'absolute',
                    top: '658px'
                }}
            >
                {props.psui.position.address}
            </Box>

            <Box
                css={{
                    left: '198px',
                    position: 'absolute',
                    top: '676px'
                }}
            >
                {props.psui.positionSlot.range.start!.toFormat('dd/MM/yyyy HH:mm', {
                    locale: 'fr'
                })}
            </Box>

            <Box
                css={{
                    left: '56px',
                    position: 'absolute',
                    top: '865px'
                }}
            >
                {props.psui.position.fields.cf32969}
            </Box>

            <Box
                css={{
                    left: '56px',
                    position: 'absolute',
                    top: '949px'
                }}
            >
                {props.psui.position.fields.cf32968}
            </Box>
        </Flex>
    );
