import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

const ACCESS_FESTIVAL_ID = 3039;
const ESPACE_VIP_ID = 2362;

interface IBadgeJardinSonorePartenaireProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeJardinSonorePartenaire = (props: IBadgeJardinSonorePartenaireProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const access = sortBy(
        vr.accreditationsUsersInfos.flatMap((aui) => {
            if (
                aui.accreditation.id === ACCESS_FESTIVAL_ID &&
                aui.accreditationSlot.date?.isValid
            ) {
                return [aui.accreditationSlot.date!];
            } else {
                return [];
            }
        }),
        (d) => d.toMillis()
    )
        .map((d) => d.toFormat('dd/MMyy'))
        .join(' - ');
    const hasVIP = vr.accreditationsUsersInfos.filter(
        (aui) => aui.accreditation.id === ESPACE_VIP_ID
    );

    return (
        <>
            <Flex
                height={559}
                width={397}
                css={{
                    background:
                        'url(https://assets.recrewteer.com/badges/jardinSonore/jardinSonoreBadgePartenaireFront.png) no-repeat',
                    backgroundSize: 'cover',
                    color: 'black',
                    position: 'relative'
                }}
            >
                <Box
                    height={88}
                    width={88}
                    css={{
                        borderRadius: '88px',
                        left: '75px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '131px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    css={{
                        fontSize: '32px',
                        fontWeight: '700',
                        left: '186px',
                        position: 'absolute',
                        top: '127px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        fontSize: '32px',
                        fontWeight: '700',
                        left: '186px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '165px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    css={{
                        fontSize: '18px',
                        fontWeight: '500',
                        left: '186px',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '203px'
                    }}
                >
                    {ui.fields.cf29485}
                </Box>

                <Box
                    css={{
                        fontSize: '18px',
                        fontWeight: '700',
                        position: 'absolute',
                        px: '37px',
                        top: '309px'
                    }}
                >
                    {access}
                </Box>

                <Flex
                    gap="3"
                    css={{
                        position: 'absolute',
                        px: '37px',
                        top: '375px'
                    }}
                >
                    {isNonEmptyString(access) && (
                        <Flex
                            align="center"
                            justify="center"
                            width={155}
                            css={{
                                background: '#1b5f81',
                                borderRadius: '$1',
                                color: '#f4ecd5',
                                py: '6px'
                            }}
                        >
                            FESTIVAL
                        </Flex>
                    )}

                    {hasVIP && (
                        <Flex
                            align="center"
                            justify="center"
                            width={155}
                            css={{
                                background: '#f4ecd5',
                                borderRadius: '$1',
                                color: 'black',
                                py: '6px'
                            }}
                        >
                            ESPACE VIP
                        </Flex>
                    )}
                </Flex>
            </Flex>

            <Flex
                height={559}
                width={397}
                css={{
                    background:
                        'url(https://assets.recrewteer.com/badges/jardinSonore/jardinSonoreBadgePartenaireBack.png) no-repeat',
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />
        </>
    );
};
