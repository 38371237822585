import { Alert } from 'common-front/src/designSystem/components/alert';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const VolunteersImportProcessingAlert = () => {
    const translate = useTranslate();

    return (
        <>
            <Alert color="primary" leftIcon="clock">
                {translate('votre_import_es_05273')}
            </Alert>

            <Spacer height="6" />
        </>
    );
};
