import { PositionSlotsMobileQuery } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';

export const slotName = (
    slot: Pick<
        PositionSlotsMobileQuery['event']['positionsSlots']['nodes'][number],
        'name' | 'range'
    >,
    intervalService: IntervalService
) => (
        slot.name ||
        intervalService.toLocaleString(slot.range, undefined, {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        })
    );
