import { uniq } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A6_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { BadgeJokersfrancesloveniaZone } from './badgeJokersfrancesloveniaZone';
import { BadgeJokersfrancesloveniaZoneEmpty } from './badgeJokersfrancesloveniaZoneEmpty';

interface IBadgeJokersfrancesloveniaA6FrontProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeJokersfrancesloveniaA6Front = (props: IBadgeJokersfrancesloveniaA6FrontProps) => {
    const userInfo = props.event.volunteerRegistration.userInfo;
    const categories = uniq(
        props.event.volunteerRegistration.positionsSlotsUsersInfos.map(
            (psui) => psui.positionCategory.name
        )
    );
    const accreditations = props.event.volunteerRegistration.accreditationsUsersInfos;
    const ice = accreditations.find((a) => a.accreditation.acronym === 'ICE');
    const press = accreditations.find((a) => a.accreditation.acronym === 'PRESS');
    const vip = accreditations.find((a) => a.accreditation.acronym === 'VIP');
    const loc = accreditations.find((a) => a.accreditation.acronym === 'LOC');
    const tri = accreditations.find((a) => a.accreditation.acronym === 'TRI');
    const acc = accreditations.find((a) => a.accreditation.acronym === 'ACC');

    return (
        <Flex
            direction="column"
            height={A6_SIZES['96dpi'].height}
            width={A6_SIZES['96dpi'].width}
            css={{
                color: 'black',
                background: `url(https://assets.recrewteer.com/badges/jokers/jokersfrancesloveniafront.png) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$anton',
                overflow: 'hidden',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    height: '86px',
                    left: '39px',
                    position: 'absolute',
                    top: '164px',
                    width: '86px'
                }}
            >
                {isNonEmptyString(userInfo.fields.picture?.url) && (
                    <img src={userInfo.fields.picture?.url} height="85px" width="85px" />
                )}
            </Box>

            <Flex
                align="center"
                css={{
                    height: '23px',
                    left: '141px',
                    position: 'absolute',
                    px: '4px',
                    top: '167px',
                    width: '200px'
                }}
            >
                {userInfo.fields.lastName}
            </Flex>

            <Flex
                align="center"
                css={{
                    height: '23px',
                    left: '141px',
                    position: 'absolute',
                    px: '4px',
                    top: '196px',
                    width: '200px'
                }}
            >
                {userInfo.firstName}
            </Flex>

            <Flex
                align="center"
                css={{
                    height: '23px',
                    left: '141px',
                    position: 'absolute',
                    px: '4px',
                    top: '226px',
                    width: '200px'
                }}
            >
                {categories.join(', ')}
            </Flex>

            <Box
                css={{
                    height: '84px',
                    left: '141px',
                    padding: '4px',
                    position: 'absolute',
                    top: '255px',
                    width: '84px'
                }}
            >
                {isNonEmptyString(
                    props.event.volunteerRegistration.weezeventParticipantInfo?.idBarcode
                ) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            props.event.volunteerRegistration.weezeventParticipantInfo!.idBarcode
                        )}&color=000000`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>

            <Flex
                justify="between"
                css={{
                    left: '54px',
                    position: 'absolute',
                    top: '380px',
                    width: '200px'
                }}
            >
                {ice ? (
                    <BadgeJokersfrancesloveniaZone accreditation={ice.accreditation} />
                ) : (
                    <BadgeJokersfrancesloveniaZoneEmpty />
                )}

                {press ? (
                    <BadgeJokersfrancesloveniaZone accreditation={press.accreditation} />
                ) : (
                    <BadgeJokersfrancesloveniaZoneEmpty />
                )}

                {vip ? (
                    <BadgeJokersfrancesloveniaZone accreditation={vip.accreditation} />
                ) : (
                    <BadgeJokersfrancesloveniaZoneEmpty />
                )}
            </Flex>

            <Flex
                justify="between"
                css={{
                    left: '54px',
                    position: 'absolute',
                    top: '440px',
                    width: '200px'
                }}
            >
                {loc ? (
                    <BadgeJokersfrancesloveniaZone accreditation={loc.accreditation} />
                ) : (
                    <BadgeJokersfrancesloveniaZoneEmpty />
                )}

                {tri ? (
                    <BadgeJokersfrancesloveniaZone accreditation={tri.accreditation} />
                ) : (
                    <BadgeJokersfrancesloveniaZoneEmpty />
                )}

                {acc ? (
                    <BadgeJokersfrancesloveniaZone accreditation={acc.accreditation} />
                ) : (
                    <BadgeJokersfrancesloveniaZoneEmpty />
                )}
            </Flex>
        </Flex>
    );
};
