import { Button, IButtonColor } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { copy } from 'common-front/src/util/commands';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CSS } from 'common/src/designSystem/components/stitches';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { noop } from 'lodash-es';
import * as React from 'react';

interface IKeyProps {
    buttonColor?: IButtonColor;
    css?: CSS;
    link: string;
    title: string;
}

export const Key = ({ title, link, css, buttonColor }: IKeyProps) => {
    const translate = useTranslate();
    const [isLoading, setIsLoading] = React.useState(false);

    return (
        <Box>
            <Box color="gray700" css={css}>
                {title}
            </Box>

            <Spacer height="1" />

            <Flex gap="2">
                <TextInput state="disabled" value={link} onChange={noop} />

                <Button
                    leftIcon="copy"
                    color={buttonColor}
                    isLoading={isLoading}
                    onClick={() => {
                        setIsLoading(true);
                        copy(link);

                        setTimeout(() => {
                            setIsLoading(false);
                        }, 500);
                    }}
                >
                    {translate('copier_75889')}
                </Button>
            </Flex>
        </Box>
    );
};
