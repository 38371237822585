import { NoFeature } from 'common-front/src/components/noFeature';
import { Flex } from 'common/src/designSystem/components/flex';
import { Feature } from 'common/src/generated/types';
import * as React from 'react';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { CommunityDelegationsLoader } from './communityDelegationsLoader';

export const CommunityDelegationsHasFeature = () => {
    const { hasFeature } = useOrganizationContext();

    return hasFeature(Feature.Delegation) ? (
        <CommunityDelegationsLoader />
    ) : (
        <Flex align="center" justify="center" height={1} width={1}>
            <NoFeature icon="people-group" />
        </Flex>
    );
};
