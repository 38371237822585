import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import {
    EventId,
    OrganizationId,
    SegmentId,
    SegmentsFoldersSegmentsFragment,
    SegmentType,
    UsersInfoId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import {
    Columns as ColumnsVO,
    Filter,
    FilterPredicate,
    partitionFiltersPredicates,
    PossibleColumn,
    Predicates
} from 'common/src/vo/segment';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { ICheckboxState } from '../designSystem/components/checkbox';
import { OrderRightPanel } from '../designSystem/components/orderRightPanel';
import { useDebounce } from '../hooks/useDebounce';
import { useMassActions } from '../hooks/useMassActions';
import { Filters } from './filters/filters';
import { Segments } from './list/segments';
import { SegmentsClosed } from './list/segmentsClosed';
import { SaveSegment } from './modal/saveSegment';
import { SegmentsContext, useSegmentsContext } from './segmentsContext';

interface ISegmentsGridComponentProps {
    children: React.ReactNode;
    filtersButtonText: string;
    filtersSubtitle: string;
    hideSegments?: boolean;
    segmentsFolders: SegmentsFoldersSegmentsFragment[];

    onSegmentDelete(): Promise<any>;
}

const SegmentsGridComponent = (props: ISegmentsGridComponentProps) => {
    const translate = useTranslate();
    const {
        areColumnsOpen,
        areSegmentsOpen,
        clearNameCursors,
        columns,
        filters,
        isFilterOpen,
        isSaveSegmentVisible,
        possibleColumns,
        predicates,
        selectedFilterPredicate,
        setAreColumnsOpen,
        setColumns,
        setIsFilterOpen,
        setPredicates
    } = useSegmentsContext();

    return (
        <>
            <Grid
                gridtemplatecolumns={areSegmentsOpen ? '260px 1fr' : '48px 1fr'}
                height={1}
                width={1}
            >
                {props.hideSegments !== true && (
                    <Box
                        height={1}
                        css={{
                            gridColumn: '1 / 2',
                            overflow: 'hidden'
                        }}
                    >
                        {areSegmentsOpen ? (
                            <Segments
                                segmentsFolders={props.segmentsFolders}
                                onSegmentDelete={props.onSegmentDelete}
                            />
                        ) : (
                            <SegmentsClosed />
                        )}
                    </Box>
                )}

                <Flex
                    direction="column"
                    height={1}
                    css={{
                        gridColumn: props.hideSegments ? '1 / 3' : '2 / 3',
                        overflowY: 'auto',
                        padding: '$7'
                    }}
                >
                    {props.children}
                </Flex>
            </Grid>

            {isFilterOpen && (
                <Filters
                    buttonText={props.filtersButtonText}
                    possibleFilters={filters}
                    predicates={predicates}
                    selectedFilterPredicate={selectedFilterPredicate}
                    subtitle={props.filtersSubtitle}
                    onClose={() => {
                        setIsFilterOpen(false);
                    }}
                    onFilter={(newPredicates) => {
                        clearNameCursors();
                        setPredicates(newPredicates);
                    }}
                />
            )}

            {areColumnsOpen && (
                <OrderRightPanel
                    columns={possibleColumns}
                    initialSelectedColumns={columns}
                    saveButtonText={translate('_diter_les_colo_39630')}
                    searchPlaceholder={translate('rechercher_un_c_86475')}
                    subtitle={translate('personnaliser_l_03763')}
                    title={translate('_dition_des_col_70300')}
                    onClose={() => {
                        setAreColumnsOpen(false);
                    }}
                    onSave={setColumns}
                />
            )}

            {isSaveSegmentVisible && <SaveSegment segmentsFolders={props.segmentsFolders} />}
        </>
    );
};

interface ISegmentsGridProps {
    children: React.ReactNode;
    eventId?: EventId;
    filters: Filter[];
    filtersButtonText: string;
    filtersSubtitle: string;
    hideSegments?: boolean;
    initialLimit: number;
    initialSegmentsOpen: boolean;
    isAdmin: boolean;
    organizationId: OrganizationId;
    possibleColumns: PossibleColumn[];
    segmentId: SegmentId;
    segmentType: SegmentType;
    segmentsFolders: SegmentsFoldersSegmentsFragment[];

    getSegmentPath(id: SegmentId): string;
    onSegmentClick(segmentId: SegmentId): void;
    onSegmentDelete(): Promise<any>;
    onSegmentsToggle(areSegmentsOpen: boolean): void;
    reload(): void;
    setLimit(limit: number): void;
}

export const SegmentsGrid = (props: ISegmentsGridProps) => {
    const segments = React.useMemo(() => props.segmentsFolders.flatMap((sf) => sf.segments), [props.segmentsFolders]);
    const segment = React.useMemo(() => segments.find((s) => s.id === props.segmentId)!, [props.segmentId, segments]);
    const [predicates, setPredicates] = React.useState<Predicates>(segment.predicates);
    const [columns, setColumns] = React.useState<ColumnsVO>(segment.columns);
    const [columnsExport, setColumnsExport] = React.useState<ColumnsVO>(segment.columnsExport);
    const [sort, _setSort] = React.useState<Sort<any> | null>(segment.sort as any);
    const initialPredicatesString = React.useMemo(() => JSON.stringify(segment.predicates), [segment]);
    const initialColumnsString = React.useMemo(() => JSON.stringify(segment.columns), [segment]);
    const initialColumnsExportString = React.useMemo(() => JSON.stringify(segment.columnsExport), [segment]);
    const initialSortString = React.useMemo(() => JSON.stringify(segment.sort), [segment]);
    const [filtersPredicates] = React.useMemo(() => partitionFiltersPredicates(predicates, props.filters), [predicates, props.filters]);
    const [isEditMode, setIsEditMode] = React.useState(false);
    const [isFilterOpen, setIsFilterOpen] = React.useState(false);
    const [areColumnsOpen, setAreColumnsOpen] = React.useState(false);
    const [selectedFilterPredicate, setSelectedFilterPredicate] =
        React.useState<FilterPredicate | null>(null);
    const [isSaveSegmentVisible, setIsSaveSegmentVisible] = React.useState(false);
    const [areSegmentsOpen, _setAreSegmentsOpen] = React.useState(
        props.initialSegmentsOpen ?? true
    );
    const setAreSegmentsOpen = React.useCallback(
        (newAreSegmentOpen: boolean) => {
            props.onSegmentsToggle?.(newAreSegmentOpen);

            _setAreSegmentsOpen(newAreSegmentOpen);
        },
        [_setAreSegmentsOpen]
    );
    const isSaveVisible = React.useMemo(() => (
            JSON.stringify(predicates) !== initialPredicatesString ||
            JSON.stringify(columns) !== initialColumnsString ||
            JSON.stringify(columnsExport) !== initialColumnsExportString ||
            JSON.stringify(sort) !== initialSortString
        ), [
        initialPredicatesString,
        predicates,
        initialColumnsString,
        columns,
        initialColumnsExportString,
        columnsExport,
        initialSortString,
        sort
    ]);
    const [name, setName] = React.useState('');
    const [nameDebounced, _setNameDebounced] = React.useState('');
    const [limit, _setLimit] = React.useState(props.initialLimit);
    const [offset, setOffset] = React.useState(0);
    const setNameDebounced = useDebounce((newName: string) => {
        _setNameDebounced(newName);
        clearCursors();
    });
    const [reloadKey, setReloadKey] = React.useState(1);
    const clearCursors = React.useCallback(() => {
        setOffset(0);
    }, [setOffset]);
    const setLimit = React.useCallback(
        (newLimit: number) => {
            _setLimit(newLimit);
            props.setLimit(newLimit);
            clearCursors();
        },
        [_setLimit, clearCursors]
    );
    const clearNameCursors = React.useCallback(() => {
        setName('');
        _setNameDebounced('');
        clearCursors();
    }, [setName, _setNameDebounced, clearCursors]);
    const { numberOfSelected, selectAllState, states, setStates, toggleRow, toggleSelectAll } =
        useMassActions<UsersInfoId>({});
    const [isSegmentSelected, setIsSegmentSelected] = React.useState(false);
    const _toggleRow = React.useCallback(
        (key: string, newState: ICheckboxState) => {
            toggleRow(key, newState);

            if (newState === 'unchecked') {
                setIsSegmentSelected(false);
            }
        },
        [toggleRow, setIsSegmentSelected]
    );
    const onMassEditSuccess = React.useCallback(() => {
        toggleSelectAll('unchecked');
        setIsSegmentSelected(false);
        setReloadKey(reloadKey + 1);
    }, [reloadKey]);
    const massEditSelectedIds = React.useMemo(() => Object.values(states)
            .filter((s) => s.state === 'checked')
            .map((s) => s.id), [states]);
    const massEditSegmentId = React.useMemo(() => isSegmentSelected ? segment.id : null, [isSegmentSelected, segment]);
    const setSort = React.useCallback(
        (newSort: Sort<any> | null) => {
            _setSort(newSort);
            setOffset(0);
        },
        [_setSort, setOffset]
    );

    React.useEffect(() => {
        setPredicates(segment.predicates);
        setColumns(segment.columns);
        setColumnsExport(segment.columnsExport);
        _setSort(segment.sort as any);
        setName('');
        _setNameDebounced('');
        clearCursors();
        setReloadKey(1);
    }, [segment]);

    return (
        <SegmentsContext.Provider
            value={{
                areColumnsOpen,
                areSegmentsOpen,
                columns,
                columnsExport,
                eventId: props.eventId,
                filters: props.filters,
                filtersPredicates,
                isAdmin: props.isAdmin,
                isEditMode,
                isFilterOpen,
                isSaveSegmentVisible,
                isSaveVisible,
                isSegmentSelected,
                limit,
                massEditSelectedIds,
                massEditSegmentId,
                name,
                nameDebounced,
                numberOfSelected,
                offset,
                organizationId: props.organizationId,
                possibleColumns: props.possibleColumns,
                predicates,
                reloadKey,
                segment,
                segmentId: props.segmentId,
                segmentType: props.segmentType,
                selectAllState,
                selectedFilterPredicate,
                sort,
                states,
                clearNameCursors,
                getSegmentPath: props.getSegmentPath,
                onMassEditSuccess,
                onSegmentClick: props.onSegmentClick,
                reload: props.reload,
                setAreColumnsOpen,
                setAreSegmentsOpen,
                setColumns,
                setColumnsExport,
                setIsEditMode,
                setIsFilterOpen,
                setIsSaveSegmentVisible,
                setIsSegmentSelected,
                setLimit,
                setName,
                setNameDebounced,
                setOffset,
                setPredicates,
                setReloadKey,
                setSelectedFilterPredicate,
                setSort,
                setStates,
                toggleRow: _toggleRow,
                toggleSelectAll
            }}
        >
            <SegmentsGridComponent
                filtersButtonText={props.filtersButtonText}
                filtersSubtitle={props.filtersSubtitle}
                hideSegments={props.hideSegments}
                segmentsFolders={props.segmentsFolders}
                onSegmentDelete={props.onSegmentDelete}
            >
                {props.children}
            </SegmentsGridComponent>
        </SegmentsContext.Provider>
    );
};
