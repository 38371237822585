import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeJardinSonorePhotographePageProps {
    children: React.ReactNode;
    date: number;
    userInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo'];
}

export const BadgeJardinSonorePhotographePage = (props: IBadgeJardinSonorePhotographePageProps) => (
        <Flex height={559} width={749} css={{ pageBreakBefore: 'always' }}>
            <Flex
                height={559}
                width={397}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/jardinSonore/photographe/recto_${props.date}.png) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$dinCondensed',
                    position: 'relative'
                }}
            >
                <Flex
                    gap="5"
                    width={1}
                    css={{
                        position: 'absolute',
                        px: '77px',
                        top: '127px'
                    }}
                >
                    <Box
                        height={88}
                        width={88}
                        css={{
                            borderRadius: '88px',
                            overflow: 'hidden'
                        }}
                    >
                        {isNonEmptyString(props.userInfo.picture?.url) && (
                            <img src={props.userInfo.picture!.url} height="100%" width="100%" />
                        )}
                    </Box>

                    <Flex
                        direction="column"
                        align="center"
                        css={{
                            color: 'black',
                            flex: '1',
                            fontSize: '32px',
                            fontWeight: '700'
                        }}
                    >
                        <Box>{props.userInfo.firstName}</Box>
                        <Box css={{ textTransform: 'uppercase' }}>{props.userInfo.lastName}</Box>
                    </Flex>
                </Flex>

                <Flex
                    direction="column"
                    wrap="wrap"
                    height={190}
                    css={{
                        gap: '10px 24px',
                        position: 'absolute',
                        px: '37px',
                        top: '239px'
                    }}
                >
                    {props.children}
                </Flex>
            </Flex>

            <Flex
                height={559}
                width={397}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/jardinSonore/photographe/verso_${props.date}.png) no-repeat`,
                    backgroundSize: 'cover',
                    position: 'relative'
                }}
            />
        </Flex>
    );
