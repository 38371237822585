import { Avatar } from 'common/src/components/avatar/avatar';
import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import * as React from 'react';
import { useOrganizationContext } from '../../../organization/organizationContext';

const Background = styled(Flex, {
    backgroundColor: '$gray900',
    height: '110px',
    marginBottom: '$11',
    '> *': { transform: 'translateY(50%)', boxShadow: '$md' }
});

export const ProfileAvatarMobile = () => {
    const { user } = useOrganizationContext();

    return (
        <Background justify="center" align="end">
            <Avatar
                email={user.email}
                name={user.userInfo.name}
                image={user.userInfo.picture?.url}
                size={96}
            />
        </Background>
    );
};
