import { DateInput } from 'common-front/src/designSystem/components/date/dateInput';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Event } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';

const SLOTS_PER_PAGE = 10;

interface ICreateSlotsProps<T> {
    event: Pick<Event, 'startAt' | 'endAt'>;
    slots: T[];

    change(name: string, value: any): void;
    matchDateFilter(slot: T, date: DateTime, interval: Interval): boolean;
    onNewSlot(): void;
    renderSlot(slot: T & { index: number }): React.ReactNode;
}

export const CreateSlots = <T extends {}>(props: ICreateSlotsProps<T>) => {
    const translate = useTranslate();
    const [date, setDate] = React.useState(DateTime.invalid('Invalid'));
    const [offset, setOffset] = React.useState(0);
    const slotsWithIndex = React.useMemo(() => props.slots.map((slot, index) => ({
            ...slot,
            index
        })), [props.slots]);
    const slotsFiltered = React.useMemo(() => {
        const dateInterval = Interval.fromDateTimes(date.startOf('day'), date.endOf('day'));

        if (date.isValid) {
            return slotsWithIndex.filter((slot) => props.matchDateFilter(slot, date, dateInterval));
        } else {
            return slotsWithIndex;
        }
    }, [date, slotsWithIndex, props.matchDateFilter]);
    const slotsToDislay = React.useMemo(() => slotsFiltered.slice(offset, SLOTS_PER_PAGE + offset), [offset, slotsFiltered]);
    const numberOfPages = React.useMemo(() => Math.ceil(slotsFiltered.length / SLOTS_PER_PAGE), [slotsFiltered]);
    const totalCount = React.useMemo(() => slotsFiltered.length, [slotsFiltered]);
    const _setDate = (newDate: DateTime) => {
        setDate(newDate);
        setOffset(0);
    };

    return (
        <Flex direction="column" gap="4">
            <Flex align="center" gap="3" width={1}>
                <Box font="gray800 textMd medium" css={{ flex: '1' }}>
                    {translate('liste_de_vos_cr_37612')}
                </Box>

                <Box width={200}>
                    <DateInput
                        min={props.event.startAt}
                        max={props.event.endAt}
                        value={date}
                        onChange={_setDate}
                    />
                </Box>
            </Flex>

            <Flex
                direction="column"
                gap="3"
                css={{
                    background: '$gray100',
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    boxShadow: '$xs',
                    padding: '$6'
                }}
            >
                {slotsToDislay.map((slot) => props.renderSlot(slot))}

                <PaginationCell
                    numberOfPages={numberOfPages}
                    offset={offset}
                    totalCount={totalCount}
                    setOffset={setOffset}
                    limit={SLOTS_PER_PAGE}
                />
            </Flex>

            <Box
                font="primary700 textSm medium"
                css={{ cursor: 'pointer' }}
                onClick={props.onNewSlot}
            >
                + {translate('ajouter_un_cr_n_62790')}
            </Box>
        </Flex>
    );
};
