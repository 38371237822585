import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeZamnaProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeZamna = (props: IBadgeZamnaProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const background =
        ui.zamnaPopulation === 'Bénévoles'
            ? 'https://assets.recrewteer.com/badges/zamna/verso_benevole.jpg'
            : 'https://assets.recrewteer.com/badges/zamna/verso_staff.jpg';

    return (
        <Flex height={321} width={408}>
            <Flex
                height={321}
                width={204}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/zamna/recto.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$encode',
                    position: 'relative'
                }}
            >
                <Box
                    height={56}
                    width={56}
                    css={{
                        borderRadius: '56px',
                        left: '74px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '131px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    width={152}
                    textAlign="center"
                    css={{
                        fontWeight: '600',
                        left: '26px',
                        position: 'absolute',
                        top: '195px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    width={152}
                    textAlign="center"
                    css={{
                        fontWeight: '600',
                        left: '26px',
                        position: 'absolute',
                        top: '214px'
                    }}
                >
                    {ui.lastName}
                </Box>
            </Flex>

            <Flex
                height={321}
                width={204}
                css={{
                    background: `url(${background}) no-repeat`,
                    backgroundSize: 'cover'
                }}
            />
        </Flex>
    );
};
