import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Tab } from '../components/tabs/tab';
import { TabList } from '../components/tabs/tabList';
import { TabPanel } from '../components/tabs/tabPanel';
import { Tabs as TabsComponent } from '../components/tabs/tabs';

export const Tabs = () => (
        <Flex direction="column" gap="6" width={1} height="fit-content">
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Tabs
            </Box>

            <Flex width={1}>
                <TabsComponent>
                    <TabList>
                        <Tab path={HeaventPaths.DESIGN_SYSTEM_TABS_ONE}>One</Tab>
                        <Tab path={HeaventPaths.DESIGN_SYSTEM_TABS_TWO}>Two</Tab>
                        <Tab path={HeaventPaths.DESIGN_SYSTEM_TABS_THREE}>Three</Tab>
                    </TabList>

                    <TabPanel path={HeaventPaths.DESIGN_SYSTEM_TABS_ONE}>Premier tab</TabPanel>
                    <TabPanel path={HeaventPaths.DESIGN_SYSTEM_TABS_TWO}>Deuxième tab</TabPanel>
                    <TabPanel path={HeaventPaths.DESIGN_SYSTEM_TABS_THREE}>Troisième tab</TabPanel>
                </TabsComponent>
            </Flex>
        </Flex>
    );
