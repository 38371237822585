import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    VolunteerRegistrationFragment,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { getWishedAccreditationsBadges } from 'common/src/vo/accreditation';
import * as React from 'react';

interface IVolunteerRegistrationWishedAccreditationsProps {
    firstCellCss: any;
    volunteerRegistration: VolunteerRegistrationFragment;

    setIsSidebarOpen(isOpen: boolean): void;
    setVolunteerRegistrationId?(volunteerRegistrationId: VolunteersRegistrationId): void;
}

export const VolunteerRegistrationWishedAccreditations = ({
    firstCellCss,
    setIsSidebarOpen,
    setVolunteerRegistrationId,
    volunteerRegistration
}: IVolunteerRegistrationWishedAccreditationsProps) => {
    const dateTimeService = useService(DateTimeService);
    const badges = React.useMemo(() => getWishedAccreditationsBadges(
            dateTimeService,
            volunteerRegistration?.accreditationsSlots || []
        ), [volunteerRegistration]);

    return (
        <Cell css={firstCellCss}>
            <BadgesPlus
                badges={badges}
                doNotSort={true}
                setIsSidebarOpen={setIsSidebarOpen}
                setVolunteerRegistrationId={() =>
                    setVolunteerRegistrationId?.(volunteerRegistration.id)
                }
            />
        </Cell>
    );
};
