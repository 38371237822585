import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    AccreditationId,
    AccreditationsQuery,
    EventId,
    OrganizationId,
    SegmentId
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { AccreditationRowDropdown } from './accreditationRowDropdown';
import { AccreditationsAcronymCell } from './cells/accreditationsAcronymCell';
import { AccreditationsCategoryCell } from './cells/accreditationsCategoryCell';
import { AccreditationsColorCell } from './cells/accreditationsColorCell';
import { AccreditationsIconCell } from './cells/accreditationsIconCell';
import { AccreditationsNameCell } from './cells/accreditationsNameCell';
import { AccreditationsTagsCell } from './cells/accreditationsTagsCell';

interface IAccreditationRowProps {
    accreditation: AccreditationsQuery['event']['accreditations']['nodes'][0];
    columns: PossibleColumn[];
    eventId: EventId;
    organizationId: OrganizationId;
    segmentId: SegmentId;
    state: ICheckboxState;

    reload(): void;
    reloadAccreditation(id: AccreditationId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const AccreditationRow = (props: IAccreditationRowProps) => {
    const { history, translate } = useHeavent();
    const reloadAccreditation = React.useCallback(() => {
        props.reloadAccreditation(props.accreditation.id);
    }, [props.accreditation, props.reloadAccreditation]);

    return (
        <Row
            css={{
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            <Cell width={48} css={{ paddingRight: 0 }}>
                <Checkbox
                    state={props.state}
                    onClick={(newState, e) => {
                        e.nativeEvent.stopImmediatePropagation();
                        e.stopPropagation();

                        props.toggleRow(`a${props.accreditation.id}`, newState);
                    }}
                />
            </Cell>

            {props.columns.map((field, index) => {
                const firstCellCss = index === 0 ? { paddingLeft: '$3' } : {};

                return field.slug === 'category' ? (
                    <AccreditationsCategoryCell
                        key={field.slug}
                        accreditation={props.accreditation}
                        firstCellCss={firstCellCss}
                        reload={reloadAccreditation}
                    />
                ) : field.slug === 'name' ? (
                    <AccreditationsNameCell
                        key={field.slug}
                        accreditation={props.accreditation}
                        firstCellCss={firstCellCss}
                        reload={reloadAccreditation}
                    />
                ) : field.slug === 'tags' ? (
                    <AccreditationsTagsCell
                        key={field.slug}
                        accreditation={props.accreditation}
                        firstCellCss={firstCellCss}
                        reload={reloadAccreditation}
                    />
                ) : field.slug === 'address' ? (
                    <Cell key={field.slug} css={firstCellCss}>
                        {props.accreditation.address}
                    </Cell>
                ) : field.slug === 'latitude' ? (
                    <Cell key={field.slug} css={firstCellCss}>
                        {props.accreditation.latitude}
                    </Cell>
                ) : field.slug === 'longitude' ? (
                    <Cell key={field.slug} css={firstCellCss}>
                        {props.accreditation.longitude}
                    </Cell>
                ) : field.slug === 'acronym' ? (
                    <AccreditationsAcronymCell
                        key={field.slug}
                        accreditation={props.accreditation}
                        firstCellCss={firstCellCss}
                        reload={reloadAccreditation}
                    />
                ) : field.slug === 'color' ? (
                    <AccreditationsColorCell
                        key={field.slug}
                        accreditation={props.accreditation}
                        firstCellCss={firstCellCss}
                        reload={reloadAccreditation}
                    />
                ) : field.slug === 'icon' ? (
                    <AccreditationsIconCell
                        key={field.slug}
                        accreditation={props.accreditation}
                        firstCellCss={firstCellCss}
                        reload={reloadAccreditation}
                    />
                ) : (
                    <Cell />
                );
            })}

            <CellControls justify="center">
                <Tooltip>
                    <Trigger>
                        <Box>
                            <I
                                icon="pen"
                                onClick={() => {
                                    history.push(
                                        HeaventPaths.EDIT_ACCREDITATION(
                                            props.organizationId,
                                            props.eventId,
                                            props.accreditation.id
                                        )
                                    );
                                }}
                            ></I>
                        </Box>
                    </Trigger>

                    <Content placement="top">{translate('_diter_62574')}</Content>
                </Tooltip>

                <AccreditationRowDropdown
                    accreditation={props.accreditation}
                    eventId={props.eventId}
                    organizationId={props.organizationId}
                    reload={props.reload}
                />
            </CellControls>
        </Row>
    );
};
