import { compact } from 'lodash-es';
import {
    CustomField,
    CustomFieldsValue,
    FieldType,
    PositionsCustomField
} from '../generated/types';
import { TranslateFn } from '../services/translationService';
import { isNonEmptyArray } from '../util/array';

export type PositionCustomFieldCheck = Pick<PositionsCustomField, 'conditionValue'> &
    Pick<CustomField, 'slug' | 'fieldType' | 'canSelectMultiple'>;

export function displayConditionValue(
    translate: TranslateFn,
    customField: Pick<CustomField, 'fieldType'> & {
        values: Array<Pick<CustomFieldsValue, 'id' | 'value'>>;
    },
    conditionValue: any
): string {
    if (customField.fieldType === FieldType.Select) {
        if (isNonEmptyArray<number>(conditionValue)) {
            return compact(
                conditionValue.map((valueId: number) => customField.values.find((v) => v.id === valueId)?.value)
            ).join(', ');
        } else {
            return '';
        }
    } else {
        if (conditionValue === true) {
            return translate('true');
        } else {
            return translate('false');
        }
    }
}
