import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { BadgeParis20kmBack } from './badgeParis20kmBack';
import { BadgeParis20kmFront } from './badgeParis20kmFront';

interface IBadgeParis20kmProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeParis20km = (props: IBadgeParis20kmProps) => (
        <>
            <BadgeParis20kmFront event={props.event} />

            <BadgeParis20kmBack event={props.event} />
        </>
    );
