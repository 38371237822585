import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

interface IRightPanelFooterProps {
    children: React.ReactNode;
}

export const RightPanelFooter = (props: IRightPanelFooterProps) => (
        <Box
            color="gray800"
            css={{
                borderTop: '1px solid $gray200',
                px: '$6',
                py: '$4'
            }}
        >
            {props.children}
        </Box>
    );
