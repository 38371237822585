import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CustomFieldId,
    FieldType,
    OrganizationId,
    SegmentId,
    UsersInfoId,
    UsersInfosMassEditInput
} from 'common/src/generated/types';
import { IMassEditValues } from 'common/src/input/usersInfosMassEditInputService';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isEqual, sortBy } from 'lodash-es';
import * as React from 'react';
import { Form } from 'react-final-form';
import { useMassEditFieldsQuery } from '../../generated/graphqlHooks';
import { MassEditFieldInput } from './massEditFieldInput';

interface IMassEditModalProps {
    organizationId: OrganizationId;
    segmentId: Emptyable<SegmentId>;
    usersInfosIds: UsersInfoId[];

    mutate(massEdit: UsersInfosMassEditInput): Promise<any>;
    onClose(): void;
    onSuccess(): void;
}

export const MassEditModal = (props: IMassEditModalProps) => {
    const translate = useTranslate();
    const { data, loader } = useMassEditFieldsQuery({ organizationId: props.organizationId });
    const customFields = React.useMemo(() => sortBy(data.organization?.customFields.nodes ?? [], (cf) => cf.name.toLowerCase()), [data.organization]);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Form
                initialValues={{
                    massEdit: {
                        addValues: true,
                        customFieldId: -1 as CustomFieldId,
                        reset: false,
                        segmentId: props.segmentId,
                        usersInfosIds: props.usersInfosIds,
                        value: ''
                    }
                }}
                initialValuesEqual={isEqual}
                onSubmit={async (values: IMassEditValues) => {
                    await props.mutate(values.massEdit);

                    props.onSuccess();
                    props.onClose();
                }}
                render={({ form, handleSubmit, submitting, values }) => {
                    const selectedField = customFields.find(
                        (cf) => cf.id === values.massEdit.customFieldId
                    );
                    // for fieldType Select we want either a value selected or the reset option checked
                    const valid =
                        values.massEdit.reset ||
                        (!!selectedField &&
                            (selectedField.fieldType === FieldType.Select &&
                            !selectedField.canSelectMultiple
                                ? typeof values.massEdit.value === 'number' &&
                                  !isNaN(values.massEdit.value)
                                : true));

                    return (
                        <>
                            <Flex justify="center">
                                <IconBackground color="primary" icon="sliders" />
                            </Flex>

                            <Spacer height="4" />

                            <Box
                                color="gray900"
                                fontSize="textLg"
                                fontWeight="medium"
                                textAlign="center"
                            >
                                {translate('_dition_de_la_v_67585')}
                            </Box>

                            <Spacer height="7" />

                            {loader || (
                                <>
                                    <RichSelect
                                        isSearchVisible={true}
                                        name="massEdit.customFieldId"
                                        label={translate('quel_est_le_cha_81138')}
                                        placeholder={translate('s_lectionnez_un_82446')}
                                    >
                                        {customFields.map((customField) => (
                                                <option key={customField.id} value={customField.id}>
                                                    {customField.name}
                                                </option>
                                            ))}
                                    </RichSelect>

                                    {selectedField && (
                                        <>
                                            <Spacer height="4" />

                                            {!values.massEdit.reset && (
                                                <>
                                                    <MassEditFieldInput
                                                        addValuesName="massEdit.addValues"
                                                        field={selectedField}
                                                        name="massEdit.value"
                                                        value={values.massEdit.value}
                                                        change={form.change}
                                                    />

                                                    <Spacer height="2" />
                                                </>
                                            )}

                                            <CheckboxText name="massEdit.reset">
                                                {translate('je_souhaite_r_i_32403')}
                                            </CheckboxText>
                                        </>
                                    )}
                                </>
                            )}

                            <Spacer height="7" />

                            <Flex justify="end" gap="3">
                                <Button
                                    disabled={!valid}
                                    isLoading={submitting}
                                    onClick={handleSubmit}
                                >
                                    {translate('modifier_la_val_07602')}
                                </Button>

                                <Button color="white" onClick={props.onClose}>
                                    {translate('annuler_48254')}
                                </Button>
                            </Flex>
                        </>
                    );
                }}
            />
        </Modal>
    );
};
