import { Box } from 'common/src/designSystem/components/box';
import { AccreditationsSlotId, EventAccreditationsFragment } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { AccreditationCategories } from './accreditationsSelectPanel';
import { AccreditationsSelectPanelAccreditation } from './accreditationsSelectPanelAccreditation';

interface IAccreditationsSelectPanelAccreditationCategoryProps {
    accreditationCategory: EventAccreditationsFragment['accreditationsCategories'][number];
    accreditationSlotsIdsValues: AccreditationsSlotId[];
    fullAccreditationCategory: AccreditationCategories[number];
    values: ICreateUpdateDelegationValues['delegation']['accreditations'];

    change(name: string, value: any): void;
}

export const AccreditationsSelectPanelAccreditationCategory = ({
    accreditationCategory,
    accreditationSlotsIdsValues,
    change,
    fullAccreditationCategory,
    values
}: IAccreditationsSelectPanelAccreditationCategoryProps) => {
    const accreditationsSorted = React.useMemo(
        () => sortBy(accreditationCategory.accreditations, (a) => a.name),
        [accreditationCategory.accreditations]
    );

    return (
        <>
            <Box color="gray500" fontSize="textSm">
                {accreditationCategory.name}
            </Box>

            {accreditationsSorted.map((accreditation) => (
                    <AccreditationsSelectPanelAccreditation
                        key={accreditation.id}
                        accreditationCategoryId={accreditationCategory.id}
                        accreditation={accreditation}
                        accreditationSlotsIdsValues={accreditationSlotsIdsValues}
                        fullAccreditation={
                            fullAccreditationCategory.accreditations.find(
                                (acc) => acc.id === accreditation.id
                            )!
                        }
                        change={change}
                        values={values}
                    />
                ))}
        </>
    );
};
