import { Flex } from 'common/src/designSystem/components/flex';
import { styled } from 'common/src/designSystem/components/stitches';
import { useHistory, useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

const _Flex = styled(Flex, {
    color: '$gray400',
    flex: 1,
    fontSize: '$textSm',
    fontWeight: '$medium',
    padding: '6px 8px',
    variants: {
        isSelected: {
            true: {
                backgroundColor: 'white',
                border: '0.5px solid $gray200',
                borderRadius: '$1',
                color: '$gray800',
                fontWeight: '$semiBold'
            }
        }
    }
});

interface ITabMobileProps {
    children: React.ReactNode;
    path: string;
}

export const TabMobile = ({ children, path }: ITabMobileProps) => {
    const history = useHistory();
    const location = useLocation();
    const isSelected = location.pathname === path;

    return (
        <_Flex
            onClick={() => {
                history.push(path);
            }}
            isSelected={isSelected}
            justify="center"
        >
            {children}
        </_Flex>
    );
};
