import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

interface IBadgeVendangesProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeVendanges = (props: IBadgeVendangesProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;
    const journeeAccreditation = vr.accreditationsUsersInfos.find(
        (aui) => aui.accreditationCategory.id === 715
    );
    const accreditations = vr.accreditationsUsersInfos.map(
        ({ accreditation }) => accreditation.acronym
    );

    return (
        <Flex
            height={325}
            width={204}
            css={{
                background: `url(https://assets.recrewteer.com/badges/vendanges/vendanges.jpg) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    color: 'white',
                    fontSize: '11px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '132px',
                    width: '100%'
                }}
            >
                {journeeAccreditation?.accreditation.name ?? ''}
            </Box>

            <Box
                css={{
                    borderRadius: '72px',
                    height: '72px',
                    left: '66px',
                    overflow: 'hidden',
                    position: 'absolute',
                    top: '163px',
                    width: '72px'
                }}
            >
                {userInfo.picture && <img src={userInfo.picture?.url} height="100%" width="100%" />}
            </Box>

            <Box
                css={{
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '239px',
                    width: '100%'
                }}
            >
                {userInfo.name}
            </Box>

            <Flex
                css={{
                    height: '53px',
                    left: '0',
                    position: 'absolute',
                    top: '272px',
                    width: '100%'
                }}
            >
                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '#6eafe2',
                        height: '100%',
                        flex: '1',
                        visibility: accreditations.includes('1') ? 'visible' : 'hidden'
                    }}
                >
                    <Box css={{ color: 'white', fontSize: '32px', fontWeight: '700' }}>1</Box>
                </Flex>

                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '#9261a8',
                        height: '100%',
                        flex: '1',
                        visibility: accreditations.includes('2') ? 'visible' : 'hidden'
                    }}
                >
                    <Box css={{ color: 'white', fontSize: '32px', fontWeight: '700' }}>2</Box>
                </Flex>

                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '#accb44',
                        height: '100%',
                        flex: '1',
                        visibility: accreditations.includes('3') ? 'visible' : 'hidden'
                    }}
                >
                    <Box css={{ color: 'white', fontSize: '32px', fontWeight: '700' }}>3</Box>
                </Flex>

                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '#e7a726',
                        height: '100%',
                        flex: '1',
                        visibility: accreditations.includes('4') ? 'visible' : 'hidden'
                    }}
                >
                    <Box css={{ color: 'white', fontSize: '32px', fontWeight: '700' }}>4</Box>
                </Flex>

                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '#d23780',
                        height: '100%',
                        flex: '1',
                        visibility: accreditations.includes('5') ? 'visible' : 'hidden'
                    }}
                >
                    <Box css={{ color: 'white', fontSize: '32px', fontWeight: '700' }}>5</Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
