import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionSlotsMobileQuery, PositionsSlotId } from 'common/src/generated/types';
import * as React from 'react';
import { PositionSlotsCardMobile } from './positionSlotsCardMobile';
import { useTranslate } from 'common/src/util/dependencies/dependencies';

interface IPositionSlotsMobileProps {
    open: (id: PositionsSlotId) => void;
    slots: PositionSlotsMobileQuery['event']['positionsSlots']['nodes'];
}

export const PositionSlotsMobile = ({ open, slots }: IPositionSlotsMobileProps) => {
    const translate = useTranslate();

    return (
        <Flex direction="column" gap="3">
            <Box color="gray900" fontWeight="semiBold" fontSize="textLg">
                {translate('liste_des_cr_ne_94056')}
            </Box>
            {slots.map((slot, index) => <PositionSlotsCardMobile open={open} slot={slot} key={index} />)}
        </Flex>
    );
};
