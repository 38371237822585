import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { ProgressGauge } from 'common-front/src/designSystem/components/progressGauge';
import {
    HorizontalSpacerSeparator,
    Separator
} from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Link } from 'common-front/src/util/link';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { DelegationsPaths } from 'common/src/delegationsPaths';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { CommonEnvVars } from 'common/src/envVars';
import { Feature } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { getFormLink } from 'common/src/vo/form';
import * as React from 'react';
import { AccreditationPrivateNote } from '../../../accreditations/show/accreditationPrivateNote';
import { AccreditationTags } from '../../../accreditations/show/accreditationTags';
import {
    useDelegationDashboardQuery,
    useTagsDelegationAssociateMutation
} from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';

export const DelegationDashboard = () => {
    const { translate } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const { organizationId, eventId, delegationId } = useParams();
    const dateTimeService = useService(DateTimeService);
    const {
        organization: {
            permissions: { isOrganizationAdmin }
        }
    } = useOrganizationContext();

    const { data, loader, reload } = useDelegationDashboardQuery({
        organizationId,
        delegationId
    });
    const { mutate: tagsDelegationAssociate } = useTagsDelegationAssociateMutation();

    const delegation = data.organization?.delegation;

    const formLink = React.useMemo(() => getFormLink({
            organizationId,
            eventId,
            delegationId,
            formId: delegation?.form.id
        }), [delegation]);

    const delegationSpaceLink = React.useMemo(() => {
        const path = DelegationsPaths.AUTH(organizationId);

        return `https://event.${CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN}${path}`;
    }, [delegation]);

    const numberWithSeparatingDots = (label: string, number: number) => (
            <Flex>
                <Box textAlign={'start'}>{label}</Box>
                <Box
                    css={{
                        borderBlockEnd: '2px dotted $gray500',
                        flex: '1',
                        marginBlockEnd: '$1'
                    }}
                ></Box>
                <Box
                    color={'gray900'}
                    css={{
                        fontSize: '$textMd'
                    }}
                    textAlign={'end'}
                >
                    {number}
                </Box>
            </Flex>
        );

    return (
        loader || (
            <Flex gap="6">
                <Flex direction="column" gap="4" width={1}>
                    <Flex gap="6">
                        <Flex
                            css={{
                                flex: '3',
                                height: '100%'
                            }}
                            direction="column"
                        >
                            <DetailBox
                                css={{
                                    flex: '1 1'
                                }}
                                title={translate('limite_de_membr_42978')}
                            >
                                <Flex
                                    css={{ justifyContent: 'space-between' }}
                                    direction={'column'}
                                    height={'100%'}
                                >
                                    <Box css={{ margin: 'auto' }}>
                                        <ProgressGauge
                                            count={delegation.numberOfResources}
                                            isErrorIfOver={true}
                                            total={delegation.maxResources ?? Infinity}
                                        />
                                    </Box>

                                    <Flex direction={'column'} gap="4">
                                        {hasFeature(Feature.Accreditation) && (
                                            <>
                                                <Box
                                                    color={'gray700'}
                                                    css={{
                                                        fontSize: '$textXs',
                                                        fontWeight: 600,
                                                        fontFamily: 'Open Sans',
                                                        lineHeight: '18px',
                                                        textTransform: 'uppercase'
                                                    }}
                                                >
                                                    {translate('dont_76603')}
                                                </Box>

                                                <Flex
                                                    color={'gray500'}
                                                    css={{
                                                        fontSize: '$textSm',
                                                        fontWeight: '$medium',
                                                        lineHeight: '20px',
                                                        justifyContent: 'space-between'
                                                    }}
                                                    direction={'column'}
                                                    gap="2"
                                                >
                                                    {numberWithSeparatingDots(
                                                        translate('membres_accr_di_73664'),
                                                        delegation.numberOfMembersAccredited
                                                    )}

                                                    {numberWithSeparatingDots(
                                                        translate('membres_en_atte_57336'),
                                                        delegation.numberOfMembersWaitingAccreditation
                                                    )}

                                                    {numberWithSeparatingDots(
                                                        translate('membres_refus_31757'),
                                                        delegation.numberOfMembersRefusedAccreditation
                                                    )}
                                                </Flex>
                                            </>
                                        )}
                                    </Flex>
                                </Flex>
                            </DetailBox>
                        </Flex>

                        <Flex css={{ flex: '6' }} direction="column" gap="6">
                            <DetailBox title={translate('responsables_84923')}>
                                {isNonEmptyArray(delegation.leaders) ? (
                                    delegation.leaders.map((userInfo, index) => (
                                            <React.Fragment key={userInfo.id}>
                                                {index !== 0 && (
                                                    <HorizontalSpacerSeparator height="4" />
                                                )}

                                                <AvatarNameEmail
                                                    userInfo={userInfo}
                                                    hasName={isNonEmptyString(userInfo.name)}
                                                />
                                            </React.Fragment>
                                        ))
                                ) : (
                                    <Box color="gray500" width={1} textAlign="center">
                                        {translate('aucun_reponsabl_38504')}
                                    </Box>
                                )}
                            </DetailBox>

                            <DetailBox title={translate('param_tres_78420')}>
                                <Flex direction="column" gap="4" width={1}>
                                    {isOrganizationAdmin && delegation.parent && (
                                        <>
                                            <Flex justify="between" gap="3">
                                                <Box color="gray500">
                                                    {translate('d_l_gation_pare_68507')}
                                                </Box>

                                                <Link
                                                    to={DelegationsPaths.DELEGATION({
                                                        organizationId,
                                                        delegationId: delegation.parent.id
                                                    })}
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    <Flex
                                                        align="center"
                                                        gap="2"
                                                        css={{
                                                            color: '$primary700',
                                                            fontWeight: '$medium',
                                                            '&:hover': {
                                                                textDecoration: 'underline'
                                                            }
                                                        }}
                                                    >
                                                        <Badge
                                                            key={delegation.parent.id}
                                                            color="white"
                                                            cursor="pointer"
                                                            size="md"
                                                        >
                                                            {delegation.parent.name}
                                                        </Badge>
                                                    </Flex>
                                                </Link>
                                            </Flex>

                                            <Separator direction="horizontal" />
                                        </>
                                    )}

                                    <Flex justify="between" gap="3">
                                        <Box color="gray500">{translate('status_06428')}</Box>

                                        <Box color="gray800" fontWeight="medium">
                                            <Badge color="success">
                                                {translate(delegation.state)}
                                            </Badge>
                                        </Box>
                                    </Flex>

                                    <Separator direction="horizontal" />

                                    <Flex justify="between" gap="3">
                                        <Box color="gray500">
                                            {translate('date_limite_d_i_79779')}
                                        </Box>

                                        <Box color="gray800" fontWeight="medium">
                                            {delegation.deadlineDate
                                                ? dateTimeService.toLocaleString(
                                                      delegation.deadlineDate,
                                                      LocaleFormats.DateOnly.MonthLong
                                                  )
                                                : '-'}
                                        </Box>
                                    </Flex>

                                    <Separator direction="horizontal" />

                                    <Flex justify="between" gap="3">
                                        <Box color="gray500">{translate('Form')}</Box>

                                        <Link to={{ pathname: formLink }} target="_blank">
                                            <Flex
                                                align="center"
                                                gap="2"
                                                css={{
                                                    color: '$primary700',
                                                    cursor: 'pointer',
                                                    fontWeight: '$medium',
                                                    '&:hover': {
                                                        textDecoration: 'underline'
                                                    }
                                                }}
                                            >
                                                <Box>{delegation.form.name}</Box>

                                                <Box>
                                                    <I icon="arrow-up-right-from-square" />
                                                </Box>
                                            </Flex>
                                        </Link>
                                    </Flex>

                                    <Separator direction="horizontal" />

                                    <Flex justify="between" gap="3">
                                        <Box color="gray500">
                                            {translate('lien_de_l_espac_46578')}
                                        </Box>

                                        <Link
                                            to={{ pathname: delegationSpaceLink }}
                                            target="_blank"
                                        >
                                            <Flex
                                                align="center"
                                                gap="2"
                                                css={{
                                                    color: '$primary700',
                                                    fontWeight: '$medium',
                                                    '&:hover': {
                                                        textDecoration: 'underline'
                                                    }
                                                }}
                                            >
                                                <Box>{translate('portail_d_l_gat_06817')}</Box>

                                                <Box>
                                                    <I icon="arrow-up-right-from-square" />
                                                </Box>
                                            </Flex>
                                        </Link>
                                    </Flex>
                                </Flex>
                            </DetailBox>
                        </Flex>
                    </Flex>

                    <Flex gap="6">
                        <AccreditationTags
                            tags={delegation.tags}
                            type="delegations"
                            associateTags={(tagsIds) => tagsDelegationAssociate({
                                    eventId,
                                    delegationId,
                                    tagsIds
                                })}
                            reload={reload}
                        />

                        <AccreditationPrivateNote accreditation={delegation} />
                    </Flex>
                </Flex>
            </Flex>
        )
    );
};
