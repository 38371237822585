import { chunk, sortBy } from 'lodash-es';
import * as React from 'react';
import { Flex } from '../../designSystem/components/flex';
import {
    DocumentUsersListingFragment,
    VolunteersListingDocumentOptions
} from '../../generated/types';
import { VolunteersTable } from '../volunteersTable';
import { UsersListingHeader } from './usersListingHeader';

const NUMBER_OF_USERS_PER_PAGE = 18;

interface IUsersListingProps {
    documentUsersListing: DocumentUsersListingFragment;
    options: VolunteersListingDocumentOptions;
}

export const UsersListing = (props: IUsersListingProps) => {
    const customFields = props.documentUsersListing.organization.customFields.nodes;
    const volunteersRegistrations = React.useMemo(() => sortBy(
            props.documentUsersListing.event.volunteersRegistrations.nodes,
            (vr) => vr.userInfo.name
        ), [props.documentUsersListing.event.volunteersRegistrations.nodes]);
    const totalCount = React.useMemo(() => volunteersRegistrations.length, [volunteersRegistrations]);
    const chunks = React.useMemo(() => chunk(volunteersRegistrations, NUMBER_OF_USERS_PER_PAGE), [volunteersRegistrations]);

    return (
        <>
            {chunks.map((users, index) => (
                    <Flex
                        key={index}
                        direction="column"
                        width={1}
                        css={{
                            background: 'white',
                            pageBreakBefore: index !== 0 ? 'always' : undefined
                        }}
                    >
                        <UsersListingHeader
                            documentUsersListing={props.documentUsersListing}
                            options={props.options}
                            totalCount={totalCount}
                        />

                        <Flex direction="column" css={{ padding: '$6 56px' }}>
                            <VolunteersTable
                                customFields={customFields}
                                slugsToDisplay={props.options.slugsToDisplay}
                                volunteers={users}
                            />
                        </Flex>
                    </Flex>
                ))}
        </>
    );
};
