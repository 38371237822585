import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import * as React from 'react';

interface IEmailTypeRowProps {
    isSelected: boolean;
    title: string;
    subtitle: string;

    onClick(): void;
}

export const EmailTypeRow = (props: IEmailTypeRowProps) => (
        <Row
            css={{
                background: props.isSelected ? '$primary100' : 'white',
                cursor: 'pointer',
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onClick={props.onClick}
        >
            <Cell direction="column" justify="center" align="start">
                <Box color="gray900" fontWeight="medium">
                    {props.title}
                </Box>
                <Box color="gray500">{props.subtitle}</Box>
            </Cell>
        </Row>
    );
