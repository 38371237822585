import { Empty } from 'common-front/src/components/empty/empty';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { BackofficeOrganizations } from './backofficeOrganizations';
import { BackofficeInvoices } from './invoices/backofficeInvoices';
import { UpdateBackofficeOrganization } from './updateBackofficeOrganization';

export const Backoffice = () => (
        <Switch>
            <Route
                exact
                path={HeaventPaths.BACKOFFICE_ORGANIZATIONS}
                children={<BackofficeOrganizations />}
            />

            <Route
                exact
                path={HeaventPaths.EDIT_BACKOFFICE_ORGANIZATION(':organizationId')}
                children={<UpdateBackofficeOrganization />}
            />

            <Route
                exact
                path={HeaventPaths.BACKOFFICE_INVOICES}
                children={<BackofficeInvoices />}
            />

            <Route
                children={<Empty path={HeaventPaths.BACKOFFICE_ORGANIZATIONS} replace={true} />}
            />
        </Switch>
    );
