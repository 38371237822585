import { FieldSpacer } from 'common-front/src/components/users/show/fieldSpacer';
import { UserField } from 'common-front/src/components/users/show/userField';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CustomFieldWithConditionFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { shouldDisplay, UserInfo } from 'common/src/vo/field';
import * as React from 'react';
import { TitleBox } from './titleBox';

interface IInformationsProps {
    editButton?: React.ReactNode;
    fieldsToDisplay: CustomFieldWithConditionFragment[];
    privateCustomFields: CustomFieldWithConditionFragment[];
    userInfo: UserInfo;
}

export const Informations = (props: IInformationsProps) => {
    const onMobile = useMobileQuery();
    const translate = useTranslate();
    const eventsFieldsToDisplay = props.fieldsToDisplay.filter((field) => !['picture', 'firstName', 'lastName'].includes(field.fieldProperty));
    const numberOfEventsFields = eventsFieldsToDisplay.length;
    const numberOfPrivateFields = props.privateCustomFields.length;

    return (
        <Flex direction="column">
            {numberOfEventsFields > 0 && (
                <>
                    <Spacer height="6" />

                    <TitleBox>{translate('informations_pe_37892')}</TitleBox>

                    <Spacer height="3" />

                    {eventsFieldsToDisplay.map((field) => (
                            shouldDisplay(field, props.userInfo.fields, eventsFieldsToDisplay) && (
                                <React.Fragment key={field.id}>
                                    <UserField field={field} userInfo={props.userInfo} />

                                    <FieldSpacer />
                                </React.Fragment>
                            )
                        ))}

                    {onMobile === false && (
                        <Box
                            width={1}
                            css={{
                                borderBottom: '1px solid $gray200'
                            }}
                        />
                    )}
                </>
            )}

            {numberOfPrivateFields > 0 && (
                <>
                    <Spacer height={onMobile ? 5 : 6} />

                    <Flex gap="3">
                        <TitleBox>
                            <I icon="lock" />
                        </TitleBox>

                        <TitleBox>{translate('informations_pr_15873')}</TitleBox>
                    </Flex>

                    <Spacer height="3" />

                    {props.privateCustomFields.map((customField) => (
                            <React.Fragment key={customField.id}>
                                <UserField field={customField} userInfo={props.userInfo} />

                                <FieldSpacer />
                            </React.Fragment>
                        ))}

                    {onMobile === false && (
                        <Box
                            width={1}
                            css={{
                                borderBottom: '1px solid $gray200'
                            }}
                        />
                    )}
                </>
            )}

            {onMobile === false && props.editButton}

            {onMobile === false && <Spacer height="6" />}
        </Flex>
    );
};
