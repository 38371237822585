import { Segment } from 'common/src/generated/types';
import * as React from 'react';
import { Select } from '../../designSystem/components/select/select';

type IVolunteersSelectSegmentMobileProps = {
    segments: Array<Pick<Segment, 'id' | 'name' | 'count'>>;
    segmentId: number;
    onChange(segmentId: number): void;
} & Omit<React.ComponentProps<typeof Select>, 'parseInt' | 'parseBoolean' | 'value' | 'onChange'>;

export const SelectSegmentMobile = ({
    segments,
    segmentId,
    ...props
}: IVolunteersSelectSegmentMobileProps) => (
        <Select value={segmentId} parseInt {...props}>
            {segments.map((segment, index) => (
                <option value={segment.id} key={index}>
                    {segment.name} ({segment.count})
                </option>
            ))}
        </Select>
    );
