import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { Row } from 'common/src/designSystem/components/table/row';
import { EventId, OrganizationId, UserOverlayPositionsQuery } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { noop } from 'lodash-es';
import * as React from 'react';

interface IUserOverlayPositionsPreassignedProps {
    eventId: EventId;
    organizationId: OrganizationId;
    volunteerRegistration: UserOverlayPositionsQuery['event']['volunteerRegistration'];
}

export const UserOverlayPositionsPreassigned = (props: IUserOverlayPositionsPreassignedProps) => {
    const { history, translate } = useHeavent();
    const intervalService = useService(IntervalService);
    const preassigneds = React.useMemo(() => {
        const positions = props.volunteerRegistration.preassignPositions.map((position) => ({
            name: position.name,
            range: intervalService.toDisplayString(position.range),
            path: HeaventPaths.POSITION(props.organizationId, props.eventId, position.id)
        }));
        const categories = props.volunteerRegistration.preassignPositionsCategories.map(
            (category) => ({
                name: category.name,
                range: category.range ? intervalService.toDisplayString(category.range) : '-',
                path: HeaventPaths.POSITION_CATEGORY(
                    props.organizationId,
                    props.eventId,
                    category.id
                )
            })
        );

        return positions.concat(categories);
    }, [props.volunteerRegistration]);

    return isNonEmptyArray(preassigneds) ? (
        <>
            <TableFilters
                filters={<></>}
                headerCells={
                    <>
                        <HeaderCell>{translate('nom_de_la_missi_64605')}</HeaderCell>
                        <HeaderCell>{translate('Date')}</HeaderCell>
                    </>
                }
                numberOfPages={0}
                offset={0}
                rows={
                    <>
                        {preassigneds.map((preassigned, index) => (
                                <Row key={index}>
                                    <Cell>
                                        <Box
                                            color="gray900"
                                            fontWeight="medium"
                                            css={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                history.push(preassigned.path);
                                            }}
                                        >
                                            {preassigned.name}
                                        </Box>
                                    </Cell>
                                    <Cell>{preassigned.range}</Cell>
                                </Row>
                            ))}
                    </>
                }
                title={translate('pr_affectation_68999')}
                totalCount={0}
                setOffset={noop}
            />

            <Spacer height="8" />
        </>
    ) : null;
};
