import { Form } from 'common-front/src/components/form/form';
import { FullPageContent } from 'common-front/src/components/mobileFullPage/fullPageContent';
import { Button } from 'common-front/src/designSystem/components/button';
import { useUserContext } from 'common-front/src/userContext';
import { signOut } from 'common-front/src/util/aws/cognito';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserInfoInputService } from 'common/src/input/userInfoInput';
import { ValidateService } from 'common/src/services/validateService';
import { useParams, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useProfileQuery, useUserProfileUpdateMutation } from '../../../generated/graphqlHooks';
import { ProfileTextInputs } from '../../profile/profileTextInputs';
import { ProfileAvatarMobile } from './profileAvatarMobile';

export const ProfileFormMobile = () => {
    const { organizationId } = useParams();
    const { data, loader } = useProfileQuery({ organizationId });
    const userInfoInput = useService(UserInfoInputService);
    const validateService = useService(ValidateService);
    const { user } = useUserContext();
    const { mutate, isLoading } = useUserProfileUpdateMutation();
    const translate = useTranslate();

    return (
        loader || (
            <Form
                initialValues={{ userInfo: userInfoInput.userInfoProfileInputDefault(data.user) }}
                validate={validateService.validateForForm(userInfoInput.updateProfileSchema())}
                onSubmit={async (values) => {
                    const {
                        userProfileUpdate: { email, language }
                    } = await mutate({
                        userInfoId: data.user.userInfo.id,
                        userInfo: values.userInfo
                    });

                    if (user!.email !== email) {
                        signOut();
                    } else if (user!.language !== language) {
                        location.reload();
                    }
                }}
                render={({ handleSubmit }) => (
                        <Flex direction="column">
                            <ProfileAvatarMobile />
                            <FullPageContent>
                                <ProfileTextInputs inputName="userInfo" />
                                <Spacer height="7" />
                                <Button
                                    onClick={handleSubmit}
                                    isLoading={isLoading}
                                    textAlign="center"
                                >
                                    {translate('enregistrer_06519')}
                                </Button>
                            </FullPageContent>
                        </Flex>
                    )}
            ></Form>
        )
    );
};
