import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useEventsSegmentsQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { Events } from './events';

export const EventsLoader = () => {
    const {
        params: { organizationId }
    } = useHeavent();
    const { localOrganization } = useOrganizationContext();
    const { data, loader, reload } = useEventsSegmentsQuery({ organizationId });
    const segmentsIds = React.useMemo(() => (data.organization?.segmentsFolders ?? [])
            .flatMap((sf) => sf.segments)
            .map((s) => s.id), [data.organization]);

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={HeaventPaths.EVENTS_SEGMENT(':organizationId', ':segmentId')}
                    children={<Events organization={data.organization} reload={reload} />}
                />

                <Route
                    children={
                        <Empty
                            path={HeaventPaths.EVENTS_SEGMENT(
                                organizationId,
                                localOrganization?.eventsSegmentId &&
                                    segmentsIds.includes(localOrganization.eventsSegmentId)
                                    ? localOrganization.eventsSegmentId
                                    : segmentsIds[0]
                            )}
                            replace={true}
                        />
                    }
                />
            </Switch>
        );
    }
};
