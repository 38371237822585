import { MobileOverlay } from 'common-front/src/components/mobileOverlay/mobileOverlay';
import { Tab } from 'common-front/src/designSystem/components/tabs/tab';
import { TabList } from 'common-front/src/designSystem/components/tabs/tabList';
import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { Tabs } from 'common-front/src/designSystem/components/tabs/tabs';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationState,
    Delegation,
    Feature,
    UserInfoDocumentLink,
    UserInfoOverlayFragment,
    VolunteerRegistrationState,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { PathComponents } from 'common/src/util/paths/commonPaths';
import { UserInfoIdPathParam } from 'common/src/util/pathsTypes';
import { isNonEmptyString } from 'common/src/util/string';
import { noop } from 'lodash-es';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useEventContext } from '../../events/show/eventContext';
import {
    useUserOverlayEventQuery,
    useUserOverlayOrganizationQuery
} from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { Actions } from '../show/actions/actions';
import { Comments } from '../show/comments/comments';
import { UserOverlayAccreditations } from './accreditations/userOverlayAccreditations';
import { UserOverlayActionsOrganization } from './actions/userOverlayActionsOrganization';
import { UserOverlayCampaigns } from './campaigns/userOverlayCampaigns';
import { UserOverlayEvents } from './events/userOverlayEvents';
import {
    UserOverlayInformationsEvent,
    UserOverlayInformationsOrganization
} from './informations/userOverlayInformations';
import { UserOverlayIntegrations } from './integrations/userOverlayIntegrations';
import { UserOverlayPositions } from './positions/userOverlayPositions';
import { UserOverlaySlots } from './slots/userOverlaySlots';
import { UserOverlayHeader } from './userOverlayHeader';
import { UserOverlayHeaderSkeleton } from './userOverlayHeaderSkeleton';

interface IUserOverlayProps {
    accreditationState?: AccreditationState;
    canEditEmail: boolean;
    delegation?: Emptyable<Pick<Delegation, 'id' | 'name'>>;
    isEvent: boolean;
    isLoading: boolean;
    isWeezeventConnected: boolean;
    numberOfComments: number;
    links: Emptyable<UserInfoDocumentLink>;
    showAccreditations: boolean;
    showActions: boolean;
    showComments: boolean;
    state?: VolunteerRegistrationState;
    userInfo: UserInfoOverlayFragment;
    volunteerRegistrationId?: VolunteersRegistrationId;

    getBasePath(userInfoId: UserInfoIdPathParam, isRouteComponent?: boolean): string;
    onClose(): void;
    onDelete(): void;
    onUpdateState(): void;
    reload(): void;
}

const UserOverlay = (props: IUserOverlayProps) => {
    const {
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const baseRoutePath = React.useMemo(() => props.getBasePath(':userInfoId', true), [props.getBasePath]);
    const basePath = React.useMemo(() => props.getBasePath(userInfoId), [userInfoId, props.getBasePath]);

    return (
        <MobileOverlay onClose={props.onClose}>
            <Flex direction="column" width="$container" css={{ margin: 'auto' }}>
                {props.isLoading ? (
                    <UserOverlayHeaderSkeleton />
                ) : (
                    <UserOverlayHeader
                        canEditEmail={props.canEditEmail}
                        eventId={eventId}
                        organizationId={organizationId}
                        state={props.state}
                        userInfo={props.userInfo}
                        reload={props.reload}
                    />
                )}

                <Spacer height="7" />

                {!props.isLoading && (
                    <Tabs>
                        <TabList center={true}>
                            <Tab path={`${basePath}/${PathComponents.SECTIONS.INFORMATION}`}>
                                {translate('informations_22954')}
                            </Tab>

                            {!props.isEvent && (
                                <Tab path={`${basePath}/events`}>
                                    {translate('_v_nements_53672')}
                                </Tab>
                            )}

                            {eventId && (
                                <Tab path={`${basePath}/slots`}>
                                    {translate('disponibilit_s_49923')}
                                </Tab>
                            )}

                            {eventId && (
                                <Tab path={`${basePath}/positions`}>
                                    {translate('missions_63972')}
                                </Tab>
                            )}

                            {props.showAccreditations && (
                                <Tab path={`${basePath}/accreditations`}>
                                    {translate('accr_ditations_39450')}
                                </Tab>
                            )}

                            {eventId && props.isWeezeventConnected && (
                                <Tab path={`${basePath}/integrations`}>
                                    {translate('int_grations_88619')}
                                </Tab>
                            )}

                            {props.showActions && (
                                <Tab path={`${basePath}/actions`}>{translate('actions_21689')}</Tab>
                            )}

                            {props.showComments && (
                                <Tab path={`${basePath}/comments`}>
                                    {translate('commentaires_97857', props.numberOfComments)}
                                </Tab>
                            )}

                            <Tab path={`${basePath}/campaigns`}>{translate('campagnes_31372')}</Tab>

                            {eventId && (
                                <Tab path={`${basePath}/events`}>
                                    {translate('autres_v_nemen_48137')}
                                </Tab>
                            )}
                        </TabList>

                        <TabPanel path={`${baseRoutePath}/${PathComponents.SECTIONS.INFORMATION}`}>
                            {props.isEvent ? (
                                <UserOverlayInformationsEvent userInfo={props.userInfo} />
                            ) : (
                                <UserOverlayInformationsOrganization userInfo={props.userInfo} />
                            )}
                        </TabPanel>

                        {!props.isEvent && (
                            <TabPanel path={`${baseRoutePath}/events`}>
                                <UserOverlayEvents />
                            </TabPanel>
                        )}

                        {eventId && (
                            <TabPanel path={`${baseRoutePath}/slots`}>
                                <UserOverlaySlots />
                            </TabPanel>
                        )}

                        {eventId && (
                            <TabPanel path={`${baseRoutePath}/positions`}>
                                <UserOverlayPositions />
                            </TabPanel>
                        )}

                        {props.showAccreditations && (
                            <TabPanel path={`${baseRoutePath}/accreditations`}>
                                <UserOverlayAccreditations />
                            </TabPanel>
                        )}

                        {eventId && props.isWeezeventConnected && (
                            <TabPanel path={`${baseRoutePath}/integrations`}>
                                <UserOverlayIntegrations />
                            </TabPanel>
                        )}

                        {props.showActions && (
                            <TabPanel path={`${baseRoutePath}/actions`}>
                                {props.isEvent ? (
                                    <Actions
                                        accreditationState={props.accreditationState!}
                                        delegation={props.delegation}
                                        organizationId={organizationId}
                                        eventId={eventId}
                                        isWeezeventConnected={props.isWeezeventConnected}
                                        links={props.links}
                                        state={props.state!}
                                        userInfo={props.userInfo}
                                        volunteerRegistrationId={props.volunteerRegistrationId!}
                                        onDelete={props.onDelete}
                                        onUpdateState={props.onUpdateState}
                                    />
                                ) : (
                                    <UserOverlayActionsOrganization userInfo={props.userInfo} />
                                )}
                            </TabPanel>
                        )}

                        {props.showComments && (
                            <TabPanel path={`${baseRoutePath}/comments`}>
                                <Comments showEvent={true} />
                            </TabPanel>
                        )}

                        <TabPanel path={`${baseRoutePath}/campaigns`}>
                            <UserOverlayCampaigns />
                        </TabPanel>

                        {eventId && (
                            <TabPanel path={`${baseRoutePath}/events`}>
                                <UserOverlayEvents />
                            </TabPanel>
                        )}
                    </Tabs>
                )}
            </Flex>
        </MobileOverlay>
    );
};

interface IUserOverlayEventProps {
    getBasePath(userInfoId: UserInfoIdPathParam, isRouteComponent?: boolean): string;
    onClose(): void;
    onDelete(): void;
    onUpdateState(): void;
}

export const UserOverlayEvent = (props: IUserOverlayEventProps) => {
    const { eventId, userInfoId } = useParams();
    const { hasFeature } = useOrganizationContext();
    const {
        event: {
            permissions: { isEventAdmin }
        },
        isPositionAdmin
    } = useEventContext();
    const { data, isLoading, reload } = useUserOverlayEventQuery({ eventId, userInfoId });

    return createPortal(
        <Box
            height={1}
            width={1}
            css={{
                background: 'white',
                left: '0',
                position: 'absolute',
                top: '0',
                zIndex: '200'
            }}
        >
            <UserOverlay
                canEditEmail={isEventAdmin}
                accreditationState={data.event?.volunteerRegistration.accreditationState}
                delegation={data.event?.volunteerRegistration.delegation}
                isEvent={true}
                isLoading={isLoading}
                isWeezeventConnected={isNonEmptyString(
                    data.event?.weezeventConnectionInfos?.apiKey
                )}
                numberOfComments={data.event?.volunteerRegistration.userInfo.numberOfComments ?? 0}
                links={data.event?.volunteerRegistration.userInfo.links}
                showAccreditations={hasFeature(Feature.Accreditation) && isEventAdmin}
                showActions={isPositionAdmin()}
                showComments={isEventAdmin}
                state={data.event?.volunteerRegistration.state}
                userInfo={data.event?.volunteerRegistration.userInfo}
                volunteerRegistrationId={data.event?.volunteerRegistration.id}
                getBasePath={props.getBasePath}
                onClose={props.onClose}
                onDelete={props.onDelete}
                onUpdateState={() => {
                    reload();
                    props.onUpdateState();
                }}
                reload={reload}
            />
        </Box>,
        document.body
    );
};

interface IUserOverlayOrganizationProps {
    getBasePath(userInfoId: UserInfoIdPathParam, isRouteComponent?: boolean): string;
    onClose(): void;
    onDelete(): void;
}

export const UserOverlayOrganization = (props: IUserOverlayOrganizationProps) => {
    const { organizationId, userInfoId } = useParams();
    const { data, isLoading, reload } = useUserOverlayOrganizationQuery({
        organizationId,
        userInfoId
    });

    return createPortal(
        <Box
            height={1}
            width={1}
            css={{
                background: 'white',
                left: '0',
                position: 'absolute',
                top: '0',
                zIndex: '200'
            }}
        >
            <UserOverlay
                canEditEmail={true}
                isEvent={false}
                isLoading={isLoading}
                isWeezeventConnected={false}
                numberOfComments={data.organization?.userInfo.numberOfComments ?? 0}
                links={null}
                showAccreditations={false}
                showActions={true}
                showComments={true}
                userInfo={data.organization?.userInfo}
                getBasePath={props.getBasePath}
                onClose={props.onClose}
                onDelete={props.onDelete}
                onUpdateState={noop}
                reload={reload}
            />
        </Box>,
        document.body
    );
};
