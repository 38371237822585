import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { AccreditationDisplay, EventAccreditationsFragment } from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { isNonEmptyArray } from 'common/src/util/array';
import * as React from 'react';
import { AccreditationsTable } from './accreditationsTable';

interface IAccreditationsFormProps {
    allAccreditationCategories: EventAccreditationsFragment['accreditationsCategories'];
    values: ICreateUpdateDelegationValues['delegation']['accreditations'];

    change(name: string, value: any): void;
    setIsAddAccreditationOpen(_: boolean): void;
}

export const AccreditationsForm = ({
    allAccreditationCategories,
    setIsAddAccreditationOpen,
    change,
    values
}: IAccreditationsFormProps) => {
    const { translate } = useHeavent();
    const allAccreditations = React.useMemo(
        () =>
            allAccreditationCategories.flatMap(
                (accreditationCategory) => accreditationCategory.accreditations
            ),
        [allAccreditationCategories]
    );
    const selectedAccreditationSlotsIds = React.useMemo(
        () => values.flatMap((ad) => ad.accreditationSlotId),
        [values]
    );
    const accreditations = React.useMemo(
        () =>
            values.map((ad) => {
                const selectedAccreditation = allAccreditations.find(
                    (accreditation) => accreditation.id === ad.accreditationId
                )!;

                return {
                    id: selectedAccreditation.id,
                    name: selectedAccreditation.name,
                    slots: selectedAccreditation.slots.flatMap((slot) => {
                        if (!selectedAccreditationSlotsIds.includes(slot.id)) {
                            return [];
                        }

                        const otherSlotIds = selectedAccreditation.slots.flatMap((as) =>
                            as.id !== slot.id ? [as.id] : []
                        );
                        const selectedOtherSlotsIds = otherSlotIds.filter((osi) =>
                            isNonEmptyArray(
                                values.filter(
                                    (val) => val.accreditationSlotId === osi && val.isDefault
                                )
                            )
                        );

                        return [
                            {
                                isDisabled:
                                    selectedAccreditation.accreditationDisplay ===
                                        AccreditationDisplay.Radio &&
                                    isNonEmptyArray(selectedOtherSlotsIds),
                                ...slot
                            }
                        ];
                    }),
                    categoryName: selectedAccreditation.accreditationCategoryName,
                    isDefault: ad.isDefault,
                    maxResources: ad.maxResources ?? null
                };
            }),
        [values]
    );
    const isDefaultByAvailabilityVisible = React.useMemo(() => values.some(({ isDefault }) => isDefault), [values]);

    return (
        <Flex direction="column">
            <Box font="gray900 textMd medium">{translate('liste_des_accr_46997')}</Box>

            <Spacer height="3" />

            <AccreditationsTable
                accreditations={accreditations}
                setIsAddAccreditationOpen={setIsAddAccreditationOpen}
                change={change}
                values={values}
            />

            {isDefaultByAvailabilityVisible && (
                <>
                    <Spacer height="7" />

                    <Box font="gray900 textMd medium">{translate('attribution_des_59896')}</Box>

                    <Spacer height="1" />

                    <ToggleText name="delegation.addDefaultAccreditationsByAvailability">
                        {translate('attribuer_les_a_56919')}
                    </ToggleText>
                </>
            )}
        </Flex>
    );
};
