import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

interface IAllDayInBadgeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const AllDayInBadge = (props: IAllDayInBadgeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const position = vr.positionsSlotsUsersInfos[0]?.position.name || '';

    return (
        <>
            <Flex
                height={325}
                width={208}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/alldayin/recto.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$albert',
                    position: 'relative'
                }}
            >
                <Box
                    height={32}
                    width={32}
                    css={{
                        borderRadius: '32px',
                        left: '88px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '143px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    width={1}
                    css={{
                        position: 'absolute',
                        fontSize: '13px',
                        fontWeight: '700',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        top: '183px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    width={1}
                    css={{
                        position: 'absolute',
                        fontSize: '13px',
                        fontWeight: '700',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        top: '199px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    width={1}
                    css={{
                        position: 'absolute',
                        fontSize: '10px',
                        textAlign: 'center',
                        top: '219px'
                    }}
                >
                    {position}
                </Box>

                <Box
                    height={40}
                    width={40}
                    css={{
                        left: '84px',
                        position: 'absolute',
                        top: '247px'
                    }}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=80&width=80`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>
            </Flex>

            <Flex
                height={325}
                width={208}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/alldayin/verso.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />
        </>
    );
};
