import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { getColorByBackgroundColor } from '../../../util/color';
import { Emptyable } from '../../../util/emptyable';
import { isNonEmptyString } from '../../../util/string';

interface IBadgePocFrontProps {
    event: DocumentUserCustomBadgeFragment;
    logoUrl: Emptyable<string>;
}

export const BadgePocFront = (props: IBadgePocFrontProps) => {
    const userInfo = props.event.volunteerRegistration.userInfo;
    const accreditations = props.event.volunteerRegistration.accreditationsUsersInfos
        .map((aui) => aui.accreditation)
        .filter((a) => a.tags.map((t) => t.name).includes('Zones'));

    return (
        <Flex
            height={597}
            width={435}
            css={{
                background: `url(https://assets.recrewteer.com/badges/poc/pocRecto.png) no-repeat`,
                backgroundColor: '#37b3e9',
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Flex
                justify="center"
                width={1}
                css={{
                    position: 'absolute',
                    top: '60px'
                }}
            >
                {isNonEmptyString(props.logoUrl) && <img src={props.logoUrl} width="100px" />}
            </Flex>

            <Box
                width={1}
                css={{
                    color: 'black',
                    fontSize: '28px',
                    fontWeight: '700',
                    position: 'absolute',
                    px: '40px',
                    top: '303px'
                }}
            >
                {userInfo.firstName}
            </Box>

            <Box
                width={1}
                css={{
                    color: 'black',
                    fontSize: '28px',
                    fontWeight: '700',
                    position: 'absolute',
                    px: '40px',
                    top: '341px'
                }}
            >
                {userInfo.lastName}
            </Box>

            <Flex
                gap="2"
                wrap="wrap"
                css={{
                    position: 'absolute',
                    px: '44px',
                    top: '504px'
                }}
            >
                {accreditations.map((accreditation) => (
                        <Box
                            key={accreditation.id}
                            css={{
                                background: accreditation.color,
                                borderRadius: '$1',
                                color: getColorByBackgroundColor(accreditation.color),
                                fontSize: '11px',
                                padding: '$1'
                            }}
                        >
                            {accreditation.name}
                        </Box>
                    ))}
            </Flex>
        </Flex>
    );
};
