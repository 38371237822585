import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import { CampaignId, CampaignsSortAttributes } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { CampaignRow } from '../../../campaigns/list/campaignRow';
import { useInProgressRefresh } from '../../../campaigns/list/useInProgressRefresh';
import { useCommunityCampaignsQuery } from '../../../generated/graphqlHooks';

export const CommunityCampaignsList = () => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const {
        limit,
        nameDebounced,
        offset,
        predicates,
        reloadKey,
        setLimit,
        setOffset,
        setSort,
        sort
    } = useSegmentsContext<CampaignId, CampaignsSortAttributes>();
    const { data, isLoading, reload } = useCommunityCampaignsQuery({
        organizationId,
        predicates,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        limit,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.organization?.campaigns);

    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);

    useInProgressRefresh(data.organization?.campaigns.nodes ?? [], reload);

    return (
        <Flex width={1} css={{ flex: '1', overflow: 'hidden' }}>
            <TableGrid
                headerRow={
                    <HeaderRow>
                        <HeaderCellSort
                            attribute={CampaignsSortAttributes.Name}
                            sort={sort}
                            setSort={setSort}
                        >
                            {translate('nom_de_la_campa_45063')}
                        </HeaderCellSort>
                        <HeaderCellSort
                            attribute={CampaignsSortAttributes.Type}
                            width={150}
                            sort={sort}
                            setSort={setSort}
                        >
                            {translate('type_35427')}
                        </HeaderCellSort>
                        <HeaderCell width={200}>{translate('nombre_de_desti_21374')}</HeaderCell>
                        <HeaderCell width={150}>{translate('co_t_68998')}</HeaderCell>
                        <HeaderCellSort
                            attribute={CampaignsSortAttributes.State}
                            css={{ flex: '0 150px' }}
                            sort={sort}
                            setSort={setSort}
                        >
                            {translate('status_06428')}
                        </HeaderCellSort>
                        <HeaderCell width={100} />
                    </HeaderRow>
                }
                paginationRow={
                    <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                        <PaginationCell
                            limit={limit}
                            numberOfPages={numberOfPages}
                            offset={offset}
                            showLimits={true}
                            totalCount={totalCount}
                            setLimit={setLimit}
                            setOffset={setOffset}
                        />
                    </PaginationRow>
                }
            >
                {isLoading ? (
                    <>
                        <RowSkeleton />
                        <RowSkeleton />
                        <RowSkeleton />
                    </>
                ) : (
                    data.organization.campaigns.nodes.map((campaign) => (
                            <CampaignRow
                                key={campaign.id}
                                campaign={campaign}
                                eventId={null}
                                organizationId={organizationId}
                                getCampaignPath={(id) => HeaventPaths.COMMUNITY_CAMPAIGN(organizationId, id)}
                                getCampaignEditPath={(id) => HeaventPaths.COMMUNITY_EDIT_CAMPAIGN(organizationId, id)}
                                reload={reload}
                            />
                        ))
                )}
            </TableGrid>
        </Flex>
    );
};
