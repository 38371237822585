import {
    AccreditationState,
    EmailType,
    EventId,
    OrganizationId,
    UsersInfoId,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import {
    useEmailResendMutation,
    useVolunteerRegistrationAccreditationStateUpdateMutation
} from '../../../generated/graphqlHooks';
import { UserProfileAction } from './userProfileAction';
import { UserProfileActionsContainer } from './userProfileActionsContainer';

interface IEventUserProfileActionsAccreditationProps {
    accreditationState: AccreditationState;
    eventId: EventId;
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;
    volunteerRegistrationId: VolunteersRegistrationId;

    reload(): void;
}

export const EventUserProfileActionsAccreditation = (
    props: IEventUserProfileActionsAccreditationProps
) => {
    const translate = useTranslate();
    const { mutate: emailResend, isLoading: isEmailResendLoading } = useEmailResendMutation();
    const {
        mutate: accreditationStateNotApplicable,
        isLoading: isAccreditationStateNotApplicableLoading
    } = useVolunteerRegistrationAccreditationStateUpdateMutation();
    const {
        mutate: accreditationStateWaitingAccreditation,
        isLoading: isAccreditationStateWaitingAccreditationLoading
    } = useVolunteerRegistrationAccreditationStateUpdateMutation();
    const showChangeToWaiting =
        props.accreditationState !== AccreditationState.WaitingAccreditation;
    const showChangeToRefused = props.accreditationState !== AccreditationState.Refused;
    const showChangeToNotApplicable =
        props.accreditationState === AccreditationState.WaitingAccreditation ||
        props.accreditationState === AccreditationState.Accredited;
    const showEditAccreditation = props.accreditationState === AccreditationState.Accredited;
    const showAccredit = props.accreditationState === AccreditationState.WaitingAccreditation;
    const showResendAccreditationEmail = props.accreditationState === AccreditationState.Accredited;

    return (
        <UserProfileActionsContainer title={translate('Accreditation')}>
            {showChangeToWaiting && (
                <UserProfileAction
                    color="success"
                    icon="arrow-up-arrow-down"
                    isLoading={isAccreditationStateWaitingAccreditationLoading}
                    onClick={async () => {
                        await accreditationStateWaitingAccreditation({
                            eventId: props.eventId,
                            volunteerRegistrationId: props.volunteerRegistrationId,
                            state: AccreditationState.WaitingAccreditation
                        });

                        props.reload();
                    }}
                >
                    {translate('changer_le_stat_27197')}
                </UserProfileAction>
            )}

            {showChangeToRefused && (
                <UserProfileAction
                    color="success"
                    icon="arrow-up-arrow-down"
                    isLoading={isAccreditationStateWaitingAccreditationLoading}
                    onClick={async () => {
                        await accreditationStateWaitingAccreditation({
                            eventId: props.eventId,
                            volunteerRegistrationId: props.volunteerRegistrationId,
                            state: AccreditationState.Refused
                        });

                        props.reload();
                    }}
                >
                    {translate('changer_le_stat_62868')}
                </UserProfileAction>
            )}

            {showChangeToNotApplicable && (
                <UserProfileAction
                    color="success"
                    icon="arrow-up-arrow-down"
                    isLoading={isAccreditationStateNotApplicableLoading}
                    onClick={async () => {
                        await accreditationStateNotApplicable({
                            eventId: props.eventId,
                            volunteerRegistrationId: props.volunteerRegistrationId,
                            state: AccreditationState.NotApplicable
                        });

                        props.reload();
                    }}
                >
                    {translate('changer_le_stat_92651')}
                </UserProfileAction>
            )}

            {showEditAccreditation && (
                <UserProfileAction
                    color="success"
                    icon="pen"
                    path={HeaventPaths.ACCREDIT(
                        props.organizationId,
                        props.eventId,
                        props.userInfoId
                    )}
                >
                    {translate('_diter_les_accr_86042')}
                </UserProfileAction>
            )}

            {showAccredit && (
                <UserProfileAction
                    color="success"
                    icon="badge-check"
                    path={HeaventPaths.ACCREDIT(
                        props.organizationId,
                        props.eventId,
                        props.userInfoId
                    )}
                >
                    {translate('accr_diter_70543')}
                </UserProfileAction>
            )}

            {showResendAccreditationEmail && (
                <UserProfileAction
                    color="success"
                    icon="paper-plane"
                    isLoading={isEmailResendLoading}
                    onClick={async () => {
                        await emailResend({
                            eventId: props.eventId,
                            userInfoId: props.userInfoId,
                            emailType: EmailType.Accreditations
                        });
                    }}
                >
                    {translate('r_envoyer_l_e_24677')}
                </UserProfileAction>
            )}
        </UserProfileActionsContainer>
    );
};
