import { Images, Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IAuthPageProps {
    children: React.ReactNode;
}

export const AuthPage = (props: IAuthPageProps) => (
        <Flex align="center" height={1} width={1}>
            <Box height={1} width={0.5} css={{ padding: '150px' }}>
                <img src={Svgs.MsoLogin} alt="Vabene illustration" height="100%" width="100%" />
            </Box>

            <Flex
                direction="column"
                align="center"
                justify="center"
                height={1}
                width={0.5}
                css={{ background: '$cyan' }}
            >
                <Box>
                    <img src={Images.MsoLogo1} height="100" alt="Logo Vabene by MSO" />
                </Box>

                <Box height={108} />

                <Flex direction="column" width={400}>
                    {props.children}
                </Flex>
            </Flex>
        </Flex>
    );
