import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { EmailPreview } from '../../../../emails/preview/emailPreview';
import { useEmailSenderPreviewQuery } from '../../../../generated/graphqlHooks';

export const EmailSenderPreview = () => {
    const {
        translate,
        params: { organizationId, emailSenderId }
    } = useHeavent();
    const { data, loader } = useEmailSenderPreviewQuery({ organizationId, emailSenderId });
    const title = React.useMemo(() => {
        if (data.organization) {
            return data.organization.emailSender!.from;
        } else {
            return '-';
        }
    }, [data.organization]);

    return (
        <FullScreenPopup
            category={translate('aper_u_d_un_me_46930')}
            title={title}
            closePath={HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(organizationId)}
        >
            {loader || (
                <Box height={1} width={1} css={{ overflowY: 'auto' }}>
                    <EmailPreview
                        content={data.organization.emailSender!.preview}
                        from={data.organization.emailSender!.from}
                        subject={translate('e_mail_test_me_23293')}
                    />
                </Box>
            )}
        </FullScreenPopup>
    );
};
