import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const AccreditationsAssignmentsHeader = () => {
    const translate = useTranslate();

    return (
        <Flex
            direction="column"
            css={{ background: 'white', borderBottom: '1px solid $gray200', padding: '$6 $7' }}
        >
            <Box font="blueGray900 textXl medium">{translate('accr_ditations_39450')}</Box>
            <Box color="gray500">{translate('accr_diter_vos_35773')}</Box>
        </Flex>
    );
};
