import * as React from 'react';
import { BaseInputProps } from './input/baseInputProps';
import { TextInput } from './textInput';

interface IFloatInputProps extends BaseInputProps {
    value: number | null;

    onChange(value: number | null): void;
}

export const FloatInput = (props: IFloatInputProps) => {
    const [value, setValue] = React.useState(props.value?.toString() ?? '');
    const [savedValue, setSavedValue] = React.useState<number | null>(props.value);

    if (savedValue !== props.value) {
        setValue(props.value?.toString() ?? '');
        setSavedValue(props.value);
    }

    return (
        <TextInput
            {...props}
            value={value}
            onChange={(newValue: string) => {
                const floatValue = parseFloat(newValue);

                setValue(newValue);
                setSavedValue(isNaN(floatValue) ? null : floatValue);
                props.onChange(isNaN(floatValue) ? null : floatValue);
            }}
        />
    );
};
