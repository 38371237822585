import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { debugLog } from 'common/src/util/debug';
import * as React from 'react';
import { Alert } from '../components/alert';

export const Alerts = () => (
        <Flex direction="column" gap="6" width={1}>
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Alerts
            </Box>

            <Flex direction="column" gap="3">
                <Alert width={1}>We've just released a new feature!</Alert>

                <Alert
                    leftIcon="circle-exclamation"
                    rightIcon="xmark"
                    width={1}
                    rightIconClick={() => {
                        debugLog('close');
                    }}
                >
                    We've just released a new feature!
                </Alert>

                <Alert color="gray" width={1}>
                    We've just released a new feature!
                </Alert>

                <Alert color="error" width={1}>
                    We've just released a new feature!
                </Alert>

                <Alert color="warning" leftIcon="triangle-exclamation" width={1}>
                    We've just released a new feature!
                </Alert>

                <Alert color="success" leftIcon="circle-check" width={1}>
                    We've just released a new feature!
                </Alert>
            </Flex>
        </Flex>
    );
