import { TabPanel } from 'common-front/src/designSystem/components/tabs/tabPanel';
import { FormSlots } from 'common-front/src/forms/slots/formSlots';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { UserUpdateLayout } from 'common-front/src/users/update/userUpdateLayout';
import { IUserUpdateSlotsValues } from 'common-front/src/users/updateSlots/userUpdateSlotsValues';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserUpdateSlotsInfosQuery } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

interface IUserUpdateSlotsProps {
    event: UserUpdateSlotsInfosQuery['event'];
    forms: UserUpdateSlotsInfosQuery['event']['forms'];
    isLoading: boolean;
    userInfo: UserUpdateSlotsInfosQuery['organization']['userInfo'];
    values: IUserUpdateSlotsValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const UserUpdateSlots = (props: IUserUpdateSlotsProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();

    return (
        <UserUpdateLayout
            forms={props.forms}
            isLoading={props.isLoading}
            origin="event"
            title={translate('mise_jour_des_28904', props.userInfo.nameOrEmail)}
            getTabPath={(formId) => HeaventPaths.EDIT_USER_SLOTS_FORM(
                    organizationId,
                    eventId,
                    userInfoId,
                    formId
                )}
            onCancel={() => {
                history.push(HeaventPaths.VOLUNTEERS(organizationId, eventId));
            }}
            onUpdate={props.handleSubmit}
        >
            {props.forms.map((form, index) => (
                    <TabPanel
                        key={form.id}
                        path={HeaventPaths.EDIT_USER_SLOTS_FORM(
                            organizationId,
                            eventId,
                            userInfoId,
                            form.id
                        )}
                    >
                        <Spacer height="7" />

                        <FormSlots
                            currentSlots={props.values.userInfo.slots[index].slots}
                            customSlotsPeriods={form.customSlotsPeriods}
                            daysDisplay={form.daysDisplay}
                            event={props.event}
                            prefix={`userInfo.slots[${index}]`}
                            slotDisplay={form.slotDisplay}
                            change={props.change}
                        />
                    </TabPanel>
                ))}
        </UserUpdateLayout>
    );
};
