import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserEmail } from 'common/src/generated/types';
import { AuthInputService } from 'common/src/input/authInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IInviteProps {
    isLoading: boolean;

    mutate(email: UserEmail): Promise<any>;
    onClose(): void;
}

export const Invite = (props: IInviteProps) => {
    const translate = useTranslate();
    const authInput = useService(AuthInputService);
    const validateService = useService(ValidateService);

    return (
        <Form
            hideErrors={true}
            initialValues={{
                email: ''
            }}
            validate={validateService.validateForForm(authInput.emailSchema())}
            onSubmit={async (values: any) => {
                await props.mutate(values.email);

                props.onClose();
            }}
            render={({ handleSubmit }) => (
                    <Modal size="md" onClose={props.onClose}>
                        <Box color="primary600" fontSize="displayXs" textAlign="center">
                            <I icon="user-plus" />
                        </Box>

                        <Spacer height="7" />

                        <Box color="gray900" fontSize="textLg" textAlign="center">
                            {translate('inviter_un_memb_02124')}
                        </Box>

                        <Spacer height="1" />

                        <Box color="gray500" textAlign="center">
                            {translate('nous_allons_lui_03680')}
                        </Box>

                        <Spacer height="7" />

                        <FormErrors />

                        <TextInput
                            label={translate('e_mail_de_l_inv_06256')}
                            name="email"
                            isEmail={true}
                        />

                        <Spacer height="7" />

                        <Flex justify="end" gap="3">
                            <Button isLoading={props.isLoading} onClick={handleSubmit}>
                                {translate('envoyer_l_invit_13956')}
                            </Button>

                            <Button color="white" onClick={props.onClose}>
                                {translate('annuler_48254')}
                            </Button>
                        </Flex>
                    </Modal>
                )}
        />
    );
};
