import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Button } from '../designSystem/components/button';
import { TextInput } from '../designSystem/components/textInput';
import { ToggleText } from '../designSystem/components/toggle';
import { useSegmentsContext } from './segmentsContext';

interface ISearchRowProps {
    actions?: React.ReactNode;
    searchPlaceholder: string;
    showEditColumns: boolean;
    showEditMode: boolean;
}

export const SearchRow = (props: ISearchRowProps) => {
    const translate = useTranslate();
    const {
        isAdmin,
        isEditMode,
        isSaveVisible,
        name,
        numberOfSelected,
        setAreColumnsOpen,
        setIsEditMode,
        setIsFilterOpen,
        setIsSaveSegmentVisible,
        setName,
        setNameDebounced,
        setSelectedFilterPredicate
    } = useSegmentsContext();
    const showActions = props.actions && isAdmin && numberOfSelected > 0;

    return (
        <Flex align="center" gap="4">
            <Flex width={320}>
                <TextInput
                    value={name}
                    onChange={(newName: string) => {
                        setName(newName);
                        setNameDebounced(newName);
                    }}
                    placeholder={props.searchPlaceholder}
                    icon="magnifying-glass"
                />
            </Flex>

            <Button
                color="gray"
                leftIcon="bars-filter"
                onClick={() => {
                    setSelectedFilterPredicate(null);
                    setIsFilterOpen(true);
                }}
            >
                {translate('filtres_64574')}
            </Button>

            <Box css={{ flex: '1' }}>
                {isAdmin && isSaveVisible && (
                    <Button
                        color="invisible"
                        onClick={() => {
                            setIsSaveSegmentVisible(true);
                        }}
                    >
                        {translate('enregistrer_la_22884')}
                    </Button>
                )}
            </Box>

            {!showActions && props.showEditColumns && (
                <Button
                    color="white"
                    leftIcon="gear"
                    onClick={() => {
                        setAreColumnsOpen(true);
                    }}
                >
                    {translate('_diter_les_colo_39630')}
                </Button>
            )}

            {!showActions && props.showEditMode && (
                <ToggleText value={isEditMode} onChange={setIsEditMode}>
                    {translate('mode_dition_93778')}
                </ToggleText>
            )}

            {showActions && props.actions}
        </Flex>
    );
};
