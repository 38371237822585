import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import * as React from 'react';

interface IMapOptionsProps {
    children: React.ReactNode;
    isOpen: boolean;

    onClick(): void;
}

export const MapOptions = (props: IMapOptionsProps) => (
        <Flex
            align="center"
            gap="3"
            css={{
                background: 'white',
                borderRadius: '$2',
                boxShadow: '$md',
                cursor: 'pointer',
                height: '48px',
                minWidth: '180px',
                padding: '$2 $3'
            }}
            onClick={(e) => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();

                props.onClick();
            }}
        >
            {props.children}

            <Box color="gray700">
                {props.isOpen ? <I icon="chevron-up" /> : <I icon="chevron-down" />}
            </Box>
        </Flex>
    );
