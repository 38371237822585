import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useEventContext } from '../../events/show/eventContext';
import { useVolunteersSegmentsQuery } from '../../generated/graphqlHooks';
import { Volunteers } from './volunteers';

export const VolunteersLoader = () => {
    const {
        params: { organizationId, eventId }
    } = useHeavent();
    const { localEvent } = useEventContext();
    const { data, loader, reload } = useVolunteersSegmentsQuery({ organizationId, eventId });
    const segmentsIds = React.useMemo(() => (data.event?.segmentsFolders ?? []).flatMap((sf) => sf.segments).map((s) => s.id), [data.event]);

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={HeaventPaths.VOLUNTEERS_SEGMENT(
                        ':organizationId',
                        ':eventId',
                        ':segmentId'
                    )}
                    children={
                        <Volunteers
                            customFields={data.organization.customFields.nodes}
                            event={data.event}
                            reload={reload}
                        />
                    }
                />

                <Route
                    children={
                        <Empty
                            path={HeaventPaths.VOLUNTEERS_SEGMENT(
                                organizationId,
                                eventId,
                                localEvent?.volunteersSegmentId &&
                                    segmentsIds.includes(localEvent?.volunteersSegmentId)
                                    ? localEvent.volunteersSegmentId
                                    : segmentsIds[0]
                            )}
                            replace={true}
                        />
                    }
                />
            </Switch>
        );
    }
};
