import * as React from 'react';
import { Flex } from '../../designSystem/components/flex';
import { I } from '../../designSystem/components/i';
import { theme } from '../../designSystem/components/stitches';
import { CheckInState, VolunteerRegistrationState } from '../../generated/types';
import { getStateBadgeColorIcon } from '../../vo/volunteerRegistration';
import { getCheckInStateColor } from '../../vo/volunteerRegistrationSlot';

interface IRegistrationStateProps {
    registrationState: VolunteerRegistrationState;
}

interface ICheckInStateProps {
    checkInState: CheckInState;
}

type IStateIconProps = (IRegistrationStateProps | ICheckInStateProps) & {
    size: number;
};

export const StateIcon = (props: IStateIconProps) => {
    let baseColor;
    let color: keyof typeof theme.colors;
    let leftIcon;
    if ('registrationState' in props) {
        ({ color: baseColor, leftIcon } = getStateBadgeColorIcon(props.registrationState));
        color = `${baseColor}100`;
    } else {
        baseColor = getCheckInStateColor(props.checkInState);
        color = `${baseColor}500`;
    }

    return (
        <Flex
            align="center"
            justify="center"
            height={props.size}
            width={props.size}
            css={{
                background: theme.colors[color].value,
                border: '2px solid white',
                borderRadius: `${props.size}px`,
                bottom: '0',
                color: theme.colors[`${baseColor}700`].value,
                fontSize: `${Math.round(0.45 * props.size)}px`,
                right: '0',
                position: 'absolute'
            }}
        >
            {leftIcon && <I icon={leftIcon} />}
        </Flex>
    );
};
