import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { Row } from 'common/src/designSystem/components/table/row';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import {
    AccreditationId,
    DelegationId,
    SortDirection,
    VolunteersRegistrationsSortAttributes
} from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { useAccreditationUsersQuery } from '../../../generated/graphqlHooks';

interface IAccreditationUsersProps {
    accreditationId: Emptyable<AccreditationId>;
    delegationId: Emptyable<DelegationId>;
}

export const AccreditationUsers = (props: IAccreditationUsersProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [offset, setOffset] = React.useState(0);
    const [sort, setSort] = React.useState<Sort<VolunteersRegistrationsSortAttributes> | null>({
        direction: SortDirection.Asc,
        attribute: VolunteersRegistrationsSortAttributes.Name
    });
    const { data, isLoading } = useAccreditationUsersQuery({
        eventId,
        accreditationId: props.accreditationId,
        delegationId: props.delegationId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.volunteersRegistrations);

    return (
        <TableFilters
            filters={
                <Box width={320}>
                    <TextInput
                        icon="magnifying-glass"
                        placeholder={translate('rechercher_un_m_05904')}
                        value={name}
                        onChange={setName}
                    />
                </Box>
            }
            headerCells={
                <>
                    <HeaderCellSort
                        attribute={VolunteersRegistrationsSortAttributes.Name}
                        sort={sort}
                        setSort={setSort}
                    >
                        {translate('full_name')}
                    </HeaderCellSort>
                    <HeaderCell>{translate('Phone')}</HeaderCell>
                </>
            }
            numberOfPages={numberOfPages}
            offset={offset}
            rows={
                isLoading ? (
                    <>
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                        <RowSkeleton bx={true} />
                    </>
                ) : data.event.volunteersRegistrations.totalCount === 0 ? (
                    <Flex
                        direction="column"
                        gap="4"
                        align="center"
                        css={{
                            border: '1px solid $gray200',
                            borderTop: 'none',
                            py: '$8'
                        }}
                    >
                        <IconBackground color="primary" icon="user-group" />

                        <Box color="gray800" fontWeight="medium">
                            {translate('aucun_membre_ac_67303')}
                        </Box>
                    </Flex>
                ) : (
                    data.event.volunteersRegistrations.nodes.map((vr) => (
                            <Row key={vr.userInfo.id}>
                                <Cell>
                                    <AvatarNameEmail userInfo={vr.userInfo} hasName={true} />
                                </Cell>
                                <Cell>{vr.userInfo.phone?.internationalFormat ?? '-'}</Cell>
                            </Row>
                        ))
                )
            }
            title={translate('liste_des_membr_60403')}
            totalCount={totalCount}
            setOffset={setOffset}
        />
    );
};
