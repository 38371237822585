import * as React from 'react';
import { Avatar } from '../../../components/avatar/avatar';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

interface IBadgeJardinMichelProProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeJardinMichelPro = (props: IBadgeJardinMichelProProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            height={378}
            width={283}
            css={{
                background: `url(https://assets.recrewteer.com/badges/jardinmichel/Jardin_Pro_Recto.png) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    height: '96px',
                    left: '87px',
                    position: 'absolute',
                    top: '110px',
                    width: '110px'
                }}
            >
                <Avatar size={110} name={ui.name} email={ui.email} image={ui.picture?.url} />
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontFamily: '$inter',
                    fontSize: '16px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    textTransform: 'capitalize',
                    top: '232px',
                    width: '100%'
                }}
            >
                {ui.firstName}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontFamily: '$inter',
                    fontSize: '16px',
                    fontWeight: '700',
                    position: 'absolute',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    top: '253px',
                    width: '100%'
                }}
            >
                {ui.lastName}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontFamily: '$inter',
                    fontSize: '14px',
                    fontWeight: '600',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '276px',
                    width: '100%'
                }}
            >
                {ui.fields.cf29612}
            </Box>
        </Flex>
    );
};
