import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { useHistory, useLocation } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Alerts } from './alerts';
import { Badges } from './badges';
import { Buttons } from './buttons';
import { Checkboxes } from './checkboxes';
import { Dropdowns } from './dropdowns';
import { Files } from './files';
import { Form } from './form';
import { Inputs } from './inputs';
import { Modals } from './modals';
import { Radios } from './radios';
import { RightPanels } from './rightPanels';
import { Selects } from './selects';
import { Skeletons } from './skeletons';
import { Tables } from './tables';
import { Tabs } from './tabs';
import { Typography } from './typography';

interface IMenuItemsProps {
    link: string;
    children: React.ReactNode;
}

const MenuItem = (props: IMenuItemsProps) => {
    const history = useHistory();
    const location = useLocation();

    return (
        <Box
            color="gray800"
            fontWeight={props.link === location.pathname ? 'semiBold' : 'regular'}
            css={{
                cursor: 'pointer'
            }}
            onClick={() => {
                history.push(props.link);
            }}
        >
            {props.children}
        </Box>
    );
};

export const Showcase = () => (
        <Flex
            css={{
                height: '100%',
                width: '100%'
            }}
        >
            <Flex
                direction="column"
                gap="3"
                css={{
                    paddingLeft: '$6',
                    py: '$6',
                    width: '200px'
                }}
            >
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_ALERTS}>Alerts</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_BADGES}>Badges</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_BUTTONS}>Buttons</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_CHECKBOXES}>Checkboxes</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_DROPDOWNS}>Dropdowns</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_FILES}>Files</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_FORM}>Form</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_INPUTS}>Inputs</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_MODALS}>Modals</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_RADIOS}>Radios</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_RIGHT_PANELS}>Right panels</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_SELECTS}>Selects</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_SKELETON}>Skeletons</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_TABS_ONE}>Tabs</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_TABLES}>Tables</MenuItem>
                <MenuItem link={HeaventPaths.DESIGN_SYSTEM_TYPOGRAPHY}>Typography</MenuItem>
            </Flex>

            <Flex
                css={{
                    flex: '1',
                    overflow: 'auto',
                    paddingRight: '$6',
                    py: '$6',
                    width: '100%'
                }}
            >
                <Switch>
                    <Route exact path={HeaventPaths.DESIGN_SYSTEM_ALERTS} children={<Alerts />} />
                    <Route exact path={HeaventPaths.DESIGN_SYSTEM_BADGES} children={<Badges />} />
                    <Route exact path={HeaventPaths.DESIGN_SYSTEM_BUTTONS} children={<Buttons />} />
                    <Route
                        exact
                        path={HeaventPaths.DESIGN_SYSTEM_CHECKBOXES}
                        children={<Checkboxes />}
                    />
                    <Route
                        exact
                        path={HeaventPaths.DESIGN_SYSTEM_DROPDOWNS}
                        children={<Dropdowns />}
                    />
                    <Route exact path={HeaventPaths.DESIGN_SYSTEM_FILES} children={<Files />} />
                    <Route exact path={HeaventPaths.DESIGN_SYSTEM_FORM} children={<Form />} />
                    <Route exact path={HeaventPaths.DESIGN_SYSTEM_INPUTS} children={<Inputs />} />
                    <Route exact path={HeaventPaths.DESIGN_SYSTEM_MODALS} children={<Modals />} />
                    <Route exact path={HeaventPaths.DESIGN_SYSTEM_RADIOS} children={<Radios />} />
                    <Route
                        exact
                        path={HeaventPaths.DESIGN_SYSTEM_RIGHT_PANELS}
                        children={<RightPanels />}
                    />
                    <Route exact path={HeaventPaths.DESIGN_SYSTEM_SELECTS} children={<Selects />} />
                    <Route path={HeaventPaths.DESIGN_SYSTEM_SKELETON} children={<Skeletons />} />
                    <Route path={HeaventPaths.DESIGN_SYSTEM_TABS} children={<Tabs />} />
                    <Route exact path={HeaventPaths.DESIGN_SYSTEM_TABLES} children={<Tables />} />
                    <Route
                        exact
                        path={HeaventPaths.DESIGN_SYSTEM_TYPOGRAPHY}
                        children={<Typography />}
                    />
                </Switch>
            </Flex>
        </Flex>
    );
