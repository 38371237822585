import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { theme } from 'common/src/designSystem/components/stitches';
import * as React from 'react';

interface IDocumentRowProps {
    color: 'purple' | 'success' | 'blueGray' | 'pink';
    count?: number;
    disabled?: boolean;
    icon: IIcon;
    path: string;
    text: string;
    title: string;
}

export const DocumentRow = (props: IDocumentRowProps) => {
    const { history, translate } = useHeavent();

    return (
        <Flex
            align="center"
            height={74}
            width={1}
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                cursor: 'pointer',
                px: '$8'
            }}
            onClick={() => {
                if (!props.disabled) {
                    history.push(props.path);
                }
            }}
        >
            <Flex
                align="center"
                justify="center"
                height={40}
                width={40}
                css={{
                    background: theme.colors[`${props.color}100`],
                    borderRadius: '$3',
                    color: theme.colors[`${props.color}700`]
                }}
            >
                <I icon={props.icon} />
            </Flex>

            <Spacer width="4" />

            <Box color="gray800" fontSize="textMd" css={{ flex: '1' }}>
                {props.title}
            </Box>

            {props.count !== undefined && !props.disabled && (
                <>
                    <Spacer width="6" />

                    <Flex gap="1">
                        <Box color="gray500">{props.text}</Box>
                        <Box color="gray800">{props.count}</Box>
                    </Flex>
                </>
            )}

            {props.disabled && (
                <Button disabled={true} size="sm">
                    {translate('non_disponible_23071')}
                </Button>
            )}
        </Flex>
    );
};
