import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A6_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeElephantsFrontProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeElephantsFront = (props: IBadgeElephantsFrontProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;
    const accreditations = vr.accreditationsUsersInfos.map((aui) => aui.accreditation);
    const vestiairesAccred = accreditations.find((a) => a.acronym === 'VEST');
    const tribunesAccred = accreditations.find((a) => a.acronym === 'TRI');
    const presseAccred = accreditations.find((a) => a.acronym === 'ZP');
    const immersionAccred = accreditations.find((a) => a.acronym === 'IMM');
    const fanAccred = accreditations.find((a) => a.acronym === 'FAN');
    const glaceAccred = accreditations.find((a) => a.acronym === 'GLA');

    return (
        <Flex
            height={A6_SIZES['96dpi'].height}
            width={A6_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/bruleurs/chamberyRecto.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$orbitron',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    height: '89px',
                    left: '53px',
                    position: 'absolute',
                    top: '158px',
                    width: '70px'
                }}
            >
                {isNonEmptyString(userInfo.picture?.url) && (
                    <img src={userInfo.picture!.url} height="100%" width="100%" />
                )}
            </Box>

            <Box
                css={{
                    color: '#03014c',
                    fontSize: '18px',
                    fontWeight: '700',
                    left: '145px',
                    position: 'absolute',
                    top: '160px'
                }}
            >
                {userInfo.firstName}
            </Box>

            <Box
                css={{
                    color: '#03014c',
                    fontSize: '18px',
                    fontWeight: '700',
                    left: '145px',
                    position: 'absolute',
                    top: '193px'
                }}
            >
                {userInfo.lastName}
            </Box>

            <Box
                css={{
                    color: '#03014c',
                    fontSize: '18px',
                    fontWeight: '700',
                    left: '145px',
                    position: 'absolute',
                    top: '223px'
                }}
            >
                {userInfo.fields.cf21877}
            </Box>

            {tribunesAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '6px',
                        left: '34px',
                        position: 'absolute',
                        top: '270px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={22}
                        width={38}
                        css={{
                            background: tribunesAccred?.color,
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '700'
                        }}
                    >
                        1
                    </Flex>

                    <Box css={{ color: 'white', fontSize: '11px', fontWeight: '700' }}>Tribune</Box>
                </Flex>
            )}

            {presseAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '6px',
                        left: '208px',
                        position: 'absolute',
                        top: '270px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={22}
                        width={38}
                        css={{
                            background: presseAccred?.color,
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '700'
                        }}
                    >
                        4
                    </Flex>

                    <Box css={{ color: 'white', fontSize: '11px', fontWeight: '700' }}>
                        Zone Presse
                    </Box>
                </Flex>
            )}

            {vestiairesAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '6px',
                        left: '34px',
                        position: 'absolute',
                        top: '303px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={22}
                        width={38}
                        css={{
                            background: vestiairesAccred?.color,
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '700'
                        }}
                    >
                        2
                    </Flex>

                    <Box css={{ color: 'white', fontSize: '11px', fontWeight: '700' }}>
                        Vestiaires
                    </Box>
                </Flex>
            )}

            {immersionAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '6px',
                        left: '208px',
                        position: 'absolute',
                        top: '303px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={22}
                        width={38}
                        css={{
                            background: vestiairesAccred?.color,
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '700'
                        }}
                    >
                        5
                    </Flex>

                    <Box css={{ color: 'white', fontSize: '11px', fontWeight: '700' }}>
                        Espace IMMERSION
                    </Box>
                </Flex>
            )}

            {fanAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '6px',
                        left: '34px',
                        position: 'absolute',
                        top: '336px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={22}
                        width={38}
                        css={{
                            background: fanAccred?.color,
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '700'
                        }}
                    >
                        3
                    </Flex>

                    <Box css={{ color: 'white', fontSize: '11px', fontWeight: '700' }}>
                        FAN EXPERIENCE
                    </Box>
                </Flex>
            )}

            {glaceAccred && (
                <Flex
                    align="center"
                    css={{
                        gap: '6px',
                        left: '208px',
                        position: 'absolute',
                        top: '336px'
                    }}
                >
                    <Flex
                        align="center"
                        justify="center"
                        height={22}
                        width={38}
                        css={{
                            background: glaceAccred?.color,
                            color: 'white',
                            fontSize: '14px',
                            fontWeight: '700'
                        }}
                    >
                        6
                    </Flex>

                    <Box css={{ color: 'white', fontSize: '11px', fontWeight: '700' }}>
                        Bord de Glace
                    </Box>
                </Flex>
            )}
        </Flex>
    );
};
