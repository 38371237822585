import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { getRichTextDefaultStyles } from 'common/src/designSystem/components/richEditor/richText';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { theme } from 'common/src/designSystem/components/stitches';
import { TranslationService } from 'common/src/services/translationService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { API_BASE_URL } from '../../../consts';
import { BaseInputProps } from '../input/baseInputProps';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import Editor from './ckeditor5/build/ckeditor.js';
import './ckeditor5/build/translations/de.js';
import './ckeditor5/build/translations/en.js';
import './ckeditor5/build/translations/es.js';
import './ckeditor5/build/translations/it.js';
import './ckeditor5/build/translations/nl.js';

interface IRichEditorProps extends Omit<BaseInputProps, 'icon' | 'rightIcon'> {
    initialValue: string;

    onChange(value: string): void;
}

export const RichEditor = (props: IRichEditorProps) => {
    const translate = useTranslate();
    const { language } = useService(TranslationService);
    const colors = React.useMemo(() => [
            {
                color: 'white',
                label: translate('blanc_36425')
            },
            {
                color: theme.colors.gray800.value,
                label: translate('gris_fonc_51159')
            },
            {
                color: theme.colors.gray400.value,
                label: translate('gris_clair_52465')
            },
            {
                color: theme.colors.primary900.value,
                label: translate('bleu_fonc_40491')
            },
            {
                color: theme.colors.primary700.value,
                label: translate('bleu_41949')
            },
            {
                color: theme.colors.error700.value,
                label: translate('rouge_fonc_45023')
            },
            {
                color: theme.colors.error500.value,
                label: translate('rouge_75079')
            },
            {
                color: theme.colors.warning300.value,
                label: translate('jaune_53503')
            },
            {
                color: theme.colors.warning600.value,
                label: translate('orange_64132')
            },
            {
                color: theme.colors.success700.value,
                label: translate('vert_fonc_37265')
            },
            {
                color: theme.colors.success500,
                label: translate('vert_27018')
            },
            {
                color: theme.colors.purple700.value,
                label: translate('violet_fonc_77270')
            },
            {
                color: theme.colors.purple500.value,
                label: translate('violet_54005')
            },
            {
                color: theme.colors.pink700.value,
                label: translate('rose_fonc_98996')
            },
            {
                color: theme.colors.pink500.value,
                label: translate('rose_49360')
            }
        ], []);

    return (
        <Flex css={props.css} direction="column" width={1}>
            <Label>{props.label}</Label>

            <Description>{props.description}</Description>

            {(props.label || props.description) && <Spacer height="1" />}

            <Box
                width={1}
                css={{
                    '--ck-border-radius': '$radii$1',
                    '--ck-color-toolbar-background': '$colors$gray100',
                    '& .ck.ck-toolbar': {
                        borderColor: props.state === 'error' ? '$error500' : '$gray300',
                        borderBottom: 'none',

                        '& .ck.ck-toolbar__separator': {
                            background: '$gray200'
                        },

                        '& .ck.ck-button': {
                            color: '$gray600',

                            '&:hover': {
                                background: '$gray200',
                                color: '$gray800',
                                cursor: 'pointer'
                            },

                            '&.ck-on, &.ck-on:hover:not(.ck-disabled)': {
                                background: '$primary300',
                                color: '$primary700'
                            }
                        }
                    },
                    '& .ck.ck-content': {
                        borderColor:
                            props.state === 'error'
                                ? '$error500 !important'
                                : '$gray300 !important',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderTop: 'none !important',
                        color: '$gray800',
                        minHeight: '100px',
                        px: '$3',

                        '&.ck-editor__editable.ck-focused': {
                            boxShadow: 'none'
                        },

                        '& p.h-small': {
                            fontSize: '12px'
                        }
                    },
                    '& .ck.ck-editor__editable > .ck-placeholder::before': {
                        color: '$gray500'
                    },
                    ...getRichTextDefaultStyles('& .ck.ck-content')
                }}
            >
                <CKEditor
                    editor={Editor}
                    data={props.initialValue}
                    config={{
                        placeholder: props.placeholder,
                        fontColor: {
                            colors
                        },
                        fontBackgroundColor: {
                            colors
                        },
                        language: language.toString().toLowerCase(),
                        simpleUpload: {
                            uploadUrl: `${API_BASE_URL}image/upload`
                        },
                        heading: {
                            options: [
                                {
                                    model: 'paragraphSmall',
                                    view: {
                                        name: 'p',
                                        classes: 'h-small'
                                    },
                                    title: translate('paragraphe_pet_01073'),
                                    class: 'ck-heading_paragraph_small',
                                    converterPriority: 'high'
                                },
                                {
                                    model: 'paragraph',
                                    title: 'Paragraph',
                                    class: 'ck-heading_paragraph'
                                },
                                {
                                    model: 'heading1',
                                    view: 'h2',
                                    title: 'Heading 1',
                                    class: 'ck-heading_heading1'
                                },
                                {
                                    model: 'heading2',
                                    view: 'h3',
                                    title: 'Heading 2',
                                    class: 'ck-heading_heading2'
                                },
                                {
                                    model: 'heading3',
                                    view: 'h4',
                                    title: 'Heading 3',
                                    class: 'ck-heading_heading2'
                                }
                            ]
                        }
                    }}
                    onChange={(_event: any, editor: any) => {
                        props.onChange(editor.getData());
                    }}
                    onBlur={() => {
                        props.onBlur?.();
                    }}
                    onFocus={() => {
                        props.onFocus?.();
                    }}
                />
            </Box>

            <Hint state={props.state}>{props.hint}</Hint>
        </Flex>
    );
};
