import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface ILinkProps {
    children: React.ReactNode;
}

export const Link = (props: ILinkProps) => (
        <Flex
            direction={{ '@initial': 'column', '@desktop': 'row' }}
            align="center"
            justify="center"
            gap="1"
            width={1}
        >
            {props.children}
        </Flex>
    );
