import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import * as React from 'react';

interface IFormBoxProps {
    children?: React.ReactNode;
    stepNumber: number;
    title: string;
    canSelect: boolean;
    isOpen: boolean;
    isCompleted: boolean;

    onSelect(): void;
}

export const FormBox = (props: IFormBoxProps) => (
        <Box
            css={{
                borderRadius: '$1',
                boxShadow: '$sm',
                overflow: 'hidden',
                width: '100%'
            }}
        >
            <Flex
                align="center"
                gap="4"
                height={64}
                width={1}
                css={{
                    background: props.isOpen ? '$gray900' : 'white',
                    cursor: props.canSelect ? 'pointer' : 'default',
                    px: '$6'
                }}
                onClick={() => {
                    if (props.canSelect) {
                        props.onSelect();
                    }
                }}
            >
                <Flex
                    align="center"
                    justify="center"
                    height={32}
                    width={32}
                    css={{
                        background: props.isCompleted ? '$success200' : 'white',
                        border: props.isCompleted ? 'none' : '1px solid $gray200',
                        borderRadius: '32px',
                        color: props.isCompleted ? '$success700' : '$gray800',
                        fontWeight: '$medium'
                    }}
                >
                    {props.isCompleted ? <I icon="check" /> : props.stepNumber}
                </Flex>

                <Box color={props.isOpen ? 'white' : 'gray800'} fontSize="textMd" fontWeight="medium">
                    {props.title}
                </Box>
            </Flex>

            {props.isOpen && (
                <Box
                    css={{
                        background: 'white',
                        padding: '$6 70px',
                        width: '100%'
                    }}
                >
                    {props.children}
                </Box>
            )}
        </Box>
    );
