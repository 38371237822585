import { groupBy, maxBy, minBy, sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { Spacer } from '../../designSystem/components/spacer';
import { DocumentUserPlanningDaysFragment, UserPlanningDaysOptions } from '../../generated/types';
import { DateTimeService } from '../../services/dateTimeService';
import { useService } from '../../util/dependencies/dependencies';
import { LocaleFormats } from '../../util/luxon';
import { isNonEmptyString } from '../../util/string';

interface IUserPlanningDaysProps {
    date?: DateTime;
    endDate?: DateTime;
    event: DocumentUserPlanningDaysFragment;
    options: UserPlanningDaysOptions;
}

export const UserPlanningDays = (props: IUserPlanningDaysProps) => {
    const dateTimeService = useService(DateTimeService);
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const min = React.useMemo(() => minBy(vr.positionsSlotsUsersInfos.map((psui) => psui.positionSlot.range.start!))!, [vr.positionsSlotsUsersInfos]);
    const max = React.useMemo(() => maxBy(vr.positionsSlotsUsersInfos.map((psui) => psui.positionSlot.range.end!))!, [vr.positionsSlotsUsersInfos]);
    const grouped: Array<[string, string[]]> = React.useMemo(() => sortBy(
            Object.entries(
                groupBy(vr.positionsSlotsUsersInfos, (psui) =>
                    psui.positionSlot.range.start!.startOf('day').toMillis()
                )
            ),
            ([_, psuis]) => psuis[0].positionSlot.range.start!.startOf('day').toMillis()
        ).map(([_, psuis]) => {
            const psui = psuis[0];
            const missions = sortBy(
                Object.values(groupBy(psuis, (psui) => psui.position.id)),
                ([{ position }]) => position.name
            ).map((positions) => {
                const position = positions[0].position;
                const ranges = sortBy(
                    positions.map(({ positionSlot }) => positionSlot.range),
                    (r) => r.start!.toMillis()
                )
                    .map((range) => `${range.start!.toFormat(`H'h'mm`)}-${range.end!.toFormat(
                            `H'h'mm`
                        )}`)
                    .join(' | ');

                return `${position.name} : ${ranges}`;
            });

            return [
                dateTimeService.toLocaleString(
                    psui.positionSlot.range.start!.startOf('day'),
                    LocaleFormats.DateOnly.WeekdayLongMonthLong
                ),
                missions
            ];
        }), [vr.positionsSlotsUsersInfos]);

    return (
        <>
            <Flex
                direction="column"
                width={1}
                css={{
                    background: '$gray100',
                    padding: '32px 45px 50px 45px'
                }}
            >
                {isNonEmptyString(props.options.logo?.content) && (
                    <>
                        <Box height={56} width={1} textAlign="center">
                            <img src={props.options.logo.content} height="100%" width="auto" />
                        </Box>

                        <Spacer height="7" />
                    </>
                )}

                <Flex
                    direction="column"
                    height={1}
                    width={1}
                    css={{
                        background: 'white',
                        borderRadius: '16px',
                        padding: '32px'
                    }}
                >
                    <Box font="primary800 displayXs semiBold">{ui.nameOrEmail}</Box>
                    <Spacer height="1" />
                    <Box color="gray800">
                        Période : du {dateTimeService.toLocaleString(min, LocaleFormats.DateTime)}{' '}
                        au {dateTimeService.toLocaleString(max, LocaleFormats.DateTime)}
                    </Box>

                    <Spacer height="6" />

                    <Flex direction="column" gap="4">
                        {grouped.map(([date, positions], index) => (
                                <Flex key={index} direction="column" gap="1">
                                    <Box
                                        font="gray800 textSm bold"
                                        css={{ textTransform: 'capitalize' }}
                                    >
                                        {date}
                                    </Box>

                                    <Flex direction="column">
                                        {positions.map((position, positionIndex) => <Box key={positionIndex}>{position}</Box>)}
                                    </Flex>
                                </Flex>
                            ))}
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};
