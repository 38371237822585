import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { IIcon } from 'common/src/designSystem/components/i';
import * as React from 'react';
import { IInputState } from '../components/input/state';
import { TextInput } from '../components/textInput';

export const Inputs = () => {
    const [value, setValue] = React.useState('');
    const [state, setState] = React.useState<IInputState>('active');
    const [label, setLabel] = React.useState('');
    const [hint, setHint] = React.useState('');
    const [placeholder, setPlaceholder] = React.useState('');
    const [icon, setIcon] = React.useState<IIcon | undefined>();

    return (
        <Flex direction="column" gap="6">
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Inputs
            </Box>

            <Flex gap="3">
                <TextInput value={value} onChange={setValue} />

                <TextInput
                    value={value}
                    onChange={setValue}
                    label="Nom du poste"
                    placeholder="Nom du poste"
                />

                <TextInput
                    value={value}
                    onChange={setValue}
                    label="Nom du poste"
                    placeholder="Nom du poste"
                    hint="Entrer le nom du poste"
                />

                <TextInput value={value} onChange={setValue} placeholder="Nom du poste" />

                <TextInput value={value} onChange={setValue} icon="magnifying-glass" />

                <TextInput
                    value={value}
                    onChange={setValue}
                    icon="envelope"
                    placeholder="Rechercher ou créer un bénévole"
                />

                <TextInput value={value} onChange={setValue} state="disabled" />

                <TextInput value={value} onChange={setValue} state="error" />

                <TextInput
                    value={value}
                    onChange={setValue}
                    state="error"
                    label="Nom du poste"
                    placeholder="Nom du poste"
                    hint="Il y a une erreur"
                />
            </Flex>

            <Flex gap="3">
                <select
                    value={state}
                    onChange={(e) => {
                        setState(e.target.value as IInputState);
                    }}
                >
                    <option value="active">active</option>
                    <option value="disabled">disabled</option>
                    <option value="error">error</option>
                </select>

                <input
                    type="text"
                    placeholder="label"
                    value={label}
                    onChange={(e) => {
                        setLabel(e.target.value);
                    }}
                />

                <input
                    type="text"
                    placeholder="hint"
                    value={hint}
                    onChange={(e) => {
                        setHint(e.target.value);
                    }}
                />

                <input
                    type="text"
                    placeholder="placeholder"
                    value={placeholder}
                    onChange={(e) => {
                        setPlaceholder(e.target.value);
                    }}
                />

                <select
                    value={icon}
                    onChange={(e) => {
                        setIcon(e.target.value as IIcon);
                    }}
                >
                    <option value="">icon</option>
                    <option value="magnifying-glass">🔍</option>
                    <option value="envelope">✉️</option>
                </select>
            </Flex>

            <Flex>
                <TextInput
                    state={state}
                    label={label}
                    hint={hint}
                    placeholder={placeholder}
                    icon={icon}
                    value={value}
                    onChange={setValue}
                />
            </Flex>
        </Flex>
    );
};
