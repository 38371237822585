import * as React from 'react';
import XMark from 'svgComponents/fontawesome/xmark.svg';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';

interface IJardinMichelAccreditationProps {
    color: string;
    isSelected: boolean;
    text: string;
}

export const JardinMichelAccreditation = (props: IJardinMichelAccreditationProps) => (
        <Flex direction="column" align="center" justify="center" gap="1">
            <Flex
                align="center"
                justify="center"
                height={20}
                width={20}
                css={{
                    background: props.isSelected ? props.color : 'white',
                    border: `1px solid ${props.color}`,
                    borderRadius: '6px',
                    '& svg': {
                        height: '90%'
                    }
                }}
            >
                {!props.isSelected && <XMark />}
            </Flex>

            <Box css={{ color: props.color, fontSize: '10px', fontWeight: '600' }}>
                {props.text}
            </Box>
        </Flex>
    );
