import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { FormWizard, FormWizardPage } from 'common-front/src/components/form/formWizard';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import {
    CampaignId,
    CampaignInput,
    CreateCampaignEmailSenderFragment,
    EmailsSenderId
} from 'common/src/generated/types';
import { CampaignInputService, ICreateUpdateCampaignValues } from 'common/src/input/campaignInput';
import {
    useHistory,
    useParams,
    useService,
    useTranslate
} from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useEventContext } from '../../events/show/eventContext';
import {
    useCampaignCreateMutation,
    useCampaignOrganizationToEditQuery,
    useCampaignToEditQuery,
    useCampaignUpdateMutation,
    useCreateCampaignInfosQuery,
    useCreateOrganizationCampaignInfosQuery
} from '../../generated/graphqlHooks';
import { CampaignParameters } from './campaignParameters';
import { Delivery } from './delivery';
import { CampaignEventRecipients } from './recipients/campaignEventRecipients';
import { CampaignOrganizationRecipients } from './recipients/campaignOrganizationRecipients';
import { Summary } from './summary';
import { CampaignText } from './text/campaignText';

interface ICreateUpdateCampaignProps {
    campaignType: 'event' | 'organization';
    closePath: string;
    emailSenderId: Emptyable<EmailsSenderId>;
    emailsSenders: CreateCampaignEmailSenderFragment[];
    initialValues: ICreateUpdateCampaignValues;
    isAdmin: boolean;
    isEdit: boolean;
    timeZone: string;

    mutate(campaign: CampaignInput): Promise<CampaignId>;
}

const CreateUpdateCampaign = (props: ICreateUpdateCampaignProps) => {
    const translate = useTranslate();
    const campaignInput = useService(CampaignInputService);
    const history = useHistory();

    return (
        <FullScreenPopup
            category={
                props.isEdit
                    ? translate('mise_jour_de_17055', props.initialValues.campaign.name)
                    : translate('cr_ation_d_une_80936')
            }
            title={
                props.isEdit
                    ? props.initialValues.campaign.name
                    : translate('nouvelle_campag_28814')
            }
            closePath={props.closePath}
        >
            <CenteredContainer>
                <FormWizard
                    initialValues={props.initialValues}
                    isEdit={props.isEdit}
                    onSubmit={async (values: ICreateUpdateCampaignValues) => {
                        await props.mutate(values.campaign);

                        history.push(props.closePath);
                    }}
                >
                    <FormWizardPage
                        title={translate('initialisation_48904')}
                        schema={campaignInput.campaignStep1Schema()}
                        render={(formProps: FormRenderProps<ICreateUpdateCampaignValues>) => (
                                <CampaignParameters formProps={formProps} isAdmin={props.isAdmin} />
                            )}
                    />

                    <FormWizardPage
                        title={translate('contenu_29668')}
                        schema={campaignInput.campaignStep2Schema()}
                        render={(formProps: FormRenderProps<ICreateUpdateCampaignValues>) => (
                                <CampaignText
                                    emailSenderId={props.emailSenderId}
                                    emailsSenders={props.emailsSenders}
                                    formProps={formProps}
                                />
                            )}
                    />

                    <FormWizardPage
                        title={translate('destinataires_19592')}
                        schema={campaignInput.campaignStep3Schema()}
                        render={(formProps: FormRenderProps<ICreateUpdateCampaignValues>) => {
                            if (props.campaignType === 'event') {
                                return <CampaignEventRecipients formProps={formProps} />;
                            } else {
                                return <CampaignOrganizationRecipients formProps={formProps} />;
                            }
                        }}
                    />

                    <FormWizardPage
                        title={translate('livraison_86944')}
                        schema={campaignInput.campaignStep4Schema()}
                        render={(formProps: FormRenderProps<ICreateUpdateCampaignValues>) => <Delivery formProps={formProps} timeZone={props.timeZone} />}
                    />

                    <FormWizardPage
                        title={translate('r_capitulatif_a_79107')}
                        schema={campaignInput.createUpdateCampaignSchema()}
                        render={(formProps: FormRenderProps<ICreateUpdateCampaignValues>) => (
                                <Summary
                                    emailsSenders={props.emailsSenders}
                                    formProps={formProps}
                                    isEdit={props.isEdit}
                                />
                            )}
                    />
                </FormWizard>
            </CenteredContainer>
        </FullScreenPopup>
    );
};

export const CreateCampaign = () => {
    const { organizationId, eventId } = useParams();
    const campaignInput = useService(CampaignInputService);
    const { data, loader } = useCreateCampaignInfosQuery({ organizationId, eventId });
    const { mutate } = useCampaignCreateMutation();
    const {
        event: {
            permissions: { isEventAdmin }
        }
    } = useEventContext();

    return (
        loader || (
            <CreateUpdateCampaign
                campaignType="event"
                closePath={HeaventPaths.CAMPAIGNS(organizationId, eventId)}
                emailSenderId={data.event.emailSender?.id}
                emailsSenders={data.organization.emailsSenders}
                initialValues={campaignInput.createUpdateCampaignValuesDefault(
                    isEventAdmin,
                    data.event.emailSender?.id
                )}
                isAdmin={isEventAdmin}
                isEdit={false}
                timeZone={data.event.timeZone}
                mutate={async (campaign) => {
                    const {
                        campaignCreate: { id }
                    } = await mutate({
                        organizationId,
                        eventId,
                        campaign
                    });

                    return id;
                }}
            />
        )
    );
};

export const CreateOrganizationCampaign = () => {
    const { organizationId } = useParams();
    const campaignInput = useService(CampaignInputService);
    const { data, loader } = useCreateOrganizationCampaignInfosQuery({ organizationId });
    const { mutate } = useCampaignCreateMutation();

    return (
        loader || (
            <CreateUpdateCampaign
                campaignType="organization"
                closePath={HeaventPaths.COMMUNITY_CAMPAIGNS(organizationId)}
                emailSenderId={data.organization.emailSenderId}
                emailsSenders={data.organization.emailsSenders}
                initialValues={campaignInput.createUpdateCampaignValuesDefault(
                    true,
                    data.organization.emailSenderId
                )}
                isAdmin={true}
                isEdit={false}
                timeZone={data.organization.timeZone}
                mutate={async (campaign) => {
                    const {
                        campaignCreate: { id }
                    } = await mutate({
                        organizationId,
                        campaign
                    });

                    return id;
                }}
            />
        )
    );
};

export const UpdateCampaign = () => {
    const { organizationId, eventId, campaignId } = useParams();
    const { data, loader } = useCampaignToEditQuery({ organizationId, eventId, campaignId });
    const campaignInput = useService(CampaignInputService);
    const { mutate } = useCampaignUpdateMutation();
    const {
        event: {
            permissions: { isEventAdmin }
        }
    } = useEventContext();

    return (
        loader || (
            <CreateUpdateCampaign
                campaignType="event"
                closePath={HeaventPaths.CAMPAIGNS(organizationId, eventId)}
                emailSenderId={data.event.emailSender?.id}
                emailsSenders={data.organization.emailsSenders}
                initialValues={campaignInput.createUpdateCampaignValuesDefault(
                    isEventAdmin,
                    data.event.emailSender?.id,
                    data.event.campaign
                )}
                isAdmin={isEventAdmin}
                isEdit={true}
                timeZone={data.event.timeZone}
                mutate={async (campaign) => {
                    const {
                        campaignUpdate: { id }
                    } = await mutate({
                        organizationId,
                        eventId,
                        campaignId,
                        campaign
                    });

                    return id;
                }}
            />
        )
    );
};

export const UpdateOrganizationCampaign = () => {
    const { organizationId, campaignId } = useParams();
    const { data, loader } = useCampaignOrganizationToEditQuery({ organizationId, campaignId });
    const campaignInput = useService(CampaignInputService);
    const { mutate } = useCampaignUpdateMutation();

    return (
        loader || (
            <CreateUpdateCampaign
                campaignType="organization"
                closePath={HeaventPaths.COMMUNITY_CAMPAIGNS(organizationId)}
                emailsSenders={data.organization.emailsSenders}
                emailSenderId={data.organization.emailSenderId}
                initialValues={campaignInput.createUpdateCampaignValuesDefault(
                    true,
                    data.organization.emailSenderId,
                    data.organization.campaign
                )}
                isAdmin={true}
                isEdit={true}
                timeZone={data.organization.timeZone}
                mutate={async (campaign) => {
                    const {
                        campaignUpdate: { id }
                    } = await mutate({
                        organizationId,
                        campaignId,
                        campaign
                    });

                    return id;
                }}
            />
        )
    );
};
