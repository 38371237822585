import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { LeaderInfosFragment } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

export const PositionCategoryLeadersList = ({ leaders }: { leaders: LeaderInfosFragment[] }) => {
    const translate = useTranslate();

    return isNonEmptyArray(leaders) ? (
        <>
            {leaders.map((leader, index) => (
                    <React.Fragment key={leader.userInfo.id}>
                        {index !== 0 && <HorizontalSpacerSeparator height="4" />}

                        <AvatarNameEmail
                            userInfo={leader.userInfo}
                            hasName={isNonEmptyString(leader.userInfo.name)}
                            badge={
                                leader.canEdit ? (
                                    <Badge color="gray">{translate('_diteur_26621')}</Badge>
                                ) : (
                                    <Badge color="success">
                                        {translate('lecture_seule_63227')}
                                    </Badge>
                                )
                            }
                        />
                    </React.Fragment>
                ))}
        </>
    ) : (
        <Box color="gray500" width={1} textAlign="center">
            {translate('aucun_reponsabl_38504')}
        </Box>
    );
};
