import * as React from 'react';
import XMark from 'svgComponents/fontawesome/xmark.svg';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CSS } from '../../../designSystem/components/stitches';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A6_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IAccredProps {
    css?: CSS;
    index: number;
    left: number;
    text: string;
    top: number;
    upper: boolean;
}

const Accred = (props: IAccredProps) => (
        <Flex
            align="center"
            css={{
                color: 'white',
                fontSize: '19px',
                fontWeight: '700',
                gap: '10px',
                left: `${props.left}px`,
                position: 'absolute',
                textTransform: props.upper ? 'uppercase' : 'capitalize',
                top: `${props.top}px`,
                ...(props.css as any)
            }}
        >
            <Flex
                className="sqy-carre"
                align="center"
                justify="center"
                height={23}
                width={23}
                css={{
                    background: 'white',
                    color: 'black',
                    fontSize: '16px',
                    fontWeight: '700',
                    lineHeight: '20px'
                }}
            >
                {props.index}
            </Flex>

            <Box>{props.text}</Box>
        </Flex>
    );

interface IBadgeClubSqyProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeClubSqy = (props: IBadgeClubSqyProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const accreditationsIds: number[] = vr.accreditationsUsersInfos.map(
        (aui) => aui.accreditation.id
    );
    const delegationLeader = vr.delegation?.leaders[0];
    const leaderInfos = delegationLeader
        ? `${delegationLeader.name} - ${delegationLeader.phone?.internationalFormat ?? ''}`
        : '';

    return (
        <Flex height={A6_SIZES['96dpi'].height} width={A6_SIZES['96dpi'].width * 2}>
            <Flex
                height={A6_SIZES['96dpi'].height}
                width={A6_SIZES['96dpi'].width}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/clubSqy/recto_sans_trait.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$paris2024',
                    position: 'relative'
                }}
            >
                <Box
                    height={112}
                    width={112}
                    css={{
                        left: '29px',
                        position: 'absolute',
                        top: '94px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    css={{
                        color: 'white',
                        fontSize: '19px',
                        fontWeight: '700',
                        left: '166px',
                        position: 'absolute',
                        top: '94px',
                        textTransform: 'uppercase'
                    }}
                >
                    {vr.delegation?.name ?? ''}
                </Box>

                <Box
                    css={{
                        color: 'white',
                        fontSize: '19px',
                        fontWeight: '700',
                        left: '166px',
                        position: 'absolute',
                        top: '138px',
                        textTransform: 'uppercase'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        color: 'white',
                        fontSize: '19px',
                        fontWeight: '700',
                        left: '166px',
                        position: 'absolute',
                        top: '182px',
                        textTransform: 'uppercase'
                    }}
                >
                    {ui.fields.cf32340 || ui.lastName}
                </Box>

                <Box
                    css={{
                        color: 'white',
                        fontSize: '16px',
                        fontWeight: '700',
                        left: '20px',
                        position: 'absolute',
                        top: '274px',
                        textTransform: 'uppercase'
                    }}
                >
                    Accès autorisé(s)
                </Box>

                {accreditationsIds.includes(2775) && (
                    <Accred index={1} left={28} top={313} text="Fan zone" upper={true} />
                )}
                {accreditationsIds.includes(2777) && (
                    <Accred index={2} left={28} top={354} text="Base vie" upper={true} />
                )}
                {accreditationsIds.includes(2829) && (
                    <Accred index={3} left={28} top={393} text="VIP" upper={true} />
                )}
                {accreditationsIds.includes(2828) && (
                    <Accred index={4} left={197} top={313} text="Soirée du 27" upper={true} />
                )}
                {accreditationsIds.includes(2839) && (
                    <Accred index={5} left={197} top={354} text="(Dé)montage" upper={true} />
                )}
                {accreditationsIds.includes(3095) && (
                    <Accred index={6} left={197} top={393} text="Accompagnant" upper={true} />
                )}
            </Flex>

            <Flex
                height={A6_SIZES['96dpi'].height}
                width={A6_SIZES['96dpi'].width}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/clubSqy/verso_sans_trait.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$paris2024',
                    position: 'relative'
                }}
            >
                <Box
                    css={{
                        color: 'white',
                        fontWeight: '700',
                        left: '29px',
                        position: 'absolute',
                        top: '57px'
                    }}
                >
                    {leaderInfos}
                </Box>

                {accreditationsIds.includes(2789) && (
                    <Flex
                        align="center"
                        justify="center"
                        height={23}
                        width={23}
                        css={{
                            left: '28px',
                            position: 'absolute',
                            top: '452px',
                            '& svg': {
                                height: '90%'
                            }
                        }}
                    >
                        <XMark />
                    </Flex>
                )}
                {accreditationsIds.includes(2790) && (
                    <Flex
                        align="center"
                        justify="center"
                        height={23}
                        width={23}
                        css={{
                            left: '115px',
                            position: 'absolute',
                            top: '452px',
                            '& svg': {
                                height: '90%'
                            }
                        }}
                    >
                        <XMark />
                    </Flex>
                )}
                {accreditationsIds.includes(2791) && (
                    <Flex
                        align="center"
                        justify="center"
                        height={23}
                        width={23}
                        css={{
                            left: '261px',
                            position: 'absolute',
                            top: '452px',
                            '& svg': {
                                height: '90%'
                            }
                        }}
                    >
                        <XMark />
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};
