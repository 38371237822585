import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    AccreditationPositionProperty,
    PositionsPositionFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsAcronymCellProps {
    firstCellCss: any;
    position: PositionsPositionFragment;

    reload(): void;
}

export const PositionsAcronymCell = (props: IPositionsAcronymCellProps) => {
    const { translate } = useHeavent();

    return (
        <PositionsUpdateCell
            cellCss={props.firstCellCss}
            initialValue={props.position.acronym || ''}
            position={props.position}
            property={AccreditationPositionProperty.Acronym}
            reload={props.reload}
            renderInput={(value, setValue) => <TextInput label={translate('Acronym')} value={value} onChange={setValue} />}
        >
            {props.position.acronym}
        </PositionsUpdateCell>
    );
};
