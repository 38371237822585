import { CheckboxText, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { RadioText } from 'common-front/src/designSystem/components/radio';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId } from 'common/src/generated/types';
import { IUpdateFormParametersAccreditationsValues } from 'common/src/input/formParametersAccreditationsInput';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useFormParametersAccreditationsHiddenDisplayedQuery } from '../../../../../generated/graphqlHooks';

interface IFormParametersAccreditationsHiddenDisplayedSelectsProps {
    eventId: EventId;
    values: IUpdateFormParametersAccreditationsValues;

    change(name: string, value: any): void;
}

export const FormParametersAccreditationsHiddenDisplayedSelects = (
    props: IFormParametersAccreditationsHiddenDisplayedSelectsProps
) => {
    const translate = useTranslate();
    const { data, loader } = useFormParametersAccreditationsHiddenDisplayedQuery({
        eventId: props.eventId
    });
    const dateTimeService = useService(DateTimeService);
    const [hiddenDisplay, setHiddenDisplay] = React.useState<'hidden' | 'displayed'>(
        isNonEmptyArray(props.values.form.hiddenAccreditationsCategoriesIds) ||
            isNonEmptyArray(props.values.form.hiddenAccreditationsIds) ||
            isNonEmptyArray(props.values.form.hiddenAccreditationsSlotsIds)
            ? 'hidden'
            : 'displayed'
    );
    const [hiddenCategoriesState, setHiddenCategoriesState] = React.useState<ICheckboxState>(
        isNonEmptyArray(props.values.form.hiddenAccreditationsCategoriesIds)
            ? 'checked'
            : 'unchecked'
    );
    const [hiddenAccreditationsState, setHiddenAccreditationsState] =
        React.useState<ICheckboxState>(
            isNonEmptyArray(props.values.form.hiddenAccreditationsIds) ? 'checked' : 'unchecked'
        );
    const [hiddenSlotsState, setHiddenSlotsState] = React.useState<ICheckboxState>(
        isNonEmptyArray(props.values.form.hiddenAccreditationsSlotsIds) ? 'checked' : 'unchecked'
    );
    const [displayedCategoriesState, setDisplayedCategoriesState] = React.useState<ICheckboxState>(
        isNonEmptyArray(props.values.form.displayedAccreditationsCategoriesIds)
            ? 'checked'
            : 'unchecked'
    );
    const [displayedAccreditationsState, setDisplayedAccreditationsState] =
        React.useState<ICheckboxState>(
            isNonEmptyArray(props.values.form.displayedAccreditationsIds) ? 'checked' : 'unchecked'
        );
    const [displayedSlotsState, setDisplayedSlotsState] = React.useState<ICheckboxState>(
        isNonEmptyArray(props.values.form.displayedAccreditationsSlotsIds) ? 'checked' : 'unchecked'
    );
    const categories = React.useMemo(() => sortBy(data.event?.accreditationsCategories ?? [], (c) => c.name), [data.event]);
    const accreditations = React.useMemo(() => sortBy(data.event?.accreditations.nodes ?? [], (a) => a.name), [data.event]);
    const accreditationsSlots = React.useMemo(() => sortBy(
            (data.event?.accreditations.nodes ?? []).map((a) => {
                const slots = sortBy(
                    a.slots.map((s) => {
                        const slotName = isNonEmptyString(s.name) ? `${s.name} - ` : '';
                        const slotDate = s.date
                            ? dateTimeService.toLocaleString(
                                  s.date,
                                  LocaleFormats.DateOnly.MonthLong
                              )
                            : '';

                        return {
                            id: s.id,
                            name: `${slotName}${slotDate}`
                        };
                    }),
                    (s) => s.name
                );

                return {
                    ...a,
                    slots: isNonEmptyArray(slots)
                        ? slots
                        : [
                              {
                                  id: a.hiddenSlotId,
                                  name: a.name
                              }
                          ]
                };
            })
        ), [data.event]);

    return (
        <Flex
            direction="column"
            css={{
                background: '$gray50',
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs',
                padding: '$4 $6'
            }}
        >
            {loader || (
                <>
                    <Box font="gray800 textSm semiBold">{translate('pr_f_rences_d_a_19371')}</Box>

                    <Spacer height="3" />

                    <RadioText
                        state={hiddenDisplay === 'hidden' ? 'checked' : 'unchecked'}
                        onClick={(newState) => {
                            if (newState === 'checked') {
                                setHiddenDisplay('hidden');

                                props.change('form.displayedAccreditationsCategoriesIds', []);
                                props.change('form.displayedAccreditationsIds', []);
                                props.change('form.displayedAccreditationsSlotsIds', []);
                            }
                        }}
                    >
                        {translate('cacher_des_cat_43848')}
                    </RadioText>

                    <Spacer height="3" />

                    {hiddenDisplay === 'hidden' && (
                        <>
                            <Flex
                                direction="column"
                                css={{
                                    background: 'white',
                                    border: '1px solid $gray200',
                                    borderRadius: '$1',
                                    boxShadow: '$xs',
                                    padding: '$4 $6'
                                }}
                            >
                                <CheckboxText
                                    state={hiddenCategoriesState}
                                    onClick={(newState) => {
                                        setHiddenCategoriesState(newState);

                                        if (newState === 'unchecked') {
                                            props.change(
                                                'form.hiddenAccreditationsCategoriesIds',
                                                []
                                            );
                                        }
                                    }}
                                >
                                    {translate('cacher_certaine_12220')}
                                </CheckboxText>

                                {hiddenCategoriesState === 'checked' && (
                                    <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                        <Spacer height="2" />

                                        <RichSelect
                                            isSearchVisible={true}
                                            isSelectAllVisible={true}
                                            multiple={true}
                                            name="form.hiddenAccreditationsCategoriesIds"
                                        >
                                            {categories.map((c) => (
                                                    <option key={c.id} value={c.id}>
                                                        {c.name}
                                                    </option>
                                                ))}
                                        </RichSelect>
                                    </Flex>
                                )}

                                <Spacer height="3" />

                                <CheckboxText
                                    state={hiddenAccreditationsState}
                                    onClick={(newState) => {
                                        setHiddenAccreditationsState(newState);

                                        if (newState === 'unchecked') {
                                            props.change('form.hiddenAccreditationsIds', []);
                                        }
                                    }}
                                >
                                    {translate('cacher_certaine_08734')}
                                </CheckboxText>

                                {hiddenAccreditationsState === 'checked' && (
                                    <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                        <Spacer height="2" />

                                        <RichSelect
                                            isSearchVisible={true}
                                            isSelectAllVisible={true}
                                            multiple={true}
                                            name="form.hiddenAccreditationsIds"
                                        >
                                            {accreditations.map((p) => (
                                                    <option key={p.id} value={p.id}>
                                                        {p.name}
                                                    </option>
                                                ))}
                                        </RichSelect>
                                    </Flex>
                                )}

                                <Spacer height="3" />

                                <CheckboxText
                                    state={hiddenSlotsState}
                                    onClick={(newState) => {
                                        setHiddenSlotsState(newState);

                                        if (newState === 'unchecked') {
                                            props.change('form.hiddenAccreditationsSlotsIds', []);
                                        }
                                    }}
                                >
                                    {translate('cacher_certains_02997')}
                                </CheckboxText>

                                {hiddenSlotsState === 'checked' && (
                                    <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                        <Spacer height="2" />

                                        <RichSelect
                                            isSearchVisible={true}
                                            isSelectAllVisible={true}
                                            multiple={true}
                                            name="form.hiddenAccreditationsSlotsIds"
                                        >
                                            {accreditationsSlots.map((p) => (
                                                    <optgroup key={p.id} label={p.name}>
                                                        {p.slots.map((s) => (
                                                                <option key={s.id} value={s.id}>
                                                                    {s.name}
                                                                </option>
                                                            ))}
                                                    </optgroup>
                                                ))}
                                        </RichSelect>
                                    </Flex>
                                )}
                            </Flex>

                            <Spacer height="5" />
                        </>
                    )}

                    <RadioText
                        state={hiddenDisplay === 'displayed' ? 'checked' : 'unchecked'}
                        onClick={(newState) => {
                            setHiddenDisplay('displayed');

                            if (newState === 'checked') {
                                props.change('form.hiddenAccreditationsCategoriesIds', []);
                                props.change('form.hiddenAccreditationsIds', []);
                                props.change('form.hiddenAccreditationsSlotsIds', []);
                            }
                        }}
                    >
                        {translate('afficher_des_ca_37473')}
                    </RadioText>

                    <Spacer height="3" />

                    {hiddenDisplay === 'displayed' && (
                        <Flex
                            direction="column"
                            css={{
                                background: 'white',
                                border: '1px solid $gray200',
                                borderRadius: '$1',
                                boxShadow: '$xs',
                                padding: '$4 $6'
                            }}
                        >
                            <CheckboxText
                                state={displayedCategoriesState}
                                onClick={(newState) => {
                                    setDisplayedCategoriesState(newState);

                                    if (newState === 'unchecked') {
                                        props.change(
                                            'form.displayedAccreditationsCategoriesIds',
                                            []
                                        );
                                    }
                                }}
                            >
                                {translate('afficher_certai_88457')}
                            </CheckboxText>

                            {displayedCategoriesState === 'checked' && (
                                <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                    <Spacer height="2" />

                                    <RichSelect
                                        isSearchVisible={true}
                                        isSelectAllVisible={true}
                                        multiple={true}
                                        name="form.displayedAccreditationsCategoriesIds"
                                    >
                                        {categories.map((c) => (
                                                <option key={c.id} value={c.id}>
                                                    {c.name}
                                                </option>
                                            ))}
                                    </RichSelect>
                                </Flex>
                            )}

                            <Spacer height="3" />

                            <CheckboxText
                                state={displayedAccreditationsState}
                                onClick={(newState) => {
                                    setDisplayedAccreditationsState(newState);

                                    if (newState === 'unchecked') {
                                        props.change('form.displayedAccreditationsIds', []);
                                    }
                                }}
                            >
                                {translate('afficher_certai_29161')}
                            </CheckboxText>

                            {displayedAccreditationsState === 'checked' && (
                                <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                    <Spacer height="2" />

                                    <RichSelect
                                        isSearchVisible={true}
                                        isSelectAllVisible={true}
                                        multiple={true}
                                        name="form.displayedAccreditationsIds"
                                    >
                                        {accreditations.map((p) => (
                                                <option key={p.id} value={p.id}>
                                                    {p.name}
                                                </option>
                                            ))}
                                    </RichSelect>
                                </Flex>
                            )}

                            <Spacer height="3" />

                            <CheckboxText
                                state={displayedSlotsState}
                                onClick={(newState) => {
                                    setDisplayedSlotsState(newState);

                                    if (newState === 'unchecked') {
                                        props.change('form.displayedAccreditationsSlotsIds', []);
                                    }
                                }}
                            >
                                {translate('afficher_certai_38724')}
                            </CheckboxText>

                            {displayedSlotsState === 'checked' && (
                                <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                    <Spacer height="2" />

                                    <RichSelect
                                        isSearchVisible={true}
                                        isSelectAllVisible={true}
                                        multiple={true}
                                        name="form.displayedAccreditationsSlotsIds"
                                    >
                                        {accreditationsSlots.map((p) => (
                                                <optgroup key={p.id} label={p.name}>
                                                    {p.slots.map((s) => (
                                                            <option key={s.id} value={s.id}>
                                                                {s.name}
                                                            </option>
                                                        ))}
                                                </optgroup>
                                            ))}
                                    </RichSelect>
                                </Flex>
                            )}
                        </Flex>
                    )}
                </>
            )}
        </Flex>
    );
};
