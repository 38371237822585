import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { RichText } from '../../../designSystem/components/richEditor/richText';
import { Spacer } from '../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { useEnvVars } from '../../../util/dependencies/dependencies';
import { googleMapsHref } from '../../../util/googleMaps';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { hasAddressOrCoordinates } from '../../../vo/position';

interface IUltraMarinPositionProps {
    psuis: DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'];
    userInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['userInfo'];
}

export const UltraMarinPosition = (props: IUltraMarinPositionProps) => {
    const { HEAVENT_EXTERNAL_DOMAIN } = useEnvVars();
    const position = props.psuis[0].position;
    const category = props.psuis[0].positionCategory;
    const leader = category.leaders[0];
    const ranges = sortBy(
        props.psuis.map(({ positionSlot }) => positionSlot.range),
        (r) => r.start!.toMillis()
    );

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/ultraMarin/background_position.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$brito',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        >
            {leader && (
                <Flex
                    direction="column"
                    css={{
                        color: 'white',
                        fontSize: '18px',
                        fontWeight: '700',
                        paddingLeft: '12px',
                        position: 'absolute',
                        top: '586px',
                        width: '185px'
                    }}
                >
                    <Box textAlign="center">{leader.userInfo.firstName}</Box>
                    <Box textAlign="center">{leader.userInfo.lastName}</Box>
                    <Box textAlign="center">{leader.userInfo.phone?.internationalFormat || ''}</Box>
                </Flex>
            )}

            <Flex
                direction="column"
                width={1}
                css={{
                    position: 'absolute',
                    paddingLeft: '239px',
                    paddingRight: '52px',
                    top: '156px'
                }}
            >
                <Box
                    width={1}
                    textAlign="center"
                    css={{
                        color: '#303889',
                        fontSize: '16px',
                        fontWeight: '700'
                    }}
                >
                    {props.userInfo.name}
                </Box>

                <Spacer height="1" />

                <Box
                    width={1}
                    textAlign="center"
                    css={{
                        color: '#303889',
                        fontSize: '18px',
                        fontWeight: '700'
                    }}
                >
                    {position.name}
                </Box>

                <Spacer height="6" />

                <Flex direction="column" gap="6" width={1}>
                    <Flex direction="column" gap="2">
                        <Box
                            css={{
                                color: 'black',
                                fontSize: '16px',
                                fontWeight: '700'
                            }}
                        >
                            Description du poste :
                        </Box>

                        <Box css={{ color: '#333333', fontSize: '13px' }}>
                            <RichText
                                text={position.description}
                                css={{
                                    '& > p:first-child': { marginTop: 0 },
                                    '& > p:last-child': { marginBottom: 0 }
                                }}
                            />
                        </Box>
                    </Flex>

                    <Flex direction="column" gap="2">
                        <Box
                            css={{
                                color: 'black',
                                fontSize: '16px',
                                fontWeight: '700'
                            }}
                        >
                            Créneaux horaires :
                        </Box>

                        <Flex direction="column" gap="1">
                            {ranges.map((range, index) => (
                                    <Box key={index} css={{ color: '#333333', fontSize: '13px' }}>
                                        Du{' '}
                                        {range.start!.toFormat('dd MMMM HH:mm', { locale: 'fr' })}{' '}
                                        au {range.end!.toFormat('dd MMMM HH:mm', { locale: 'fr' })}
                                    </Box>
                                ))}
                        </Flex>
                    </Flex>

                    {isNonEmptyString(position.fields.cf31570) && (
                        <Flex direction="column" gap="2">
                            <Box
                                css={{
                                    color: 'black',
                                    fontSize: '16px',
                                    fontWeight: '700'
                                }}
                            >
                                Dotation / Matériel :
                            </Box>

                            <Box css={{ color: '#333333', fontSize: '13px' }}>
                                {position.fields.cf31570}
                            </Box>
                        </Flex>
                    )}

                    {hasAddressOrCoordinates(position) && (
                        <Flex direction="column" gap="2">
                            <Box
                                css={{
                                    color: 'black',
                                    fontSize: '16px',
                                    fontWeight: '700'
                                }}
                            >
                                Localisation de la mission :
                            </Box>

                            <Flex direction="column" gap="1">
                                <Box css={{ color: '#333333', fontSize: '13px' }}>
                                    {position.address}
                                </Box>

                                <a
                                    href={googleMapsHref(position.latitude!, position.longitude!)}
                                    style={{
                                        background: `url(https://api.${HEAVENT_EXTERNAL_DOMAIN}/image/event/${position.eventId}/position/${position.id}.png)`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        display: 'block',
                                        height: '180px',
                                        width: '100%'
                                    }}
                                />
                            </Flex>
                        </Flex>
                    )}

                    {isNonEmptyString(position.fields.cf31571) && (
                        <Flex direction="column" gap="2">
                            <Box
                                css={{
                                    color: 'black',
                                    fontSize: '16px',
                                    fontWeight: '700'
                                }}
                            >
                                Où se garer :
                            </Box>

                            <Box css={{ color: '#333333', fontSize: '13px' }}>
                                {position.fields.cf31571}
                            </Box>
                        </Flex>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
