import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import {
    AccreditationId,
    AccreditationsCategoryId,
    AccreditationsSlotId,
    EventAccreditationsFragment
} from 'common/src/generated/types';
import { ICreateUpdateDelegationValues } from 'common/src/input/delegationInput';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { fullName } from 'common/src/vo/accreditationSlot';
import * as React from 'react';

interface IAccreditationsSelectPanelAccreditationSlotProps {
    accreditationCategoryId: AccreditationsCategoryId;
    accreditationId: AccreditationId;
    accreditationName: string;
    accreditationSlotsIdsValues: AccreditationsSlotId[];
    slot: EventAccreditationsFragment['accreditationsCategories'][number]['accreditations'][number]['slots'][number];
    values: ICreateUpdateDelegationValues['delegation']['accreditations'];
    change(name: string, value: any): void;
}

export const AccreditationsSelectPanelAccreditationSlot = ({
    accreditationCategoryId,
    accreditationId,
    accreditationName,
    accreditationSlotsIdsValues,
    change,
    slot,
    values
}: IAccreditationsSelectPanelAccreditationSlotProps) => {
    const dateTimeService = useService(DateTimeService);

    const isSelected = React.useMemo(
        () => accreditationSlotsIdsValues.includes(slot.id),
        [accreditationSlotsIdsValues]
    );

    return (
        <CheckboxText
            onClick={() => {
                change(
                    'delegation.accreditations',
                    isSelected
                        ? values.filter((da) => da.accreditationSlotId != slot.id)
                        : [
                              ...values,
                              {
                                  accreditationCategoryId,
                                  accreditationId,
                                  accreditationSlotId: slot.id,
                                  isDefault: false,
                                  isVisible: true
                              }
                          ]
                );
            }}
            state={isSelected ? 'checked' : 'unchecked'}
        >
            {fullName(dateTimeService, slot, accreditationName)}
        </CheckboxText>
    );
};
