import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { CreatePositionTagsFragment, OrganizationId, TagId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { TagCreateModal } from '../../organizationParameters/tags/tagCreateModal';

interface ITagsFormProps {
    name: string;
    organizationId: OrganizationId;
    tags: CreatePositionTagsFragment['tags']['nodes'];
    tagsIds: TagId[];

    change(name: string, value: any): void;
}

export const TagsForm = (props: ITagsFormProps) => {
    const translate = useTranslate();
    const [tags, setTags] = React.useState(props.tags);
    const [isTagCreateOpen, setIsTagCreateOpen] = React.useState(false);

    return (
        <>
            <RichSelect
                isSearchVisible={true}
                isCreateVisible={true}
                multiple={true}
                label={<LabelOptional>{translate('tags_79499')}</LabelOptional>}
                name={`${props.name}tagsIds`}
                onCreateClick={() => {
                    setIsTagCreateOpen(true);
                }}
            >
                {tags.map((tag) => (
                        <option key={tag.id} value={tag.id}>
                            {tag.name}
                        </option>
                    ))}
            </RichSelect>

            {isTagCreateOpen && (
                <TagCreateModal
                    organizationId={props.organizationId}
                    onClose={() => {
                        setIsTagCreateOpen(false);
                    }}
                    onSuccess={(tag) => {
                        setTags([...tags, tag]);

                        props.change(`${props.name}.tagsIds`, [...props.tagsIds, tag.id]);
                    }}
                />
            )}
        </>
    );
};
