import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { A6_SIZES } from '../../../util/pdf';

export const BadgeJokersfrancesloveniaA6Back = () => (
        <Flex
            direction="column"
            height={A6_SIZES['96dpi'].height}
            width={A6_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/jokers/jokersfrancesloveniaback.png) no-repeat`,
                backgroundSize: 'cover',
                overflow: 'hidden',
                position: 'relative'
            }}
        />
    );
