import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { FileS3Input } from 'common-front/src/designSystem/form/file/fileS3Input';
import { Accept } from 'common-front/src/util/accept';
import { MAX_ARCHIVE_FILE_SIZE, MAX_ARCHIVE_SIZE } from 'common/src/input/archiveInput';
import { NumberService } from 'common/src/services/numberService';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const ArchiveFormBox = () => {
    const translate = useTranslate();

    return (
        <FormBox
            hideToggle={true}
            initialIsOpen={true}
            title={translate('votre_fichier_d_51776')}
            subtitle={translate(
                's_lectionnez_vo_27155',
                NumberService.bytesToGigabytes(MAX_ARCHIVE_SIZE),
                NumberService.bytesToMegabytes(MAX_ARCHIVE_FILE_SIZE)
            )}
        >
            <FileS3Input
                accept={Accept.Archives}
                acl="private"
                prefix="input.archive."
                withFileField={true}
            />
        </FormBox>
    );
};
