import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeSqyTraitCoupeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeSqyTraitCoupe = (props: IBadgeSqyTraitCoupeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const isMan = ui.sqySexe === 'Homme' || ui.sqyGender === 'Homme / Man';

    return (
        <>
            <Flex
                height={363}
                width={242}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/sqy/badge_trait_coupe_recto.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$parisine',
                    position: 'relative'
                }}
            >
                <Box
                    height={84}
                    width={84}
                    css={{
                        left: '79px',
                        position: 'absolute',
                        top: '68px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) ? (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    ) : isMan ? (
                        <img
                            src="https://assets.recrewteer.com/badges/sqy/homme.jpg"
                            height="100%"
                            width="100%"
                        />
                    ) : (
                        <img
                            src="https://assets.recrewteer.com/badges/sqy/femme.jpg"
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>

                <Box
                    width={1}
                    css={{
                        color: 'back',
                        fontSize: '13px',
                        fontWeight: '700',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '162px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    width={1}
                    css={{
                        color: 'back',
                        fontSize: '13px',
                        fontWeight: '700',
                        position: 'absolute',
                        textAlign: 'center',
                        top: '179px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    height={74}
                    width={74}
                    css={{
                        left: '84px',
                        position: 'absolute',
                        top: '204px'
                    }}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=74&width=74`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>
            </Flex>

            <Flex
                height={363}
                width={242}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/sqy/badge_trait_coupe_verso.jpg) no-repeat`,
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />
        </>
    );
};
