import * as React from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../../components/reactFinalForm/isInvalid';
import { Accept } from '../../../util/accept';
import { FileInput as FileInputComponent } from '../../components/file/fileInput';
import { BaseInputProps } from '../../components/input/baseInputProps';

interface IFileInputProps extends BaseInputProps {
    accept: Accept | Accept[];
    format: 'base64' | 'string';
    name: string;
}

export const FileInput = (props: IFileInputProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => {
                const invalid = isInvalid(fieldProps.meta);

                return (
                    <FileInputComponent
                        {...props}
                        hint={invalid ? fieldProps.meta.error.content : props.hint}
                        state={invalid ? 'error' : props.state}
                        value={fieldProps.input.value}
                        onChange={(value) => {
                            fieldProps.input.onChange(value);
                        }}
                    />
                );
            }}
        />
    );
