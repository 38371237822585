import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    Feature,
    OrganizationId,
    UsersInfoId,
    VolunteerRegistrationState
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { useEventUserProfileActionsQuery } from '../../../generated/graphqlHooks';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { EventUserProfileActionsAccreditation } from './eventUserProfileActionsAccreditation';
import { EventUserProfileActionsAssignment } from './eventUserProfileActionsAssignment';
import { EventUserProfileActionsDocuments } from './eventUserProfileActionsDocuments';
import { EventUserProfileActionsGeneral } from './eventUserProfileActionsGeneral';

interface IUserProfileActionsProps {
    eventId: EventId;
    isWeezeventConnected: boolean;
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;
}

export const EventUserProfileActions = (props: IUserProfileActionsProps) => {
    const { organization, hasFeature } = useOrganizationContext();
    const { isEventAdmin } = useEventContext();
    const { data, isLoading, loader, reload } = useEventUserProfileActionsQuery({
        eventId: props.eventId,
        userInfoId: props.userInfoId
    });
    const showAccreditation = isEventAdmin && hasFeature(Feature.Accreditation);
    const showDocuments =
        (!isLoading &&
            data.event.volunteerRegistration.state === VolunteerRegistrationState.Assigned) ||
        isNonEmptyArray(organization.customBadges);
    const showGeneral = isEventAdmin;

    return (
        loader || (
            <>
                <Spacer height="7" />

                <EventUserProfileActionsAssignment
                    eventId={props.eventId}
                    organizationId={props.organizationId}
                    state={data.event.volunteerRegistration.state}
                    userInfoId={props.userInfoId}
                    volunteerRegistrationId={data.event.volunteerRegistration.id}
                    reload={reload}
                />

                {showAccreditation && (
                    <>
                        <Spacer height="7" />

                        <EventUserProfileActionsAccreditation
                            accreditationState={data.event.volunteerRegistration.accreditationState}
                            eventId={props.eventId}
                            organizationId={props.organizationId}
                            userInfoId={props.userInfoId}
                            volunteerRegistrationId={data.event.volunteerRegistration.id}
                            reload={reload}
                        />
                    </>
                )}

                {showDocuments && (
                    <>
                        <Spacer height="7" />

                        <EventUserProfileActionsDocuments
                            eventId={props.eventId}
                            links={data.event.volunteerRegistration.userInfo.links!}
                            organizationId={props.organizationId}
                            state={data.event.volunteerRegistration.state}
                            userInfoId={props.userInfoId}
                        />
                    </>
                )}

                {showGeneral && (
                    <>
                        <Spacer height="7" />

                        <EventUserProfileActionsGeneral
                            eventId={props.eventId}
                            isWeezeventConnected={props.isWeezeventConnected}
                            nameOrEmail={data.event.volunteerRegistration.userInfo.nameOrEmail}
                            volunteerRegistrationId={data.event.volunteerRegistration.id}
                        />
                    </>
                )}

                <Spacer height="7" />
            </>
        )
    );
};
