import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Grid } from 'common/src/designSystem/components/grid';
import { I } from 'common/src/designSystem/components/i';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Button } from '../designSystem/components/button';
import { Content } from '../designSystem/components/tooltip/content';
import { Tooltip } from '../designSystem/components/tooltip/tooltip';
import { Trigger } from '../designSystem/components/tooltip/trigger';

interface IAccreditAssignGridProps {
    buttons: React.ReactNode;
    children: React.ReactNode;
    header: React.ReactNode;
    isEdit: boolean;
    isLeftPanelOpen: boolean;
    isRightPanelOpen: boolean;
    leftPanel: React.ReactNode;
    rightPanel: React.ReactNode;

    setIsLeftPanelOpen(isLeftPanelOpen: boolean): void;
    setIsRightPanelOpen(isRightPanelOpen: boolean): void;
}

export const AccreditAssignGrid = (props: IAccreditAssignGridProps) => {
    const translate = useTranslate();
    const leftPanelWidth = props.isEdit ? '0px' : props.isLeftPanelOpen ? '280px' : '68px';
    const rightPanelWidth = props.isRightPanelOpen ? '360px' : '68px';

    return (
        <Grid
            gridtemplatecolumns={`${leftPanelWidth} 1fr ${rightPanelWidth}`}
            gridtemplaterows="88px 1fr 72px"
            height={1}
            width={1}
            css={{ overflow: 'hidden' }}
        >
            {!props.isEdit && (
                <>
                    <Box
                        css={{
                            borderRight: '1px solid $gray200',
                            gridColumn: '1 / 2',
                            gridRow: '1 / 3'
                        }}
                    >
                        {props.isLeftPanelOpen && props.leftPanel}
                    </Box>

                    <Flex
                        align="center"
                        justify={props.isLeftPanelOpen ? 'end' : 'center'}
                        css={{
                            borderRight: '1px solid $gray200',
                            borderTop: '1px solid $gray200',
                            gridColumn: '1 / 2',
                            gridRow: '3 / 4',
                            padding: props.isLeftPanelOpen ? '$4' : '0'
                        }}
                    >
                        <Tooltip>
                            <Trigger>
                                <Box>
                                    <Button
                                        color="white"
                                        leftIcon="sidebar"
                                        onClick={() => {
                                            props.setIsLeftPanelOpen(!props.isLeftPanelOpen);
                                        }}
                                    />
                                </Box>
                            </Trigger>

                            <Content placement="top">
                                {props.isLeftPanelOpen
                                    ? translate('cacher_la_liste_66205')
                                    : translate('afficher_la_lis_14277')}
                            </Content>
                        </Tooltip>
                    </Flex>
                </>
            )}

            <Box
                css={{
                    gridColumn: '2 / 4',
                    gridRow: '1 / 2'
                }}
            >
                {props.header}
            </Box>

            <Box
                css={{
                    borderTop: '1px solid $gray200',
                    gridColumn: '2 / 3',
                    gridRow: '2 / 3',
                    overflowY: 'auto'
                }}
            >
                {props.children}
            </Box>

            <Box
                css={{
                    gridColumn: '2 / 3',
                    gridRow: '3 / 4'
                }}
            >
                {props.buttons}
            </Box>

            <Box
                css={{
                    borderLeft: '1px solid $gray200',
                    borderTop: '1px solid $gray200',
                    gridColumn: '3 / 4',
                    gridRow: '2 / 4',
                    overflowY: 'auto'
                }}
            >
                {props.isRightPanelOpen ? (
                    props.rightPanel
                ) : (
                    <Flex
                        justify="center"
                        height={1}
                        width={1}
                        css={{
                            paddingTop: '$6'
                        }}
                    >
                        <Tooltip>
                            <Trigger>
                                <Box
                                    color="gray800"
                                    fontSize="textLg"
                                    css={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        props.setIsRightPanelOpen(true);
                                    }}
                                >
                                    <I icon="sidebar" />
                                </Box>
                            </Trigger>

                            <Content placement="top">{translate('afficher_les_in_82197')}</Content>
                        </Tooltip>
                    </Flex>
                )}
            </Box>
        </Grid>
    );
};
