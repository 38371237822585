import { StyledIcon } from 'common-front/src/designSystem/components/iconSelect';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { Link } from 'common-front/src/util/link';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { useLocation } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IMenuItemProps {
    icon: IIcon;
    path: string;
    title: string;

    isActive?(pathname: string): boolean;
    onClick?(): Promise<any>;
}

export const MenuItem = (props: IMenuItemProps) => {
    const location = useLocation();
    const isActive = props.isActive
        ? props.isActive(location.pathname)
        : location.pathname === props.path;

    return (
        <Link
            to={props.path}
            onClick={async () => {
                if (props.onClick) {
                    await props.onClick();
                }
            }}
        >
            <Tooltip>
                <Trigger>
                    <StyledIcon active={isActive}>
                        <I icon={props.icon} />
                    </StyledIcon>
                </Trigger>

                <Content placement="right">{props.title}</Content>
            </Tooltip>
        </Link>
    );
};
