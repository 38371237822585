import { AccreditationCategories } from 'common-front/src/components/accreditations/accreditationCategories';
import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { RightPanelHeader } from 'common-front/src/designSystem/components/rightPanel/rightPanelHeader';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import {
    useVolunteerRegistrationAccreditMutation,
    useVolunteerRegistrationDeaccreditMutation
} from 'common-front/src/generated/graphqlHooks';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useReferrerContext } from 'common-front/src/util/referrerContext';
import { Avatar } from 'common/src/components/avatar/avatar';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    AccreditationsSlotId,
    EventId,
    FieldType,
    OrganizationId,
    SegmentCustomFieldFragment,
    SegmentId,
    VolunteerRegistrationFragment,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { DurationService } from 'common/src/services/durationService';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { LocaleFormats } from 'common/src/util/luxon';
import { FieldService } from 'common/src/vo/field';
import { getFormsInsertedAt } from 'common/src/vo/formUserInfo';
import { PossibleColumn } from 'common/src/vo/segment';
import { VolunteerDefaultColumns } from 'common/src/vo/segments/volunteersSegmentsService';
import { uniq } from 'lodash-es';
import * as React from 'react';
import { useEventContext } from '../../../events/show/eventContext';
import { VolunteerRegistrationAccreditations } from './volunteerRegistrationAccreditations';
import { VolunteerRegistrationAccreditationStateCell } from './volunteerRegistrationAccreditationStateCell';
import { VolunteerRegistrationCheckboxCell } from './volunteerRegistrationCheckboxCell';
import { VolunteerRegistrationDateCell } from './volunteerRegistrationDateCell';
import { VolunteerRegistrationDelegation } from './volunteerRegistrationDelegation';
import { VolunteerRegistrationForms } from './volunteerRegistrationForms';
import { VolunteerRegistrationNumberCell } from './volunteerRegistrationNumberCell';
import { VolunteerRegistrationPhoneCell } from './volunteerRegistrationPhoneCell';
import { VolunteerRegistrationPositions } from './volunteerRegistrationPositions';
import { VolunteerRegistrationSelectCell } from './volunteerRegistrationSelectCell';
import { VolunteerRegistrationStateCell } from './volunteerRegistrationStateCell';
import { VolunteerRegistrationTextareaCell } from './volunteerRegistrationTextareaCell';
import { VolunteerRegistrationTextCell } from './volunteerRegistrationTextCell';
import { VolunteerRegistrationWishedAccreditations } from './volunteerRegistrationWishedAccreditations';
import { VolunteerRegistrationWishedPositions } from './volunteerRegistrationWishedPositions';
import { VolunteerRowDropdown } from './volunteerRowDropdown';

interface IVolunteerRowProps {
    columns: PossibleColumn[];
    customFields: SegmentCustomFieldFragment[];
    eventId: EventId;
    organizationId: OrganizationId;
    segmentId: SegmentId;
    state: ICheckboxState;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
    reloadVolunteer(id: VolunteersRegistrationId): void;
    toggleRow(key: string, newState: ICheckboxState): void;
}

export const VolunteerRow = ({
    columns,
    customFields,
    eventId,
    organizationId,
    reload,
    reloadVolunteer,
    segmentId,
    state: checkBoxState,
    toggleRow,
    volunteerRegistration
}: IVolunteerRowProps) => {
    const { history, translate } = useHeavent();

    const { mutate: accreditMember } = useVolunteerRegistrationAccreditMutation();
    const { mutate: deaccreditMember } = useVolunteerRegistrationDeaccreditMutation();

    const { isEventAdmin } = useEventContext();
    const { setReferrerPath } = useReferrerContext();

    const dateTimeService = useService(DateTimeService);
    const durationService = useService(DurationService);
    const fieldService = useService(FieldService);
    const intervalService = useService(IntervalService);

    const slugToCustomField = React.useMemo(() => new Map(customFields.map((cf) => [cf.slug, cf])), [customFields]);
    const sortedAvailability = React.useMemo(() => (volunteerRegistration.userInfo.formsUsersInfos || [])
            .flatMap((fui) => (fui.slots || []).map((s) =>
                    intervalService.toDisplayString(s.range, {
                        formats: {
                            localeFormat: LocaleFormats.DateOnly.Numeric
                        }
                    })
                ))
            .join('; '), [volunteerRegistration.userInfo.formsUsersInfos]);
    const formsInsertedAt = React.useMemo(() => getFormsInsertedAt(
            dateTimeService,
            volunteerRegistration.userInfo.formsUsersInfos || []
        ), [volunteerRegistration]);
    const auiSlotsIds = React.useMemo(
        () =>
            (volunteerRegistration.accreditationsUsersInfos || []).map(
                (aui) => aui.accreditationSlot.id
            ),
        [volunteerRegistration.accreditationsUsersInfos]
    );

    const reloadVolunteerCallback = React.useCallback(() => {
        reloadVolunteer(volunteerRegistration.id);
    }, [volunteerRegistration, reloadVolunteer]);

    const [isAccreditationsSidebarOpen, setIsAccreditationsSidebarOpen] = React.useState(false);
    const [isWishedAccreditationsSidebarOpen, setIsWishedAccreditationsSidebarOpen] =
        React.useState(false);
    const [volunteerRegistrationId, setVolunteerRegistrationId] = React.useState<
        VolunteersRegistrationId | undefined
    >(undefined);

    const userInfo = volunteerRegistration.userInfo;
    const state = volunteerRegistration.state;
    const accreditationState = volunteerRegistration.accreditationState;

    return (
        <>
            <Row
                css={{
                    background: checkBoxState === 'checked' ? '$primary100' : 'white',
                    cursor: 'pointer',
                    '&:hover': {
                        background: checkBoxState === 'checked' ? '$primary100' : '$gray100'
                    }
                }}
                onClick={() => {
                    history.push(
                        HeaventPaths.VOLUNTEERS_LIST_USER_INFORMATIONS(
                            organizationId,
                            eventId,
                            segmentId,
                            volunteerRegistration.userInfo.id
                        )
                    );
                }}
            >
                {isEventAdmin && (
                    <Cell width={48} css={{ paddingRight: 0 }}>
                        <Checkbox
                            state={checkBoxState}
                            onClick={(newState, e) => {
                                e.nativeEvent.stopImmediatePropagation();
                                e.stopPropagation();

                                toggleRow(`ui${userInfo.id}`, newState);
                            }}
                        />
                    </Cell>
                )}

                {columns.map((field, index) => {
                    const firstCellCss = index === 0 && isEventAdmin ? { paddingLeft: '$3' } : {};
                    const customField = slugToCustomField.get(field.slug);

                    switch (field.slug) {
                        case VolunteerDefaultColumns.Accreditations:
                            return (
                                <VolunteerRegistrationAccreditations
                                    key={field.slug}
                                    eventId={eventId}
                                    firstCellCss={firstCellCss}
                                    setIsSidebarOpen={setIsAccreditationsSidebarOpen}
                                    setVolunteerRegistrationId={setVolunteerRegistrationId}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            );
                        case VolunteerDefaultColumns.AccreditationState:
                            return (
                                <VolunteerRegistrationAccreditationStateCell
                                    key={field.slug}
                                    eventId={eventId}
                                    field={field}
                                    firstCellCss={firstCellCss}
                                    state={accreditationState}
                                    volunteerRegistrationId={volunteerRegistration.id}
                                    reload={reloadVolunteerCallback}
                                />
                            );
                        case VolunteerDefaultColumns.Availability:
                            return (
                                <Cell key={field.slug} css={firstCellCss}>
                                    {sortedAvailability}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.Comments:
                            return (
                                <Cell key={field.slug} css={firstCellCss}>
                                    {isNonEmptyArray(userInfo.comments)
                                        ? userInfo.comments.flatMap((c) => c.content).join('; ')
                                        : ''}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.CompletedForms:
                            return (
                                <VolunteerRegistrationForms
                                    key={field.slug}
                                    firstCellCss={firstCellCss}
                                    volunteerRegistration={volunteerRegistration}
                                />
                            );
                        case VolunteerDefaultColumns.FormsInsertedAt:
                            return (
                                <Cell key={field.slug} css={firstCellCss}>
                                    <Box title={formsInsertedAt} css={{ ellipsis: true }}>
                                        {formsInsertedAt}
                                    </Box>
                                </Cell>
                            );
                        case VolunteerDefaultColumns.Delegations:
                            return (
                                <VolunteerRegistrationDelegation
                                    key={field.slug}
                                    eventId={eventId}
                                    firstCellCss={firstCellCss}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            );
                        case VolunteerDefaultColumns.Email:
                            return <Cell key={field.slug}>{userInfo.email}</Cell>;
                        case VolunteerDefaultColumns.Id:
                            return (
                                <Cell key={field.slug} width={100} css={firstCellCss}>
                                    {userInfo.id}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.InsertedAt:
                            return (
                                <Cell key={field.slug} width={230} css={firstCellCss}>
                                    {dateTimeService.toLocaleString(
                                        volunteerRegistration.insertedAt.toLocal(),
                                        LocaleFormats.DateTime
                                    )}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.Name:
                            return (
                                <Cell key={field.slug} css={firstCellCss}>
                                    <AvatarNameEmail userInfo={userInfo} hasName={true} />
                                </Cell>
                            );
                        case VolunteerDefaultColumns.NumberOfAccreditations:
                            return (
                                <Cell key={field.slug} width={230} css={firstCellCss}>
                                    {volunteerRegistration.numberOfAccreditations}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.NumberOfAssignments:
                            return (
                                <Cell key={field.slug} width={230} css={firstCellCss}>
                                    {volunteerRegistration.numberOfAssignments}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.NumberOfAssignmentsDays:
                            return (
                                <Cell key={field.slug} width={230} css={firstCellCss}>
                                    {volunteerRegistration.numberOfAssignmentsDays}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.NumberOfAvailableDays:
                            return (
                                <Cell key={field.slug} width={230} css={firstCellCss}>
                                    {volunteerRegistration.numberOfAvailableDays}
                                </Cell>
                            );
                        case 'picture':
                            return (
                                <Cell key={field.slug} width={80} css={firstCellCss}>
                                    <Avatar
                                        size={32}
                                        image={userInfo.picture?.url}
                                        email={userInfo.email}
                                        name={userInfo.name}
                                    />
                                </Cell>
                            );
                        case VolunteerDefaultColumns.Positions:
                            return (
                                <VolunteerRegistrationPositions
                                    key={field.slug}
                                    eventId={eventId}
                                    firstCellCss={firstCellCss}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            );
                        case VolunteerDefaultColumns.PositionsCategories:
                            return (
                                <Cell key={field.slug} css={firstCellCss}>
                                    {uniq(
                                        volunteerRegistration?.positionsSlotsUsersInfos?.map(
                                            (psui) => psui.positionCategory.name
                                        )
                                    ).join(', ')}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.TeamCode:
                            return (
                                <Cell
                                    key={field.slug}
                                    css={firstCellCss}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                    }}
                                >
                                    {volunteerRegistration.teamCode}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.VolunteerMinutes:
                            return (
                                <Cell key={field.slug} width={230} css={firstCellCss}>
                                    {durationService.minutesToHoursMinutesString(
                                        volunteerRegistration.volunteerMinutes
                                    )}
                                </Cell>
                            );
                        case VolunteerDefaultColumns.VolunteerRegistrationState:
                            return (
                                <VolunteerRegistrationStateCell
                                    key={field.slug}
                                    eventId={eventId}
                                    field={field}
                                    firstCellCss={firstCellCss}
                                    state={state}
                                    volunteerRegistrationId={volunteerRegistration.id}
                                    reload={reloadVolunteerCallback}
                                />
                            );
                        case VolunteerDefaultColumns.WishedAccreditationSlots:
                            return (
                                <VolunteerRegistrationWishedAccreditations
                                    key={field.slug}
                                    firstCellCss={firstCellCss}
                                    setIsSidebarOpen={setIsWishedAccreditationsSidebarOpen}
                                    setVolunteerRegistrationId={setVolunteerRegistrationId}
                                    volunteerRegistration={volunteerRegistration}
                                />
                            );
                        case VolunteerDefaultColumns.WishedPositions:
                            return (
                                <VolunteerRegistrationWishedPositions
                                    key={field.slug}
                                    firstCellCss={firstCellCss}
                                    volunteerRegistration={volunteerRegistration}
                                />
                            );
                        case VolunteerDefaultColumns.WeezeventBarcodeId:
                            return (
                                <Cell key={field.slug} css={firstCellCss}>
                                    {volunteerRegistration.weezeventParticipantInfo?.idBarcode ??
                                        ''}
                                </Cell>
                            );
                        default:
                            if (
                                Object.values<string>(VolunteerDefaultColumns).includes(field.slug)
                            ) {
                                console.error(`003: Unhandled default column ${field.slug}`);
                                return <Cell>ERR</Cell>;
                            }

                            return customField?.fieldType === FieldType.Checkbox ? (
                                <VolunteerRegistrationCheckboxCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    firstCellCss={firstCellCss}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : customField?.fieldType === FieldType.Date ? (
                                <VolunteerRegistrationDateCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    firstCellCss={firstCellCss}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : customField?.fieldType === FieldType.Phone ? (
                                <VolunteerRegistrationPhoneCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    firstCellCss={firstCellCss}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : customField?.fieldType === FieldType.Select ||
                              customField?.fieldType === FieldType.Country ||
                              customField?.fieldType === FieldType.Language ||
                              customField?.fieldType === FieldType.Sex ||
                              customField?.fieldType === FieldType.Nationality ? (
                                <VolunteerRegistrationSelectCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    firstCellCss={firstCellCss}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : customField?.fieldType === FieldType.Text ? (
                                <VolunteerRegistrationTextCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    firstCellCss={firstCellCss}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : customField?.fieldType === FieldType.Textarea ? (
                                <VolunteerRegistrationTextareaCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    firstCellCss={firstCellCss}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : customField?.fieldType === FieldType.Number ? (
                                <VolunteerRegistrationNumberCell
                                    key={field.slug}
                                    customField={customField}
                                    eventId={eventId}
                                    field={field}
                                    firstCellCss={firstCellCss}
                                    volunteerRegistration={volunteerRegistration}
                                    reload={reloadVolunteerCallback}
                                />
                            ) : (
                                <Cell key={field.slug} css={firstCellCss}>
                                    {customField
                                        ? fieldService.getValueString(customField, userInfo, {
                                              fileReturnValue: 'name'
                                          })
                                        : ''}
                                </Cell>
                            );
                    }
                })}

                {isEventAdmin && (
                    <CellControls justify="center">
                        <Tooltip>
                            <Trigger>
                                <Box>
                                    <I
                                        icon="pen"
                                        onClick={() => {
                                            setReferrerPath();
                                            history.push(
                                                HeaventPaths.EDIT_USER(
                                                    organizationId,
                                                    eventId,
                                                    volunteerRegistration.userInfo.id
                                                )
                                            );
                                        }}
                                    />
                                </Box>
                            </Trigger>

                            <Content placement="top">{translate('_diter_62574')}</Content>
                        </Tooltip>

                        <VolunteerRowDropdown
                            eventId={eventId}
                            organizationId={organizationId}
                            reload={reload}
                            volunteerRegistration={volunteerRegistration}
                        />
                    </CellControls>
                )}
            </Row>

            {isAccreditationsSidebarOpen && (
                <RightPanel size="md" onClose={() => setIsAccreditationsSidebarOpen(false)}>
                    <RightPanelHeader>{translate('accr_ditations_39450')}</RightPanelHeader>

                    <RightPanelBody>
                        <Spacer height="9" />
                        <AccreditationCategories
                            accreditationsSlots={volunteerRegistration.accreditationsUsersInfos.map(
                                (aui) => aui.accreditationSlot
                            )}
                            actions={(accreditationSlotId: AccreditationsSlotId) => (
                                <DropdownEllipsis>
                                    <ItemIcon
                                        icon="minus"
                                        color={'red'}
                                        onClick={async () => {
                                            await deaccreditMember({
                                                eventId,
                                                volunteerRegistrationId: volunteerRegistrationId!,
                                                deaccredit: {
                                                    accreditationsSlotsIds: [accreditationSlotId]
                                                }
                                            });

                                            reload();
                                        }}
                                    >
                                        {translate('deaccredit_memb_96566')}
                                    </ItemIcon>
                                </DropdownEllipsis>
                            )}
                        />
                    </RightPanelBody>
                </RightPanel>
            )}

            {isWishedAccreditationsSidebarOpen && (
                <RightPanel size="md" onClose={() => setIsWishedAccreditationsSidebarOpen(false)}>
                    <RightPanelHeader>{translate('desired_accreditations')}</RightPanelHeader>

                    <RightPanelBody>
                        <Spacer height="9" />
                        <AccreditationCategories
                            accreditationsSlots={volunteerRegistration.accreditationsSlots}
                            actions={(accreditationSlotId: AccreditationsSlotId) =>
                                auiSlotsIds.includes(accreditationSlotId) ? undefined : (
                                    <DropdownEllipsis>
                                        <ItemIcon
                                            icon="plus"
                                            onClick={async () => {
                                                await accreditMember({
                                                    eventId,
                                                    volunteerRegistrationId:
                                                        volunteerRegistrationId!,
                                                    accredit: {
                                                        accreditationsSlotsIds: [
                                                            accreditationSlotId
                                                        ],
                                                        isAdditive: true
                                                    }
                                                });

                                                reload();
                                            }}
                                        >
                                            {translate('accredit_member_73476')}
                                        </ItemIcon>
                                    </DropdownEllipsis>
                                )
                            }
                        />
                    </RightPanelBody>
                </RightPanel>
            )}
        </>
    );
};
