import { Empty } from 'common-front/src/components/empty/empty';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Feature } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { OrganizationsPaths } from 'common/src/util/paths/organizationsPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useOrganizationContext } from '../organization/organizationContext';
import { Activities } from './activities/activities';
import { OrganizationCategories } from './categories/organizationCategories';
import { OrganizationDelegationsSpace } from './delegationsSpace/organizationDelegationsSpace';
import { OrganizationEmails } from './emails/organizationEmails';
import { OrganizationInfos } from './infos/organizationInfos';
import { Invoices } from './invoices/invoices';
import { OrganizationMembersSpace } from './membersSpace/organizationMembersSpace';
import { OrganizationParametersMenuItem } from './organizationParametersMenuItem';
import { OrganizationParametersMenuSection } from './organizationParametersMenuSection';
import { Profile } from './profile/profile';
import { Skidata } from './skidata/skidata';
import { Tags } from './tags/tags';
import { Team } from './team/team';
import { OrganizationTranslationsLoader } from './translations/organizationTranslationsLoader';

export const OrganizationParameters = () => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const {
        organization: {
            permissions: { isOrganizationAdmin }
        },
        hasFeature
    } = useOrganizationContext();

    return (
        <Flex height={1} width={1}>
            <Flex
                direction="column"
                height={1}
                width={240}
                css={{ background: '$gray100', overflowY: 'auto', py: '$2' }}
            >
                <OrganizationParametersMenuSection
                    name="general"
                    title={translate('g_n_ral_64953')}
                >
                    <OrganizationParametersMenuItem
                        icon="user"
                        path={HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(organizationId)}
                        title={translate('mon_profil_48224')}
                    />

                    {isOrganizationAdmin && (
                        <>
                            <OrganizationParametersMenuItem
                                icon="building"
                                path={HeaventPaths.ORGANIZATION_PARAMETERS_INFOS(organizationId)}
                                title={translate('organisation_71863')}
                            />

                            <OrganizationParametersMenuItem
                                icon="wave-pulse"
                                path={HeaventPaths.ORGANIZATION_PARAMETERS_ACTIVITY(organizationId)}
                                title={translate('activit_95747')}
                            />
                        </>
                    )}
                </OrganizationParametersMenuSection>

                {isOrganizationAdmin && (
                    <>
                        <OrganizationParametersMenuSection
                            name="parameters"
                            title={translate('param_tres_78420')}
                        >
                            <OrganizationParametersMenuItem
                                icon="address-card"
                                path={OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE_DESIGN(
                                    organizationId
                                )}
                                title={translate('espace_membres_90896')}
                                isActive={(pathname) =>
                                    pathname.includes(
                                        OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE(
                                            organizationId
                                        )
                                    )
                                }
                            />

                            {hasFeature(Feature.Delegation) && (
                                <OrganizationParametersMenuItem
                                    icon="users"
                                    path={OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE_DESIGN(
                                        organizationId
                                    )}
                                    title={translate('espace_d_l_gati_59251')}
                                    isActive={(pathname) =>
                                        pathname.includes(
                                            OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE(
                                                organizationId
                                            )
                                        )
                                    }
                                />
                            )}

                            <OrganizationParametersMenuItem
                                icon="at"
                                path={HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                                    organizationId
                                )}
                                title={translate('e_mails_09850')}
                                isActive={(pathname) => pathname.includes(
                                        HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS(organizationId)
                                    )}
                            />

                            <OrganizationParametersMenuItem
                                icon="layer-group"
                                path={HeaventPaths.ORGANIZATION_PARAMETERS_CATEGORIES_CUSTOM_FIELDS(
                                    organizationId
                                )}
                                title={translate('cat_gories_24533')}
                                isActive={(pathname) => pathname.includes(
                                        HeaventPaths.ORGANIZATION_PARAMETERS_CATEGORIES(
                                            organizationId
                                        )
                                    )}
                            />

                            <OrganizationParametersMenuItem
                                icon="tag"
                                path={HeaventPaths.ORGANIZATION_PARAMETERS_TAGS(organizationId)}
                                title={translate('tags_79499')}
                            />

                            <OrganizationParametersMenuItem
                                icon="language"
                                path={HeaventPaths.ORGANIZATION_PARAMETERS_TRANSLATIONS(
                                    organizationId
                                )}
                                title={translate('traductions_88349')}
                            />
                        </OrganizationParametersMenuSection>

                        <OrganizationParametersMenuSection
                            name="users"
                            title={translate('utilisateurs_04975')}
                        >
                            <OrganizationParametersMenuItem
                                icon="user-group"
                                path={HeaventPaths.ORGANIZATION_PARAMETERS_TEAM(organizationId)}
                                title={translate('utilisateurs_04975')}
                            />
                        </OrganizationParametersMenuSection>

                        <OrganizationParametersMenuSection
                            name="subscription"
                            title={translate('abonnement_98940')}
                        >
                            <OrganizationParametersMenuItem
                                icon="receipt"
                                path={HeaventPaths.ORGANIZATION_PARAMETERS_INVOICES(organizationId)}
                                title={translate('factures_03552')}
                            />
                        </OrganizationParametersMenuSection>

                        {hasFeature(Feature.Skidata) && (
                            <OrganizationParametersMenuSection
                                name="integrations"
                                title={translate('int_grations_88619')}
                            >
                                <OrganizationParametersMenuItem
                                    icon="person-skiing"
                                    path={HeaventPaths.ORGANIZATION_PARAMETERS_SKIDATA(
                                        organizationId
                                    )}
                                    title="Skidata"
                                />
                            </OrganizationParametersMenuSection>
                        )}
                    </>
                )}
            </Flex>

            <Box height={1} css={{ flex: '1' }}>
                <Switch>
                    <Route
                        exact
                        path={HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(':organizationId')}
                        children={<Profile />}
                    />

                    {isOrganizationAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_INFOS(':organizationId')}
                            children={<OrganizationInfos />}
                        />
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_TEAM(':organizationId')}
                            children={<Team />}
                        />
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS(':organizationId')}
                            children={<OrganizationEmails />}
                        />
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            path={OrganizationsPaths.ORGANIZATION_SETTINGS_MEMBERS_SPACE(
                                ':organizationId'
                            )}
                            children={<OrganizationMembersSpace />}
                        />
                    )}

                    {isOrganizationAdmin && hasFeature(Feature.Delegation) && (
                        <Route
                            path={OrganizationsPaths.ORGANIZATION_SETTINGS_DELEGATION_SPACE(
                                ':organizationId'
                            )}
                            children={<OrganizationDelegationsSpace />}
                        />
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_CATEGORIES(
                                ':organizationId'
                            )}
                            children={<OrganizationCategories />}
                        />
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_TAGS(':organizationId')}
                            children={<Tags />}
                        />
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_TRANSLATIONS(
                                ':organizationId'
                            )}
                            children={<OrganizationTranslationsLoader />}
                        />
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_INVOICES(':organizationId')}
                            children={<Invoices />}
                        />
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_ACTIVITY(':organizationId')}
                            children={<Activities />}
                        />
                    )}

                    {isOrganizationAdmin && (
                        <Route
                            exact
                            path={HeaventPaths.ORGANIZATION_PARAMETERS_SKIDATA(':organizationId')}
                            children={<Skidata />}
                        />
                    )}

                    <Route
                        children={
                            <Empty
                                path={HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(organizationId)}
                            />
                        }
                    />
                </Switch>
            </Box>
        </Flex>
    );
};
