import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import {
    useOrganizationDelegationSpaceSettingsQuery,
    useOrganizationDelegationSpaceSettingsUpdateMutation
} from '../../generated/graphqlHooks';

export const OrganizationDelegationSpaceSettings = () => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const { data, loader } = useOrganizationDelegationSpaceSettingsQuery({
        organizationId
    });
    const { mutate } = useOrganizationDelegationSpaceSettingsUpdateMutation();

    return (
        loader || (
            <Form
                direction="column"
                initialValues={{
                    allowDelegationSpaceDownload: data.organization.allowDelegationSpaceDownload
                }}
                onSubmit={async (values) => {
                    await mutate({
                        organizationId,
                        delegationSpaceSettings: values
                    });
                }}
                render={({ handleSubmit, submitting }) => (
                    <>
                        <Spacer height="7" />

                        <Box color="gray900" fontSize="textLg" fontWeight="medium">
                            {translate('team_area_setti_29458')}
                        </Box>

                        <Box color="gray500" fontWeight="medium">
                            {translate('customize_the_s_92329')}
                        </Box>

                        <HorizontalSpacerSeparator height="5" />

                        <Flex gap="7">
                            <Box color="gray700" fontWeight="medium" width={300}>
                                {translate('allow_documents_96106')}
                            </Box>

                            <ToggleText name="allowDelegationSpaceDownload">
                                {translate('allow_delegatio_08622')}
                            </ToggleText>
                        </Flex>

                        <HorizontalSpacerSeparator height="5" />

                        <Flex>
                            <Button isLoading={submitting} onClick={handleSubmit}>
                                {translate('enregistrer_06519')}
                            </Button>
                        </Flex>
                    </>
                )}
            />
        )
    );
};
