import { PhoneInput } from 'common-front/src/designSystem/components/phoneInput';
import {
    EventId,
    PhoneInput as PhoneInputVO,
    SegmentCustomFieldFragment,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { PhoneInputService } from 'common/src/input/phoneInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { VolunteerRegistrationUpdateCell } from './volunteerRegistrationUpdateCell';

interface IVolunteerRegistrationPhoneCellProps {
    customField: SegmentCustomFieldFragment;
    eventId: EventId;
    field: PossibleColumn;
    firstCellCss: any;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
}

export const VolunteerRegistrationPhoneCell = (props: IVolunteerRegistrationPhoneCellProps) => {
    const fieldService = useService(FieldService);
    const phoneInput = useService(PhoneInputService);
    const userInfo = props.volunteerRegistration.userInfo;
    const valueString = fieldService.getValueString(props.customField, userInfo);
    const initialValue = React.useMemo(() => {
        const value = fieldService.getValue<PhoneInputVO>(props.customField, userInfo);
        const defaultValues = phoneInput.basePhoneInputDefault();

        return {
            country: value?.country || defaultValues.country,
            code: value?.code || defaultValues.code,
            number: value?.number || defaultValues.number
        };
    }, []);

    return (
        <VolunteerRegistrationUpdateCell
            customField={props.customField}
            eventId={props.eventId}
            field={props.field}
            firstCellCss={props.firstCellCss}
            initialValue={initialValue}
            valueString={valueString}
            volunteerRegistration={props.volunteerRegistration}
            reload={props.reload}
        >
            {(value, setValue) => (
                    <PhoneInput
                        label={props.customField.name}
                        renderOnPortal={true}
                        value={value}
                        onChange={setValue}
                    />
                )}
        </VolunteerRegistrationUpdateCell>
    );
};
