import { groupBy, unionBy } from 'lodash-es';
import * as React from 'react';
import { Avatar } from '../../../../components/avatar/avatar';
import { Box } from '../../../../designSystem/components/box';
import { Flex } from '../../../../designSystem/components/flex';
import { Spacer } from '../../../../designSystem/components/spacer';
import { DocumentUserCustomBadgeFragment } from '../../../../generated/types';

interface IUltraMarinMedicalBackProps {
    event: DocumentUserCustomBadgeFragment;
}

export const UltraMarinMedicalBack = (props: IUltraMarinMedicalBackProps) => {
    const userInfo = props.event.volunteerRegistration.userInfo;
    const assignments = Object.entries(
        groupBy(
            props.event.volunteerRegistration.positionsSlotsUsersInfos,
            (psui) => psui.positionCategory.id
        )
    );
    const accreditations = props.event.volunteerRegistration.accreditationsUsersInfos
        .map(({ accreditation }) => accreditation.acronym)
        .join(', ');

    return (
        <Flex
            direction="column"
            align="center"
            height={396}
            width={280}
            css={{
                background: `url(https://assets.recrewteer.com/badges/ultraMarin/ultraMarinMedicalVerso.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$brito',
                overflow: 'hidden',
                paddingBottom: '60px',
                paddingTop: '44px'
            }}
        >
            <Avatar
                size={64}
                name={userInfo.name}
                email={userInfo.email}
                image={userInfo.picture?.url}
                border="1px solid #d34a44"
            />

            <Spacer height="3" />

            <Box
                width={1}
                color="white"
                textAlign="center"
                css={{ fontSize: '10px', fontWeight: '700' }}
            >
                {userInfo.name}
            </Box>

            <Spacer height="3" />

            <Flex direction="column" align="center" css={{ flex: '1' }}>
                {assignments.map(([_, psuis], index) => {
                    const leaders = unionBy(
                        psuis[0].position.leaders.concat(
                            psuis.flatMap(({ positionCategory }) => positionCategory.leaders)
                        ),
                        (l) => l.userInfo.id
                    );

                    return (
                        <React.Fragment key={index}>
                            {index !== 0 && (
                                <>
                                    <Spacer height="2" />
                                    <Box
                                        css={{
                                            background: '#d34a44',
                                            borderRadius: '1px',
                                            height: '1px',
                                            width: '80px'
                                        }}
                                    />
                                    <Spacer height="2" />
                                </>
                            )}

                            <Box
                                css={{
                                    color: 'white',
                                    fontSize: '11px',
                                    fontWeight: '400',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {psuis[0].positionCategory.name}
                            </Box>

                            <Spacer height="2" />

                            <Box
                                css={{
                                    color: 'white',
                                    fontSize: '10px',
                                    fontWeight: '300'
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: leaders
                                        .map(
                                            (leader) =>
                                                `${leader.userInfo.firstName} : ${leader.userInfo.phone?.internationalFormat}`
                                        )
                                        .join('<br />')
                                }}
                            />
                        </React.Fragment>
                    );
                })}
            </Flex>

            <Spacer height="3" />

            <Box
                css={{
                    color: 'white',
                    fontSize: '8px',
                    fontWeight: '400'
                }}
            >
                ACCRÉDITATIONS
            </Box>

            <Box
                css={{
                    color: 'white',
                    fontSize: '10px',
                    fontWeight: '300'
                }}
            >
                {accreditations}
            </Box>
        </Flex>
    );
};
