import * as React from 'react';
import { Box } from '../../../../designSystem/components/box';
import { Flex } from '../../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../../generated/types';

interface IUltraMarinPartenaireBackProps {
    event: DocumentUserCustomBadgeFragment;
}

export const UltraMarinPartenaireBack = (props: IUltraMarinPartenaireBackProps) => {
    const accreditations = props.event.volunteerRegistration.accreditationsUsersInfos
        .map(({ accreditation }) => accreditation.acronym)
        .join(', ');

    return (
        <Flex
            direction="column"
            height={396}
            width={280}
            css={{
                background: `url(https://assets.recrewteer.com/badges/ultraMarin/ultraMarinPartenaireVerso.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$brito',
                overflow: 'hidden'
            }}
        >
            <Box
                css={{
                    color: 'white',
                    fontSize: '8px',
                    fontWeight: '400',
                    paddingLeft: '101px',
                    paddingTop: '321px'
                }}
            >
                ACCRÉDITATIONS
            </Box>

            <Box
                css={{
                    color: 'white',
                    fontSize: '10px',
                    fontWeight: '300',
                    paddingLeft: '101px'
                }}
            >
                {accreditations}
            </Box>
        </Flex>
    );
};
