import { localDatabase, LocalEvent } from 'common-front/src/localDatabase';
import { EventQuery, PositionId, PositionsCategoryId } from 'common/src/generated/types';
import { Emptyable } from 'common/src/util/emptyable';
import { merge } from 'lodash-es';
import * as React from 'react';
import * as Rules from './rules';

export interface IEventContext {
    event: Omit<EventQuery['event'], 'numberOfWaitingAssigments'>;
    isEventAdmin: boolean;
    localEvent: Emptyable<LocalEvent>;
    numberOfWaitingAccreditations: number;
    numberOfWaitingAssigments: number;

    isPositionCategoryAdmin(positionCategoryId?: PositionsCategoryId): boolean;
    isPositionAdmin(positionCategoryId?: PositionsCategoryId, positionId?: PositionId): boolean;
    isPositionCategoryViewer(positionCategoryId?: PositionsCategoryId): boolean;
    isPositionViewer(positionCategoryId?: PositionsCategoryId, positionId?: PositionId): boolean;
    setNumberOfWaitingAccreditations(numberOfWaitingAccreditations: number): void;
    setNumberOfWaitingAssigments(numberOfWaitingAssigments: number): void;
    updateLocalEvent(localEvent: Partial<LocalEvent>): Promise<any>;
}

const EventContext = React.createContext<IEventContext>({} as any);

interface IEventContextProviderProps {
    children: React.ReactNode;
    event: Omit<EventQuery['event'], 'numberOfWaitingAssigments'>;
    numberOfWaitingAccreditations: number;
    numberOfWaitingAssigments: number;
}

export const EventContextProvider = (props: IEventContextProviderProps) => {
    const [numberOfWaitingAccreditations, setNumberOfWaitingAccreditations] = React.useState(
        props.numberOfWaitingAccreditations
    );
    const [numberOfWaitingAssigments, setNumberOfWaitingAssigments] = React.useState(
        props.numberOfWaitingAssigments
    );
    const [localEvent, setLocalEvent] = React.useState<Emptyable<LocalEvent>>(null);
    const updateLocalEvent = React.useCallback(
        async (updates: Partial<LocalEvent>) => {
            const newLocalEvent = merge(localEvent, { id: props.event.id }, updates);

            await localDatabase.events.put(newLocalEvent);

            setLocalEvent(newLocalEvent);
        },
        [props.event.id, localEvent, setLocalEvent]
    );

    React.useEffect(() => {
        (async () => {
            let e = await localDatabase.events.get(props.event.id);

            if (!e) {
                e = { id: props.event.id };

                await localDatabase.events.put(e);
            }

            setLocalEvent(e);
        })();
    }, [props.event]);

    return (
        <EventContext.Provider
            value={{
                event: props.event,
                isEventAdmin: props.event.permissions.isEventAdmin,
                localEvent,
                numberOfWaitingAccreditations,
                numberOfWaitingAssigments,
                isPositionCategoryAdmin(positionCategoryId?: PositionsCategoryId) {
                    return Rules.isPositionCategoryAdmin(props.event, positionCategoryId);
                },
                isPositionAdmin(positionCategoryId?: PositionsCategoryId, positionId?: PositionId) {
                    return Rules.isPositionAdmin(props.event, positionCategoryId, positionId);
                },
                isPositionCategoryViewer(positionCategoryId?: PositionsCategoryId) {
                    return Rules.isPositionCategoryViewer(props.event, positionCategoryId);
                },
                isPositionViewer(
                    positionCategoryId?: PositionsCategoryId,
                    positionId?: PositionId
                ) {
                    return Rules.isPositionViewer(props.event, positionCategoryId, positionId);
                },
                setNumberOfWaitingAccreditations,
                setNumberOfWaitingAssigments,
                updateLocalEvent
            }}
        >
            {props.children}
        </EventContext.Provider>
    );
};

export function useEventContext(): IEventContext {
    return React.useContext(EventContext);
}
