import { CardListItem } from 'common-front/src/components/pagination/mobile/cardListItem';
import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionSlotsMobileQuery, PositionsSlotId } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { slotName } from '../slotName';

interface IPositionSlotsCardMobileProps {
    open: (id: PositionsSlotId) => void;
    slot: PositionSlotsMobileQuery['event']['positionsSlots']['nodes'][number];
}

export const PositionSlotsCardMobile = ({ open, slot }: IPositionSlotsCardMobileProps) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);

    return (
        <CardListItem
            onClick={() => {
                open(slot.id);
            }}
        >
            <Flex gap="2">
                {slot.assignedResources >= slot.resources ? (
                    <Badge color="success">{translate('complet_51600')}</Badge>
                ) : (
                    <Badge color="warning">{`${
                        slot.resources - slot.assignedResources
                    } membres manquants`}</Badge>
                )}
            </Flex>
            <Box fontWeight="semiBold" color="gray800" css={{ marginTop: '$2' }}>
                {slot.name}
            </Box>
            <Box fontSize="textSm" color="gray500" css={{ marginTop: '$1' }}>
                {slotName(slot, intervalService)}
            </Box>
            <Box css={{ marginTop: '$3' }}>
                <ProgressBar percent={slot.assignedResources / slot.resources} />
            </Box>
        </CardListItem>
    );
};
