import { CheckboxText, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { RadioText } from 'common-front/src/designSystem/components/radio';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId } from 'common/src/generated/types';
import { IUpdateFormParametersMissionsValues } from 'common/src/input/formParametersMissionsInput';
import { IntervalService } from 'common/src/services/intervalService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useFormParametersMissionsHiddenDisplayedQuery } from '../../../../../generated/graphqlHooks';

interface IFormParametersMissionsHiddenDisplayedSelectsProps {
    eventId: EventId;
    values: IUpdateFormParametersMissionsValues;

    change(name: string, value: any): void;
}

export const FormParametersMissionsHiddenDisplayedSelects = (
    props: IFormParametersMissionsHiddenDisplayedSelectsProps
) => {
    const translate = useTranslate();
    const { data, loader } = useFormParametersMissionsHiddenDisplayedQuery({
        eventId: props.eventId
    });
    const intervalService = useService(IntervalService);
    const [hiddenDisplay, setHiddenDisplay] = React.useState<'hidden' | 'displayed'>(
        isNonEmptyArray(props.values.form.hiddenPositionsCategoriesIds) ||
            isNonEmptyArray(props.values.form.hiddenPositionsIds) ||
            isNonEmptyArray(props.values.form.hiddenPositionsSlotsIds)
            ? 'hidden'
            : 'displayed'
    );
    const [hiddenCategoriesState, setHiddenCategoriesState] = React.useState<ICheckboxState>(
        isNonEmptyArray(props.values.form.hiddenPositionsCategoriesIds) ? 'checked' : 'unchecked'
    );
    const [hiddenPositionsState, setHiddenPositionsState] = React.useState<ICheckboxState>(
        isNonEmptyArray(props.values.form.hiddenPositionsIds) ? 'checked' : 'unchecked'
    );
    const [hiddenSlotsState, setHiddenSlotsState] = React.useState<ICheckboxState>(
        isNonEmptyArray(props.values.form.hiddenPositionsSlotsIds) ? 'checked' : 'unchecked'
    );
    const [displayedCategoriesState, setDisplayedCategoriesState] = React.useState<ICheckboxState>(
        isNonEmptyArray(props.values.form.displayedPositionsCategoriesIds) ? 'checked' : 'unchecked'
    );
    const [displayedPositionsState, setDisplayedPositionsState] = React.useState<ICheckboxState>(
        isNonEmptyArray(props.values.form.displayedPositionsIds) ? 'checked' : 'unchecked'
    );
    const [displayedSlotsState, setDisplayedSlotsState] = React.useState<ICheckboxState>(
        isNonEmptyArray(props.values.form.displayedPositionsSlotsIds) ? 'checked' : 'unchecked'
    );
    const categories = React.useMemo(() => sortBy(data.event?.positionsCategories ?? [], (c) => c.name), [data.event]);
    const positions = React.useMemo(() => sortBy(data.event?.positions.nodes ?? [], (p) => p.name), [data.event]);
    const positionsSlots = React.useMemo(() => sortBy(
            (data.event?.positions.nodes ?? []).map((p) => {
                const slots = sortBy(
                    p.slots.map((s) => {
                        const slotName = isNonEmptyString(s.name) ? `${s.name} - ` : '';
                        const slotRange = intervalService.toDisplayString(s.range, {
                            formats: { displayWeekday: true }
                        });

                        return {
                            id: s.id,
                            name: `${slotName}${slotRange} - ${p.name}`,
                            startTime: s.range.start!.toMillis()
                        };
                    }),
                    (s) => s.startTime
                );

                return {
                    ...p,
                    slots
                };
            }),
            (p) => p.name
        ), [data.event]);

    return (
        <Flex
            direction="column"
            css={{
                background: '$gray50',
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs',
                padding: '$4 $6'
            }}
        >
            {loader || (
                <>
                    <Box font="gray800 textSm semiBold">{translate('pr_f_rences_d_a_19371')}</Box>

                    <Spacer height="3" />

                    <RadioText
                        state={hiddenDisplay === 'hidden' ? 'checked' : 'unchecked'}
                        onClick={(newState) => {
                            if (newState === 'checked') {
                                setHiddenDisplay('hidden');

                                props.change('form.displayedPositionsCategoriesIds', []);
                                props.change('form.displayedPositionsIds', []);
                                props.change('form.displayedPositionsSlotsIds', []);
                            }
                        }}
                    >
                        {translate('cacher_des_cat_31908')}
                    </RadioText>

                    <Spacer height="3" />

                    {hiddenDisplay === 'hidden' && (
                        <>
                            <Flex
                                direction="column"
                                css={{
                                    background: 'white',
                                    border: '1px solid $gray200',
                                    borderRadius: '$1',
                                    boxShadow: '$xs',
                                    padding: '$4 $6'
                                }}
                            >
                                <CheckboxText
                                    state={hiddenCategoriesState}
                                    onClick={(newState) => {
                                        setHiddenCategoriesState(newState);

                                        if (newState === 'unchecked') {
                                            props.change('form.hiddenPositionsCategoriesIds', []);
                                        }
                                    }}
                                >
                                    {translate('cacher_certaine_12220')}
                                </CheckboxText>

                                {hiddenCategoriesState === 'checked' && (
                                    <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                        <Spacer height="2" />

                                        <RichSelect
                                            isSearchVisible={true}
                                            isSelectAllVisible={true}
                                            multiple={true}
                                            name="form.hiddenPositionsCategoriesIds"
                                        >
                                            {categories.map((c) => (
                                                    <option key={c.id} value={c.id}>
                                                        {c.name}
                                                    </option>
                                                ))}
                                        </RichSelect>
                                    </Flex>
                                )}

                                <Spacer height="3" />

                                <CheckboxText
                                    state={hiddenPositionsState}
                                    onClick={(newState) => {
                                        setHiddenPositionsState(newState);

                                        if (newState === 'unchecked') {
                                            props.change('form.hiddenPositionsIds', []);
                                        }
                                    }}
                                >
                                    {translate('cacher_certaine_19933')}
                                </CheckboxText>

                                {hiddenPositionsState === 'checked' && (
                                    <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                        <Spacer height="2" />

                                        <RichSelect
                                            isSearchVisible={true}
                                            isSelectAllVisible={true}
                                            multiple={true}
                                            name="form.hiddenPositionsIds"
                                        >
                                            {positions.map((p) => (
                                                    <option key={p.id} value={p.id}>
                                                        {p.name}
                                                    </option>
                                                ))}
                                        </RichSelect>
                                    </Flex>
                                )}

                                <Spacer height="3" />

                                <CheckboxText
                                    state={hiddenSlotsState}
                                    onClick={(newState) => {
                                        setHiddenSlotsState(newState);

                                        if (newState === 'unchecked') {
                                            props.change('form.hiddenPositionsSlotsIds', []);
                                        }
                                    }}
                                >
                                    {translate('cacher_certains_02997')}
                                </CheckboxText>

                                {hiddenSlotsState === 'checked' && (
                                    <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                        <Spacer height="2" />

                                        <RichSelect
                                            isSearchVisible={true}
                                            isSelectAllVisible={true}
                                            multiple={true}
                                            name="form.hiddenPositionsSlotsIds"
                                        >
                                            {positionsSlots.map((p) => (
                                                    <optgroup key={p.id} label={p.name}>
                                                        {p.slots.map((s) => (
                                                                <option key={s.id} value={s.id}>
                                                                    {s.name}
                                                                </option>
                                                            ))}
                                                    </optgroup>
                                                ))}
                                        </RichSelect>
                                    </Flex>
                                )}
                            </Flex>

                            <Spacer height="5" />
                        </>
                    )}

                    <RadioText
                        state={hiddenDisplay === 'displayed' ? 'checked' : 'unchecked'}
                        onClick={(newState) => {
                            setHiddenDisplay('displayed');

                            if (newState === 'checked') {
                                props.change('form.hiddenPositionsCategoriesIds', []);
                                props.change('form.hiddenPositionsIds', []);
                                props.change('form.hiddenPositionsSlotsIds', []);
                            }
                        }}
                    >
                        {translate('afficher_des_ca_37473')}
                    </RadioText>

                    <Spacer height="3" />

                    {hiddenDisplay === 'displayed' && (
                        <Flex
                            direction="column"
                            css={{
                                background: 'white',
                                border: '1px solid $gray200',
                                borderRadius: '$1',
                                boxShadow: '$xs',
                                padding: '$4 $6'
                            }}
                        >
                            <CheckboxText
                                state={displayedCategoriesState}
                                onClick={(newState) => {
                                    setDisplayedCategoriesState(newState);

                                    if (newState === 'unchecked') {
                                        props.change('form.displayedPositionsCategoriesIds', []);
                                    }
                                }}
                            >
                                {translate('afficher_certai_88457')}
                            </CheckboxText>

                            {displayedCategoriesState === 'checked' && (
                                <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                    <Spacer height="2" />

                                    <RichSelect
                                        isSearchVisible={true}
                                        isSelectAllVisible={true}
                                        multiple={true}
                                        name="form.displayedPositionsCategoriesIds"
                                    >
                                        {categories.map((c) => (
                                                <option key={c.id} value={c.id}>
                                                    {c.name}
                                                </option>
                                            ))}
                                    </RichSelect>
                                </Flex>
                            )}

                            <Spacer height="3" />

                            <CheckboxText
                                state={displayedPositionsState}
                                onClick={(newState) => {
                                    setDisplayedPositionsState(newState);

                                    if (newState === 'unchecked') {
                                        props.change('form.displayedPositionsIds', []);
                                    }
                                }}
                            >
                                {translate('affficher_certa_32341')}
                            </CheckboxText>

                            {displayedPositionsState === 'checked' && (
                                <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                    <Spacer height="2" />

                                    <RichSelect
                                        isSearchVisible={true}
                                        isSelectAllVisible={true}
                                        multiple={true}
                                        name="form.displayedPositionsIds"
                                    >
                                        {positions.map((p) => (
                                                <option key={p.id} value={p.id}>
                                                    {p.name}
                                                </option>
                                            ))}
                                    </RichSelect>
                                </Flex>
                            )}

                            <Spacer height="3" />

                            <CheckboxText
                                state={displayedSlotsState}
                                onClick={(newState) => {
                                    setDisplayedSlotsState(newState);

                                    if (newState === 'unchecked') {
                                        props.change('form.displayedPositionsSlotsIds', []);
                                    }
                                }}
                            >
                                {translate('afficher_certai_38724')}
                            </CheckboxText>

                            {displayedSlotsState === 'checked' && (
                                <Flex direction="column" css={{ paddingLeft: '$6' }}>
                                    <Spacer height="2" />

                                    <RichSelect
                                        isSearchVisible={true}
                                        isSelectAllVisible={true}
                                        multiple={true}
                                        name="form.displayedPositionsSlotsIds"
                                    >
                                        {positionsSlots.map((p) => (
                                                <optgroup key={p.id} label={p.name}>
                                                    {p.slots.map((s) => (
                                                            <option key={s.id} value={s.id}>
                                                                {s.name}
                                                            </option>
                                                        ))}
                                                </optgroup>
                                            ))}
                                    </RichSelect>
                                </Flex>
                            )}
                        </Flex>
                    )}
                </>
            )}
        </Flex>
    );
};
