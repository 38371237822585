import { IconSelect } from 'common-front/src/designSystem/components/iconSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import {
    AccreditationPositionProperty,
    AccreditationsAccreditationFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsIconCellProps {
    accreditation: AccreditationsAccreditationFragment;
    firstCellCss: any;

    reload(): void;
}

export const AccreditationsIconCell = (props: IAccreditationsIconCellProps) => {
    const { translate } = useHeavent();

    return (
        <AccreditationsUpdateCell
            accreditation={props.accreditation}
            cellCss={{
                ...props.firstCellCss,
                flex: '0 100px'
            }}
            initialValue={props.accreditation.icon}
            property={AccreditationPositionProperty.Icon}
            reload={props.reload}
            renderInput={(value, setValue) => (
                    <IconSelect
                        label={translate('Icon')}
                        renderOnPortal={true}
                        value={value}
                        onChange={setValue}
                    />
                )}
        >
            <I icon={fromIconVO(props.accreditation.icon)} />
        </AccreditationsUpdateCell>
    );
};
