import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { I } from 'common/src/designSystem/components/i';
import { CustomField, VolunteersImportMode } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export type CustomFieldsInCategory = [
    string,
    Array<Pick<CustomField, 'id' | 'name' | 'customFieldCategoryName'>>
];

interface IColumnMappingProps {
    column: {
        name: string;
        index: number;
    };
    fields: CustomFieldsInCategory[];
    mode: VolunteersImportMode;
}

export const ColumnMapping = ({ column, fields, mode }: IColumnMappingProps) => {
    const translate = useTranslate();

    return (
        <>
            <TextInput state="disabled" value={column.name} onChange={() => undefined} />
            <I icon="arrow-right" />
            <RichSelect
                multiple={false}
                isSearchVisible={true}
                placeholder={translate('s_lectionnez_un_12007')}
                name={`input.mappings.${column.index}`}
            >
                {mode === VolunteersImportMode.UpdateFromId && (
                    <optgroup label={translate('id_51738')}>
                        <option value="id">{translate('id_51738')}</option>
                    </optgroup>
                )}
                <optgroup label={translate('Email')}>
                    <option value="email">{translate('Email')}</option>
                </optgroup>
                {fields.map(([customFieldCategoryId, customFieldsInCategory]) => (
                    <optgroup
                        key={customFieldCategoryId}
                        label={customFieldsInCategory[0].customFieldCategoryName}
                    >
                        {customFieldsInCategory.map((field) => (
                            <option key={field.id} value={field.id.toString()}>
                                {field.name}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </RichSelect>
        </>
    );
};
