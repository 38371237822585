import '0_reflect-metadata';
import { Authenticate } from 'common-front/src/authenticate';
import { Empty } from 'common-front/src/components/empty/empty';
import { ErrorBoundaryContextProvider } from 'common-front/src/components/errorBoundary/errorBoundaryContext';
import { MagicLink } from 'common-front/src/components/magicLink/magicLink';
import { MainLoader } from 'common-front/src/components/mainLoader/mainLoader';
import { MediaQueryProvider } from 'common-front/src/components/mediaQuery/mediaQueryContext';
import { Showcase } from 'common-front/src/designSystem/showcase/showcase';
import { UserContext } from 'common-front/src/userContext';
import { FrontDependenciesProvider } from 'common-front/src/util/dependencies/frontDependenciesProvider';
import { LogToken } from 'common-front/src/util/logToken';
import { ReferrerContextProvider } from 'common-front/src/util/referrerContext';
import { Box } from 'common/src/designSystem/components/box';
import { overriddenTheme } from 'common/src/designSystem/components/stitches';
import { CommonEnvVars } from 'common/src/envVars';
import { HeaventEnv } from 'common/src/heaventEnv';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { OrganizationsPaths } from 'common/src/util/paths/organizationsPaths';
import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AccreditationMap } from './accreditations/map/accreditationMap';
import { Auth } from './auth/auth';
import { CognitoCallback } from './auth/cognitoCallback';
import { Backoffice } from './backoffice/backoffice';
import { CreateEventOrganization } from './events/create/createEventOrganization';
import { Home } from './home/home';
import { Hubspot } from './hubspot';
import { About } from './mso/about';
import { NotificationContextProvider } from './notifications/notificationContext';
import { CreateOrganization } from './organization/create/createOrganization';
import { Organization } from './organization/organization';
import { ExportPositionsMap } from './positions/list/map/export/exportPositionsMap';
import { PositionMap } from './positions/map/positionMap';
import { RedirectToRegister } from './redirectToRegister';
import { UnsubscribeEmail } from './unsubscribeEmail';
import { UserFieldUpdate } from './userFieldUpdate';

const Authenticated = (props: { children: React.ReactNode }) => (
        <Authenticate shouldHaveUser={true} getRedirectPath={() => HeaventPaths.AUTH_SIGN_IN}>
            {props.children}
        </Authenticate>
    );

const Unauthenticated = (props: { children: React.ReactNode }) => (
        <Authenticate shouldHaveUser={false} getRedirectPath={() => HeaventPaths.HOME}>
            {props.children}
        </Authenticate>
    );

const App = () => (
        <Box
            css={{
                color: '$oldGrayDark',
                height: '100%',
                overflow: 'hidden',
                position: 'relative',
                width: '100%'
            }}
            className={overriddenTheme}
        >
            <BrowserRouter>
                <Switch>
                    <Route
                        exact
                        path={HeaventPaths.HOME}
                        children={
                            <Authenticated>
                                <Home />
                            </Authenticated>
                        }
                    />

                    <Route
                        path={HeaventPaths.USER_FIELD_UPDATE(
                            ':organizationId',
                            ':userInfoId',
                            ':customFieldSlug',
                            ':value'
                        )}
                        children={<UserFieldUpdate />}
                    />

                    <Route
                        path={HeaventPaths.BACKOFFICE}
                        children={
                            <Authenticated>
                                <Backoffice />
                            </Authenticated>
                        }
                    />

                    <Route path={HeaventPaths.DESIGN_SYSTEM} children={<Showcase />} />

                    <Route
                        path={HeaventPaths.POSITION_MAP(':eventId', ':positionId')}
                        children={<PositionMap />}
                    />

                    <Route
                        path={HeaventPaths.ACCREDITATION_MAP(':eventId', ':accreditationId')}
                        children={<AccreditationMap />}
                    />

                    <Route
                        path={HeaventPaths.EXPORT_POSITIONS_MAP(':eventId', ':segmentId')}
                        children={<ExportPositionsMap />}
                    />

                    <Redirect
                        from={OrganizationsPaths.__Deprecated.CREATE_ORGANIZATION}
                        to={OrganizationsPaths.CREATE_ORGANIZATION}
                    />
                    <Route
                        exact
                        path={OrganizationsPaths.CREATE_ORGANIZATION}
                        children={
                            <Authenticated>
                                <CreateOrganization />
                            </Authenticated>
                        }
                    />

                    <Route
                        exact
                        path={HeaventPaths.CREATE_EVENT_WITH_ORGANIZATION}
                        children={
                            <Authenticated>
                                <CreateEventOrganization />
                            </Authenticated>
                        }
                    />

                    <Route
                        path={HeaventPaths.ORGANIZATION(':organizationId')}
                        children={
                            <Authenticated>
                                <Organization />
                            </Authenticated>
                        }
                    />

                    <Route
                        path={HeaventPaths.AUTH}
                        children={
                            <Unauthenticated>
                                <Auth />
                            </Unauthenticated>
                        }
                    />

                    <Route
                        path={HeaventPaths.COGNITO_CALLBACK}
                        children={
                            <Unauthenticated>
                                <CognitoCallback />
                            </Unauthenticated>
                        }
                    />

                    <Route
                        path={HeaventPaths.MAGIC_LINK(':code', ':source')}
                        children={
                            <Unauthenticated>
                                <MagicLink />
                            </Unauthenticated>
                        }
                    />

                    <Route path={HeaventPaths.UNSUBSCRIBE_EMAIL} children={<UnsubscribeEmail />} />

                    {/* MSO specific routes */}

                    {CommonEnvVars.HEAVENT_ENV === HeaventEnv.Mso && (
                        <>
                            <Route path="/about" children={<About />} />
                        </>
                    )}

                    {/* Handle old register routes */}
                    <Route
                        path="/volunteers/:organizationId-:eventId"
                        children={<RedirectToRegister isEmbed={false} />}
                    />

                    <Route
                        path="/embed/volunteers/:organizationId-:eventId"
                        children={<RedirectToRegister isEmbed={true} />}
                    />

                    {/* Default redirect */}
                    <Route children={<Empty path={HeaventPaths.HOME} />} />
                </Switch>
            </BrowserRouter>
        </Box>
    );

render(
    <React.StrictMode>
        <MainLoader
            render={(userContext) => (
                    <UserContext.Provider value={userContext}>
                        <FrontDependenciesProvider>
                            <MediaQueryProvider>
                                <ErrorBoundaryContextProvider>
                                    <NotificationContextProvider>
                                        <ReferrerContextProvider>
                                            <LogToken />

                                            <Hubspot />

                                            <App />
                                        </ReferrerContextProvider>
                                    </NotificationContextProvider>
                                </ErrorBoundaryContextProvider>
                            </MediaQueryProvider>
                        </FrontDependenciesProvider>
                    </UserContext.Provider>
                )}
        />
    </React.StrictMode>,
    document.getElementById('heavent-react-hook')
);
