import { Flex } from 'common/src/designSystem/components/flex';
import { PositionQuery } from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationCustomFields } from '../../../../accreditations/show/accreditationCustomFields';
import { AccreditationDescription } from '../../../../accreditations/show/accreditationDescription';
import { AccreditationLocation } from '../../../../accreditations/show/accreditationLocation';
import { AccreditationPrivateNote } from '../../../../accreditations/show/accreditationPrivateNote';
import { AccreditationTags } from '../../../../accreditations/show/accreditationTags';
import { PositionCategoryGauge } from '../../../../positionsCategories/show/positionCategoryGauge';
import { PositionCategoryLeaders } from '../../../../positionsCategories/show/positionCategoryLeaders';
import { PositionParameters } from '../../../show/positionParameters';
import { useTranslate } from 'common/src/util/dependencies/dependencies';

interface IPositionSettingsMobileProps {
    position: PositionQuery['event']['position'];
}

export const PositionSettingsMobile = ({ position }: IPositionSettingsMobileProps) => {
    const translate = useTranslate();

    return (
        <Flex direction="column" gap="7" css={{ marginBottom: '$7' }}>
            <PositionCategoryGauge category={position} title={translate('remplissage_des_84383')} />
            <PositionCategoryLeaders leaders={position.leaders} />
            <PositionParameters position={position} />
            <AccreditationDescription accreditation={position} />
            <AccreditationLocation accreditation={position} />
            <AccreditationCustomFields customFields={position.positionsCustomFields} />
            <AccreditationTags tags={position.tags} type="positions" />
            <AccreditationPrivateNote accreditation={position} />
        </Flex>
    );
};
