import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A6_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';
import { EmptyZone, Zone } from './zone';

interface IBadgeFcgA6Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeFcgA6 = (props: IBadgeFcgA6Props) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;
    const acronyms = vr.accreditationsUsersInfos.map(({ accreditation }) => accreditation.acronym);
    const hasCat1 = acronyms.includes('CAT 1');
    const hasCat2 = acronyms.includes('CAT 2');
    const hasCat3 = acronyms.includes('CAT 3');
    const hasCat4 = acronyms.includes('CAT 4');
    const hasCat5 = acronyms.includes('CAT 5');
    const hasCat6 = acronyms.includes('CAT 6');

    return (
        <Flex
            direction="column"
            height={A6_SIZES['96dpi'].height}
            width={A6_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/fcg/fcgBackgroundBlue2.jpg) no-repeat`,
                backgroundSize: 'cover',
                color: '#00384f',
                fontFamily: 'Montserrat',
                padding: '$4'
            }}
        >
            <Flex
                direction="column"
                height={1}
                width={1}
                css={{ background: 'white', padding: '$5' }}
            >
                <Flex height={80}>
                    {isNonEmptyString(userInfo.fields.picture?.url) && (
                        <Box height={1} width={80} css={{ border: '2px solid #004994' }}>
                            <img src={userInfo.fields.picture?.url} height="100%" width="100%" />
                        </Box>
                    )}

                    <Spacer width="2" />

                    <Flex
                        direction="column"
                        align="start"
                        justify="center"
                        css={{
                            flex: '1',
                            fontSize: '12px',
                            fontWeight: '900',
                            lineHeight: '18px',
                            textTransform: 'uppercase'
                        }}
                    >
                        <Box>{userInfo.fields.cf10106 || ''}</Box>
                        <Box>{userInfo.fields.firstName}</Box>
                        <Box>{userInfo.fields.lastName}</Box>
                    </Flex>

                    <Spacer width="4" />

                    {isNonEmptyString(vr.utid) && (
                        <Box
                            height={1}
                            width={80}
                            css={{ border: '2px solid #004994', padding: '$1' }}
                        >
                            <img
                                src={`https://api.${
                                    CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                                }/barcode/qr?text=${encodeURIComponent(vr.utid)}&color=5d9dcb`}
                                height="100%"
                                width="100%"
                            />
                        </Box>
                    )}
                </Flex>

                <Spacer height="6" />

                {/*
                <Flex align="center" height={100}>
                    <Box height={100} width={100}>
                        <img
                            src="https://assets.recrewteer.com/badges/fcg/fcgLogo.png"
                            height="100%"
                            width="100%"
                        />
                    </Box>

                    <Spacer width="6" />

                    <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        css={{
                            color: '#cd1719',
                            flex: '1',
                            fontSize: '30px',
                            fontWeight: '900',
                            textAlign: 'center',
                            textTransform: 'uppercase'
                        }}
                    >
                        {props.event.name}
                    </Flex>
                </Flex>
                */}

                <Box height={104} width={325}>
                    <img
                        src="https://assets.recrewteer.com/badges/fcg/bannerAccess.jpg"
                        height="100%"
                        width="100%"
                    />
                </Box>

                <Spacer height="7" />

                <Flex css={{ gap: '7px' }}>
                    <Flex direction="column" gap="2">
                        {hasCat1 ? (
                            <Zone backgroundColor="#8ec89a" zoneNumber={1} zoneText="Terrain" />
                        ) : (
                            <EmptyZone />
                        )}

                        {hasCat2 ? (
                            <Zone backgroundColor="#ddca00" zoneNumber={2} zoneText="Zone mixte" />
                        ) : (
                            <EmptyZone />
                        )}

                        {hasCat3 ? (
                            <Zone
                                backgroundColor="#cd1719"
                                zoneNumber={3}
                                zoneText="Espaces Media"
                            />
                        ) : (
                            <EmptyZone />
                        )}
                    </Flex>

                    <Flex direction="column" gap="2">
                        {hasCat4 ? (
                            <Zone backgroundColor="#009fe3" zoneNumber={4} zoneText="Tribunes" />
                        ) : (
                            <EmptyZone />
                        )}

                        {hasCat5 ? (
                            <Zone
                                backgroundColor="#d89fc7"
                                zoneNumber={5}
                                zoneText="Espaces et Salon VIP"
                            />
                        ) : (
                            <EmptyZone />
                        )}

                        {hasCat6 ? (
                            <Zone backgroundColor="#010000" zoneNumber={6} zoneText="PC Sécurité" />
                        ) : (
                            <EmptyZone />
                        )}
                    </Flex>
                </Flex>

                <Spacer height="5" />

                <Box height={32} width={1}>
                    <img
                        src="https://assets.recrewteer.com/badges/fcg/fcgMontagne.png"
                        width="100%"
                    />
                </Box>

                <Spacer height="3" />

                <Box
                    css={{
                        fontSize: '6px',
                        fontWeight: '700'
                    }}
                >
                    Les titres d'accès au Stade des Alpes pour les matchs du FC Grenoble Rugby :
                </Box>

                <Box
                    css={{
                        fontSize: '6px',
                        fontWeight: '400'
                    }}
                >
                    &bull; Le billet du match permet d'entrer dans le stade et donne accès à une
                    place assise réservée.
                </Box>

                <Spacer height="2" />

                <Box css={{ fontSize: '6px', fontWeight: '400' }}>
                    &bull;{' '}
                    <span style={{ fontWeight: 700 }}>
                        L'accréditation permet uniquement de circuler à l'intérieur du stade dans
                        les zones réservées et ne donne droit à aucune place assise.
                    </span>{' '}
                    L'entrée n'est possible que via l'entrée EST Valmy.
                </Box>

                <Spacer height="2" />

                <Box
                    css={{
                        fontSize: '6px',
                        fontWeight: '400'
                    }}
                >
                    Cette accréditation est la propriété de la SASP FC Grenoble Rugby, organisateur
                    de l'événement, qui se réserve le doit de vérifier l'identité et la qualité
                    professionnelle du porteur. Ce dernier s'engage à suivre les prescriptions de
                    l'organisateur et à respecter en tout point le règlement intérieur du site. Tout
                    manquement à ces règles entraînera la saisie de l'accréditation et l'expulsion
                    du porteur hors du site.
                </Box>
            </Flex>
        </Flex>
    );
};
