import { Button } from 'common-front/src/designSystem/components/button';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useOrganizationTagsQuery } from '../../generated/graphqlHooks';
import { TagCreateModal } from './tagCreateModal';
import { TagRow } from './tagRow';

export const Tags = () => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [offset, setOffset] = React.useState(0);
    const { data, isLoading, reload } = useOrganizationTagsQuery({
        organizationId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.organization?.tags);
    const [isCreateOpen, setIsCreateOpen] = React.useState(false);

    return (
        <>
            <Flex direction="column" height={1} css={{ overflowY: 'auto', padding: '$7' }}>
                <Flex gap="3">
                    <Box
                        color="gray900"
                        fontSize="displayXs"
                        fontWeight="semiBold"
                        css={{ flex: '1' }}
                    >
                        {translate('tags_79499')}
                    </Box>

                    <Button
                        onClick={() => {
                            setIsCreateOpen(true);
                        }}
                    >
                        {translate('cr_er_un_nouvea_56617')}
                    </Button>
                </Flex>

                <Spacer height="7" />

                <TableFilters
                    filters={
                        <>
                            <Box width={320}>
                                <TextInput
                                    icon="magnifying-glass"
                                    placeholder={translate('rechercher_un_t_34829')}
                                    value={name}
                                    onChange={setName}
                                />
                            </Box>
                        </>
                    }
                    headerCells={
                        <>
                            <HeaderCell>{translate('nom_08311')}</HeaderCell>
                            <HeaderCell>{translate('nombre_d_utilis_58389')}</HeaderCell>
                            <HeaderCell width={100} />
                        </>
                    }
                    numberOfPages={numberOfPages}
                    offset={offset}
                    rows={
                        isLoading ? (
                            <></>
                        ) : (
                            data.organization.tags.nodes.map((tag) => (
                                    <TagRow
                                        key={tag.id}
                                        organizationId={organizationId}
                                        tag={tag}
                                        reload={reload}
                                    />
                                ))
                        )
                    }
                    title={translate('liste_des_tags_83521')}
                    totalCount={totalCount}
                    setOffset={setOffset}
                />
            </Flex>

            {isCreateOpen && (
                <TagCreateModal
                    organizationId={organizationId}
                    onClose={() => {
                        setIsCreateOpen(false);
                    }}
                    onSuccess={reload}
                />
            )}
        </>
    );
};
