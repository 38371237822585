import * as React from 'react';
import { Flex } from 'common/src/designSystem/components/flex';
import { Button } from 'common-front/src/designSystem/components/button';
import { Svgs } from 'common-front/src/util/assets';
import { Blank } from 'common-front/src/components/blank/blank';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { FormId } from 'common/src/generated/types';
import { Spacer } from 'common/src/designSystem/components/spacer';

interface IUserProfileInformationsEmptyProps {
    getEditUserFormPath(formId: FormId | 'all' | 'private'): string;
}

export const UserProfileInformationsEmpty = (props: IUserProfileInformationsEmptyProps) => {
    const { history, translate } = useHeavent();

    return (
        <>
            <Spacer height="7" />

            <Flex
                height={450}
                css={{
                    background: 'white',
                    border: '1px solid $gray200',
                    borderRadius: '$2',
                    boxShadow: '$xs'
                }}
            >
                <Blank
                    button={
                        <Button
                            onClick={() => {
                                history.push(props.getEditUserFormPath('all'));
                            }}
                        >
                            {translate('_diter_les_cham_66020')}
                        </Button>
                    }
                    imageSrc={Svgs.FormsElementsBlank}
                    title={translate('aucun_formulair_41857')}
                />
            </Flex>
        </>
    );
};
