import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { TimeInput } from 'common-front/src/designSystem/form/date/timeInput';
import { FloatInput } from 'common-front/src/designSystem/form/floatInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EmailsSender, MapLayer } from 'common/src/generated/types';
import { CountriesService } from 'common/src/services/countriesService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { USABLE_LANGUAGES } from 'common/src/vo/supportedLanguage';
import { TimeZones } from 'common/src/vo/timeZone';
import last from 'lodash-es/last';
import * as React from 'react';
import { OnChange } from 'react-final-form-listeners';

interface IEventOptionsProps {
    emailsSenders: Array<Pick<EmailsSender, 'id' | 'name'>>;
    hasLanguagesFeature: boolean;
    showEmailSender: boolean;

    change(field: string, value: any): void;
}

export const EventOptions = (props: IEventOptionsProps) => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const countriesService = useService(CountriesService);
    const timeZonesOptions = React.useMemo(() => TimeZones.map((timeZone, index) => (
                <option key={index} value={timeZone}>
                    {timeZone}
                </option>
            )), []);

    return (
        <>
            {props.showEmailSender && (
                <>
                    <RichSelect
                        isCreateVisible={true}
                        isSearchVisible={true}
                        label={translate('_metteur_des_e_27849')}
                        name="event.emailSenderId"
                        onCreateClick={() => {
                            history.push(
                                HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(organizationId)
                            );
                        }}
                    >
                        {props.emailsSenders.map((emailSender) => (
                                <option key={emailSender.id} value={emailSender.id}>
                                    {emailSender.name}
                                </option>
                            ))}
                    </RichSelect>

                    <Spacer height="4" />
                </>
            )}

            <OnChange name="event.languages">
                {(languages: string[]) => {
                    if (!props.hasLanguagesFeature && languages.length > 1) {
                        props.change('event.languages', [last(languages)]);
                    }
                }}
            </OnChange>

            <RichSelect
                multiple={true}
                label={translate('langues_de_l_v_94191')}
                name="event.languages"
            >
                {USABLE_LANGUAGES.map((language) => (
                        <option key={language} value={language}>
                            {translate(language)}
                        </option>
                    ))}
            </RichSelect>

            <Spacer height="4" />

            <RichSelect
                isSearchVisible={true}
                label={translate('fuseau_horaire_14654')}
                name="event.timeZone"
            >
                {timeZonesOptions}
            </RichSelect>

            <Spacer height="4" />

            <TimeInput label={translate('heure_laquell_13491')} name="event.dayStartTime" />

            <Spacer height="4" />

            <RichSelect isSearchVisible={true} label={translate('Country')} name="event.country">
                {countriesService.countriesIdName.map((idName) => (
                        <option key={idName.id} value={idName.id}>
                            {idName.name}
                        </option>
                    ))}
            </RichSelect>

            <Spacer height="4" />

            <Select label={translate('fond_de_carte_43822')} name="event.layer">
                <option value={MapLayer.Google}>Plan</option>
                <option value={MapLayer.GoogleSatellite}>Satellite</option>
                <option value={MapLayer.IgnPlan}>IGN Plan</option>
            </Select>

            <Spacer height="4" />

            <Flex gap="6">
                <FloatInput
                    label={<LabelOptional>{translate('latitude_par_d_01620')}</LabelOptional>}
                    name="event.latitude"
                />
                <FloatInput
                    label={<LabelOptional>{translate('longitude_par_d_28326')}</LabelOptional>}
                    name="event.longitude"
                />
            </Flex>
        </>
    );
};
