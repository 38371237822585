import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

interface IBadgeWeezdaysA6Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeWeezdaysA6 = (props: IBadgeWeezdaysA6Props) => {
    const ui = props.event.volunteerRegistration.userInfo;

    return (
        <>
            <Flex
                height={597}
                width={435}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/weezdays/weezdaysRector.png) no-repeat`,
                    backgroundSize: 'cover',
                    position: 'relative'
                }}
            >
                <Box
                    css={{
                        color: '$gray800',
                        fontSize: '28px',
                        fontWeight: '700',
                        left: '40px',
                        position: 'absolute',
                        top: '412px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        color: '$gray800',
                        fontSize: '28px',
                        fontWeight: '700',
                        left: '40px',
                        position: 'absolute',
                        top: '446px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    css={{
                        color: '$gray800',
                        fontSize: '13px',
                        fontWeight: '500',
                        left: '40px',
                        position: 'absolute',
                        top: '487px'
                    }}
                >
                    {ui.fields.cf27989}
                </Box>
            </Flex>

            <Flex
                height={597}
                width={435}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/weezdays/weezdaysVerso.png) no-repeat`,
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />
        </>
    );
};
