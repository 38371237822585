import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import {
    Feature,
    SegmentCustomFieldFragment,
    SegmentId,
    SegmentType,
    VolunteersSegmentsQuery
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { VolunteersSegmentsService } from 'common/src/vo/segments/volunteersSegmentsService';
import * as React from 'react';
import { useEventContext } from '../../events/show/eventContext';
import { useOrganizationContext } from '../../organization/organizationContext';
import { VolunteersContent } from './volunteersContent';

interface IVolunteersProps {
    customFields: SegmentCustomFieldFragment[];
    event: VolunteersSegmentsQuery['event'];

    reload(): void;
}

export const Volunteers = (props: IVolunteersProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, segmentId }
    } = useHeavent();
    const { hasFeature } = useOrganizationContext();
    const { isEventAdmin, localEvent, updateLocalEvent } = useEventContext();
    const segmentService = useService(VolunteersSegmentsService);
    const filters = React.useMemo(() => segmentService.getVolunteersFilters(
            eventId,
            segmentId,
            props.customFields,
            props.event,
            {
                hasAccreditations: hasFeature(Feature.Accreditation),
                hasDelegations: hasFeature(Feature.Delegation)
            }
        ), [eventId, segmentId, props.customFields, props.event]);
    const possibleColumns = React.useMemo(() => segmentService.getVolunteersPossibleColumns(
            props.customFields,
            hasFeature(Feature.Accreditation),
            hasFeature(Feature.Delegation)
        ), [props.customFields, props.event]);

    return (
        <SegmentsGrid
            eventId={eventId}
            filters={filters}
            filtersButtonText={translate('filtrer_les_mem_47149')}
            filtersSubtitle={translate('appliquer_des_f_48814')}
            initialLimit={localEvent?.volunteersLimit ?? DEFAULT_PAGINATION_LIMIT}
            initialSegmentsOpen={localEvent?.areVolunteersSegmentsOpen ?? true}
            isAdmin={isEventAdmin}
            organizationId={organizationId}
            possibleColumns={possibleColumns}
            segmentId={segmentId}
            segmentType={SegmentType.Volunteers}
            segmentsFolders={props.event.segmentsFolders}
            getSegmentPath={(id: SegmentId) => HeaventPaths.VOLUNTEERS_SEGMENT(organizationId, eventId, id)}
            onSegmentClick={(selectedSegmentId) => {
                updateLocalEvent({ volunteersSegmentId: selectedSegmentId });
            }}
            onSegmentDelete={async () => {
                await updateLocalEvent({ volunteersSegmentId: null });

                history.push(HeaventPaths.VOLUNTEERS(organizationId, eventId));
            }}
            onSegmentsToggle={async (areSegmentsOpen) => {
                await updateLocalEvent({ areVolunteersSegmentsOpen: areSegmentsOpen });
            }}
            reload={props.reload}
            setLimit={(volunteersLimit) => {
                updateLocalEvent({ volunteersLimit });
            }}
        >
            <VolunteersContent customFields={props.customFields} />
        </SegmentsGrid>
    );
};
