import { Button } from 'common-front/src/designSystem/components/button';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

export const CustomFieldsHeader = () => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const { segment } = useSegmentsContext();

    return (
        <Flex width={1} gap="4">
            <Box font="gray900 displaySm semiBold" css={{ flex: '1' }}>
                {segment.name}
            </Box>

            <Button
                onClick={() => {
                    history.push(HeaventPaths.CREATE_CUSTOM_FIELD(organizationId, segment.id));
                }}
            >
                {translate('cr_er_un_champ_31226')}
            </Button>
        </Flex>
    );
};
