import * as React from 'react';
import { Flex } from '../../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../../generated/types';
import { UltraMarinPresseBack } from './ultraMarinPresseBack';
import { UltraMarinPresseFront } from './ultraMarinPresseFront';

interface IUltraMarinPresseProps {
    event: DocumentUserCustomBadgeFragment;
}

export const UltraMarinPresse = (props: IUltraMarinPresseProps) => (
        <>
            <Flex height={396} width={280} css={{ overflow: 'hidden', pageBreakBefore: 'always' }}>
                <UltraMarinPresseFront event={props.event} />
            </Flex>

            <Flex height={396} width={280} css={{ overflow: 'hidden', pageBreakBefore: 'always' }}>
                <UltraMarinPresseBack event={props.event} />
            </Flex>
        </>
    );
