import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A7_SIZES } from '../../../util/pdf';
import { BadgeSalamandreA7Back } from './badgeSalamandreA7Back';
import { BagdeSalamandreA7Front } from './bagdeSalamandreA7Front';

interface IBadgeSalamandreA7Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeSalamandreA7 = (props: IBadgeSalamandreA7Props) => (
        <Flex height={A7_SIZES['96dpi'].height} width={A7_SIZES['96dpi'].width * 2}>
            <BagdeSalamandreA7Front event={props.event} />

            <BadgeSalamandreA7Back event={props.event} />
        </Flex>
    );
