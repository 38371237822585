import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { PasswordInput } from 'common-front/src/designSystem/form/passwordInput';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { confirmPassword, forgotPassword } from 'common-front/src/util/aws/cognito';
import { LocalStorageKeys } from 'common-front/src/util/localStorage';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    ForgotPasswordInputService,
    IForgotPasswordValues
} from 'common/src/input/forgotPasswordInput';
import { ValidateService } from 'common/src/services/validateService';
import {
    useHistory,
    useLocalStorage,
    useService,
    useTranslate
} from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { AuthPage } from './authPage';
import { Link } from './link';
import { PasswordRules } from './passwordRules';

interface IForgotPasswordProps {
    onSuccess(token: string): void;
}

export const ForgotPassword = (props: IForgotPasswordProps) => {
    const translate = useTranslate();
    const history = useHistory();
    const localStorage = useLocalStorage();
    const validateService = useService(ValidateService);
    const forgotPasswordInput = useService(ForgotPasswordInputService);
    const [step, setStep] = React.useState(0);

    return (
        <AuthPage
            link={
                <Link>
                    <Box color="gray500">{translate('vous_n_avez_pas_69078')}</Box>

                    <Box
                        color="primary700"
                        css={{ cursor: 'pointer' }}
                        onClick={() => {
                            history.push(HeaventPaths.AUTH_SIGN_IN_EMAIL);
                        }}
                    >
                        {translate('r_essayer_21582')}
                    </Box>
                </Link>
            }
        >
            <Form
                direction="column"
                width={1}
                initialValues={{
                    email: localStorage.getItem(LocalStorageKeys.AUTH_EMAIL) || '',
                    code: '',
                    password: ''
                }}
                validate={(values: IForgotPasswordValues) => {
                    if (step === 0) {
                        return validateService.validateForForm(forgotPasswordInput.step1Schema())(
                            values
                        );
                    } else {
                        return validateService.validateForForm(forgotPasswordInput.step2Schema())(
                            values
                        );
                    }
                }}
                onSubmit={async (values: IForgotPasswordValues) => {
                    if (step === 0) {
                        await forgotPassword(values.email);

                        setStep(1);
                    } else {
                        props.onSuccess(
                            await confirmPassword(values.email, values.code, values.password)
                        );
                    }
                }}
                render={({ handleSubmit, submitting, values }) => (
                        <>
                            <Box
                                color="gray900"
                                fontSize="textXl"
                                fontWeight="medium"
                                textAlign="center"
                            >
                                {translate('mot_de_passe_ou_17690')}
                            </Box>

                            <Spacer height="1" />

                            <Box color="gray500" textAlign="center">
                                {translate('saisissez_votre_47959')}
                            </Box>

                            <Spacer height="7" />

                            <TextInput
                                autoComplete="email"
                                label={translate('adresse_e_mail_60930')}
                                placeholder={translate('entrez_votre_ad_42451')}
                                name="email"
                                isEmail={true}
                                state={step > 0 ? 'disabled' : 'active'}
                            />

                            {step > 0 && (
                                <>
                                    <Spacer height="4" />

                                    <TextInput
                                        autoComplete="one-time-code"
                                        label={translate('code_re_u_par_e_75150')}
                                        placeholder={translate('code_20843')}
                                        name="code"
                                    />

                                    <Spacer height="4" />

                                    <PasswordInput
                                        autoComplete="new-password"
                                        label={translate('nouveau_mot_de_46347')}
                                        placeholder={translate('entrez_votre_mo_51874')}
                                        name="password"
                                    />

                                    <Spacer height="2" />

                                    <PasswordRules password={values.password} />
                                </>
                            )}

                            <Spacer height="6" />

                            <Button
                                onClick={handleSubmit}
                                isLoading={submitting}
                                textAlign="center"
                            >
                                {step === 0
                                    ? translate('recevoir_le_cod_88633')
                                    : translate('r_initialiser_l_12900')}
                            </Button>
                        </>
                    )}
            />
        </AuthPage>
    );
};
