import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

interface IBadgeParis20kmBackProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeParis20kmBack = (props: IBadgeParis20kmBackProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;
    const accreditations = vr.accreditationsUsersInfos.map(
        ({ accreditation }) => accreditation.name
    );
    const color = accreditations.includes('ACCES VIOLET')
        ? '#772a8a'
        : accreditations.includes('ACCES BLEU CLAIR')
        ? '#57c9e9'
        : accreditations.includes('ACCES VERT')
        ? '#078e45'
        : accreditations.includes('ACCES BLEU FONCE')
        ? '#2c358c'
        : accreditations.includes('ACCES JAUNE')
        ? '#fee901'
        : '#fea101';
    const background = accreditations.includes('ACCES VIOLET')
        ? 'https://assets.recrewteer.com/badges/paris20km/masque_zone-violet.png'
        : accreditations.includes('ACCES BLEU CLAIR')
        ? 'https://assets.recrewteer.com/badges/paris20km/masque_zone-bleu_clair.png'
        : accreditations.includes('ACCES VERT')
        ? 'https://assets.recrewteer.com/badges/paris20km/masque_zone-vert.png'
        : accreditations.includes('ACCES BLEU FONCE')
        ? 'https://assets.recrewteer.com/badges/paris20km/masque_zone-bleu.png'
        : accreditations.includes('ACCES JAUNE')
        ? 'https://assets.recrewteer.com/badges/paris20km/masque_zone-jaune.png'
        : 'https://assets.recrewteer.com/badges/paris20km/masque_zone-orange.png';

    return (
        <Flex
            height={476}
            width={368}
            css={{
                background: `url(https://assets.recrewteer.com/badges/paris20km/paris20kmVerso_2.png) no-repeat`,
                backgroundColor: color,
                backgroundSize: 'cover',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        >
            <Flex
                align="center"
                justify="center"
                css={{
                    color: 'black',
                    fontSize: '16px',
                    fontWeight: '700',
                    height: '39px',
                    left: '29px',
                    position: 'absolute',
                    top: '31px',
                    width: '40px'
                }}
            >
                {userInfo.paris20kmTaille}
            </Flex>

            <Box
                css={{
                    height: '59px',
                    left: '0',
                    position: 'absolute',
                    top: '122px',
                    width: '367px'
                }}
            >
                <img src={background} height="100%" width="100%" />
            </Box>
        </Flex>
    );
};
