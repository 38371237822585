import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { DelegationProperty, DelegationsDelegationFragment } from 'common/src/generated/types';
import * as React from 'react';
import { useDelegationsContext } from '../delegationsContext';
import { DelegationsUpdateCell } from './delegationsUpdateCell';

interface IDelegationsNameCellProps {
    delegation: DelegationsDelegationFragment;
    firstCellCss: any;

    reload(): void;
}

export const DelegationsNameCell = (props: IDelegationsNameCellProps) => {
    const { history, translate } = useHeavent();
    const { isEditMode } = useSegmentsContext();
    const { getShowPath } = useDelegationsContext();
    const css = isEditMode
        ? { ellipsis: true }
        : {
              cursor: 'pointer',
              ellipsis: true,
              '&:hover': {
                  textDecoration: 'underline'
              }
          };

    return (
        <DelegationsUpdateCell
            delegation={props.delegation}
            cellCss={props.firstCellCss}
            initialValue={props.delegation.name}
            property={DelegationProperty.Name}
            reload={props.reload}
            renderInput={(value, setValue) => (
                    <TextInput
                        label={translate('nom_de_la_d_l_g_28087')}
                        value={value}
                        onChange={setValue}
                    />
                )}
        >
            <Box
                font="gray900 textSm medium"
                width={1}
                css={css}
                title={props.delegation.name}
                onClick={() => {
                    if (!isEditMode) {
                        history.push(getShowPath(props.delegation.id));
                    }
                }}
            >
                {props.delegation.name}
            </Box>
        </DelegationsUpdateCell>
    );
};
