import { Flex } from 'common/src/designSystem/components/flex';
import * as React from 'react';

interface IMenuProps {
    children: React.ReactNode;
    height?: number | string;
    width?: number | string;
}

export const TopMenu = (props: IMenuProps) => (
        <Flex
            justify="center"
            gap="2"
            height={props.height}
            width={props.width}
            css={{
                borderBottom: '1px solid $gray200'
            }}
        >
            {props.children}
        </Flex>
    );
