import { Box } from 'common/src/designSystem/components/box';
import * as React from 'react';

interface IAccordionProps {
    initialIsOpen: boolean;
    title: string;
    children: React.ReactNode;
}

export const Accordion = (props: IAccordionProps) => {
    const [currentIsOpen, setIsOpen] = React.useState(props.initialIsOpen);

    return (
        <Box width={1}>
            <Box
                css={{ cursor: 'pointer', display: 'flex', marginBottom: '$4' }}
                onClick={() => {
                    setIsOpen(!currentIsOpen);
                }}
            >
                <Box css={{ color: '#929ea9', width: '10px' }}>
                    {currentIsOpen ? (
                        <i className="far fa-caret-down" />
                    ) : (
                        <i className="far fa-caret-right" />
                    )}
                </Box>

                <Box css={{ fontSize: '14px', fontWeight: '$oldBold', marginLeft: '$2' }}>
                    {props.title}
                </Box>
            </Box>

            {currentIsOpen && <Box css={{ zIndex: 1 }}>{props.children}</Box>}
        </Box>
    );
};
