import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

const getBackBackground = (profil: string) => {
    switch (profil) {
        case 'STAFF':
        case 'ORGANISATION':
            return 'https://assets.recrewteer.com/badges/jardinmichel/verso_annuaire_v4.png';
        default:
            return 'https://assets.recrewteer.com/badges/jardinmichel/v2/Verso.png';
    }
};

interface IBadgeJardinMichelBackProps {
    displayBorder?: boolean;
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeJardinMichelBack = (props: IBadgeJardinMichelBackProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            height={378}
            width={283}
            css={{
                background: `url(${getBackBackground(ui.jardinMichelProfil)}) no-repeat`,
                backgroundSize: 'cover',
                border: props.displayBorder ? '1px solid black' : '',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        />
    );
};
