import { Button } from 'common-front/src/designSystem/components/button';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { CampaignTextMessagesQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import {
    useHistory,
    useParams,
    useService,
    useTranslate
} from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { getStateBadgeColorIcon } from 'common/src/vo/textMessage';
import * as React from 'react';
import { useTextMessageRepliesReadMutation } from '../../generated/graphqlHooks';
import { useCampaignContext } from './campaignContext';
import { TextMessageReplies } from './textMessageReplies/textMessageReplies';

interface ICampaignTextMessageRowProps {
    createdBy?: CampaignTextMessagesQuery['organization']['campaign']['createdBy'];
    text: string;
    textMessage: CampaignTextMessagesQuery['organization']['campaign']['textMessages']['nodes'][0];
}

export const CampaignTextMessageRow = (props: ICampaignTextMessageRowProps) => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const history = useHistory();
    const { getCampaignDashboardUserInfosPath } = useCampaignContext();
    const dateTimeService = useService(DateTimeService);
    const { mutate } = useTextMessageRepliesReadMutation();
    const [isRepliesOpen, setIsRepliesOpen] = React.useState(false);

    return (
        <>
            <Row
                css={{
                    cursor: 'pointer',
                    '&:hover': {
                        background: '$gray100'
                    }
                }}
                onClick={() => {
                    history.push(getCampaignDashboardUserInfosPath(props.textMessage.userInfo.id));
                }}
            >
                <Cell>
                    <AvatarNameEmail
                        userInfo={props.textMessage.userInfo}
                        hasName={isNonEmptyString(props.textMessage.userInfo.name)}
                    />
                </Cell>
                <Cell>{props.textMessage.userInfo.phone?.internationalFormat ?? ''}</Cell>
                <Cell>
                    {props.textMessage.sentAt
                        ? dateTimeService.toLocaleString(
                              props.textMessage.sentAt,
                              LocaleFormats.DateTime
                          )
                        : ''}
                </Cell>
                <Cell>
                    <Badge {...getStateBadgeColorIcon(props.textMessage.state)}>
                        {translate(props.textMessage.state)}
                    </Badge>
                </Cell>
                <Cell>
                    {props.textMessage.numberOfReplies > 0 ? (
                        <Button
                            size="sm"
                            color="white"
                            onClick={async (e) => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();

                                await mutate({
                                    organizationId,
                                    eventId,
                                    textMessageId: props.textMessage.id
                                });
                                setIsRepliesOpen(true);
                            }}
                        >
                            {translate('voir_la_reponse', props.textMessage.numberOfReplies)}
                        </Button>
                    ) : (
                        ''
                    )}
                </Cell>
            </Row>

            {isRepliesOpen && (
                <TextMessageReplies
                    createdBy={props.createdBy}
                    eventId={eventId}
                    organizationId={organizationId}
                    userInfo={props.textMessage.userInfo}
                    text={props.text}
                    textMessage={props.textMessage}
                    onClose={() => {
                        setIsRepliesOpen(false);
                    }}
                />
            )}
        </>
    );
};
