import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { IIcon } from 'common/src/designSystem/components/i';
import { debugLog } from 'common/src/util/debug';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { Button, IButtonColor, IButtonSize } from '../components/button';

export const Buttons = () => {
    const [color, setColor] = React.useState<IButtonColor>('primary');
    const [size, setSize] = React.useState<IButtonSize>('md');
    const [disabled, setDisabled] = React.useState(false);
    const [content, setContent] = React.useState('Button');
    const [leftIcon, setLeftIcon] = React.useState<IIcon | undefined>();
    const [rightIcon, setRightIcon] = React.useState<IIcon | undefined>();
    const [isLoading, setIsLoading] = React.useState(false);
    const [textAlign, setTextAlign] = React.useState<
        'start' | 'end' | 'center' | 'justify' | undefined
    >(undefined);
    const onClick = () => {
        debugLog('click !');
    };

    return (
        <Flex direction="column" gap="6">
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Buttons
            </Box>

            <Flex gap="3">
                <Button onClick={onClick}>Primary</Button>

                <Button isLoading={true} onClick={onClick}>
                    Primary
                </Button>

                <Button disabled onClick={onClick}>
                    Primary disabled
                </Button>

                <Button color="gray" onClick={onClick}>
                    Gray
                </Button>

                <Button color="error" onClick={onClick}>
                    Error
                </Button>

                <Button color="error" isLoading={true} onClick={onClick}>
                    Error
                </Button>

                <Button color="white" onClick={onClick}>
                    White
                </Button>

                <Button color="white" isLoading={true} onClick={onClick}>
                    White
                </Button>

                <Button color="white" disabled onClick={onClick}>
                    White disabled
                </Button>

                <Button color="invisible" onClick={onClick}>
                    Invisible
                </Button>

                <Button color="invisible" isLoading={true} onClick={onClick}>
                    Invisible
                </Button>

                <Button color="invisible" disabled onClick={onClick}>
                    Invisible disabled
                </Button>

                <Button size="lg" onClick={onClick}>
                    Primary lg
                </Button>
            </Flex>

            <Flex align="center" gap="3">
                <select
                    value={color}
                    onChange={(e) => {
                        setColor(e.target.value as IButtonColor);
                    }}
                >
                    <option value="primary">primary</option>
                    <option value="gray">gray</option>
                    <option value="transparent">transparent</option>
                    <option value="error">error</option>
                </select>

                <select
                    value={size}
                    onChange={(e) => {
                        setSize(e.target.value as IButtonSize);
                    }}
                >
                    <option value="sm">sm</option>
                    <option value="md">md</option>
                    <option value="lg">lg</option>
                    <option value="xl">xl</option>
                    <option value="2xl">2xl</option>
                </select>

                <input
                    type="checkbox"
                    name="disabled"
                    checked={disabled}
                    onChange={(e) => {
                        setDisabled(e.target.checked);
                    }}
                />

                <Box color="gray800">Disabled</Box>

                <input
                    type="text"
                    value={content}
                    onChange={(e) => {
                        setContent(e.target.value);
                    }}
                />

                <select
                    value={leftIcon}
                    onChange={(e) => {
                        setLeftIcon(e.target.value as IIcon);
                    }}
                >
                    <option value="">left icon</option>
                    <option value="triangle-exclamation">⚠️</option>
                    <option value="check">✅</option> <option value="circle">⭕</option>
                </select>

                <select
                    value={rightIcon}
                    onChange={(e) => {
                        setRightIcon(e.target.value as IIcon);
                    }}
                >
                    <option value="">right icon</option>
                    <option value="triangle-exclamation">⚠️</option>
                    <option value="check">✅</option>
                    <option value="circle">⭕</option>
                </select>

                <input
                    type="checkbox"
                    checked={isLoading}
                    onChange={(e) => {
                        setIsLoading(e.target.checked);
                    }}
                />

                <Box color="gray800">Loading</Box>

                <Box color="gray800">Text align:</Box>
                <select
                    value={textAlign}
                    onChange={(e) => {
                        setTextAlign(
                            e.target.value as 'start' | 'end' | 'center' | 'justify' | undefined
                        );
                    }}
                >
                    <option value="start">start</option>
                    <option value="end">end</option>
                    <option value="center">center</option>
                    <option value="justify">justify</option>
                </select>
            </Flex>

            <Flex gap="3">
                <Button
                    color={color}
                    size={size}
                    disabled={disabled}
                    leftIcon={leftIcon}
                    rightIcon={rightIcon}
                    isLoading={isLoading}
                    textAlign={textAlign}
                    onClick={() => {
                        setIsLoading(true);

                        setTimeout(() => {
                            setIsLoading(false);
                        }, 2000);
                    }}
                    children={isNonEmptyString(content) ? content : undefined}
                />
                <Box width={200}>
                    <Button
                        color={color}
                        size={size}
                        disabled={disabled}
                        leftIcon={leftIcon}
                        rightIcon={rightIcon}
                        isLoading={isLoading}
                        textAlign={textAlign}
                        onClick={() => {
                            setIsLoading(true);

                            setTimeout(() => {
                                setIsLoading(false);
                            }, 2000);
                        }}
                        children={isNonEmptyString(content) ? content : undefined}
                    />
                </Box>
            </Flex>
        </Flex>
    );
};
