import { getAccreditationLimits } from 'common-front/src/delegations/accreditations/getAccreditationLimits';
import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { ProgressBar } from 'common-front/src/designSystem/components/progressBar';
import { RadioText } from 'common-front/src/designSystem/components/radio';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationsSlotId,
    AccreditSlotsQuery,
    DelegationAccreditation
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { NumberService } from 'common/src/services/numberService';
import { toggle } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { fullName } from 'common/src/vo/accreditationSlot';
import { compact, sortBy, without } from 'lodash-es';
import * as React from 'react';
import { useAccreditContext } from './accreditContext';

interface IAccreditSlotsListRadioProps {
    delegationAccreditations?: DelegationAccreditation[];
    isRadio: boolean;
    slots: AccreditSlotsQuery['event']['accreditationsSlots']['nodes'];
}

export const AccreditSlotsListRadio = ({
    delegationAccreditations,
    isRadio,
    slots
}: IAccreditSlotsListRadioProps) => {
    const dateTimeService = useService(DateTimeService);
    const numberService = useService(NumberService);
    const { accreditationsSlotsIds, setAccreditationsSlotsIds } = useAccreditContext();
    const sortedSlots = React.useMemo(() => sortBy(slots, (s) => compact([s.date?.toMillis(), s.name.toLowerCase()])), [slots]);
    const onClick = React.useCallback(
        (id: AccreditationsSlotId) => {
            if (isRadio) {
                setAccreditationsSlotsIds([
                    ...without(accreditationsSlotsIds, ...slots.map((s) => s.id)),
                    id
                ]);
            } else {
                setAccreditationsSlotsIds(toggle(accreditationsSlotsIds, id));
            }
        },
        [isRadio, slots, accreditationsSlotsIds, setAccreditationsSlotsIds]
    );

    return (
        <Flex direction="column">
            {sortedSlots.map((slot) => {
                const state = accreditationsSlotsIds.includes(slot.id) ? 'checked' : 'unchecked';

                // calculate resource limitations, if any
                const da = delegationAccreditations?.find(
                    (da) => da.accreditationSlotId === slot.id
                );

                const { assignedResources, isMaxedOut, limitIcon, maxResources, percent } =
                    getAccreditationLimits(da, slot);

                const prospectivePercent =
                    percent !== undefined
                        ? (assignedResources + (accreditationsSlotsIds.includes(slot.id) ? 1 : 0)) /
                          maxResources
                        : undefined;

                return (
                    <Flex key={slot.id} css={{ borderTop: '1px solid $gray200', padding: '$4 $6' }}>
                        <Box css={{ flex: '1' }}>
                            {isRadio ? (
                                <RadioText
                                    disabled={isMaxedOut && state === 'unchecked'}
                                    state={state}
                                    onClick={() => {
                                        onClick(slot.id);
                                    }}
                                >
                                    <Box css={{ textTransform: 'capitalize' }}>
                                        {fullName(dateTimeService, slot, '', {
                                            format: LocaleFormats.DateOnly.WeekdayLongMonthLong
                                        })}
                                    </Box>
                                </RadioText>
                            ) : (
                                <CheckboxText
                                    disabled={isMaxedOut && state === 'unchecked'}
                                    state={state}
                                    onClick={() => {
                                        onClick(slot.id);
                                    }}
                                >
                                    <Box css={{ textTransform: 'capitalize' }}>
                                        {fullName(dateTimeService, slot, '', {
                                            format: LocaleFormats.DateOnly.WeekdayLongMonthLong
                                        })}
                                    </Box>
                                </CheckboxText>
                            )}
                        </Box>

                        <Spacer width="2" />

                        <Box color="gray700" css={{ marginBlockStart: '2px' }} fontSize="textXs">
                            <I icon="user-group" />
                        </Box>

                        <Spacer width="1" />

                        <Box color="gray700">
                            {assignedResources + (accreditationsSlotsIds.includes(slot.id) ? 1 : 0)}
                            /{numberService.toNumberOrInfinity(maxResources)}
                        </Box>

                        <Spacer width="2" />

                        <Box color="gray400" textAlign="center">
                            {maxResources !== Infinity && <>[{limitIcon}]</>}
                        </Box>

                        <Spacer width="6" />

                        <Box css={{ marginTop: 'auto', marginBottom: 'auto' }} width={120}>
                            {percent !== undefined && (
                                <ProgressBar
                                    isErrorIfOver={true}
                                    percent={percent}
                                    prospectivePercent={prospectivePercent}
                                />
                            )}
                        </Box>

                        <Spacer width="3" />

                        <Box color="gray700" width={36} textAlign="end">
                            {(prospectivePercent ?? percent) !== undefined && (
                                <>
                                    {Math.min(
                                        100,
                                        Math.round((prospectivePercent ?? percent)! * 100)
                                    )}
                                    %
                                </>
                            )}
                        </Box>
                    </Flex>
                );
            })}
        </Flex>
    );
};
