import { capitalize, sum } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { Cell } from '../../../designSystem/components/table/cell';
import { HeaderCell } from '../../../designSystem/components/table/headerCell';
import { HeaderRow } from '../../../designSystem/components/table/headerRow';
import { Row } from '../../../designSystem/components/table/row';
import { Table } from '../../../designSystem/components/table/table';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { DateTimeService } from '../../../services/dateTimeService';
import { DurationService } from '../../../services/durationService';
import { isNonEmptyArray } from '../../../util/array';
import { useService } from '../../../util/dependencies/dependencies';
import { LocaleFormats } from '../../../util/luxon';
import { IGroupedAssignment } from '../../userPlanningList/groupAssignmentsByPositionDay';

interface IEurockeennesPageProps {
    assignments: IGroupedAssignment[];
    event: DocumentUserCustomBadgeFragment;
    header: React.ReactNode;
    index: number;
}

export const EurockeennesPage = (props: IEurockeennesPageProps) => {
    const dateTimeService = useService(DateTimeService);
    const durationService = useService(DurationService);

    return (
        <Flex
            direction="column"
            width={1}
            css={{
                background: 'white',
                pageBreakBefore: props.index !== 0 ? 'always' : undefined,
                px: '$8'
            }}
        >
            {props.header}

            <Spacer height="5" />

            <Box font="gray800 textMd semiBold">Planning des missions Eurockéennes 2023</Box>

            <Spacer height="2" />

            <Table>
                <HeaderRow>
                    <HeaderCell>Nom de la mission</HeaderCell>
                    <HeaderCell width={410}>Date</HeaderCell>
                    <HeaderCell width={120}>Total</HeaderCell>
                </HeaderRow>

                {props.assignments.map((assignment, index) => {
                    const hasSlotsNames = isNonEmptyArray(assignment.slotsNames);
                    const minutes = sum(
                        assignment.ranges.map((i) => i.toDuration('minutes').minutes)
                    );

                    return (
                        <Row key={index} css={{ height: hasSlotsNames ? '54px' : '44px' }}>
                            <Cell
                                css={{
                                    color: '$gray900',
                                    fontWeight: '$medium'
                                }}
                            >
                                {assignment.name}
                            </Cell>

                            <Cell direction="column" justify="center" gap="1" width={410}>
                                {hasSlotsNames && (
                                    <Box color="gray900" width={1}>
                                        {assignment.slotsNames.join(', ')}
                                    </Box>
                                )}

                                <Box width={1}>
                                    {capitalize(
                                        dateTimeService.toLocaleString(
                                            assignment.ranges[0].start!,
                                            LocaleFormats.DateOnly.WeekdayLongMonthLong
                                        )
                                    )}{' '}
                                    {assignment.ranges
                                        .map((range) => `${range.start!.toFormat(
                                                'HH:mm'
                                            )}-${range.end!.toFormat('HH:mm')}`)
                                        .join(' | ')}
                                </Box>
                            </Cell>

                            <Cell width={120}>
                                {durationService.minutesToHoursMinutesString(minutes)}
                            </Cell>
                        </Row>
                    );
                })}
            </Table>
        </Flex>
    );
};
