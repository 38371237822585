import { identity } from 'lodash-es';
import * as React from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../components/reactFinalForm/isInvalid';
import { BaseInputProps } from '../components/input/baseInputProps';
import { RichEditor as RichEditorComponent } from '../components/richEditor/richEditor';

interface IRichEditorProps extends Omit<BaseInputProps, 'icon' | 'rightIcon'> {
    name: string;
}

export const RichEditor = (props: IRichEditorProps) => (
        <Field
            name={props.name}
            parse={identity}
            render={(fieldProps) => {
                const invalid = isInvalid(fieldProps.meta);

                return (
                    <RichEditorComponent
                        {...props}
                        hint={invalid ? fieldProps.meta.error : props.hint}
                        state={invalid ? 'error' : props.state}
                        initialValue={fieldProps.input.value!}
                        onChange={(value) => {
                            fieldProps.input.onChange(value);
                        }}
                    />
                );
            }}
        />
    );
