import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UsersInfoId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { getDisplayName } from 'common/src/vo/customBadge';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';
import { DocumentRow } from './documentRow';

interface IDocumentsMembersProps {
    assignedUserInfoId: UsersInfoId;
    numberOfAssignedVolunteersRegistrations: number;
    numberOfVolunteersRegistrations: number;
    userInfoId: UsersInfoId;
}

export const DocumentsMembers = (props: IDocumentsMembersProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { organization } = useOrganizationContext();

    return (
        <Flex direction="column" gap="2">
            <Box font="gray900 textLg semiBold">{translate('membres_11310')}</Box>

            <DocumentRow
                color="pink"
                disabled={props.numberOfAssignedVolunteersRegistrations === 0}
                icon="user"
                title={translate('fiche_d_affecta_91461')}
                text={translate('nombre_de_membr_87049')}
                count={props.numberOfAssignedVolunteersRegistrations}
                path={HeaventPaths.DOCUMENTS_ASSIGNMENTS(
                    organizationId,
                    eventId,
                    props.assignedUserInfoId
                )}
            />

            <DocumentRow
                color="purple"
                disabled={props.numberOfAssignedVolunteersRegistrations === 0}
                icon="calendar-day"
                title={translate('UserPlanning')}
                text={translate('nombre_de_membr_87049')}
                count={props.numberOfAssignedVolunteersRegistrations}
                path={HeaventPaths.DOCUMENTS_USER_PLANNING(
                    organizationId,
                    eventId,
                    props.assignedUserInfoId
                )}
            />

            <DocumentRow
                color="purple"
                disabled={props.numberOfAssignedVolunteersRegistrations === 0}
                icon="calendar-day"
                title={translate('UserPlanningList')}
                text={translate('nombre_de_membr_87049')}
                count={props.numberOfAssignedVolunteersRegistrations}
                path={HeaventPaths.DOCUMENTS_USER_PLANNING_LIST(
                    organizationId,
                    eventId,
                    props.assignedUserInfoId
                )}
            />

            <DocumentRow
                color="purple"
                disabled={props.numberOfAssignedVolunteersRegistrations === 0}
                icon="calendar-day"
                title={translate('UserPlanningDays')}
                text={translate('nombre_de_membr_87049')}
                count={props.numberOfAssignedVolunteersRegistrations}
                path={HeaventPaths.DOCUMENTS_USER_PLANNING_DAYS(
                    organizationId,
                    eventId,
                    props.assignedUserInfoId
                )}
            />

            <DocumentRow
                color="success"
                disabled={props.numberOfAssignedVolunteersRegistrations === 0}
                icon="certificate"
                title={translate('Certificate')}
                text={translate('nombre_de_membr_87049')}
                count={props.numberOfAssignedVolunteersRegistrations}
                path={HeaventPaths.DOCUMENTS_CERTIFICATE(
                    organizationId,
                    eventId,
                    props.assignedUserInfoId
                )}
            />

            <DocumentRow
                color="success"
                disabled={props.numberOfAssignedVolunteersRegistrations === 0}
                icon="id-card"
                title={translate('BadgeCheck')}
                text={translate('nombre_de_membr_87049')}
                count={props.numberOfAssignedVolunteersRegistrations}
                path={HeaventPaths.DOCUMENTS_BADGE(
                    organizationId,
                    eventId,
                    props.assignedUserInfoId,
                    'generic'
                )}
            />

            {organization.customBadges.map((customBadge) => (
                    <React.Fragment key={customBadge}>
                        <DocumentRow
                            color="success"
                            disabled={false}
                            icon="id-card"
                            title={getDisplayName(customBadge)}
                            text={translate('nombre_de_membr_87049')}
                            count={props.numberOfVolunteersRegistrations}
                            path={HeaventPaths.DOCUMENTS_BADGE(
                                organizationId,
                                eventId,
                                props.userInfoId,
                                customBadge
                            )}
                        />

                        <Spacer height="2" />
                    </React.Fragment>
                ))}
        </Flex>
    );
};
