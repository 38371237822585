import { Button } from 'common-front/src/designSystem/components/button';
import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UserInfoOverlayFragment } from 'common/src/generated/types';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import * as React from 'react';
import { useUserInfoAdminDeleteMutation } from '../../../generated/graphqlHooks';

interface IUserOverlayActionsOrganizationProps {
    userInfo: UserInfoOverlayFragment;
}

export const UserOverlayActionsOrganization = (props: IUserOverlayActionsOrganizationProps) => {
    const {
        history,
        params: { organizationId },
        translate
    } = useHeavent();
    const { mutate: userInfoAdminDelete } = useUserInfoAdminDeleteMutation();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

    return (
        <>
            <Spacer height="6" />

            <Box color="gray800" fontSize="textMd" fontWeight="semiBold">
                {translate('g_n_ral_64953')}
            </Box>

            <Spacer height="3" />

            <Button
                color="white"
                leftIcon="trash-can"
                rightIcon="arrow-right"
                onClick={() => {
                    setIsDeleteModalOpen(true);
                }}
            >
                {translate('supprimer_43083')}
            </Button>

            {isDeleteModalOpen && (
                <DeleteModal
                    text={translate('suppression_de_04261', props.userInfo.nameOrEmail)}
                    subtext={[
                        translate('_tes_vous_s_r_d_53083'),
                        translate('cette_action_es_08212')
                    ]}
                    buttonText={translate('supprimer_43083')}
                    onDelete={async () => {
                        await userInfoAdminDelete({
                            organizationId,
                            userInfoId: props.userInfo.id
                        });

                        history.push(CommunityPaths.COMMUNITY_USERS(organizationId));
                    }}
                    onClose={() => {
                        setIsDeleteModalOpen(false);
                    }}
                />
            )}
        </>
    );
};
