import { pick, range, sortBy } from 'lodash-es';
import { Interval } from 'luxon';
import * as React from 'react';
import { DocumentUsersPlanningFragment } from '../../generated/types';
import { isNonEmptyArray } from '../../util/array';
import {
    DEFAULT_MAX_HOUR,
    DEFAULT_MIN_HOUR,
    intervalsStats,
    splitRangesByDay,
    splitRangesMidday
} from '../../util/interval';
import { UserInfoSlot, UsersPlanningPage } from './usersPlanningPage';

interface IUsersPlanningProps {
    displayedPageIndex?: number;
    ranges: Interval[];
    volunteersRegistrations: DocumentUsersPlanningFragment[];

    onNextClick?(): void;
    onPreviousClick?(): void;
}

export const UsersPlanning = (props: IUsersPlanningProps) => {
    const slots = React.useMemo(() => {
        const userInfosSlots = (
            props.volunteersRegistrations.flatMap((vr) => vr.positionsSlotsUsersInfos.map((psui) => ({
                        type: 'assignment',
                        range: psui.positionSlot.range,
                        position: psui.position,
                        userInfoId: vr.userInfo.id
                    }))) as UserInfoSlot[]
        ).concat(
            props.volunteersRegistrations.flatMap((vr) => vr.slots.map((s) => ({
                        type: 'availability',
                        range: s.range,
                        userInfoId: vr.userInfo.id
                    })))
        );
        const usersInfosSlotsByDay = splitRangesByDay(userInfosSlots);

        if (isNonEmptyArray(usersInfosSlotsByDay)) {
            const usersInfosSlotsByDayStats = intervalsStats(
                usersInfosSlotsByDay.map((s) => s.range).concat(props.ranges)
            );
            let usersInfosSlotsSplitsMidday: UserInfoSlot[];

            if (
                usersInfosSlotsByDayStats.startHour !== DEFAULT_MIN_HOUR ||
                usersInfosSlotsByDayStats.endHour !== DEFAULT_MAX_HOUR
            ) {
                usersInfosSlotsSplitsMidday = splitRangesMidday(usersInfosSlotsByDay);
            } else {
                usersInfosSlotsSplitsMidday = usersInfosSlotsByDay;
            }

            return usersInfosSlotsSplitsMidday;
        } else {
            return [];
        }
    }, [props.ranges, props.volunteersRegistrations]);
    const volunteersRegistrations = React.useMemo(() => sortBy(
            props.volunteersRegistrations.map((vr) => pick(vr, ['state', 'userInfo'])),
            (vr) => vr.userInfo.name.toLowerCase()
        ), [props.volunteersRegistrations]);
    const stats = React.useMemo(() => {
        if (isNonEmptyArray(props.ranges) || isNonEmptyArray(slots)) {
            return intervalsStats(props.ranges.concat(slots.map((s) => s.range)));
        } else {
            return null;
        }
    }, [props.ranges, slots]);
    const has2PagesPerDay =
        stats && (stats.startHour !== DEFAULT_MIN_HOUR || stats.endHour !== DEFAULT_MAX_HOUR);
    const numberOfPages = (stats?.numberOfDays ?? 0) * (has2PagesPerDay ? 2 : 1);

    return (
        <>
            {stats
                ? range(0, stats.numberOfDays).map((day, dayIndex) => range(0, has2PagesPerDay ? 2 : 1).map((_, dayPartIndex) => {
                          const pageIndex =
                              (has2PagesPerDay ? dayIndex * 2 : dayIndex) + dayPartIndex;

                          if (
                              typeof props.displayedPageIndex === 'number' &&
                              props.displayedPageIndex !== pageIndex
                          ) {
                              return null;
                          } else {
                              const startDate = stats.startDate.plus({ day }).set({
                                  hour: has2PagesPerDay
                                      ? dayPartIndex === 0
                                          ? 0
                                          : 12
                                      : DEFAULT_MIN_HOUR
                              });
                              const endDate = has2PagesPerDay
                                  ? dayPartIndex === 0
                                      ? startDate.set({ hour: 12 })
                                      : startDate.plus({ day: 1 }).startOf('day')
                                  : startDate.set({ hour: DEFAULT_MAX_HOUR });
                              const interval = Interval.fromDateTimes(startDate, endDate);
                              const daySlots = slots.filter((s) => s.range.overlaps(interval));

                              return (
                                  <UsersPlanningPage
                                      hasPreviousPage={pageIndex !== 0}
                                      hasNextPage={pageIndex !== numberOfPages - 1}
                                      key={`${dayIndex}-${dayPartIndex}`}
                                      interval={interval}
                                      slots={daySlots}
                                      volunteersRegistrations={volunteersRegistrations}
                                      onNextClick={props.onNextClick}
                                      onPreviousClick={props.onPreviousClick}
                                  />
                              );
                          }
                      }))
                : null}
        </>
    );
};
