import {
    Badge,
    IBadgeColor,
    IBadgeCursor,
    IBadgeSize
} from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { IIcon } from 'common/src/designSystem/components/i';
import * as React from 'react';

export const Badges = () => {
    const [size, setSize] = React.useState<IBadgeSize>('sm');
    const [color, setColor] = React.useState<IBadgeColor>('gray');
    const [cursor, setCursor] = React.useState<IBadgeCursor>('default');
    const [content, setContent] = React.useState('Badge');
    const [leftIcon, setLeftIcon] = React.useState<IIcon | undefined>();
    const [rightIcon, setRightIcon] = React.useState<IIcon | undefined>();

    return (
        <Flex direction="column" gap="6">
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Badges
            </Box>

            <Flex gap="3">
                <Badge>Gray sm</Badge>

                <Badge size="md">Gray md</Badge>

                <Badge size="lg">Gray lg</Badge>

                <Badge color="primary">Primary sm</Badge>

                <Badge color="error">Error sm</Badge>

                <Badge color="success">Success sm</Badge>

                <Badge color="warning">Warning sm</Badge>

                <Badge leftIcon="circle">With circle</Badge>

                <Badge rightIcon="xmark" cursor="pointer">
                    With cross
                </Badge>

                <Badge leftIcon="circle" rightIcon="xmark">
                    With circle and cross
                </Badge>

                <Badge rightIcon="plus" cursor="pointer" />
            </Flex>

            <Flex align="center" gap="3">
                <select
                    value={size}
                    onChange={(e) => {
                        setSize(e.target.value as IBadgeSize);
                    }}
                >
                    <option value="sm">sm</option>
                    <option value="md">md</option>
                    <option value="lg">lg</option>
                </select>

                <select
                    value={color}
                    onChange={(e) => {
                        setColor(e.target.value as IBadgeColor);
                    }}
                >
                    <option value="gray">gray</option>
                    <option value="primary">primary</option>
                    <option value="error">error</option>
                    <option value="warning">warning</option>
                    <option value="success">success</option>
                </select>

                <select
                    value={cursor}
                    onChange={(e) => {
                        setCursor(e.target.value as IBadgeCursor);
                    }}
                >
                    <option value="default">default</option>
                    <option value="pointer">pointer</option>
                </select>

                <input
                    type="text"
                    value={content}
                    onChange={(e) => {
                        setContent(e.target.value);
                    }}
                />

                <select
                    value={leftIcon}
                    onChange={(e) => {
                        setLeftIcon(e.target.value as IIcon);
                    }}
                >
                    <option value="">left icon</option>
                    <option value="triangle-exclamation">⚠️</option>
                    <option value="check">✅</option>
                    <option value="circle">⭕</option>
                    <option value="xmark">❌</option>
                </select>

                <select
                    value={rightIcon}
                    onChange={(e) => {
                        setRightIcon(e.target.value as IIcon);
                    }}
                >
                    <option value="">right icon</option>
                    <option value="triangle-exclamation">⚠️</option>
                    <option value="check">✅</option>
                    <option value="circle">⭕</option>
                    <option value="xmark">❌</option>
                </select>
            </Flex>

            <Flex>
                <Badge
                    size={size}
                    color={color}
                    cursor={cursor}
                    leftIcon={leftIcon}
                    rightIcon={rightIcon}
                >
                    {content}
                </Badge>
            </Flex>
        </Flex>
    );
};
