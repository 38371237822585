import { Form } from 'common-front/src/components/form/form';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { CheckboxText as CheckboxTextForm } from 'common-front/src/designSystem/form/checkbox';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    RegisterAccreditationDisplay,
    RegisterPositionFilter,
    RegisterSlotDisplay
} from 'common/src/generated/types';
import {
    FormParametersAccreditationsInputService,
    IUpdateFormParametersAccreditationsValues
} from 'common/src/input/formParametersAccreditationsInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import {
    useFormParametersAccreditationsQuery,
    useFormParametersAccreditationsUpdateMutation
} from '../../../../generated/graphqlHooks';
import { useFormContext } from '../../../show/formContext';
import { FormParametersAccreditationsHiddenDisplayed } from './hiddenDisplayed/formParametersAccreditationsHiddenDisplayed';

interface IFormParametersAccreditationsInnerProps {
    eventId: EventId;
    slotDisplay: RegisterSlotDisplay;
    values: IUpdateFormParametersAccreditationsValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

const FormParametersAccreditationsInner = (props: IFormParametersAccreditationsInnerProps) => {
    const translate = useTranslate();
    const { isSaving } = useFormContext();
    const showAccreditationFilter =
        props.slotDisplay !== RegisterSlotDisplay.Hide &&
        props.values.form.accreditationDisplay !== RegisterAccreditationDisplay.None;

    React.useEffect(() => {
        if (isSaving) {
            props.handleSubmit();
        }
    }, [isSaving]);

    return (
        <>
            <Box font="gray800 textMd semiBold">{translate('param_tres_des_90955')}</Box>

            <Spacer height="6" />

            <Box color="gray800" fontWeight="medium">
                {translate('comment_souhait_18471')}
            </Box>

            <Spacer height="2" />

            <RadioText
                name="form.accreditationDisplay"
                value={RegisterAccreditationDisplay.Accreditation}
            >
                {translate('afficher_les_ac_56952')}
            </RadioText>

            <Spacer height="3" />

            <RadioText name="form.accreditationDisplay" value={RegisterAccreditationDisplay.None}>
                {translate('ne_rien_affiche_81467')}
            </RadioText>

            {props.values.form.accreditationDisplay !== RegisterAccreditationDisplay.None && (
                <>
                    <Spacer height="6" />

                    <Box color="gray800" fontWeight="medium">
                        {translate('personnalisatio_86385')}
                    </Box>

                    <Spacer height="2" />

                    <ToggleText name="form.showFullAccreditation">
                        {translate('afficher_les_ac_56062')}
                    </ToggleText>

                    <Spacer height="3" />

                    <FormParametersAccreditationsHiddenDisplayed
                        eventId={props.eventId}
                        values={props.values}
                        change={props.change}
                    />

                    <Spacer height="6" />

                    <Box color="gray800" fontWeight="medium">
                        {translate('affichage_des_d_57491')}
                    </Box>

                    <Spacer height="2" />

                    <CheckboxTextForm name="form.showAccreditationDescription">
                        {translate('afficher_la_des_94587')}
                    </CheckboxTextForm>
                </>
            )}

            {showAccreditationFilter && (
                <>
                    <HorizontalSpacerSeparator height="7" />

                    <Box font="gray800 textMd semiBold">{translate('filtrer_les_acc_57840')}</Box>

                    <Spacer height="1" />

                    <Box color="gray500">{translate('d_cider_si_le_c_31187')}</Box>

                    <Spacer height="2" />

                    <RadioText
                        name="form.accreditationFilter"
                        value={RegisterPositionFilter.FilterWithSlots}
                    >
                        {translate('filtrer_les_acc_66152')}
                    </RadioText>

                    <Spacer height="3" />

                    <RadioText name="form.accreditationFilter" value={RegisterPositionFilter.None}>
                        {translate('ne_pas_filtrer_97321')}
                    </RadioText>
                </>
            )}

            {props.values.form.accreditationDisplay !== RegisterAccreditationDisplay.None && (
                <>
                    <HorizontalSpacerSeparator height="7" />

                    <Box font="gray800 textMd semiBold">{translate('personnalisatio_15893')}</Box>

                    <Spacer height="1" />

                    <Box color="gray500">{translate('personnaliser_l_44808')}</Box>

                    <Spacer height="4" />

                    <TextInput
                        label={translate('nom_de_l_tape_85862')}
                        name="form.accreditationTitle"
                    />

                    <Spacer height="6" />

                    <RichEditor
                        label={translate('description_58935')}
                        name="form.accreditationSubtitle"
                    />
                </>
            )}
        </>
    );
};

export const FormParametersAccreditations = () => {
    const { organizationId, eventId, formId, setIsSaving } = useFormContext();
    const { data, loader } = useFormParametersAccreditationsQuery({
        organizationId,
        formId
    });
    const validateService = useService(ValidateService);
    const formParametersAccreditationsInput = useService(FormParametersAccreditationsInputService);
    const { mutate } = useFormParametersAccreditationsUpdateMutation();

    return (
        loader || (
            <Form
                direction="column"
                width={1}
                initialValues={{
                    form: formParametersAccreditationsInput.formParametersAccreditationsInputDefault(
                        data.organization.form
                    )
                }}
                validate={validateService.validateForForm(
                    formParametersAccreditationsInput.updateFormParametersAccreditationsSchema()
                )}
                onSubmit={async (values: IUpdateFormParametersAccreditationsValues) => {
                    await mutate({
                        organizationId,
                        eventId,
                        formId,
                        form: values.form
                    });

                    setIsSaving(false);
                }}
                render={({ form, handleSubmit, values }) => (
                        <FormParametersAccreditationsInner
                            eventId={eventId!}
                            slotDisplay={data.organization.form.slotDisplay}
                            values={values}
                            change={form.change}
                            handleSubmit={handleSubmit}
                        />
                    )}
            />
        )
    );
};
