import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { DesignInputService, IDesignInputValues } from 'common/src/input/designInput';
import { useService } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { CreateUpdateDesign } from '../../designs/create/createUpdateDesign';
import {
    useOrganizationDelegationsSpaceDesignQuery,
    useOrganizationDelegationsSpaceDesignUpdateMutation
} from '../../generated/graphqlHooks';

export const OrganizationDelegationsSpaceDesign = () => {
    const {
        params: { organizationId },
        translate
    } = useHeavent();
    const designInput = useService(DesignInputService);
    const { data, loader } = useOrganizationDelegationsSpaceDesignQuery({
        organizationId
    });
    const { mutate } = useOrganizationDelegationsSpaceDesignUpdateMutation();

    return (
        loader || (
            <Form
                direction="column"
                initialValues={{
                    design: designInput.organizationDesignInputDefault(
                        data.organization.delegationsSpaceDesign
                    )
                }}
                onSubmit={async (values: IDesignInputValues) => {
                    await mutate({
                        organizationId,
                        delegationsSpaceDesign: values.design
                    });
                }}
                render={({ handleSubmit, submitting }) => (
                        <>
                            <Spacer height="7" />

                            <CreateUpdateDesign prefix="design." showLogo={true} title="Images" />

                            <HorizontalSpacerSeparator height="5" />

                            <Flex>
                                <Button isLoading={submitting} onClick={handleSubmit}>
                                    {translate('enregistrer_06519')}
                                </Button>
                            </Flex>
                        </>
                    )}
            />
        )
    );
};
