import { VolunteerRegistrationFragment } from '../generated/types';
import { DateTimeService } from '../services/dateTimeService';
import { LocaleFormats } from '../util/luxon';

export function getFormsInsertedAt(
    dateTimeService: DateTimeService,
    formsUsersInfos: VolunteerRegistrationFragment['userInfo']['formsUsersInfos']
): string {
    return formsUsersInfos
        .flatMap((fui) => {
            if (fui.form) {
                const name = fui.form.name;
                const insertedAt = dateTimeService.toLocaleString(
                    fui.insertedAt.toLocal(),
                    LocaleFormats.DateTime
                );

                return [`${name} : ${insertedAt}`];
            } else {
                return [];
            }
        })
        .join(', ');
}
