import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AccreditationId,
    AccreditationInput,
    AccreditationsCategoryId,
    AccreditationsSlotInput,
    CreateAccreditationEventFragment,
    CreatePositionCustomFieldsFragment,
    CreatePositionTagsFragment,
    EventId
} from 'common/src/generated/types';
import {
    AccreditationInputService,
    ICreateUpdateAccreditationValues
} from 'common/src/input/accreditationInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams, useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DEFAULT_ZOOM } from 'common/src/util/map';
import { isNonEmptyString } from 'common/src/util/string';
import { omit } from 'lodash-es';
import * as React from 'react';
import {
    useAccreditationCreateMutation,
    useAccreditationEventInfosQuery,
    useAccreditationToEditQuery,
    useAccreditationUpdateMutation
} from '../../generated/graphqlHooks';
import { ConditionsForm } from './conditionsForm';
import { CreateAccreditationInformations } from './createAccreditationInformations';
import { CreateAccreditationSlots } from './createAccreditationSlots';
import { LocationForm } from './locationForm';
import { TagsForm } from './tagsForm';

interface ICreateUpdateAccreditationProps {
    customFields: CreatePositionCustomFieldsFragment['customFields'];
    event: CreateAccreditationEventFragment;
    initialValues: ICreateUpdateAccreditationValues;
    isEdit: boolean;
    tags: CreatePositionTagsFragment['tags']['nodes'];

    mutate(args: { eventId: EventId; accreditation: AccreditationInput }): Promise<AccreditationId>;
}

const CreateUpdateAccreditation = (props: ICreateUpdateAccreditationProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, accreditationId }
    } = useHeavent();
    const accreditationInput = useService(AccreditationInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const { areSlotsOpen, isLocalisationOpen, areConditionsOpen, isPrivateNoteOpen, areTagsOpen } =
        React.useMemo(() => {
            const initialAccreditation = props.initialValues.accreditation;

            return {
                areSlotsOpen: props.isEdit && isNonEmptyArray(initialAccreditation.slots),
                isLocalisationOpen:
                    props.isEdit &&
                    (isNonEmptyString(initialAccreditation.address) ||
                        typeof initialAccreditation.latitude === 'number' ||
                        typeof initialAccreditation.longitude === 'number'),
                areConditionsOpen:
                    props.isEdit &&
                    isNonEmptyArray(initialAccreditation.accreditationsCustomFields),
                isPrivateNoteOpen:
                    props.isEdit && isNonEmptyString(initialAccreditation.privateNote),
                areTagsOpen: props.isEdit && isNonEmptyArray(initialAccreditation.tagsIds)
            };
        }, [props.initialValues, props.isEdit]);

    return (
        <Form
            height={1}
            width={1}
            hideErrors={true}
            onShowErrors={() => {
                if (centeredContainerRef.current) {
                    centeredContainerRef.current.scrollTop = 0;
                }
            }}
            initialValues={props.initialValues}
            validate={validateService.validateForForm(
                accreditationInput.createUpdateAccreditationSchema()
            )}
            onSubmit={async (values: ICreateUpdateAccreditationValues) => {
                const id = await props.mutate({
                    eventId,
                    accreditation: {
                        ...values.accreditation,
                        slots: values.accreditation.slots.map(
                            (slot) => omit(slot, ['index']) as AccreditationsSlotInput
                        )
                    }
                });

                if (props.isEdit) {
                    history.push(HeaventPaths.ACCREDITATION(organizationId, eventId, id));
                } else {
                    history.push(HeaventPaths.ACCREDITATIONS(organizationId, eventId));
                }
            }}
            render={({ form, handleSubmit, submitting, values }) => (
                    <FullScreenPopup
                        color="dark"
                        category={
                            props.isEdit
                                ? translate(
                                      'mise_jour_de_17055',
                                      props.initialValues.accreditation.name
                                  )
                                : translate('cr_ation_d_une_01473')
                        }
                        title={
                            props.isEdit
                                ? props.initialValues.accreditation.name
                                : translate('nouvelle_accr_d_32707')
                        }
                        closePath={
                            props.isEdit
                                ? HeaventPaths.ACCREDITATION(
                                      organizationId,
                                      eventId,
                                      accreditationId
                                  )
                                : HeaventPaths.ACCREDITATIONS(organizationId, eventId)
                        }
                        button={
                            props.isEdit ? (
                                <Button isLoading={submitting} onClick={handleSubmit}>
                                    {translate('mettre_jour_l_92576')}
                                </Button>
                            ) : null
                        }
                    >
                        <CenteredContainer ref={centeredContainerRef}>
                            <FormErrors />

                            <FormBox
                                hideToggle={true}
                                initialIsOpen={true}
                                title={translate('informations_su_35382')}
                            >
                                <CreateAccreditationInformations
                                    event={props.event}
                                    change={form.change}
                                />
                            </FormBox>

                            <Spacer height="5" />

                            <FormBox
                                initialIsOpen={areSlotsOpen}
                                title={translate('cr_neaux_33401')}
                                subtitle={translate('ajouter_les_dif_76832')}
                                onToggle={(isOpen) => {
                                    if (!isOpen) {
                                        form.change('accreditation.slots', []);
                                    }
                                }}
                            >
                                <CreateAccreditationSlots
                                    event={props.event}
                                    values={values}
                                    change={form.change}
                                />
                            </FormBox>

                            <Spacer height="5" />

                            <FormBox
                                initialIsOpen={isLocalisationOpen}
                                title={translate('localisation_38416')}
                                subtitle={translate('indiquez_la_loc_61935')}
                                onToggle={(isOpen) => {
                                    if (!isOpen) {
                                        form.change('accreditation.layer', props.event.layer);
                                        form.change('accreditation.zoom', DEFAULT_ZOOM);
                                        form.change('accreditation.address', null);
                                        form.change('accreditation.latitude', null);
                                        form.change('accreditation.longitude', null);
                                    }
                                }}
                            >
                                <LocationForm
                                    center={null}
                                    layer={values.accreditation.layer}
                                    name="accreditation."
                                    point={values.accreditation}
                                    traces={[]}
                                    tracesIds={[]}
                                />
                            </FormBox>

                            <Spacer height="5" />

                            <FormBox
                                initialIsOpen={areConditionsOpen}
                                title={translate('conditions_77756')}
                                subtitle={translate('ajoutez_des_con_94296')}
                                onToggle={(isOpen) => {
                                    if (!isOpen) {
                                        form.change('accreditation.accreditationsCustomFields', []);
                                    }
                                }}
                            >
                                <ConditionsForm
                                    conditionsOperator={values.accreditation.conditionsOperator}
                                    customFields={props.customFields}
                                    customFieldsValues={
                                        values.accreditation.accreditationsCustomFields
                                    }
                                    name="accreditation.accreditationsCustomFields"
                                    prefix="accreditation."
                                    change={form.change}
                                />
                            </FormBox>

                            <Spacer height="5" />

                            <FormBox
                                initialIsOpen={isPrivateNoteOpen}
                                title={translate('note_priv_e_06303')}
                                subtitle={translate('ajoutez_une_not_89122')}
                                onToggle={(isOpen) => {
                                    if (!isOpen) {
                                        form.change('accreditation.privateNote', '');
                                    }
                                }}
                            >
                                <RichEditor
                                    name="accreditation.privateNote"
                                    label={
                                        <LabelOptional>
                                            {translate('note_priv_e_06303')}
                                        </LabelOptional>
                                    }
                                />
                            </FormBox>

                            <Spacer height="5" />

                            <FormBox
                                initialIsOpen={areTagsOpen}
                                subtitle={translate('ajouter_des_tag_77298')}
                                title={translate('tags_79499')}
                                onToggle={(isOpen) => {
                                    if (!isOpen) {
                                        form.change('accreditation.tagsIds', []);
                                    }
                                }}
                            >
                                <TagsForm
                                    name="accreditation."
                                    organizationId={organizationId}
                                    tags={props.tags}
                                    tagsIds={values.accreditation.tagsIds}
                                    change={form.change}
                                />
                            </FormBox>

                            <Spacer height="5" />

                            <FormBox>
                                <Button isLoading={submitting} onClick={handleSubmit}>
                                    <Box textAlign="center">
                                        {props.isEdit
                                            ? translate('mettre_jour_l_92576')
                                            : translate('cr_er_l_accr_di_16123')}
                                    </Box>
                                </Button>
                            </FormBox>
                        </CenteredContainer>
                    </FullScreenPopup>
                )}
        />
    );
};

export const CreateAccreditation = () => {
    const { organizationId, eventId, options } = useParams<{ options: string }>();
    const accreditationInput = useService(AccreditationInputService);
    const { data, loader } = useAccreditationEventInfosQuery({ organizationId, eventId });
    const { mutate } = useAccreditationCreateMutation();
    const accreditationCategoryId = isNonEmptyString(options)
        ? (parseInt(options, 10) as AccreditationsCategoryId)
        : null;

    return (
        loader || (
            <CreateUpdateAccreditation
                customFields={data.organization.customFields}
                event={data.event}
                initialValues={{
                    accreditation: accreditationInput.accreditationInputDefault(
                        data.event.layer,
                        null,
                        accreditationCategoryId
                    )
                }}
                isEdit={false}
                tags={data.organization.tags.nodes}
                mutate={async (values) => (await mutate(values)).accreditationCreate.id}
            />
        )
    );
};

export const UpdateAccreditation = () => {
    const { organizationId, eventId, accreditationId } = useParams();
    const accreditationInput = useService(AccreditationInputService);
    const { data, loader } = useAccreditationToEditQuery({
        organizationId,
        eventId,
        accreditationId
    });
    const { mutate } = useAccreditationUpdateMutation();

    return (
        loader || (
            <CreateUpdateAccreditation
                customFields={data.organization.customFields}
                event={data.event}
                initialValues={{
                    accreditation: accreditationInput.accreditationInputDefault(
                        data.event.layer,
                        data.event.accreditation,
                        null
                    )
                }}
                isEdit={true}
                tags={data.organization.tags.nodes}
                mutate={async (values) => (
                        await mutate({
                            ...values,
                            accreditationId
                        })
                    ).accreditationUpdate.id}
            />
        )
    );
};
