import { Form } from 'common-front/src/components/form/form';
import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { AuthInputService, ICodeValues } from 'common/src/input/authInput';
import { ValidateService } from 'common/src/services/validateService';
import { useHistory, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { AuthPage } from './authPage';

interface ICodeProps {
    email: string;
    isRestartVisible: boolean;
    buttonText: string;

    onSubmit(code: string): void;
    onFailure(): void;
}

export const Code = (props: ICodeProps) => {
    const translate = useTranslate();
    const authInput = useService(AuthInputService);
    const validateService = useService(ValidateService);
    const history = useHistory();

    if (props.email.length === 0) {
        history.push(HeaventPaths.AUTH_SIGN_IN_EMAIL);
    }

    return (
        <AuthPage>
            <Form
                direction="column"
                initialValues={{
                    code: ''
                }}
                validate={(values: ICodeValues) => validateService.validateForForm(authInput.codeSchema())(values)}
                onSubmit={(values: ICodeValues) => props.onSubmit(values.code)}
                render={({ handleSubmit, hasValidationErrors, submitting }) => (
                        <>
                            <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                                {translate('saisissez_votre_20218')}
                            </Box>

                            <Spacer height="3" />

                            <Box color="gray500" fontSize="textMd">
                                {translate('il_s_agit_du_co_07957', props.email)}
                            </Box>

                            <Spacer height="7" />

                            <TextInput
                                autoComplete="one-time-code"
                                label={translate('code_20843')}
                                placeholder={translate('code_20843')}
                                name="code"
                                state={props.isRestartVisible ? 'disabled' : undefined}
                                type="number"
                            />

                            <Spacer height="6" />

                            {props.isRestartVisible ? (
                                <Button onClick={props.onFailure}>
                                    <Box width={1} textAlign="center">
                                        {translate('recommencer_14712')}
                                    </Box>
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleSubmit}
                                    disabled={hasValidationErrors}
                                    isLoading={submitting}
                                >
                                    <Box width={1} textAlign="center">
                                        {props.buttonText}
                                    </Box>
                                </Button>
                            )}

                            <Spacer height="7" />

                            <Flex justify="center" gap="1" width={1}>
                                <Box color="gray500">{translate('vous_n_avez_pas_69078')}</Box>

                                <Box
                                    color="primary700"
                                    css={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        history.push(HeaventPaths.AUTH_SIGN_IN_EMAIL);
                                    }}
                                >
                                    {translate('r_essayer_21582')}
                                </Box>
                            </Flex>
                        </>
                    )}
            />
        </AuthPage>
    );
};
