import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES, A6_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (population: string) => {
    switch (population) {
        case 'BÉNÉVOLE':
            return 'https://assets.recrewteer.com/badges/tvj/benevole.jpg';
        case 'PARTENAIRE':
            return 'https://assets.recrewteer.com/badges/tvj/partenaire.jpg';
        case 'PRESTATAIRE':
            return 'https://assets.recrewteer.com/badges/tvj/presta.jpg';
        default:
            return 'https://assets.recrewteer.com/badges/tvj/orga.jpg';
    }
};

interface IBadgeTvjProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeTvj = (props: IBadgeTvjProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = ui.tvjPopulation || '';

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(${getBackground(population)}) no-repeat`,
                backgroundSize: 'cover'
            }}
        >
            <Flex
                justify="center"
                height={A6_SIZES['96dpi'].height}
                width={A6_SIZES['96dpi'].width}
                css={{
                    fontFamily: '$urbanist',
                    position: 'relative'
                }}
            >
                <Box
                    css={{
                        fontSize: '24px',
                        fontWeight: '700',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '285px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        fontSize: '24px',
                        fontWeight: '700',
                        position: 'absolute',
                        textTransform: 'uppercase',
                        top: '313px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Flex
                    align="center"
                    justify="center"
                    height={88}
                    width={88}
                    css={{
                        background: 'white',
                        position: 'absolute',
                        top: '365px'
                    }}
                >
                    <Box height={76} width={76}>
                        {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                            <img
                                src={`https://api.${
                                    CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                                }/barcode/qr?text=${encodeURIComponent(
                                    vr.weezeventParticipantInfo!.idBarcode
                                )}&color=000000&height=84&width=84`}
                                height="100%"
                                width="100%"
                            />
                        )}
                    </Box>
                </Flex>
            </Flex>

            <Flex
                height={A6_SIZES['96dpi'].height}
                width={A6_SIZES['96dpi'].width}
                css={{
                    background: `url() no-repeat`,
                    backgroundSize: 'cover'
                }}
            />
        </Flex>
    );
};
