import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { CampaignQuery } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { getStateBadgeColorIcon } from 'common/src/vo/volunteerRegistration';
import * as React from 'react';
import { useCampaignContext } from './campaignContext';

interface ICampaignPreviewToProps {
    campaign: CampaignQuery['organization']['campaign'];
}

export const CampaignPreviewTo = (props: ICampaignPreviewToProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { getUserInformationsPath, getVolunteersSegmentPath } = useCampaignContext();
    const positions = React.useMemo(() => props.campaign.positionsCategories
            .map((pc) => ({
                id: `pc-${pc.id}`,
                name: pc.name,
                link: HeaventPaths.POSITION_CATEGORY(organizationId, eventId, pc.id)
            }))
            .concat(
                props.campaign.positions.map((p) => ({
                    id: `p-${p.id}`,
                    name: p.name,
                    link: HeaventPaths.POSITION(organizationId, eventId, p.id)
                }))
            )
            .concat(
                props.campaign.positionsSlots.map((ps) => ({
                    id: `ps-${ps.id}`,
                    name: `${ps.position.name} - ${ps.nameOrRange}`,
                    link: HeaventPaths.POSITION(organizationId, eventId, ps.position.id)
                }))
            )
            .concat(
                props.campaign.tags.map((t) => ({
                    id: `t-${t.id}`,
                    name: t.name,
                    link: ''
                }))
            ), [props.campaign]);
    const accreditations = React.useMemo(() => props.campaign.accreditationsCategories
            .map((ac) => ({
                id: `ac-${ac.id}`,
                name: ac.name,
                link: ''
            }))
            .concat(
                props.campaign.accreditations.map((a) => ({
                    id: `a-${a.id}`,
                    name: a.name,
                    link: HeaventPaths.ACCREDITATION(organizationId, eventId, a.id)
                }))
            )
            .concat(
                props.campaign.accreditationsSlots.map((as) => ({
                    id: `as-${as.id}`,
                    name: `${as.accreditation.name} - ${as.name}}`,
                    link: HeaventPaths.ACCREDITATION(organizationId, eventId, as.accreditation.id)
                }))
            ), [props.campaign]);
    const numberOfAccreditationsPositions = React.useMemo(() => accreditations.length + positions.length, [accreditations, positions]);

    return (
        <Flex gap="1">
            <Box color="gray500">{translate('_96502')}</Box>

            {props.campaign.sendToEveryone ? (
                <Box color="gray800">{translate('tous_vos_membre_30731')}</Box>
            ) : isNonEmptyArray(props.campaign.states) ? (
                <Flex gap="2" wrap="wrap" css={{ flex: '1' }}>
                    {props.campaign.states.map((s) => (
                        <Badge key={s} {...getStateBadgeColorIcon(s)}>
                            {translate(s)}
                        </Badge>
                    ))}
                </Flex>
            ) : isNonEmptyArray(props.campaign.usersInfos) ? (
                <Flex gap="1" wrap="wrap" css={{ flex: '1' }}>
                    {props.campaign.usersInfos.map((ui, index) => (
                            <Box
                                key={ui.id}
                                color="primary700"
                                css={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline'
                                }}
                                onClick={() => {
                                    history.push(getUserInformationsPath(ui.id));
                                }}
                            >
                                {ui.nameOrEmail}
                                {index !== props.campaign.usersInfos.length - 1 && ', '}
                            </Box>
                        ))}
                </Flex>
            ) : isNonEmptyArray(props.campaign.segments) ? (
                <Flex gap="1" wrap="wrap" css={{ flex: '1' }}>
                    {props.campaign.segments.map((segment, index) => (
                            <Box
                                key={segment.id}
                                color="primary700"
                                css={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline'
                                }}
                                onClick={() => {
                                    history.push(getVolunteersSegmentPath(segment.id));
                                }}
                            >
                                {segment.name}
                                {index !== props.campaign.segments.length - 1 && ', '}
                            </Box>
                        ))}
                </Flex>
            ) : (
                <Flex gap="1" wrap="wrap" css={{ flex: '1' }}>
                    {positions.concat(accreditations).map((p, index) => (
                            <Box
                                key={p.id}
                                color={isNonEmptyString(p.link) ? 'primary700' : 'gray800'}
                                css={{
                                    cursor: isNonEmptyString(p.link) ? 'pointer' : 'initial',
                                    textDecoration: 'underline'
                                }}
                                onClick={() => {
                                    if (isNonEmptyString(p.link)) {
                                        history.push(p.link);
                                    }
                                }}
                            >
                                {p.name}
                                {index !== numberOfAccreditationsPositions - 1 ? ', ' : ''}
                            </Box>
                        ))}
                </Flex>
            )}
        </Flex>
    );
};
