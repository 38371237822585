import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { heaventEnvDisplayName, heaventEnvTermsUrl } from 'common/src/heaventEnv';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const TermsForm = () => {
    const translate = useTranslate();

    return (
        <CheckboxText name="organization.termsAccepted">
            <span
                dangerouslySetInnerHTML={{
                    __html: translate(
                        'en_cr_ant_votre_88363',
                        heaventEnvTermsUrl(),
                        heaventEnvDisplayName()
                    )
                }}
            ></span>
        </CheckboxText>
    );
};
