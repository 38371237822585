import { API_BASE_URL } from 'common-front/src/consts';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { Feature } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useEventKeyQuery } from '../generated/graphqlHooks';
import { useOrganizationContext } from '../organization/organizationContext';
import { Key } from './key';

export const Keys = () => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();
    const { data, loader } = useEventKeyQuery({ eventId });
    const { hasFeature } = useOrganizationContext();
    const baseUrl = `${API_BASE_URL}export/organization/${organizationId}/event/${eventId}`;
    const getUrl = (pathComponent: string) => `${baseUrl}/${pathComponent}?key=${data.event?.key}`;

    return (
        loader || (
            <Flex direction="column" width={1}>
                <Box color="gray900" fontSize="textLg" fontWeight="medium">
                    {translate('listes_des_cl_s_74703')}
                </Box>

                <Spacer height="4" />

                <Key title={translate('api_pour_les_af_72872')} link={getUrl('assignments')} />

                <Spacer height="6" />

                {hasFeature(Feature.Accreditation) && (
                    <>
                        <Key
                            title={translate('api_pour_les_ac_99569')}
                            link={getUrl('accreditations')}
                        />

                        <Spacer height="6" />
                    </>
                )}

                <Key title={translate('api_pour_les_mi_67453')} link={getUrl('positions')} />

                <Spacer height="6" />

                <Key
                    title={translate('import_des_affe_99331')}
                    link={`=IMPORTDATA("${getUrl('assignments')}")`}
                />

                <Spacer height="6" />

                {hasFeature(Feature.Accreditation) && (
                    <>
                        <Key
                            title={translate('import_des_accr_25702')}
                            link={`=IMPORTDATA("${getUrl('accreditations')}")`}
                        />

                        <Spacer height="6" />
                    </>
                )}

                <Key
                    title={translate('import_des_miss_42639')}
                    link={`=IMPORTDATA("${getUrl('positions')}")`}
                />

                <Spacer height="7" />
            </Flex>
        )
    );
};
