import { MobileLayout } from 'common-front/src/components/mobileLayout/mobileLayout';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useOrganizationContext } from '../../organization/organizationContext';
import { DeleteAccountSheetMobile } from './deleteAccountSheetMobile';
import { OrganizationMainMobile } from './organizationMainMobile';
import { OrganizationSwitchSheetMobile } from './organizationSwitchSheetMobile';
import { ProfileMobile } from './profile/profileMobile';

export const OrganizationParametersMobile = () => {
    const { organization, user } = useOrganizationContext();

    return (
        <>
            <Switch>
                <Route
                    path={HeaventPaths.ORGANIZATION_PARAMETERS_PROFILE(':organizationId')}
                    children={<ProfileMobile />}
                />
                <Route
                    children={
                        <MobileLayout user={user} eventId={organization.events.nodes[0].id}>
                            <OrganizationMainMobile organization={organization} user={user} />
                        </MobileLayout>
                    }
                />
            </Switch>
            <Switch>
                <Route
                    path={HeaventPaths.ORGANIZATION_PARAMETERS_ORGANIZATION_SWITCH(
                        ':organizationId'
                    )}
                    children={<OrganizationSwitchSheetMobile />}
                />
            </Switch>
            <Switch>
                <Route
                    path={HeaventPaths.ORGANIZATION_PARAMETERS_DELETE_ACCOUNT(':organizationId')}
                    children={<DeleteAccountSheetMobile />}
                />
            </Switch>
        </>
    );
};
