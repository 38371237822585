import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventsQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { startAtEndAtToString } from 'common/src/vo/event';
import * as React from 'react';

export const EventProperties = ({
    event
}: {
    event: EventsQuery['organization']['events']['nodes'][0];
}) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);

    return (
        <Box>
            <Flex align="center" css={{ color: '$gray500' }}>
                <Box width={25}>
                    <I icon="calendar" />
                </Box>

                <Box css={{ flex: '1', ellipsis: true }}>
                    {startAtEndAtToString(dateTimeService, event.startAt, event.endAt)}
                </Box>
            </Flex>

            <Spacer height="1" />

            <Flex align="center" css={{ color: '$gray500' }}>
                <Box width={25}>
                    <I icon="user-group" />
                </Box>

                <Box css={{ flex: '1', ellipsis: true }}>
                    {translate('_1_membre_n_ce_47474', event.resources)}
                </Box>
            </Flex>
        </Box>
    );
};
