import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

interface IBadgeVannesA6BackQrCodeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeVannesA6BackQrCode = (props: IBadgeVannesA6BackQrCodeProps) => {
    const accreditations = props.event.volunteerRegistration.accreditationsUsersInfos
        .filter((aui) => aui.accreditationCategory.id === 522)
        .map(({ accreditation }) => accreditation);

    return (
        <Flex
            height={597}
            width={435}
            css={{
                background: `url(https://assets.recrewteer.com/badges/vannes/vannesVersoQrCode.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$montserrat',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        >
            <Flex
                width={1}
                css={{
                    gap: '40px',
                    position: 'absolute',
                    px: '46px',
                    top: '516px'
                }}
            >
                {accreditations.map((accreditation, index) => (
                        <Flex key={index} direction="column" align="center" width={70}>
                            <Box
                                css={{
                                    border: '1px solid white',
                                    borderRadius: '6px',
                                    height: '28px',
                                    width: '28px'
                                }}
                            />

                            <Box
                                css={{
                                    color: 'white',
                                    fontSize: '12px',
                                    fontWeight: '500'
                                }}
                            >
                                {accreditation.name}
                            </Box>
                        </Flex>
                    ))}
            </Flex>
        </Flex>
    );
};
