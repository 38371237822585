import { Blank } from 'common-front/src/components/blank/blank';
import { Svgs } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useParams } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useUserOverlayIntegrationsQuery } from '../../../generated/graphqlHooks';
import { UserOverlayWeezevent } from './userOverlayWeezevent';

export const UserOverlayIntegrations = () => {
    const { eventId, userInfoId } = useParams();
    const { data, loader, reload } = useUserOverlayIntegrationsQuery({
        eventId,
        userInfoId
    });

    return (
        loader || (
            <>
                <Spacer height="6" />

                {data.event.volunteerRegistration.weezeventParticipantInfo ? (
                    <UserOverlayWeezevent
                        eventId={eventId}
                        participantInfo={data.event.volunteerRegistration.weezeventParticipantInfo}
                        volunteerRegistrationId={data.event.volunteerRegistration.id}
                        reload={reload}
                    />
                ) : (
                    <Box height={250} width={1}>
                        <Blank
                            imageSrc={Svgs.Done}
                            title={`${data.event.volunteerRegistration.userInfo.firstName} n'est pas synchronisé pour le moment`}
                        />
                    </Box>
                )}
            </>
        )
    );
};
