import { AvatarWithState } from 'common/src/components/avatarWithState/avatarWithState';
import { Box } from 'common/src/designSystem/components/box';
import { styled } from 'common/src/designSystem/components/stitches';
import { UserInfoMobileQuery } from 'common/src/generated/types';
import * as React from 'react';

const Name = styled(Box, {
    lineHeight: '28px',
    marginTop: '$3',
    maxWidth: '100%',
    ellipsis: ''
});

const Email = styled(Box, {
    lineHeight: '20px',
    maxWidth: '100%',
    ellipsis: ''
});

interface IUserTopInfoProps {
    registration: UserInfoMobileQuery['event']['volunteerRegistration'];
}

export const UserTopInfo = ({ registration }: IUserTopInfoProps) => (
        <>
            <AvatarWithState
                name={registration.userInfo.name}
                email={registration.userInfo.email}
                image={registration.userInfo.picture?.url}
                size={64}
                registrationState={registration.state}
            />
            <Name fontSize="textLg" fontWeight="semiBold" color="gray800">
                {registration.userInfo.name}
            </Name>
            <Email fontSize="textSm" color="gray500">
                {registration.userInfo.email}
            </Email>
        </>
    );
