import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';
import { PrimroseAccreditation } from './primroseAccreditation';

interface IBadgePrimroseProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgePrimrose = (props: IBadgePrimroseProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const auis = vr.accreditationsUsersInfos;
    const acronyms = auis.map(({ accreditation }) => accreditation.acronym);
    const validityAuis = sortBy(
        auis.filter(({ accreditation }) => accreditation.id === 2367),
        ({ accreditationSlot }) => accreditationSlot.date?.toMillis() ?? 0
    );
    const validity =
        validityAuis.length === 8
            ? 'Semaine'
            : validityAuis
                  .map(
                      ({ accreditationSlot }) =>
                          accreditationSlot.date
                              ?.toFormat('ccc', { locale: 'fr' })
                              .substring(0, 3) ?? ''
                  )
                  .join(' / ');

    return (
        <>
            <Flex
                height={559}
                width={397}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/primrose/badge_recto_2.png) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$gothamMedium',
                    position: 'relative'
                }}
            >
                <Box
                    height={118}
                    width={127}
                    css={{
                        borderRadius: '16px',
                        left: '23px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '76px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    css={{
                        color: '#285a2f',
                        fontFamily: '$gothamBlack',
                        fontSize: '20px',
                        fontWeight: '500',
                        left: '150px',
                        position: 'absolute',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        top: '84px',
                        width: 'calc(100% - 150px)'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        color: '#285a2f',
                        fontFamily: '$gothamBlack',
                        fontSize: '20px',
                        fontWeight: '500',
                        left: '150px',
                        position: 'absolute',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        top: '108px',
                        width: 'calc(100% - 150px)'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    css={{
                        color: 'black',
                        fontSize: '16px',
                        fontWeight: '500',
                        left: '150px',
                        position: 'absolute',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        top: '152px',
                        width: 'calc(100% - 150px)'
                    }}
                >
                    {ui.primrosePopulation}
                </Box>

                <Box
                    css={{
                        color: 'black',
                        fontFamily: '$gothamBook',
                        fontSize: '14px',
                        fontWeight: '500',
                        left: '150px',
                        position: 'absolute',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        top: '174px',
                        width: 'calc(100% - 150px)'
                    }}
                >
                    {ui.fields.cf25944}
                </Box>

                <Box
                    css={{
                        color: 'black',
                        fontFamily: '$gothamMedium',
                        fontSize: '20px',
                        fontWeight: '700',
                        left: '0',
                        lineHeight: '22px',
                        margin: '0 42px',
                        position: 'absolute',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        top: '237px',
                        width: 'calc(100% - 114px)'
                    }}
                >
                    {validity}
                </Box>

                <Flex
                    direction="column"
                    css={{
                        gap: '14px',
                        left: '23px',
                        position: 'absolute',
                        top: '327px'
                    }}
                >
                    {acronyms.includes('MATCH') && (
                        <PrimroseAccreditation background="Match.png" name="Match" />
                    )}

                    {acronyms.includes('TECH') && (
                        <PrimroseAccreditation background="Technical.png" name="Technique" />
                    )}

                    {acronyms.includes('ORGA') && (
                        <PrimroseAccreditation background="Orga.png" name="Organisation" />
                    )}

                    {acronyms.includes('PUBLIC') && (
                        <PrimroseAccreditation
                            background="Public.png"
                            name={
                                <>
                                    Village <br />
                                    Grand public
                                </>
                            }
                        />
                    )}
                </Flex>

                <Flex
                    direction="column"
                    css={{
                        gap: '14px',
                        left: '221px',
                        position: 'absolute',
                        top: '327px'
                    }}
                >
                    {acronyms.includes('PART') && (
                        <PrimroseAccreditation
                            background="Partners.png"
                            name={
                                <>
                                    Village
                                    <br />
                                    partenaire
                                </>
                            }
                        />
                    )}

                    {acronyms.includes('JOUEURS') && (
                        <PrimroseAccreditation background="Players.png" name="Joueurs" />
                    )}

                    {acronyms.includes('CAT') && (
                        <PrimroseAccreditation background="Catering.png" name="Restauration" />
                    )}
                </Flex>
            </Flex>

            <Flex
                height={559}
                width={397}
                css={{
                    background: `url(https://assets.recrewteer.com/badges/primrose/badge_verso_2.png) no-repeat`,
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />
        </>
    );
};
