import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CustomBadgeVolunteerRegistrationFragment } from '../../../generated/types';

interface IFiseZoneProps {
    aui: CustomBadgeVolunteerRegistrationFragment['accreditationsUsersInfos'][0];
    num: number;
}

export const FiseZone = (props: IFiseZoneProps) => {
    const accreditation = props.aui.accreditation;

    return (
        <Flex align="center" css={{ gap: '6px' }}>
            <Flex
                align="center"
                justify="center"
                height={22}
                width={38}
                css={{
                    background: accreditation.color,
                    color: 'white',
                    fontFamily: '$paytone',
                    fontSize: '18px',
                    fontWeight: '600'
                }}
            >
                {props.num}
            </Flex>

            <Box
                css={{
                    color: 'black',
                    fontSize: '14px',
                    fontWeight: '600',
                    textTransform: 'capitalize'
                }}
            >
                {accreditation.name}
            </Box>
        </Flex>
    );
};
