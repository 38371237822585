import { IconSelect } from 'common-front/src/designSystem/components/iconSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import {
    AccreditationPositionProperty,
    PositionsPositionFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsIconCellProps {
    firstCellCss: any;
    position: PositionsPositionFragment;

    reload(): void;
}

export const PositionsIconCell = (props: IPositionsIconCellProps) => {
    const { translate } = useHeavent();

    return (
        <PositionsUpdateCell
            cellCss={{
                ...props.firstCellCss,
                flex: '0 100px'
            }}
            initialValue={props.position.icon}
            position={props.position}
            property={AccreditationPositionProperty.Icon}
            reload={props.reload}
            renderInput={(value, setValue) => (
                    <IconSelect
                        label={translate('Icon')}
                        renderOnPortal={true}
                        value={value}
                        onChange={setValue}
                    />
                )}
        >
            <I icon={fromIconVO(props.position.icon)} />
        </PositionsUpdateCell>
    );
};
