import * as React from 'react';
import { Field } from 'react-final-form';
import {
    IRadioSize,
    Radio as RadioComponent,
    RadioText as RadioTextComponent
} from '../components/radio';

interface IRadioProps {
    name: string;
    value: any;
    size?: IRadioSize;
    disabled?: boolean;
}

export const Radio = (props: IRadioProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => {
                const state = props.value === fieldProps.input.value ? 'checked' : 'unchecked';

                return (
                    <RadioComponent
                        size={props.size}
                        disabled={props.disabled}
                        state={state}
                        onClick={() => {
                            fieldProps.input.onChange(props.value);
                        }}
                    />
                );
            }}
        />
    );

interface IRadioTextProps {
    name: string;
    value: any;
    size?: IRadioSize;
    disabled?: boolean;
    children: React.ReactNode;
    subtext?: string;
}

export const RadioText = (props: IRadioTextProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => {
                const state = props.value === fieldProps.input.value ? 'checked' : 'unchecked';

                return (
                    <RadioTextComponent
                        size={props.size}
                        disabled={props.disabled}
                        state={state}
                        subtext={props.subtext}
                        onClick={() => {
                            fieldProps.input.onChange(props.value);
                        }}
                    >
                        {props.children}
                    </RadioTextComponent>
                );
            }}
        />
    );
