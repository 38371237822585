import { Box } from 'common/src/designSystem/components/box';
import {
    AccreditationPositionProperty,
    AccreditationsAccreditationFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsCategoryCellInput } from './accreditationsCategoryCellInput';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsCategoryCellProps {
    accreditation: AccreditationsAccreditationFragment;
    firstCellCss: any;

    reload(): void;
}

export const AccreditationsCategoryCell = (props: IAccreditationsCategoryCellProps) => (
        <AccreditationsUpdateCell
            accreditation={props.accreditation}
            cellCss={props.firstCellCss}
            initialValue={props.accreditation.accreditationCategoryId}
            property={AccreditationPositionProperty.Category}
            reload={props.reload}
            renderInput={(value, setValue) => <AccreditationsCategoryCellInput value={value} setValue={setValue} />}
        >
            <Box
                font="gray900 textSm medium"
                width={1}
                css={{
                    ellipsis: true
                }}
                title={props.accreditation.accreditationCategoryName}
            >
                {props.accreditation.accreditationCategoryName}
            </Box>
        </AccreditationsUpdateCell>
    );
