import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import {
    ALL_SEX,
    EventId,
    FieldType,
    SegmentCustomFieldFragment,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { CountriesService } from 'common/src/services/countriesService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import { USABLE_LANGUAGES } from 'common/src/vo/supportedLanguage';
import * as React from 'react';
import { VolunteerRegistrationUpdateCell } from './volunteerRegistrationUpdateCell';

interface IVolunteerRegistrationSelectCellProps {
    customField: SegmentCustomFieldFragment;
    eventId: EventId;
    field: PossibleColumn;
    firstCellCss: any;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
}

export const VolunteerRegistrationSelectCell = (props: IVolunteerRegistrationSelectCellProps) => {
    const translate = useTranslate();
    const fieldService = useService(FieldService);
    const countriesService = useService(CountriesService);
    const enumToOptions = useEnumToOptions();
    const valueString = fieldService.getValueString(
        props.customField,
        props.volunteerRegistration.userInfo
    );
    const initialValue = React.useMemo(() => {
        const value = fieldService.getValue<any>(
            props.customField,
            props.volunteerRegistration.userInfo
        );

        if (props.customField.canSelectMultiple) {
            return isNonEmptyArray(value) ? value : [];
        } else {
            return value || '';
        }
    }, []);
    const options = React.useMemo(() => {
        if (props.customField.fieldType === FieldType.Country) {
            return countriesService.countriesIdName.map(({ id, name }) => (
                    <option key={id} value={id}>
                        {name}
                    </option>
                ));
        } else if (props.customField.fieldType === FieldType.Language) {
            return enumToOptions(USABLE_LANGUAGES);
        } else if (props.customField.fieldType === FieldType.Nationality) {
            return countriesService.nationalitiesIdName.map(({ id, name }) => (
                    <option key={id} value={id}>
                        {name}
                    </option>
                ));
        } else if (props.customField.fieldType === FieldType.Sex) {
            return enumToOptions(ALL_SEX);
        } else {
            return props.customField.values.map(({ id, value }) => (
                    <option key={id} value={id}>
                        {value}
                    </option>
                ));
        }
    }, [props.customField]);

    return (
        <VolunteerRegistrationUpdateCell
            customField={props.customField}
            eventId={props.eventId}
            field={props.field}
            firstCellCss={props.firstCellCss}
            initialValue={initialValue}
            valueString={valueString}
            volunteerRegistration={props.volunteerRegistration}
            reload={props.reload}
        >
            {(value, setValue) => {
                if (props.customField.canSelectMultiple) {
                    return (
                        <RichSelect
                            multiple={true}
                            label={props.customField.name}
                            renderOnPortal={true}
                            values={value}
                            onChange={setValue}
                        >
                            {options}
                        </RichSelect>
                    );
                } else {
                    return (
                        <Select
                            label={props.customField.name}
                            value={value}
                            onChange={setValue}
                            parseInt={props.customField.fieldType === FieldType.Select}
                            parseBoolean={false}
                        >
                            <option value="" disabled={true}>
                                {translate('choisir_une_val_00277')}
                            </option>

                            {options}
                        </Select>
                    );
                }
            }}
        </VolunteerRegistrationUpdateCell>
    );
};
