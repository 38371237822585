import { Form } from 'common-front/src/components/form/form';
import { DesignInputService, IDesignInputValues } from 'common/src/input/designInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { CreateUpdateDesign } from '../../../../designs/create/createUpdateDesign';
import {
    useFormParametersDesignQuery,
    useFormParametersDesignUpdateMutation
} from '../../../../generated/graphqlHooks';
import { useFormContext } from '../../../show/formContext';

interface IFormParametersDesignInnerProps {
    handleSubmit(): void;
}

const FormParametersDesignInner = (props: IFormParametersDesignInnerProps) => {
    const translate = useTranslate();
    const { isSaving } = useFormContext();

    React.useEffect(() => {
        if (isSaving) {
            props.handleSubmit();
        }
    }, [isSaving]);

    return (
        <CreateUpdateDesign
            prefix="design."
            showLogo={false}
            title={translate('images_de_votre_00576')}
        />
    );
};

export const FormParametersDesign = () => {
    const { eventId, formId, organizationId, setIsSaving } = useFormContext();
    const { data, loader } = useFormParametersDesignQuery({ organizationId, formId });
    const designInput = useService(DesignInputService);
    const { mutate } = useFormParametersDesignUpdateMutation();

    return (
        loader || (
            <Form
                direction="column"
                width={1}
                initialValues={{
                    design: designInput.formParametersDesignInputDefault(
                        data.organization.form.design
                    )
                }}
                onSubmit={async (values: IDesignInputValues) => {
                    await mutate({
                        organizationId,
                        eventId,
                        formId,
                        design: values.design
                    });

                    setIsSaving(false);
                }}
                render={({ handleSubmit }) => <FormParametersDesignInner handleSubmit={handleSubmit} />}
            />
        )
    );
};
