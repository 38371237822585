import * as React from 'react';
import { Flex } from '../../../../designSystem/components/flex';

export const UltraMarinOrganisationFront = () => (
        <Flex
            height={396}
            width={280}
            css={{
                background: `url(https://assets.recrewteer.com/badges/ultraMarin/ultraMarinOrganisationRecto.jpg) no-repeat`,
                backgroundSize: 'cover',
                overflow: 'hidden'
            }}
        />
    );
