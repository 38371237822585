import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { getColorByBackgroundColor } from '../../../util/color';
import { getIconComponent } from '../icon';

interface IPastourelleAccreditationsProps {
    event: DocumentUserCustomBadgeFragment;
}

export const PastourelleAccreditations = (props: IPastourelleAccreditationsProps) => (
        <Flex
            justify="between"
            width={1}
            css={{
                position: 'absolute',
                px: '34px',
                top: '516px'
            }}
        >
            {props.event.volunteerRegistration.accreditationsUsersInfos.map((aui, index) => {
                const Icon = getIconComponent(aui.accreditation.icon);
                const fill = getColorByBackgroundColor(aui.accreditation.color);

                return (
                    <Flex
                        key={index}
                        direction="column"
                        gap="1"
                        align="center"
                        justify="center"
                        width={75}
                    >
                        <Flex
                            align="center"
                            justify="center"
                            height={24}
                            width={24}
                            css={{
                                background: aui.accreditation.color,
                                borderRadius: '$1',
                                '& svg': {
                                    fill,
                                    height: '50%',
                                    width: '50%'
                                }
                            }}
                        >
                            <Icon />
                        </Flex>

                        <Box
                            color="gray800"
                            fontWeight="medium"
                            textAlign="center"
                            css={{ fontSize: '10px' }}
                        >
                            {aui.accreditation.name}
                        </Box>
                    </Flex>
                );
            })}
        </Flex>
    );
