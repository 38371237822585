import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { theme } from 'common/src/designSystem/components/stitches';
import { FilterPredicate } from 'common/src/vo/segment';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { filterTypeToIcon } from '../../vo/customField';

interface IFiltersListProps {
    filtersPredicates: FilterPredicate[];
    isEdit: boolean;

    onAdd?(filterPredicate: FilterPredicate): void;
    onEdit?(filterPredicate: FilterPredicate): void;
    onDelete?(slug: string): void;
}

export const FiltersList = (props: IFiltersListProps) => (
        <Flex
            direction="column"
            css={{
                border: '1px solid $gray200',
                borderRadius: '$1',
                boxShadow: '$xs',
                px: '$4',
                py: '$1'
            }}
        >
            {props.filtersPredicates.map((filterPredicate) => {
                const { filter } = filterPredicate;

                return (
                    <Flex
                        key={filter.slug}
                        align="center"
                        gap="3"
                        css={{
                            py: '$3',
                            '&:not(:last-child)': {
                                borderBottom: '1px solid $gray200'
                            }
                        }}
                    >
                        <Flex
                            align="center"
                            justify="center"
                            css={{
                                borderRadius: '$1',
                                background: theme.colors[`${filter.color}100`].value,
                                color: theme.colors[`${filter.color}700`].value,
                                height: '32px',
                                width: '32px'
                            }}
                        >
                            <I icon={filterTypeToIcon(filter.fieldType)} />
                        </Flex>

                        <Box
                            color="gray900"
                            fontWeight="medium"
                            title={filter.name}
                            css={{
                                ellipsis: '',
                                flex: '1'
                            }}
                        >
                            {filter.name}
                        </Box>

                        {props.isEdit ? (
                            <>
                                <Button
                                    size="sm"
                                    color="white"
                                    leftIcon="pen"
                                    onClick={() => {
                                        props.onEdit!(filterPredicate);
                                    }}
                                />

                                <Button
                                    size="sm"
                                    color="white"
                                    leftIcon="trash-can"
                                    onClick={() => {
                                        props.onDelete!(filter.slug);
                                    }}
                                />
                            </>
                        ) : (
                            <Button
                                size="sm"
                                color="white"
                                leftIcon="plus"
                                onClick={() => {
                                    props.onAdd!(filterPredicate);
                                }}
                            />
                        )}
                    </Flex>
                );
            })}
        </Flex>
    );
