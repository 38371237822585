import { HorizontalSpacerSeparator } from 'common-front/src/designSystem/components/separator';
import { ColorInput } from 'common-front/src/designSystem/form/colorInput';
import { FileS3Input } from 'common-front/src/designSystem/form/file/fileS3Input';
import { ImageInput } from 'common-front/src/designSystem/form/image/imageInput';
import { Accept } from 'common-front/src/util/accept';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { theme } from 'common/src/designSystem/components/stitches';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface ICreateUpdateDesignProps {
    prefix: string;
    showLogo: boolean;
    title: string;
}

export const CreateUpdateDesign = (props: ICreateUpdateDesignProps) => {
    const translate = useTranslate();

    return (
        <>
            <Box color="gray900" fontSize="textLg" fontWeight="medium">
                {props.title}
            </Box>

            {props.showLogo && (
                <>
                    <HorizontalSpacerSeparator height="5" />

                    <Flex gap="7">
                        <Box color="gray700" fontWeight="medium" width={300}>
                            {translate('logo_18191')}
                        </Box>

                        <ImageInput
                            label={translate('s_lectionnez_un_82734')}
                            hint={translate('nous_recommando_20904')}
                            prefix={`${props.prefix}logo.`}
                        />
                    </Flex>
                </>
            )}

            <HorizontalSpacerSeparator height="5" />

            <Flex gap="7">
                <Box color="gray700" fontWeight="medium" width={300}>
                    {translate('banni_re_86634')}
                </Box>

                <FileS3Input
                    accept={Accept.Images}
                    acl="public-read"
                    hint={translate('taille_de_l_ima_84109')}
                    prefix={`${props.prefix}banner.`}
                />
            </Flex>

            <Spacer height="10" />

            <Box color="gray900" fontSize="textLg" fontWeight="medium">
                {translate('charte_graphiqu_02725')}
            </Box>

            <HorizontalSpacerSeparator height="5" />

            <Flex gap="7">
                <Box color="gray700" fontWeight="medium" width={300}>
                    {translate('couleur_de_fond_75677')}
                </Box>

                <Flex direction="column" gap="4" css={{ flex: '1' }}>
                    <ColorInput
                        label={translate('couleur_de_fond_75677')}
                        name={`${props.prefix}colors.gray50`}
                        initialValue={theme.colors.gray50.value}
                    />
                </Flex>
            </Flex>

            <HorizontalSpacerSeparator height="5" />

            <Flex gap="7">
                <Box color="gray700" fontWeight="medium" width={300}>
                    {translate('textes_07935')}
                </Box>

                <Flex direction="column" gap="4" css={{ flex: '1' }}>
                    <ColorInput
                        label={translate('couleur_du_text_32021')}
                        name={`${props.prefix}colors.gray800`}
                        initialValue={theme.colors.gray800.value}
                    />

                    <ColorInput
                        label={translate('couleur_des_tit_58918')}
                        name={`${props.prefix}colors.gray900`}
                        initialValue={theme.colors.gray900.value}
                    />

                    <ColorInput
                        label={translate('couleur_des_sou_18968')}
                        name={`${props.prefix}colors.gray500`}
                        initialValue={theme.colors.gray500.value}
                    />

                    <ColorInput
                        label={'Couleur des labels'}
                        name={`${props.prefix}colors.gray700`}
                        initialValue={theme.colors.gray700.value}
                    />
                </Flex>
            </Flex>

            <HorizontalSpacerSeparator height="5" />

            <Flex gap="7">
                <Box color="gray700" fontWeight="medium" width={300}>
                    {translate('boutons_35604')}
                </Box>

                <Flex direction="column" gap="4" css={{ flex: '1' }}>
                    <ColorInput
                        label={translate('couleur_des_bou_24410')}
                        name={`${props.prefix}colors.primary700`}
                        initialValue={theme.colors.primary700.value}
                    />

                    <ColorInput
                        label={translate('couleur_des_bou_16515')}
                        name={`${props.prefix}colors.primary800`}
                        initialValue={theme.colors.primary800.value}
                    />
                </Flex>
            </Flex>
        </>
    );
};
