import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import { DelegationsPaths } from 'common/src/delegationsPaths';
import {
    CommunityDelegationsSegmentsQuery,
    DelegationId,
    SegmentId,
    SegmentType
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { DelegationsSegmentsService } from 'common/src/vo/segments/delegationsSegmentsService';
import * as React from 'react';
import { DelegationsContextProvider } from '../../../delegations/list/delegationsContext';
import { useOrganizationContext } from '../../../organization/organizationContext';
import { CommunityDelegationsContent } from './communityDelegationsContent';

interface ICommunityDelegationsProps {
    organization: CommunityDelegationsSegmentsQuery['organization'];

    reload(): void;
}

export const CommunityDelegations = (props: ICommunityDelegationsProps) => {
    const {
        history,
        translate,
        params: { organizationId, segmentId }
    } = useHeavent();
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const segmentsService = useService(DelegationsSegmentsService);

    return (
        <DelegationsContextProvider
            eventId={null}
            organizationId={organizationId}
            getEditPath={(id: DelegationId) => DelegationsPaths.DELEGATION_EDIT({ organizationId, delegationId: id })}
            getShowPath={(id: DelegationId) => DelegationsPaths.DELEGATION({ organizationId, delegationId: id })}
        >
            <SegmentsGrid
                filters={segmentsService.getDelegationsFilters(organizationId)}
                filtersButtonText={translate('filtrer_les_d_l_04628')}
                filtersSubtitle={translate('appliquer_des_f_65318')}
                initialLimit={localOrganization?.delegationsLimit ?? DEFAULT_PAGINATION_LIMIT}
                initialSegmentsOpen={localOrganization?.areDelegationsSegmentsOpen ?? true}
                isAdmin={true}
                organizationId={organizationId}
                possibleColumns={segmentsService.getDelegationsPossibleColumns()}
                segmentId={segmentId}
                segmentType={SegmentType.Delegations}
                segmentsFolders={props.organization.segmentsFolders}
                getSegmentPath={(id: SegmentId) => HeaventPaths.COMMUNITY_DELEGATIONS_SEGMENT(organizationId, id)}
                onSegmentClick={(selectedSegmentId) => {
                    updateLocalOrganization({ delegationsSegmentId: selectedSegmentId });
                }}
                onSegmentDelete={async () => {
                    await updateLocalOrganization({ delegationsSegmentId: null });

                    history.push(HeaventPaths.COMMUNITY_DELEGATIONS(organizationId));
                }}
                onSegmentsToggle={async (areSegmentsOpen) => {
                    await updateLocalOrganization({ areDelegationsSegmentsOpen: areSegmentsOpen });
                }}
                reload={props.reload}
                setLimit={(delegationsLimit) => {
                    updateLocalOrganization({ delegationsLimit });
                }}
            >
                <CommunityDelegationsContent
                    numberOfDelegations={props.organization.delegations.totalCount}
                />
            </SegmentsGrid>
        </DelegationsContextProvider>
    );
};
