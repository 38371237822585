import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { OnboardingView } from 'common-front/src/components/onboardingView/onboardingView';
import { Button } from 'common-front/src/designSystem/components/button';
import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useUserContext } from 'common-front/src/userContext';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import { HeaventEnv } from 'common/src/heaventEnv';
import {
    ICreateOrganizationValues,
    OrganizationInputService
} from 'common/src/input/organizationInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useOrganizationCreateMutation } from '../../generated/graphqlHooks';
import { OrganizationForm } from './organizationForm';
import { TermsForm } from './termsForm';

export const CreateOrganization = () => {
    const { translate, history } = useHeavent();
    const organizationInput = useService(OrganizationInputService);
    const validateService = useService(ValidateService);
    const { mutate } = useOrganizationCreateMutation({ redirectOnSuccess: true });
    const onboardingViewRef = React.useRef<HTMLDivElement | null>(null);
    const termsAccepted = !!useUserContext().user?.termsAcceptedAt;

    return (
        <Form
            height={1}
            width={1}
            hideErrors={true}
            initialValues={{
                organization: organizationInput.organizationInputDefault()
            }}
            validate={validateService.validateForForm(
                organizationInput.createOrganizationSchema(termsAccepted)
            )}
            onSubmit={async (values: ICreateOrganizationValues) => {
                const {
                    organizationCreate: { id }
                } = await mutate({ organization: values.organization });

                history.push(HeaventPaths.ORGANIZATION(id));
            }}
            onShowErrors={() => {
                if (onboardingViewRef.current) {
                    onboardingViewRef.current.scrollTop = 0;
                }
            }}
            render={({ handleSubmit }) => (
                    <OnboardingView
                        ref={onboardingViewRef}
                        onClose={() => {
                            history.push(HeaventPaths.HOME);
                        }}
                    >
                        <FormErrors />

                        <FormBox
                            hideToggle={true}
                            initialIsOpen={true}
                            subtitle={translate('ajouter_le_nom_69919')}
                            title={translate('_propos_de_vot_88855')}
                            centerTitleAndSubtitle={true}
                        >
                            <OrganizationForm />
                        </FormBox>

                        <FormBox>
                            {!termsAccepted &&
                                CommonEnvVars.HEAVENT_ENV === HeaventEnv.Recrewteer && (
                                    <>
                                        <TermsForm />
                                        <Spacer height="4" />
                                    </>
                                )}
                            <Button onClick={handleSubmit} textAlign="center">
                                {translate('cr_er_l_organis_55358')}
                            </Button>
                        </FormBox>
                    </OnboardingView>
                )}
        />
    );
};
