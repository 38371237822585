import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { BadgeVannesA6Back } from './badgeVannesA6Back';
import { BadgeVannesA6BackQrCode } from './badgeVannesA6BackQrCode';
import { BadgeVannesA6Front } from './badgeVannesA6Front';

interface IBadgeVannesA6Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeVannesA6 = (props: IBadgeVannesA6Props) => {
    const userInfo = props.event.volunteerRegistration.userInfo;

    return (
        <>
            <BadgeVannesA6Front event={props.event} />

            {userInfo.vannesStatut === 'ORGANISATION' ||
            userInfo.vannesStatut === 'BENEVOLES' ||
            userInfo.vannesStatut === 'MEDICAL' ? (
                <BadgeVannesA6Back event={props.event} />
            ) : (
                <BadgeVannesA6BackQrCode event={props.event} />
            )}
        </>
    );
};
