import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IVandbInvitationProps {
    event: DocumentUserCustomBadgeFragment;
}

export const VandbInvitation = (props: IVandbInvitationProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/vandb/bg_invitation_2.jpg) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Flex
                align="center"
                justify="between"
                width={A4_SIZES['96dpi'].width}
                css={{
                    position: 'absolute',
                    px: '163px',
                    top: '152px'
                }}
            >
                <Flex
                    direction="column"
                    css={{
                        color: '#103725',
                        fontFamily: '$badTyp',
                        fontSize: '22px',
                        fontWeight: '500'
                    }}
                >
                    <Box>{ui.firstName}</Box>
                    <Box>{ui.lastName}</Box>
                </Flex>

                <Box height={90} width={90}>
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=90&width=90`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};
