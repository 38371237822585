import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { Templates } from './templates/templates';

export const Emails = () => {
    const translate = useTranslate();
    const { organizationId, eventId } = useParams();

    return (
        <Flex direction="column" width={1} css={{ padding: '$7' }}>
            <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                {translate('mod_les_e_mail_91257')}
            </Box>

            <Spacer height="7" />

            <Templates organizationId={organizationId} eventId={eventId} />
        </Flex>
    );
};
