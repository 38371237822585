import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    AccreditationPositionProperty,
    AccreditationsAccreditationFragment
} from 'common/src/generated/types';
import * as React from 'react';
import { AccreditationsUpdateCell } from './accreditationsUpdateCell';

interface IAccreditationsAcronymCellProps {
    accreditation: AccreditationsAccreditationFragment;
    firstCellCss: any;

    reload(): void;
}

export const AccreditationsAcronymCell = (props: IAccreditationsAcronymCellProps) => {
    const { translate } = useHeavent();

    return (
        <AccreditationsUpdateCell
            accreditation={props.accreditation}
            cellCss={props.firstCellCss}
            initialValue={props.accreditation.acronym || ''}
            property={AccreditationPositionProperty.Acronym}
            reload={props.reload}
            renderInput={(value, setValue) => <TextInput label={translate('Acronym')} value={value} onChange={setValue} />}
        >
            {props.accreditation.acronym}
        </AccreditationsUpdateCell>
    );
};
