import { closeOtherDropdowns } from 'common-front/src/designSystem/components/dropdown/dropdown';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    CampaignId,
    CampaignsQuery,
    CampaignState,
    CampaignType,
    EventId,
    OrganizationId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { LocaleFormats } from 'common/src/util/luxon';
import { DateTime } from 'luxon';
import * as React from 'react';
import { CampaignRowDropdown } from './campaignRowDropdown';

interface ICampaignRowProps {
    campaign: CampaignsQuery['event']['campaigns']['nodes'][0];
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;

    getCampaignPath(id: CampaignId): string;
    getCampaignEditPath(id: CampaignId): string;
    reload(): void;
}

export const CampaignRow = (props: ICampaignRowProps) => {
    const { history, translate } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const sentAt = props.campaign.sentAt?.setZone(DateTime.local().zone);

    return (
        <Row
            css={{
                cursor: 'pointer',
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onClick={() => {
                history.push(props.getCampaignPath(props.campaign.id));
            }}
            onMouseLeave={(e) => {
                closeOtherDropdowns(e.target);
            }}
        >
            <Cell>
                <Flex direction="column" width={1}>
                    <Box color="gray800" css={{ ellipsis: true }} title={props.campaign.name}>
                        {props.campaign.name}
                    </Box>

                    {props.campaign.state === CampaignState.Done && sentAt && (
                        <Box color="gray500">
                            {translate(
                                'envoy_e_le_1_90336',
                                dateTimeService.toLocaleString(sentAt, LocaleFormats.DateTime),
                                props.campaign.createdBy?.nameOrEmail ?? ''
                            )}
                        </Box>
                    )}

                    {props.campaign.state === CampaignState.Scheduled &&
                        props.campaign.scheduledAt?.isValid && (
                            <Box color="gray500">
                                {translate(
                                    'programm_e_le_58279',
                                    dateTimeService.toLocaleString(
                                        props.campaign.scheduledAt,
                                        LocaleFormats.DateOnly.MonthLong
                                    ),
                                    dateTimeService.toLocaleString(
                                        props.campaign.scheduledAt,
                                        LocaleFormats.TimeOnly
                                    )
                                )}
                            </Box>
                        )}
                </Flex>
            </Cell>
            <Cell css={{ flex: '0 150px' }}>
                <Box width={22}>
                    {props.campaign.campaignType === CampaignType.Email ? (
                        <I icon="at" />
                    ) : (
                        <I icon="message" />
                    )}
                </Box>

                {props.campaign.campaignType}
            </Cell>
            <Cell css={{ flex: '0 200px', justifyContent: 'flex-end' }}>
                {props.campaign.numberOfRecipients}
            </Cell>
            <Cell css={{ flex: '0 150px', justifyContent: 'flex-end' }}>
                {props.campaign.campaignType === CampaignType.Email
                    ? translate('gratuit_13712')
                    : `${props.campaign.cost} €`}
            </Cell>
            <Cell css={{ flex: '0 150px' }}>
                {props.campaign.state === CampaignState.Done ? (
                    <Badge color="success" leftIcon="check">
                        {translate(props.campaign.state)}
                    </Badge>
                ) : props.campaign.state === CampaignState.InProgress ? (
                    <Badge color="warning" leftIcon="hourglass-empty">
                        {translate(props.campaign.state)}
                    </Badge>
                ) : props.campaign.state === CampaignState.Scheduled ? (
                    <Badge color="primary" leftIcon="calendar">
                        {translate(props.campaign.state)}
                    </Badge>
                ) : (
                    <Badge color="gray" leftIcon="pen-ruler">
                        {translate(props.campaign.state)}
                    </Badge>
                )}
            </Cell>
            <CellControls justify="end">
                {(props.campaign.state === CampaignState.Draft ||
                    props.campaign.state === CampaignState.Scheduled) && (
                    <Tooltip>
                        <Trigger>
                            <Box>
                                <I
                                    icon="pen"
                                    onClick={() => {
                                        history.push(props.getCampaignEditPath(props.campaign.id));
                                    }}
                                />
                            </Box>
                        </Trigger>

                        <Content placement="top">{translate('_diter_62574')}</Content>
                    </Tooltip>
                )}

                <CampaignRowDropdown
                    campaign={props.campaign}
                    eventId={props.eventId}
                    organizationId={props.organizationId}
                    getCampaignEditPath={props.getCampaignEditPath}
                    reload={props.reload}
                />
            </CellControls>
        </Row>
    );
};
