import { Empty } from 'common-front/src/components/empty/empty';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useCustomFieldsSegmentsQuery } from '../../generated/graphqlHooks';
import { useOrganizationContext } from '../../organization/organizationContext';
import { CustomFields } from './customFields';

export const CustomFieldsLoader = () => {
    const {
        params: { organizationId }
    } = useHeavent();
    const { localOrganization } = useOrganizationContext();
    const { data, loader, reload } = useCustomFieldsSegmentsQuery({
        organizationId,
        predicates: [
            {
                operator: 'is',
                slug: 'condition',
                value: []
            }
        ]
    });
    const segmentsIds = React.useMemo(() => (data.organization?.segmentsFolders ?? [])
            .flatMap((sf) => sf.segments)
            .map((s) => s.id), [data.organization]);

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={HeaventPaths.CUSTOM_FIELDS_SEGMENT(':organizationId', ':segmentId')}
                    children={<CustomFields organization={data.organization} reload={reload} />}
                />

                <Route
                    children={
                        <Empty
                            path={HeaventPaths.CUSTOM_FIELDS_SEGMENT(
                                organizationId,
                                localOrganization?.customFieldsSegmentId &&
                                    segmentsIds.includes(localOrganization.customFieldsSegmentId)
                                    ? localOrganization.customFieldsSegmentId
                                    : segmentsIds[0]
                            )}
                            replace={true}
                        />
                    }
                />
            </Switch>
        );
    }
};
