import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { merge } from 'lodash-es';
import * as React from 'react';
import { useOrganizationContext } from '../organization/organizationContext';

interface IOrganizationParametersMenuSectionProps {
    children: React.ReactNode;
    name: string;
    title: string;
}

export const OrganizationParametersMenuSection = (
    props: IOrganizationParametersMenuSectionProps
) => {
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const [isOpen, _setIsOpen] = React.useState(
        localOrganization?.areParametersMenuSectionsOpen?.[props.name] ?? true
    );
    const setIsOpen = React.useCallback(
        async (newIsOpen: boolean) => {
            _setIsOpen(newIsOpen);

            await updateLocalOrganization({
                areParametersMenuSectionsOpen: merge(
                    localOrganization?.areParametersMenuSectionsOpen,
                    {
                        [props.name]: newIsOpen
                    }
                )
            });
        },
        [localOrganization, isOpen, _setIsOpen]
    );

    return (
        <Flex
            direction="column"
            css={{
                borderBottom: '1px solid $gray200',
                paddingBottom: '$1',
                paddingTop: '$4'
            }}
        >
            <Flex
                align="center"
                gap="3"
                css={{
                    color: '$gray800',
                    cursor: 'pointer',
                    px: '$5'
                }}
                onClick={async () => {
                    await setIsOpen(!isOpen);
                }}
            >
                <Box fontSize="textXs" fontWeight="semiBold" css={{ textTransform: 'uppercase' }}>
                    {props.title}
                </Box>

                <Box css={{ fontSize: '10px' }}>
                    <I icon={isOpen ? 'chevron-down' : 'chevron-up'} />
                </Box>
            </Flex>

            {isOpen && (
                <>
                    <Spacer height="2" />

                    <Flex direction="column" css={{ px: '$3' }}>
                        {props.children}
                    </Flex>
                </>
            )}
        </Flex>
    );
};
