import { Label } from 'common-front/src/designSystem/components/input/label';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { usePromise } from 'common-front/src/hooks/usePromise';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { usePositionsContext } from '../../positionsContext';

interface IPositionsCategoryCellInputProps {
    value: number;

    setValue(value: number): void;
}

export const PositionsCategoryCellInput = (props: IPositionsCategoryCellInputProps) => {
    const translate = useTranslate();
    const { getPositionsCategories } = usePositionsContext();
    const { result: positionsCategories, isLoading } = usePromise(getPositionsCategories);

    return isLoading ? (
        <Flex direction="column" gap="1" width={1}>
            <Label>{translate('Category')}</Label>
            <Skeleton height={38} width={1} borderRadius="$1" />
        </Flex>
    ) : (
        <Select
            label={translate('Category')}
            value={props.value}
            onChange={props.setValue}
            parseInt={true}
        >
            {positionsCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                        {category.name}
                    </option>
                ))}
        </Select>
    );
};
