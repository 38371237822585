import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CampaignOrganizationUsersRecipientsQuery } from 'common/src/generated/types';
import * as React from 'react';
import { executeCampaignOrganizationUsersRecipientsQuery } from '../../../generated/graphqlHooks';

interface ICampaignOrganizationUsersRecipientsProps {
    recipients: string;
}

export const CampaignOrganizationUsersRecipients = (
    props: ICampaignOrganizationUsersRecipientsProps
) => {
    const {
        translate,
        params: { organizationId }
    } = useHeavent();
    const [isFirstTime, setIsFirstTime] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [usersInfos, setUsersInfos] = React.useState<
        CampaignOrganizationUsersRecipientsQuery['organization']['usersInfos']['nodes']
    >([]);

    React.useEffect(() => {
        (async () => {
            if (props.recipients === 'users' && isFirstTime) {
                const data = await executeCampaignOrganizationUsersRecipientsQuery(
                    { organizationId },
                    await getToken()
                );

                setUsersInfos(data.organization.usersInfos.nodes);
                setIsFirstTime(false);
                setIsLoading(false);
            }
        })();
    }, [props.recipients, isFirstTime]);

    if (props.recipients === 'users') {
        return (
            <>
                <Spacer height="2" />

                <Box css={{ marginLeft: '$6' }}>
                    {isLoading ? (
                        <Skeleton height={40} width={1} borderRadius="$1" />
                    ) : (
                        <RichSelect
                            isSearchVisible={true}
                            multiple={true}
                            name="campaign.usersInfosIds"
                            placeholder={translate('ajouter_des_mem_58427')}
                        >
                            {usersInfos.map((userInfo) => (
                                    <option key={userInfo.id} value={userInfo.id}>
                                        {userInfo.nameOrEmail}
                                    </option>
                                ))}
                        </RichSelect>
                    )}
                </Box>
            </>
        );
    } else {
        return null;
    }
};
