import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

export const UserOverlayHeaderSkeleton = () => (
        <Flex direction="column" align="center">
            <Skeleton variant="circular" size={96} />

            <Spacer height="6" />

            <Skeleton height={34} width={0.5} borderRadius="$1" />

            <Spacer height="1" />

            <Skeleton height={20} width={0.5} borderRadius="$1" />
        </Flex>
    );
