import { compact, sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const getBackground = (population: string) => {
    if (population === 'Pro / Partenaire') {
        return 'https://assets.recrewteer.com/badges/jardinSonore/jardinSonoreContremarquePartenaire_v3.jpg';
    } else {
        return 'https://assets.recrewteer.com/badges/jardinSonore/jardinSonoreContremarquePress_v3.jpg';
    }
};

interface IContremarqueJardinSonorePartenaireProps {
    event: DocumentUserCustomBadgeFragment;
}

export const ContremarqueJardinSonorePartenaire = (
    props: IContremarqueJardinSonorePartenaireProps
) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const accreditationsIds: number[] = vr.accreditationsUsersInfos.map(
        (aui) => aui.accreditation.id
    );
    const days = sortBy(
        vr.accreditationsUsersInfos.flatMap((aui) => {
            if (aui.accreditation.id === 3039) {
                return [aui.accreditationSlot.date!];
            } else {
                return [];
            }
        }),
        (d) => d.toMillis()
    );
    const access = compact([
        accreditationsIds.includes(2362) ? 'VIP' : null,
        accreditationsIds.includes(3047) ? 'Presse' : null
    ]).join(', ');

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(${getBackground(ui.jardinSonorePopulation)}) no-repeat`,
                backgroundSize: 'cover',
                color: 'black',
                fontFamily: '$dinCondensed',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    fontSize: '24px',
                    fontWeight: '700',
                    left: '125px',
                    lineHeight: '28px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '283px'
                }}
            >
                {ui.name}
            </Box>

            <Box
                css={{
                    fontFamily: '$dinCondensed',
                    fontSize: '18px',
                    fontWeight: '500',
                    left: ui.jardinSonorePopulation === 'Pro / Partenaire' ? '163px' : '213px',
                    lineHeight: '26px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '311px'
                }}
            >
                {ui.jardinSonorePopulation === 'Pro / Partenaire'
                    ? ui.jardinSonoreStructure
                    : ui.jardinSonoreNomDuMedia}
            </Box>

            <Box
                css={{
                    fontFamily: '$dinCondensed',
                    fontSize: '18px',
                    fontWeight: '500',
                    left: '224px',
                    lineHeight: '26px',
                    position: 'absolute',
                    top: '337px'
                }}
            >
                {access}
            </Box>

            <Flex
                direction="column"
                align="center"
                gap="1"
                css={{
                    position: 'absolute',
                    right: '125px',
                    top: '283px'
                }}
            >
                <Box height={79} width={79}>
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=79&width=79`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>

                <Box css={{ fontSize: '12px' }}>{vr.weezeventParticipantInfo?.idBarcode ?? ''}</Box>
            </Flex>

            <Flex
                css={{
                    gap: '6px',
                    left: '125px',
                    position: 'absolute',
                    top: '488px'
                }}
            >
                {days.map((day, index) => (
                        <Flex
                            key={index}
                            direction="column"
                            css={{
                                border: '1px solid black',
                                borderRadius: '4px',
                                fontSize: '16px',
                                fontWeight: '700',
                                lineHeight: '20px',
                                padding: '4px 8px'
                            }}
                        >
                            <Box textAlign="center">{day.toFormat('dd')}</Box>
                            <Box textAlign="center">{'JUIL.'}</Box>
                        </Flex>
                    ))}
            </Flex>
        </Flex>
    );
};
