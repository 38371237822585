import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import * as React from 'react';

export const AccreditAssignWaitingVolunteerSkeleton = () => (
        <Flex
            align="center"
            gap="3"
            css={{
                borderBottom: '1px solid $gray200',
                padding: '$4'
            }}
        >
            <Skeleton variant="circular" size={40} />

            <Flex direction="column" gap="1" css={{ flex: '1' }}>
                <Skeleton height={20} width={1} borderRadius="$1" />
                <Skeleton height={20} width={1} borderRadius="$1" />
            </Flex>
        </Flex>
    );
