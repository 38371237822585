import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { Button } from 'common-front/src/designSystem/components/button';
import { FileInput, FileInputValue } from 'common-front/src/designSystem/components/file/fileInput';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { ColorInput } from 'common-front/src/designSystem/form/colorInput';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Accept } from 'common-front/src/util/accept';
import { parseGpx } from 'common-front/src/util/gpx';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, TraceId, TraceInput } from 'common/src/generated/types';
import { ICreateTraceValues, TraceInputService } from 'common/src/input/traceInput';
import { ValidateService } from 'common/src/services/validateService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { slug } from 'common/src/util/string';
import * as React from 'react';
import { useTraceCreateMutation, useTraceUpdateMutation } from '../../../generated/graphqlHooks';

interface IAddTraceProps {
    eventId: EventId;
    trace?: TraceInput;
    traceId?: TraceId;

    onClose(): void;
    reload(): void;
}

export const AddTrace = (props: IAddTraceProps) => {
    const translate = useTranslate();
    const traceInput = useService(TraceInputService);
    const validateService = useService(ValidateService);
    const [file, setFile] = React.useState<FileInputValue | undefined>({
        name: props.trace ? `${slug(props.trace.name)}.gpx` : '',
        content: ''
    });
    const { mutate: create, isLoading: createLoading } = useTraceCreateMutation();
    const { mutate: update, isLoading: updateLoading } = useTraceUpdateMutation();

    return (
        <Form
            hideErrors={true}
            initialValues={{
                trace: traceInput.traceInputDefault(props.trace)
            }}
            validate={validateService.validateForForm(traceInput.createTraceSchema())}
            onSubmit={async (values: ICreateTraceValues) => {
                if (props.traceId) {
                    await update({
                        eventId: props.eventId,
                        traceId: props.traceId,
                        trace: values.trace
                    });
                } else {
                    await create({
                        eventId: props.eventId,
                        trace: values.trace
                    });
                }

                props.onClose();
                props.reload();
            }}
            render={({ form, handleSubmit }) => (
                    <Modal size="md" onClose={props.onClose}>
                        <Box color="primary600" fontSize="displayXs" textAlign="center">
                            <I icon="route" />
                        </Box>

                        <Spacer height="7" />

                        <Box color="gray900" fontSize="textLg" textAlign="center">
                            {translate('ajouter_un_trac_05425')}
                        </Box>

                        <Spacer height="7" />

                        <FormErrors />

                        <TextInput
                            name="trace.name"
                            label={translate('nom_du_trac_16379')}
                            placeholder={translate('nom_du_trac_16379')}
                        />

                        <Spacer height="4" />

                        <FileInput
                            label={translate('fichier_gpx_55484')}
                            placeholder={translate('s_lectionnez_un_17918')}
                            accept={Accept.Gpx}
                            format="string"
                            value={file}
                            onChange={(newFile) => {
                                setFile(newFile);

                                form.change('trace.points', parseGpx(newFile.content));
                            }}
                        />

                        <Spacer height="4" />

                        <ColorInput name="trace.color" label={translate('couleur_du_trac_28854')} />

                        <Spacer height="7" />

                        <Flex justify="end" gap="3">
                            <Button
                                isLoading={createLoading || updateLoading}
                                onClick={handleSubmit}
                            >
                                {props.traceId
                                    ? translate('mettre_jour_85141')
                                    : translate('ajouter_97327')}
                            </Button>

                            <Button color="white" onClick={props.onClose}>
                                {translate('annuler_48254')}
                            </Button>
                        </Flex>
                    </Modal>
                )}
        />
    );
};
