import * as React from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../components/reactFinalForm/isInvalid';
import { FloatInput as FloatInputComponent } from '../components/floatInput';
import { BaseInputProps } from '../components/input/baseInputProps';

interface IFloatInputProps extends BaseInputProps {
    name: string;
}

export const FloatInput = (props: IFloatInputProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => {
                const invalid = isInvalid(fieldProps.meta);

                return (
                    <FloatInputComponent
                        {...props}
                        hint={invalid ? fieldProps.meta.error : props.hint}
                        state={invalid ? 'error' : props.state}
                        value={fieldProps.input.value}
                        onChange={fieldProps.input.onChange}
                        onBlur={fieldProps.input.onBlur}
                        onFocus={fieldProps.input.onFocus}
                    />
                );
            }}
        />
    );
