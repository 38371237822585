import * as React from 'react';
import { Field } from 'react-final-form';
import { isInvalid } from '../../components/reactFinalForm/isInvalid';
import { BaseInputProps } from '../components/input/baseInputProps';
import { PasswordInput as PasswordInputComponent } from '../components/passwordInput';
import { AutoComplete } from '../components/textInput';

interface IPasswordInputProps extends BaseInputProps {
    name: string;
    autoComplete?: AutoComplete;

    onForgotPasswordClick?(): void;
}

export const PasswordInput = (props: IPasswordInputProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => {
                const invalid = isInvalid(fieldProps.meta);

                return (
                    <PasswordInputComponent
                        autoComplete={props.autoComplete ?? 'current-password'}
                        description={props.description}
                        hint={invalid ? fieldProps.meta.error : props.hint}
                        icon={props.icon}
                        label={props.label}
                        placeholder={props.placeholder}
                        state={invalid ? 'error' : props.state}
                        value={fieldProps.input.value}
                        onChange={(value) => {
                            fieldProps.input.onChange(value);
                        }}
                        onBlur={fieldProps.input.onBlur}
                        onFocus={fieldProps.input.onFocus}
                        onForgotPasswordClick={props.onForgotPasswordClick}
                    />
                );
            }}
        />
    );
