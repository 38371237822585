import {
    LimitSource,
    LimitSourceAccreditationIcon
} from 'common-front/src/accreditAssign/accreditationLimitSource';
import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    AccreditationsSlotId,
    MassAccreditAccreditationsQuery,
    MassAssignStrategy
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { NumberService } from 'common/src/services/numberService';
import { addOrRemove } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';

interface IMassAccreditSlotRowProps {
    accreditationsSlotsIds: AccreditationsSlotId[];
    numberOfSelected: number;
    slot: MassAccreditAccreditationsQuery['event']['accreditationsSlots']['nodes'][0];
    strategy: MassAssignStrategy;

    change(name: string, value: any): void;
}

export const MassAccreditSlotRow = ({
    accreditationsSlotsIds,
    change,
    numberOfSelected,
    slot,
    strategy
}: IMassAccreditSlotRowProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const numberService = useService(NumberService);

    const isChecked = React.useMemo(
        () => accreditationsSlotsIds.includes(slot.id),
        [accreditationsSlotsIds]
    );

    const numberForSlot = isChecked
        ? strategy === MassAssignStrategy.Add
            ? slot.assignedResources + numberOfSelected
            : strategy === MassAssignStrategy.Replace
            ? slot.assignedResources - slot.assignedResourcesForUsers + numberOfSelected
            : slot.assignedResources - slot.assignedResourcesForUsers
        : strategy === MassAssignStrategy.Replace
        ? slot.assignedResources - slot.assignedResourcesForUsers
        : slot.assignedResources;
    const isMaxedOut = slot.assignedResources >= (slot.maxResources ?? Infinity);
    const isEmpty = slot.assignedResources === 0;

    return (
        <Row>
            <Cell width={48} css={{ paddingRight: 0 }}>
                <Checkbox
                    disabled={
                        strategy === MassAssignStrategy.Add
                            ? isMaxedOut
                            : strategy === MassAssignStrategy.Replace
                            ? false
                            : isEmpty
                    }
                    state={isChecked ? 'checked' : 'unchecked'}
                    onClick={(state) => {
                        change(
                            'massAccredit.accreditationsSlotsIds',
                            addOrRemove(accreditationsSlotsIds, slot.id, state === 'checked')
                        );
                    }}
                />
            </Cell>

            <Cell direction="column" gap="1" align="start" justify="center">
                <Box font="gray900 textSm medium">{slot.accreditation.name}</Box>
                <Box color="gray500">{slot.accreditationCategory.name}</Box>
            </Cell>

            <Cell direction="column" gap="1" align="start" justify="center">
                <Box color="gray900">{slot.name}</Box>
                {slot.date
                    ? dateTimeService.toLocaleString(
                          slot.date,
                          LocaleFormats.DateOnly.WeekdayLongMonthLong
                      )
                    : '-'}
            </Cell>

            <Cell>
                <Badge
                    leftIcon="user-group"
                    color={
                        numberForSlot < (slot.maxResources ?? Infinity)
                            ? 'success'
                            : numberForSlot === slot.maxResources
                            ? 'warning'
                            : 'error'
                    }
                >
                    {translate(
                        '_1_2_membres_04485',
                        numberForSlot,
                        numberService.toNumberOrInfinity(slot.maxResources)
                    )}
                    {slot?.maxResources && (
                        // Temporary until we figure out how to handle delegation accreditation limits here
                        <>
                            &nbsp;[
                            <LimitSourceAccreditationIcon limitSource={LimitSource.Slot} />]
                        </>
                    )}
                </Badge>
            </Cell>
        </Row>
    );
};
