import { chunk, range, sortBy, uniqBy } from 'lodash-es';
import { DateTime, Interval } from 'luxon';
import * as React from 'react';
import { DocumentPositionsPlanningFragment } from '../../generated/types';
import { isNonEmptyArray } from '../../util/array';
import {
    DEFAULT_MAX_HOUR,
    DEFAULT_MIN_HOUR,
    intervalsStats,
    splitRangesByDay,
    splitRangesMidday
} from '../../util/interval';
import { PositionsVolunteersPlanningHeader } from '../positionsVolunteersPlanning/positionsVolunteersPlanningHeader';
import { PositionsPlanningPage } from './positionsPlanningPage';

const NUMBER_OF_POSITIONS_PER_PAGE = 11;

interface IPositionsPlanningProps {
    date?: DateTime;
    event: DocumentPositionsPlanningFragment;
    hideHeader?: boolean;
}

export const PositionsPlanning = (props: IPositionsPlanningProps) => {
    const slots = React.useMemo(() => {
        const slotsSplitsByDay = splitRangesByDay(props.event.positionsSlots.nodes);

        if (isNonEmptyArray(slotsSplitsByDay)) {
            const slotsSplitsByDayStats = intervalsStats(slotsSplitsByDay.map((s) => s.range));
            let slotsSplitsMidday: DocumentPositionsPlanningFragment['positionsSlots']['nodes'];

            if (
                slotsSplitsByDayStats.startHour !== DEFAULT_MIN_HOUR ||
                slotsSplitsByDayStats.endHour !== DEFAULT_MAX_HOUR
            ) {
                slotsSplitsMidday = splitRangesMidday(slotsSplitsByDay);
            } else {
                slotsSplitsMidday = slotsSplitsByDay;
            }

            return slotsSplitsMidday.filter((s) => {
                if (props.date?.isValid) {
                    return s.range.start!.startOf('day').equals(props.date.startOf('day'));
                } else {
                    return true;
                }
            });
        } else {
            return [];
        }
    }, [props.event, props.date]);
    const stats = React.useMemo(() => {
        if (isNonEmptyArray(slots)) {
            return intervalsStats(slots.map((s) => s.range));
        } else {
            return null;
        }
    }, [slots]);
    const has2PagesPerDay =
        stats && (stats.startHour !== DEFAULT_MIN_HOUR || stats.endHour !== DEFAULT_MAX_HOUR);

    return (
        <>
            {stats ? (
                range(0, stats.numberOfDays).map((day, dayIndex) => range(0, has2PagesPerDay ? 2 : 1).map((_, dayPartIndex) => {
                        const startDate = stats.startDate.plus({ day }).set({
                            hour: has2PagesPerDay ? (dayPartIndex === 0 ? 0 : 12) : DEFAULT_MIN_HOUR
                        });
                        const endDate = has2PagesPerDay
                            ? dayPartIndex === 0
                                ? startDate.set({ hour: 12 })
                                : startDate.plus({ day: 1 }).startOf('day')
                            : startDate.set({ hour: DEFAULT_MAX_HOUR });
                        const interval = Interval.fromDateTimes(startDate, endDate);
                        const daySlots = slots.filter((s) => s.range.overlaps(interval));
                        const positions = sortBy(
                            uniqBy(
                                daySlots.map((s) => s.position),
                                (p) => p.id
                            ),
                            (p) => p.name
                        );

                        if (isNonEmptyArray(daySlots)) {
                            return chunk(positions, NUMBER_OF_POSITIONS_PER_PAGE).map(
                                (positionsChunk, positionsChunkIndex) => (
                                        <PositionsPlanningPage
                                            key={`${dayIndex}-${dayPartIndex}-${positionsChunkIndex}`}
                                            eventName={props.event.name}
                                            hideHeader={props.hideHeader}
                                            positions={positionsChunk}
                                            segmentName={props.event.segment.name}
                                            slots={daySlots}
                                            startDate={startDate}
                                        />
                                    )
                            );
                        } else {
                            return null;
                        }
                    }))
            ) : props.hideHeader !== true ? (
                <PositionsVolunteersPlanningHeader
                    eventName={props.event.name}
                    segmentName={props.event.segment.name}
                />
            ) : null}
        </>
    );
};
