import { Duration } from 'luxon';
import * as React from 'react';
import { Avatar } from '../../components/avatar/avatar';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { Spacer } from '../../designSystem/components/spacer';
import {
    DocumentUserAssignmentsFragment,
    UserAssignmentsDocumentOptions
} from '../../generated/types';
import { useTranslate } from '../../util/dependencies/dependencies';
import { isNonEmptyString } from '../../util/string';

interface IAssignmentHeaderProps {
    duration: Duration;
    eventName: string;
    numberOfAssignments: number;
    options: UserAssignmentsDocumentOptions;
    pageBreak: boolean;
    userInfo: DocumentUserAssignmentsFragment['organization']['userInfo'];
}

export const AssignmentHeader = (props: IAssignmentHeaderProps) => {
    const translate = useTranslate();

    return (
        <Flex
            direction="column"
            css={{
                background: '$gray100',
                padding: '$7 56px',
                pageBreakBefore: props.pageBreak ? 'always' : undefined
            }}
        >
            <Box color="gray900" fontSize="textXl" fontWeight="medium">
                {isNonEmptyString(props.options.headerText)
                    ? props.options.headerText
                    : translate('Assignments')}
            </Box>

            <Box color="gray500" fontSize="textXs">
                {props.eventName}
            </Box>

            <Spacer height="6" />

            <Flex align="center" gap="4">
                <Avatar
                    size={64}
                    name={props.userInfo.name}
                    email={props.userInfo.email}
                    image={props.userInfo.picture?.url}
                />

                <Flex direction="column">
                    <Box color="gray800" fontSize="textMd" fontWeight="medium">
                        {props.userInfo.nameOrEmail}
                    </Box>

                    <Box color="gray500">
                        {translate('nombre_d_affect_45664', props.numberOfAssignments)}
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
