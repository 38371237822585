import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { range } from 'lodash-es';
import * as React from 'react';
import { Button } from '../components/button';
import { Checkbox, ICheckboxState } from '../components/checkbox';
import { Dropdown } from '../components/dropdown/dropdown';
import { Item, ItemIcon } from '../components/dropdown/item';
import { Menu } from '../components/dropdown/menu';
import { Trigger } from '../components/dropdown/trigger';
import { Separator } from '../components/separator';

const getAllCheckboxes = (state: ICheckboxState) => Object.fromEntries(range(0, 100).map((i) => [`key${i}`, state]));

export const Dropdowns = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [checkboxes, setCheckboxes] = React.useState(getAllCheckboxes('unchecked'));
    const checkboxesValues = Object.values(checkboxes);
    const state = checkboxesValues.every((value) => value === 'checked')
        ? 'checked'
        : checkboxesValues.every((value) => value === 'unchecked')
        ? 'unchecked'
        : 'indeterminate';

    return (
        <Flex direction="column" gap="6">
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Dropdowns
            </Box>

            <Flex gap="3">
                <Dropdown
                    onStateChange={(open) => {
                        setIsOpen(open);
                    }}
                >
                    <Trigger>
                        <Button rightIcon={isOpen ? 'chevron-up' : 'chevron-down'}>Open</Button>
                    </Trigger>

                    <Menu placement="bottom-start">
                        <Item>View profile</Item>
                        <Item>Settings</Item>
                        <Item color="disabled">Slack</Item>
                        <Item color="red">Delete</Item>
                        <Separator direction="horizontal" color="gray100" />
                        <Item>Log out</Item>
                    </Menu>
                </Dropdown>

                <Dropdown>
                    <Trigger>
                        <Button>Menu</Button>
                    </Trigger>

                    <Menu placement="bottom-end">
                        <ItemIcon icon="user">View profile</ItemIcon>
                        <ItemIcon icon="eye">View</ItemIcon>
                        <ItemIcon icon="trash-can" color="red">
                            Delete
                        </ItemIcon>
                        <Separator direction="horizontal" color="gray100" />
                        <ItemIcon icon="arrow-right-from-bracket">Log out</ItemIcon>
                    </Menu>
                </Dropdown>

                <Dropdown>
                    <Trigger>
                        <Flex
                            align="center"
                            justify="center"
                            css={{
                                border: '1px solid $gray200',
                                borderRadius: '$1',
                                cursor: 'pointer',
                                width: '300px'
                            }}
                        >
                            Click me!
                        </Flex>
                    </Trigger>

                    <Menu placement="bottom-start" width="match">
                        <Item>Item 1</Item>
                        <Item>Item 2</Item>
                    </Menu>
                </Dropdown>

                <Dropdown>
                    <Trigger>
                        <Flex
                            align="center"
                            justify="center"
                            css={{
                                border: '1px solid $gray200',
                                borderRadius: '$1',
                                cursor: 'pointer',
                                width: '300px'
                            }}
                        >
                            Menu max height
                        </Flex>
                    </Trigger>

                    <Menu placement="bottom-start" width="match" maxHeight={300}>
                        <Item>
                            <Flex align="center" gap="3">
                                <Checkbox
                                    state={state}
                                    onClick={(newState) => {
                                        setCheckboxes(getAllCheckboxes(newState));
                                    }}
                                />
                                Toggle all
                            </Flex>
                        </Item>
                        <Separator direction="horizontal" color="gray100" />
                        {range(0, 100).map((i) => (
                                <Item key={i}>
                                    <Flex align="center" gap="3">
                                        <Checkbox
                                            onClick={(newState) => {
                                                setCheckboxes({
                                                    ...checkboxes,
                                                    [`key${i}`]: newState
                                                });
                                            }}
                                            state={checkboxes[`key${i}`]}
                                        />
                                        Item {i + 1}
                                    </Flex>
                                </Item>
                            ))}
                    </Menu>
                </Dropdown>
            </Flex>
        </Flex>
    );
};
