import * as React from 'react';
import { Flex } from '../../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../../generated/types';
import { UltraMarinExposantBack } from './ultraMarinExposantBack';
import { UltraMarinExposantFront } from './ultraMarinExposantFront';

interface IUltraMarinExposantProps {
    event: DocumentUserCustomBadgeFragment;
}

export const UltraMarinExposant = (props: IUltraMarinExposantProps) => (
        <>
            <Flex height={396} width={280} css={{ overflow: 'hidden', pageBreakBefore: 'always' }}>
                <UltraMarinExposantFront event={props.event} />
            </Flex>

            <Flex height={396} width={280} css={{ overflow: 'hidden', pageBreakBefore: 'always' }}>
                <UltraMarinExposantBack event={props.event} />
            </Flex>
        </>
    );
