import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';
import { FiseZone } from './fiseZone';

const getFrontBackground = (population: string) => {
    switch (population) {
        case 'BENEVOLES':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Benevole_Recto.png';
        case 'HURRICANE':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Hurricane_Recto.png';
        case 'GESTION SPORT':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Gestion_Sport_Recto.png';
        case 'ORGANISATION':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Organisation_Recto.png';
        case 'PARTENAIRES':
        case 'EXPOSANTS':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Partenaire_Recto.png';
        case 'PRESSE & MEDIAS':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Media_Recto.png';
        case 'PRESTATAIRES':
        case 'PROD TV':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Prestataire_Recto.png';
        default:
            return 'https://assets.recrewteer.com/badges/fise/badge2/Benevole_Recto.png';
    }
};

const getBackBackground = (population: string) => {
    switch (population) {
        case 'BENEVOLES':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Benevole_Verso.png';
        case 'HURRICANE':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Hurricane_Verso.png';
        case 'GESTION SPORT':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Gestion_Sport_Verso.png';
        case 'ORGANISATION':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Organisation_Verso.png';
        case 'PARTENAIRES':
        case 'EXPOSANTS':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Partenaire_Verso.png';
        case 'PRESSE & MEDIAS':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Media_Verso.png';
        case 'PRESTATAIRES':
        case 'PROD TV':
            return 'https://assets.recrewteer.com/badges/fise/badge2/Prestataire_Verso.png';
        default:
            return 'https://assets.recrewteer.com/badges/fise/badge2/Benevole_Verso.png';
    }
};

interface IFiseBadgeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const FiseBadge = (props: IFiseBadgeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const aragonAui = vr.accreditationsUsersInfos.find((aui) => aui.accreditation.acronym === 'SA');
    const prodComAui = vr.accreditationsUsersInfos.find(
        (aui) => aui.accreditation.acronym === 'CP'
    );
    const vipAui = vr.accreditationsUsersInfos.find((aui) => aui.accreditation.acronym === 'VIP');
    const presseAui = vr.accreditationsUsersInfos.find((aui) => aui.accreditation.acronym === 'EP');
    const airesSportivesAui = vr.accreditationsUsersInfos.find(
        (aui) => aui.accreditation.acronym === 'AS'
    );
    const tribunesAui = vr.accreditationsUsersInfos.find(
        (aui) => aui.accreditation.acronym === 'TRI'
    );
    const accessAvecAccompagnant = ui.fiseSousPopulation === 'ACCES AUTORISÉ AVEC ACCOMPAGNANT';
    const accessH24AvecAccompagnant =
        ui.fiseSousPopulation === 'ACCES AUTORISÉ H24 AVEC ACCOMPAGNANT';

    return (
        <>
            <Flex
                height={597}
                width={435}
                css={{
                    background: `url(${getFrontBackground(ui.fisePopulation)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$poppins',
                    position: 'relative'
                }}
            >
                <Box
                    css={{
                        color: 'black',
                        fontSize: '16px',
                        fontWeight: '600',
                        left: '65px',
                        position: 'absolute',
                        top: '82px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        color: 'black',
                        fontSize: '16px',
                        fontWeight: '600',
                        left: '65px',
                        position: 'absolute',
                        top: '115px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    css={{
                        color: 'black',
                        fontSize: '16px',
                        fontWeight: '600',
                        left: '65px',
                        position: 'absolute',
                        top: '148px'
                    }}
                >
                    {ui.fields.cf25674 || ''}
                </Box>

                <Box
                    css={{
                        height: '64px',
                        left: '304px',
                        position: 'absolute',
                        top: '95px',
                        width: '64px'
                    }}
                >
                    {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                        <img
                            src={`https://api.${
                                CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                            }/barcode/qr?text=${encodeURIComponent(
                                vr.weezeventParticipantInfo!.idBarcode
                            )}&color=000000&height=64&width=64`}
                            height="100%"
                            width="100%"
                        />
                    )}
                </Box>

                {(ui.fisePopulation === 'HURRICANE' ||
                    ui.fisePopulation === 'PRESSE & MEDIAS' ||
                    ui.fisePopulation === 'ORGANISATION') &&
                    (accessAvecAccompagnant || accessH24AvecAccompagnant) && (
                        <Flex
                            align="center"
                            justify="center"
                            css={{
                                background: accessAvecAccompagnant ? '#686868' : '#067918',
                                color: 'white',
                                height: '20px',
                                fontSize: '10px',
                                fontWeight: '600',
                                left: '57px',
                                position: 'absolute',
                                top: '180px',
                                width: '320px'
                            }}
                        >
                            {ui.fiseSousPopulation}
                        </Flex>
                    )}

                {aragonAui && (
                    <Box
                        css={{
                            left: '67px',
                            position: 'absolute',
                            top: '235px'
                        }}
                    >
                        <FiseZone aui={aragonAui} num={1} />
                    </Box>
                )}

                {prodComAui && (
                    <Box
                        css={{
                            left: '67px',
                            position: 'absolute',
                            top: '268px'
                        }}
                    >
                        <FiseZone aui={prodComAui} num={2} />
                    </Box>
                )}

                {vipAui && (
                    <Box
                        css={{
                            left: '67px',
                            position: 'absolute',
                            top: '301px'
                        }}
                    >
                        <FiseZone aui={vipAui} num={3} />
                    </Box>
                )}

                {presseAui && (
                    <Box
                        css={{
                            left: '231px',
                            position: 'absolute',
                            top: '235px'
                        }}
                    >
                        <FiseZone aui={presseAui} num={4} />
                    </Box>
                )}

                {airesSportivesAui && (
                    <Box
                        css={{
                            left: '231px',
                            position: 'absolute',
                            top: '268px'
                        }}
                    >
                        <FiseZone aui={airesSportivesAui} num={5} />
                    </Box>
                )}

                {tribunesAui && (
                    <Box
                        css={{
                            left: '231px',
                            position: 'absolute',
                            top: '301px'
                        }}
                    >
                        <FiseZone aui={tribunesAui} num={6} />
                    </Box>
                )}
            </Flex>

            <Flex
                height={597}
                width={435}
                css={{
                    background: `url(${getBackBackground(ui.fisePopulation)}) no-repeat`,
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />
        </>
    );
};
