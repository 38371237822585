import * as React from 'react';
import { Field } from 'react-final-form';
import {
    IToggleSize,
    Toggle as ToggleComponent,
    ToggleText as ToggleTextComponent
} from '../components/toggle';

interface IToggleProps {
    name: string;
    size?: IToggleSize;
}

export const Toggle = (props: IToggleProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => (
                    <ToggleComponent
                        size={props.size}
                        value={fieldProps.input.value}
                        onChange={fieldProps.input.onChange}
                    />
                )}
        />
    );

interface IToggleTextProps {
    name: string;
    size?: IToggleSize;
    disabled?: boolean;
    children: React.ReactNode;
    subtext?: string;
}

export const ToggleText = (props: IToggleTextProps) => (
        <Field
            name={props.name}
            render={(fieldProps) => (
                    <ToggleTextComponent
                        size={props.size}
                        subtext={props.subtext}
                        value={fieldProps.input.value}
                        onChange={fieldProps.input.onChange}
                    >
                        {props.children}
                    </ToggleTextComponent>
                )}
        />
    );
