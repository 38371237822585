import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { FormsCustomFieldInput, MassEditCustomFieldFragment } from 'common/src/generated/types';
import { removeAtIndex } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormParametersGeneralCustomField } from './formParametersGeneralCustomField';

interface IFormParametersGeneralCustomFieldsProps {
    customFields: MassEditCustomFieldFragment[];
    name: string;
    values: FormsCustomFieldInput[];

    change(name: string, value: any): void;
}

export const FormParametersGeneralCustomFields = (
    props: IFormParametersGeneralCustomFieldsProps
) => {
    const translate = useTranslate();

    return (
        <>
            {props.values.map((_, index) => {
                const field = `${props.name}[${index}]`;

                return (
                    <React.Fragment key={index}>
                        {index !== 0 && <Spacer height="3" />}

                        <FormParametersGeneralCustomField
                            customFields={props.customFields}
                            customFieldValues={props.values[index]}
                            field={field}
                            index={index}
                            change={props.change}
                            remove={() => {
                                props.change(props.name, removeAtIndex(props.values, index));
                            }}
                        />
                    </React.Fragment>
                );
            })}

            <Spacer height="3" />

            <Box
                color="primary700"
                fontWeight="medium"
                css={{ cursor: 'pointer' }}
                onClick={() => {
                    props.change(
                        props.name,
                        props.values.concat([{ customFieldId: -1, addValues: false } as any])
                    );
                }}
            >
                + {translate('ajouter_un_nouv_22370')}
            </Box>
        </>
    );
};
