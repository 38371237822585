import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { RichText } from 'common/src/designSystem/components/richEditor/richText';
import { Accreditation } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IAccreditationPrivateNoteProps {
    accreditation: Pick<Accreditation, 'privateNote'>;
}

export const AccreditationPrivateNote = (props: IAccreditationPrivateNoteProps) => {
    const translate = useTranslate();

    return (
        <DetailBox
            title={
                <Flex align="center" gap="2">
                    <I icon="lock" />

                    <Box>{translate('note_priv_e_06303')}</Box>
                </Flex>
            }
        >
            {isNonEmptyString(props.accreditation.privateNote) ? (
                <RichText text={props.accreditation.privateNote} />
            ) : (
                <Box color="gray500" width={1} textAlign="center">
                    {translate('aucune_note_pri_86832')}
                </Box>
            )}
        </DetailBox>
    );
};
