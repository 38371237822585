import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyArray } from '../../../util/array';
import { JardinSonoreParkingPage } from './jardinSonoreParkingPage';

interface IContremarqueJardinSonoreParkingProps {
    event: DocumentUserCustomBadgeFragment;
}

export const ContremarqueJardinSonoreParking = (props: IContremarqueJardinSonoreParkingProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const p1Slots = vr.accreditationsUsersInfos.flatMap((aui) => {
        if (aui.accreditation.acronym === 'P1') {
            return [aui.accreditationSlot.date!];
        } else {
            return [];
        }
    });
    const p2Slots = vr.accreditationsUsersInfos.flatMap((aui) => {
        if (aui.accreditation.acronym === 'P2') {
            return [aui.accreditationSlot.date!];
        } else {
            return [];
        }
    });
    const p3Slots = vr.accreditationsUsersInfos.flatMap((aui) => {
        if (aui.accreditation.acronym === 'P3') {
            return [aui.accreditationSlot.date!];
        } else {
            return [];
        }
    });
    let delegation: string = '';
    if (ui.jardinSonorePopulation === 'Pro / Partenaire') {
        delegation = ui.jardinSonoreStructure;
    } else if (ui.jardinSonorePopulation === 'Presse / Média') {
        delegation = ui.jardinSonoreNomDuMedia;
    }

    return (
        <>
            {isNonEmptyArray(p1Slots) && (
                <JardinSonoreParkingPage
                    background="https://assets.recrewteer.com/badges/jardinSonore/jardinSonoreContremarqueParkingP1.jpg"
                    barcode={vr.weezeventParticipantInfo?.idBarcode ?? ''}
                    delegation={delegation}
                    name={ui.name}
                    slots={p1Slots}
                />
            )}

            {isNonEmptyArray(p2Slots) && (
                <JardinSonoreParkingPage
                    background="https://assets.recrewteer.com/badges/jardinSonore/jardinSonoreContremarqueParkingP2.jpg"
                    barcode={vr.weezeventParticipantInfo?.idBarcode ?? ''}
                    delegation={delegation}
                    name={ui.name}
                    slots={p2Slots}
                />
            )}

            {isNonEmptyArray(p3Slots) && (
                <JardinSonoreParkingPage
                    background="https://assets.recrewteer.com/badges/jardinSonore/jardinSonoreContremarqueParkingP3.jpg"
                    barcode={vr.weezeventParticipantInfo?.idBarcode ?? ''}
                    delegation={delegation}
                    name={ui.name}
                    slots={p3Slots}
                />
            )}
        </>
    );
};
