import * as React from 'react';
import { Flex } from '../../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../../generated/types';
import { UltraMarinOrganisationBack } from './ultraMarinOrganisationBack';
import { UltraMarinOrganisationFront } from './ultraMarinOrganisationFront';

interface IUltraMarinOrganisationProps {
    event: DocumentUserCustomBadgeFragment;
}

export const UltraMarinOrganisation = (props: IUltraMarinOrganisationProps) => (
        <>
            <Flex height={396} width={280} css={{ overflow: 'hidden', pageBreakBefore: 'always' }}>
                <UltraMarinOrganisationFront />
            </Flex>

            <Flex height={396} width={280} css={{ overflow: 'hidden', pageBreakBefore: 'always' }}>
                <UltraMarinOrganisationBack event={props.event} />
            </Flex>
        </>
    );
