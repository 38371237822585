import { UserCreateField } from 'common-front/src/components/users/create/v2/userCreateField';
import { Flex } from 'common/src/designSystem/components/flex';
import { CreatePositionCustomFieldsFragment } from 'common/src/generated/types';
import * as React from 'react';

interface ICreatePositionCustomFieldsProps {
    customFields: CreatePositionCustomFieldsFragment['positionCustomFields'];

    change(name: string, value: any): void;
}

export const CreatePositionCustomFields = (props: ICreatePositionCustomFieldsProps) => (
        <Flex direction="column" gap="4">
            {props.customFields.nodes.map((customField) => (
                    <UserCreateField
                        key={customField.id}
                        display={true}
                        field={customField}
                        fileAcl="public-read"
                        isMandatory={false}
                        prefix="position.fields."
                        change={props.change}
                    />
                ))}
        </Flex>
    );
