import { getAccreditationLimits } from 'common-front/src/delegations/accreditations/getAccreditationLimits';
import { CalendarInput } from 'common-front/src/designSystem/components/date/calendarInput';
import { Flex } from 'common/src/designSystem/components/flex';
import { AccreditSlotsQuery, DelegationAccreditation } from 'common/src/generated/types';
import {
    dateSlotsStats,
    newCalendarsAccreditationsSlotsIds
} from 'common/src/vo/accreditationSlot';
import { range } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { useAccreditContext } from './accreditContext';

interface IAccreditSlotsCalendarsProps {
    delegationAccreditations?: DelegationAccreditation[];
    slots: AccreditSlotsQuery['event']['accreditationsSlots']['nodes'];
}

export const AccreditSlotsCalendars = ({
    delegationAccreditations,
    slots
}: IAccreditSlotsCalendarsProps) => {
    const { accreditationsSlotsIds, setAccreditationsSlotsIds } = useAccreditContext();

    const { minDate, maxDate, numberOfMonths } = React.useMemo(() => dateSlotsStats(slots), [slots]);
    const availableDates = React.useMemo(() => slots.flatMap((slot) => {
            if (slot.date?.isValid) {
                return [slot.date!];
            } else {
                return [];
            }
        }), [slots]);
    const slotsIds = React.useMemo(() => slots.flatMap((slot) => slot.id), [slots]);
    const availableDelegationAccreditationDates = React.useMemo(() => delegationAccreditations?.flatMap((da) => slotsIds.includes(da.accreditationSlot.id)
                ? da.accreditationSlot!.date!.toISODate()
                : []), [delegationAccreditations, slotsIds]);
    const availableValues = React.useMemo(() => availableDates?.flatMap((date) => {
            const da = delegationAccreditations?.find(
                (da) => da.accreditationSlot.date?.toISODate() === date.toISODate()
            );
            const slot = slots?.find((slot) => slot.date?.toISODate() === date.toISODate());
            if (!da && !slot) {
                return [];
            }

            const { assignedResources, isMaxedOut, limitIcon, maxResources } =
                getAccreditationLimits(da, slot);

            return [
                {
                    date,
                    metadata: {
                        maxResources,
                        limitIcon,
                        assignedResources
                    },
                    isSelectable: !isMaxedOut
                }
            ];
        }), [availableDates, availableDelegationAccreditationDates, slots]);
    const selectedDates = React.useMemo(() => slots.flatMap((slot) => {
            if (
                slot.date?.isValid &&
                accreditationsSlotsIds.includes(slot.id) &&
                availableValues.find((val) => val.date === slot.date && val.isSelectable)
            ) {
                return [slot.date!];
            } else {
                return [];
            }
        }), [slots, accreditationsSlotsIds, availableValues]);

    return (
        <Flex
            gap="4"
            wrap="wrap"
            css={{
                background: '$gray50',
                borderTop: '1px solid $gray200',
                padding: '$4'
            }}
        >
            {range(0, numberOfMonths).map((i) => {
                const firstDayOfMonth = minDate.plus({ month: i }).startOf('month');
                const min = i === 0 ? minDate : firstDayOfMonth;
                const max = i === numberOfMonths - 1 ? maxDate : firstDayOfMonth.endOf('month');
                const values = selectedDates.filter((date) =>
                    date.startOf('month').equals(firstDayOfMonth)
                );

                return (
                    <CalendarInput
                        key={i}
                        availableValues={availableValues}
                        firstDayOfMonth={firstDayOfMonth}
                        max={max}
                        min={min}
                        values={values}
                        onChange={(newDateTimes: DateTime[]) => {
                            setAccreditationsSlotsIds(
                                newCalendarsAccreditationsSlotsIds(
                                    accreditationsSlotsIds,
                                    slots,
                                    firstDayOfMonth,
                                    newDateTimes
                                )
                            );
                        }}
                    />
                );
            })}
        </Flex>
    );
};
