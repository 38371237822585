import { CheckboxText, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { DropdownFilters } from 'common-front/src/designSystem/components/dropdownFilters';
import { Select } from 'common-front/src/designSystem/components/select/select';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { MassAssignSlotsQuery, SegmentId } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IMassAssignFiltersProps {
    excludeFull: boolean;
    name: string;
    segmentId: SegmentId;
    segments: MassAssignSlotsQuery['event']['segments'];

    setExcludeFull(excludeFull: boolean): void;
    setName(name: string): void;
    setOffset(offset: number): void;
    setSegmentId(segmentId: SegmentId): void;
}

export const MassAssignFilters = (props: IMassAssignFiltersProps) => {
    const translate = useTranslate();
    const [areFiltersOpen, setAreFiltersOpen] = React.useState(false);
    const [segmentId, setSegmentId] = React.useState(props.segmentId);
    const [hiddenState, setHiddenState] = React.useState<ICheckboxState>(
        props.excludeFull ? 'checked' : 'unchecked'
    );
    const apply = () => {
        props.setExcludeFull(hiddenState === 'checked');
        props.setSegmentId(segmentId);

        setAreFiltersOpen(false);
    };
    const reinit = () => {
        props.setExcludeFull(false);
        setHiddenState('unchecked');
        props.setSegmentId(-1 as SegmentId);
        setSegmentId(-1 as SegmentId);
        props.setName('');
        props.setOffset(0);

        setAreFiltersOpen(false);
    };

    return (
        <>
            <Box width={320}>
                <TextInput
                    value={props.name}
                    onChange={(newName: string) => {
                        props.setOffset(0);
                        props.setName(newName);
                    }}
                    placeholder={translate('rechercher_une_69998')}
                    icon="magnifying-glass"
                />
            </Box>

            <DropdownFilters
                color="white"
                isOpen={areFiltersOpen}
                showButtons={true}
                apply={apply}
                onStateChange={setAreFiltersOpen}
                reinit={reinit}
            >
                <Select
                    value={segmentId}
                    onChange={(newSegmentId) => {
                        setSegmentId(newSegmentId as SegmentId);
                    }}
                    parseInt={true}
                >
                    <option value={-1}>{translate('vue_personnalis_97654')}</option>

                    {props.segments.map((segment) => (
                            <option key={segment.id} value={segment.id}>
                                {segment.name}
                            </option>
                        ))}
                </Select>

                <CheckboxText state={hiddenState} onClick={setHiddenState}>
                    {translate('cacher_les_cr_n_31908')}
                </CheckboxText>
            </DropdownFilters>
        </>

        /*
        <DropdownFiltersMenu
            apply={apply}
            reset={() => {
                props.reset();
                props.close();
            }}
        >
            <Select
                value={segmentId}
                onChange={(newSegmentId) => {
                    setSegmentId(newSegmentId as SegmentId);
                }}
                parseInt={true}
            >
                <option value={-1}>{translate('vue_personnalis_97654')}</option>

                {props.segments.map((segment) => {
                    return (
                        <option key={segment.id} value={segment.id}>
                            {segment.name}
                        </option>
                    );
                })}
            </Select>

            <CheckboxText state={hiddenState} onClick={setHiddenState}>
                {translate('cacher_les_cr_n_31908')}
            </CheckboxText>
        </DropdownFiltersMenu>
         */
    );
};
