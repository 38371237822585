import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

export const FormElementsSkeleton = () => (
        <>
            <Skeleton height={76} width={1} borderRadius="$2" />
            <Spacer height="3" />
            <Skeleton height={76} width={1} borderRadius="$2" />
            <Spacer height="3" />
            <Skeleton height={76} width={1} borderRadius="$2" />
        </>
    );
