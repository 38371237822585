import { Accordion } from 'common-front/src/designSystem/components/accordion';
import { getWishedPositions } from 'common-front/src/vo/formUserInfo';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { Row } from 'common/src/designSystem/components/table/row';
import { Table } from 'common/src/designSystem/components/table/table';
import {
    EventId,
    FormPositionRanking,
    FormsUsersInfosWishedFragment,
    OrganizationId
} from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IAssignmentUserPanelPositionsWishedProps {
    eventId: EventId;
    formsUsersInfos: FormsUsersInfosWishedFragment['formsUsersInfos'];
    organizationId: OrganizationId;
}

export const AssignmentUserPanelPositionsWished = (
    props: IAssignmentUserPanelPositionsWishedProps
) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);
    const wishedPositions = React.useMemo(() => getWishedPositions(
            props.formsUsersInfos,
            intervalService,
            props.organizationId,
            props.eventId
        ), [props.formsUsersInfos, props.organizationId, props.eventId]);

    return (
        <Accordion title={translate('missions_souhai_23225')}>
            <Table>
                <HeaderRow>
                    <HeaderCell>{translate('mission_souhait_49168')}</HeaderCell>
                </HeaderRow>

                {wishedPositions.map((position, index) => (
                        <Row key={index}>
                            <Cell direction="column" align="start" justify="center" gap="1">
                                <Flex align="center" gap="2">
                                    <Box color="gray900" fontWeight="medium">
                                        {position.name}
                                    </Box>

                                    {position.form.positionRanking ===
                                        FormPositionRanking.Ranked && (
                                        <Badge>{translate('choix_n_1_00613', position.rank)}</Badge>
                                    )}
                                </Flex>

                                <Box color="gray500">{position.range}</Box>
                            </Cell>
                        </Row>
                    ))}
            </Table>
        </Accordion>
    );
};
