import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { ProgressGauge } from 'common-front/src/designSystem/components/progressGauge';
import { useMobileQuery } from 'common-front/src/hooks/useMobileQuery';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionsCategory } from 'common/src/generated/types';
import * as React from 'react';

interface IPositionCategoryGaugeProps {
    category: Pick<PositionsCategory, 'assignedResources' | 'resources'>;
    title: string;
}

export const PositionCategoryGauge = (props: IPositionCategoryGaugeProps) => {
    const onMobile = useMobileQuery();

    return (
        <DetailBox title={props.title}>
            <Flex
                direction="column"
                align="center"
                {...(onMobile ? { gap: '9' } : { gap: '7' })}
                width={1}
            >
                <ProgressGauge
                    count={props.category.assignedResources}
                    total={props.category.resources}
                />
            </Flex>
        </DetailBox>
    );
};
