import { DateTime } from 'luxon';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IJardinSonoreParkingPageProps {
    background: string;
    barcode: string;
    delegation: string;
    name: string;
    slots: DateTime[];
}

export const JardinSonoreParkingPage = (props: IJardinSonoreParkingPageProps) => (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(${props.background}) no-repeat`,
                backgroundSize: 'cover',
                color: 'black',
                fontFamily: '$dinCondensed',
                fontSize: '24px',
                fontWeight: '700',
                pageBreakBefore: 'always',
                position: 'relative',
                textTransform: 'uppercase'
            }}
        >
            <Box
                css={{
                    left: '147px',
                    position: 'absolute',
                    right: '147px',
                    top: '640px'
                }}
            >
                {props.name}
            </Box>

            <Box
                css={{
                    left: '147px',
                    position: 'absolute',
                    top: '713px'
                }}
            >
                {props.delegation}
            </Box>

            <Box
                css={{
                    left: '147px',
                    position: 'absolute',
                    top: '786px'
                }}
            >
                {props.slots
                    .map((day) => day.toFormat('dd/MM/yyyy'))
                    .join(' - ')}
            </Box>

            <Box
                height={119}
                width={119}
                css={{
                    left: '337px',
                    position: 'absolute',
                    top: '863px'
                }}
            >
                {isNonEmptyString(props.barcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            props.barcode
                        )}&color=000000&height=119&width=119`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
