import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { EmailsRecipientsFragment, EmailState } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useHistory, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { getStateBadgeColorIcon } from 'common/src/vo/email';
import * as React from 'react';
import { useCampaignContext } from './campaignContext';

interface ICampaignEmailRowProps {
    email: EmailsRecipientsFragment['nodes'][0];
}

export const CampaignEmailRow = (props: ICampaignEmailRowProps) => {
    const translate = useTranslate();
    const history = useHistory();
    const { getCampaignDashboardUserInfosPath } = useCampaignContext();
    const dateTimeService = useService(DateTimeService);

    return (
        <Row
            css={{
                cursor: getCampaignDashboardUserInfosPath !== undefined ? 'pointer' : 'inherit',
                '&:hover': {
                    background:
                        getCampaignDashboardUserInfosPath !== undefined ? '$gray100' : 'inherit'
                }
            }}
            onClick={() => {
                if (getCampaignDashboardUserInfosPath) {
                    history.push(getCampaignDashboardUserInfosPath(props.email.userInfo.id));
                }
            }}
        >
            <Cell>
                <AvatarNameEmail
                    userInfo={props.email.userInfo}
                    hasName={isNonEmptyString(props.email.userInfo.name)}
                />
            </Cell>
            <Cell>
                {props.email.sentAt
                    ? dateTimeService.toLocaleString(
                          props.email.sentAt.toLocal(),
                          LocaleFormats.DateTime
                      )
                    : ''}
            </Cell>
            <Cell>
                {props.email.state === EmailState.Bounced &&
                isNonEmptyString(props.email.infos?.bounceDiagnosticCode) ? (
                    <Tooltip>
                        <Trigger>
                            <Box>
                                <Badge {...getStateBadgeColorIcon(props.email.state)}>
                                    {translate(props.email.state)}
                                </Badge>
                            </Box>
                        </Trigger>

                        <Content placement="top">{props.email.infos.bounceDiagnosticCode}</Content>
                    </Tooltip>
                ) : (
                    <Badge {...getStateBadgeColorIcon(props.email.state)}>
                        {translate(props.email.state)}
                    </Badge>
                )}
            </Cell>
        </Row>
    );
};
