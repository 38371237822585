import { Emptyable } from 'common/src/util/emptyable';

export enum VolunteersRendering {
    Calendar = 'Calendar',
    List = 'List'
}

export function getVolunteersRendering(volunteersRendering: Emptyable<VolunteersRendering>) {
    switch (volunteersRendering) {
        case VolunteersRendering.Calendar:
            return VolunteersRendering.Calendar;
        default:
            return VolunteersRendering.List;
    }
}
