import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import {
    SegmentCustomFieldFragment,
    UsersInfoId,
    VolunteerRegistrationFragment,
    VolunteersRegistrationId,
    VolunteersRegistrationsSortAttributes
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { isSortable } from 'common/src/vo/segment';
import { VolunteerDefaultColumns } from 'common/src/vo/segments/volunteersSegmentsService';
import { compact } from 'lodash-es';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useEventContext } from '../../../events/show/eventContext';
import { executeVolunteerQuery, useVolunteersQuery } from '../../../generated/graphqlHooks';
import { UserOverlayEvent } from '../../../users/overlay/userOverlay';
import { VolunteerRow } from './volunteerRow';

interface IVolunteersListProps {
    customFields: SegmentCustomFieldFragment[];
}

export const VolunteersList = (props: IVolunteersListProps) => {
    const {
        history,
        params: { organizationId, eventId, segmentId }
    } = useHeavent();
    const { isEventAdmin } = useEventContext();
    const {
        columns,
        limit,
        nameDebounced,
        offset,
        possibleColumns,
        predicates,
        reloadKey,
        selectAllState,
        setLimit,
        setOffset,
        setSort,
        setStates,
        sort,
        states,
        toggleRow,
        toggleSelectAll
    } = useSegmentsContext<UsersInfoId, VolunteersRegistrationsSortAttributes>();
    const includes = React.useMemo(() => ({
            includeAccreditations: columns.includes(VolunteerDefaultColumns.Accreditations),
            includeAvailability: columns.includes(VolunteerDefaultColumns.Availability),
            includeComments: columns.includes(VolunteerDefaultColumns.Comments),
            includeForms:
                columns.includes(VolunteerDefaultColumns.CompletedForms) ||
                columns.includes(VolunteerDefaultColumns.FormsInsertedAt),
            includeDelegation: columns.includes(VolunteerDefaultColumns.Delegations),
            includeNumberOfAccreditations: columns.includes(
                VolunteerDefaultColumns.NumberOfAccreditations
            ),
            includeNumberOfAssignments: columns.includes(
                VolunteerDefaultColumns.NumberOfAssignments
            ),
            includeNumberOfAssignmentsDays: columns.includes(
                VolunteerDefaultColumns.NumberOfAssignmentsDays
            ),
            includeNumberOfAvailableDays: columns.includes(
                VolunteerDefaultColumns.NumberOfAvailableDays
            ),
            includePositions:
                columns.includes(VolunteerDefaultColumns.Positions) ||
                columns.includes(VolunteerDefaultColumns.PositionsCategories),
            includeTeamCode: columns.includes(VolunteerDefaultColumns.TeamCode),
            includeVolunteerMinutes: columns.includes(VolunteerDefaultColumns.VolunteerMinutes),
            includeWishedAccreditations: columns.includes(
                VolunteerDefaultColumns.WishedAccreditationSlots
            ),
            includeWishedPositions: columns.includes(VolunteerDefaultColumns.WishedPositions),
            includeWeezevent: columns.includes(VolunteerDefaultColumns.WeezeventBarcodeId)
        }), [columns]);
    const { data, isLoading, reload } = useVolunteersQuery({
        ...includes,
        eventId,
        predicates,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        limit,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.volunteersRegistrations);
    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);
    const selectedFields = React.useMemo(() => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])), [possibleColumns, columns]);
    const [volunteersRegistrations, setVolunteersRegistrations] = React.useState<
        VolunteerRegistrationFragment[]
    >([]);
    const [idToLoading, setIdToLoading] = React.useState<Record<VolunteersRegistrationId, boolean>>(
        {}
    );
    const reloadVolunteer = React.useCallback(
        async (id: VolunteersRegistrationId) => {
            setIdToLoading((ids) => ({ ...ids, [id]: true }));

            const {
                event: { volunteerRegistration }
            } = await executeVolunteerQuery(
                {
                    ...includes,
                    eventId,
                    id
                },
                await getToken()
            );

            setVolunteersRegistrations((vrs) =>
                vrs.map((vr) => (vr.id === id ? volunteerRegistration : vr))
            );
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
        },
        [includes, setIdToLoading, setVolunteersRegistrations]
    );

    React.useEffect(() => {
        setVolunteersRegistrations(data.event?.volunteersRegistrations.nodes ?? []);
    }, [data.event]);

    React.useEffect(() => {
        setStates(
            Object.fromEntries(
                (data.event?.volunteersRegistrations.nodes ?? []).map(({ userInfo }) => [
                        `ui${userInfo.id}`,
                        {
                            id: userInfo.id,
                            state: 'unchecked'
                        }
                    ])
            )
        );
    }, [data.event]);

    return (
        <>
            <Flex width={1} css={{ flex: '1', overflow: 'hidden' }}>
                <TableGrid
                    headerRow={
                        <HeaderRow>
                            {isEventAdmin && (
                                <HeaderCell width={48} css={{ paddingRight: 0 }}>
                                    <Checkbox state={selectAllState} onClick={toggleSelectAll} />
                                </HeaderCell>
                            )}

                            {selectedFields.map((field, index) => {
                                const firstCellCss =
                                    index === 0 && isEventAdmin ? { paddingLeft: '$3' } : {};

                                return field.slug === VolunteerDefaultColumns.AccreditationState ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        width={240}
                                        attribute={
                                            VolunteersRegistrationsSortAttributes.AccreditationState
                                        }
                                        sort={sort}
                                        setSort={setSort}
                                        css={firstCellCss}
                                    >
                                        {field.name}
                                    </HeaderCellSort>
                                ) : field.slug === 'firstName' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={VolunteersRegistrationsSortAttributes.FirstName}
                                        sort={sort}
                                        setSort={setSort}
                                        css={firstCellCss}
                                    >
                                        {field.name}
                                    </HeaderCellSort>
                                ) : field.slug === 'lastName' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={VolunteersRegistrationsSortAttributes.LastName}
                                        sort={sort}
                                        setSort={setSort}
                                        css={firstCellCss}
                                    >
                                        {field.name}
                                    </HeaderCellSort>
                                ) : field.slug === VolunteerDefaultColumns.Id ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        width={100}
                                        attribute={VolunteersRegistrationsSortAttributes.Id}
                                        sort={sort}
                                        setSort={setSort}
                                        css={firstCellCss}
                                    >
                                        {field.name}
                                    </HeaderCellSort>
                                ) : field.slug === VolunteerDefaultColumns.InsertedAt ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        width={230}
                                        attribute={VolunteersRegistrationsSortAttributes.InsertedAt}
                                        sort={sort}
                                        setSort={setSort}
                                        css={firstCellCss}
                                    >
                                        {field.name}
                                    </HeaderCellSort>
                                ) : field.slug === VolunteerDefaultColumns.Name ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={VolunteersRegistrationsSortAttributes.Name}
                                        sort={sort}
                                        setSort={setSort}
                                        css={firstCellCss}
                                    >
                                        {field.name}
                                    </HeaderCellSort>
                                ) : field.slug ===
                                  VolunteerDefaultColumns.NumberOfAccreditations ? (
                                    <HeaderCell key={field.slug} width={230} css={firstCellCss}>
                                        {field.name}
                                    </HeaderCell>
                                ) : field.slug === VolunteerDefaultColumns.NumberOfAssignments ? (
                                    <HeaderCell key={field.slug} width={230} css={firstCellCss}>
                                        {field.name}
                                    </HeaderCell>
                                ) : field.slug ===
                                  VolunteerDefaultColumns.NumberOfAssignmentsDays ? (
                                    <HeaderCell key={field.slug} width={230} css={firstCellCss}>
                                        {field.name}
                                    </HeaderCell>
                                ) : field.slug === VolunteerDefaultColumns.NumberOfAvailableDays ? (
                                    <HeaderCell key={field.slug} width={230} css={firstCellCss}>
                                        {field.name}
                                    </HeaderCell>
                                ) : field.slug === 'picture' ? (
                                    <HeaderCell key={field.slug} width={80} css={firstCellCss}>
                                        {field.name}
                                    </HeaderCell>
                                ) : field.slug == VolunteerDefaultColumns.TeamCode ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={VolunteersRegistrationsSortAttributes.TeamCode}
                                        sort={sort}
                                        setSort={setSort}
                                    >
                                        {field.name}
                                    </HeaderCellSort>
                                ) : field.slug === VolunteerDefaultColumns.VolunteerMinutes ? (
                                    <HeaderCell key={field.slug} width={230} css={firstCellCss}>
                                        {field.name}
                                    </HeaderCell>
                                ) : field.slug ===
                                  VolunteerDefaultColumns.VolunteerRegistrationState ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        width={230}
                                        attribute={VolunteersRegistrationsSortAttributes.State}
                                        sort={sort}
                                        setSort={setSort}
                                        css={firstCellCss}
                                    >
                                        {field.name}
                                    </HeaderCellSort>
                                ) : isSortable(field) ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={field.slug}
                                        sort={sort}
                                        setSort={setSort}
                                        css={firstCellCss}
                                    >
                                        <Box title={field.name} css={{ ellipsis: true }}>
                                            {field.name}
                                        </Box>
                                    </HeaderCellSort>
                                ) : (
                                    <HeaderCell key={field.slug} css={firstCellCss}>
                                        <Box title={field.name} css={{ ellipsis: true }}>
                                            {field.name}
                                        </Box>
                                    </HeaderCell>
                                );
                            })}

                            {isEventAdmin && <HeaderCell width={100} />}
                        </HeaderRow>
                    }
                    paginationRow={
                        <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                            <PaginationCell
                                limit={limit}
                                numberOfPages={numberOfPages}
                                offset={offset}
                                showLimits={true}
                                totalCount={totalCount}
                                setLimit={setLimit}
                                setOffset={setOffset}
                            />
                        </PaginationRow>
                    }
                >
                    {isLoading ? (
                        <>
                            <RowSkeleton />
                            <RowSkeleton />
                            <RowSkeleton />
                        </>
                    ) : (
                        volunteersRegistrations.map((volunteerRegistration) => {
                            if (idToLoading[volunteerRegistration.id]) {
                                return <RowSkeleton key={volunteerRegistration.id} />;
                            } else {
                                return (
                                    <VolunteerRow
                                        key={volunteerRegistration.id}
                                        columns={selectedFields}
                                        customFields={props.customFields}
                                        eventId={eventId}
                                        organizationId={organizationId}
                                        segmentId={segmentId}
                                        state={
                                            states[`ui${volunteerRegistration.userInfo.id}`]
                                                ?.state ?? 'unchecked'
                                        }
                                        volunteerRegistration={volunteerRegistration}
                                        reload={reload}
                                        reloadVolunteer={reloadVolunteer}
                                        toggleRow={toggleRow}
                                    />
                                );
                            }
                        })
                    )}
                </TableGrid>
            </Flex>

            <Switch>
                <Route
                    path={HeaventPaths.VOLUNTEERS_LIST_USER(
                        ':organizationId',
                        ':eventId',
                        ':segmentId',
                        ':userInfoId'
                    )}
                    children={
                        <UserOverlayEvent
                            getBasePath={(userInfoId, isRouteComponent) => HeaventPaths.VOLUNTEERS_LIST_USER(
                                    isRouteComponent ? ':organizationId' : organizationId,
                                    isRouteComponent ? ':eventId' : eventId,
                                    isRouteComponent ? ':segmentId' : segmentId,
                                    userInfoId
                                )}
                            onClose={() => {
                                history.push(
                                    HeaventPaths.VOLUNTEERS_SEGMENT(
                                        organizationId,
                                        eventId,
                                        segmentId
                                    )
                                );
                            }}
                            onDelete={() => {
                                history.push(HeaventPaths.VOLUNTEERS(organizationId, eventId));

                                reload();
                            }}
                            onUpdateState={reload}
                        />
                    }
                />
            </Switch>
        </>
    );
};
