import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { PositionsPositionFragment } from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useHistory, useParams, useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';

export const POSITION_POPUP_HEIGHT = 70;

interface IPositionPopupProps {
    position: PositionsPositionFragment;
}

export const PositionPopup = (props: IPositionPopupProps) => {
    const { organizationId, eventId } = useParams();
    const history = useHistory();
    const intervalService = useService(IntervalService);

    return (
        <Flex
            align="center"
            height={POSITION_POPUP_HEIGHT}
            gap="4"
            css={{
                background: 'white',
                borderRadius: '$1',
                boxShadow: '$xs',
                cursor: 'pointer',
                padding: '$4'
            }}
            onClick={() => {
                history.push(HeaventPaths.POSITION(organizationId, eventId, props.position.id));
            }}
        >
            <Flex direction="column">
                <Box color="gray800">{props.position.name}</Box>
                <Box color="gray500" fontSize="textXs">
                    {intervalService.toDisplayString(props.position.range)}
                </Box>
            </Flex>

            <Box>
                <I icon="chevron-right" />
            </Box>
        </Flex>
    );
};
