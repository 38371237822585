import * as React from 'react';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { UserProfileHistoryCampaignRow } from './userProfileHistoryCampaignRow';
import { Table } from 'common/src/designSystem/components/table/table';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { useUserProfileHistoryCampaignsQuery } from '../../../generated/graphqlHooks';
import { Emptyable } from 'common/src/util/emptyable';
import { EventId, OrganizationId, UsersInfoId } from 'common/src/generated/types';

interface IUserProfileHistoryCampaignsProps {
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;
}

export const UserProfileHistoryCampaigns = (props: IUserProfileHistoryCampaignsProps) => {
    const translate = useTranslate();
    const { data, isLoading } = useUserProfileHistoryCampaignsQuery({
        organizationId: props.organizationId,
        eventId: props.eventId,
        userInfoId: props.userInfoId
    });

    return (
        <Table hideBorder={true}>
            <HeaderRow>
                <HeaderCell>{translate('nom_de_la_campa_45063')}</HeaderCell>
                <HeaderCell width={150}>{translate('type_35427')}</HeaderCell>
                <HeaderCell width={200}>{translate('status_06428')}</HeaderCell>
            </HeaderRow>

            {isLoading ? (
                <>
                    <RowSkeleton />
                    <RowSkeleton />
                    <RowSkeleton />
                </>
            ) : (
                data.organization.userInfo.campaigns.nodes.map((campaign) => <UserProfileHistoryCampaignRow key={campaign.id} campaign={campaign} />)
            )}
        </Table>
    );
};
