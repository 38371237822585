import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import { Button } from '../components/button';
import { Modal } from '../components/modal';
import { TextInput } from '../components/textInput';

export const Modals = () => {
    const [isSmallModalOpen, setIsSmallModalOpen] = React.useState(false);
    const closeSmallModal = () => {
        setIsSmallModalOpen(false);
    };
    const [isTextModalOpen, setIsTextModalOpen] = React.useState(false);
    const closeTextModal = () => {
        setIsTextModalOpen(false);
    };
    const [isMediumModalOpen, setIsMediumModalOpen] = React.useState(false);
    const closeMediumModal = () => {
        setIsMediumModalOpen(false);
    };
    const [searchValue, setSearchValue] = React.useState('');

    return (
        <Flex direction="column" gap="6">
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Modals
            </Box>

            <Flex gap="3">
                <Button
                    onClick={() => {
                        setIsSmallModalOpen(true);
                    }}
                >
                    Open small modal
                </Button>

                <Button
                    onClick={() => {
                        setIsTextModalOpen(true);
                    }}
                >
                    Open small modal with text
                </Button>

                <Button
                    onClick={() => {
                        setIsMediumModalOpen(true);
                    }}
                >
                    Open medium modal
                </Button>
            </Flex>

            {isSmallModalOpen && (
                <Modal size="sm" onClose={closeSmallModal}>
                    <>
                        <Box color="gray900" textAlign="center">
                            My small modal
                        </Box>

                        <Spacer height="6" />

                        <Flex justify="end" gap="3">
                            <Button onClick={closeSmallModal}>Ok !</Button>

                            <Button color="white" onClick={closeSmallModal}>
                                Cancel
                            </Button>
                        </Flex>
                    </>
                </Modal>
            )}

            {isTextModalOpen && (
                <Modal size="sm" onClose={closeTextModal}>
                    <>
                        <Box
                            color="gray900"
                            fontSize="textLg"
                            fontWeight="semiBold"
                            textAlign="center"
                        >
                            My small modal with text
                        </Box>

                        <Spacer height="6" />

                        <Box
                            color="gray800"
                            css={{
                                flex: '1',
                                overflow: 'auto'
                            }}
                        >
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam arcu
                            mauris, ultricies at mollis vel, tempor sed arcu. Maecenas id erat quis
                            neque bibendum tincidunt id vel arcu. Vivamus quis lacus eget felis
                            elementum tincidunt. Ut fringilla ipsum vitae placerat lobortis. Sed
                            consequat maximus rhoncus. Integer mattis lacinia nunc vulputate
                            tincidunt. Quisque nec fringilla mauris. Integer mollis eget nibh id
                            ultricies. Phasellus consequat aliquam risus eget auctor. Curabitur
                            vulputate lobortis elit, eget blandit leo ultrices vitae. Vivamus
                            placerat consequat egestas. Curabitur eu nulla dictum, ornare est non,
                            dictum sapien. Vestibulum eu nisi lorem. Pellentesque habitant morbi
                            tristique senectus et netus et malesuada fames ac turpis egestas. Etiam
                            laoreet orci nec scelerisque dictum. Sed ut dapibus lorem, laoreet
                            aliquam leo. Sed fringilla lacinia magna, sit amet rutrum ex porttitor
                            eu. Pellentesque tempor velit eget diam ultricies facilisis. Sed
                            ultrices elit orci, ac volutpat nibh blandit id. Nullam accumsan
                            facilisis consectetur. Nunc dapibus cursus est, nec tristique erat
                            hendrerit quis. Praesent elementum commodo nisl in scelerisque. In
                            luctus ultrices leo laoreet scelerisque. Proin volutpat ac elit ac
                            mollis. Nullam nec nisl sagittis, pharetra ligula et, dignissim sem.
                            Aenean diam lacus, gravida et orci sit amet, volutpat volutpat leo. In
                            efficitur tempus tempor. Duis in orci in dui aliquet pretium. Nunc nec
                            enim eget nibh pellentesque convallis. Duis ex ligula, blandit ac
                            posuere quis, mattis eu dui. Praesent pulvinar vestibulum blandit.
                            Aenean tincidunt auctor odio non maximus. Vivamus ac venenatis elit, ut
                            convallis ex. Sed tortor purus, interdum at blandit ac, tempor ut
                            lectus. Curabitur viverra, tortor sit amet blandit gravida, est felis
                            hendrerit ligula, id pharetra massa odio non nunc. Duis nisi nunc,
                            mattis a nisi in, imperdiet hendrerit nibh. Donec pellentesque pretium
                            diam in congue. Praesent ullamcorper molestie tortor, a laoreet justo
                            vehicula quis. Etiam enim nibh, cursus quis nisi a, ultricies porttitor
                            sapien. Vestibulum dolor nunc, consequat at tincidunt non, maximus ut
                            erat. Nunc nisi leo, scelerisque sit amet enim eget, dictum ultricies
                            mi. Mauris quam augue, sagittis in feugiat at, pharetra vitae purus.
                            Quisque porttitor ipsum mollis ipsum elementum condimentum. Etiam
                            posuere dapibus venenatis. Maecenas molestie auctor faucibus. Aenean
                            quis semper risus.
                        </Box>

                        <Spacer height="6" />

                        <Flex justify="end" gap="3">
                            <Button onClick={closeTextModal}>Ok !</Button>

                            <Button color="white" onClick={closeTextModal}>
                                Cancel
                            </Button>
                        </Flex>
                    </>
                </Modal>
            )}

            {isMediumModalOpen && (
                <Modal size="md" onClose={closeMediumModal}>
                    <>
                        <Box color="gray900" fontSize="textLg" textAlign="center">
                            Ajouter un bénévole à votre événement
                        </Box>

                        <Box color="gray500" textAlign="center">
                            Ajouter un bénévole existant ou créer en un nouveau
                        </Box>

                        <Spacer height="6" />

                        <Box color="inherit">
                            <TextInput
                                value={searchValue}
                                onChange={setSearchValue}
                                icon="magnifying-glass"
                                placeholder="Rechercher ou créer un bénévole"
                            />
                        </Box>

                        <Spacer height="6" />

                        <Flex justify="end" gap="3">
                            <Button onClick={closeMediumModal}>Ajouter le bénévole</Button>

                            <Button color="white" onClick={closeMediumModal}>
                                Annuler
                            </Button>
                        </Flex>
                    </>
                </Modal>
            )}
        </Flex>
    );
};
