import { groupBy } from 'lodash-es';
import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { UltraMarinPosition } from './ultraMarinPosition';

interface IUltraMarinAssignmentProps {
    event: DocumentUserCustomBadgeFragment;
}

export const UltraMarinAssignment = (props: IUltraMarinAssignmentProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const byMission = Object.values(
        groupBy(vr.positionsSlotsUsersInfos, ({ position }) => position.id)
    );

    return (
        <>
            {byMission.map((psuis, index) => <UltraMarinPosition key={index} psuis={psuis} userInfo={ui} />)}
        </>
    );
};
