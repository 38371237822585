import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { useVolunteerRegistrationAccreditMutation } from 'common-front/src/generated/graphqlHooks';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    AccreditationsSlotId,
    EventId,
    VolunteerRegistrationFragment,
    VolunteersRegistrationId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { getAccreditationsBadges } from 'common/src/vo/accreditation';
import { fullName } from 'common/src/vo/accreditationSlot';
import { groupBy } from 'lodash-es';
import * as React from 'react';
import { executeUpdateCellAccreditationsSlotsQuery } from '../../../generated/graphqlHooks';
import { UpdateCellDropdown } from './updateCellDropdown';

interface IVolunteerRegistrationAccreditationsProps {
    eventId: EventId;
    firstCellCss: any;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
    setIsSidebarOpen(isOpen: boolean): void;
    setVolunteerRegistrationId?(volunteerRegistrationId: VolunteersRegistrationId): void;
}

export const VolunteerRegistrationAccreditations = ({
    eventId,
    firstCellCss,
    reload,
    setIsSidebarOpen,
    setVolunteerRegistrationId,
    volunteerRegistration
}: IVolunteerRegistrationAccreditationsProps) => {
    const translate = useTranslate();
    const { mutate: volunteerRegistrationAccredit } = useVolunteerRegistrationAccreditMutation();
    const dateTimeService = useService(DateTimeService);
    const { isEditMode } = useSegmentsContext();
    const [isFirstTime, setIsFirstTime] = React.useState(true);
    const [accreditations, setAccreditations] = React.useState<any[]>([]);
    const auis = React.useMemo(() => volunteerRegistration?.accreditationsUsersInfos || [], [volunteerRegistration]);
    const badges = React.useMemo(() => getAccreditationsBadges(dateTimeService, auis), [auis]);
    const initialValue = React.useMemo(() => auis.map((aui) => aui.accreditationSlot.id), [auis]);
    const onStateChange = React.useCallback(
        async (isOpen) => {
            if (isOpen && isFirstTime) {
                const {
                    event: { accreditationsSlots }
                } = await executeUpdateCellAccreditationsSlotsQuery(
                    { eventId: eventId },
                    await getToken()
                );

                setAccreditations(
                    Object.entries(
                        groupBy(accreditationsSlots.nodes, (as) => as.accreditation.name)
                    )
                );
                setIsFirstTime(false);
            }
        },
        [isFirstTime]
    );
    const onSave = React.useCallback(
        async (value: AccreditationsSlotId[]) => {
            await volunteerRegistrationAccredit({
                eventId: eventId,
                volunteerRegistrationId: volunteerRegistration.id,
                accredit: { accreditationsSlotsIds: value }
            });

            reload();
        },
        [eventId, volunteerRegistration, volunteerRegistrationAccredit, reload]
    );

    if (isEditMode) {
        return (
            <UpdateCellDropdown
                firstCellCss={{ ...firstCellCss, gap: '$2' }}
                initialValue={initialValue}
                renderValue={() => <BadgesPlus badges={badges} doNotSort={true} />}
                onSave={onSave}
                onStateChange={onStateChange}
            >
                {(value, setValue) => isFirstTime ? (
                        <Flex direction="column" gap="1" width={1}>
                            <Skeleton height={20} width={1} borderRadius="$1" />
                            <Skeleton height={40} width={1} borderRadius="$1" />
                        </Flex>
                    ) : (
                        <RichSelect
                            isSearchVisible={true}
                            multiple={true}
                            label={translate('accr_ditations_39450')}
                            renderOnPortal={true}
                            values={value}
                            onChange={setValue}
                        >
                            {accreditations.map(([accreditationName, slots], index) => (
                                    <optgroup key={index} label={accreditationName}>
                                        {slots.map((slot: any) => (
                                                <option key={slot.id} value={slot.id}>
                                                    {fullName(
                                                        dateTimeService,
                                                        slot,
                                                        accreditationName
                                                    )}
                                                </option>
                                            ))}
                                    </optgroup>
                                ))}
                        </RichSelect>
                    )}
            </UpdateCellDropdown>
        );
    } else {
        return (
            <Cell css={{ ...firstCellCss, border: '2px solid transparent' }}>
                <BadgesPlus
                    badges={badges}
                    doNotSort={true}
                    setIsSidebarOpen={setIsSidebarOpen}
                    setVolunteerRegistrationId={() =>
                        setVolunteerRegistrationId?.(volunteerRegistration.id)
                    }
                />
            </Cell>
        );
    }
};
