import { Flex } from 'common/src/designSystem/components/flex';
import { EventId, PositionId, PositionsSlotId } from 'common/src/generated/types';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import * as React from 'react';
import { executePositionSlotsMobileQuery } from '../../../../generated/graphqlHooks';
import { useLoadMore } from 'common-front/src/components/graphql/useLoadMore';
import { LoadMoreControl } from 'common-front/src/components/pagination/mobile/loadMoreControl';
import { PositionSlotsMobile } from './positionSlotsMobile';

const getNodes = (result: Awaited<ReturnType<typeof executePositionSlotsMobileQuery>>) =>
    result.event.positionsSlots.nodes;

interface IPositionSlotsMobileProps {
    open: (id: PositionsSlotId) => void;
    eventId: EventId;
    positionId: PositionId;
}

export const PositionSlotsListMobile = ({
    open,
    eventId,
    positionId
}: IPositionSlotsMobileProps) => {
    const variables = {
        eventId,
        positionId,
        limit: DEFAULT_PAGINATION_LIMIT,
        offset: 0
    };
    const { data, loader, loadMore, xs } = useLoadMore(
        executePositionSlotsMobileQuery,
        variables,
        getNodes
    );

    if (loader && data === undefined) {
        return loader;
    } else {
        return (
            <Flex direction="column" gap="7" css={{ marginBottom: '$7' }}>
                <PositionSlotsMobile open={open} slots={xs} />
                <LoadMoreControl
                    loadMore={loadMore}
                    pageInfo={data.event.positionsSlots.pageInfo}
                    isLoading={loader !== undefined}
                    buttonText="Afficher plus de créneaux"
                    statusText={`${xs.length} sur ${data.event.positionsSlots.totalCount} créneaux`}
                />
            </Flex>
        );
    }
};
