import { ExistingUserRow, ExistingUserSource } from 'common-front/src/users/create/existingUserRow';
import {
    DelegationId,
    EventId,
    ExistingVolunteerFragment,
    OrganizationId,
    UsersInfoId
} from 'common/src/generated/types';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import {
    useEventUsersInfosQuery,
    useOrganizationUsersInfosQuery
} from '../../generated/graphqlHooks';

type AvailableMember = {
    source?: ExistingUserSource;
} & ExistingVolunteerFragment;

interface IAvailableDelegationMembersProps {
    availableMembers: AvailableMember[];
    usersInfosIds: UsersInfoId[];

    select(userInfoId: UsersInfoId): void;
}

const AvailableDelegationMembers = ({
    availableMembers,
    select,
    usersInfosIds
}: IAvailableDelegationMembersProps) => (
        <>
            {availableMembers.map((userInfo) => {
                const state = usersInfosIds.includes(userInfo.id) ? 'checked' : 'unchecked';

                return (
                    <ExistingUserRow
                        key={userInfo.id}
                        state={state}
                        userInfo={userInfo}
                        onClick={() => {
                            select(userInfo.id);
                        }}
                    />
                );
            })}
        </>
    );

interface IAvailableDelegationMembersOrganizationProps {
    delegationId: DelegationId;
    nameOrEmail?: string;
    organizationId: OrganizationId;
    usersInfosIds: UsersInfoId[];

    select(userInfoId: UsersInfoId): void;
}

export const AvailableDelegationMembersOrganization = ({
    delegationId,
    nameOrEmail,
    organizationId,
    usersInfosIds,
    select
}: IAvailableDelegationMembersOrganizationProps) => {
    const { data, loader } = useOrganizationUsersInfosQuery({
        organizationId,
        excludedDelegationId: delegationId,
        name: isNonEmptyString(nameOrEmail) ? nameOrEmail : null
    });

    return (
        loader || (
            <AvailableDelegationMembers
                availableMembers={data.organization.usersInfos.nodes}
                usersInfosIds={usersInfosIds}
                select={select}
            />
        )
    );
};

interface IAvailableDelegationMembersEventProps {
    delegationId: DelegationId;
    eventId: EventId;
    nameOrEmail?: string;
    usersInfosIds: UsersInfoId[];

    select(userInfoId: UsersInfoId): void;
}

export const AvailableDelegationMembersEvent = ({
    delegationId,
    eventId,
    nameOrEmail,
    usersInfosIds,
    select
}: IAvailableDelegationMembersEventProps) => {
    const { data, loader } = useEventUsersInfosQuery({
        eventId,
        delegationId,
        name: isNonEmptyString(nameOrEmail) ? nameOrEmail : null
    });
    const availableMembers = React.useMemo(() => {
        if (data.event) {
            const eventMembers: AvailableMember[] = data.event.volunteersRegistrations.nodes.map(
                (vr) => ({
                    ...vr.userInfo,
                    source: ExistingUserSource.Event
                })
            );
            const eventMembersIds = eventMembers.map(({ id }) => id);
            const parentMembers: AvailableMember[] = data.event.delegation.parentUsersInfos.flatMap(
                (ui) =>
                    eventMembersIds.includes(ui.id)
                        ? []
                        : [
                              {
                                  ...ui,
                                  source: ExistingUserSource.ParentDelegation
                              }
                          ]
            );

            return eventMembers.concat(parentMembers);
        } else {
            return [];
        }
    }, [data.event]);

    return (
        loader || (
            <AvailableDelegationMembers
                availableMembers={availableMembers}
                usersInfosIds={usersInfosIds}
                select={select}
            />
        )
    );
};
