import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { Emptyable } from '../../../util/emptyable';
import { BadgePocBack } from './badgePocBack';
import { BadgePocFront } from './badgePocFront';

interface IBadgePocProps {
    event: DocumentUserCustomBadgeFragment;
    logoUrl: Emptyable<string>;
}

export const BadgePoc = (props: IBadgePocProps) => (
        <>
            <BadgePocFront event={props.event} logoUrl={props.logoUrl} />

            <BadgePocBack event={props.event} logoUrl={props.logoUrl} />
        </>
    );
