import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { heaventEnvDisplayName, heaventEnvSupportEmail } from 'common/src/heaventEnv';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import ExpiredImage from 'svgComponents/expired.svg';

export const OrganizationExpired = () => {
    const translate = useTranslate();

    return (
        <Flex
            align="center"
            justify="center"
            height={1}
            width={1}
            css={{
                background: '$gray50'
            }}
        >
            <Flex
                direction="column"
                align="center"
                justify="center"
                gap="2"
                width={840}
                css={{
                    background: 'white',
                    borderRadius: '$2',
                    boxShadow: '$xs',
                    padding: '$9'
                }}
            >
                <ExpiredImage />

                <Box color="gray900" fontSize="displayXs" fontWeight="semiBold">
                    {translate('votre_compte_1_64779', heaventEnvDisplayName())}
                </Box>

                <Box color="gray500" textAlign="center">
                    {translate('ne_vous_inqui_t_38880')} <br />
                    {translate(
                        `contactez_nous_35526`,
                        heaventEnvSupportEmail(),
                        heaventEnvDisplayName()
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};
