import { DropdownEllipsis } from 'common-front/src/designSystem/components/dropdown/dropdownEllipsis';
import { ItemIcon } from 'common-front/src/designSystem/components/dropdown/item';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import {
    OrganizationCategoriesCustomFieldsQuery,
    OrganizationId
} from 'common/src/generated/types';
import * as React from 'react';
import { DeleteCustomFieldCategory } from '../../customFields/list/deleteCustomFieldCategory';
import { CreateUpdateCustomFieldCategory } from '../../customFieldsCategories/create/createUpdateCustomFieldCategory';

interface IOrganizationCategoriesCustomFieldsRowDropdownProps {
    customFieldCategory: OrganizationCategoriesCustomFieldsQuery['organization']['customFieldsCategories']['nodes'][0];
    organizationId: OrganizationId;

    reload(): void;
}

export const OrganizationCategoriesCustomFieldsRowDropdown = (
    props: IOrganizationCategoriesCustomFieldsRowDropdownProps
) => {
    const { translate } = useHeavent();
    const [isRenameOpen, setIsRenameOpen] = React.useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <>
            <DropdownEllipsis>
                <ItemIcon
                    icon="pen"
                    onClick={() => {
                        setIsRenameOpen(true);
                    }}
                >
                    {translate('renommer_42135')}
                </ItemIcon>

                <ItemIcon
                    color={
                        props.customFieldCategory.numberOfCustomFields === 0 ? 'red' : 'disabled'
                    }
                    icon="trash-can"
                    onClick={() => {
                        setIsDeleteOpen(true);
                    }}
                >
                    {translate('supprimer_43083')}
                </ItemIcon>
            </DropdownEllipsis>

            {isRenameOpen && (
                <CreateUpdateCustomFieldCategory
                    customFieldCategory={props.customFieldCategory}
                    organizationId={props.organizationId}
                    onClose={() => {
                        setIsRenameOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}

            {isDeleteOpen && (
                <DeleteCustomFieldCategory
                    customFieldCategory={props.customFieldCategory}
                    organizationId={props.organizationId}
                    onSuccess={props.reload}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                />
            )}
        </>
    );
};
