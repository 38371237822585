import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    AccreditationId,
    AccreditationsCategoryId,
    AccreditInfosQuery,
    SegmentId,
    TagId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';

interface IAccreditAppliedFiltersProps {
    accreditationsCategoriesIds: AccreditationsCategoryId[] | null;
    accreditationsIds: AccreditationId[] | null;
    date: DateTime | null;
    event: AccreditInfosQuery['event'];
    excludeFull: boolean;
    name: string;
    organization: AccreditInfosQuery['organization'];
    segmentId: SegmentId | null;
    tagsIds: TagId[] | null;

    reset(): void;
    setAccreditationsCategoriesIds(
        accreditationsCategoriesids: AccreditationsCategoryId[] | null
    ): void;
    setAccreditationsIds(accreditationsIds: AccreditationId[] | null): void;
    setDate(date: DateTime | null): void;
    setExcludeFull(excludeFull: boolean): void;
    setName(name: string): void;
    setSegmentId(segmentId: SegmentId | null): void;
    setTagsIds(tagsIds: TagId[] | null): void;
}

export const AccreditAppliedFilters = (props: IAccreditAppliedFiltersProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const selectedCategories = React.useMemo(() => (props.accreditationsCategoriesIds || [])
            .map((id) => props.event.accreditationsCategories.find((ac) => ac.id === id)!.name)
            .join(', '), [props.accreditationsCategoriesIds, props.event]);
    const selectedAccreditations = React.useMemo(() => (props.accreditationsIds || [])
            .map((id) => props.event.accreditations.nodes.find((a) => a.id === id)!.name)
            .join(', '), [props.accreditationsIds, props.event]);
    const selectedDate = React.useMemo(() => props.date
            ? dateTimeService.toLocaleString(props.date, LocaleFormats.DateOnly.MonthLong)
            : '', [props.date]);
    const selectedSegment = React.useMemo(() => props.segmentId
            ? props.event.segments.find((s) => s.id === props.segmentId)!.name
            : '', [props.segmentId, props.event]);
    const selectedTags = React.useMemo(() => (props.tagsIds || [])
            .map((id) => props.organization.tags.nodes.find((t) => t.id === id)!.name)
            .join(', '), [props.tagsIds, props.organization]);
    const isAppliedFiltersVisible =
        isNonEmptyString(selectedCategories) ||
        isNonEmptyString(selectedAccreditations) ||
        isNonEmptyString(selectedDate) ||
        isNonEmptyString(selectedSegment) ||
        isNonEmptyString(selectedTags) ||
        props.excludeFull;

    return isAppliedFiltersVisible ? (
        <Flex align="center" gap="3" wrap="wrap">
            {isNonEmptyString(selectedCategories) && (
                <Badge
                    color="white"
                    rightIcon="xmark"
                    onRightIconClick={() => {
                        props.setAccreditationsCategoriesIds([]);
                    }}
                >
                    {translate('cat_gories_1_42029', selectedCategories)}
                </Badge>
            )}

            {isNonEmptyString(selectedAccreditations) && (
                <Badge
                    color="white"
                    rightIcon="xmark"
                    onRightIconClick={() => {
                        props.setAccreditationsIds([]);
                    }}
                >
                    {translate('accr_ditations_30765', selectedAccreditations)}
                </Badge>
            )}

            {isNonEmptyString(selectedDate) && (
                <Badge
                    color="white"
                    rightIcon="xmark"
                    onRightIconClick={() => {
                        props.setDate(null);
                    }}
                >
                    {translate('date_1_17337', selectedDate)}
                </Badge>
            )}

            {isNonEmptyString(selectedSegment) && (
                <Badge
                    color="white"
                    rightIcon="xmark"
                    onRightIconClick={() => {
                        props.setSegmentId(null);
                    }}
                >
                    {translate('vue_1_29260', selectedSegment)}
                </Badge>
            )}

            {isNonEmptyString(selectedTags) && (
                <Badge
                    color="white"
                    rightIcon="xmark"
                    onRightIconClick={() => {
                        props.setTagsIds([]);
                    }}
                >
                    {translate('tags_1_43158', selectedTags)}
                </Badge>
            )}

            {props.excludeFull && (
                <Badge
                    color="white"
                    rightIcon="xmark"
                    onRightIconClick={() => {
                        props.setExcludeFull(false);
                    }}
                >
                    {translate('cr_neaux_comple_77561')}
                </Badge>
            )}

            <Box font="gray700 textXs medium" css={{ cursor: 'pointer' }} onClick={props.reset}>
                {translate('r_initialiser_l_66835')}
            </Box>
        </Flex>
    ) : null;
};
