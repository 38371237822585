import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { Flex } from 'common/src/designSystem/components/flex';
import { VolunteersImportInput } from 'common/src/generated/types';
import { VolunteersImportInputService } from 'common/src/input/volunteersImportInput';
import { ValidateService } from 'common/src/services/validateService';
import { useParams, useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { FormApi } from 'final-form';
import * as React from 'react';
import {
    useOrganizationCustomFieldsFrontQuery,
    useVolunteersImportMutation
} from '../../generated/graphqlHooks';
import { useNotificationContext } from '../../notifications/notificationContext';
import { CSVFormBox } from './csvFormBox';
import { MappingsFormBoxes } from './mappingsFormBoxes';
import { VolunteersImportProcessingAlert } from './volunteersImportProcessingAlert';

const isFileValid = (form: FormApi<{ input: VolunteersImportInputWithFile }>): boolean => (
        !!form.getFieldState('input.csv.key')?.valid &&
        !!form.getFieldState('input.csv.file')?.valid
    );

const removeFileFromInput = (input: VolunteersImportInputWithFile) => {
    const csvCopy = { ...input.csv };

    delete csvCopy.file;

    return {
        ...input,
        csv: csvCopy
    };
};

export type VolunteersImportInputWithFile = VolunteersImportInput & {
    csv: {
        file?: File;
    };
};

interface IVolunteersImportProps {
    fromPath: string;
}

export const VolunteersImport = ({ fromPath }: IVolunteersImportProps) => {
    const translate = useTranslate();
    const volunteersImportInput = useService(VolunteersImportInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const { eventId, organizationId } = useParams();
    const { data, loader } = useOrganizationCustomFieldsFrontQuery({ organizationId });
    const { mutate } = useVolunteersImportMutation();
    const { checkVolunteersImport } = useNotificationContext();

    if (loader) {
        return loader;
    } else {
        const customFields = data.organization.customFields.nodes;

        return (
            <Form
                height={1}
                initialValues={{
                    input: volunteersImportInput.default().input as VolunteersImportInputWithFile
                }}
                validate={validateService.validateForForm(volunteersImportInput.schema())}
                onSubmit={async ({ input }) => {
                    const inputWithoutFile = removeFileFromInput(input);

                    try {
                        const { jobId } = await mutate({
                            eventId,
                            organizationId,
                            input: inputWithoutFile
                        });
                        if (centeredContainerRef.current) {
                            centeredContainerRef.current.scrollTop = 0;
                        }
                        checkVolunteersImport(organizationId, jobId);
                    } catch (e) {
                        /* Already displayed in <FormErrors />. */
                    }
                }}
                hideErrors={true}
                onShowErrors={() => {
                    if (centeredContainerRef.current) {
                        centeredContainerRef.current.scrollTop = 0;
                    }
                }}
                render={({ form, handleSubmit, submitting, values }) => (
                        <FullScreenPopup
                            color="dark"
                            category={translate('import_de_membr_48618')}
                            title={translate('nouvel_import_48306')}
                            closePath={fromPath}
                            button={
                                <Button isLoading={submitting} onClick={handleSubmit}>
                                    {translate('importer_la_lis_12866')}
                                </Button>
                            }
                        >
                            <CenteredContainer ref={centeredContainerRef}>
                                {form.getState().submitSucceeded && (
                                    <VolunteersImportProcessingAlert />
                                )}
                                <FormErrors />

                                <Flex direction="column" gap="5">
                                    <CSVFormBox />

                                    {values.input.csv?.file && isFileValid(form) && (
                                        <MappingsFormBoxes
                                            customFields={customFields}
                                            file={values.input.csv.file}
                                            form={form}
                                            values={values}
                                        />
                                    )}
                                </Flex>
                            </CenteredContainer>
                        </FullScreenPopup>
                    )}
            />
        );
    }
};
