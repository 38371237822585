import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A6_SIZES } from '../../../util/pdf';
import { BadgeJokersfrancesloveniaA6Back } from './badgeJokersfrancesloveniaA6Back';
import { BadgeJokersfrancesloveniaA6Front } from './badgeJokersfrancesloveniaA6Front';

interface IBadgeJokersfrancesloveniaA6Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeJokersfrancesloveniaA6 = (props: IBadgeJokersfrancesloveniaA6Props) => (
        <Flex height={A6_SIZES['96dpi'].height} width={A6_SIZES['96dpi'].width * 2}>
            <BadgeJokersfrancesloveniaA6Front event={props.event} />

            <BadgeJokersfrancesloveniaA6Back />
        </Flex>
    );
