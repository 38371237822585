import { useLoadMore } from 'common-front/src/components/graphql/useLoadMore';
import { Button } from 'common-front/src/designSystem/components/button';
import { CheckInState, PositionsSlotsUsersInfoId } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useState } from 'react';
import {
    executePositionSlotMembersMobileQuery,
    usePositionsSlotsUsersInfosCheckInMobileMutation
} from '../../../../generated/graphqlHooks';
import { PositionSlotMembersCheckFormMobile } from './positionSlotMembersCheckFormMobile';

const getNodes = (result: Awaited<ReturnType<typeof executePositionSlotMembersMobileQuery>>) =>
    result.event.volunteersRegistrations.nodes;

export const PositionSlotMembersCheckInListMobile = () => {
    const { eventId, positionSlotId } = useParams();
    const translate = useTranslate();

    const variables = {
        eventId,
        positionSlotId,
        predicates: [{ operator: 'isNot', value: [CheckInState.CheckedIn], slug: 'checkInState' }],
        limit: 50,
        offset: 0
    };
    const { data, loader, loadMore, xs } = useLoadMore(
        executePositionSlotMembersMobileQuery,
        variables,
        getNodes
    );
    const { isLoading, mutate } = usePositionsSlotsUsersInfosCheckInMobileMutation();
    const [disabled, disable] = useState<PositionsSlotsUsersInfoId[]>([]);

    if (loader && data === undefined) {
        return loader;
    } else {
        const onSubmit = async (positionsSlotsUsersInfosIds: PositionsSlotsUsersInfoId[]) => {
            await mutate({ eventId, positionsSlotsUsersInfosIds });
            disable([...disabled, ...positionsSlotsUsersInfosIds]);
        };

        return (
            <PositionSlotMembersCheckFormMobile
                onSubmit={onSubmit}
                data={data}
                loader={loader}
                loadMore={loadMore}
                xs={xs}
                disabled={disabled}
            >
                <Button textAlign="center" isLoading={isLoading}>
                    {translate('pointer_80927')}
                </Button>
            </PositionSlotMembersCheckFormMobile>
        );
    }
};
