import { Flex } from 'common/src/designSystem/components/flex';
import { PositionId, PositionsMobileQuery } from 'common/src/generated/types';
import { groupBy, sortBy, values } from 'lodash-es';
import * as React from 'react';
import { PositionCardMobile } from './positionCardMobile';
import { PositionCategoryMobile } from './positionCategoryMobile';

interface IPositionCategoriesMobileProps {
    open: (id: PositionId) => void;
    positions: PositionsMobileQuery['event']['positions']['nodes'];
}

export const PositionCategoriesMobile = ({ open, positions }: IPositionCategoriesMobileProps) => {
    const positionsByCategory = React.useMemo(() => sortBy(
            values(groupBy(positions, (position) => position.category.id)),
            (positionsInCategory) => positionsInCategory[0].category.name
        ), [positions]);

    return (
        <>
            {positionsByCategory.map((positionsInCategory, index1) => (
                    <Flex direction="column" gap="3" key={index1}>
                        <PositionCategoryMobile category={positionsInCategory[0].category} />
                        {positionsInCategory.map((position, index2) => (
                            <PositionCardMobile open={open} position={position} key={index2} />
                        ))}
                    </Flex>
                ))}
        </>
    );
};
