import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { Span } from '../../../designSystem/components/span';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeGarorockProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeGarorock = (props: IBadgeGarorockProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const adhesionPayee = ui.fields.cf19022 === true;
    const hasGaroTri = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionCategory.id === 14199
    );

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/garorock/badge_v2.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$inter',
                position: 'relative'
            }}
        >
            <Flex
                direction="column"
                css={{
                    left: '96px',
                    position: 'absolute',
                    top: '239px'
                }}
            >
                <Box
                    css={{
                        color: '#cc2536',
                        fontSize: '24px',
                        fontWeight: '700'
                    }}
                >
                    {ui.firstName}
                </Box>
                <Box
                    css={{
                        color: '#cc2536',
                        fontSize: '24px',
                        fontWeight: '700'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Spacer height="1" />

                <Box>
                    <Span fontWeight="semiBold">Code Garocash : </Span>
                    {vr.weezeventParticipantInfo?.idBarcode}
                </Box>

                <Box>
                    <Span fontWeight="semiBold">Bénévole : </Span>
                    {ui.garorockTypeBenevole}
                </Box>

                <Box>
                    <Span fontWeight="semiBold">
                        Taille T-Shirt [{hasGaroTri ? 'Garo Tri' : 'Bénévole'}] :{' '}
                    </Span>
                    {ui.garorockTshirt}
                </Box>

                <Box>
                    <Span fontWeight="semiBold">Adhésion : </Span>
                    {adhesionPayee ? 'Réglée' : 'Non réglée'}
                </Box>
            </Flex>

            <Box
                height={80}
                width={80}
                css={{
                    position: 'absolute',
                    right: '96px',
                    top: '268px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=80&width=80`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
