import { CreateUpdateCategoryModal } from 'common-front/src/components/createUpdateCategoryModal';
import { AccreditationsCategory, EventId } from 'common/src/generated/types';
import * as React from 'react';
import {
    useAccreditationCategoryCreateMutation,
    useAccreditationCategoryUpdateMutation
} from '../../generated/graphqlHooks';

type AccreditationCategoryCreate = Pick<AccreditationsCategory, 'id' | 'name'>;

interface ICreateAccreditationCategoryProps {
    eventId: EventId;

    onClose(): void;
    onSuccess(accreditationCategory: AccreditationCategoryCreate): void;
}

export const CreateAccreditationCategory = (props: ICreateAccreditationCategoryProps) => {
    const { mutate } = useAccreditationCategoryCreateMutation();

    return (
        <CreateUpdateCategoryModal
            initialName={''}
            isEdit={false}
            mutate={async (name: string) => (
                    await mutate({
                        eventId: props.eventId,
                        accreditationCategory: { name }
                    })
                ).accreditationCategoryCreate}
            onClose={props.onClose}
            onSuccess={props.onSuccess}
        />
    );
};

interface IUpdateAccreditationCategoryProps {
    accreditationCategory: Pick<AccreditationsCategory, 'id' | 'name'>;
    eventId: EventId;

    onClose(): void;
    onSuccess(): void;
}

export const UpdateAccreditationCategory = (props: IUpdateAccreditationCategoryProps) => {
    const { mutate } = useAccreditationCategoryUpdateMutation();

    return (
        <CreateUpdateCategoryModal
            initialName={props.accreditationCategory.name}
            isEdit={true}
            mutate={async (name) => (
                    await mutate({
                        eventId: props.eventId,
                        accreditationCategoryId: props.accreditationCategory.id,
                        accreditationCategory: { name }
                    })
                ).accreditationCategoryUpdate}
            onClose={props.onClose}
            onSuccess={props.onSuccess}
        />
    );
};
