import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IBadgeKermesseProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeKermesse = (props: IBadgeKermesseProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const accreditation = vr.accreditationsUsersInfos.map((aui) => aui.accreditation.name)[0];
    const days = sortBy(
        uniqBy(
            vr.accreditationsUsersInfos.flatMap((aui) => aui.accreditationSlot.date?.isValid ? [aui.accreditationSlot.date] : []),
            (d) => d.startOf('day').toMillis()
        ),
        (d) => d.toMillis()
    );

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/kermesse/background_seyne.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$poppins',
                position: 'relative'
            }}
        >
            <Flex
                direction="column"
                css={{
                    color: 'black',
                    fontSize: '24px',
                    fontWeight: '700',
                    left: '68px',
                    position: 'absolute',
                    top: '270px'
                }}
            >
                <Box>{ui.firstName}</Box>
                <Box>{ui.lastName}</Box>
            </Flex>

            <Box
                css={{
                    color: '#ffaed3',
                    fontSize: '20px',
                    fontWeight: '600',
                    left: '68px',
                    position: 'absolute',
                    top: '334px'
                }}
            >
                {vr.delegation?.name ?? ''}
            </Box>

            <Box
                css={{
                    color: '#ffaed3',
                    fontSize: '20px',
                    fontWeight: '600',
                    left: '68px',
                    position: 'absolute',
                    top: '360px'
                }}
            >
                Bracelet : {accreditation || ''}
            </Box>

            <Box
                height={90}
                width={90}
                css={{
                    position: 'absolute',
                    right: '68px',
                    top: '281px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=90&width=90`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>

            <Flex
                css={{
                    gap: '6px',
                    left: '68px',
                    position: 'absolute',
                    top: '572px'
                }}
            >
                {days.map((day, index) => (
                        <Flex
                            key={index}
                            direction="column"
                            align="center"
                            css={{
                                border: '1px solid black',
                                borderRadius: '4px',
                                padding: '4px 6px'
                            }}
                        >
                            <Box css={{ color: 'black', fontSize: '16px', fontWeight: '700' }}>
                                {day.toFormat('dd')}
                            </Box>
                            <Box
                                css={{
                                    color: 'black',
                                    fontSize: '10px',
                                    fontWeight: '500',
                                    textTransform: 'uppercase'
                                }}
                            >
                                {day.toFormat('MMMM', { locale: 'fr' })}
                            </Box>
                        </Flex>
                    ))}
            </Flex>
        </Flex>
    );
};
