import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { Box } from 'common/src/designSystem/components/box';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import { OrganizationId, OrganizationTagsQuery } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useTagDeleteMutation } from '../../generated/graphqlHooks';
import { TagUpdateNameModal } from './tagUpdateNameModal';

interface ITagRowProps {
    organizationId: OrganizationId;
    tag: OrganizationTagsQuery['organization']['tags']['nodes'][0];

    reload(): void;
}

export const TagRow = (props: ITagRowProps) => {
    const translate = useTranslate();
    const { mutate: tagDelete } = useTagDeleteMutation();
    const [isRenameOpen, setIsRenameOpen] = React.useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = React.useState(false);

    return (
        <>
            <Row
                css={{
                    '&:hover': {
                        background: '$gray100'
                    }
                }}
            >
                <Cell css={{ color: '$gray800' }}>{props.tag.name}</Cell>
                <Cell>{props.tag.numberOfTimesUsed}</Cell>
                <CellControls justify="center">
                    <Tooltip>
                        <Trigger>
                            <Box>
                                <I
                                    icon="pen"
                                    onClick={() => {
                                        setIsRenameOpen(true);
                                    }}
                                />
                            </Box>
                        </Trigger>

                        <Content placement="top">{translate('_diter_62574')}</Content>
                    </Tooltip>

                    <Tooltip>
                        <Trigger>
                            <Box color="error700">
                                <I
                                    icon="trash-can"
                                    onClick={() => {
                                        setIsDeleteOpen(true);
                                    }}
                                />
                            </Box>
                        </Trigger>

                        <Content placement="top">{translate('supprimer_02105')}</Content>
                    </Tooltip>
                </CellControls>
            </Row>

            {isRenameOpen && (
                <TagUpdateNameModal
                    organizationId={props.organizationId}
                    name={props.tag.name}
                    tagId={props.tag.id}
                    onClose={() => {
                        setIsRenameOpen(false);
                    }}
                    onSuccess={props.reload}
                />
            )}

            {isDeleteOpen && (
                <DeleteModal
                    text={translate('suppression_de_73669', props.tag.name)}
                    subtext={translate('_tes_vous_s_r_d_70200')}
                    buttonText={translate('supprimer_43083')}
                    onDelete={async () => {
                        await tagDelete({
                            organizationId: props.organizationId,
                            tagId: props.tag.id
                        });

                        props.reload();
                    }}
                    onClose={() => {
                        setIsDeleteOpen(false);
                    }}
                />
            )}
        </>
    );
};
