import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { FilterPredicate, Operator, Predicate } from 'common/src/vo/segment';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Button } from '../../designSystem/components/button';
import { DateInput } from '../../designSystem/components/date/dateInput';
import { RadioText } from '../../designSystem/components/radio';
import { RichSelect } from '../../designSystem/components/richSelect/richSelect';
import { DayMonthYearSelector } from './date/dayMonthYearSelector';

interface IAddFormsInsertedAtFilterProps {
    filterPredicate: FilterPredicate;

    onAdd(predicate: Predicate): void;
}

export const AddFormsInsertedAtFilter = (props: IAddFormsInsertedAtFilterProps) => {
    const translate = useTranslate();
    const options = React.useMemo(() => (props.filterPredicate.filter.values || []).map(({ id, name }) => (
                <option key={id} value={id}>
                    {name}
                </option>
            )), [props.filterPredicate.filter]);
    const [operator, setOperator] = React.useState<Operator>(
        props.filterPredicate.predicate?.operator ?? 'is'
    );
    const [value, setValue] = React.useState<number[]>(
        props.filterPredicate.predicate?.value ?? []
    );
    const [value2, setValue2] = React.useState<DateTime | string>(
        props.filterPredicate.predicate?.value2 ?? ''
    );
    const [valueDate, setValueDate] = React.useState<any>(
        props.filterPredicate.predicate?.valueDate ?? {
            day: -1,
            month: -1,
            year: -1
        }
    );
    const valid =
        isNonEmptyArray(value) && ['is', 'isNot'].includes(operator)
            ? (typeof valueDate.day === 'number' && valueDate.day !== -1) ||
              (typeof valueDate.month === 'number' && valueDate.month !== -1) ||
              (typeof valueDate.year === 'number' && valueDate.year !== -1)
            : ['isBefore', 'isAfter'].includes(operator)
            ? DateTime.isDateTime(value2) && value2.isValid
            : false;

    return (
        <>
            <RichSelect
                isSearchVisible={true}
                isSelectAllVisible={true}
                multiple={true}
                values={value}
                onChange={setValue}
                placeholder={translate('s_lectionner_un_13356')}
            >
                {options}
            </RichSelect>

            {value.length > 0 && (
                <>
                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'is' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('is');
                        }}
                    >
                        {translate('est_25967')}
                    </RadioText>

                    {operator === 'is' && (
                        <DayMonthYearSelector value={valueDate} setValue={setValueDate} />
                    )}

                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isNot' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isNot');
                        }}
                    >
                        {translate('n_est_pas_97826')}
                    </RadioText>

                    {operator === 'isNot' && (
                        <DayMonthYearSelector value={valueDate} setValue={setValueDate} />
                    )}

                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isBefore' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isBefore');
                        }}
                    >
                        {translate('est_avant_le_16689')}
                    </RadioText>

                    {operator === 'isBefore' && (
                        <>
                            <Spacer height="1" />

                            <Flex>
                                <Spacer width="6" />
                                <DateInput
                                    min={props.filterPredicate.filter.startAt}
                                    max={props.filterPredicate.filter.endAt}
                                    value={value2}
                                    onChange={setValue2}
                                />
                            </Flex>

                            <Spacer height="1" />
                        </>
                    )}

                    <Spacer height="3" />

                    <RadioText
                        state={operator === 'isAfter' ? 'checked' : 'unchecked'}
                        onClick={() => {
                            setOperator('isAfter');
                        }}
                    >
                        {translate('est_apr_s_le_79703')}
                    </RadioText>

                    {operator === 'isAfter' && (
                        <>
                            <Spacer height="1" />

                            <Flex>
                                <Spacer width="6" />
                                <DateInput
                                    min={props.filterPredicate.filter.startAt}
                                    max={props.filterPredicate.filter.endAt}
                                    value={value2}
                                    onChange={setValue2}
                                />
                            </Flex>

                            <Spacer height="1" />
                        </>
                    )}
                </>
            )}

            <Spacer height="7" />

            <Flex>
                <Button
                    disabled={!valid}
                    onClick={() => {
                        props.onAdd({
                            operator,
                            value,
                            value2,
                            valueDate,
                            slug: props.filterPredicate.filter.slug
                        });
                    }}
                >
                    {translate('ajouter_le_filt_54222')}
                </Button>
            </Flex>
        </>
    );
};
