import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CreateDelegationFormFragment,
    CreatePositionTagsFragment,
    Delegation,
    DelegationId,
    DelegationInput,
    EventAccreditationsFragment,
    EventId,
    OrganizationId,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import {
    DelegationInputService,
    ICreateUpdateDelegationValues
} from 'common/src/input/delegationInput';
import { ValidateService } from 'common/src/services/validateService';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { DateTime } from 'luxon';
import * as React from 'react';
import { TagsForm } from '../../accreditations/create/tagsForm';
import {
    useDelegationCreateMutation,
    useDelegationEventInfosQuery,
    useDelegationEventToEditQuery,
    useDelegationOrganizationInfosQuery,
    useDelegationOrganizationToEditQuery,
    useDelegationUpdateMutation
} from '../../generated/graphqlHooks';
import { AccreditationsForm } from './accreditationsForm';
import { AccreditationsSelectPanel } from './accreditationsSelectPanel';
import {
    CreateEventDelegationInformations,
    CreateOrganizationDelegationInformations
} from './createDelegationInformations';
import { CreateDelegationParameters } from './createDelegationParameters';

type CreateUpdateDelegationBaseProps = {
    closePath: string;
    customFields: SegmentCustomFieldFragment[];
    forms: CreateDelegationFormFragment[];
    initialValues: ICreateUpdateDelegationValues;
    isEdit: boolean;
    tags: CreatePositionTagsFragment['tags']['nodes'];

    mutate(args: {
        organizationId: OrganizationId;
        eventId: EventId;
        delegation: DelegationInput;
    }): Promise<DelegationId>;
};

type CreateUpdateEventDelegationProps = CreateUpdateDelegationBaseProps & {
    accreditationsCategories: EventAccreditationsFragment['accreditationsCategories'];
    delegationType: 'event';
    eventStartAt: DateTime;
    eventEndAt: DateTime;
    organizationDelegations: Array<Pick<Delegation, 'id' | 'name'>>;
};

type CreateUpdateOrganizationDelegationProps = CreateUpdateDelegationBaseProps & {
    delegationType: 'organization';
};

type CreateUpdateDelegationProps =
    | CreateUpdateEventDelegationProps
    | CreateUpdateOrganizationDelegationProps;

const CreateUpdateDelegation = (props: CreateUpdateDelegationProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const validateService = useService(ValidateService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const isPrivateNoteOpen = React.useMemo(() => props.isEdit && isNonEmptyString(props.initialValues.delegation.privateNote), [props.initialValues, props.isEdit]);
    const areAccreditationsOpen = React.useMemo(() => props.isEdit && isNonEmptyArray(props.initialValues.delegation.accreditations), [props.initialValues, props.isEdit]);
    const areTagsOpen = React.useMemo(() => props.isEdit && isNonEmptyArray(props.initialValues.delegation.tagsIds), [props.initialValues, props.isEdit]);
    const [isAddAccreditationOpen, setIsAddAccreditationOpen] = React.useState(false);

    return (
        <Form
            height={1}
            width={1}
            hideErrors={true}
            onShowErrors={() => {
                if (centeredContainerRef.current) {
                    centeredContainerRef.current.scrollTop = 0;
                }
            }}
            initialValues={props.initialValues}
            validate={validateService.validateForForm(
                delegationInput.createUpdateDelegationSchema()
            )}
            onSubmit={async (values: ICreateUpdateDelegationValues) => {
                await props.mutate({
                    organizationId,
                    eventId,
                    delegation: values.delegation
                });

                history.push(props.closePath);
            }}
            render={({ form, handleSubmit, submitting, values }) => (
                    <>
                        <FullScreenPopup
                            color="dark"
                            category={
                                props.isEdit
                                    ? translate(
                                          'mise_jour_de_17055',
                                          props.initialValues.delegation.name
                                      )
                                    : translate('cr_ation_d_une_07120')
                            }
                            title={
                                props.isEdit
                                    ? props.initialValues.delegation.name
                                    : translate('nouvelle_d_l_ga_42871')
                            }
                            closePath={props.closePath}
                            button={
                                props.isEdit ? (
                                    <Button isLoading={submitting} onClick={handleSubmit}>
                                        {translate('mettre_jour_l_69948')}
                                    </Button>
                                ) : null
                            }
                        >
                            <CenteredContainer ref={centeredContainerRef}>
                                <FormErrors />

                                <FormBox
                                    hideToggle={true}
                                    initialIsOpen={true}
                                    title={translate('informations_su_34607')}
                                >
                                    {props.delegationType === 'event' ? (
                                        <CreateEventDelegationInformations
                                            initialParentId={
                                                props.initialValues.delegation
                                                    .parentId as DelegationId
                                            }
                                            leadersIds={values.delegation.leadersIds}
                                            organizationDelegations={props.organizationDelegations}
                                            change={form.change}
                                        />
                                    ) : (
                                        <CreateOrganizationDelegationInformations
                                            leadersIds={values.delegation.leadersIds}
                                            change={form.change}
                                        />
                                    )}
                                </FormBox>

                                <Spacer height="5" />

                                <FormBox
                                    hideToggle={true}
                                    initialIsOpen={true}
                                    title={translate('param_tres_de_l_81810')}
                                >
                                    <CreateDelegationParameters
                                        customFields={props.customFields}
                                        delegationType={props.delegationType}
                                        forms={props.forms}
                                        initialValues={props.initialValues}
                                        values={values}
                                        change={form.change}
                                    />
                                </FormBox>

                                <Spacer height="5" />

                                {props.delegationType === 'event' && (
                                    <>
                                        <FormBox
                                            initialIsOpen={areAccreditationsOpen}
                                            title={translate('accr_ditations_39450')}
                                            subtitle={translate('delegation_accreditations_desc')}
                                            onToggle={(isOpen) => {
                                                if (!isOpen) {
                                                    form.change('delegation.accreditations', []);
                                                }
                                            }}
                                        >
                                            <AccreditationsForm
                                                allAccreditationCategories={
                                                    props.accreditationsCategories
                                                }
                                                setIsAddAccreditationOpen={
                                                    setIsAddAccreditationOpen
                                                }
                                                change={form.change}
                                                values={values.delegation.accreditations}
                                            />
                                        </FormBox>

                                        <Spacer height="5" />
                                    </>
                                )}

                                <FormBox
                                    initialIsOpen={isPrivateNoteOpen}
                                    title={translate('note_priv_e_06303')}
                                    subtitle={translate('ajoutez_une_not_98366')}
                                    onToggle={(isOpen) => {
                                        if (!isOpen) {
                                            form.change('delegation.privateNote', '');
                                        }
                                    }}
                                >
                                    <RichEditor
                                        name="delegation.privateNote"
                                        label={
                                            <LabelOptional>
                                                {translate('note_priv_e_06303')}
                                            </LabelOptional>
                                        }
                                    />
                                </FormBox>

                                <Spacer height="5" />

                                <FormBox
                                    initialIsOpen={areTagsOpen}
                                    subtitle={translate('ajouter_des_tag_35833')}
                                    title={translate('tags_79499')}
                                    onToggle={(isOpen) => {
                                        if (!isOpen) {
                                            form.change('delegation.tagsIds', []);
                                        }
                                    }}
                                >
                                    <TagsForm
                                        name="delegation."
                                        organizationId={organizationId}
                                        tags={props.tags}
                                        tagsIds={values.delegation.tagsIds}
                                        change={form.change}
                                    />
                                </FormBox>

                                <Spacer height="5" />

                                <FormBox>
                                    <Button
                                        isLoading={submitting}
                                        onClick={handleSubmit}
                                        textAlign="center"
                                    >
                                        {props.isEdit
                                            ? translate('mettre_jour_l_69948')
                                            : translate('cr_er_la_d_l_ga_53268')}
                                    </Button>
                                </FormBox>
                            </CenteredContainer>
                        </FullScreenPopup>

                        {props.delegationType === 'event' && isAddAccreditationOpen && (
                            <AccreditationsSelectPanel
                                allAccreditationCategories={props.accreditationsCategories}
                                change={form.change}
                                eventStartAt={props.eventStartAt}
                                eventEndAt={props.eventEndAt}
                                setIsAddAccreditationOpen={setIsAddAccreditationOpen}
                                values={values.delegation.accreditations}
                            />
                        )}
                    </>
                )}
        />
    );
};

export const CreateEventDelegation = () => {
    const {
        params: { organizationId, eventId }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const { data, loader } = useDelegationEventInfosQuery({ organizationId, eventId });
    const { mutate } = useDelegationCreateMutation();

    return (
        loader || (
            <CreateUpdateDelegation
                closePath={HeaventPaths.DELEGATIONS(organizationId, eventId)}
                customFields={data.organization.customFields.nodes}
                delegationType="event"
                eventStartAt={data.event.startAt}
                eventEndAt={data.event.endAt}
                forms={data.event.forms}
                initialValues={{
                    delegation: delegationInput.delegationInputDefault(null)
                }}
                isEdit={false}
                organizationDelegations={data.organization.delegations.nodes}
                accreditationsCategories={data.event.accreditationsCategories}
                tags={data.organization.tags.nodes}
                mutate={async (values) => (await mutate(values)).delegationCreate.id}
            />
        )
    );
};

export const CreateOrganizationDelegation = () => {
    const {
        params: { organizationId }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const { data, loader } = useDelegationOrganizationInfosQuery({ organizationId });
    const { mutate } = useDelegationCreateMutation();

    return (
        loader || (
            <CreateUpdateDelegation
                closePath={HeaventPaths.COMMUNITY_DELEGATIONS(organizationId)}
                customFields={data.organization.customFields.nodes}
                delegationType="organization"
                forms={data.organization.forms}
                initialValues={{
                    delegation: delegationInput.delegationInputDefault(null)
                }}
                isEdit={false}
                tags={data.organization.tags.nodes}
                mutate={async (values) => (await mutate(values)).delegationCreate.id}
            />
        )
    );
};

export const UpdateEventDelegation = () => {
    const {
        params: { organizationId, eventId, delegationId }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const { data, loader } = useDelegationEventToEditQuery({
        organizationId,
        eventId,
        delegationId
    });
    const { mutate } = useDelegationUpdateMutation();

    return (
        loader || (
            <CreateUpdateDelegation
                closePath={HeaventPaths.DELEGATION(organizationId, eventId, delegationId)}
                customFields={data.organization.customFields.nodes}
                delegationType="event"
                eventStartAt={data.event.startAt}
                eventEndAt={data.event.endAt}
                forms={data.event.forms}
                initialValues={{
                    delegation: delegationInput.delegationInputDefault(data.event.delegation)
                }}
                isEdit={true}
                organizationDelegations={data.organization.delegations.nodes}
                accreditationsCategories={data.event.accreditationsCategories}
                tags={data.organization.tags.nodes}
                mutate={async (values) => (
                        await mutate({
                            ...values,
                            delegationId
                        })
                    ).delegationUpdate.id}
            />
        )
    );
};

export const UpdateOrganizationDelegation = () => {
    const {
        params: { organizationId, delegationId }
    } = useHeavent();
    const delegationInput = useService(DelegationInputService);
    const { data, loader } = useDelegationOrganizationToEditQuery({ organizationId, delegationId });
    const { mutate } = useDelegationUpdateMutation();

    return (
        loader || (
            <CreateUpdateDelegation
                closePath={HeaventPaths.COMMUNITY_DELEGATION(organizationId, delegationId)}
                customFields={data.organization.customFields.nodes}
                delegationType="organization"
                forms={data.organization.forms}
                initialValues={{
                    delegation: delegationInput.delegationInputDefault({
                        ...data.organization.delegation,
                        accreditations: []
                    })
                }}
                isEdit={true}
                tags={data.organization.tags.nodes}
                mutate={async (values) => (
                        await mutate({
                            ...values,
                            delegationId
                        })
                    ).delegationUpdate.id}
            />
        )
    );
};
