import { heaventEnvDisplayName, heaventEnvTermsUrl } from 'common/src/heaventEnv';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

export const TermsCheckboxLabel = () => {
    const translate = useTranslate();

    return (
        <span
            dangerouslySetInnerHTML={{
                __html: translate(
                    'accepter_a_hre_50341',
                    heaventEnvTermsUrl(),
                    heaventEnvDisplayName()
                )
            }}
        ></span>
    );
};
