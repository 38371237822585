import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { EventId, PositionsCategory } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { usePositionCategoryDeleteMutation } from '../../generated/graphqlHooks';

interface IDeletePositionCategoryProps {
    eventId: EventId;
    positionCategory: Pick<PositionsCategory, 'id' | 'name'>;

    onSuccess(): void;
    onClose(): void;
}

export const DeletePositionCategory = (props: IDeletePositionCategoryProps) => {
    const translate = useTranslate();
    const { mutate } = usePositionCategoryDeleteMutation();

    return (
        <DeleteModal
            text={translate('suppression_de_73669', props.positionCategory.name)}
            subtext={[translate('_tes_vous_s_r_d_50954'), translate('cela_supprimera_30946')]}
            buttonText={translate('supprimer_43083')}
            onDelete={async () => {
                await mutate({
                    eventId: props.eventId,
                    positionCategoryId: props.positionCategory.id
                });

                props.onSuccess();
            }}
            onClose={props.onClose}
        />
    );
};
