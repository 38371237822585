import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

const getBackgroundUrl = (badgeType: string) => {
    switch (badgeType) {
        case 'Crew':
            return `https://assets.recrewteer.com/badges/recrewdays/recrewdaysStaffVerso.png`;
        case 'Festival':
            return `https://assets.recrewteer.com/badges/recrewdays/recrewdaysFestivalVerso.png`;
        case 'Sport':
            return `https://assets.recrewteer.com/badges/recrewdays/recrewdaysSportVerso.png`;
        case 'Partenaire':
            return `https://assets.recrewteer.com/badges/recrewdays/recrewdaysPartenaireVerso.png`;
        case 'Tech':
            return `https://assets.recrewteer.com/badges/recrewdays/recrewdaysTechVerso.png`;
        default:
            return '';
    }
};

interface IBadgeRecrewdaysA6BackProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeRecrewdaysA6Back = (props: IBadgeRecrewdaysA6BackProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;

    return (
        <Flex
            height={597}
            width={435}
            css={{
                background: `url(${getBackgroundUrl(userInfo.recrewdaysTypeBadge)}) no-repeat`,
                backgroundSize: 'cover',
                pageBreakBefore: 'always',
                position: 'relative'
            }}
        ></Flex>
    );
};
