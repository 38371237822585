import {
    COGNITO_CLIENT_CALLBACK_URL,
    COGNITO_DOMAIN_URL,
    COGNITO_USER_POOL_CLIENT_ID
} from 'common-front/src/consts';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';
import LogoWhite from 'svgComponents/logo/logo.svg';

export function authRoute(provider: 'Facebook' | 'Google') {
    return (
        `${COGNITO_DOMAIN_URL}/oauth2/authorize?response_type=code&` +
        `client_id=${COGNITO_USER_POOL_CLIENT_ID}&` +
        `redirect_uri=${encodeURIComponent(COGNITO_CLIENT_CALLBACK_URL)}&` +
        `identity_provider=${provider}`
    );
}

interface IAuthPageProps {
    children: React.ReactNode;
    link: React.ReactNode;
}

export const AuthPage = (props: IAuthPageProps) => (
        <Flex
            direction="column"
            align="center"
            height={1}
            width={1}
            css={{
                background: '#232935',
                overflowX: 'hidden',
                overflowY: 'auto'
            }}
        >
            <Spacer height="7" />

            <Box
                height={32}
                css={{
                    marginBottom: '40px',
                    '@desktop': {
                        marginBottom: '80px'
                    },
                    '& svg': { height: '100%' }
                }}
            >
                <LogoWhite />
            </Box>

            <Flex
                direction="column"
                css={{
                    width: '100%',
                    '@mobile': {
                        px: '$4'
                    },
                    '@desktop': {
                        width: '540px'
                    }
                }}
            >
                <Flex
                    width={1}
                    css={{
                        background: 'white',
                        borderTopLeftRadius: '$2',
                        borderTopRightRadius: '$2',
                        padding: '$7'
                    }}
                >
                    {props.children}
                </Flex>

                <Flex
                    width={1}
                    css={{
                        background: '$gray100',
                        borderBottomLeftRadius: '$2',
                        borderBottomRightRadius: '$2',
                        padding: '$7'
                    }}
                >
                    {props.link}
                </Flex>
            </Flex>

            <Spacer height="7" />
        </Flex>
    );
