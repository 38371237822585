import { FileInput, FileInputValue } from 'common-front/src/designSystem/components/file/fileInput';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { Accept } from 'common-front/src/util/accept';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { UsersListing } from 'common/src/documents/usersListing/usersListing';
import { DocumentType, EventId, UsersListingDocumentQuery } from 'common/src/generated/types';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import {
    getDefaultFileOptions,
    getDefaultVolunteersListingOptions
} from 'common/src/vo/eventDocument';
import * as React from 'react';
import { useUsersListingDocumentQuery } from '../../generated/graphqlHooks';
import { DocumentHeader } from './documentHeader';
import { DocumentOptions, useDocumentOptions } from './documentOptions';
import { DocumentShadow } from './documentShadow';

interface IVolunteersListingDocumentContentProps {
    eventId: EventId;
    data: UsersListingDocumentQuery;
}

const VolunteersListingDocumentContent = (props: IVolunteersListingDocumentContentProps) => {
    const translate = useTranslate();
    const [options, setOptions] = useDocumentOptions(
        props.data.event.document.options,
        getDefaultVolunteersListingOptions(),
        props.eventId,
        props.data.event.document.id
    );

    return (
        <Flex gap="7" width={1} css={{ flex: '1', overflow: 'hidden' }}>
            <DocumentOptions>
                <RichSelect
                    isSearchVisible={true}
                    multiple={true}
                    label={translate('champs_affich_17945')}
                    placeholder={translate('champs_affich_17945')}
                    values={options.slugsToDisplay}
                    onChange={(values: string[]) => {
                        setOptions({ slugsToDisplay: values });
                    }}
                >
                    <option value="email">{translate('Email')}</option>
                    <option value="fullName">{translate('nom_complet_05907')}</option>
                    <option value="teamCode">{translate('code_quipe_14296')}</option>

                    {props.data.organization.customFields.nodes.map((customField) => (
                            <option value={customField.slug} key={customField.id}>
                                {customField.name}
                            </option>
                        ))}
                </RichSelect>

                <Flex align="center" gap="3">
                    <FileInput
                        accept={Accept.Images}
                        format="base64"
                        label={translate('en_t_te_11987')}
                        placeholder={translate('en_t_te_11987')}
                        hint={translate('taille_de_l_ima_12660', '682x210px')}
                        value={options.header}
                        onChange={(header: FileInputValue) => {
                            setOptions({ header });
                        }}
                    />

                    {isNonEmptyString(options.header?.name) && (
                        <Box
                            font="gray700 textSm medium"
                            css={{ cursor: 'pointer' }}
                            onClick={() => {
                                setOptions({ header: getDefaultFileOptions() });
                            }}
                        >
                            {translate('r_initialiser_77538')}
                        </Box>
                    )}
                </Flex>
            </DocumentOptions>

            <DocumentShadow width={1}>
                <UsersListing options={options} documentUsersListing={props.data} />
            </DocumentShadow>
        </Flex>
    );
};

export const UsersListingDocument = () => {
    const translate = useTranslate();
    const { organizationId, eventId, segmentId } = useParams();
    const { data, loader } = useUsersListingDocumentQuery({
        organizationId,
        eventId,
        segmentId
    });

    return (
        <Flex direction="column" height={1} width={1} css={{ padding: '$7' }}>
            <DocumentHeader
                documentType={DocumentType.VolunteersListing}
                eventId={eventId}
                languages={data.event?.languages ?? []}
                organizationId={organizationId}
                segments={data.event?.segments ?? []}
                title={translate('liste_des_membr_89037')}
            />

            <Spacer height="7" />

            {loader || <VolunteersListingDocumentContent eventId={eventId} data={data} />}
        </Flex>
    );
};
