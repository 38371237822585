import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { IIcon } from 'common/src/designSystem/components/i';
import * as React from 'react';
import { IInputState } from '../components/input/state';
import { Select } from '../components/select/select';

const POSSIBLE_VALUES = ['Benjamin', 'Florent', 'Jean-Charles', 'Matthieu'];
const getOptions = () => POSSIBLE_VALUES.map((value, index) => (
            <option value={value} key={index}>
                {value}
            </option>
        ));

export const Selects = () => {
    const [value, setValue] = React.useState(POSSIBLE_VALUES[0]);
    const [state, setState] = React.useState<IInputState>('active');
    const [label, setLabel] = React.useState('');
    const [hint, setHint] = React.useState('');
    const [icon, setIcon] = React.useState<IIcon | undefined>();

    return (
        <Flex direction="column" gap="6" width={1}>
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Selects
            </Box>

            <Flex gap="3">
                <Select value={value} onChange={setValue}>
                    {getOptions()}
                </Select>

                <Select value={value} onChange={setValue} label="Nom de l'associé">
                    {getOptions()}
                </Select>

                <Select
                    value={value}
                    onChange={setValue}
                    label="Nom de l'associé"
                    hint="Entrez le nom de l'associé"
                >
                    {getOptions()}
                </Select>

                <Select
                    value={value}
                    onChange={setValue}
                    label="Nom de l'associé"
                    hint="Entrez le nom de l'associé"
                    icon="magnifying-glass"
                >
                    {getOptions()}
                </Select>

                <Select value={value} onChange={setValue} state="disabled">
                    {getOptions()}
                </Select>

                <Select value={value} onChange={setValue} state="error">
                    {getOptions()}
                </Select>

                <Select
                    value={value}
                    onChange={setValue}
                    label="Nom de l'associé"
                    hint="Entrez le nom de l'associé"
                    icon="magnifying-glass"
                    state="error"
                >
                    {getOptions()}
                </Select>
            </Flex>

            <Flex gap="3">
                <select
                    value={state}
                    onChange={(e) => {
                        setState(e.target.value as IInputState);
                    }}
                >
                    <option value="active">active</option>
                    <option value="disabled">disabled</option>
                    <option value="error">error</option>
                </select>

                <input
                    type="text"
                    placeholder="label"
                    value={label}
                    onChange={(e) => {
                        setLabel(e.target.value);
                    }}
                />

                <input
                    type="text"
                    placeholder="hint"
                    value={hint}
                    onChange={(e) => {
                        setHint(e.target.value);
                    }}
                />

                <select
                    value={icon}
                    onChange={(e) => {
                        setIcon(e.target.value as IIcon);
                    }}
                >
                    <option value="">icon</option>
                    <option value="magnifying-glass">🔍</option>
                    <option value="envelope">✉️</option>
                </select>
            </Flex>

            <Flex width={1}>
                <Select
                    state={state}
                    label={label}
                    hint={hint}
                    icon={icon}
                    value={value}
                    onChange={setValue}
                >
                    {getOptions()}
                </Select>
            </Flex>
        </Flex>
    );
};
