import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { useTranslate } from '../../util/dependencies/dependencies';

interface IEventPlanningHeaderProps {
    eventName: string;
    segmentName: string;
}

export const PositionsVolunteersPlanningHeader = (props: IEventPlanningHeaderProps) => {
    const translate = useTranslate();

    return (
        <Flex direction="column" css={{ background: '$gray100', padding: '$6' }}>
            <Box color="gray900" fontSize="textXl" fontWeight="medium">
                {translate('planning_1_14391', `${props.eventName} - ${props.segmentName}`)}
            </Box>
        </Flex>
    );
};
