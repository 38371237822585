import { BadgesPlus } from 'common-front/src/components/badgesPlus/badgesPlus';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    EventId,
    PositionsSlotId,
    VolunteerRegistrationFragment
} from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { getPositionsBadges } from 'common/src/vo/position';
import { fullName } from 'common/src/vo/positionSlot';
import { groupBy } from 'lodash-es';
import * as React from 'react';
import {
    executeUpdateCellPositionsSlotsQuery,
    useVolunteerRegistrationAssignMutation
} from '../../../generated/graphqlHooks';
import { UpdateCellDropdown } from './updateCellDropdown';

interface IVolunteerRegistrationPositionsProps {
    eventId: EventId;
    firstCellCss: any;
    volunteerRegistration: VolunteerRegistrationFragment;

    reload(): void;
}

export const VolunteerRegistrationPositions = (props: IVolunteerRegistrationPositionsProps) => {
    const translate = useTranslate();
    const { mutate: volunteerRegistrationAssign } = useVolunteerRegistrationAssignMutation();
    const intervalService = useService(IntervalService);
    const { isEditMode } = useSegmentsContext();
    const psuis = React.useMemo(() => props.volunteerRegistration?.positionsSlotsUsersInfos || [], [props.volunteerRegistration]);
    const badges = React.useMemo(() => getPositionsBadges(intervalService, psuis), [psuis]);
    const initialValue = React.useMemo(() => psuis.map((psui) => psui.positionSlot.id), [psuis]);
    const [isFirstTime, setIsFirstTime] = React.useState(true);
    const [positions, setPositions] = React.useState<any[]>([]);
    const onStateChange = React.useCallback(
        async (isOpen) => {
            if (isOpen && isFirstTime) {
                const {
                    event: { positionsSlots }
                } = await executeUpdateCellPositionsSlotsQuery(
                    { eventId: props.eventId },
                    await getToken()
                );

                setPositions(
                    Object.entries(groupBy(positionsSlots.nodes, (ps) => ps.position.name))
                );
                setIsFirstTime(false);
            }
        },
        [isFirstTime]
    );
    const onSave = React.useCallback(
        async (value: PositionsSlotId[]) => {
            await volunteerRegistrationAssign({
                eventId: props.eventId,
                volunteerRegistrationId: props.volunteerRegistration.id,
                assignment: { positionsSlotsIds: value }
            });

            props.reload();
        },
        [props.eventId, props.volunteerRegistration, volunteerRegistrationAssign, props.reload]
    );

    if (isEditMode) {
        return (
            <UpdateCellDropdown
                firstCellCss={{ ...props.firstCellCss, gap: '$2' }}
                initialValue={initialValue}
                renderValue={() => <BadgesPlus badges={badges} doNotSort={true} />}
                onSave={onSave}
                onStateChange={onStateChange}
            >
                {(value, setValue) => isFirstTime ? (
                        <Flex direction="column" gap="1" width={1}>
                            <Skeleton height={20} width={1} borderRadius="$1" />
                            <Skeleton height={40} width={1} borderRadius="$1" />
                        </Flex>
                    ) : (
                        <RichSelect
                            isSearchVisible={true}
                            multiple={true}
                            label={translate('missions_63972')}
                            renderOnPortal={true}
                            values={value}
                            onChange={setValue}
                        >
                            {positions.map(([positionName, slots], index) => (
                                    <optgroup key={index} label={positionName}>
                                        {slots.map((slot: any) => (
                                                <option key={slot.id} value={slot.id}>
                                                    {fullName(intervalService, slot, {
                                                        formats: { displayWeekday: true }
                                                    })}
                                                </option>
                                            ))}
                                    </optgroup>
                                ))}
                        </RichSelect>
                    )}
            </UpdateCellDropdown>
        );
    } else {
        return (
            <Cell css={{ ...props.firstCellCss, border: '2px solid transparent' }}>
                <BadgesPlus badges={badges} doNotSort={true} />
            </Cell>
        );
    }
};
