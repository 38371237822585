import { Button } from 'common-front/src/designSystem/components/button';
import { SlotCard } from 'common-front/src/designSystem/components/slotCard';
import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Box } from 'common/src/designSystem/components/box';
import { MassEditCustomFieldFragment } from 'common/src/generated/types';
import { IUpdateFormParametersGeneralValues } from 'common/src/input/formParametersGeneralInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { MassEditFieldInput } from '../../../../volunteers/massEdit/massEditFieldInput';

interface IFormParametersGeneralCustomFieldProps {
    customFields: MassEditCustomFieldFragment[];
    customFieldValues: IUpdateFormParametersGeneralValues['form']['customFields'][0];
    field: string;
    index: number;

    change(name: string, value: any): void;
    remove(): void;
}

export const FormParametersGeneralCustomField = (props: IFormParametersGeneralCustomFieldProps) => {
    const translate = useTranslate();
    const selectedField = props.customFields.find(
        (cf) => cf.id === props.customFieldValues.customFieldId
    );

    return (
        <SlotCard
            buttons={
                <Tooltip>
                    <Trigger>
                        <Box>
                            <Button
                                color="white"
                                size="sm"
                                leftIcon="trash-can"
                                onClick={props.remove}
                            />
                        </Box>
                    </Trigger>

                    <Content placement="top">{translate('supprimer_43083')}</Content>
                </Tooltip>
            }
            title={translate('champ_de_formul_73221', props.index + 1)}
        >
            <RichSelect
                isSearchVisible={true}
                label={translate('CustomField')}
                name={`${props.field}.customFieldId`}
            >
                <option value="-1">{translate('s_lectionner_un_48159')}</option>

                {props.customFields.map((cf) => (
                        <option key={cf.id} value={cf.id}>
                            {cf.name}
                        </option>
                    ))}
            </RichSelect>

            {selectedField && (
                <>
                    <MassEditFieldInput
                        addValuesName={`${props.field}.addValues`}
                        field={selectedField}
                        name={`${props.field}.value`}
                        value={props.customFieldValues.value}
                        change={props.change}
                    />
                </>
            )}
        </SlotCard>
    );
};
