import { Button } from 'common-front/src/designSystem/components/button';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { CustomFieldsCategoryId, SegmentCustomFieldFragment } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { PossibleColumn } from 'common/src/vo/segment';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useMemo } from 'react';

interface IVolunteersExportColumnsSelectorDropdownProps {
    customFields: SegmentCustomFieldFragment[];
    customFieldCategories: { id: CustomFieldsCategoryId | undefined; name: string }[];
    possibleColumns: PossibleColumn[];
}

export const VolunteersExportColumnsSelectorDropdown = ({
    customFields,
    customFieldCategories,
    possibleColumns
}: IVolunteersExportColumnsSelectorDropdownProps) => {
    const translate = useTranslate();
    const { columnsExport, setColumnsExport } = useSegmentsContext();
    const slugToCustomField = useMemo(() => new Map(customFields.map((cf) => [cf.slug, cf])), [customFields]);
    const customFieldCategoryFields = useMemo(() => customFieldCategories.map((category) => ({
                ...category,
                fields: possibleColumns.filter((field: PossibleColumn) => {
                    const customField = slugToCustomField.get(field.slug);
                    return customField?.customFieldCategoryId === category.id;
                })
            })), [possibleColumns, customFieldCategories]);

    return (
        <RichSelect
            isSearchVisible={true}
            isSelectAllVisible={true}
            multiple={true}
            values={columnsExport}
            triggerElem={
                <Button color="invisible" leftIcon="plus">
                    {translate('ajouter_une_colonne')}
                </Button>
            }
            onChange={setColumnsExport}
        >
            {customFieldCategoryFields.map((category) => (
                    <optgroup key={category.id ?? 0} label={category.name}>
                        {sortBy(category.fields, (f) => f.name).map((column) => (
                                <option key={column.slug} value={column.slug}>
                                    {column.name}
                                </option>
                            ))}
                    </optgroup>
                ))}
        </RichSelect>
    );
};
