import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IContremarqueJardinSonoreBenevoleProps {
    event: DocumentUserCustomBadgeFragment;
}

export const ContremarqueJardinSonoreBenevole = (props: IContremarqueJardinSonoreBenevoleProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <>
            <Flex
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
                css={{
                    background:
                        'url(https://assets.recrewteer.com/badges/jardinSonore/jardinSonoreContremarqueBenevoleFront.png) no-repeat',
                    backgroundSize: 'cover',
                    color: 'black',
                    fontFamily: '$dinCondensed',
                    position: 'relative'
                }}
            >
                <Box
                    css={{
                        fontSize: '24px',
                        fontWeight: '700',
                        left: '210px',
                        lineHeight: 'normal',
                        position: 'absolute',
                        top: '301px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Flex
                    direction="column"
                    align="center"
                    gap="1"
                    css={{
                        position: 'absolute',
                        right: '125px',
                        top: '283px'
                    }}
                >
                    <Box height={79} width={79}>
                        {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                            <img
                                src={`https://api.${
                                    CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                                }/barcode/qr?text=${encodeURIComponent(
                                    vr.weezeventParticipantInfo!.idBarcode
                                )}&color=000000&height=79&width=79`}
                                height="100%"
                                width="100%"
                            />
                        )}
                    </Box>

                    <Box css={{ fontSize: '12px' }}>
                        {vr.weezeventParticipantInfo?.idBarcode ?? ''}
                    </Box>
                </Flex>
            </Flex>

            <Flex
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
                css={{
                    background:
                        'url(https://assets.recrewteer.com/badges/jardinSonore/jardinSonoreContremarqueBenevoleBack.png) no-repeat',
                    backgroundSize: 'cover',
                    pageBreakBefore: 'always'
                }}
            />
        </>
    );
};
