import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { BaseInputProps } from './input/baseInputProps';
import { Description } from './input/description';
import { Hint } from './input/hint';
import { Label } from './input/label';
import { StyledInputContainer } from './input/styledInputContainer';
import { AutoComplete } from './textInput';

interface IPasswordInputProps extends BaseInputProps {
    autoComplete?: AutoComplete;
    value: string;

    onChange(value: string): void;
    onForgotPasswordClick?(): void;
}

export const PasswordInput = React.forwardRef(
    (props: IPasswordInputProps, ref: React.Ref<HTMLInputElement>) => {
        const translate = useTranslate();
        const rootRef = React.useRef<any>();
        const [inputType, setInputType] = React.useState<'password' | 'text'>('password');
        const { inputId, descId, errorId } = useUniqueIds();

        return (
            <Flex
                ref={rootRef}
                css={props.css}
                direction="column"
                width={1}
                onClick={(e) => {
                    e.preventDefault();

                    rootRef.current?.querySelector('input')?.focus();
                }}
            >
                <Flex gap="3" width={1}>
                    <Box css={{ flex: '1' }}>
                        <Label htmlFor={inputId}>{props.label}</Label>
                    </Box>

                    {typeof props.onForgotPasswordClick === 'function' && (
                        <Box
                            color="primary700"
                            fontWeight="medium"
                            css={{ cursor: 'pointer' }}
                            onClick={() => {
                                props.onForgotPasswordClick?.();
                            }}
                        >
                            {translate('mot_de_passe_ou_17690')}
                        </Box>
                    )}
                </Flex>

                <Description id={descId}>{props.description}</Description>

                {(props.label || props.description) && <Spacer height="1" />}

                <StyledInputContainer
                    icon={props.icon}
                    state={props.state}
                    cursor="text"
                    rightIcon={inputType === 'password' ? 'eye' : 'eye-slash'}
                    onRightIconClick={(e) => {
                        e.stopPropagation();
                        e.nativeEvent.stopImmediatePropagation();

                        setInputType(inputType === 'password' ? 'text' : 'password');
                    }}
                >
                    <Box
                        color="inherit"
                        css={{
                            flex: '1',
                            height: '100%',
                            '& input': {
                                background: 'transparent',
                                border: 'none',
                                color: '$gray800',
                                height: '100%',
                                outline: 'none',
                                width: '100%'
                            },
                            '& input::placeholder': {
                                color: '$gray500',
                                opacity: 1
                            },
                            '& input::-ms-input-placeholder ': {
                                color: '$gray500',
                                opacity: 1
                            }
                        }}
                    >
                        <input
                            ref={ref}
                            id={inputId}
                            aria-describedby={props.description ? descId : undefined}
                            aria-invalid={props.state === 'error'}
                            aria-errormessage={props.state === 'error' ? errorId : undefined}
                            type={inputType}
                            placeholder={props.placeholder}
                            disabled={props.state === 'disabled'}
                            autoComplete={props.autoComplete}
                            value={props.value}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}
                            onBlur={props.onBlur}
                            onFocus={props.onFocus}
                        />
                    </Box>
                </StyledInputContainer>

                <Hint id={errorId} state={props.state}>
                    {props.hint}
                </Hint>
            </Flex>
        );
    }
);
