import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { CheckboxText } from 'common-front/src/designSystem/components/checkbox';
import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { FileS3Input } from 'common-front/src/designSystem/form/file/fileS3Input';
import { ImageInput } from 'common-front/src/designSystem/form/image/imageInput';
import { RichEditor } from 'common-front/src/designSystem/form/richEditor';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Accept } from 'common-front/src/util/accept';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EmailsSenderInput, Event, EventId } from 'common/src/generated/types';
import {
    EmailSenderInputService,
    ICreateUpdateEmailSenderValues
} from 'common/src/input/emailSenderInput';
import { isNonEmptyArray } from 'common/src/util/array';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { from } from 'common/src/vo/emailSender';
import * as React from 'react';
import {
    useAllEventsQuery,
    useEmailSenderCreateMutation,
    useEmailSenderToEditQuery,
    useEmailSenderUpdateMutation
} from '../../../../generated/graphqlHooks';

type EventIdCommunity = EventId | 'community';

interface ICreateUpdateEmailSenderProps {
    events: Array<Pick<Event, 'id' | 'name'>>;
    initialValues: ICreateUpdateEmailSenderValues;
    isEdit: boolean;

    mutate(emailSenderInput: EmailsSenderInput): Promise<any>;
}

const CreateUpdateEmailSender = (props: ICreateUpdateEmailSenderProps) => {
    const {
        history,
        translate,
        params: { organizationId }
    } = useHeavent();
    const emailSenderInput = useService(EmailSenderInputService);
    const centeredContainerRef = React.useRef<HTMLDivElement | null>(null);
    const [isDefaultOpen, setIsDefaultOpen] = React.useState(
        props.initialValues.emailSender.isOrganizationDefault ||
            isNonEmptyArray(props.initialValues.emailSender.eventsIds)
    );
    const [selectedDefaults, setSelectedDefaults] = React.useState<EventIdCommunity[]>(
        (props.initialValues.emailSender.eventsIds as EventIdCommunity[]).concat(
            props.initialValues.emailSender.isOrganizationDefault ? ['community'] : []
        )
    );

    return (
        <Form
            height={1}
            width={1}
            hideErrors={true}
            initialValues={props.initialValues}
            schema={emailSenderInput.createUpdateEmailSenderSchema()}
            onSubmit={async (values: ICreateUpdateEmailSenderValues) => {
                const emailSender = {
                    ...values.emailSender,
                    eventsIds: selectedDefaults.filter((d) => d !== 'community') as EventId[],
                    isOrganizationDefault: selectedDefaults.includes('community')
                };

                await props.mutate(emailSender);

                history.push(HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(organizationId));
            }}
            onShowErrors={() => {
                if (centeredContainerRef.current) {
                    centeredContainerRef.current.scrollTop = 0;
                }
            }}
            render={({ handleSubmit, submitting, values }) => (
                    <FullScreenPopup
                        category={translate('_metteur_d_e_ma_51878')}
                        closePath={HeaventPaths.ORGANIZATION_PARAMETERS_EMAILS_SENDERS(
                            organizationId
                        )}
                        title={
                            props.isEdit
                                ? from(props.initialValues.emailSender)
                                : translate('nouvel_metteur_58381')
                        }
                        button={
                            props.isEdit && (
                                <Button isLoading={submitting} onClick={handleSubmit}>
                                    {translate('mettre_jour_l_14264')}
                                </Button>
                            )
                        }
                    >
                        <CenteredContainer ref={centeredContainerRef}>
                            <FormErrors />

                            <FormBox
                                hideToggle={true}
                                initialIsOpen={true}
                                title={translate('informations_su_10889')}
                            >
                                <Flex direction="column" gap="4" width={1}>
                                    <TextInput label="Label" name="emailSender.name" />

                                    <TextInput
                                        label={translate('nom_de_l_mette_49412')}
                                        hint={translate('les_e_mails_ser_70662')}
                                        name="emailSender.fromName"
                                    />

                                    <TextInput
                                        label={translate('e_mail_de_l_me_98374')}
                                        hint={translate('les_e_mails_ser_62925')}
                                        name="emailSender.fromEmail"
                                        isEmail={true}
                                    />

                                    <TextInput
                                        label={
                                            <LabelOptional>
                                                {translate('adresse_de_r_po_86181')}
                                            </LabelOptional>
                                        }
                                        hint={translate(
                                            'cette_adresse_r_36038',
                                            values.emailSender.fromEmail
                                        )}
                                        name="emailSender.replyTo"
                                        isEmail={true}
                                    />

                                    <FileS3Input
                                        accept={Accept.Images}
                                        acl="public-read"
                                        label={translate('en_t_te_11987')}
                                        hint={translate('chaque_e_mail_c_96561')}
                                        prefix="emailSender.header."
                                    />

                                    <ImageInput
                                        label={translate('photo_de_profil_08618')}
                                        prefix="emailSender.picture."
                                    />

                                    <RichEditor
                                        label={translate('signature_67521')}
                                        name="emailSender.signature"
                                    />

                                    <FileS3Input
                                        accept={Accept.Images}
                                        acl="public-read"
                                        label={translate('bas_de_page_86491')}
                                        hint={translate('chaque_e_mail_c_65078')}
                                        prefix="emailSender.footer."
                                    />

                                    <Flex direction="column" gap="3">
                                        <CheckboxText
                                            state={isDefaultOpen ? 'checked' : 'unchecked'}
                                            onClick={(state) => {
                                                setIsDefaultOpen(state === 'checked');
                                            }}
                                        >
                                            {translate('utiliser_cet_m_47949')}
                                        </CheckboxText>

                                        {isDefaultOpen && (
                                            <Flex>
                                                <Spacer width="6" />

                                                <RichSelect
                                                    isSearchVisible={true}
                                                    multiple={true}
                                                    values={selectedDefaults}
                                                    onChange={setSelectedDefaults}
                                                >
                                                    <option value="community">
                                                        {translate('communaut_20896')}
                                                    </option>

                                                    {props.events.map((event) => (
                                                            <option key={event.id} value={event.id}>
                                                                {event.name}
                                                            </option>
                                                        ))}
                                                </RichSelect>
                                            </Flex>
                                        )}
                                    </Flex>
                                </Flex>
                            </FormBox>

                            <Spacer height="5" />

                            <FormBox>
                                <Button
                                    isLoading={submitting}
                                    onClick={handleSubmit}
                                    textAlign="center"
                                >
                                    {props.isEdit
                                        ? translate('mettre_jour_l_14264')
                                        : translate('cr_er_l_metteu_36451')}
                                </Button>
                            </FormBox>
                        </CenteredContainer>
                    </FullScreenPopup>
                )}
        />
    );
};

export const CreateEmailSender = () => {
    const {
        params: { organizationId }
    } = useHeavent();
    const { data, loader } = useAllEventsQuery({ organizationId });
    const emailSenderInput = useService(EmailSenderInputService);
    const { mutate } = useEmailSenderCreateMutation();

    return (
        loader || (
            <CreateUpdateEmailSender
                events={data.organization.events.nodes}
                initialValues={{
                    emailSender: emailSenderInput.emailSenderInputDefault()
                }}
                isEdit={false}
                mutate={(emailSenderInput) => mutate({
                        organizationId,
                        emailSender: emailSenderInput
                    })}
            />
        )
    );
};

export const UpdateEmailSender = () => {
    const {
        params: { organizationId, emailSenderId }
    } = useHeavent();
    const emailSenderInput = useService(EmailSenderInputService);
    const { data, loader } = useEmailSenderToEditQuery({ organizationId, emailSenderId });
    const { mutate } = useEmailSenderUpdateMutation();

    return (
        loader || (
            <CreateUpdateEmailSender
                events={data.organization.events.nodes}
                initialValues={{
                    emailSender: emailSenderInput.emailSenderInputDefault(
                        data.organization.emailSender
                    )
                }}
                isEdit={true}
                mutate={(emailSenderInput) => mutate({
                        organizationId,
                        emailSenderId,
                        emailSender: emailSenderInput
                    })}
            />
        )
    );
};
