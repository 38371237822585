import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

export const Skeletons = () => (
        <Flex direction="column" width={1}>
            <Box color="gray900" fontSize="textXl" fontWeight="semiBold">
                Skeletons
            </Box>

            <Spacer height="7" />

            <Skeleton height={72} borderRadius="$2" />

            <Spacer height="3" />

            <Skeleton height={72} borderRadius="$2" />
        </Flex>
    );
