import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IAxeA4Props {
    event: DocumentUserCustomBadgeFragment;
}

export const AxeA4 = (props: IAxeA4Props) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            direction="column"
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background:
                    'url(https://assets.recrewteer.com/badges/axe/backgroundNotCompressed.jpg) no-repeat',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    color: '$gray800',
                    fontSize: '22px',
                    fontWeight: '700',
                    left: '113px',
                    position: 'absolute',
                    top: '307px'
                }}
            >
                {ui.firstName}
                <br />
                {ui.lastName}
            </Box>

            <Box
                css={{
                    color: '#6800cb',
                    fontSize: '20px',
                    fontWeight: '600',
                    left: '113px',
                    position: 'absolute',
                    top: '371px'
                }}
            >
                {ui.fields.cf27064}
            </Box>

            <Box
                css={{
                    height: '90px',
                    left: '591px',
                    position: 'absolute',
                    top: '305px',
                    width: '90px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=90&width=90`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
