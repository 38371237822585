import { Alert } from 'common-front/src/designSystem/components/alert';
import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { RadioText } from 'common-front/src/designSystem/components/radio';
import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AssignmentsEmailsRecipients,
    EmailsSender,
    EventId,
    SegmentId,
    SendAssignmentsEmailsInput,
    UsersInfoId
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { uniqBy } from 'lodash-es';
import * as React from 'react';
import {
    useAssignmentsEmailsEstimateMutation,
    useSendEmailInfosQuery
} from '../../generated/graphqlHooks';

interface ISendAssignmentsEmailsModalProps {
    emailSender: Emptyable<Pick<EmailsSender, 'isValidToSendEmail'>>;
    eventId: EventId;

    onClose(): void;
    onSuccess(numberOfEmails: number, sendAssignmentsEmails: SendAssignmentsEmailsInput): void;
}

export const SendAssignmentsEmailsModal = (props: ISendAssignmentsEmailsModalProps) => {
    const translate = useTranslate();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [usersInfosIds, setUsersInfosIds] = React.useState<UsersInfoId[]>([]);
    const { data } = useSendEmailInfosQuery({
        eventId: props.eventId,
        usersInfosIds,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined
    });
    const { mutate, isLoading } = useAssignmentsEmailsEstimateMutation();
    const [recipients, setRecipients] = React.useState<AssignmentsEmailsRecipients>(
        AssignmentsEmailsRecipients.Everyone
    );
    const volunteersRegistrations = React.useMemo(() => uniqBy(
            (data.event?.volunteersRegistrations.nodes ?? []).concat(
                data.event?.selectedVolunteersRegistrations.nodes ?? []
            ),
            (vr) => vr.id
        ), [data.event]);
    const [segmentsIds, setSegmentsIds] = React.useState<SegmentId[]>([]);
    const valid =
        recipients === AssignmentsEmailsRecipients.Everyone ||
        recipients === AssignmentsEmailsRecipients.Assigned ||
        recipients === AssignmentsEmailsRecipients.Refused ||
        (recipients === AssignmentsEmailsRecipients.Members && isNonEmptyArray(usersInfosIds)) ||
        (recipients === AssignmentsEmailsRecipients.Segments && isNonEmptyArray(segmentsIds));
    const isSendDisabled = !!(props.emailSender && !props.emailSender.isValidToSendEmail);

    return (
        <Modal size="md" onClose={props.onClose}>
            <IconBackground color="purple" icon="paper-plane" css={{ margin: 'auto' }} />

            <Spacer height="4" />

            <Box color="gray900" fontSize="textLg" fontWeight="medium" textAlign="center">
                {translate('envoi_des_e_mai_81363')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500" textAlign="center">
                {translate('personnaliser_v_78340')}
            </Box>

            <Spacer height="7" />

            {isSendDisabled && (
                <>
                    <Alert color="error" leftIcon="circle-exclamation">
                        {translate('vous_ne_pouvez_69435')}
                    </Alert>

                    <Spacer height="4" />
                </>
            )}

            <Box color="gray800" fontWeight="medium">
                {translate('que_souhaitez_v_01732')}
            </Box>

            <Spacer height="4" />

            <RadioText
                state={
                    recipients === AssignmentsEmailsRecipients.Everyone ? 'checked' : 'unchecked'
                }
                onClick={() => {
                    setRecipients(AssignmentsEmailsRecipients.Everyone);
                }}
            >
                {translate('tous_les_e_mail_39319')}
            </RadioText>

            <Spacer height="3" />

            <RadioText
                state={
                    recipients === AssignmentsEmailsRecipients.Assigned ? 'checked' : 'unchecked'
                }
                onClick={() => {
                    setRecipients(AssignmentsEmailsRecipients.Assigned);
                }}
            >
                {translate('tous_les_e_mail_42685')}
            </RadioText>

            <Spacer height="3" />

            <RadioText
                state={recipients === AssignmentsEmailsRecipients.Refused ? 'checked' : 'unchecked'}
                onClick={() => {
                    setRecipients(AssignmentsEmailsRecipients.Refused);
                }}
            >
                {translate('tous_les_e_mail_41162')}
            </RadioText>

            <Spacer height="3" />

            <RadioText
                state={recipients === AssignmentsEmailsRecipients.Members ? 'checked' : 'unchecked'}
                onClick={() => {
                    setRecipients(AssignmentsEmailsRecipients.Members);
                }}
            >
                {translate('_certains_memb_91094')}
            </RadioText>

            {recipients === AssignmentsEmailsRecipients.Members && (
                <>
                    <Spacer height="2" />

                    <Box css={{ paddingLeft: '$6' }}>
                        <RichSelect
                            isSearchVisible={true}
                            searchElement={
                                <TextInput
                                    icon="magnifying-glass"
                                    placeholder={translate('rechercher_50038')}
                                    state="search"
                                    value={name}
                                    onChange={setName}
                                />
                            }
                            multiple={true}
                            values={usersInfosIds}
                            onChange={setUsersInfosIds}
                        >
                            {volunteersRegistrations.map((vr) => (
                                    <option key={vr.userInfo.id} value={vr.userInfo.id}>
                                        {vr.userInfo.nameOrEmail}
                                    </option>
                                ))}
                        </RichSelect>
                    </Box>
                </>
            )}

            <Spacer height="3" />

            <RadioText
                state={
                    recipients === AssignmentsEmailsRecipients.Segments ? 'checked' : 'unchecked'
                }
                onClick={() => {
                    setRecipients(AssignmentsEmailsRecipients.Segments);
                }}
            >
                {translate('uniquement_ce_54026')}
            </RadioText>

            {recipients === AssignmentsEmailsRecipients.Segments && (
                <>
                    <Spacer height="2" />

                    <Box css={{ paddingLeft: '$6' }}>
                        <RichSelect
                            isSearchVisible={true}
                            multiple={true}
                            values={segmentsIds}
                            onChange={setSegmentsIds}
                        >
                            {(data.event?.segments ?? []).map((segment) => (
                                    <option key={segment.id} value={segment.id}>
                                        {segment.name}
                                    </option>
                                ))}
                        </RichSelect>
                    </Box>
                </>
            )}

            <Spacer height="7" />

            <Flex justify="end" gap="3">
                <Button
                    disabled={isSendDisabled}
                    isLoading={isLoading}
                    onClick={async () => {
                        if (valid) {
                            const sendAssignmentsEmails = {
                                recipients,
                                segmentsIds,
                                usersInfosIds
                            };
                            const { eventAssignmentsEmailsEstimate: numberOfEmails } = await mutate(
                                {
                                    eventId: props.eventId,
                                    sendAssignmentsEmails
                                }
                            );

                            props.onSuccess(numberOfEmails, sendAssignmentsEmails);
                            props.onClose();
                        }
                    }}
                >
                    {translate('envoyer_les_e_m_37846')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
