import chardet from 'chardet';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useUniqueIds } from '../../../hooks/useUniqueIds';
import { Accept, acceptString } from '../../../util/accept';
import { BaseInputProps } from '../input/baseInputProps';
import { Description } from '../input/description';
import { Hint } from '../input/hint';
import { Label } from '../input/label';
import { StyledInputContainer } from '../input/styledInputContainer';

const readFile = (file: File, format: 'base64' | 'string'): Promise<string> => {
    if (format === 'base64') {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result?.toString() ?? '');
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    } else {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const result = new Uint8Array(reader.result as ArrayBuffer);
                resolve(chardet.detect(result) || 'UTF-8');
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(file);
        }).then((encoding) => new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result?.toString() ?? '');
                reader.onerror = reject;
                reader.readAsText(file, encoding);
            }));
    }
};

export type FileInputValue = {
    name: string;
    content: string;
};

interface IFileInputProps extends BaseInputProps {
    accept: Accept | Accept[];
    format: 'base64' | 'string';
    value?: FileInputValue;

    onChange(value: FileInputValue): void;
}

export const FileInput = (props: IFileInputProps) => {
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex css={props.css} direction="column" width={1}>
            <Label htmlFor={inputId}>{props.label}</Label>

            <Description id={descId}>{props.description}</Description>

            {(props.label || props.description) && <Spacer height="1" />}

            <StyledInputContainer
                icon={props.icon}
                rightIcon="file"
                state={props.state}
                cursor="pointer"
                onClick={() => {
                    inputRef.current?.click();
                }}
            >
                <Box
                    color={isNonEmptyString(props.value?.name) ? 'gray800' : 'gray500'}
                    css={{
                        flex: '1',
                        '& input': {
                            display: 'none'
                        }
                    }}
                >
                    {isNonEmptyString(props.value?.name) ? props.value!.name : props.placeholder}

                    <input
                        ref={inputRef}
                        id={inputId}
                        aria-describedby={props.description ? descId : undefined}
                        aria-invalid={props.state === 'error'}
                        aria-errormessage={props.state === 'error' ? errorId : undefined}
                        type="file"
                        accept={acceptString(props.accept)}
                        onChange={async (e) => {
                            const file = e.target.files?.[0];

                            if (file) {
                                props.onChange({
                                    name: file.name,
                                    content: await readFile(file, props.format)
                                });
                            }
                        }}
                    />
                </Box>
            </StyledInputContainer>

            <Hint id={errorId} state={props.state}>
                {props.hint}
            </Hint>
        </Flex>
    );
};
