import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { Separator } from 'common-front/src/designSystem/components/separator';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { fromIconVO, I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import {
    useAccreditationQuery,
    useTagsAccreditationAssociateMutation
} from '../../generated/graphqlHooks';
import { AccreditationCustomFields } from './accreditationCustomFields';
import { AccreditationDescription } from './accreditationDescription';
import { AccreditationHeader } from './accreditationHeader';
import { AccreditationLocation } from './accreditationLocation';
import { AccreditationPrivateNote } from './accreditationPrivateNote';
import { AccreditationSlots } from './accreditationSlots/accreditationSlots';
import { AccreditationTags } from './accreditationTags';
import { AccreditationUsers } from './accreditationUsers/accreditationUsers';

export const Accreditation = () => {
    const translate = useTranslate();
    const { eventId, accreditationId } = useParams();
    const { data, loader, reload } = useAccreditationQuery({ eventId, accreditationId });
    const { mutate: tagsAssociate } = useTagsAccreditationAssociateMutation();

    return (
        loader || (
            <Flex direction="column" width={1} css={{ padding: '$7' }}>
                <AccreditationHeader accreditation={data.event.accreditation} />

                <Spacer height="7" />

                {data.event.accreditation.hasSlots && (
                    <>
                        <AccreditationSlots />

                        <Spacer height="6" />
                    </>
                )}

                <AccreditationUsers accreditationId={accreditationId} delegationId={null} />

                <Spacer height="6" />

                <Flex gap="6">
                    <Flex direction="column" gap="4" width={1}>
                        <DetailBox title={translate('param_tres_78420')}>
                            <Flex direction="column" gap="4" width={1}>
                                <Flex justify="between" gap="3">
                                    <Box color="gray500">{translate('cat_gorie_00291')}</Box>

                                    <Box color="gray800" fontWeight="medium">
                                        {data.event.accreditation.accreditationCategory.name}
                                    </Box>
                                </Flex>

                                <Separator direction="horizontal" />

                                <Flex justify="between" gap="3">
                                    <Box color="gray500">{translate('Color')}</Box>

                                    <Box
                                        color="gray800"
                                        css={{
                                            background: data.event.accreditation.color,
                                            borderRadius: '20px',
                                            height: '20px',
                                            width: '20px'
                                        }}
                                    />
                                </Flex>

                                <Separator direction="horizontal" />

                                <Flex justify="between" gap="3">
                                    <Box color="gray500">{translate('ic_ne_55554')}</Box>

                                    <Box color="gray800">
                                        <I icon={fromIconVO(data.event.accreditation.icon)} />
                                    </Box>
                                </Flex>
                            </Flex>
                        </DetailBox>

                        <AccreditationCustomFields
                            customFields={data.event.accreditation.accreditationsCustomFields}
                        />

                        <AccreditationTags
                            tags={data.event.accreditation.tags}
                            type="accreditations"
                            associateTags={(tagsIds) => tagsAssociate({
                                    eventId,
                                    accreditationId,
                                    tagsIds
                                })}
                            reload={reload}
                        />
                    </Flex>

                    <Flex direction="column" gap="4" width={1}>
                        <AccreditationDescription accreditation={data.event.accreditation} />

                        <AccreditationLocation accreditation={data.event.accreditation} />

                        <AccreditationPrivateNote accreditation={data.event.accreditation} />
                    </Flex>
                </Flex>
            </Flex>
        )
    );
};
