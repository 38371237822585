import * as React from 'react';
import { Avatar } from '../../components/avatar/avatar';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { DocumentUserAssignmentsFragment } from '../../generated/types';
import { useTranslate } from '../../util/dependencies/dependencies';

interface IAssignmentsImageHeaderProps {
    headerContent: string;
    numberOfAssignments: number;
    userInfo: DocumentUserAssignmentsFragment['organization']['userInfo'];
}

export const AssignmentsImageHeader = (props: IAssignmentsImageHeaderProps) => {
    const translate = useTranslate();

    return (
        <Flex
            direction="column"
            gap="6"
            width={1}
            css={{
                pageBreakBefore: 'always',
                px: '56px'
            }}
        >
            <Box height={210} width={1}>
                <Box
                    height={1}
                    width={1}
                    css={{
                        borderBottomLeftRadius: '$1',
                        borderBottomRightRadius: '$1',
                        overflow: 'hidden'
                    }}
                >
                    <img src={props.headerContent} height="100%" width="100%" />
                </Box>
            </Box>

            <Flex align="center" gap="4">
                <Avatar
                    size={64}
                    name={props.userInfo.name}
                    email={props.userInfo.email}
                    image={props.userInfo.picture?.url}
                />

                <Flex direction="column">
                    <Box color="gray800" fontSize="textMd" fontWeight="medium">
                        {props.userInfo.nameOrEmail}
                    </Box>

                    <Box color="gray500">
                        {translate('nombre_d_affect_45664', props.numberOfAssignments)}
                    </Box>
                </Flex>
            </Flex>
        </Flex>
    );
};
