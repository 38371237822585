import { partition, sortBy } from 'lodash-es';
import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyArray } from '../../../util/array';
import { P2NAssignmentPage } from './p2nAssignmentPage';
import { exploit } from './p2nInterval';
import { P2NPosition } from './p2nPosition';

interface IP2NAssignmentProps {
    event: DocumentUserCustomBadgeFragment;
}

export const P2nAssignment = (props: IP2NAssignmentProps) => {
    const vr = props.event.volunteerRegistration;
    const [others, _montageDemontage] = partition(vr.positionsSlotsUsersInfos, (psui) => psui.positionSlot.range.overlaps(exploit));

    return (
        <>
            {isNonEmptyArray(others) && (
                <P2NAssignmentPage event={props.event} title="Planning P2N 2024">
                    {sortBy(others, ({ positionSlot }) => positionSlot.range.start!.toMillis()).map(
                        (psui) => <P2NPosition key={psui.id} psui={psui} />
                    )}
                </P2NAssignmentPage>
            )}
        </>
    );
};
