import { Empty } from 'common-front/src/components/empty/empty';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { usePositionsSegmentsMobileQuery } from '../../../generated/graphqlHooks';
import { PositionsMobile } from './positionsMobile';
import { PositionsSearchMobile } from '../search/positionsSearchMobile';

export const PositionsLoaderMobile = () => {
    const { organizationId, eventId } = useParams();
    const { data, loader } = usePositionsSegmentsMobileQuery({ eventId });

    if (loader) {
        return loader;
    } else {
        return (
            <Switch>
                <Route
                    path={HeaventPaths.POSITIONS_SEARCH(':organizationId', ':eventId')}
                    children={<PositionsSearchMobile />}
                />

                <Route
                    path={HeaventPaths.POSITIONS_SEGMENT(
                        ':organizationId',
                        ':eventId',
                        ':segmentId'
                    )}
                    children={<PositionsMobile event={data.event} />}
                />

                <Route
                    children={
                        <Empty
                            path={HeaventPaths.POSITIONS_SEGMENT(
                                organizationId,
                                eventId,
                                data.event.segments[0].id
                            )}
                        />
                    }
                />
            </Switch>
        );
    }
};
