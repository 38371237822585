import { chunk } from 'lodash-es';
import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { groupAssignmentsByPositionDay } from '../../userPlanningList/groupAssignmentsByPositionDay';
import { EurockeennesHeader } from './eurockeennesHeader';
import { EurockeennesPage } from './eurockeennesPage';

const NUMBER_OF_ASSIGNMENTS_PER_PAGE = 17;

interface IEurockeennesA4Props {
    event: DocumentUserCustomBadgeFragment;
}

export const EurockeennesA4 = (props: IEurockeennesA4Props) => {
    const assignments = React.useMemo(() => {
        const psuis = props.event.volunteerRegistration.positionsSlotsUsersInfos;

        return groupAssignmentsByPositionDay(psuis);
    }, [props.event]);
    const header = React.useMemo(() => <EurockeennesHeader assignments={assignments} event={props.event} />, [assignments, props.event]);

    return (
        <>
            {chunk(assignments, NUMBER_OF_ASSIGNMENTS_PER_PAGE).map((chunkedAssignments, index) => (
                    <EurockeennesPage
                        key={index}
                        assignments={chunkedAssignments}
                        event={props.event}
                        header={header}
                        index={index}
                    />
                ))}
        </>
    );
};
