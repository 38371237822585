import { Checkbox } from 'common-front/src/designSystem/components/checkbox';
import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { PaginationRow } from 'common-front/src/designSystem/components/pagination/paginationRow';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { HeaderRow } from 'common/src/designSystem/components/table/headerRow';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { TableGrid } from 'common/src/designSystem/components/table/tableGrid';
import {
    PositionId,
    PositionsPositionFragment,
    PositionsSortAttributes,
    SegmentCustomFieldFragment
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { isNonEmptyString } from 'common/src/util/string';
import { compact } from 'lodash-es';
import * as React from 'react';
import { Route, Switch } from 'react-router';
import { useEventContext } from '../../../events/show/eventContext';
import { executePositionsPositionQuery, usePositionsQuery } from '../../../generated/graphqlHooks';
import { PositionRightPanel } from '../../rightPanel/positionRightPanel';
import { PositionRow } from './positionRow';

interface IPositionsListProps {
    customFields: SegmentCustomFieldFragment[];
}

export const PositionsList = (props: IPositionsListProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, segmentId }
    } = useHeavent();
    const {
        event: {
            permissions: { isEventAdmin }
        },
        isPositionCategoryAdmin
    } = useEventContext();
    const {
        columns,
        limit,
        nameDebounced,
        offset,
        possibleColumns,
        predicates,
        reloadKey,
        selectAllState,
        setLimit,
        setOffset,
        setSort,
        setStates,
        sort,
        states,
        toggleRow,
        toggleSelectAll
    } = useSegmentsContext<PositionId, PositionsSortAttributes>();
    const { data, isLoading, reload } = usePositionsQuery({
        eventId,
        loadTags: true,
        loadTraces: false,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : null,
        predicates,
        limit,
        offset,
        sort
    });
    const selectedFields = React.useMemo(() => columns.flatMap((slug) => compact([possibleColumns.find((pc) => pc.slug === slug)])), [possibleColumns, columns]);
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.positions);
    const [positions, setPositions] = React.useState<PositionsPositionFragment[]>([]);
    const [idToLoading, setIdToLoading] = React.useState<Record<PositionId, boolean>>({});
    const reloadPosition = React.useCallback(
        async (id: PositionId) => {
            setIdToLoading((ids) => ({ ...ids, [id]: true }));

            const {
                event: { position }
            } = await executePositionsPositionQuery(
                { eventId, id, loadTags: true },
                await getToken()
            );

            setPositions((ps) => ps.map((p) => (p.id === id ? position : p)));
            setIdToLoading((ids) => ({ ...ids, [id]: false }));
        },
        [setIdToLoading, setPositions]
    );

    React.useEffect(() => {
        setPositions(data.event?.positions.nodes ?? []);
    }, [data.event]);

    React.useEffect(() => {
        if (reloadKey > 1) {
            reload();
        }
    }, [reloadKey]);

    React.useEffect(() => {
        setStates(
            Object.fromEntries(
                (data.event?.positions.nodes ?? []).map((position) => [`p${position.id}`, { id: position.id, state: 'unchecked' }])
            )
        );
    }, [data.event]);

    return (
        <>
            <Flex width={1} css={{ flex: '1', overflow: 'hidden' }}>
                <TableGrid
                    headerRow={
                        <HeaderRow>
                            {isEventAdmin && (
                                <HeaderCell width={48} css={{ paddingRight: 0 }}>
                                    <Checkbox state={selectAllState} onClick={toggleSelectAll} />
                                </HeaderCell>
                            )}

                            {selectedFields.map((field, index) => {
                                const firstCellCss =
                                    index === 0 && isEventAdmin ? { paddingLeft: '$3' } : {};

                                return field.slug === 'category' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={PositionsSortAttributes.Category}
                                        sort={sort}
                                        setSort={setSort}
                                        css={firstCellCss}
                                    >
                                        {translate('cat_gorie_00291')}
                                    </HeaderCellSort>
                                ) : field.slug === 'name' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={PositionsSortAttributes.Name}
                                        sort={sort}
                                        setSort={setSort}
                                        css={firstCellCss}
                                    >
                                        {translate('nom_de_la_missi_64605')}
                                    </HeaderCellSort>
                                ) : field.slug === 'slot' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={PositionsSortAttributes.Slot}
                                        sort={sort}
                                        setSort={setSort}
                                        css={firstCellCss}
                                    >
                                        {translate('cr_neau_11117')}
                                    </HeaderCellSort>
                                ) : field.slug === 'resources' ? (
                                    <HeaderCell key={field.slug} width={256} css={firstCellCss}>
                                        {translate('membres_11310')}
                                    </HeaderCell>
                                ) : field.slug === 'icon' ? (
                                    <HeaderCell key={field.slug} width={100}>
                                        {field.name}
                                    </HeaderCell>
                                ) : field.slug === 'acronym' ? (
                                    <HeaderCellSort
                                        key={field.slug}
                                        attribute={PositionsSortAttributes.Acronym}
                                        sort={sort}
                                        setSort={setSort}
                                        css={firstCellCss}
                                    >
                                        {field.name}
                                    </HeaderCellSort>
                                ) : (
                                    <HeaderCell key={field.slug} css={firstCellCss}>
                                        <Box title={field.name} css={{ ellipsis: true }}>
                                            {field.name}
                                        </Box>
                                    </HeaderCell>
                                );
                            })}

                            {isPositionCategoryAdmin() && <HeaderCell width={100} />}
                        </HeaderRow>
                    }
                    paginationRow={
                        <PaginationRow css={{ borderTop: '1px solid $gray200' }}>
                            <PaginationCell
                                limit={limit}
                                numberOfPages={numberOfPages}
                                offset={offset}
                                showLimits={true}
                                totalCount={totalCount}
                                setLimit={setLimit}
                                setOffset={setOffset}
                            />
                        </PaginationRow>
                    }
                >
                    {isLoading ? (
                        <>
                            <RowSkeleton />
                            <RowSkeleton />
                            <RowSkeleton />
                        </>
                    ) : (
                        positions.map((position) => {
                            if (idToLoading[position.id]) {
                                return <RowSkeleton key={position.id} />;
                            } else {
                                return (
                                    <PositionRow
                                        key={position.id}
                                        columns={selectedFields}
                                        customFields={props.customFields}
                                        eventId={eventId}
                                        organizationId={organizationId}
                                        position={position}
                                        segmentId={segmentId}
                                        state={states[`p${position.id}`]?.state ?? 'unchecked'}
                                        reload={reload}
                                        reloadPosition={reloadPosition}
                                        toggleRow={toggleRow}
                                    />
                                );
                            }
                        })
                    )}
                </TableGrid>
            </Flex>

            <Switch>
                <Route
                    path={HeaventPaths.POSITIONS_LIST_POSITION(
                        ':organizationId',
                        ':eventId',
                        ':segmentId',
                        ':positionId'
                    )}
                    children={
                        <PositionRightPanel
                            onClose={() => {
                                history.push(
                                    HeaventPaths.POSITIONS_LIST(organizationId, eventId, segmentId)
                                );
                            }}
                        />
                    }
                />
            </Switch>
        </>
    );
};
