import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { CustomSlotsPeriodInput, Event } from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormParametersAvailabilititesCustomSlotPeriod } from './formParametersAvailabilititesCustomSlotPeriod';

interface IFormParametersAvailabilitiesCustomSlotsPeriodsProps {
    event: Pick<Event, 'startAt' | 'endAt'>;
    customSlotsPeriods: CustomSlotsPeriodInput[];

    add(): void;
    remove(index: number): void;
    updateCustomSlotPeriod(customSlotPeriod: CustomSlotsPeriodInput, index: number): void;
}

export const FormParametersAvailabilitiesCustomSlotsPeriods = (
    props: IFormParametersAvailabilitiesCustomSlotsPeriodsProps
) => {
    const translate = useTranslate();

    return (
        <Flex
            direction="column"
            gap="3"
            width={1}
            css={{
                background: '$gray50',
                borderRadius: '$1',
                marginLeft: '$6',
                padding: '$6'
            }}
        >
            {props.customSlotsPeriods.map((customSlotPeriod, index) => (
                    <FormParametersAvailabilititesCustomSlotPeriod
                        key={index}
                        customSlotPeriod={customSlotPeriod}
                        event={props.event}
                        index={index}
                        remove={() => {
                            props.remove(index);
                        }}
                        updateCustomSlotPeriod={props.updateCustomSlotPeriod}
                    />
                ))}

            <Flex
                align="center"
                gap="3"
                css={{
                    color: '$primary700',
                    cursor: 'pointer'
                }}
                onClick={props.add}
            >
                <I icon="plus" />

                <Box>{translate('ajouter_une_p_r_04249')}</Box>
            </Flex>
        </Flex>
    );
};
