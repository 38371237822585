import { DeleteModal } from 'common-front/src/designSystem/components/deleteModal';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { OrganizationId, UsersInfoId } from 'common/src/generated/types';
import { CommunityPaths } from 'common/src/util/paths/communityPaths';
import * as React from 'react';
import {
    useOrganizationUserProfileActionsQuery,
    useUserInfoAdminDeleteMutation
} from '../../../generated/graphqlHooks';
import { UserProfileAction } from './userProfileAction';
import { UserProfileActionsContainer } from './userProfileActionsContainer';

interface IOrganizationUserProfileActionsProps {
    organizationId: OrganizationId;
    userInfoId: UsersInfoId;
}

export const OrganizationUserProfileActions = (props: IOrganizationUserProfileActionsProps) => {
    const { history, translate } = useHeavent();
    const { data, loader } = useOrganizationUserProfileActionsQuery({
        organizationId: props.organizationId,
        userInfoId: props.userInfoId
    });
    const { mutate: userInfoAdminDelete } = useUserInfoAdminDeleteMutation();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

    return (
        loader || (
            <>
                <Spacer height="7" />

                <UserProfileActionsContainer title={translate('g_n_ral_64953')}>
                    <UserProfileAction
                        color="error"
                        icon="user-slash"
                        onClick={() => {
                            setIsDeleteModalOpen(true);
                        }}
                    >
                        {translate('supprimer_43083')}
                    </UserProfileAction>
                </UserProfileActionsContainer>

                {isDeleteModalOpen && (
                    <DeleteModal
                        text={translate(
                            'suppression_de_04261',
                            data.organization.userInfo.nameOrEmail
                        )}
                        subtext={[
                            translate('_tes_vous_s_r_d_53083'),
                            translate('cette_action_es_08212')
                        ]}
                        buttonText={translate('supprimer_43083')}
                        onDelete={async () => {
                            await userInfoAdminDelete({
                                organizationId: props.organizationId,
                                userInfoId: props.userInfoId
                            });

                            history.push(CommunityPaths.COMMUNITY_USERS(props.organizationId));
                        }}
                        onClose={() => {
                            setIsDeleteModalOpen(false);
                        }}
                    />
                )}
            </>
        )
    );
};
