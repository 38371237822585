import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { getColorByBackgroundColor } from '../../../util/color';
import { getIconComponent } from '../icon';

interface IBadgeJokersfrancesloveniaZoneProps {
    accreditation: DocumentUserCustomBadgeFragment['volunteerRegistration']['accreditationsUsersInfos'][0]['accreditation'];
}

export const BadgeJokersfrancesloveniaZone = (props: IBadgeJokersfrancesloveniaZoneProps) => {
    const Icon = getIconComponent(props.accreditation.icon);
    const fill = getColorByBackgroundColor(props.accreditation.color);

    return (
        <Flex direction="column" gap="1" align="center" width={1}>
            <Flex
                align="center"
                justify="center"
                css={{
                    background: props.accreditation.color,
                    borderRadius: '$1',
                    height: '28px',
                    width: '28px',
                    '& svg': {
                        fill,
                        height: '50%',
                        width: '50%'
                    }
                }}
            >
                <Icon />
            </Flex>

            <Box
                textAlign="center"
                css={{
                    fontSize: '12px'
                }}
            >
                {props.accreditation.name}
            </Box>
        </Flex>
    );
};
