import { RichSelect } from 'common-front/src/designSystem/components/richSelect/richSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { PositionId } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import * as React from 'react';
import { useDocumentsPositionsQuery } from '../../generated/graphqlHooks';

interface IDocumentsPositionSelectorProps {
    getPath(positionId: PositionId): string;
}

export const DocumentsPositionSelector = (props: IDocumentsPositionSelectorProps) => {
    const {
        history,
        translate,
        params: { eventId, positionId }
    } = useHeavent();
    const { data, isLoading } = useDocumentsPositionsQuery({ eventId });

    return (
        <Flex
            align="center"
            gap="4"
            css={{
                background: '$gray100',
                border: '1px solid $gray200',
                borderBottom: 'none',
                padding: '$3 $7'
            }}
        >
            <Box font="gray900 textMd semiBold">{translate('aper_u_pour_la_94895')}</Box>

            <Box width={320}>
                {isLoading ? (
                    <Skeleton height={40} width={1} borderRadius="$1" />
                ) : (
                    <RichSelect
                        isSearchVisible={true}
                        values={[positionId]}
                        onChange={(newValue) => {
                            if (isNonEmptyArray(newValue)) {
                                history.push(props.getPath(newValue[0]));
                            }
                        }}
                    >
                        {data.event.positions.nodes.map((position) => (
                                <option key={position.id} value={position.id}>
                                    {position.name}
                                </option>
                            ))}
                    </RichSelect>
                )}
            </Box>
        </Flex>
    );
};
