import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CSS } from 'common/src/designSystem/components/stitches';
import { reorder } from 'common/src/util/array';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { produce } from 'immer';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProvided
} from 'react-beautiful-dnd';
import { Button } from './button';
import { RightPanel } from './rightPanel/rightPanel';
import { RightPanelBody } from './rightPanel/rightPanelBody';
import { RightPanelFooter } from './rightPanel/rightPanelFooter';
import { TextInput } from './textInput';
import { Toggle } from './toggle';

type Column = {
    slug: string;
    name: string;
};

interface IOrderRightPanelProps<T> {
    columns: Array<T & Column>;
    css?: CSS;
    initialSelectedColumns: string[];
    saveButtonText: string;
    searchPlaceholder: string;
    subtitle: string;
    title: string;

    onClose(): void;
    onSave(columns: string[]): void;
}

export const OrderRightPanel = <T extends {}>(props: IOrderRightPanelProps<T>) => {
    const translate = useTranslate();
    const [search, setSearch] = React.useState('');
    const [columns, setColumns] = React.useState<Array<T & Column & { isSelected: boolean }>>(
        sortBy(
            props.columns.map((column) => ({
                ...column,
                isSelected: props.initialSelectedColumns.includes(column.slug)
            })),
            [
                ({ slug }) => {
                    const index = props.initialSelectedColumns.indexOf(slug);

                    return index === -1 ? props.columns.length : index;
                },
                ({ name }) => name
            ]
        )
    );
    const shouldDisplay = (fieldName: string) => {
        if (isNonEmptyString(search)) {
            return fieldName.toLowerCase().includes(search.trim().toLowerCase());
        } else {
            return true;
        }
    };
    const onChange = (column: T & Column & { isSelected: boolean }, columnIndex: number) => {
        setColumns(
            produce((draft) => {
                draft[columnIndex].isSelected = !column.isSelected;
            })
        );
    };

    return (
        <RightPanel css={props.css} size="sm" onClose={props.onClose}>
            <RightPanelBody>
                <Spacer height="6" />

                <Box color="gray900" fontSize="textXl">
                    {props.title}
                </Box>

                <Box color="gray500">{props.subtitle}</Box>

                <Spacer height="6" />

                <TextInput
                    icon="magnifying-glass"
                    value={search}
                    onChange={setSearch}
                    placeholder={props.searchPlaceholder}
                />

                <Spacer height="6" />

                <Box color="gray900" fontSize="textMd" fontWeight="semiBold">
                    {translate('liste_des_champ_00325')}
                </Box>

                <Spacer height="2" />

                <DragDropContext
                    onDragEnd={({ reason, source, destination }) => {
                        if (
                            reason === 'DROP' &&
                            destination &&
                            source.index !== destination.index
                        ) {
                            setColumns(reorder(columns, source.index, destination.index));
                        }
                    }}
                >
                    <Droppable droppableId="1">
                        {(droppableProvided: DroppableProvided) => (
                                <Flex
                                    direction="column"
                                    gap="2"
                                    {...droppableProvided.droppableProps}
                                    ref={droppableProvided.innerRef}
                                >
                                    {columns.map((field, fieldIndex) => {
                                        const display = shouldDisplay(field.name);

                                        return display ? (
                                            <Draggable
                                                draggableId={field.slug}
                                                index={fieldIndex}
                                                key={field.slug}
                                            >
                                                {(draggableProvided: DraggableProvided) => (
                                                        <Flex
                                                            gap="4"
                                                            css={{
                                                                border: '1px solid $gray200',
                                                                borderRadius: '$1',
                                                                boxShadow: '$xs',
                                                                padding: '$4'
                                                            }}
                                                            {...draggableProvided.draggableProps}
                                                            ref={draggableProvided.innerRef}
                                                        >
                                                            <Box
                                                                color="gray300"
                                                                css={{ cursor: 'grab' }}
                                                                {...draggableProvided.dragHandleProps}
                                                            >
                                                                <I
                                                                    icon="grip-vertical"
                                                                    style="solid"
                                                                />
                                                            </Box>

                                                            <Box
                                                                color="gray700"
                                                                css={{ flex: '1' }}
                                                            >
                                                                {field.name}
                                                            </Box>

                                                            <Toggle
                                                                value={field.isSelected}
                                                                onChange={() => {
                                                                    onChange(field, fieldIndex);
                                                                }}
                                                            />
                                                        </Flex>
                                                    )}
                                            </Draggable>
                                        ) : null;
                                    })}

                                    {droppableProvided.placeholder}
                                </Flex>
                            )}
                    </Droppable>
                </DragDropContext>

                <Spacer height="6" />
            </RightPanelBody>

            <RightPanelFooter>
                <Flex justify="end" gap="3">
                    <Button
                        onClick={() => {
                            props.onSave(columns.filter((c) => c.isSelected).map((c) => c.slug));
                            props.onClose();
                        }}
                    >
                        {props.saveButtonText}
                    </Button>

                    <Button color="white" onClick={props.onClose}>
                        {translate('annuler_48254')}
                    </Button>
                </Flex>
            </RightPanelFooter>
        </RightPanel>
    );
};
