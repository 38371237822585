import { Content } from 'common-front/src/designSystem/components/tooltip/content';
import { Tooltip } from 'common-front/src/designSystem/components/tooltip/tooltip';
import { Trigger } from 'common-front/src/designSystem/components/tooltip/trigger';
import { copy } from 'common-front/src/util/commands';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IOrganizationEmailsDnsRowProps {
    name: string;
    type: string;
    value: string;
}

export const OrganizationEmailsDnsRow = (props: IOrganizationEmailsDnsRowProps) => {
    const translate = useTranslate();
    const [isNameLoading, setIsNameLoading] = React.useState(false);
    const [isValueLoading, setIsValueLoading] = React.useState(false);

    return (
        <Row>
            <Cell>
                <Flex align="center" css={{ '& i': { cursor: 'pointer' } }}>
                    <Tooltip>
                        <Trigger>
                            <Box width={20}>
                                {isNameLoading ? (
                                    <I icon="loader" />
                                ) : (
                                    <I
                                        icon="copy"
                                        onClick={() => {
                                            setIsNameLoading(true);
                                            copy(props.name);

                                            setTimeout(() => {
                                                setIsNameLoading(false);
                                            }, 500);
                                        }}
                                    />
                                )}
                            </Box>
                        </Trigger>

                        <Content placement="top">{translate('copier_75889')}</Content>
                    </Tooltip>
                    {props.name}
                </Flex>
            </Cell>
            <Cell width={100}>{props.type}</Cell>
            <Cell>
                <Flex align="center" gap="1" css={{ '& i': { cursor: 'pointer' } }}>
                    <Tooltip>
                        <Trigger>
                            <Box width={20}>
                                {isValueLoading ? (
                                    <I icon="loader" />
                                ) : (
                                    <I
                                        icon="copy"
                                        onClick={() => {
                                            setIsValueLoading(true);
                                            copy(props.value);

                                            setTimeout(() => {
                                                setIsValueLoading(false);
                                            }, 500);
                                        }}
                                    />
                                )}
                            </Box>
                        </Trigger>

                        <Content placement="top">{translate('copier_75889')}</Content>
                    </Tooltip>
                    {props.value}
                </Flex>
            </Cell>
        </Row>
    );
};
