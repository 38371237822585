import { Box } from 'common/src/designSystem/components/box';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    AssignmentSlotsQuery,
    PositionId,
    PositionsCategoryId,
    PositionsSlot,
    PositionsSlotId,
    UsersInfo
} from 'common/src/generated/types';
import { IntervalService } from 'common/src/services/intervalService';
import { toggle } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { overlaps, splitSlots } from 'common/src/vo/positionSlot';
import { DateTimeFormatOptions, Interval } from 'luxon';
import * as React from 'react';
import { useAssignmentContext } from './assignmentContext';
import { AssignmentSlot } from './assignmentSlot';

const getSubtitle = (
    intervalService: IntervalService,
    positionSlot: Pick<PositionsSlot, 'name' | 'range'>
) => {
    const format: DateTimeFormatOptions = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    };
    if (isNonEmptyString(positionSlot.name)) {
        return `${positionSlot.name} - ${intervalService.toLocaleString(
            positionSlot.range,
            undefined,
            format
        )}`;
    } else {
        return intervalService.toLocaleString(positionSlot.range, undefined, format);
    }
};

interface IAssignmentSlotsProps {
    positionsSlots: AssignmentSlotsQuery['event']['positionsSlots']['nodes'];
    userInfo: Pick<UsersInfo, 'name' | 'fields'>;
    wishedPositionsCategoriesIds: PositionsCategoryId[];
    wishedPositionsIds: PositionId[];
    wishedPositionsSlotsIds: PositionsSlotId[];
    wishedRanges: Interval[];
}

export const AssignmentSlots = (props: IAssignmentSlotsProps) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);
    const { isEdit, positionsSlotsIds, setPositionsSlotsIds } = useAssignmentContext();
    const initialPositionsSlots = React.useMemo(() => props.positionsSlots.filter((ps) => isEdit && positionsSlotsIds.includes(ps.id)), [isEdit]);
    const { wishedPositionsSlots, possiblePositionsSlots, otherPositionsSlots } =
        React.useMemo(() => {
            const allSlots = splitSlots(
                props.positionsSlots,
                props.wishedPositionsCategoriesIds,
                props.wishedPositionsIds,
                props.wishedPositionsSlotsIds,
                props.wishedRanges,
                props.userInfo.fields
            );
            const initialPositionsSlotsIds = initialPositionsSlots.map((ps) => ps.id);

            return {
                wishedPositionsSlots: allSlots.wishedPositionsSlots.filter(
                    (ps) => !initialPositionsSlotsIds.includes(ps.id)
                ),
                possiblePositionsSlots: allSlots.possiblePositionsSlots.filter(
                    (ps) => !initialPositionsSlotsIds.includes(ps.id)
                ),
                otherPositionsSlots: allSlots.otherPositionsSlots.filter(
                    (ps) => !initialPositionsSlotsIds.includes(ps.id)
                )
            };
        }, [
            props.positionsSlots,
            props.userInfo,
            props.wishedPositionsCategoriesIds,
            props.wishedPositionsIds,
            props.wishedPositionsSlotsIds,
            props.wishedRanges
        ]);
    const selectedPositionsSlots = React.useMemo(() => {
        const allPositionsSlots = wishedPositionsSlots
            .concat(possiblePositionsSlots)
            .concat(otherPositionsSlots);

        return positionsSlotsIds.flatMap((id) => {
            const slot = allPositionsSlots.find((positionSlot) => positionSlot.id === id);

            return slot ? [slot] : [];
        });
    }, [wishedPositionsSlots, possiblePositionsSlots, otherPositionsSlots, positionsSlotsIds]);

    return (
        <>
            {isEdit && (
                <>
                    <Box font="gray900 textMd medium">
                        {translate('cr_neau_affect_50400', initialPositionsSlots.length)}
                    </Box>

                    <Spacer height="1" />

                    <Box color="gray500">
                        {translate('cr_neaux_sur_le_05515', props.userInfo.name)}
                    </Box>

                    <Spacer height="3" />

                    {initialPositionsSlots.map((positionSlot) => (
                            <React.Fragment key={positionSlot.id}>
                                <AssignmentSlot
                                    assignedResources={positionSlot.assignedResources}
                                    category={positionSlot.positionCategory.name}
                                    color={positionSlot.position.color}
                                    icon={positionSlot.position.icon}
                                    isSelected={positionsSlotsIds.includes(positionSlot.id)}
                                    neededResources={positionSlot.resources}
                                    overlaps={overlaps(positionSlot, selectedPositionsSlots)}
                                    subtitle={getSubtitle(intervalService, positionSlot)}
                                    title={positionSlot.position.name}
                                    onClick={() => {
                                        setPositionsSlotsIds(
                                            toggle(positionsSlotsIds, positionSlot.id)
                                        );
                                    }}
                                />

                                <Spacer height="3" />
                            </React.Fragment>
                        ))}

                    <Spacer height="6" />
                </>
            )}

            <Box font="gray900 textMd medium">
                {translate('creneau_souhaite', wishedPositionsSlots.length)}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('cr_neaux_dont_l_10130')}</Box>

            <Spacer height="3" />

            {wishedPositionsSlots.map((positionSlot) => (
                    <React.Fragment key={positionSlot.id}>
                        <AssignmentSlot
                            assignedResources={positionSlot.assignedResources}
                            category={positionSlot.positionCategory.name}
                            color={positionSlot.position.color}
                            icon={positionSlot.position.icon}
                            isSelected={positionsSlotsIds.includes(positionSlot.id)}
                            neededResources={positionSlot.resources}
                            overlaps={overlaps(positionSlot, selectedPositionsSlots)}
                            subtitle={getSubtitle(intervalService, positionSlot)}
                            title={positionSlot.position.name}
                            onClick={() => {
                                setPositionsSlotsIds(toggle(positionsSlotsIds, positionSlot.id));
                            }}
                        />

                        <Spacer height="3" />
                    </React.Fragment>
                ))}

            <Spacer height="6" />

            <Box font="gray900 textMd medium">
                {translate('autre_creneau_possible', possiblePositionsSlots.length)}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('cr_neaux_dont_l_99850')}</Box>

            <Spacer height="3" />

            {possiblePositionsSlots.map((positionSlot) => (
                    <React.Fragment key={positionSlot.id}>
                        <AssignmentSlot
                            assignedResources={positionSlot.assignedResources}
                            category={positionSlot.positionCategory.name}
                            color={positionSlot.position.color}
                            icon={positionSlot.position.icon}
                            isSelected={positionsSlotsIds.includes(positionSlot.id)}
                            neededResources={positionSlot.resources}
                            overlaps={overlaps(positionSlot, selectedPositionsSlots)}
                            subtitle={getSubtitle(intervalService, positionSlot)}
                            title={positionSlot.position.name}
                            onClick={() => {
                                setPositionsSlotsIds(toggle(positionsSlotsIds, positionSlot.id));
                            }}
                        />

                        <Spacer height="3" />
                    </React.Fragment>
                ))}

            <Spacer height="6" />

            <Box font="gray900 textMd medium">
                {translate('autre_creneau', otherPositionsSlots.length)}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('cr_neaux_dont_l_25685')}</Box>

            <Spacer height="3" />

            {otherPositionsSlots.map((positionSlot) => (
                    <React.Fragment key={positionSlot.id}>
                        <AssignmentSlot
                            assignedResources={positionSlot.assignedResources}
                            category={positionSlot.positionCategory.name}
                            color={positionSlot.position.color}
                            icon={positionSlot.position.icon}
                            isSelected={positionsSlotsIds.includes(positionSlot.id)}
                            neededResources={positionSlot.resources}
                            overlaps={overlaps(positionSlot, selectedPositionsSlots)}
                            subtitle={getSubtitle(intervalService, positionSlot)}
                            title={positionSlot.position.name}
                            onClick={() => {
                                setPositionsSlotsIds(toggle(positionsSlotsIds, positionSlot.id));
                            }}
                        />

                        <Spacer height="3" />
                    </React.Fragment>
                ))}
        </>
    );
};
