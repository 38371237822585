import { Button } from 'common-front/src/designSystem/components/button';
import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { LabelOptional } from 'common-front/src/designSystem/components/input/labelOptional';
import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { TimeInput } from 'common-front/src/designSystem/form/date/timeInput';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { ToggleText } from 'common-front/src/designSystem/form/toggle';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CustomSlotsPeriodInput, Event } from 'common/src/generated/types';
import { CustomSlotInputService } from 'common/src/input/customSlotInput';
import { ICreateCustomSlotPeriodValues } from 'common/src/input/customSlotPeriodInput';
import { removeAtIndex } from 'common/src/util/array';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IUpdateCustomSlotPeriodModalProps {
    customSlotPeriod: CustomSlotsPeriodInput;
    event: Pick<Event, 'startAt' | 'endAt'>;

    onClose(): void;
    onSuccess(customSlotPeriod: CustomSlotsPeriodInput): Promise<void>;
}

export const UpdateCustomSlotPeriodModal = (props: IUpdateCustomSlotPeriodModalProps) => {
    const translate = useTranslate();
    const customSlotInput = useService(CustomSlotInputService);

    return (
        <FormModal
            size="md"
            buttonText={translate('mettre_jour_l_81276')}
            icon="sliders"
            initialValues={{
                customSlotPeriod: props.customSlotPeriod
            }}
            title={translate('mise_jour_de_14984')}
            subtitle={translate('param_trer_les_33935')}
            onClose={props.onClose}
            onSubmit={async (values: ICreateCustomSlotPeriodValues) => {
                await props.onSuccess(values.customSlotPeriod);
                props.onClose();
            }}
        >
            {({ form, values }) => (
                    <Flex direction="column" width={1} css={{ flex: '1', overflowY: 'auto' }}>
                        <TextInput
                            label={<LabelOptional>Nom de la période personnalisée</LabelOptional>}
                            name="customSlotPeriod.name"
                            hint={translate('le_nom_de_la_p_49293')}
                        />

                        <Spacer height="4" />

                        <Flex gap="4">
                            <DateInput
                                label={
                                    <LabelOptional>
                                        {translate('date_de_d_but_43380')}
                                    </LabelOptional>
                                }
                                name="customSlotPeriod.startDate"
                                min={props.event.startAt}
                                max={props.event.endAt}
                            />

                            <DateInput
                                label={
                                    <LabelOptional>{translate('date_de_fin_86920')}</LabelOptional>
                                }
                                name="customSlotPeriod.endDate"
                                min={props.event.startAt}
                                max={props.event.endAt}
                            />
                        </Flex>

                        <Spacer height="7" />

                        <Box font="gray800 textSm semiBold">
                            {translate('param_trer_les_28991')}
                        </Box>

                        <Spacer height="4" />

                        {values.customSlotPeriod.customSlots.map((_customSlot, customSlotIndex) => {
                            const baseName = `customSlotPeriod.customSlots[${customSlotIndex}]`;

                            return (
                                <React.Fragment key={customSlotIndex}>
                                    <Flex gap="4" align="end">
                                        <Box css={{ flex: '1' }}>
                                            <TextInput
                                                label={translate('nom_du_cr_neau_54351')}
                                                name={`${baseName}.name`}
                                            />
                                        </Box>

                                        <Box css={{ flex: '1' }}>
                                            <TimeInput
                                                label={translate('d_but_du_cr_nea_91700')}
                                                name={`${baseName}.startTime`}
                                            />
                                        </Box>

                                        <Box css={{ flex: '1' }}>
                                            <TimeInput
                                                label={translate('fin_du_cr_neau_81741')}
                                                name={`${baseName}.endTime`}
                                            />
                                        </Box>

                                        <Button
                                            color="white"
                                            leftIcon="trash-can"
                                            onClick={() => {
                                                form.change(
                                                    'customSlotPeriod.customSlots',
                                                    removeAtIndex(
                                                        values.customSlotPeriod.customSlots,
                                                        customSlotIndex
                                                    )
                                                );
                                            }}
                                        ></Button>
                                    </Flex>

                                    <Spacer height="4" />
                                </React.Fragment>
                            );
                        })}

                        <Box
                            color="primary700"
                            css={{ cursor: 'pointer' }}
                            onClick={() => {
                                form.change('customSlotPeriod.customSlots', [
                                    ...values.customSlotPeriod.customSlots,
                                    customSlotInput.customSlotInputDefault()
                                ]);
                            }}
                        >
                            + {translate('ajouter_un_cr_n_63607')}
                        </Box>

                        {values.customSlotPeriod.customSlots.length < 2 && (
                            <>
                                <Spacer height="6" />

                                <Box font="gray800 textSm medium">
                                    {translate('options_d_affic_10797')}
                                </Box>

                                <Spacer height="2" />

                                <ToggleText name="customSlotPeriod.hideNameDates">
                                    {translate('masquer_les_inf_78021')}
                                </ToggleText>

                                <Spacer height="2" />

                                <ToggleText name="customSlotPeriod.displayAsCalendar">
                                    {translate('afficher_les_di_90328')}
                                </ToggleText>
                            </>
                        )}
                    </Flex>
                )}
        </FormModal>
    );
};
