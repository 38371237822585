import { Button } from 'common-front/src/designSystem/components/button';
import { TableFilters } from 'common-front/src/designSystem/components/tableFilters';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useStateDebounce } from 'common-front/src/hooks/useStateDebounce';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { HeaderCell } from 'common/src/designSystem/components/table/headerCell';
import { HeaderCellSort } from 'common/src/designSystem/components/table/headerCellSort';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { EventId, PositionsSortAttributes } from 'common/src/generated/types';
import { IMassPreAssignValues } from 'common/src/input/massPreAssignInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { Sort } from 'common/src/vo/sort';
import * as React from 'react';
import { useMassPreAssignPositionsQuery } from '../../generated/graphqlHooks';
import { MassPreAssignPositionRow } from './massPreAssignPositionRow';

interface IMassPreAssignStep2PositionsProps {
    eventId: EventId;
    numberOfSelected: number;
    values: IMassPreAssignValues;

    change(name: string, value: any): void;
    handleSubmit(): void;
}

export const MassPreAssignStep2Positions = (props: IMassPreAssignStep2PositionsProps) => {
    const translate = useTranslate();
    const [name, nameDebounced, setName] = useStateDebounce('');
    const [offset, setOffset] = React.useState(0);
    const [sort, setSort] = React.useState<Sort<PositionsSortAttributes> | null>(null);
    const { data, isLoading } = useMassPreAssignPositionsQuery({
        eventId: props.eventId,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        offset,
        sort
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.positions);
    const _setSort = (newSort: Sort<PositionsSortAttributes> | null) => {
        setSort(newSort);
        setOffset(0);
    };

    return (
        <>
            <TableFilters
                filters={
                    <>
                        <Box width={320}>
                            <TextInput
                                value={name}
                                onChange={(newName: string) => {
                                    setOffset(0);
                                    setName(newName);
                                }}
                                placeholder={translate('rechercher_une_69998')}
                                icon="magnifying-glass"
                            />
                        </Box>
                    </>
                }
                headerCells={
                    <>
                        <HeaderCell width={48} css={{ paddingRight: 0 }} />
                        <HeaderCellSort
                            attribute={PositionsSortAttributes.Name}
                            sort={sort}
                            setSort={_setSort}
                        >
                            {translate('nom_de_la_missi_64605')}
                        </HeaderCellSort>
                        <HeaderCell />
                    </>
                }
                numberOfPages={numberOfPages}
                offset={offset}
                rows={
                    isLoading ? (
                        <>
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                            <RowSkeleton bx={true} />
                        </>
                    ) : (
                        data.event.positions.nodes.map((position) => (
                                <MassPreAssignPositionRow
                                    key={position.id}
                                    position={position}
                                    positionsIds={props.values.massPreAssign.positionsIds}
                                    change={props.change}
                                />
                            ))
                    )
                }
                title={
                    <Flex direction="column" gap="1">
                        <Box>{translate('s_lectionner_le_26300')}</Box>
                        <Box font="gray500 textSm regular">
                            {translate('les_membres_s_l_63510')}
                        </Box>
                    </Flex>
                }
                totalCount={totalCount}
                setOffset={setOffset}
            />

            <Spacer height="7" />

            <Flex>
                <Button onClick={props.handleSubmit}>
                    {translate('pr_affecter_le_81533', props.numberOfSelected)}
                </Button>
            </Flex>
        </>
    );
};
