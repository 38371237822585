import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { Form } from 'common-front/src/components/form/form';
import { FormErrors } from 'common-front/src/components/form/formErrors';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Button } from 'common-front/src/designSystem/components/button';
import { FormBox } from 'common-front/src/designSystem/components/formBox';
import { CheckboxText } from 'common-front/src/designSystem/form/checkbox';
import { DateInput } from 'common-front/src/designSystem/form/date/dateInput';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import {
    ALL_CUSTOMBADGE,
    ALL_FEATURE,
    BackofficeOrganizationQuery
} from 'common/src/generated/types';
import {
    BackofficeOrganizationInputService,
    IUpdateBackofficeOrganizationValues
} from 'common/src/input/backofficeOrganizationInput';
import { ValidateService } from 'common/src/services/validateService';
import {
    useHistory,
    useParams,
    useService,
    useTranslate
} from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { getDisplayName } from 'common/src/vo/customBadge';
import { sortBy, uniqBy } from 'lodash-es';
import * as React from 'react';
import {
    useBackofficeOrganizationQuery,
    useBackofficeOrganizationUpdateMutation
} from '../generated/graphqlHooks';

interface IUpdateBackofficeOrganizationComponentProps {
    organization: BackofficeOrganizationQuery['backoffice']['organization'];
}

const UpdateBackofficeOrganizationComponent = (
    props: IUpdateBackofficeOrganizationComponentProps
) => {
    const translate = useTranslate();
    const backofficeOrganizationInput = useService(BackofficeOrganizationInputService);
    const validateService = useService(ValidateService);
    const { mutate } = useBackofficeOrganizationUpdateMutation();
    const history = useHistory();
    const usersIdsOptions = React.useMemo(() => uniqBy(
            CommonEnvVars.ADMINS.concat(props.organization.users).map((user) => ({
                id: user.id,
                name: user.email
            })),
            ({ id }) => id
        ), [props.organization]);
    const customBadges = React.useMemo(() => sortBy(
            ALL_CUSTOMBADGE.map((customBadge) => ({
                value: customBadge,
                name: getDisplayName(customBadge)
            })),
            ({ name }) => name
        ), []);

    return (
        <Form
            height={1}
            width={1}
            hideErrors={true}
            initialValues={{
                organization: backofficeOrganizationInput.organizationInputDefault(
                    props.organization
                )
            }}
            validate={validateService.validateForForm(
                backofficeOrganizationInput.updateOrganizationSchema()
            )}
            onSubmit={async (values: IUpdateBackofficeOrganizationValues) => {
                await mutate({
                    id: props.organization.id,
                    organization: values.organization
                });

                history.push(HeaventPaths.BACKOFFICE_ORGANIZATIONS);
            }}
            render={({ handleSubmit, submitting }) => (
                    <FullScreenPopup
                        title={translate('mise_jour_de_17055', props.organization.name)}
                        category="Backoffice"
                        closePath={HeaventPaths.BACKOFFICE_ORGANIZATIONS}
                    >
                        <CenteredContainer>
                            <FormErrors />

                            <FormBox>
                                <TextInput
                                    label={translate('nom_de_l_organi_49583')}
                                    name="organization.name"
                                />

                                <Spacer height="4" />

                                <DateInput
                                    label={translate('date_de_fin_de_17750')}
                                    name="organization.periodEndAt"
                                />

                                <Spacer height="4" />

                                <RichSelect
                                    isSearchVisible={true}
                                    isSelectAllVisible={true}
                                    multiple={true}
                                    label={translate('fonctionnalit_s_47714')}
                                    name="organization.features"
                                >
                                    {ALL_FEATURE.map((feature) => (
                                            <option key={feature} value={feature}>
                                                {feature}
                                            </option>
                                        ))}
                                </RichSelect>

                                <Spacer height="4" />

                                <RichSelect
                                    isSearchVisible={true}
                                    multiple={true}
                                    label={translate('badges_personna_27045')}
                                    name="organization.customBadges"
                                >
                                    {customBadges.map(({ value, name }) => (
                                            <option key={value} value={value}>
                                                {name}
                                            </option>
                                        ))}
                                </RichSelect>

                                <Spacer height="4" />

                                <RichSelect
                                    isSearchVisible={true}
                                    multiple={true}
                                    label={translate('utilisateurs_ay_27051')}
                                    name="organization.usersIds"
                                >
                                    {usersIdsOptions.map(({ id, name }) => (
                                            <option key={id} value={id}>
                                                {name}
                                            </option>
                                        ))}
                                </RichSelect>

                                <Spacer height="4" />

                                <CheckboxText name="organization.isBlocked">
                                    {translate('bloquer_l_organ_81103')}
                                </CheckboxText>
                            </FormBox>

                            <Spacer height="6" />

                            <FormBox>
                                <Button
                                    onClick={handleSubmit}
                                    isLoading={submitting}
                                    textAlign="center"
                                >
                                    {translate('mettre_jour_l_12499')}
                                </Button>
                            </FormBox>
                        </CenteredContainer>
                    </FullScreenPopup>
                )}
        />
    );
};

export const UpdateBackofficeOrganization = () => {
    const { organizationId } = useParams();
    const { data, loader } = useBackofficeOrganizationQuery({ id: organizationId });

    return (
        loader || (
            <UpdateBackofficeOrganizationComponent organization={data.backoffice.organization} />
        )
    );
};
