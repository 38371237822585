import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { AvatarNameEmail } from 'common/src/components/avatarNameEmail';
import { Badge } from 'common/src/designSystem/components/badge';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { CellControls } from 'common/src/designSystem/components/table/cellControls';
import { Row } from 'common/src/designSystem/components/table/row';
import {
    CheckInState,
    PositionId,
    PositionsCategoryId,
    PositionSlotVolunteersQuery,
    PositionsSlotId
} from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { DurationService } from 'common/src/services/durationService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';
import { PositionPositionSlotVolunteerRowDropdown } from './positionPositionSlotVolunteerRowDropdown';

interface IPositionPositionSlotVolunteerRowProps {
    positionCategoryId: PositionsCategoryId;
    positionId: PositionId;
    positionSlotId: PositionsSlotId;
    volunteerRegistration: PositionSlotVolunteersQuery['event']['volunteersRegistrations']['nodes'][0];

    reload(): void;
}

export const PositionPositionSlotVolunteerRow = (props: IPositionPositionSlotVolunteerRowProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId, positionId, positionSlotId }
    } = useHeavent();
    const dateTimeService = useService(DateTimeService);
    const durationService = useService(DurationService);

    return (
        <Row
            css={{
                cursor: 'pointer',
                '&:hover': {
                    background: '$gray100'
                }
            }}
            onClick={() => {
                history.push(
                    HeaventPaths.POSITION_SLOT_MEMBERS_USER_INFORMATIONS(
                        organizationId,
                        eventId,
                        positionId,
                        positionSlotId,
                        props.volunteerRegistration.userInfo.id
                    )
                );
            }}
        >
            <Cell>
                <AvatarNameEmail userInfo={props.volunteerRegistration.userInfo} hasName={true} />
            </Cell>
            <Cell>
                {props.volunteerRegistration.positionSlotUserInfo!.state ===
                CheckInState.CheckedOut ? (
                    <Badge leftIcon="clock">
                        {translate(
                            'temps_total_79448',
                            durationService.minutesToHoursMinutesString(
                                props.volunteerRegistration.positionSlotUserInfo!
                                    .timeDifferenceMinutes
                            )
                        )}
                    </Badge>
                ) : props.volunteerRegistration.positionSlotUserInfo!.state ===
                  CheckInState.CheckedIn ? (
                    <Badge leftIcon="circle" leftIconStyle="solid" color="success" size="sm">
                        {translate(
                            'point_le_1_63576',
                            dateTimeService.toLocaleString(
                                props.volunteerRegistration.positionSlotUserInfo!.checkInAt!.toLocal(),
                                LocaleFormats.DateTime
                            )
                        )}
                    </Badge>
                ) : (
                    <Badge leftIcon="circle" leftIconStyle="solid" color="warning" size="sm">
                        {translate('non_point_42145')}
                    </Badge>
                )}
            </Cell>
            <CellControls justify="center">
                <PositionPositionSlotVolunteerRowDropdown
                    eventId={eventId}
                    positionSlotId={props.positionSlotId}
                    volunteerRegistration={props.volunteerRegistration}
                    reload={props.reload}
                />
            </CellControls>
        </Row>
    );
};
