import { range as lodashRange } from 'lodash-es';
import * as React from 'react';
import XMark from 'svgComponents/fontawesome/xmark.svg';
import { Avatar } from '../../components/avatar/avatar';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { DocumentPositionPlanningFragment, PositionsSlot } from '../../generated/types';
import { DateTimeService } from '../../services/dateTimeService';
import { useService, useTranslate } from '../../util/dependencies/dependencies';
import { getNumberOfDays } from '../../util/interval';
import { LocaleFormats } from '../../util/luxon';
import { filterSameDaySlots } from '../../vo/positionSlot';
import { AssignedCell, AvailableCell, HeaderCell, NameCell, UnavailableCell } from '../cells';

interface IPositionPlanningPageProps {
    position: DocumentPositionPlanningFragment['position'];
    slots: Array<Pick<PositionsSlot, 'id' | 'range'>>;
    volunteersRegistrations: DocumentPositionPlanningFragment['volunteersRegistrations']['nodes'];
}

export const PositionPlanningPage = (props: IPositionPlanningPageProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);
    const numberOfDays = React.useMemo(() => {
        const interval = props.slots
            .map(({ range }) => range)
            .reduce((previousValue, currentValue) => previousValue.union(currentValue), props.slots[0].range);

        return getNumberOfDays(interval);
    }, [props.slots]);
    const start = props.slots[0].range.start!;

    return (
        <Flex
            direction="column"
            width={1}
            css={{
                background: 'white',
                pageBreakBefore: 'always'
            }}
        >
            <Flex direction="column" css={{ background: '$gray100', padding: '$6' }}>
                <Box font="gray900 textXl medium">
                    {translate('planning_1_14391', props.position.name)}
                </Box>
            </Flex>

            <Flex
                direction="column"
                width={1}
                css={{
                    background: 'white',
                    padding: '$6'
                }}
            >
                <Flex>
                    <HeaderCell width={200}>{translate('jours_88317')}</HeaderCell>

                    {lodashRange(0, numberOfDays).map((i) => {
                        const day = start.plus({ days: i });
                        const filteredSlots = filterSameDaySlots(day, props.slots);

                        return (
                            <HeaderCell key={i} width={(filteredSlots.length || 1) * 100}>
                                {dateTimeService.toLocaleString(
                                    day,
                                    LocaleFormats.DateOnly.MonthShort
                                )}
                            </HeaderCell>
                        );
                    })}
                </Flex>

                <Flex>
                    <HeaderCell width={200}>{translate('cr_neaux_33401')}</HeaderCell>

                    {lodashRange(0, numberOfDays).map((i) => {
                        const day = start.plus({ days: i });
                        const filteredSlots = filterSameDaySlots(day, props.slots);

                        if (filteredSlots.length === 0) {
                            return <UnavailableCell key={`${i}`} />;
                        } else {
                            return filteredSlots.map((slot) => (
                                    <HeaderCell key={`${i}-${slot.id}`} width={100}>
                                        {slot.range.toFormat('HH:mm', { separator: '-' })}
                                    </HeaderCell>
                                ));
                        }
                    })}
                </Flex>

                {props.volunteersRegistrations.map((volunteerRegistration) => {
                    const userInfo = volunteerRegistration.userInfo;

                    return (
                        <Flex key={volunteerRegistration.id}>
                            <NameCell>
                                <Avatar
                                    size={24}
                                    email={userInfo.email}
                                    name={userInfo.name}
                                    image={userInfo.picture?.url}
                                />

                                <Box css={{ flex: '1', ellipsis: '' }} title={userInfo.name}>
                                    {userInfo.name}
                                </Box>
                            </NameCell>

                            {lodashRange(0, numberOfDays).map((i) => {
                                const day = start.plus({ days: i });
                                const filteredSlots = filterSameDaySlots(day, props.slots);

                                if (filteredSlots.length === 0) {
                                    return <UnavailableCell key={`${i}`} />;
                                } else {
                                    return filteredSlots.map((slot) => {
                                        const key = `${i}-${slot.id}`;
                                        const isAssignedOnThisSlot =
                                            volunteerRegistration.positionsSlotsUsersInfos.find(
                                                ({ positionSlotId }) => positionSlotId === slot.id
                                            ) !== undefined;
                                        const isAssignedOnOverlappingSlot =
                                            volunteerRegistration.positionsSlotsUsersInfos.some(
                                                ({ positionSlot }) =>
                                                    positionSlot.range.overlaps(slot.range)
                                            );
                                        const isAvailable = volunteerRegistration.slots.some(
                                            (vrSlot) => vrSlot.range.engulfs(slot.range)
                                        );

                                        return isAssignedOnThisSlot ? (
                                            <AssignedCell key={key}>
                                                <Box>
                                                    <XMark />
                                                </Box>
                                            </AssignedCell>
                                        ) : isAvailable && !isAssignedOnOverlappingSlot ? (
                                            <AvailableCell key={key}>
                                                <Box>{translate('disponible_69523')}</Box>
                                            </AvailableCell>
                                        ) : (
                                            <UnavailableCell key={key} />
                                        );
                                    });
                                }
                            })}
                        </Flex>
                    );
                })}
            </Flex>
        </Flex>
    );
};
