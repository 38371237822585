import { orderBy, sortBy, uniq, uniqBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const CROIX_URL = `https://assets.recrewteer.com/badges/terresDuSon/croix.png`;

interface ICarreProps {
    left: number;
    top: number;
}

const Carre = (props: ICarreProps) => (
        <Box
            height={16}
            width={16}
            css={{
                border: '1px solid white',
                left: `${props.left}px`,
                position: 'absolute',
                top: `${props.top}px`
            }}
        />
    );

const Croix = (props: ICarreProps) => (
        <Box
            height={18}
            width={20}
            css={{
                background: `url(${CROIX_URL}) no-repeat`,
                backgroundSize: 'cover',
                left: `${props.left}px`,
                position: 'absolute',
                top: `${props.top}px`
            }}
        />
    );

const getFirstPageBackground = (population: string) => {
    switch (population) {
        case 'Bénévole':
            return 'https://assets.recrewteer.com/badges/terresDuSon/benevole/page1_v3.jpg';
        case 'Prestataire avec repas':
            return `https://assets.recrewteer.com/badges/terresDuSon/prestataire/page1_v2.jpg`;
        case 'Prestataire':
            return `https://assets.recrewteer.com/badges/terresDuSon/prestataireSansRepas/page1.jpg`;
        case 'Structure médico-sociale':
            return `https://assets.recrewteer.com/badges/terresDuSon/medicaux/page1_v2.jpg`;
        case 'Intermittent.e.s':
            return `https://assets.recrewteer.com/badges/terresDuSon/intermittent/page1_v2.jpg`;
        default:
            return '';
    }
};

const getSecondPageBackground = (population: string) => {
    switch (population) {
        case 'Bénévole':
            return 'https://assets.recrewteer.com/badges/terresDuSon/benevole/page2.jpg';
        case 'Prestataire avec repas':
            return `s3://assets.recrewteer.com/badges/terresDuSon/prestataire/page2.jpg`;
        case 'Prestataire':
            return ``;
        case 'Structure médico-sociale':
            return `https://assets.recrewteer.com/badges/terresDuSon/medicaux/page2.jpg`;
        case 'Intermittent.e.s':
            return `https://assets.recrewteer.com/badges/terresDuSon/intermittent/page2.jpg`;
        default:
            return '';
    }
};

const getTitle = (population: string) => {
    switch (population) {
        case 'Bénévole':
            return 'Bénévole';
        case 'Prestataire avec repas':
            return 'Prestataire';
        case 'Prestataire':
            return 'Pass technique';
        case 'Structure médico-sociale':
            return 'Structures Médico-Sociales';
        case 'Intermittent.e.s':
            return 'Intermittent';
        default:
            return '';
    }
};

const getSubTitle = (population: string, categories: string, delegation: string, fields: any) => {
    switch (population) {
        case 'Bénévole':
            return categories;
        case 'Prestataire avec repas':
            return fields.cf25446;
        case 'Prestataire':
            return fields.cf25476;
        case 'Structure médico-sociale':
            return delegation;
        case 'Intermittent.e.s':
            return fields.cf25446;
        default:
            return '';
    }
};

const hasPage2 = (population: string) => {
    switch (population) {
        case 'Bénévole':
            return true;
        case 'Prestataire avec repas':
            return true;
        case 'Prestataire':
            return false;
        case 'Structure médico-sociale':
            return true;
        case 'Intermittent.e.s':
            return true;
        default:
            return false;
    }
};

const hasPage3 = (population: string) => {
    switch (population) {
        case 'Bénévole':
            return true;
        case 'Prestataire avec repas':
        case 'Prestataire':
        case 'Structure médico-sociale':
        case 'Intermittent.e.s':
            return false;
        default:
            return false;
    }
};

const showTShirts = (population: string) => {
    switch (population) {
        case 'Bénévole':
            return true;
        case 'Prestataire avec repas':
            return false;
        case 'Prestataire':
            return false;
        case 'Structure médico-sociale':
            return true;
        case 'Intermittent.e.s':
            return false;
        default:
            return false;
    }
};

interface IBadgeTerresDuSonProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeTerresDuSon = (props: IBadgeTerresDuSonProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const population = ui.terresDuSonPopulation;
    const categories = uniq(
        sortBy(
            vr.positionsSlotsUsersInfos.map((psui) => psui.positionCategory.name)
        )
    ).join(', ');
    const days = orderBy(
        uniqBy(
            vr.positionsSlotsUsersInfos.map((psui) => psui.positionSlot.range.start!),
            (d) => d.startOf('day').toMillis()
        ),
        (d) => d.startOf('day').toMillis()
    )
        .map((d) => d.toFormat('cccc dd MMMM', { locale: 'fr' }))
        .join(', ');
    const has8 = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionSlot.range.start!.day === 8
    );
    const has9 = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionSlot.range.start!.day === 9
    );
    const has10 = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionSlot.range.start!.day === 10
    );
    const has11 = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionSlot.range.start!.day === 11
    );
    const has12 = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionSlot.range.start!.day === 12
    );
    const has13 = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionSlot.range.start!.day === 13
    );
    const has14 = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionSlot.range.start!.day === 14
    );
    const has15 = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionSlot.range.start!.day === 15
    );
    const has16 = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionSlot.range.start!.day === 16
    );
    const has17 = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionSlot.range.start!.day === 17
    );
    const has18 = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionSlot.range.start!.day === 18
    );
    const has19 = vr.positionsSlotsUsersInfos.some(
        (psui) => psui.positionSlot.range.start!.day === 19
    );
    const hasCatering8Midi = ui.fields.cf27263 === true;
    const hasCatering8Soir = ui.fields.cf27264 === true;
    const hasCatering9Midi = ui.fields.cf27265 === true;
    const hasCatering9Soir = ui.fields.cf27266 === true;
    const hasCatering10Midi = ui.fields.cf27267 === true;
    const hasCatering10Soir = ui.fields.cf27268 === true;
    const hasCatering11Midi = ui.fields.cf27269 === true;
    const hasCatering11Soir = ui.fields.cf27270 === true;
    const hasCatering12Midi = ui.fields.cf27271 === true;
    const hasCatering12Soir = ui.fields.cf27272 === true;
    const hasCatering13Midi = ui.fields.cf27273 === true;
    const hasCatering13Soir = ui.fields.cf27274 === true;
    const hasCatering14Midi = ui.fields.cf27275 === true;
    const hasCatering14Soir = ui.fields.cf27276 === true;
    const hasCatering15Midi = ui.fields.cf27277 === true;
    const hasCatering15Soir = ui.fields.cf27278 === true;
    const hasCatering16Midi = ui.fields.cf27279 === true;
    const hasCatering16Soir = ui.fields.cf27280 === true;
    const hasCatering17Midi = ui.fields.cf27281 === true;
    const hasCatering17Soir = ui.fields.cf27282 === true;
    const hasCatering18Midi = ui.fields.cf27283 === true;
    const hasCatering18Soir = ui.fields.cf27284 === true;
    const hasCatering19Midi = ui.fields.cf27285 === true;
    const hasCatering19Soir = ui.fields.cf27286 === true;

    return (
        <>
            <Flex
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
                css={{
                    background: `url(${getFirstPageBackground(population)}) no-repeat`,
                    backgroundSize: 'cover',
                    fontFamily: '$bricolage',
                    position: 'relative'
                }}
            >
                <Box
                    width={1}
                    css={{
                        color: '#32e775',
                        fontSize: '30px',
                        fontWeight: '700',
                        position: 'absolute',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        top: '318px'
                    }}
                >
                    {getTitle(population)}
                </Box>

                <Box
                    css={{
                        color: '#f966a5',
                        fontSize: '28px',
                        fontWeight: '700',
                        left: '63px',
                        position: 'absolute',
                        top: population === 'Prestataire' ? '375px' : '415px'
                    }}
                >
                    {ui.name}
                </Box>

                <Box
                    css={{
                        color: '#010f5c',
                        fontSize: '16px',
                        left: '63px',
                        position: 'absolute',
                        top: population === 'Prestataire' ? '409px' : '451px'
                    }}
                >
                    {getSubTitle(population, categories, vr.delegation?.name ?? '', ui.fields)}
                </Box>

                <Flex
                    direction="column"
                    align="center"
                    css={{
                        position: 'absolute',
                        right: showTShirts(population) ? '74px' : '108px',
                        top: population === 'Prestataire' ? '353px' : '363px'
                    }}
                >
                    {showTShirts(population) && (
                        <Box css={{ color: '#010f5c', fontSize: '18px', fontWeight: '700' }}>
                            {ui.terresDuSonNbTshirts || '0'} tee-shirt(s)
                        </Box>
                    )}

                    <Spacer height="2" />

                    <Box height={80} width={80}>
                        {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                            <img
                                src={`https://api.${
                                    CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                                }/barcode/qr?text=${encodeURIComponent(
                                    vr.weezeventParticipantInfo!.idBarcode
                                )}&color=000000&height=80&width=80`}
                                height="100%"
                                width="100%"
                            />
                        )}
                    </Box>

                    <Spacer height="1" />

                    <Box css={{ color: '#010f5c', fontSize: '12px' }}>
                        {vr.weezeventParticipantInfo?.idBarcode ?? ''}
                    </Box>
                </Flex>

                {population !== 'Prestataire' && (
                    <Box
                        css={{
                            color: '#010f5c',
                            fontSize: '16px',
                            left: '63px',
                            position: 'absolute',
                            top: '872px'
                        }}
                    >
                        {(ui.terresDuSonCamping || []).join(', ')}
                    </Box>
                )}

                {population === 'Prestataire' ? (
                    <Box
                        css={{
                            color: '#010f5c',
                            fontSize: '16px',
                            left: '64px',
                            position: 'absolute',
                            top: '559px'
                        }}
                    >
                        {days}
                    </Box>
                ) : (
                    <>
                        {has8 && (
                            <>
                                <Carre left={144} top={665} />
                                <Carre left={144} top={694} />
                            </>
                        )}

                        {hasCatering8Midi && <Croix left={143} top={664} />}
                        {hasCatering8Soir && <Croix left={142} top={693} />}

                        {has9 && (
                            <>
                                <Carre left={196} top={665} />
                                <Carre left={196} top={694} />
                            </>
                        )}

                        {hasCatering9Midi && <Croix left={195} top={664} />}
                        {hasCatering9Soir && <Croix left={195} top={693} />}

                        {has10 && (
                            <>
                                <Carre left={247} top={665} />
                                <Carre left={247} top={694} />
                            </>
                        )}

                        {hasCatering10Midi && <Croix left={246} top={664} />}
                        {hasCatering10Soir && <Croix left={246} top={693} />}

                        {has11 && (
                            <>
                                <Carre left={299} top={665} />
                                <Carre left={299} top={694} />
                            </>
                        )}

                        {hasCatering11Midi && <Croix left={298} top={664} />}
                        {hasCatering11Soir && <Croix left={298} top={693} />}

                        {has12 && (
                            <>
                                <Carre left={350} top={665} />
                                <Carre left={350} top={694} />
                            </>
                        )}

                        {hasCatering12Midi && <Croix left={349} top={664} />}
                        {hasCatering12Soir && <Croix left={349} top={693} />}

                        {has13 && (
                            <>
                                <Carre left={401} top={665} />
                                <Carre left={401} top={694} />
                            </>
                        )}

                        {hasCatering13Midi && <Croix left={400} top={664} />}
                        {hasCatering13Soir && <Croix left={400} top={693} />}

                        {has14 && (
                            <>
                                <Carre left={453} top={665} />
                                <Carre left={453} top={694} />
                            </>
                        )}

                        {hasCatering14Midi && <Croix left={452} top={664} />}
                        {hasCatering14Soir && <Croix left={452} top={693} />}

                        {has15 && (
                            <>
                                <Carre left={505} top={665} />
                                <Carre left={505} top={694} />
                            </>
                        )}

                        {hasCatering15Midi && <Croix left={504} top={664} />}
                        {hasCatering15Soir && <Croix left={504} top={693} />}

                        {has16 && (
                            <>
                                <Carre left={558} top={665} />
                                <Carre left={558} top={694} />
                            </>
                        )}

                        {hasCatering16Midi && <Croix left={557} top={664} />}
                        {hasCatering16Soir && <Croix left={557} top={693} />}

                        {has17 && (
                            <>
                                <Carre left={609} top={665} />
                                <Carre left={609} top={694} />
                            </>
                        )}

                        {hasCatering17Midi && <Croix left={608} top={664} />}
                        {hasCatering17Soir && <Croix left={608} top={693} />}

                        {has18 && (
                            <>
                                <Carre left={660} top={665} />
                                <Carre left={660} top={694} />
                            </>
                        )}

                        {hasCatering18Midi && <Croix left={659} top={664} />}
                        {hasCatering18Soir && <Croix left={659} top={693} />}

                        {has19 && (
                            <>
                                <Carre left={711} top={665} />
                                <Carre left={711} top={694} />
                            </>
                        )}

                        {hasCatering19Midi && <Croix left={710} top={664} />}
                        {hasCatering19Soir && <Croix left={710} top={693} />}
                    </>
                )}
            </Flex>

            {hasPage2(population) && (
                <Flex
                    height={A4_SIZES['96dpi'].height}
                    width={A4_SIZES['96dpi'].width}
                    css={{
                        background: `url(${getSecondPageBackground(population)}) no-repeat`,
                        backgroundSize: 'cover',
                        pageBreakBefore: 'always'
                    }}
                />
            )}

            {hasPage3(population) && (
                <Flex
                    height={A4_SIZES['96dpi'].height}
                    width={A4_SIZES['96dpi'].width}
                    css={{
                        background: `url(https://assets.recrewteer.com/badges/terresDuSon/benevole/page3.jpg) no-repeat`,
                        backgroundSize: 'cover',
                        pageBreakBefore: 'always'
                    }}
                />
            )}
        </>
    );
};
