import { Button } from 'common-front/src/designSystem/components/button';
import { Images } from 'common-front/src/util/assets';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { EventId, OrganizationId } from 'common/src/generated/types';
import { heaventEnvDisplayName } from 'common/src/heaventEnv';
import { useHistory, useTranslate } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { useWeezeventConnectionInfosQuery } from '../../generated/graphqlHooks';
import { WeezeventConnection } from './weezevent/connection/weezeventConnection';

interface IApiIntegrationProps {
    eventId: EventId;
    organizationId: OrganizationId;
}

export const ApiIntegration = (props: IApiIntegrationProps) => {
    const translate = useTranslate();
    const history = useHistory();
    const { data, loader } = useWeezeventConnectionInfosQuery({
        eventId: props.eventId
    });
    const [weezeventOpen, setWeezeventOpen] = React.useState(false);

    return (
        <Flex direction="column">
            <Box color="gray900" fontSize="textLg" fontWeight="medium">
                {translate('int_grations_av_95283')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500">{translate('connecter_1_09087', heaventEnvDisplayName())}</Box>

            <Spacer height="6" />

            {loader || (
                <Flex
                    align="center"
                    gap="4"
                    css={{
                        border: '1px solid $gray200',
                        borderRadius: '$2',
                        boxShadow: '$xs',
                        cursor: 'pointer',
                        padding: '$4 $6',
                        '&:hover': {
                            boxShadow: '$md'
                        }
                    }}
                    onClick={() => {
                        if (data.event?.weezeventConnectionInfos) {
                            history.push(
                                HeaventPaths.API_WEEZEVENT(props.organizationId, props.eventId)
                            );
                        } else {
                            setWeezeventOpen(true);
                        }
                    }}
                >
                    <Box height={35} width={35}>
                        <img
                            src={Images.LogoWeezeventSquare}
                            alt="Logo Weezevent"
                            height="100%"
                            width="100%"
                        />
                    </Box>

                    <Flex direction="column" gap="1" css={{ flex: '1' }}>
                        <Box color="gray800" fontWeight="medium">
                            Weezevent
                        </Box>
                        <Box color="gray500">
                            {translate('synchroniser_au_93947', heaventEnvDisplayName())}
                        </Box>
                    </Flex>

                    {!data.event.weezeventConnectionInfos && (
                        <Button
                            color="white"
                            onClick={() => {
                                setWeezeventOpen(true);
                            }}
                        >
                            {translate('connecter_36439')}
                        </Button>
                    )}
                </Flex>
            )}

            {weezeventOpen && (
                <WeezeventConnection
                    eventId={props.eventId}
                    onClose={() => {
                        setWeezeventOpen(false);
                    }}
                    onConnect={() => {
                        setWeezeventOpen(false);

                        history.push(
                            HeaventPaths.API_WEEZEVENT(props.organizationId, props.eventId)
                        );
                    }}
                />
            )}
        </Flex>
    );
};
