import * as React from 'react';
import { Box } from '../../../../designSystem/components/box';
import { Flex } from '../../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../../generated/types';

interface IUltraMarinPresseBackProps {
    event: DocumentUserCustomBadgeFragment;
}

export const UltraMarinPresseBack = (props: IUltraMarinPresseBackProps) => {
    const vr = props.event.volunteerRegistration;
    const accreditations = vr.accreditationsUsersInfos
        .map(({ accreditation }) => accreditation.acronym)
        .join(', ');

    return (
        <Flex
            direction="column"
            height={396}
            width={280}
            css={{
                background: `url(https://assets.recrewteer.com/badges/ultraMarin/ultraMarinPresseVerso.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$brito',
                overflow: 'hidden',
                paddingTop: '52px'
            }}
        >
            <Box
                css={{
                    color: '#30388a',
                    fontSize: '14px',
                    fontWeight: '700',
                    paddingLeft: '101px',
                    textTransform: 'uppercase'
                }}
            >
                {vr.userInfo.fields.lastName}
            </Box>

            <Box
                css={{
                    color: '#30388a',
                    fontSize: '14px',
                    fontWeight: '700',
                    paddingLeft: '101px',
                    textTransform: 'capitalize'
                }}
            >
                {vr.userInfo.fields.firstName}
            </Box>

            <Box
                css={{
                    color: 'white',
                    fontSize: '8px',
                    fontWeight: '400',
                    paddingLeft: '101px',
                    paddingTop: '237px'
                }}
            >
                ACCRÉDITATIONS
            </Box>

            <Box
                css={{
                    color: 'white',
                    fontSize: '10px',
                    fontWeight: '300',
                    paddingLeft: '101px'
                }}
            >
                {accreditations}
            </Box>
        </Flex>
    );
};
