import { Flex } from 'common/src/designSystem/components/flex';
import { useParams } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import { getGoogleCenter } from 'common/src/vo/event';
import { Predicates } from 'common/src/vo/segment';
import * as React from 'react';
import { usePositionsQuery } from '../../../generated/graphqlHooks';
import { PositionsMap } from './positionsMap';

interface IPositionsMapProps {
    name: string;
    predicates: Predicates;
}

export const PositionsMapContainer = (props: IPositionsMapProps) => {
    const { eventId } = useParams();
    const { data, loader, reload } = usePositionsQuery({
        eventId,
        loadTags: false,
        loadTraces: true,
        name: isNonEmptyString(props.name) ? props.name : null,
        predicates: props.predicates,
        hasCoordinates: true
    });

    if (loader) {
        return <Flex css={{ flex: '1' }}>{loader}</Flex>;
    } else {
        return (
            <PositionsMap
                center={getGoogleCenter(data.event)}
                layer={data.event.layer}
                positions={data.event.positions.nodes}
                traces={data.event.traces}
                reload={reload}
            />
        );
    }
};
