import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { debounce } from 'lodash-es';
import * as React from 'react';
import { useUniqueIds } from '../../hooks/useUniqueIds';
import { BaseInputProps } from './input/baseInputProps';
import { Description } from './input/description';
import { Hint } from './input/hint';
import { Label } from './input/label';
import { StyledInputContainer } from './input/styledInputContainer';

interface IColorInputProps extends BaseInputProps {
    value: string;
    initialValue?: string;

    onChange(value: string): void;
}

export const ColorInput = (props: IColorInputProps) => {
    const translate = useTranslate();
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const onChangedDebounced = debounce((value: string) => {
        props.onChange(value);
    }, 500);
    const { inputId, descId, errorId } = useUniqueIds();

    return (
        <Flex css={props.css} direction="column" width={1}>
            <Label htmlFor={inputId}>{props.label}</Label>

            <Description id={descId}>{props.description}</Description>

            {(props.label || props.description) && <Spacer height="1" />}

            <StyledInputContainer
                icon={props.icon}
                state={props.state}
                cursor="default"
                onClick={() => {
                    inputRef.current?.click();
                }}
            >
                <Flex
                    align="center"
                    height={1}
                    css={{
                        flex: '1'
                    }}
                >
                    <Box
                        css={{
                            background: props.value,
                            borderRadius: '2px',
                            height: '24px',
                            width: '24px',
                            '& input': {
                                background: 'transparent',
                                border: 'none',
                                borderRadius: '2px',
                                cursor: 'pointer',
                                height: '24px',
                                width: '24px',
                                '&::-webkit-color-swatch-wrapper': {
                                    padding: 0
                                },
                                '&::-webkit-color-swatch': {
                                    borderRadius: '2px',
                                    border: 'none'
                                },
                                '&::-moz-color-swatch': {
                                    border: 'none'
                                }
                            }
                        }}
                    >
                        <input
                            ref={inputRef}
                            id={inputId}
                            aria-describedby={props.description ? descId : undefined}
                            type="color"
                            value={props.value}
                            onChange={(e) => {
                                onChangedDebounced(e.target.value);
                            }}
                        />
                    </Box>

                    <Spacer width="3" />

                    <Box
                        color="gray800"
                        css={{
                            flex: '1',
                            '& input[type=text]': {
                                background: 'transparent',
                                border: 'none',
                                color: '$gray800',
                                height: '100%',
                                outline: 'none',
                                width: '100%'
                            }
                        }}
                    >
                        <input
                            type="text"
                            id={inputId}
                            aria-describedby={props.description ? descId : undefined}
                            aria-invalid={props.state === 'error'}
                            aria-errormessage={props.state === 'error' ? errorId : undefined}
                            disabled={props.state === 'disabled'}
                            value={props.value}
                            onChange={(e) => {
                                props.onChange(e.target.value);
                            }}
                        />
                    </Box>
                </Flex>

                {props.initialValue && (
                    <Box
                        color="primary700"
                        fontWeight="medium"
                        css={{ cursor: 'pointer', userSelect: 'none' }}
                        onClick={(e) => {
                            e.nativeEvent.stopImmediatePropagation();
                            e.stopPropagation();

                            props.onChange(props.initialValue!);
                        }}
                    >
                        {translate('remettre_sa_v_90478')}
                    </Box>
                )}
            </StyledInputContainer>

            <Hint id={errorId} state={props.state}>
                {props.hint}
            </Hint>
        </Flex>
    );
};
