import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    CustomFieldSlug,
    CustomFieldWithConditionFragment,
    DelegationUserProfileFragment,
    EventId,
    EventUserProfilePrivateFieldsQuery,
    OrganizationId,
    UserInfoOverlayFragment
} from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import { compact } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import {
    useEventProfilePrivateFieldsUpdateMutation,
    useEventUserProfilePrivateFieldsQuery,
    useOrganizationProfilePrivateFieldsUpdateMutation,
    useOrganizationUserProfilePrivateFieldsQuery
} from '../../../generated/graphqlHooks';
import { UserProfilePrivateFieldsInner } from './userProfilePrivateFieldsInner';

interface IUserProfilePrivateFieldsProps {
    customFields: CustomFieldWithConditionFragment[];
    delegations: DelegationUserProfileFragment[];
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    profilePrivateFields: CustomFieldSlug[];
    registrationDate: DateTime;
    teams: EventUserProfilePrivateFieldsQuery['organization']['userInfo']['formsUsersInfos'];
    userInfo: UserInfoOverlayFragment;

    profilePrivateFieldsUpdate(profilePrivateFields: CustomFieldSlug[]): Promise<any>;
    reload(): void;
}

const UserProfilePrivateFields = (props: IUserProfilePrivateFieldsProps) => (
        <Flex
            direction="column"
            width={1}
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                padding: '$6'
            }}
        >
            <UserProfilePrivateFieldsInner
                customFields={props.customFields}
                delegations={props.delegations}
                eventId={props.eventId}
                organizationId={props.organizationId}
                profilePrivateFields={props.profilePrivateFields}
                registrationDate={props.registrationDate}
                teams={props.teams}
                userInfo={props.userInfo}
                profilePrivateFieldsUpdate={props.profilePrivateFieldsUpdate}
                reload={props.reload}
            />
        </Flex>
    );

export const EventUserProfilePrivateFields = () => {
    const {
        params: { organizationId, eventId, userInfoId }
    } = useHeavent();
    const { mutate } = useEventProfilePrivateFieldsUpdateMutation();
    const { data, loader, reload } = useEventUserProfilePrivateFieldsQuery({
        organizationId,
        eventId,
        userInfoId
    });
    const delegations = React.useMemo(() => compact([data.event?.volunteerRegistration.delegation]), [data.event]);
    const teams = React.useMemo(() => (data.organization?.userInfo.formsUsersInfos ?? []).filter(
            (fui) => isNonEmptyString(fui.teamCode) && isNonEmptyArray(fui.teamMembers)
        ), [data.organization]);

    return (
        loader || (
            <UserProfilePrivateFields
                customFields={data.organization.customFields.nodes}
                delegations={delegations}
                eventId={eventId}
                organizationId={organizationId}
                profilePrivateFields={data.event.profilePrivateFields}
                registrationDate={data.event.volunteerRegistration.insertedAt}
                teams={teams}
                userInfo={data.organization.userInfo}
                profilePrivateFieldsUpdate={(profilePrivateFields) => mutate({
                        eventId,
                        profilePrivateFields
                    })}
                reload={reload}
            />
        )
    );
};

export const OrganizationUserProfilePrivateFields = () => {
    const {
        params: { organizationId, userInfoId }
    } = useHeavent();
    const { mutate } = useOrganizationProfilePrivateFieldsUpdateMutation();
    const { data, loader, reload } = useOrganizationUserProfilePrivateFieldsQuery({
        organizationId,
        userInfoId
    });

    return (
        loader || (
            <UserProfilePrivateFields
                customFields={data.organization.customFields.nodes}
                delegations={data.organization.userInfo.delegations}
                eventId={null}
                organizationId={organizationId}
                profilePrivateFields={data.organization.profilePrivateFields}
                registrationDate={data.organization.userInfo.insertedAt}
                teams={[]}
                userInfo={data.organization.userInfo}
                profilePrivateFieldsUpdate={(profilePrivateFields) => mutate({
                        organizationId,
                        profilePrivateFields
                    })}
                reload={reload}
            />
        )
    );
};
