import { groupBy, sortBy } from 'lodash-es';
import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { DateTimeService } from '../../../services/dateTimeService';
import { useService } from '../../../util/dependencies/dependencies';
import { LocaleFormats } from '../../../util/luxon';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

const getFrontBackground = (status: string[]) => {
    if (
        status.includes('Régisseur') ||
        status.includes('Team Delta') ||
        status.includes('Warrior Chauffeur')
    ) {
        return 'https://assets.recrewteer.com/badges/delta/2024/delta_tech_recto.jpg';
    } else {
        return 'https://assets.recrewteer.com/badges/delta/2024/delta_qg_recto.jpg';
    }
};

const getBackBackground = (status: string[]) => {
    if (
        status.includes('Régisseur') ||
        status.includes('Team Delta') ||
        status.includes('Warrior Chauffeur')
    ) {
        return 'https://assets.recrewteer.com/badges/delta/2024/delta_tech_verso.jpg';
    } else {
        return 'https://assets.recrewteer.com/badges/delta/2024/delta_qr_verso.jpg';
    }
};

interface IBadgeDeltaProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeDelta = (props: IBadgeDeltaProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const dateTimeService = useService(DateTimeService);
    const assignmentsByDay = React.useMemo(() => {
        const psuis = props.event.volunteerRegistration.positionsSlotsUsersInfos;

        return sortBy(
            Object.entries(
                groupBy(psuis, (psui) => psui.positionSlot.range.start!.startOf('day').toMillis())
            ),
            ([start]) => start
        ).map(([_, assignments]) => [
                dateTimeService.toLocaleString(
                    assignments[0].positionSlot.range.start!,
                    LocaleFormats.DateOnly.WeekdayLongMonthLong
                ),
                assignments
            ]);
    }, [props.event]);

    return (
        <>
            <Flex
                direction="column"
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
                css={{
                    background: `url(${getFrontBackground(ui.deltaStatut)}) no-repeat`,
                    backgroundSize: 'contain',
                    color: 'black',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
            >
                <Box
                    height={100}
                    width={100}
                    css={{
                        borderRadius: '100px',
                        left: '60px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: '286px'
                    }}
                >
                    {isNonEmptyString(ui.picture?.url) && (
                        <img src={ui.picture!.url} height="100%" width="100%" />
                    )}
                </Box>

                <Box
                    css={{
                        fontSize: '24px',
                        fontWeight: '700',
                        left: '177px',
                        position: 'absolute',
                        top: '289px'
                    }}
                >
                    {ui.firstName}
                </Box>

                <Box
                    css={{
                        fontSize: '24px',
                        fontWeight: '700',
                        left: '177px',
                        position: 'absolute',
                        top: '322px'
                    }}
                >
                    {ui.lastName}
                </Box>

                <Box
                    css={{
                        fontSize: '18px',
                        left: '177px',
                        position: 'absolute',
                        top: '359px'
                    }}
                >
                    {ui.deltaStatut}
                </Box>

                <Flex
                    direction="column"
                    align="center"
                    gap="2"
                    css={{
                        position: 'absolute',
                        right: '71px',
                        top: '282px'
                    }}
                >
                    <Box height={90} width={90}>
                        {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                            <img
                                src={`https://api.${
                                    CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                                }/barcode/qr?text=${encodeURIComponent(
                                    vr.weezeventParticipantInfo!.idBarcode
                                )}&color=000000&height=90&width=90`}
                                height="100%"
                                width="100%"
                            />
                        )}
                    </Box>

                    <Box css={{ fontSize: '10px' }}>{vr.weezeventParticipantInfo?.idBarcode}</Box>
                </Flex>
            </Flex>

            <Flex
                direction="column"
                height={A4_SIZES['96dpi'].height}
                width={A4_SIZES['96dpi'].width}
                css={{
                    background: `url(${getBackBackground(ui.deltaStatut)}) no-repeat`,
                    backgroundSize: 'contain',
                    color: 'black',
                    pageBreakBefore: 'always',
                    position: 'relative'
                }}
            >
                <Flex
                    direction="column"
                    gap="6"
                    css={{
                        left: '76px',
                        position: 'absolute',
                        top: '328px'
                    }}
                >
                    {assignmentsByDay.map(([day, assignments]: any, dayIndex) => (
                            <Flex key={dayIndex} direction="column" gap="1">
                                <Box
                                    css={{
                                        fontSize: '18px',
                                        fontWeight: '700',
                                        textTransform: 'uppercase'
                                    }}
                                >
                                    {day}
                                </Box>

                                <Flex direction="column">
                                    {assignments.map((assignment: any, assignmentIndex: number) => (
                                            <Box
                                                key={assignmentIndex}
                                                css={{
                                                    fontSize: '16px'
                                                }}
                                            >
                                                {assignment.position.name} /{' '}
                                                {assignment.positionSlot.range.toFormat('HH:mm', {
                                                    separator: '-'
                                                })}
                                            </Box>
                                        ))}
                                </Flex>
                            </Flex>
                        ))}
                </Flex>
            </Flex>
        </>
    );
};
