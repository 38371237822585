import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { AccreditInfosQuery, AccreditSlotsQuery } from 'common/src/generated/types';
import * as React from 'react';
import { AccreditAccreditations } from './accreditAccreditations';

interface IAccreditCategoryProps {
    category: AccreditSlotsQuery['event']['accreditationsSlots']['nodes'][0]['accreditationCategory'];
    delegation: AccreditInfosQuery['event']['volunteerRegistration']['delegation'];
    slots: AccreditSlotsQuery['event']['accreditationsSlots']['nodes'];
}

export const AccreditCategory = ({ category, delegation, slots }: IAccreditCategoryProps) => {
    const [isOpen, setIsOpen] = React.useState(true);

    return (
        <Flex
            direction="column"
            css={{
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                overflow: 'hidden'
            }}
        >
            <Flex
                align="center"
                gap="1"
                css={{ background: 'white', cursor: 'pointer', padding: '$4', userSelect: 'none' }}
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <Flex
                    align="center"
                    justify="center"
                    height={32}
                    width={32}
                    css={{ fontSize: '$textXs' }}
                >
                    <I icon={isOpen ? 'chevron-down' : 'chevron-right'} />
                </Flex>

                <Box font="gray800 textMd medium">{category.name}</Box>
            </Flex>

            {isOpen && <AccreditAccreditations slots={slots} delegation={delegation} />}
        </Flex>
    );
};
