import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { SegmentsGrid } from 'common-front/src/segments/segmentsGrid';
import { EventsSegmentsQuery, SegmentId, SegmentType } from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import { DEFAULT_PAGINATION_LIMIT } from 'common/src/vo/pagination';
import { EventsSegmentsService } from 'common/src/vo/segments/eventsSegmentsService';
import * as React from 'react';
import { useOrganizationContext } from '../../organization/organizationContext';
import { EventsContent } from './eventsContent';

interface IEventsProps {
    organization: EventsSegmentsQuery['organization'];

    reload(): void;
}

export const Events = (props: IEventsProps) => {
    const {
        history,
        translate,
        params: { organizationId, segmentId }
    } = useHeavent();
    const { localOrganization, updateLocalOrganization } = useOrganizationContext();
    const segmentService = useService(EventsSegmentsService);
    const filters = React.useMemo(() => segmentService.getEventsFilters(organizationId), [organizationId]);

    return (
        <SegmentsGrid
            filters={filters}
            filtersButtonText={translate('filtrer_les_v_48869')}
            filtersSubtitle={translate('appliquer_des_f_84816')}
            initialLimit={localOrganization?.eventsLimit ?? DEFAULT_PAGINATION_LIMIT}
            initialSegmentsOpen={localOrganization?.areEventsSegmentsOpen ?? true}
            isAdmin={true}
            organizationId={organizationId}
            possibleColumns={[]}
            segmentId={segmentId}
            segmentType={SegmentType.Events}
            segmentsFolders={props.organization.segmentsFolders}
            getSegmentPath={(id: SegmentId) => HeaventPaths.EVENTS_SEGMENT(organizationId, id)}
            onSegmentClick={(selectedSegmentId) => {
                updateLocalOrganization({ eventsSegmentId: selectedSegmentId });
            }}
            onSegmentDelete={async () => {
                await updateLocalOrganization({ eventsSegmentId: null });

                history.push(HeaventPaths.EVENTS(organizationId));
            }}
            onSegmentsToggle={async (areSegmentsOpen) => {
                await updateLocalOrganization({ areEventsSegmentsOpen: areSegmentsOpen });
            }}
            reload={props.reload}
            setLimit={(eventsLimit) => {
                updateLocalOrganization({ eventsLimit });
            }}
        >
            <EventsContent />
        </SegmentsGrid>
    );
};
