import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { Spacer } from '../../../designSystem/components/spacer';
import {
    AsoassignmentDocumentOptions,
    DocumentUserCustomBadgeFragment
} from '../../../generated/types';
import { getColorByBackgroundColor } from '../../../util/color';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IAsoAssignmentPage2Props {
    options: AsoassignmentDocumentOptions;
    positionSlotUserInfo: DocumentUserCustomBadgeFragment['volunteerRegistration']['positionsSlotsUsersInfos'][0];
}

export const AsoAssignmentPage2 = (props: IAsoAssignmentPage2Props) => {
    const position = props.positionSlotUserInfo.position;
    const textColor = getColorByBackgroundColor(props.options.color);

    return (
        <Flex
            direction="column"
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: 'white',
                fontFamily: '$default',
                pageBreakBefore: 'always'
            }}
        >
            <Flex
                align="center"
                justify="between"
                height={112}
                width={1}
                css={{
                    px: '60px',
                    py: '$6'
                }}
            >
                <Flex
                    css={{
                        height: '64px',
                        '& > img': {
                            height: '100%',
                            width: 'auto'
                        }
                    }}
                >
                    {isNonEmptyString(props.options.logo?.content) && (
                        <img src={props.options.logo.content} />
                    )}
                </Flex>

                <Flex
                    align="center"
                    height={44}
                    css={{
                        background: props.options.color,
                        borderRadius: '$1',
                        color: textColor,
                        fontSize: '28px',
                        fontWeight: '$bold',
                        px: '$6'
                    }}
                >
                    Fiche d'affectation
                </Flex>
            </Flex>

            <Flex
                width={1}
                css={{
                    background: isNonEmptyString(props.options.background?.content)
                        ? `url(${props.options.background.content}) no-repeat`
                        : '$gray50',
                    backgroundSize: 'cover',
                    flex: '1',
                    padding: '40px 56px'
                }}
            >
                <Flex
                    direction="column"
                    height={1}
                    width={1}
                    css={{
                        borderRadius: '10px',
                        background: 'white',
                        padding: '$7'
                    }}
                >
                    <Box font="gray800 displayXs bold">Annexe</Box>

                    <Spacer height="6" />

                    <Flex
                        width={1}
                        css={{
                            padding: '$5 $6',
                            '& > img': {
                                height: 'auto',
                                width: '100%'
                            }
                        }}
                    >
                        {isNonEmptyString(position.fields.cf24781?.url) && (
                            <img src={position.fields.cf24781.url} />
                        )}
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                justify="center"
                height={64}
                width={1}
                css={{
                    background: props.options.color,
                    color: textColor,
                    fontSize: '10px',
                    fontWeight: '600',
                    py: '$3',
                    textAlign: 'center'
                }}
            >
                Paris Athlétisme Compétition - Cub Marathon
                <br />
                Quai Ouest, 40/42 Quai du Point du Jour - 92100 Boulogne Billancourt
                <br />
                Tél : 01.41.33.46.67 / contact@partagetapassion.com
            </Flex>
        </Flex>
    );
};
