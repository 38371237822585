import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IFiseContremarqueProps {
    event: DocumentUserCustomBadgeFragment;
}

export const FiseContremarque = (props: IFiseContremarqueProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background:
                    'url(https://assets.recrewteer.com/badges/fise/fiseContremarque2.jpg) no-repeat',
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Box
                css={{
                    color: 'black',
                    fontFamily: '$poppins',
                    fontSize: '20px',
                    fontWeight: '600',
                    left: '49px',
                    position: 'absolute',
                    top: '494px'
                }}
            >
                {ui.firstName}
                <br />
                {ui.lastName}
            </Box>

            <Box
                css={{
                    color: '#2797d4',
                    fontFamily: '$poppins',
                    fontSize: '18px',
                    fontWeight: '600',
                    left: '49px',
                    position: 'absolute',
                    top: '546px'
                }}
            >
                {vr.delegation?.name || ''}
            </Box>

            <Box
                css={{
                    height: '90px',
                    left: '655px',
                    position: 'absolute',
                    top: '475px',
                    width: '90px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=90&width=90`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>

            <Box
                css={{
                    color: 'black',
                    fontFamily: 'arial',
                    fontSize: '14px',
                    fontWeight: '400',
                    left: '655px',
                    position: 'absolute',
                    textAlign: 'center',
                    top: '569px',
                    width: '90px'
                }}
            >
                {vr.weezeventParticipantInfo?.idBarcode || ''}
            </Box>
        </Flex>
    );
};
