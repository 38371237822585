import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';

interface IAddSectionModalProps {
    initialName: string;

    onAdd(name: string): void;
    onClose(): void;
}

export const AddSectionModal = (props: IAddSectionModalProps) => {
    const translate = useTranslate();
    const [name, setName] = React.useState(props.initialName);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Flex justify="center">
                <IconBackground color="primary" icon="sliders" />
            </Flex>

            <Spacer height="4" />

            <Box color="gray900" fontSize="textLg" fontWeight="medium" textAlign="center">
                {translate('nouvelle_sectio_88105')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500" textAlign="center">
                {translate('ajouter_des_sec_58545')}
            </Box>

            <Spacer height="7" />

            <TextInput label={translate('nom_de_la_secti_38728')} value={name} onChange={setName} />

            <Spacer height="7" />

            <Flex justify="end" gap="3">
                <Button
                    onClick={() => {
                        props.onAdd(name);
                        props.onClose();
                    }}
                >
                    {isNonEmptyString(props.initialName)
                        ? translate('renommer_la_sec_00650')
                        : translate('cr_er_la_sectio_96175')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
