import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

export const PositionRightPanelBodySkeleton = () => (
        <Flex direction="column" width={1}>
            <Flex
                direction="column"
                align="center"
                css={{
                    paddingTop: '78px'
                }}
            >
                <Skeleton height={64} width={64} borderRadius="12px" />

                <Spacer height="6" />

                <Skeleton height={34} width={0.7} borderRadius="$1" />

                <Spacer height="1" />

                <Skeleton height={20} width={0.7} borderRadius="$1" />
            </Flex>
        </Flex>
    );
