import { Button } from 'common-front/src/designSystem/components/button';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { ALL_VOLUNTEERREGISTRATIONSTATE } from 'common/src/generated/types';
import { CampaignRecipients, ICreateUpdateCampaignValues } from 'common/src/input/campaignInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useEventContext } from '../../../events/show/eventContext';
import { CampaignEventAccreditationsRecipients } from './campaignEventAccreditationsRecipients';
import { CampaignEventPositionsRecipients } from './campaignEventPositionsRecipients';
import { CampaignEventSegmentsRecipients } from './campaignEventSegmentsRecipients';
import { CampaignEventUsersRecipients } from './campaignEventUsersRecipients';

interface ICampaignEventRecipientsProps {
    formProps: FormRenderProps<ICreateUpdateCampaignValues>;
}

export const CampaignEventRecipients = (props: ICampaignEventRecipientsProps) => {
    const translate = useTranslate();
    const {
        event: {
            permissions: { isEventAdmin }
        },
        isPositionCategoryAdmin
    } = useEventContext();
    const { form, handleSubmit, values } = props.formProps;

    return (
        <>
            <OnChange name="recipients">
                {(recipients: CampaignRecipients) => {
                    if (recipients === 'everyone') {
                        form.change('campaign.sendToEveryone', true);
                        form.change('campaign.states', []);
                        form.change('campaign.positionsCategoriesIds', []);
                        form.change('campaign.positionsIds', []);
                        form.change('campaign.positionsSlotsIds', []);
                        form.change('campaign.accreditationsCategoriesIds', []);
                        form.change('campaign.accreditationsIds', []);
                        form.change('campaign.accreditationsSlotsIds', []);
                        form.change('campaign.tagsIds', []);
                        form.change('campaign.usersInfosIds', []);
                        form.change('campaign.segmentsIds', []);
                    } else if (recipients === 'states') {
                        form.change('campaign.sendToEveryone', false);
                        form.change('campaign.positionsCategoriesIds', []);
                        form.change('campaign.positionsIds', []);
                        form.change('campaign.positionsSlotsIds', []);
                        form.change('campaign.accreditationsCategoriesIds', []);
                        form.change('campaign.accreditationsIds', []);
                        form.change('campaign.accreditationsSlotsIds', []);
                        form.change('campaign.tagsIds', []);
                        form.change('campaign.usersInfosIds', []);
                        form.change('campaign.segmentsIds', []);
                    } else if (recipients === 'positions') {
                        form.change('campaign.sendToEveryone', false);
                        form.change('campaign.states', []);
                        form.change('campaign.accreditationsCategoriesIds', []);
                        form.change('campaign.accreditationsIds', []);
                        form.change('campaign.accreditationsSlotsIds', []);
                        form.change('campaign.usersInfosIds', []);
                        form.change('campaign.segmentsIds', []);
                    } else if (recipients === 'accreditations') {
                        form.change('campaign.sendToEveryone', false);
                        form.change('campaign.states', []);
                        form.change('campaign.positionsCategoriesIds', []);
                        form.change('campaign.positionsIds', []);
                        form.change('campaign.positionsSlotsIds', []);
                        form.change('campaign.usersInfosIds', []);
                        form.change('campaign.segmentsIds', []);
                    } else if (recipients === 'users') {
                        form.change('campaign.sendToEveryone', false);
                        form.change('campaign.states', []);
                        form.change('campaign.positionsCategoriesIds', []);
                        form.change('campaign.positionsIds', []);
                        form.change('campaign.positionsSlotsIds', []);
                        form.change('campaign.accreditationsCategoriesIds', []);
                        form.change('campaign.accreditationsIds', []);
                        form.change('campaign.accreditationsSlotsIds', []);
                        form.change('campaign.tagsIds', []);
                        form.change('campaign.segmentsIds', []);
                    } else if (recipients === 'segments') {
                        form.change('campaign.sendToEveryone', false);
                        form.change('campaign.states', []);
                        form.change('campaign.positionsCategoriesIds', []);
                        form.change('campaign.positionsIds', []);
                        form.change('campaign.positionsSlotsIds', []);
                        form.change('campaign.accreditationsCategoriesIds', []);
                        form.change('campaign.accreditationsIds', []);
                        form.change('campaign.accreditationsSlotsIds', []);
                        form.change('campaign.tagsIds', []);
                        form.change('campaign.usersInfosIds', []);
                    } else {
                        throw new Error(`Recipients ${recipients} is not possible`);
                    }
                }}
            </OnChange>

            <OnChange name="campaign.sendToEveryone">
                {(sendToEveryone: boolean) => {
                    if (sendToEveryone) {
                        form.change('campaign.states', []);
                        form.change('campaign.positionsCategoriesIds', []);
                        form.change('campaign.positionsIds', []);
                        form.change('campaign.positionsSlotsIds', []);
                        form.change('campaign.tagsIds', []);
                        form.change('campaign.accreditationsCategoriesIds', []);
                        form.change('campaign.accreditationsIds', []);
                        form.change('campaign.accreditationsSlotsIds', []);
                        form.change('campaign.usersInfosIds', []);
                    }
                }}
            </OnChange>

            <OnChange name="campaign.positionsCategoriesIds">
                {() => {
                    if (
                        values.campaign.sendToEveryone &&
                        values.campaign.positionsCategoriesIds.length > 0
                    ) {
                        form.change('campaign.positionsCategoriesIds', []);
                    }
                }}
            </OnChange>

            <OnChange name="campaign.positionsIds">
                {() => {
                    if (values.campaign.sendToEveryone && values.campaign.positionsIds.length > 0) {
                        form.change('campaign.positionsIds', []);
                    }
                }}
            </OnChange>

            <OnChange name="campaign.positionsSlotsIds">
                {() => {
                    if (
                        values.campaign.sendToEveryone &&
                        values.campaign.positionsSlotsIds.length > 0
                    ) {
                        form.change('campaign.positionsSlotsIds', []);
                    }
                }}
            </OnChange>

            <OnChange name="campaign.tagsIds">
                {() => {
                    if (values.campaign.sendToEveryone && values.campaign.tagsIds.length > 0) {
                        form.change('campaign.tagsIds', []);
                    }
                }}
            </OnChange>

            <OnChange name="campaign.usersInfosIds">
                {() => {
                    if (
                        values.campaign.sendToEveryone &&
                        values.campaign.usersInfosIds.length > 0
                    ) {
                        form.change('campaign.usersInfosIds', []);
                    }
                }}
            </OnChange>

            <OnChange name="campaign.segmentsIds">
                {() => {
                    if (values.campaign.sendToEveryone && values.campaign.segmentsIds.length > 0) {
                        form.change('campaign.segmentsIds', []);
                    }
                }}
            </OnChange>

            <Box color="gray800">{translate('_qui_voulez_vo_96064')}</Box>

            <Spacer height="3" />

            {isEventAdmin && (
                <>
                    <RadioText name="recipients" value="everyone">
                        {translate('_tous_vos_memb_31761')}
                    </RadioText>

                    <Spacer height="3" />
                </>
            )}

            {isEventAdmin && (
                <RadioText name="recipients" value="states">
                    {translate('_certains_memb_35722')}
                </RadioText>
            )}

            {values.recipients === 'states' && (
                <>
                    <Spacer height="2" />

                    <Box css={{ marginLeft: '$6' }}>
                        <RichSelect
                            isSearchVisible={true}
                            multiple={true}
                            name="campaign.states"
                            placeholder={translate('ajouter_des_sta_70922')}
                        >
                            {ALL_VOLUNTEERREGISTRATIONSTATE.map((state) => (
                                    <option key={state} value={state}>
                                        {translate(state)}
                                    </option>
                                ))}
                        </RichSelect>
                    </Box>
                </>
            )}

            <Spacer height="3" />

            <RadioText name="recipients" value="positions">
                {isEventAdmin
                    ? translate('_certaines_cat_59188')
                    : isPositionCategoryAdmin()
                    ? translate('_certaines_cat_22161')
                    : translate('_certaines_mis_80674')}
            </RadioText>

            <CampaignEventPositionsRecipients recipients={values.recipients} />

            <Spacer height="3" />

            {isEventAdmin && (
                <>
                    <RadioText name="recipients" value="accreditations">
                        {translate('_certaines_cat_14293')}
                    </RadioText>

                    <CampaignEventAccreditationsRecipients recipients={values.recipients} />

                    <Spacer height="3" />
                </>
            )}

            <RadioText name="recipients" value="users">
                {translate('_certains_memb_91094')}
            </RadioText>

            <CampaignEventUsersRecipients recipients={values.recipients} />

            <Spacer height="3" />

            <RadioText name="recipients" value="segments">
                {translate('_certaines_vue_55512')}
            </RadioText>

            <CampaignEventSegmentsRecipients recipients={values.recipients} />

            <Spacer height="7" />

            <Flex>
                <Button onClick={handleSubmit}>{translate('suivant_livra_97068')}</Button>
            </Flex>
        </>
    );
};
