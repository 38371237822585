import { RightPanel } from 'common-front/src/designSystem/components/rightPanel/rightPanel';
import { RightPanelBody } from 'common-front/src/designSystem/components/rightPanel/rightPanelBody';
import { RightPanelFooter } from 'common-front/src/designSystem/components/rightPanel/rightPanelFooter';
import { RightPanelHeader } from 'common-front/src/designSystem/components/rightPanel/rightPanelHeader';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    CampaignTextMessagesQuery,
    CampaignUserInfoFragment,
    EventId,
    OrganizationId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { sortBy } from 'lodash-es';
import * as React from 'react';
import { useTextMessageRepliesQuery } from '../../../generated/graphqlHooks';
import { MessageWithReplies } from './messageWithReplies';
import { TextMessageSendNew } from './textMessageSendNew';

interface ITextMessageRepliesProps {
    createdBy?: CampaignTextMessagesQuery['organization']['campaign']['createdBy'];
    eventId: Emptyable<EventId>;
    organizationId: OrganizationId;
    text: string;
    textMessage: CampaignTextMessagesQuery['organization']['campaign']['textMessages']['nodes'][0];
    userInfo: CampaignUserInfoFragment;

    onClose(): void;
}

export const TextMessageReplies = (props: ITextMessageRepliesProps) => {
    const translate = useTranslate();
    const { data, loader, reload } = useTextMessageRepliesQuery({
        organizationId: props.organizationId,
        textMessageId: props.textMessage.id
    });
    const allMessages = React.useMemo(() => sortBy(
            [
                {
                    text: props.text,
                    replies: data.organization?.textMessage.replies ?? [],
                    sentAt: props.textMessage.sentAt,
                    createdBy: props.createdBy
                }
            ].concat((data.organization?.textMessage.children ?? []) as MessageWithReplies[]),
            (m) => m.sentAt.toMillis()
        ), [data.organization]);

    return (
        <RightPanel size="sm" onClose={props.onClose}>
            <RightPanelHeader>
                {translate('r_ponse_de_1_77675', props.userInfo.name)}
            </RightPanelHeader>

            {loader || (
                <>
                    <RightPanelBody>
                        {allMessages.map((message, index) => (
                                <MessageWithReplies
                                    key={index}
                                    message={message}
                                    userInfo={props.userInfo}
                                />
                            ))}

                        <Spacer height="6" />
                    </RightPanelBody>

                    <RightPanelFooter>
                        <TextMessageSendNew
                            eventId={props.eventId}
                            organizationId={props.organizationId}
                            parentId={props.textMessage.id}
                            reload={reload}
                        />
                    </RightPanelFooter>
                </>
            )}
        </RightPanel>
    );
};
