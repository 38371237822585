import { Button } from 'common-front/src/designSystem/components/button';
import { Images } from 'common-front/src/util/assets';
import { Link } from 'common-front/src/util/link';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { Spacer } from 'common/src/designSystem/components/spacer';
import * as React from 'react';

interface IFeatureProps {
    description: string;
    icon: IIcon;
    title: string;
}

const Feature = (props: IFeatureProps) => (
        <Flex direction="column" width={1}>
            <Box fontSize="displayMd" textAlign="center" css={{ color: 'black' }}>
                <I icon={props.icon} />
            </Box>

            <Spacer height="5" />

            <Box
                color="primary700"
                fontSize="textXl"
                textAlign="center"
                css={{
                    fontFamily: '$montserrat',
                    fontSize: '$textXl',
                    textTransform: 'uppercase',
                    fontWeight: '$bold',
                    '@tablet': {
                        fontSize: '$displayXs'
                    }
                }}
            >
                {props.title}
            </Box>

            <Spacer height="2" />

            <Box
                color="primary700"
                fontSize="textMd"
                textAlign="center"
                css={{
                    fontFamily: '$madurai',
                    fontSize: '$textMd',
                    '@tablet': {
                        fontSize: '$displayXs'
                    }
                }}
            >
                {props.description}
            </Box>
        </Flex>
    );

/*
interface IFunctionalityProps {
    title: string;
    text: string;
}

const Functionality = (props: IFunctionalityProps) => {
    return (
        <Flex
            direction="column"
            gap="2"
            css={{
                background: 'white',
                borderRadius: '$2',
                boxShadow: '$xs',
                padding: '$5 $4',
                width: '100%',
                '@tablet': {
                    width: '540px'
                },
                '@desktop': {
                    width: '100%'
                }
            }}
        >
            <Box
                color="primary700"
                fontWeight="bold"
                textAlign="center"
                css={{ fontFamily: '$montserrat', fontSize: '20px' }}
            >
                {props.title}
            </Box>

            <Box
                color="primary700"
                textAlign="center"
                css={{
                    fontFamily: '$madurai',
                    fontSize: '16px',
                    '@tablet': {
                        fontSize: '20px'
                    }
                }}
            >
                {props.text}
            </Box>
        </Flex>
    );
};
*/

export const About = () => (
        <Box height={1} width={1} css={{ overflowY: 'auto' }}>
            <Flex
                justify="center"
                width={1}
                css={{
                    background: '$cyan',
                    padding: '$9 $4',
                    '@tablet': {
                        padding: '72px 68px'
                    },
                    '@desktop': {
                        padding: '96px 0'
                    }
                }}
            >
                <Flex
                    css={{
                        flexDirection: 'column',
                        gap: '56px',
                        width: '100%',
                        '@tablet': {
                            gap: '88px'
                        },
                        '@desktop': {
                            flexDirection: 'row',
                            gap: '144px',
                            width: '1280px'
                        }
                    }}
                >
                    <Flex
                        css={{
                            justifyContent: 'center',
                            width: '100%',
                            '@desktop': {
                                alignItems: 'center',
                                height: '100%',
                                width: '440px'
                            }
                        }}
                    >
                        <Box
                            css={{
                                height: '74px',
                                width: '180px',
                                '@tablet': {
                                    height: '180px',
                                    width: '440px'
                                },
                                '@desktop': {
                                    height: '180px',
                                    width: '440px'
                                }
                            }}
                        >
                            <img
                                src={Images.MsoLogo1}
                                height="100%"
                                width="100%"
                                alt="Logo Vabene"
                            />
                        </Box>
                    </Flex>

                    <Flex direction="column">
                        <Box
                            color="white"
                            fontWeight="bold"
                            css={{
                                fontFamily: '$montserrat',
                                fontSize: '36px',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                '@tablet': {
                                    fontSize: '40px'
                                },
                                '@desktop': {
                                    fontSize: '40px',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            La gestion de bénévoles n’a jamais été aussi facile...
                        </Box>

                        <Spacer height="3" />

                        <Box
                            color="white"
                            textAlign="center"
                            css={{
                                fontFamily: '$montserrat',
                                fontSize: '16px',
                                '@tablet': {
                                    fontSize: '20px'
                                },
                                '@desktop': {
                                    fontSize: '20px',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Vabene est la solution complète pour la gestion de bénévoles sur vos
                            événements sportifs et culturels.
                        </Box>

                        <Spacer height="7" />

                        <Flex
                            css={{
                                flexDirection: 'column',
                                gap: '$4',
                                px: '$6',
                                '@tablet': {
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    px: '0'
                                },
                                '@desktop': {
                                    justifyContent: 'flex-start'
                                }
                            }}
                        >
                            <Flex
                                direction="column"
                                css={{
                                    gap: '$1',
                                    '@tablet': {
                                        gap: '$2'
                                    }
                                }}
                            >
                                <Box css={{ width: '100%', '@tablet': { width: '160px' } }}>
                                    <Link to={'/auth/sign-up/credentials'}>
                                        <Button size="xl" textAlign="center">
                                            Essayer 14 jours
                                        </Button>
                                    </Link>
                                </Box>

                                <Box
                                    color="white"
                                    fontSize="textXs"
                                    css={{ textAlign: 'center', '@tablet': { textAlign: 'left' } }}
                                >
                                    *Version desktop uniquement
                                </Box>
                            </Flex>

                            <Flex>
                                <Box css={{ width: '100%', '@tablet': { width: '160px' } }}>
                                    <Link to={{ pathname: 'mailto:vabene@mso.swiss' }}>
                                        <Button size="xl" textAlign="center">
                                            Nous contacter
                                        </Button>
                                    </Link>
                                </Box>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                justify="center"
                width={1}
                css={{
                    padding: '$9 $7',
                    '@tablet': {
                        padding: '72px 84px'
                    },
                    '@desktop': {
                        padding: '96px 0'
                    }
                }}
            >
                <Flex
                    direction="column"
                    css={{
                        gap: '40px',
                        width: '100%',
                        '@tablet': {
                            gap: '64px'
                        },
                        '@desktop': {
                            gap: '64px',
                            width: '1280px'
                        }
                    }}
                >
                    <Flex direction="column">
                        <Box
                            color="primary700"
                            fontWeight="bold"
                            textAlign="center"
                            css={{
                                fontFamily: '$montserrat',
                                fontSize: '$textXl',
                                textTransform: 'uppercase',
                                '@tablet': {
                                    fontSize: '$displayXs'
                                }
                            }}
                        >
                            Une gestion
                        </Box>

                        <Box
                            color="primary700"
                            fontWeight="bold"
                            textAlign="center"
                            css={{
                                fontFamily: '$montserrat',
                                fontSize: '$displayXs',
                                textTransform: 'uppercase',
                                '@tablet': {
                                    fontSize: '40px'
                                }
                            }}
                        >
                            Tout-en-un
                        </Box>
                    </Flex>

                    <Flex
                        css={{
                            flexDirection: 'column',
                            gap: '56px',
                            '@desktop': {
                                flexDirection: 'row',
                                justifyContent: 'center',
                                gap: '$7'
                            }
                        }}
                    >
                        <Feature
                            description="Gérez tout depuis un tableau de bord en ligne."
                            icon="arrows-to-dot"
                            title="Centralisée"
                        />

                        <Feature
                            description="Travaillez simultanément avec vos équipes sur le même évenement."
                            icon="comments"
                            title="Collaborative"
                        />

                        <Feature
                            description="Gardez vos bénévoles informés à n’importe quel moment."
                            icon="lightbulb-on"
                            title="Proactive"
                        />
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                justify="center"
                width={1}
                css={{
                    background: '$cyan',
                    padding: '$9 $7',
                    '@tablet': {
                        padding: '72px 84px'
                    },
                    '@desktop': {
                        padding: '96px 0'
                    }
                }}
            >
                <Flex
                    css={{
                        flexDirection: 'column',
                        gap: '40px',
                        width: '100%',
                        '@tablet': {
                            gap: '64px'
                        },
                        '@desktop': {
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: '64px',
                            width: '1280px'
                        }
                    }}
                >
                    <Flex
                        direction="column"
                        width={1}
                        css={{
                            gap: '$2',
                            '@tablet': {
                                gap: '$4'
                            },
                            '@desktop': {
                                gap: '$4'
                            }
                        }}
                    >
                        <Box
                            color="primary700"
                            fontWeight="bold"
                            css={{
                                fontFamily: '$montserrat',
                                fontSize: '$displayXs',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                '@tablet': {
                                    fontSize: '40px'
                                },
                                '@desktop': {
                                    fontSize: '40px',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Création des postes
                        </Box>

                        <Box
                            color="white"
                            css={{
                                fontFamily: '$madurai',
                                fontSize: '$textMd',
                                textAlign: 'center',
                                '@tablet': {
                                    fontSize: '$displayXs'
                                },
                                '@desktop': {
                                    fontSize: '$displayXs',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Listez facilement toutes les tâches de l’événement.
                        </Box>
                    </Flex>

                    <Box
                        width={1}
                        textAlign="center"
                        css={{
                            height: '300px',
                            '@tablet': {
                                height: '576px'
                            },
                            ' @desktop': {
                                height: '576px'
                            }
                        }}
                    >
                        <img
                            src={Images.MsoCreatePositions}
                            height="100%"
                            alt="Création des postes"
                        />
                    </Box>
                </Flex>
            </Flex>

            <Flex
                justify="center"
                width={1}
                css={{
                    padding: '$9 $7',
                    '@tablet': {
                        padding: '72px 84px'
                    },
                    '@desktop': {
                        padding: '96px 0'
                    }
                }}
            >
                <Flex
                    css={{
                        flexDirection: 'column',
                        gap: '40px',
                        width: '100%',
                        '@tablet': {
                            gap: '64px'
                        },
                        '@desktop': {
                            alignItems: 'center',
                            flexDirection: 'row-reverse',
                            gap: '64px',
                            width: '1280px'
                        }
                    }}
                >
                    <Flex direction="column" gap="4" width={1}>
                        <Box
                            color="primary700"
                            fontWeight="bold"
                            css={{
                                fontFamily: '$montserrat',
                                fontSize: '$displayXs',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                '@tablet': {
                                    fontSize: '40px'
                                },
                                '@desktop': {
                                    fontSize: '40px',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Gérez vos bénévoles
                        </Box>

                        <Box
                            color="primary700"
                            css={{
                                fontFamily: '$madurai',
                                fontSize: '$textMd',
                                textAlign: 'center',
                                '@tablet': {
                                    fontSize: '$displayXs'
                                },
                                '@desktop': {
                                    fontSize: '$displayXs',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Affectez vos bénévoles à vos postes en quelques clics et trouvez
                            rapidement vos bénévoles disponibles grâce aux statuts d’affectation.
                        </Box>
                    </Flex>

                    <Box
                        width={1}
                        textAlign="center"
                        css={{
                            height: '162px',
                            '@tablet': {
                                height: '324px'
                            },
                            '@desktop': {
                                height: '324px'
                            }
                        }}
                    >
                        <img src={Images.MsoVolunteers} height="100%" alt="Gérez vos bénévoles" />
                    </Box>
                </Flex>
            </Flex>

            <Flex
                justify="center"
                width={1}
                css={{
                    background: '$cyan',
                    padding: '$9 $7',
                    '@tablet': {
                        padding: '72px 84px'
                    },
                    '@desktop': {
                        padding: '96px 0'
                    }
                }}
            >
                <Flex
                    css={{
                        flexDirection: 'column',
                        gap: '40px',
                        width: '100%',
                        '@tablet': {
                            gap: '64px'
                        },
                        '@desktop': {
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: '64px',
                            width: '1280px'
                        }
                    }}
                >
                    <Flex
                        direction="column"
                        width={1}
                        css={{
                            gap: '$2',
                            '@tablet': {
                                gap: '$4'
                            },
                            '@desktop': {
                                gap: '$4'
                            }
                        }}
                    >
                        <Box
                            color="primary700"
                            fontWeight="bold"
                            css={{
                                fontFamily: '$montserrat',
                                fontSize: '$displayXs',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                '@tablet': {
                                    fontSize: '40px'
                                },
                                '@desktop': {
                                    fontSize: '40px',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Carte intéractive
                        </Box>

                        <Box
                            color="white"
                            css={{
                                fontFamily: '$madurai',
                                fontSize: '$textMd',
                                textAlign: 'center',
                                '@tablet': {
                                    fontSize: '$displayXs'
                                },
                                '@desktop': {
                                    fontSize: '$displayXs',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Visualisez vos postes et vos bénévoles sur une carte interactive pour
                            une vue globale de votre événement en tout temps.
                        </Box>
                    </Flex>

                    <Box
                        width={1}
                        textAlign="center"
                        css={{
                            height: '300px',
                            '@tablet': {
                                height: '576px'
                            },
                            ' @desktop': {
                                height: '576px'
                            }
                        }}
                    >
                        <img
                            src="https://assets.recrewteer.com/badges/mso/carte.png"
                            height="100%"
                            alt="Carte intéractive"
                        />
                    </Box>
                </Flex>
            </Flex>

            <Flex
                justify="center"
                width={1}
                css={{
                    padding: '$9 $7',
                    '@tablet': {
                        padding: '72px 84px'
                    },
                    '@desktop': {
                        padding: '96px 0'
                    }
                }}
            >
                <Flex
                    css={{
                        flexDirection: 'column',
                        gap: '40px',
                        width: '100%',
                        '@tablet': {
                            gap: '64px'
                        },
                        '@desktop': {
                            alignItems: 'center',
                            flexDirection: 'row-reverse',
                            gap: '64px',
                            width: '1280px'
                        }
                    }}
                >
                    <Flex direction="column" gap="4" width={1}>
                        <Box
                            color="primary700"
                            fontWeight="bold"
                            css={{
                                fontFamily: '$montserrat',
                                fontSize: '$displayXs',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                '@tablet': {
                                    fontSize: '40px'
                                },
                                '@desktop': {
                                    fontSize: '40px',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Badges & Accréditations
                        </Box>

                        <Box
                            color="primary700"
                            css={{
                                fontFamily: '$madurai',
                                fontSize: '$textMd',
                                textAlign: 'center',
                                '@tablet': {
                                    fontSize: '$displayXs'
                                },
                                '@desktop': {
                                    fontSize: '$displayXs',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Générez des modèles de badges pour vos bénévoles prêts à imprimer et
                            assignez des accréditations spéciales à vos bénévoles selon vos besoins.
                        </Box>
                    </Flex>

                    <Box
                        width={1}
                        textAlign="center"
                        css={{
                            height: '162px',
                            '@tablet': {
                                height: '324px'
                            },
                            '@desktop': {
                                height: '324px'
                            }
                        }}
                    >
                        <img
                            src="https://assets.recrewteer.com/badges/mso/carte.png"
                            height="100%"
                            alt="Badges & Accréditations"
                        />
                    </Box>
                </Flex>
            </Flex>

            <Flex
                justify="center"
                width={1}
                css={{
                    background: '$cyan',
                    padding: '$9 $7',
                    '@tablet': {
                        padding: '72px 84px'
                    },
                    '@desktop': {
                        padding: '96px 0'
                    }
                }}
            >
                <Flex
                    css={{
                        flexDirection: 'column',
                        gap: '40px',
                        width: '100%',
                        '@tablet': {
                            gap: '64px'
                        },
                        '@desktop': {
                            alignItems: 'center',
                            flexDirection: 'row',
                            gap: '64px',
                            width: '1280px'
                        }
                    }}
                >
                    <Flex
                        direction="column"
                        width={1}
                        css={{
                            gap: '$2',
                            '@tablet': {
                                gap: '$4'
                            },
                            '@desktop': {
                                gap: '$4'
                            }
                        }}
                    >
                        <Box
                            color="primary700"
                            fontWeight="bold"
                            css={{
                                fontFamily: '$montserrat',
                                fontSize: '$displayXs',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                '@tablet': {
                                    fontSize: '40px'
                                },
                                '@desktop': {
                                    fontSize: '40px',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Campagnes
                        </Box>

                        <Box
                            color="white"
                            css={{
                                fontFamily: '$madurai',
                                fontSize: '$textMd',
                                textAlign: 'center',
                                '@tablet': {
                                    fontSize: '$displayXs'
                                },
                                '@desktop': {
                                    fontSize: '$displayXs',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Besoin d’informer tous vos bénévoles ou certains segments parmi eux ?
                            Rien de plus facile avec notre module de campagne par e-mail ou SMS.
                        </Box>
                    </Flex>

                    <Box
                        width={1}
                        textAlign="center"
                        css={{
                            height: '300px',
                            '@tablet': {
                                height: '576px'
                            },
                            ' @desktop': {
                                height: '576px'
                            }
                        }}
                    >
                        <img
                            src="https://assets.recrewteer.com/badges/mso/campagne.png"
                            height="100%"
                            alt="Campagnes"
                        />
                    </Box>
                </Flex>
            </Flex>

            <Flex
                justify="center"
                width={1}
                css={{
                    padding: '$9 $7',
                    '@tablet': {
                        padding: '72px 84px'
                    },
                    '@desktop': {
                        padding: '96px 0'
                    }
                }}
            >
                <Flex
                    css={{
                        flexDirection: 'column',
                        gap: '40px',
                        width: '100%',
                        '@tablet': {
                            gap: '64px'
                        },
                        '@desktop': {
                            alignItems: 'center',
                            flexDirection: 'row-reverse',
                            gap: '64px',
                            width: '1280px'
                        }
                    }}
                >
                    <Flex direction="column" gap="4" width={1}>
                        <Box
                            color="primary700"
                            fontWeight="bold"
                            css={{
                                fontFamily: '$montserrat',
                                fontSize: '$displayXs',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                '@tablet': {
                                    fontSize: '40px'
                                },
                                '@desktop': {
                                    fontSize: '40px',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Intégrations
                        </Box>

                        <Box
                            color="primary700"
                            css={{
                                fontFamily: '$madurai',
                                fontSize: '$textMd',
                                textAlign: 'center',
                                '@tablet': {
                                    fontSize: '$displayXs'
                                },
                                '@desktop': {
                                    fontSize: '$displayXs',
                                    textAlign: 'left'
                                }
                            }}
                        >
                            Intégrez vos différents systèmes tels qu’Excel, solutions de contrôle
                            d’accès ou solutions cashless directement dans la plateforme.
                        </Box>
                    </Flex>

                    <Box
                        width={1}
                        textAlign="center"
                        css={{
                            height: '162px',
                            '@tablet': {
                                height: '324px'
                            },
                            '@desktop': {
                                height: '324px'
                            }
                        }}
                    >
                        <img
                            src="https://assets.recrewteer.com/badges/mso/integration.png"
                            height="100%"
                            alt="Intégrations"
                        />
                    </Box>
                </Flex>
            </Flex>

            <Flex
                justify="center"
                width={1}
                css={{
                    background: '$cyan',
                    padding: '$9 $4',
                    '@tablet': {
                        padding: '72px 52px'
                    },
                    '@desktop': {
                        padding: '96px 0'
                    }
                }}
            >
                <Flex
                    direction="column"
                    css={{
                        width: '100%',
                        '@desktop': {
                            width: '1280px'
                        }
                    }}
                >
                    <Box
                        color="white"
                        fontWeight="bold"
                        textAlign="center"
                        css={{
                            fontFamily: '$montserrat',
                            fontSize: '$displayXs',
                            '@tablet': {
                                fontSize: '40px'
                            },
                            '@desktop': {
                                fontSize: '40px'
                            }
                        }}
                    >
                        Curieux de savoir ce que <br />
                        notre plateforme peut vous apporter ?
                    </Box>

                    <Spacer height="8" />

                    <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        width={1}
                        css={{
                            gap: '$1',
                            '@tablet': {
                                gap: '$2'
                            }
                        }}
                    >
                        <Link to={'/auth/sign-up/credentials'}>
                            <Button size="xl" textAlign="center">
                                Essayer 14 jours
                            </Button>
                        </Link>

                        <Box
                            color="white"
                            fontSize="textXs"
                            css={{ textAlign: 'center', '@tablet': { textAlign: 'left' } }}
                        >
                            *Version desktop uniquement
                        </Box>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                justify="center"
                width={1}
                css={{
                    padding: '$9 $4',
                    '@tablet': {
                        padding: '96px 52px'
                    },
                    '@desktop': {
                        padding: '96px 0'
                    }
                }}
            >
                <Flex
                    direction="column"
                    css={{
                        gap: '40px',
                        width: '100%',
                        '@tablet': {
                            gap: '64px'
                        },
                        '@desktop': {
                            gap: '64px',
                            width: '1280px'
                        }
                    }}
                >
                    <Flex direction="column" gap="5">
                        <Box
                            color="primary700"
                            fontWeight="bold"
                            textAlign="center"
                            css={{
                                fontFamily: '$montserrat',
                                fontSize: '$displayXs',
                                textTransform: 'uppercase',
                                '@tablet': {
                                    fontSize: '40px'
                                }
                            }}
                        >
                            Simplifiez-vous la vie
                        </Box>

                        <Box
                            color="primary700"
                            fontSize="textMd"
                            textAlign="center"
                            css={{
                                fontFamily: '$madurai',
                                '@tablet': {
                                    fontSize: '$displayXs'
                                }
                            }}
                        >
                            Contactez-nous dès maintenant pour une introduction détaillée à notre
                            solution de gestion de bénévoles.
                        </Box>
                    </Flex>

                    <Flex
                        css={{
                            flexDirection: 'column',
                            gap: '$8',
                            '@tablet': {
                                flexDirection: 'row',
                                justifyContent: 'center',
                                padding: '$7'
                            }
                        }}
                    >
                        <Link to={{ pathname: 'mailto:johnathan@mso.swiss' }}>
                            <Flex direction="column" gap="5" css={{ cursor: 'pointer' }}>
                                <Flex justify="center" width={1}>
                                    <Flex
                                        align="center"
                                        justify="center"
                                        height={48}
                                        width={48}
                                        css={{
                                            border: '2px solid black',
                                            borderRadius: '48px',
                                            color: 'black',
                                            fontSize: '$displayXs'
                                        }}
                                    >
                                        <I icon="envelope" />
                                    </Flex>
                                </Flex>

                                <Box
                                    color="gray900"
                                    fontWeight="medium"
                                    textAlign="center"
                                    css={{
                                        fontSize: '$textMd',
                                        '@tablet': {
                                            fontSize: '$displayXs'
                                        }
                                    }}
                                >
                                    jonathan@mso.swiss
                                </Box>
                            </Flex>
                        </Link>

                        <Link to={{ pathname: 'tel:+41775372049' }}>
                            <Flex direction="column" gap="5" css={{ cursor: 'pointer' }}>
                                <Flex justify="center" width={1}>
                                    <Flex
                                        align="center"
                                        justify="center"
                                        height={48}
                                        width={48}
                                        css={{
                                            border: '2px solid black',
                                            borderRadius: '48px',
                                            color: 'black',
                                            fontSize: '$displayXs'
                                        }}
                                    >
                                        <I icon="phone" />
                                    </Flex>
                                </Flex>

                                <Box
                                    color="gray900"
                                    fontWeight="medium"
                                    textAlign="center"
                                    css={{
                                        fontSize: '$textMd',
                                        '@tablet': {
                                            fontSize: '$displayXs'
                                        }
                                    }}
                                >
                                    +41 77 537 20 49
                                </Box>
                            </Flex>
                        </Link>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                css={{
                    background: '$primary700',
                    padding: '$9 $7'
                }}
            >
                <Box width={1} textAlign="center">
                    <img src={Images.MsoLogo3} height="50px" alt="Logo Vabene" />
                </Box>
            </Flex>
        </Box>
    );
