import { PaginationCell } from 'common-front/src/designSystem/components/pagination/paginationCell';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { usePaginationInfos } from 'common-front/src/hooks/usePaginationInfos';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Flex } from 'common/src/designSystem/components/flex';
import { RowSkeleton } from 'common/src/designSystem/components/table/rowSkeleton';
import { UsersPlanning } from 'common/src/documents/usersPlanning/usersPlanning';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import { useVolunteersCalendarQuery } from '../../../generated/graphqlHooks';

export const VolunteersCalendar = () => {
    const {
        params: { eventId }
    } = useHeavent();
    const { nameDebounced, offset, predicates, setOffset } = useSegmentsContext();
    const { data, isLoading } = useVolunteersCalendarQuery({
        eventId,
        predicates,
        name: isNonEmptyString(nameDebounced) ? nameDebounced : undefined,
        offset
    });
    const { numberOfPages, totalCount } = usePaginationInfos(data.event?.volunteersRegistrations);
    const [displayedPageIndex, setDisplayedPageIndex] = React.useState(0);

    return (
        <Flex direction="column" width={1}>
            {isLoading ? (
                <>
                    <RowSkeleton
                        css={{
                            border: '1px solid $gray200',
                            borderTopLeftRadius: '$2',
                            borderTopRightRadius: '$2'
                        }}
                    />
                    <RowSkeleton
                        css={{
                            border: '1px solid $gray200',
                            borderTop: 'none'
                        }}
                    />
                    <RowSkeleton
                        css={{
                            border: '1px solid $gray200',
                            borderTop: 'none'
                        }}
                    />
                </>
            ) : (
                <UsersPlanning
                    displayedPageIndex={displayedPageIndex}
                    ranges={data.event?.ranges ?? []}
                    volunteersRegistrations={data.event?.volunteersRegistrations.nodes ?? []}
                    onNextClick={() => {
                        setDisplayedPageIndex(displayedPageIndex + 1);
                    }}
                    onPreviousClick={() => {
                        setDisplayedPageIndex(displayedPageIndex - 1);
                    }}
                />
            )}

            <Flex
                align="center"
                height={56}
                css={{
                    borderBottom: '1px solid $gray200',
                    borderBottomLeftRadius: '$2',
                    borderBottomRightRadius: '$2',
                    borderLeft: '1px solid $gray200',
                    borderRight: '1px solid $gray200'
                }}
            >
                <PaginationCell
                    numberOfPages={numberOfPages}
                    offset={offset}
                    totalCount={totalCount}
                    setOffset={setOffset}
                />
            </Flex>
        </Flex>
    );
};
