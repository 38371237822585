import { CreateUpdateCategoryModal } from 'common-front/src/components/createUpdateCategoryModal';
import { CustomFieldsCategory, OrganizationId } from 'common/src/generated/types';
import * as React from 'react';
import { useCustomFieldCategoryUpdateMutation } from '../../generated/graphqlHooks';

interface ICreateUpdateCustomFieldCategoryProps {
    customFieldCategory: Pick<CustomFieldsCategory, 'id' | 'name'>;
    organizationId: OrganizationId;

    onClose(): void;
    onSuccess(): void;
}

export const CreateUpdateCustomFieldCategory = (props: ICreateUpdateCustomFieldCategoryProps) => {
    const { mutate } = useCustomFieldCategoryUpdateMutation();

    return (
        <CreateUpdateCategoryModal
            initialName={props.customFieldCategory.name}
            isEdit={true}
            mutate={async (name) => (
                    await mutate({
                        organizationId: props.organizationId,
                        customFieldCategoryId: props.customFieldCategory.id,
                        customFieldCategory: { name }
                    })
                ).customFieldCategoryUpdate}
            onClose={props.onClose}
            onSuccess={props.onSuccess}
        />
    );
};
