import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';

const getBackgroundUrl = (badgeType: string) => {
    switch (badgeType) {
        case 'Crew':
            return `https://assets.recrewteer.com/badges/recrewdays/recrewdaysStaffRecto.png`;
        case 'Festival':
            return `https://assets.recrewteer.com/badges/recrewdays/recrewdaysFestivalRecto.png`;
        case 'Sport':
            return `https://assets.recrewteer.com/badges/recrewdays/recrewdaysSportRecto.png`;
        case 'Partenaire':
            return `https://assets.recrewteer.com/badges/recrewdays/recrewdaysPartenaireRecto.png`;
        case 'Tech':
            return `https://assets.recrewteer.com/badges/recrewdays/recrewdaysTechRecto.png`;
        default:
            return '';
    }
};

interface IBadgeRecrewdays16FrontProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeRecrewdaysA6Front = (props: IBadgeRecrewdays16FrontProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;

    return (
        <Flex
            height={597}
            width={435}
            css={{
                background: `url(${getBackgroundUrl(userInfo.recrewdaysTypeBadge)}) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Box
                color="gray800"
                css={{
                    fontSize: '28px',
                    fontWeight: '700',
                    left: '40px',
                    position: 'absolute',
                    top: '412px'
                }}
            >
                {userInfo.firstName}
            </Box>

            <Box
                color="gray800"
                css={{
                    fontSize: '28px',
                    fontWeight: '700',
                    left: '40px',
                    position: 'absolute',
                    top: '446px'
                }}
            >
                {userInfo.lastName}
            </Box>

            <Box
                color="gray800"
                css={{
                    fontSize: '13px',
                    fontWeight: '500',
                    left: '40px',
                    position: 'absolute',
                    top: '487px'
                }}
            >
                {userInfo.recrewdaysEvenement}
            </Box>
        </Flex>
    );
};
