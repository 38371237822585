import * as React from 'react';
import { Flex } from '../../../../designSystem/components/flex';
import { DocumentUserCustomBadgeFragment } from '../../../../generated/types';
import { UltraMarinMediaBack } from './ultraMarinMediaBack';
import { UltraMarinMediaFront } from './ultraMarinMediaFront';

interface IUltraMarinMediaProps {
    event: DocumentUserCustomBadgeFragment;
}

export const UltraMarinMedia = (props: IUltraMarinMediaProps) => (
        <>
            <Flex height={396} width={280} css={{ overflow: 'hidden', pageBreakBefore: 'always' }}>
                <UltraMarinMediaFront event={props.event} />
            </Flex>

            <Flex height={396} width={280} css={{ overflow: 'hidden', pageBreakBefore: 'always' }}>
                <UltraMarinMediaBack event={props.event} />
            </Flex>
        </>
    );
