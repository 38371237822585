import { sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { DocumentPositionSheetFragment, PositionDocumentOptions } from '../../generated/types';
import { PositionSheetHeader } from './positionSheetHeader';
import { PositionSheetPositionSlot } from './positionSheetPositionSlot';
import { PositionSheetsInfos } from './positionSheetsInfos';

interface IPositionSheetProps {
    date?: DateTime;
    options: PositionDocumentOptions;
    positionDocumentFragment: DocumentPositionSheetFragment;
}

export const PositionSheet = (props: IPositionSheetProps) => {
    const { organization, event } = props.positionDocumentFragment;
    const position = event.position;
    const slots = React.useMemo(
        () =>
            position.slots.filter((slot) => {
                if (props.date?.isValid) {
                    return slot.range.start!.startOf('day').equals(props.date.startOf('day'));
                } else {
                    return true;
                }
            }),
        [position, props.date]
    );

    return (
        <Box width={1} css={{ background: 'white' }}>
            {props.options.showPositionData && (
                <Flex
                    direction="column"
                    width={1}
                    css={{
                        background: 'white',
                        pageBreakBefore: 'always'
                    }}
                >
                    <PositionSheetHeader acronym={position.acronym} name={position.name} />

                    <PositionSheetsInfos
                        displayPositionName={false}
                        leadersData="both"
                        position={position}
                        ranges={[{ name: null, range: position.range }]}
                        showResourcesInfos={true}
                    />
                </Flex>
            )}

            {props.options.showVolunteersLists &&
                slots.map((slot, index) => {
                    const pageBreak = props.options.showPositionData ? true : index !== 0;
                    const volunteers = sortBy(
                        event.volunteersRegistrations.nodes.filter(({ positionsSlotsUsersInfos }) =>
                            positionsSlotsUsersInfos
                                .map(({ positionSlotId }) => positionSlotId)
                                .includes(slot.id)
                        ),
                        (vr) => vr.userInfo.name
                    );

                    return (
                        <PositionSheetPositionSlot
                            key={slot.id}
                            customFields={organization.customFields.nodes}
                            pageBreak={pageBreak}
                            positionAcronym={position.acronym}
                            positionName={position.name}
                            slot={slot}
                            slugsToDisplay={props.options.slugsToDisplay}
                            volunteers={volunteers}
                        />
                    );
                })}
        </Box>
    );
};
