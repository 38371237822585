import { sortBy } from 'lodash-es';
import * as React from 'react';
import { Avatar } from '../components/avatar/avatar';
import { Cell } from '../designSystem/components/table/cell';
import { HeaderCell } from '../designSystem/components/table/headerCell';
import { HeaderRow } from '../designSystem/components/table/headerRow';
import { Row } from '../designSystem/components/table/row';
import { Table } from '../designSystem/components/table/table';
import { CustomFieldWithConditionFragment, VolunteerPdfFragment } from '../generated/types';
import { useService, useTranslate } from '../util/dependencies/dependencies';
import { FieldService } from '../vo/field';

interface IVolunteersTableProps {
    customFields: CustomFieldWithConditionFragment[];
    slugsToDisplay: string[];
    volunteers: VolunteerPdfFragment[];
}

export const VolunteersTable = (props: IVolunteersTableProps) => {
    const translate = useTranslate();
    const fieldService = useService(FieldService);
    const slugsToDisplay = React.useMemo(() => props.slugsToDisplay || [], [props.slugsToDisplay]);
    const slugToCustomField = React.useMemo(() => Object.fromEntries(
            slugsToDisplay.flatMap((slug) => {
                const customField = props.customFields.find((cf) => cf.slug === slug);

                if (customField) {
                    return [[slug, customField]];
                } else {
                    return [];
                }
            })
        ), [slugsToDisplay, props.customFields]);
    const volunteers = React.useMemo(() => sortBy(props.volunteers, (v) => v.userInfo.name), [props.volunteers]);

    return (
        <Table>
            <HeaderRow>
                {slugsToDisplay.map((slug) => {
                    const customField = slugToCustomField[slug];

                    if (slug === 'email') {
                        return <HeaderCell key={slug}>{translate('Email')}</HeaderCell>;
                    } else if (slug === 'fullName') {
                        return <HeaderCell key={slug}>{translate('full_name')}</HeaderCell>;
                    } else if (slug === 'picture' && customField) {
                        return (
                            <HeaderCell key={slug} width={80}>
                                {customField.name}
                            </HeaderCell>
                        );
                    } else if (slug === 'teamCode') {
                        return <HeaderCell key={slug}>{translate('code_quipe_14296')}</HeaderCell>;
                    } else if (customField) {
                        return <HeaderCell key={slug}>{customField.name}</HeaderCell>;
                    } else {
                        return <HeaderCell key={slug} />;
                    }
                })}
            </HeaderRow>

            {volunteers.map((volunteer) => {
                const userInfo = volunteer.userInfo;

                return (
                    <Row key={userInfo.id} css={{ height: '44px' }}>
                        {slugsToDisplay.map((slug) => {
                            const customField = slugToCustomField[slug];

                            if (slug === 'email') {
                                return <Cell key={slug}>{userInfo.email}</Cell>;
                            } else if (slug === 'fullName') {
                                return (
                                    <HeaderCell css={{ color: '$gray800' }} key={slug}>
                                        {userInfo.name}
                                    </HeaderCell>
                                );
                            } else if (slug === 'picture' && customField) {
                                return (
                                    <Cell key={slug} width={80}>
                                        <Avatar
                                            size={32}
                                            image={userInfo.picture?.url}
                                            email={userInfo.email}
                                            name={userInfo.name}
                                        />
                                    </Cell>
                                );
                            } else if (slug === 'teamCode') {
                                return <Cell key={slug}>{volunteer.teamCode}</Cell>;
                            } else if (customField) {
                                return (
                                    <Cell key={slug}>
                                        {fieldService.getValueString(customField, userInfo)}
                                    </Cell>
                                );
                            } else {
                                return <Cell key={slug} />;
                            }
                        })}
                    </Row>
                );
            })}
        </Table>
    );
};
