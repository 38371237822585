import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { isNonEmptyString } from '../../../util/string';

interface IRecrewdaysPassProps {
    event: DocumentUserCustomBadgeFragment;
}

export const RecrewdaysPass = (props: IRecrewdaysPassProps) => {
    const vr = props.event.volunteerRegistration;
    const userInfo = vr.userInfo;

    return (
        <Flex
            direction="column"
            height={A4_SIZES['96dpi'].height}
            width={A4_SIZES['96dpi'].width}
            css={{
                background: `url(https://assets.recrewteer.com/badges/recrewdays/passText.png) no-repeat`,
                backgroundSize: 'cover',
                position: 'relative'
            }}
        >
            <Flex
                direction="column"
                css={{
                    color: '$gray800',
                    fontSize: '22px',
                    fontWeight: '700',
                    left: '92px',
                    position: 'absolute',
                    textTransform: 'capitalize',
                    top: '246px'
                }}
            >
                <Box>{userInfo.firstName}</Box>
                <Box>{userInfo.lastName}</Box>
            </Flex>

            <Box
                css={{
                    color: '#0032fa',
                    fontSize: '20px',
                    fontWeight: '700',
                    left: '92px',
                    position: 'absolute',
                    top: '312px'
                }}
            >
                {userInfo.recrewdaysEvenement}
            </Box>

            <Box
                css={{
                    height: '90px',
                    left: '612px',
                    position: 'absolute',
                    top: '246px',
                    width: '90px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
