import { PhoneInput } from 'common-front/src/designSystem/form/phoneInput';
import { Select } from 'common-front/src/designSystem/form/select/select';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { useEnumToOptions } from 'common-front/src/hooks/useEnumToOptions';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { styled } from 'common/src/designSystem/components/stitches';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { USABLE_LANGUAGES } from 'common/src/vo/supportedLanguage';
import * as React from 'react';

const FirstAndLastNames = styled(Flex, {});

export const ProfileTextInputs = ({ inputName }: { inputName: string }) => {
    const translate = useTranslate();
    const enumToOptions = useEnumToOptions();

    return (
        <>
            <FirstAndLastNames
                wrap="wrap"
                css={{
                    gap: '$6',
                    '@tablet': {
                        gap: '$4',
                        '> *': {
                            flexGrow: 1,
                            width: 'auto'
                        }
                    }
                }}
            >
                <TextInput
                    autoComplete="given-name"
                    label={translate('pr_nom_66988')}
                    name={`${inputName}.fields.firstName`}
                />

                <TextInput
                    autoComplete="family-name"
                    label={translate('last_name')}
                    name={`${inputName}.fields.lastName`}
                />
            </FirstAndLastNames>
            <Spacer height="6" />
            <TextInput
                autoComplete="email"
                isEmail={true}
                label={translate('e_mail_13869')}
                name={`${inputName}.email`}
            />
            <Spacer height="6" />
            <Select
                autoComplete="language"
                label={translate('Language')}
                name={`${inputName}.fields.language`}
            >
                {enumToOptions(USABLE_LANGUAGES)}
            </Select>
            <Spacer height="6" />
            <PhoneInput label={translate('Phone')} prefix={`${inputName}.fields.phone.`} />
        </>
    );
};
