import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import { useHistory } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';

interface IOnboardingRowProps {
    isDone: boolean;
    path: string;
    subtitle: string;
    title: string;
}

export const OnboardingRow = (props: IOnboardingRowProps) => {
    const history = useHistory();

    return (
        <Flex
            align="center"
            gap="3"
            width={1}
            css={{
                background: 'white',
                border: '1px solid $gray200',
                borderRadius: '$2',
                boxShadow: '$xs',
                cursor: 'pointer',
                padding: '$3 $4'
            }}
            onClick={() => {
                history.push(props.path);
            }}
        >
            <Flex
                align="center"
                justify="center"
                height={20}
                width={20}
                css={{
                    background: props.isDone ? '$success100' : '',
                    border: props.isDone ? '' : '1px solid $gray300',
                    borderRadius: '20px',
                    color: '$success700',
                    fontSize: '10px'
                }}
            >
                {props.isDone && <I icon="check" />}
            </Flex>

            <Flex direction="column" gap="1" css={{ flex: '1' }}>
                <Box
                    color="gray800"
                    fontWeight="medium"
                    css={{
                        textDecoration: props.isDone ? 'line-through' : ''
                    }}
                >
                    {props.title}
                </Box>

                <Box color="gray500">{props.subtitle}</Box>
            </Flex>

            <Box color="gray900" css={{ cursor: 'pointer' }}>
                <I icon="arrow-right" />
            </Box>
        </Flex>
    );
};
