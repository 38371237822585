import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';
import { CommonEnvVars } from '../../../envVars';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { isNonEmptyString } from '../../../util/string';

interface IBelgiumHouseBadge2Props {
    event: DocumentUserCustomBadgeFragment;
}

export const BelgiumHouseBadge2 = (props: IBelgiumHouseBadge2Props) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;

    return (
        <Flex
            height={530}
            width={333}
            css={{
                background: `url(https://assets.recrewteer.com/badges/belgiumHouse/badge2.jpg) no-repeat`,
                backgroundSize: 'cover',
                fontFamily: '$gothamRoundedBook',
                position: 'relative'
            }}
        >
            <Box
                width={1}
                textAlign="center"
                css={{
                    color: 'white',
                    fontSize: '24px',
                    position: 'absolute',
                    textTransform: 'uppercase',
                    top: '250px'
                }}
            >
                {ui.fields.cf33876}
            </Box>

            <Box
                height={91}
                width={91}
                css={{
                    background: 'white',
                    left: '121px',
                    padding: '4px',
                    position: 'absolute',
                    top: '303px'
                }}
            >
                {isNonEmptyString(vr.weezeventParticipantInfo?.idBarcode) && (
                    <img
                        src={`https://api.${
                            CommonEnvVars.HEAVENT_EXTERNAL_DOMAIN
                        }/barcode/qr?text=${encodeURIComponent(
                            vr.weezeventParticipantInfo!.idBarcode
                        )}&color=000000&height=91&width=91`}
                        height="100%"
                        width="100%"
                    />
                )}
            </Box>
        </Flex>
    );
};
