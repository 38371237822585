import { chunk, sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import * as React from 'react';
import { DocumentPositionPlanningFragment } from '../../generated/types';
import { splitRangesByDay } from '../../util/interval';
import { PositionPlanningPage } from './positionPlanningPage';

const NUMBER_OF_SLOTS_PER_PAGE = 8;
const NUMBER_OF_MEMBERS_PER_PAGE = 11;

interface IPositionPlanningProps {
    date?: DateTime;
    event: DocumentPositionPlanningFragment;
}

export const PositionPlanning = (props: IPositionPlanningProps) => {
    const slots = React.useMemo(() => sortBy(splitRangesByDay(props.event.position.slots), (s) => s.range.start).filter(
            (s) => {
                if (props.date?.isValid) {
                    return s.range.start!.startOf('day').equals(props.date.startOf('day'));
                } else {
                    return true;
                }
            }
        ), [props.event, props.date]);

    return (
        <>
            {chunk(slots, NUMBER_OF_SLOTS_PER_PAGE).map((slotsChunk, slotsChunkIndex) => chunk(
                    props.event.volunteersRegistrations.nodes,
                    NUMBER_OF_MEMBERS_PER_PAGE
                ).map((volunteersRegistrations, vrChunkIndex) => (
                        <PositionPlanningPage
                            key={`${slotsChunkIndex}-${vrChunkIndex}`}
                            position={props.event.position}
                            slots={slotsChunk}
                            volunteersRegistrations={volunteersRegistrations}
                        />
                    )))}
        </>
    );
};
