import { Button } from 'common-front/src/designSystem/components/button';
import { IconBackground } from 'common-front/src/designSystem/components/iconBackground';
import { Modal } from 'common-front/src/designSystem/components/modal';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import {
    EventId,
    LeaderInfosFragment,
    LeaderInput,
    OrganizationId
} from 'common/src/generated/types';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import { produce } from 'immer';
import { without } from 'lodash-es';
import * as React from 'react';
import { AddLeaders } from './addLeaders';
import { Leader } from './leader';

interface IAssignLeadersModalProps {
    currentLeaders: LeaderInfosFragment[];
    eventId: EventId;
    organizationId: OrganizationId;

    onClose(): void;
    onSave(leaders: LeaderInput[]): void;
}

export const AssignLeadersModal = (props: IAssignLeadersModalProps) => {
    const translate = useTranslate();
    const [leaders, setLeaders] = React.useState(props.currentLeaders);

    return (
        <Modal size="md" onClose={props.onClose}>
            <Flex justify="center">
                <IconBackground color="primary" icon="user-gear" />
            </Flex>

            <Spacer height="4" />

            <Box color="gray900" fontSize="textLg" fontWeight="medium" textAlign="center">
                {translate('modification_de_24714')}
            </Box>

            <Spacer height="1" />

            <Box color="gray500" textAlign="center">
                {translate('les_responsable_92946')}
            </Box>

            <Spacer height="6" />

            <AddLeaders
                currentLeaders={leaders}
                eventId={props.eventId}
                organizationId={props.organizationId}
                onAdd={(newLeader) => {
                    setLeaders(
                        leaders.concat([
                            {
                                canEdit: false,
                                userInfo: newLeader
                            }
                        ])
                    );
                }}
            />

            <Spacer height="6" />

            <Flex direction="column" gap="4" css={{ flex: '1', overflowY: 'auto' }}>
                {leaders.map((leader, index) => (
                        <Leader
                            key={leader.userInfo.id}
                            leader={leader}
                            onCanEditChange={(canEdit) => {
                                setLeaders(
                                    produce((currentLeaders) => {
                                        currentLeaders[index].canEdit = canEdit;
                                    })
                                );
                            }}
                            onDelete={() => {
                                setLeaders(without(leaders, leader));
                            }}
                        />
                    ))}
            </Flex>

            <Spacer height="7" />

            <Flex justify="end" gap="3">
                <Button
                    onClick={() => {
                        props.onSave(
                            leaders.map((leader) => ({
                                userId: leader.userInfo.userId,
                                userInfoId: leader.userInfo.id,
                                canEdit: leader.canEdit
                            }))
                        );
                    }}
                >
                    {translate('enregistrer_06519')}
                </Button>

                <Button color="white" onClick={props.onClose}>
                    {translate('annuler_48254')}
                </Button>
            </Flex>
        </Modal>
    );
};
