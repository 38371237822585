import { DateInput } from 'common-front/src/designSystem/components/date/dateInput';
import { DelegationProperty, DelegationsDelegationFragment } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';
import { DelegationsUpdateCell } from './delegationsUpdateCell';

interface IDelegationsDeadlineDateCellProps {
    delegation: DelegationsDelegationFragment;
    firstCellCss: any;

    reload(): void;
}

export const DelegationsDeadlineDateCell = (props: IDelegationsDeadlineDateCellProps) => {
    const translate = useTranslate();
    const dateTimeService = useService(DateTimeService);

    return (
        <DelegationsUpdateCell
            delegation={props.delegation}
            cellCss={props.firstCellCss}
            initialValue={props.delegation.deadlineDate as any}
            property={DelegationProperty.DeadlineDate}
            reload={props.reload}
            renderInput={(value, setValue) => (
                    <DateInput
                        label={translate('date_limite_d_i_79779')}
                        value={value}
                        onChange={setValue}
                    />
                )}
        >
            {props.delegation.deadlineDate
                ? dateTimeService.toLocaleString(
                      props.delegation.deadlineDate,
                      LocaleFormats.DateOnly.MonthLong
                  )
                : '-'}
        </DelegationsUpdateCell>
    );
};
