import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I } from 'common/src/designSystem/components/i';
import * as React from 'react';

interface IFormParametersMissionsPositionRankingProps {
    isSelected: boolean;
    name: string;
    src: string;

    onSelect(): void;
}

export const FormParametersMissionsPositionRanking = (
    props: IFormParametersMissionsPositionRankingProps
) => (
        <Flex
            direction="column"
            gap="1"
            css={{ cursor: 'pointer', position: 'relative' }}
            onClick={props.onSelect}
        >
            {props.isSelected && (
                <Flex
                    align="center"
                    justify="center"
                    height={20}
                    width={20}
                    css={{
                        background: '$primary700',
                        borderRadius: '20px',
                        color: 'white',
                        fontSize: '$textMd',
                        position: 'absolute',
                        right: '-10px',
                        top: '-10px'
                    }}
                >
                    <I icon="check" />
                </Flex>
            )}

            <Box
                height={135}
                width={240}
                css={{
                    background: '$gray100',
                    border: props.isSelected ? '2px solid $primary700' : '2px solid $gray100',
                    borderRadius: '$1',
                    padding: '$6',
                    paddingBottom: '0'
                }}
            >
                <img src={props.src} height="107px" width="188px" />
            </Box>

            <Box font="gray800 textSm semiBold">{props.name}</Box>
        </Flex>
    );
