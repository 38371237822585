import { Button } from 'common-front/src/designSystem/components/button';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import {
    AccreditationPositionProperty,
    PositionsPositionFragment
} from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { PositionsUpdateCell } from './positionsUpdateCell';

interface IPositionsNameCellProps {
    firstCellCss: any;
    position: PositionsPositionFragment;

    reload(): void;
}

export const PositionsNameCell = (props: IPositionsNameCellProps) => {
    const {
        history,
        translate,
        params: { organizationId, eventId }
    } = useHeavent();
    const { isEditMode, segmentId } = useSegmentsContext();
    const css = isEditMode
        ? { ellipsis: true }
        : {
              cursor: 'pointer',
              ellipsis: true,
              '&:hover': {
                  textDecoration: 'underline'
              }
          };

    return (
        <PositionsUpdateCell
            cellCss={props.firstCellCss}
            initialValue={props.position.name}
            position={props.position}
            property={AccreditationPositionProperty.Name}
            reload={props.reload}
            renderInput={(value, setValue) => (
                    <TextInput
                        label={translate('nom_de_la_missi_64605')}
                        value={value}
                        onChange={setValue}
                    />
                )}
        >
            <Flex align="center" gap="2" width={1} css={{ overflow: 'hidden' }}>
                <Flex direction="column" css={{ flex: '1', overflow: 'hidden' }}>
                    <Box
                        font="gray900 textSm medium"
                        width={1}
                        css={css}
                        title={props.position.name}
                        onClick={() => {
                            if (!isEditMode) {
                                history.push(
                                    HeaventPaths.POSITION(
                                        organizationId,
                                        eventId,
                                        props.position.id
                                    )
                                );
                            }
                        }}
                    >
                        {props.position.name}
                    </Box>

                    <Box color="gray500">{translate('creneau', props.position.numberOfSlots)}</Box>
                </Flex>

                {!isEditMode && (
                    <Button
                        className="h-preview"
                        size="sm"
                        onClick={() => {
                            history.push(
                                HeaventPaths.POSITIONS_LIST_POSITION(
                                    organizationId,
                                    eventId,
                                    segmentId,
                                    props.position.id
                                )
                            );
                        }}
                    >
                        {translate('aper_u_25595')}
                    </Button>
                )}
            </Flex>
        </PositionsUpdateCell>
    );
};
