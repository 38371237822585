import { Button } from 'common-front/src/designSystem/components/button';
import { RadioText } from 'common-front/src/designSystem/form/radio';
import { TextInput } from 'common-front/src/designSystem/form/textInput';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CommonEnvVars } from 'common/src/envVars';
import { CampaignType, Feature } from 'common/src/generated/types';
import { ICreateUpdateCampaignValues } from 'common/src/input/campaignInput';
import { useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useOrganizationContext } from '../../organization/organizationContext';

interface ICampaignParametersProps {
    formProps: FormRenderProps<ICreateUpdateCampaignValues>;
    isAdmin: boolean;
}

export const CampaignParameters = (props: ICampaignParametersProps) => {
    const translate = useTranslate();
    const { hasFeature } = useOrganizationContext();
    const hasSms = hasFeature(Feature.Sms);

    return (
        <>
            <OnChange name="campaign.campaignType">
                {(campaignType: CampaignType) => {
                    if (campaignType === CampaignType.Sms) {
                        if (hasSms) {
                            props.formProps.form.change('campaign.text', '');
                            props.formProps.form.change('campaign.html', '');
                        } else {
                            props.formProps.form.change(
                                'campaign.campaignType',
                                CampaignType.Email
                            );
                        }
                    } else {
                        props.formProps.form.change('campaign.text', '');
                        props.formProps.form.change('campaign.html', '');
                    }
                }}
            </OnChange>

            <TextInput label={translate('nom_de_votre_ca_08001')} name="campaign.name" />

            <Spacer height="6" />

            <Box color="gray700" fontWeight="medium">
                {translate('quel_type_de_ca_11960')}
            </Box>

            <Spacer height="3" />

            <RadioText name="campaign.campaignType" value={CampaignType.Email}>
                {translate('e_mail_13869')}
            </RadioText>

            <Spacer height="3" />

            {props.isAdmin && (
                <RadioText name="campaign.campaignType" value={CampaignType.Sms} disabled={!hasSms}>
                    <Flex align="center" gap="2">
                        <Box>SMS</Box>

                        <Badge>0.07€/SMS</Badge>

                        {!hasSms && (
                            <Box
                                color="gray500"
                                fontSize="textXs"
                                css={{
                                    '& a': {
                                        color: '$primary700'
                                    }
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        `contactez_nous_22980`,
                                        CommonEnvVars.EMAIL_FROM,
                                        CommonEnvVars.EMAIL_FROM
                                    )
                                }}
                            />
                        )}
                    </Flex>
                </RadioText>
            )}

            <Spacer height="7" />

            <Flex>
                <Button onClick={props.formProps.handleSubmit}>
                    {translate('suivant_conte_90603')}
                </Button>
            </Flex>
        </>
    );
};
