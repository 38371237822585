import { chunk } from 'lodash-es';
import * as React from 'react';
import { Flex } from '../../../designSystem/components/flex';
import { DocumentUserCustomBadgesFragment } from '../../../generated/types';
import { A4_SIZES } from '../../../util/pdf';
import { BadgeHighfiveA6 } from './badgeHighfiveA6';

interface IBadgesHighfiveProps {
    event: DocumentUserCustomBadgesFragment;
}

export const BadgesHighfive = (props: IBadgesHighfiveProps) => (
        <>
            {chunk(props.event.volunteersRegistrations.nodes, 2).map(
                (volunteersRegistrations, index) => (
                        <Flex
                            key={`page-${index}`}
                            wrap="wrap"
                            justify="between"
                            width={A4_SIZES['96dpi'].width}
                            css={{
                                pageBreakBefore: index !== 0 ? 'always' : undefined
                            }}
                        >
                            {volunteersRegistrations.map((vr, vrIndex) => (
                                    <BadgeHighfiveA6
                                        key={`${index}-${vrIndex}`}
                                        event={{
                                            ...props.event,
                                            volunteerRegistration: vr
                                        }}
                                    />
                                ))}
                        </Flex>
                    )
            )}
        </>
    );
