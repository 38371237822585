import { DetailBox } from 'common-front/src/designSystem/components/detailBox';
import { Badge } from 'common/src/designSystem/components/badge';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Tag, TagId } from 'common/src/generated/types';
import { isNonEmptyArray } from 'common/src/util/array';
import { useParams, useTranslate } from 'common/src/util/dependencies/dependencies';
import * as React from 'react';
import { AssociateTagsModal } from '../../tags/associate/associateTagsModal';

type ITagsType = 'accreditations' | 'delegations' | 'positions';

interface IAccreditationEditableTagsProps {
    tags: Array<Pick<Tag, 'id' | 'name'>>;
    type: ITagsType;

    associateTags(tagsIds: TagId[]): Promise<any>;
    reload(): void;
}

interface IAccreditationReadOnlyTagsProps {
    tags: Array<Pick<Tag, 'id' | 'name'>>;
    type: ITagsType;
}

type IAccreditationTagsProps = IAccreditationReadOnlyTagsProps | IAccreditationEditableTagsProps;

export const AccreditationTags = (props: IAccreditationTagsProps) => {
    const translate = useTranslate();
    const { organizationId } = useParams();
    const [isTagAssociateOpen, setIsTagAssociateOpen] = React.useState(false);

    // TODO: handle isEventAdmin
    if ('associateTags' in props) {
        return (
            <DetailBox
                rightIcon="pen"
                rightIconClick={() => {
                    setIsTagAssociateOpen(true);
                }}
                title={translate('tags_79499')}
            >
                {isNonEmptyArray(props.tags) ? (
                    <Flex gap="2" wrap="wrap">
                        {props.tags.map((tag) => <Badge key={tag.id}>{tag.name}</Badge>)}
                    </Flex>
                ) : (
                    <Box color="gray500" width={1} textAlign="center">
                        {translate('aucun_tag_ajout_63348')}
                    </Box>
                )}

                {isTagAssociateOpen && (
                    <AssociateTagsModal
                        organizationId={organizationId}
                        subtitle={
                            props.type === 'accreditations'
                                ? translate('ajouter_des_tag_77298')
                                : props.type === 'delegations'
                                ? translate('ajouter_des_tag_35833')
                                : translate('ajouter_des_tag_64526')
                        }
                        tagsIds={props.tags.map(({ id }) => id)}
                        onClose={() => {
                            setIsTagAssociateOpen(false);
                        }}
                        onSave={async (tagsIds) => {
                            await props.associateTags(tagsIds);

                            props.reload();

                            setIsTagAssociateOpen(false);
                        }}
                    />
                )}
            </DetailBox>
        );
    } else {
        return (
            <DetailBox title={translate('tags_79499')}>
                {isNonEmptyArray(props.tags) ? (
                    <Flex gap="2" wrap="wrap">
                        {props.tags.map((tag) => <Badge key={tag.id}>{tag.name}</Badge>)}
                    </Flex>
                ) : (
                    <Box color="gray500" width={1} textAlign="center">
                        {translate('aucun_tag_ajout_63348')}
                    </Box>
                )}
            </DetailBox>
        );
    }
};
