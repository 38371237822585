import { IIcon } from 'common/src/designSystem/components/i';
import { assertUnreachable } from 'common/src/util/assertUnreachable';
import { Emptyable } from 'common/src/util/emptyable';
import { HeaventPaths } from 'common/src/util/heaventPaths';

export enum PositionsRendering {
    Calendar = 'Calendar',
    List = 'List',
    Map = 'Map'
}

export function getPositionsRendering(positionsRendering: Emptyable<PositionsRendering>) {
    switch (positionsRendering) {
        case PositionsRendering.Calendar:
            return PositionsRendering.Calendar;
        case PositionsRendering.Map:
            return PositionsRendering.Map;
        default:
            return PositionsRendering.List;
    }
}

export function positionsRenderingPath(positionsRendering: PositionsRendering) {
    switch (positionsRendering) {
        case PositionsRendering.Calendar:
            return HeaventPaths.POSITIONS_CALENDAR;
        case PositionsRendering.List:
            return HeaventPaths.POSITIONS_LIST;
        case PositionsRendering.Map:
            return HeaventPaths.POSITIONS_MAP;
        default:
            return assertUnreachable(positionsRendering);
    }
}

export function positionsRenderingIcon(positionsRendering: PositionsRendering): IIcon {
    switch (positionsRendering) {
        case PositionsRendering.Calendar:
            return 'calendar';
        case PositionsRendering.List:
            return 'table';
        case PositionsRendering.Map:
            return 'map-location';
        default:
            return assertUnreachable(positionsRendering);
    }
}
