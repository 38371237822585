import { Interval } from 'luxon';
import * as React from 'react';
import { Box } from '../../designSystem/components/box';
import { Flex } from '../../designSystem/components/flex';
import { IntervalService } from '../../services/intervalService';
import { useService, useTranslate } from '../../util/dependencies/dependencies';

interface IUserPlanningHeaderProps {
    interval?: Interval;
    nameOrEmail: string;
    numberOfAssignments: number;
}

export const UserPlanningHeader = (props: IUserPlanningHeaderProps) => {
    const translate = useTranslate();
    const intervalService = useService(IntervalService);

    return (
        <Flex direction="column" gap="2" css={{ background: '$gray100', padding: '$4 56px' }}>
            <Box font="gray900 textXl semiBold">
                {translate('planning_1_14391', props.nameOrEmail)}
            </Box>

            {props.interval && (
                <Flex gap="1">
                    <Box color="gray800">{translate('plage_de_dates_08875')}</Box>
                    <Box color="gray500">
                        {intervalService.toDisplayString(props.interval, {
                            formats: { displayWeekday: true },
                            hideTime: true
                        })}
                    </Box>
                </Flex>
            )}

            <Flex gap="1">
                <Box color="gray800">{translate('nombre_d_affect_01587')}</Box>
                <Box color="gray500">{props.numberOfAssignments}</Box>
            </Flex>
        </Flex>
    );
};
