import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { BadgeJardinMichelBack } from './badgeJardinMichelBack';
import { BadgeJardinMichelFront } from './badgeJardinMichelFront';

interface IBadgeJardinMichelProps {
    event: DocumentUserCustomBadgeFragment;
}

export const BadgeJardinMichel = (props: IBadgeJardinMichelProps) => (
        <>
            <BadgeJardinMichelFront event={props.event} />

            <BadgeJardinMichelBack event={props.event} />
        </>
    );
