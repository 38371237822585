import { CenteredContainer } from 'common-front/src/components/centeredContainer/centeredContainer';
import { FormWizard, FormWizardPage } from 'common-front/src/components/form/formWizard';
import { FullScreenPopup } from 'common-front/src/components/fullScreenPopup/fullScreenPopup';
import { Portal } from 'common-front/src/components/portal';
import { EventId, MassAssignStrategy, SegmentId, UsersInfoId } from 'common/src/generated/types';
import { IMassAccreditValues, MassAccreditInputService } from 'common/src/input/massAccreditInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import * as React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useVolunteersRegistrationsMassAccreditMutation } from '../../generated/graphqlHooks';
import { MassAccreditStep1 } from './massAccreditStep1';
import { MassAccreditStep2 } from './massAccreditStep2';

interface IMassAccreditModalProps {
    eventId: EventId;
    numberOfSelected: number;
    segmentId: Emptyable<SegmentId>;
    usersInfosIds: UsersInfoId[];

    onClose(): void;
    onSuccess(): void;
}

export const MassAccreditModal = ({
    eventId,
    numberOfSelected,
    onClose,
    onSuccess,
    segmentId,
    usersInfosIds
}: IMassAccreditModalProps) => {
    const translate = useTranslate();
    const massAccreditInput = useService(MassAccreditInputService);
    const { mutate } = useVolunteersRegistrationsMassAccreditMutation();

    return (
        <Portal>
            <FullScreenPopup
                category={translate('Accreditation')}
                title={translate('accr_diter_les_48281')}
                close={onClose}
            >
                <CenteredContainer>
                    <FormWizard
                        initialValues={{
                            massAccredit: {
                                segmentId,
                                strategy: MassAssignStrategy.Add,
                                accreditationsSlotsIds: [],
                                usersInfosIds
                            }
                        }}
                        isEdit={false}
                        onSubmit={async (values: IMassAccreditValues) => {
                            await mutate({
                                eventId,
                                massAccredit: values.massAccredit
                            });

                            onSuccess();
                            onClose();
                        }}
                    >
                        <FormWizardPage
                            title={translate('initialisation_38640')}
                            schema={massAccreditInput.massAccreditStep1Schema()}
                            render={({ handleSubmit }) => <MassAccreditStep1 handleSubmit={handleSubmit} />}
                        />

                        <FormWizardPage
                            title={translate('accr_ditations_39450')}
                            schema={massAccreditInput.massAccreditStep2Schema()}
                            render={({
                                form,
                                handleSubmit,
                                submitting,
                                values
                            }: FormRenderProps<IMassAccreditValues>) => (
                                    <MassAccreditStep2
                                        eventId={eventId}
                                        numberOfSelected={numberOfSelected}
                                        submitting={submitting}
                                        usersInfosIds={usersInfosIds}
                                        values={values}
                                        change={form.change}
                                        handleSubmit={handleSubmit}
                                    />
                                )}
                        />
                    </FormWizard>
                </CenteredContainer>
            </FullScreenPopup>
        </Portal>
    );
};
