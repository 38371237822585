import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { I, IIcon } from 'common/src/designSystem/components/i';
import { Cell } from 'common/src/designSystem/components/table/cell';
import { Row } from 'common/src/designSystem/components/table/row';
import { ActivitiesQuery } from 'common/src/generated/types';
import { DateTimeService } from 'common/src/services/dateTimeService';
import { useService } from 'common/src/util/dependencies/dependencies';
import { LocaleFormats } from 'common/src/util/luxon';
import * as React from 'react';

interface IActivityRowProps {
    activity: ActivitiesQuery['organization']['activities']['nodes'][0];
}

export const ActivityRow = (props: IActivityRowProps) => {
    const dateTimeService = useService(DateTimeService);

    return (
        <Row>
            <Cell align="center" gap="3">
                <Flex
                    align="center"
                    justify="center"
                    css={{
                        background: '$primary300',
                        borderRadius: '32px',
                        color: '$primary600',
                        height: '32px',
                        width: '32px'
                    }}
                >
                    <I icon={props.activity.icon as IIcon} />
                </Flex>

                <Box font="gray900 textSm medium">{props.activity.text}</Box>
            </Cell>

            <Cell>
                {dateTimeService.toLocaleString(
                    props.activity.date.toLocal(),
                    LocaleFormats.DateTime
                )}
            </Cell>
        </Row>
    );
};
