import * as React from 'react';
import { Box } from '../../../designSystem/components/box';
import { Flex } from '../../../designSystem/components/flex';

interface IJardinSonorePhotograpeAccreditationProps {
    color: string;
    title: string;
    scene: string;
}

export const JardinSonorePhotograpeAccreditation = (
    props: IJardinSonorePhotograpeAccreditationProps
) => (
        <Flex direction="column" gap="1" width={132}>
            <Box
                width={1}
                css={{
                    fontSize: '10px',
                    fontWeight: '700',
                    textAlign: 'center',
                    textTransform: 'uppercase'
                }}
            >
                {props.title}
            </Box>

            <Flex
                align="center"
                justify="center"
                height={20}
                width={1}
                css={{
                    background: props.color,
                    borderRadius: '$1',
                    fontSize: '14px',
                    fontWeight: '700',
                    textTransform: 'uppercase'
                }}
            >
                {props.scene}
            </Flex>
        </Flex>
    );
