import { sortBy } from 'lodash-es';
import * as React from 'react';
import { DocumentUserCustomBadgeFragment } from '../../../generated/types';
import { PositionSheetCroixRougePage } from './positionSheetCroixRougePage';

interface IPositionSheetCroixRougeProps {
    event: DocumentUserCustomBadgeFragment;
}

export const PositionSheetCroixRouge = (props: IPositionSheetCroixRougeProps) => {
    const vr = props.event.volunteerRegistration;
    const ui = vr.userInfo;
    const psuis = sortBy(vr.positionsSlotsUsersInfos, (psui) => psui.positionSlot.id);

    return (
        <>
            {psuis.map((psui) => (
                    <PositionSheetCroixRougePage
                        key={psui.positionSlot.id}
                        psui={psui}
                        userInfo={ui}
                    />
                ))}
        </>
    );
};
