import { Checkbox, ICheckboxState } from 'common-front/src/designSystem/components/checkbox';
import { useSegmentsContext } from 'common-front/src/segments/segmentsContext';
import { Box } from 'common/src/designSystem/components/box';
import { Cell } from 'common/src/designSystem/components/table/cell';
import {
    OrganizationId,
    SegmentCustomFieldFragment,
    UserInfoCommunityFragment
} from 'common/src/generated/types';
import { useService } from 'common/src/util/dependencies/dependencies';
import { FieldService } from 'common/src/vo/field';
import { PossibleColumn } from 'common/src/vo/segment';
import * as React from 'react';
import { useUserInfoUpdateFieldMutation } from '../../generated/graphqlHooks';

interface ICommunityUserCheckboxCellProps {
    customField: SegmentCustomFieldFragment;
    field: PossibleColumn;
    firstCellCss: any;
    organizationId: OrganizationId;
    userInfo: UserInfoCommunityFragment;

    reload(): void;
}

export const CommunityUserCheckboxCell = (props: ICommunityUserCheckboxCellProps) => {
    const { mutate } = useUserInfoUpdateFieldMutation();
    const fieldService = useService(FieldService);
    const { isEditMode } = useSegmentsContext();
    const value = fieldService.getValue<boolean>(props.customField, props.userInfo);
    const valueString = fieldService.getValueString(props.customField, props.userInfo);
    const state = value === true ? 'checked' : value === false ? 'unchecked' : 'indeterminate';
    const onClick = async (newState: ICheckboxState) => {
        await mutate({
            organizationId: props.organizationId,
            userInfoId: props.userInfo.id,
            slug: props.field.slug,
            value: newState === 'checked'
        });

        props.reload();
    };

    if (isEditMode) {
        return (
            <Cell
                css={{
                    ...props.firstCellCss,
                    border: '2px solid transparent',
                    '& > .h-checkbox-cell-hover': {
                        display: 'none'
                    },
                    '&:hover': {
                        border: '2px solid $primary700'
                    },
                    '&:hover > .h-checkbox-cell-default': {
                        display: 'none'
                    },
                    '&:hover > .h-checkbox-cell-hover': {
                        display: 'block'
                    }
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
            >
                <Box className="h-checkbox-cell-default">{valueString}</Box>

                <Box className="h-checkbox-cell-hover">
                    <Checkbox state={state} onClick={onClick} />
                </Box>
            </Cell>
        );
    } else {
        return (
            <Cell
                css={{
                    ...props.firstCellCss,
                    border: '2px solid transparent'
                }}
            >
                {valueString}
            </Cell>
        );
    }
};
