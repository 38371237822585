import { RichSelect } from 'common-front/src/designSystem/form/richSelect';
import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { getToken } from 'common-front/src/util/aws/cognito';
import { Box } from 'common/src/designSystem/components/box';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { CampaignEventSegmentsRecipientsQuery } from 'common/src/generated/types';
import * as React from 'react';
import { executeCampaignEventSegmentsRecipientsQuery } from '../../../generated/graphqlHooks';

interface ICampaignEventSegmentsRecipientsProps {
    recipients: string;
}

export const CampaignEventSegmentsRecipients = (props: ICampaignEventSegmentsRecipientsProps) => {
    const {
        translate,
        params: { eventId }
    } = useHeavent();
    const [isFirstTime, setIsFirstTime] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(true);
    const [segmentsFolders, setSegmentsFolders] = React.useState<
        CampaignEventSegmentsRecipientsQuery['event']['segmentsFolders']
    >([]);

    React.useEffect(() => {
        (async () => {
            if (props.recipients === 'segments' && isFirstTime) {
                const data = await executeCampaignEventSegmentsRecipientsQuery(
                    { eventId },
                    await getToken()
                );

                setSegmentsFolders(data.event.segmentsFolders);
                setIsFirstTime(false);
                setIsLoading(false);
            }
        })();
    }, [props.recipients, isFirstTime]);

    if (props.recipients === 'segments') {
        return (
            <>
                <Spacer height="2" />

                <Box css={{ marginLeft: '$6' }}>
                    {isLoading ? (
                        <Skeleton height={40} width={1} borderRadius="$1" />
                    ) : (
                        <RichSelect
                            isSearchVisible={true}
                            multiple={true}
                            name="campaign.segmentsIds"
                            placeholder={translate('ajouter_des_vue_58990')}
                        >
                            {segmentsFolders.map((sf) => (
                                    <optgroup key={sf.id} label={sf.name}>
                                        {sf.segments.map((s) => (
                                                <option key={s.id} value={s.id}>
                                                    {s.name}
                                                </option>
                                            ))}
                                    </optgroup>
                                ))}
                        </RichSelect>
                    )}
                </Box>
            </>
        );
    } else {
        return null;
    }
};
