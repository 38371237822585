import { useHeavent } from 'common-front/src/hooks/useHeavent';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { PositionId } from 'common/src/generated/types';
import { HeaventPaths } from 'common/src/util/heaventPaths';
import * as React from 'react';
import { DocumentRow } from './documentRow';

interface IDocumentsPositionsProps {
    numberOfPositions: number;
    positionId: PositionId;
}

export const DocumentsPositions = (props: IDocumentsPositionsProps) => {
    const {
        translate,
        params: { organizationId, eventId }
    } = useHeavent();

    return (
        <Flex direction="column" gap="2">
            <Box font="gray900 textLg semiBold">{translate('missions_63972')}</Box>

            <DocumentRow
                color="blueGray"
                disabled={props.numberOfPositions === 0}
                icon="sitemap"
                title={translate('fiche_de_missio_00324')}
                text={translate('nombre_de_fiche_03009')}
                count={props.numberOfPositions}
                path={HeaventPaths.DOCUMENTS_POSITION(organizationId, eventId, props.positionId)}
            />

            <DocumentRow
                color="purple"
                disabled={props.numberOfPositions === 0}
                icon="calendar-day"
                title={translate('planning_d_une_64581')}
                text={translate('nombre_de_missi_56719')}
                count={props.numberOfPositions}
                path={HeaventPaths.DOCUMENTS_POSITION_PLANNING(
                    organizationId,
                    eventId,
                    props.positionId
                )}
            />
        </Flex>
    );
};
