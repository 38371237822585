import { FormModal } from 'common-front/src/designSystem/components/formModal';
import { TextInput } from 'common-front/src/designSystem/components/textInput';
import { RadioRow } from 'common-front/src/designSystem/form/radioRow';
import { Box } from 'common/src/designSystem/components/box';
import { Flex } from 'common/src/designSystem/components/flex';
import { Skeleton } from 'common/src/designSystem/components/skeleton';
import { Spacer } from 'common/src/designSystem/components/spacer';
import { OrganizationId, SegmentId, UsersInfoId } from 'common/src/generated/types';
import { IMassAddEventValues, MassAddEventInputService } from 'common/src/input/massAddEventInput';
import { useService, useTranslate } from 'common/src/util/dependencies/dependencies';
import { Emptyable } from 'common/src/util/emptyable';
import { isNonEmptyString } from 'common/src/util/string';
import * as React from 'react';
import {
    useAllEventsQuery,
    useUsersInfosMassAddEventMutation
} from '../../../generated/graphqlHooks';

interface IMassAddEventModalProps {
    numberOfSelected: number;
    organizationId: OrganizationId;
    segmentId: Emptyable<SegmentId>;
    usersInfosIds: UsersInfoId[];

    onClose(): void;
    onSuccess(): void;
}

export const MassAddEventModal = (props: IMassAddEventModalProps) => {
    const translate = useTranslate();
    const massAddEventInput = useService(MassAddEventInputService);
    const { mutate } = useUsersInfosMassAddEventMutation();
    const { data, isLoading } = useAllEventsQuery({ organizationId: props.organizationId });
    const [name, setName] = React.useState('');
    const events = React.useMemo(() => {
        if (isNonEmptyString(name)) {
            return (data.organization?.events.nodes ?? []).filter((e) => e.name.trim().toLowerCase().includes(name.trim().toLowerCase()));
        } else {
            return data.organization?.events.nodes ?? [];
        }
    }, [data.organization, name]);

    return (
        <FormModal
            buttonText={translate('ajouter_le_memb_03343', props.numberOfSelected)}
            icon="sliders"
            initialValues={{
                massAddEvent: {
                    eventId: null as any,
                    segmentId: props.segmentId,
                    usersInfosIds: props.usersInfosIds
                }
            }}
            size="md"
            title={translate('ajouter_les_mem_13940')}
            onClose={props.onClose}
            schema={massAddEventInput.massAddEventInputSchema()}
            onSubmit={async (values: IMassAddEventValues) => {
                await mutate({
                    organizationId: props.organizationId,
                    massAddEvent: values.massAddEvent
                });

                props.onSuccess();
                props.onClose();
            }}
        >
            <TextInput
                icon="magnifying-glass"
                placeholder={translate('rechercher_un_40024')}
                value={name}
                onChange={setName}
            />

            <Spacer height="6" />

            <Box color="gray900" fontWeight="medium">
                {translate('sur_quel_v_nem_31606')}
            </Box>

            <Spacer height="3" />

            <Flex direction="column" gap="3" css={{ overflowY: 'auto' }}>
                {isLoading ? (
                    <>
                        <Skeleton height={54} borderRadius="$2" />

                        <Skeleton height={54} borderRadius="$2" />
                    </>
                ) : (
                    events.map((event) => (
                            <RadioRow key={event.id} name="massAddEvent.eventId" value={event.id}>
                                {event.name}
                            </RadioRow>
                        ))
                )}
            </Flex>
        </FormModal>
    );
};
